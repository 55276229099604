/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { ENTITYNAME } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { Box, Tab } from "@mui/material";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { CellRender, RowRender } from "../Segment/renderers";
import BossDialog from "../../components/BossDialog";


export const SalesTargetEditForm = (props) => {

    const [dataItem, setDataItem] = useState({
        Description: "",
        Quarter: "",
        FinancialYear: {},
        Currency: {},
        Channel: {},
        Target: 0,
        SalesExecutive: [],
        Archive: false,
    });

    const [changes, setChanges] = useState(false);

    const financialYearRef = useRef([]);
    const [financialYear, _setFinancialYear] = useState([]);
    const setFinancialYear = (data) => {
        financialYearRef.current = data;
        _setFinancialYear(data)
    }

    const currencyRef = useRef([]);
    const [currency, _setCurrency] = useState([]);
    const setCurrency = (data) => {
        currencyRef.current = data;
        _setCurrency(data)
    }

    const channelRef = useRef([]);
    const [channel, _setChannel] = useState([]);
    const setChannel = (data) => {
        channelRef.current = data;
        _setChannel(data)
    }

    const [saleExecutive, setSaleExecutive] = useState([]);
    const [tab, setTab] = useState('1');
    const [targetDetails, setTargetDetails] = useState([]);

    let defaultQuarter = {
        '1': false,
        '2': false,
        '3': false,
        '4': false
    }
    const [quarter, setQuarter] = useState(defaultQuarter)

    const lang = useTranslation();

    useEffect(() => {
        pageRender();
    }, []);

    const pageRender = async () => {
        await loadCombo();
        if (props.item.SID > 0 || props.item.copy) {
            loadEdit();
        }
    }

    const isValid = () => {

        if (dataItem.Description == "" || dataItem.Description == undefined || !dataItem.Description.trim()) {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!dataItem.FinancialYear || Object.keys(dataItem.FinancialYear).length == 0) {
            toast.error(`${lang.please_select_financial_year_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(quarter).filter((x) => quarter[x]).length == 0) {
            toast.error(`${lang.please_select_quarter_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Target == "" || dataItem.Target == undefined || dataItem.Target == 0) {
            toast.error(`${lang.please_enter_target_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!dataItem.Currency || Object.keys(dataItem.Currency).length == 0) {
            toast.error(`${lang.please_select_Currency_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!dataItem.Channel || Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!dataItem.SalesExecutive || dataItem.SalesExecutive.length == 0) {
            toast.error(`${lang.please_select_sales_executive_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (targetDetails.length == 0) {
            toast.error(`${lang.please_update_target_details_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (parseInt(dataItem.Target) != utility.getSumOfAColumnValueFromList(targetDetails, 'Target')) {
            toast.error(`${lang.please_update_target_wrong_distribution_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }


        return true;

    }

    const loadCombo = async () => {

        let channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 }, query: ['Archive', '=', false] });
        setChannel(channelRes.data);

        let currencyRes = await API.getDataLookup(ENTITYNAME.Currency, { sort: { Description: 1 }, query: ['Archive', '=', false] });
        setCurrency(currencyRes.data);

        let financialYearRes = await API.getDataLookup(ENTITYNAME.FinancialYear, { sort: { Description: 1 }, query: ['Archive', '=', false] });
        setFinancialYear(financialYearRes.data);

        let saleExecutiveRes = await API.getDataLookup(ENTITYNAME.Users, { sort: { Name: 1 }, query: ['Archive', '=', false] });

        setSaleExecutive(saleExecutiveRes.data.filter((x) => x.IsSalesExecutive == true));

        if (props.item.SID == 0) {
            setDataItem({
                ...dataItem,
                Channel : utility.getDefaultItem(channelRes.data),
                Currency: utility.getDefaultItem(currencyRes.data),
                FinancialYear: utility.getDefaultItem(financialYearRes.data)
            })
        }
    }

    const loadEdit = () => {
        let editData = {
            ...props.item,
            SID: props.item.SID,
            Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
            FinancialYear: financialYearRef.current.find((x) => x._id == props.item.FinancialYear),
            Target: props.item.Target,
            Currency: currencyRef.current.find((x) => x._id == props.item.Currency),
            Channel: channelRef.current.find((x) => x._id == props.item.Channel),
            SalesExecutive: props.item.SalesExecutive,
        }
        setQuarter({
            ...defaultQuarter,
            [props.item.Quarter]: true
        })
        setTargetDetails(props.item.SalesExecutive)
        setDataItem(editData)
    }

    const onChange = (e) => {
        if (e.target.name == 'SalesExecutive') {
            setTargetDetails([]);
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
        else if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        }else{
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const onCheckQuarter = (e) => {
        setQuarter({ ...defaultQuarter, [e.target.name]: e.target.checked })
    }

    const handleSubmit = async () => {

        if (!isValid()) return;

        let localData = {
            SID: dataItem.SID ?? 0,
            Description: dataItem.Description,
            FinancialYear: dataItem.FinancialYear._id,
            Quarter: parseInt(Object.keys(quarter).filter((x) => quarter[x])),
            Target: parseInt(dataItem.Target),
            Currency: dataItem.Currency._id,
            Channel: dataItem.Channel._id,
            SalesExecutive: targetDetails.map((x) => {
                return {
                    _id: x._id,
                    name: x.name,
                    Target: x.Target
                }
            }),
            Archive: dataItem.Archive ?? false,
            checkDuplicate: true,
            query: [["Description", "=", dataItem.Description]]
        }

        let res = await API.saveData(ENTITYNAME.SalesTarget, localData);
        if (res.success) {
            props.cancelEdit();
            props.refresh();
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const onUpdateTargetDetails = () => {
        if (!dataItem.SalesExecutive || dataItem.SalesExecutive.length == 0) {
            toast.error(`${'Please Select Sales Executive first'}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let targetDetails = dataItem.SalesExecutive.map((x) => {
            return {
                _id: x._id,
                name: x.name,
                Target: dataItem.Target / dataItem.SalesExecutive.length
            }
        })

        setTargetDetails(targetDetails);

    }

    const EDIT_FIELD = 'inEdit';

    const enterEdit = (dataItem, field) => {
        const newData = targetDetails.map((item) => ({
            ...item,
            [EDIT_FIELD]: item._id === dataItem._id ? field : undefined,
        }));
        setTargetDetails(newData);
    };
    const exitEdit = () => {
        const newData = targetDetails.map((item) => ({
            ...item,
            [EDIT_FIELD]: undefined,
        }));
        setTargetDetails(newData);
    };

    const customCellRender = (td, props) => <CellRender originalProps={props} td={td} enterEdit={enterEdit} editField={EDIT_FIELD} />;

    const customRowRender = (tr, props) => <RowRender originalProps={props} tr={tr} exitEdit={exitEdit} editField={EDIT_FIELD} />;

    const itemChange = (event) => {
        let copyTargetDetails = [...targetDetails]
        let field = event.field || "";
        event.dataItem[field] = event.value;
        let newData = copyTargetDetails.map((item) => {
            if (item._id === event.dataItem._id) {
                item[field] = event.value;
            }
            return item;
        });
        setTargetDetails(newData);
        setChanges(true);
    };

    return (
        <BossDialog
            title={props.item.Description ? props.item.Description : `${lang.sales_target_dialog_header}`}
            onClose={props.cancelEdit}
            width={"860px"}
        >
            <div className="row">
                <div className="col-12">
                    <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                    <div className="row mt-2">
                        <div className="col-12 form-group">
                            <label htmlFor="TabView">{lang.description_label} *</label>
                            <input type="text" className="form-control form-control-sm" name="Description" onChange={onChange} value={dataItem.Description} />
                        </div>

                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label htmlFor="TabView">{lang.financial_year_sub_menu} *</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={financialYear}
                                name="FinancialYear"
                                textField="Description"
                                dataItemKey="_id"
                                value={dataItem.FinancialYear}
                                onChange={onChange}
                            />
                        </div>
                        <div className="col-6" style={{ padding: '0px' }}>
                            <label htmlFor="TabView" style={{ padding: '0px 15px' }}>{lang.quarter_label} *</label>
                            <div className="row" style={{ margin: '0px 15px', padding: '6px 15px', border: '1px solid #D8D9DA', borderRadius: '5px' }}>
                                <div className="col-2" style={{ padding: '0px 2px' }}>
                                    <input type="checkbox" name={'1'} value={quarter['1']} checked={quarter['1']} onChange={onCheckQuarter} />
                                    <label htmlFor="TabView" className="pl-2">{'1st'}</label>
                                </div>
                                <div className="col-2" style={{ padding: '0px 2px' }}>
                                    <input type="checkbox" name={'2'} value={quarter['2']} checked={quarter['2']} onChange={onCheckQuarter} />
                                    <label htmlFor="TabView" className="pl-2">{'2nd'}</label>
                                </div>
                                <div className="col-2" style={{ padding: '0px 2px' }}>
                                    <input type="checkbox" name={'3'} value={quarter['3']} checked={quarter['3']} onChange={onCheckQuarter} />
                                    <label htmlFor="TabView" className="pl-2">{'3rd'}</label>
                                </div>
                                <div className="col-2" style={{ padding: '0px 2px' }}>
                                    <input type="checkbox" name={'4'} value={quarter['4']} checked={quarter['4']} onChange={onCheckQuarter} />
                                    <label htmlFor="TabView" className="pl-2">{'4th'}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6">
                            <label htmlFor="TabView">{lang.target_label} *</label>
                            <div className="form-group">
                                <input className="form-control form-control-sm" type="number" name="Target" onChange={onChange} value={dataItem.Target} min={0} />
                            </div>
                        </div>
                        <div className="col-6 form-group">
                            <label htmlFor="TabView">{lang.currency_label} *</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={currency}
                                name="Currency"
                                textField="Description"
                                dataItemKey="_id"
                                value={dataItem.Currency}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-6 form-group">
                            <label htmlFor="TabView">{lang.channel_label} *</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={channel}
                                name="Channel"
                                textField="FullChannelName"
                                dataItemKey="_id"
                                value={dataItem.Channel}
                                onChange={onChange}
                            />
                        </div>
                        <div className="col-5 form-group">
                            <label htmlFor="TabView">{lang.sales_executive_label} *</label>
                            <MultiSelect
                                style={{ backgroundColor: "white" }}
                                data={saleExecutive}
                                name="SalesExecutive"
                                textField="name"
                                dataItemKey="_id"
                                value={dataItem.SalesExecutive}
                                onChange={onChange}
                            />
                        </div>

                        <div className="col-1" style={{ padding: '0px' }}>
                            <button type="button" title='Update Target Details' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                                style={{ height: "30px", margin: '18px 10px 0px 0px' }} onClick={onUpdateTargetDetails}>
                                {lang.update_button_text}
                            </button>
                        </div>
                    </div>
                    <div className="row mt-2">
                        <div className="col-12">
                            <input type="checkbox" name="Archive" checked={dataItem.Archive} onChange={onChange} />
                            <label className="pl-1" htmlFor="TabView">{lang.archive}</label>
                        </div>
                    </div>
                </div>
                <div className="col-12">




                    <Box>
                        <TabContext value={tab}>
                            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                                <TabList variant="scrollable"
                                    onChange={(event, newValue) => {
                                        setTab(`${newValue}`);
                                    }}
                                    aria-label="lab API tabs example"
                                >
                                    <Tab label={lang.target_details_label} value="1" />

                                </TabList>
                            </Box>
                            <TabPanel value={"1"}>
                                <p className="text-muted text-right" style={{ fontSize: "10px", margin: '0px' }}>{`${lang.total_target_label} ${dataItem.Target} | ${lang.distributed_target_label} ${utility.getSumOfAColumnValueFromList(targetDetails, 'Target')}`}</p>
                                <Grid data={targetDetails} style={{ height: "20vh" }} dataItemKey={'_id'} onItemChange={itemChange} cellRender={customCellRender} rowRender={customRowRender} editField={EDIT_FIELD}  >
                                    <GridColumn field={"name"} title={"Name"} editable={false} />
                                    <GridColumn field={"Target"} title={"Target"} editor="numeric" />
                                </Grid>
                            </TabPanel>
                        </TabContext>
                    </Box>
                </div>
            </div>
        </BossDialog>
    )
}