import React, { memo, useEffect, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as API from '../../../framework/API/api'
import { DateOnlyCell } from "../../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { ArrayCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { HrefCell } from "../../../framework/forms/helpercomponents/CustomGridCells/HrefCell";
import { TimeCell } from "../../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { LookupCell } from "../../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import { ImageCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { CheckboxCell } from "../../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY } from "../../../framework/constant/constant";
import { utility } from "../../../framework/utility/utilityProvider";
import { useTranslation } from "../../../locale/useTranslation";


const MediaByCategoryCollection = (props) => {
    const gridData = props.data;
    const selectedCategory = props.selectedCategory;
    const lang = useTranslation();

    const [dataColumns, setDataColumns] = useState([]);
    const [tileView, setTileView] = useState(false);

    useEffect(() => {
        getColumns();
    }, []);

    const getColumns = async () => {
        let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
        let columns = await API.getFormData(ENTITYNAME.MediaByCategory, currentUserId);
        console.log(columns);
        setDataColumns(columns.sort((a, b) => a.sequence - b.sequence));
    }

    //working on the basis of title for now client side
    const onFilterSearchChange = (e) => {
        props.setSearch(e.target.value);
    };


    const MyDateCell = (props) => <DateOnlyCell {...props} />;
    const MyArrayCell = (props) => <ArrayCell {...props} />;
    const MyHrefCell = (props) => <HrefCell {...props} />;

    const MyTimeCell = (props) => <TimeCell {...props} />;

    const MyDatalookupCell = (props) => (
        <LookupCell {...props} dataColumns={dataColumns} />
    );

    const MyImageCell = (props) => <ImageCell {...props} />;

    const MyCheckBoxCell = (props) => <CheckboxCell {...props} />;

    return <div style={{ overflowX: "hidden", padding: '5px 10px' }}>


        <div className="row">
            <div className="col-8">
                <h1 className="page-title txt-color-bludeDark" style={{ margin: '10px 5px' }}>
                    <i className="fa fa-table marlr" style={{ fontSize: "20px", color: "GrayText" }} ></i>
                    <span>{lang.category_label} : {selectedCategory?.Name ?? `${lang.select_category_label}`}</span>
                </h1>
            </div>
            <div className="col-4 d-flex align-items-center justify-content-end">
                <div className="pointer p-1" onClick={() => setTileView(!tileView)} title={tileView ? `${lang.grid_view_label}` : `${lang.tile_view_label}`}><i className={`fas ${tileView ? 'fa-table-cells' : 'fa-table-list'} fa-xl`} /></div>
            </div>
        </div>
        {/* implementing seaech here */}
        <div className="flex-container">
            <div className="flex-container-reverse flex-item-auto">
                <div className="input-group input-group martb" style={{ width: '30%' }} >
                    <input type="search" className="form-control shadow-inset-2 pt-0 pb-0" id="search" aria-label="type 2 or more letters"
                        placeholder={lang.search_button_text} onChange={onFilterSearchChange} />
                    <button style={{ border: "0px", backgroundColor: "white", height: "40px" }} >
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <i style={{ margin: "5px 0px" }} className="fa fa-search" />
                            </span>
                        </div>
                    </button>
                </div>
            </div>

        </div>

        {tileView ? <div className="row g-4">
            {
                gridData.map((item, index) => {
                    console.log(item);
                    return <div className="col-xl-3 col-lg-4 col-md-6 col-sm-12">
                        <div className="card" key={index}
                            title={item.Title + "\n" +
                                utility.convertMilisecondsToStringWithFrames(item.Duration) + "\n\n" +
                                item.Description + "\n\n" +
                                item.MediaCategory.Description + "\n" +
                                item.Genres.map(a => a.Description)
                            }>
                            <img src={item.ImageUrl} style={{ height: "150px", objectFit: "cover" }} className="card-img-top" alt={item.Title} />
                            <p className="card-title" style={{ fontSize: "20px" }}>{utility.subString(item.Title, 15)}</p>
                            <div className="card-subtitle mb-2 text-muted" style={{ fontSize: "small" }}>
                                <div className="row" style={{ padding: "5px 12px" }}>
                                    <div className="col">
                                        {item.MediaCategory.Description}
                                    </div>
                                    <div className="col d-flex  justify-content-end">
                                        {item.AssetId}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                })
            }
        </div>
            : <Grid
                style={{
                    height: "100%",
                }}
                data={gridData}
            >

                {/* Rows Loop */}
                {dataColumns.map((column, index) => {
                    if (column.type === COLUMNSTYPE.date) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={MyDateCell}
                                title={column.label}
                                width={column.width ?? 200}
                            />
                        );
                        // }else if (column.name === COLUMNSTYPE.time) {
                    } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={MyTimeCell}
                                title={column.label}
                                width={column.width ?? 200}
                            />
                        );
                    } else if (column.name === "SID") {
                        return;
                        //we are not returning any value for SID column
                    } else if (column.type === COLUMNSTYPE.image) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={MyImageCell}
                                title={column.label}
                                width={column.width ?? 100}
                            />
                        );
                    } else if (column.type === COLUMNSTYPE.select) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={MyDatalookupCell}
                                title={column.label}
                                width={column.width ?? 200}
                            />
                        );
                    }
                    else if (column.type === COLUMNSTYPE.checkbox) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={MyCheckBoxCell}
                                title={column.label}
                                width={column.width ?? 80}
                            />
                        );
                    }
                    else if (column.type === COLUMNSTYPE.array) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={MyArrayCell}
                                title={column.label}
                                width={column.width ?? 200}
                                format={column.format ?? "Description"} //display item in object
                            />
                        );
                    }
                    else if (column.type === COLUMNSTYPE.href) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={MyHrefCell}
                                title={column.label}
                                width={column.width ?? 200}
                                format={column.format}
                            />
                        );
                    } else {
                        return (
                            column.type !== COLUMNSTYPE.hidden && (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            )
                        );
                    }
                })}
            </Grid>
        }

    </div>
}

export default memo(MediaByCategoryCollection);