/* eslint-disable */
import React from 'react'
import MenuButton from '../../../framework/forms/helpercomponents/buttons/MenuButton';
import { useTranslation } from '../../../locale/useTranslation';

const lang=useTranslation();
const menuModel = (props) => {
    const menu = [
      {
        label: `${lang.options_actionbutton_menutext}`,
        items: [
          { label: `${lang.edit_actionbutton_menutext}`, icon: 'fa-solid fa-pen fa-lg', command: () => props.onEdit(props.dataItem) },
        //   { label: inEdit ? 'Save Changed Spots' : 'Change Spots', icon: 'fa-regular fa-pen-to-square fa-lg', command: () => inEdit ? props.onUpdate(props.dataItem) :  props.onEdit(props.dataItem) }
          { label: `${lang.delete_actionbutton_menutext}`, icon: 'fa-regular fa-trash-can fa-lg', command: () => props.onRemove(props.dataItem)}
        ]
      }
    ]
    return menu;
}

const UpdateLineNumberMenuCommandCell = (props) => {
    return <td
    style={props.style}
    className={props.className}
    colSpan={props.colSpan}
    role={"gridcell"}>
    <div className="row">
      <div className="col-1">
        <MenuButton title={lang.options_actionbutton_menutext} props={props} collectionMenuModel={menuModel}/>
      </div>
    </div>
  </td>;
}

export default UpdateLineNumberMenuCommandCell