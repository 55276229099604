import { TreeList, TreeListDraggableRow } from '@progress/kendo-react-treelist';
import React, { cloneElement, useEffect, useRef, useState } from 'react'
import { SchedulingHelper } from '../Scheduling/helper/SchedulingHelper';
import { orderBy } from '@progress/kendo-data-query';
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY, MEDIACATEGORIES, SCHEDULETYPE } from '../../framework/constant/constant';
import * as API from "../../framework/API/api"
import { utility } from '../../framework/utility/utilityProvider';
import { TimeCell, TimeHyperLinkCell } from '../../framework/forms/helpercomponents/CustomGridCells/TimeCell';
import { DateCell, DateOnlyCell } from '../../framework/forms/helpercomponents/CustomGridCells/DateCell';
import ArchiveIconCell from '../../components/ArchiveIconCell';
import { ImageCell } from '../../framework/forms/helpercomponents/CustomGridCells/ImageCell';
import RefreshButton from '../../framework/forms/helpercomponents/buttons/RefreshButton';
import { useTranslation } from '../../locale/useTranslation';
import { Pager } from "@progress/kendo-react-data-tools";
import { Grid, GridCell, GridColumn as Column } from '@progress/kendo-react-grid';
import { myTimeCell } from '../../framework/forms/helpercomponents/inline grid cell/MyTimeCell';
import { WrappedTextCell } from '../../framework/forms/helpercomponents/CustomGridCells/WrappedTextCell';
import PreferenceRoundButton from '../../framework/forms/helpercomponents/buttons/PreferenceRoundButton';
import { LookupCell } from '../../framework/forms/helpercomponents/CustomGridCells/LookupCell';
import { ArrayCell } from '../../framework/forms/helpercomponents/CustomGridCells/ArrayCell';
import { UserPreference } from '../userPreference/UserPreference';

const DATA_ITEM_KEY = "_id";
const SELECTED_FIELD = "selected";
const EXPAND_FIELD = "expanded";
const SUB_ITEM_FIELD = "children";
const SELECTED_HEADER = "isHeaderSelected";
const ROW_HEIGHT = 40;

export const TreeListPager = (props) => {
    return (
        <Pager
            {...props}
            previousNext={true}
            buttonCount={8}

        // Configure the Pager props here.
        // See example: https://www.telerik.com/kendo-react-ui/components/datatools/pager/
        />
    );
};

const PlanningSeriesTab = (props) => {

    const lang = useTranslation();
    const treeListRef = useRef();
    const [treeListHeight, setTreeListHeight] = useState("500px");

    const seriesDataRef = useRef([])
    const [seriesData, setSeriesData] = useState([]);
    const [total, setTotal] = useState(1);
    const dataRefSetter = (data) => {
        console.log(data)
        seriesDataRef.current = data;
        setSeriesData(data)
    }

    const [isDataLoading, setIsDataLoading] = useState(false);

    const [selectedState, setSelectedState] = useState([]); //children multiple selection grid
    const selectedStateRef = useRef(selectedState);

    const [page, setPage] = useState({
        skip: 0,
        take: 50,
    });

    useEffect(() => {

        loadData("", []);

    }, [])

    useEffect(() => {
        if (isDataLoading == false) {
            treeListRef?.current?.element?.addEventListener('scroll', onTreeViewscroll);
        }

        return () => {
            treeListRef?.current?.element?.removeEventListener('scroll', onTreeViewscroll);
        }
    }, [isDataLoading]);

    const onTreeViewscroll = (e) => {
        scrollPosition.current = e.target.scrollTop;
    }

    const selectedStateRefSetter = (data) => {
        selectedStateRef.current = data;
        setSelectedState(data);
        //seeting header data
        // setSelectedHeadersDataItems(data.filter(x => x.Type == SCHEDULETYPE.Header));
        props.setSelectedState(data);
    };
    const [searchText, setSearchText] = useState([]);
    const [showPreferences, setShowPreferences] = useState(false);
    const [preferenceState, setPreferenceState] = useState({});
    const [dataColumns, setDataColumns] = useState([]);

    const scheduleScrollRef = useRef();
    const scrollPosition = useRef(0);

    const loadData = async (searchText, filters, pagination = { per_page: page.take, current_page: 1, orderby: "SID", direction: "asc" }) => {
        dataRefSetter([]);
        setIsDataLoading(true);

        let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
        let columns = await API.getFormData('planningserieslibrary', currentUserId);
        let finalSort = pagination.direction ? { [pagination.orderby ?? 'SID']: pagination.direction == 'asc' ? 1 : -1 } : { SID: 1 };
        let json = await API.getEntitiesWithSearch(
            ENTITYNAME.Season,
            { query: [], page: pagination.current_page, perPage: pagination.per_page, archive: false, sort: finalSort },
            columns,
            searchText,
            filters
        );
        console.log(json)
        if (json.success) {

            setIsDataLoading(false)
            dataRefSetter(json.data);
            setTotal(json?.pagination?.total);
            setDataColumns(columns);
        }

    }

    const pageChange = async (e) => {

        console.log(e)

        setIsDataLoading(true);
        setPage({
            skip: e.page.skip,
            take: e.page.take,
        });

        let current_page = e.page.skip / e.page.take + 1;

        await loadData(searchText, [], { per_page: e.page.take, current_page: current_page, orderby: "SID", direction: "asc" });

    };

    const onRefresh = () => {
        loadData("", [])
        setPage({
            skip: 0,
            take: 50
        })
    }

    const onTreeExpandChange = async (event) => {

        console.log(event)
        const expanded = event.value;
        const tree = [...seriesDataRef.current];
        const itemId = event.dataItem._id;
        let children = event.dataItem.children ? event.dataItem.children : [];

        let pagination = { per_page: 1000, current_page: 1, orderby: "EpisodeNumber", direction: "asc" }

        let filters = {
            Season_id: event.dataItem._id
        }

        //CALL API
        if (event.dataItem.children == undefined) {
            console.log(filters, pagination, props.channels);
            const json = await API.loadPlanningSeriesEpisodes('', filters, pagination, props.channels);
            if (json.success) {
                children = json.data
            }
            console.log(json)
        }

        const updatedTree = tree.map((item) => {
            if (item._id === itemId) {
                return {
                    ...item,
                    children: children,
                    [EXPAND_FIELD]: expanded,
                };
            } else {
                return item;
            }
        });

        dataRefSetter(updatedTree);
    };

    const onFilterSearchChange = (e) => {
        setSearchText(() => e.target.value);

        if (e.target.value == '') {
            loadData('', []);
        }
    };

    const onSearchClick = () => {

        if (searchText != '') {
            loadData(searchText, []);

        } else {
            loadData('', []);
        }

    }

    const onSearchEnter = (event) => {
        if (event.key === "Enter") {
            event.preventDefault();
            onSearchClick();
        }
    }

    const gridDetail = ({ dataItem }) => {
        if (dataItem?.children?.length > 0) {
            return <Grid
                style={{ height: "500px", overflowY: "scroll" }}
                className='no-headers-grid .k-grid-header'
                data={dataItem.children}
                rowRender={seriesLibraryRowRender}
            >
                {dataColumns.map((column, index) => {
                    if (column.type === COLUMNSTYPE.date) {
                        return (
                            <Column
                                key={index}
                                field={column.name}
                                cell={DateCell}
                                title={column.label}
                                width={140}
                                sortable={true}
                            />
                        );
                    } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                        return (
                            <Column
                                key={index}
                                field={column.name}
                                cell={TimeCell}
                                title={column.label}
                                width={140}
                                sortable={true}
                            />
                        );
                    } else if (column.name === "SID") {
                        return;
                    } else if (column.type === COLUMNSTYPE.image) {
                        return (
                            <Column
                                key={index}
                                field={column.name}
                                cell={ImageCell}
                                title={column.label}
                                width={column.width ?? 200}
                                sortable={false}
                            />
                        );
                    } else if (column.type === COLUMNSTYPE.select) {
                        return (
                            <Column
                                key={index}
                                field={column.name}
                                cell={MyDatalookupCell}
                                title={column.label}
                                width={140}
                                sortable={false}
                            />
                        );
                    }
                    else if (column.type === COLUMNSTYPE.array) {
                        return (
                            <Column
                                key={index}
                                field={column.name}
                                cell={ArrayCell}
                                title={column.label}
                                width={140}
                                format={column.format ?? "Description"} //display item in object
                                sortable={false}
                            />
                        );
                    } else {
                        return (
                            column.type !== COLUMNSTYPE.hidden && (
                                <Column
                                    key={index}
                                    field={column.name}
                                    title={column.label}
                                    width={140}
                                    sortable={true}
                                />
                            )
                        );
                    }
                })}
            </Grid>
        } else {
            return <div> No Data </div>
        }
    }

    const MyDatalookupCell = (props) => (
        <LookupCell {...props} dataColumns={dataColumns} />
    );

    const GridRowRender = (tr, props) => {

        return cloneElement(tr, { style: { ...props?.dataItem?.style } }, tr.props.children);
    }
    const seriesLibraryRowRender = (tr, props) => {
        const trProps = {
            draggable: true,
            onDragStart: (e) => {
                // to revert change width height back to normal as commented  zindex uncomment, id =drag-ghost and remove drag-ghost-del from ondrag end too
                // also go to planning performance.js and seee instructions there ln 816
                var elem = document.createElement("div");
                let duration = props.dataItem.Duration?.toString();
                elem.id = "drag-ghost-del";
                elem.textNode = "Dragging";
                elem.style.position = "fixed";
                elem.style.width = "0px";//150px
                elem.style.height = "0px";//200px
                elem.style.backgroundColor = "#3F497F";
                elem.style.whiteSpace = "pre-line";
                elem.textContent =
                    props.dataItem.Title + "\r\n" + props.dataItem.AssetId;
                elem.style.opacity = 0;
                // elem.style.zIndex = 10000;
                elem.style.transition = "0";//0.23
                elem.title = duration;
                document.body.appendChild(elem);
                e.dataTransfer.setDragImage(elem, 0, 0);
                var dataTransfer = {
                    dataItem: props.dataItem,
                    filterChannels: props.channels,
                };
                e.dataTransfer.clearData();
                e.dataTransfer.setData("text/plain", JSON.stringify(dataTransfer));
            },
            onDragOver: (e) => { },
            onDragEnd: (e) => {
                var elem = document.getElementById("drag-ghost");
                var elem2 = document.getElementById("drag-ghost-del");
                if (elem) {
                    document.body.removeChild(elem);
                }
                document.body.removeChild(elem2);
            },
        };
        return cloneElement(tr, { ...trProps, style: { ...props?.dataItem?.style } }, tr.props.children);
    }

    const onCloseUserPrefence = () => {
        loadData(searchText, []);
        setShowPreferences(!showPreferences);
    }

    return (<>
        <div style={{}}>

            <h1 className="page-title" style={{ marginBottom: "-5px" }}>
                <i
                    className="fa fa-table marlr"
                    style={{ fontSize: "20px", color: "GrayText" }}
                ></i>
                <span>Series Library</span>
            </h1>

            <div className="flex-container pr-2 pl-2">
                <div className="flex-container-reverse flex-item-auto">
                    <PreferenceRoundButton onClick={() => { setShowPreferences(!showPreferences) }} />
                    <RefreshButton onClick={onRefresh} />
                </div>
            </div>
            <div className="flex-container">

                <div className="input-group input-group martb marlr" style={{ zIndex: 1 }}>
                    <input
                        type="search"
                        className="form-control shadow-inset-2 pt-0 pb-0"
                        id="search"
                        value={searchText}
                        aria-label="type 2 or more letters"
                        placeholder={lang.search_button_text}
                        onKeyDown={onSearchEnter}
                        onChange={onFilterSearchChange}
                    />
                    <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                            </span>
                        </div>
                    </button>

                </div>

            </div>

            <div>
                {isDataLoading ?
                    <div className="ml-2 mt-2">
                        <i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i>
                    </div> :
                    <div style={{
                        height: treeListHeight,
                        overflow: "auto",
                        // width: "100%",
                        userSelect: "none",
                    }}>
                        <Grid
                            style={{ position: "absolute", left: 0, top: "180px", right: 0, bottom: 0 }}
                            data={orderBy(seriesData.map(parent => {
                                parent[SELECTED_FIELD] = selectedState.some(
                                    (dataItem) => dataItem._id == parent._id
                                );
                                if (parent?.children?.length > 0) {
                                    parent.children = orderBy(parent.children.map((item) => {
                                        item[SELECTED_FIELD] = selectedState.some(
                                            (dataItem) => dataItem._id == item._id
                                        );
                                        if (item?.children?.length > 0) {
                                            item.children = orderBy(item.children.map((bc) => {
                                                bc[SELECTED_FIELD] = selectedState.some(
                                                    (dataItem) => dataItem._id == bc._id
                                                );
                                                return bc
                                            }), [
                                                {
                                                    field: "SID",
                                                    dir: "asc",
                                                }
                                            ])
                                        }
                                        return item
                                    },), [
                                        {
                                            field: "SID",
                                            dir: "asc",
                                        }
                                    ])
                                }
                                return parent;
                            }), [
                                {
                                    field: "SID",
                                    dir: "asc",
                                },
                            ])}
                            detail={gridDetail}
                            expandField={EXPAND_FIELD}
                            onExpandChange={onTreeExpandChange}
                            rowRender={GridRowRender}
                            take={page.take}
                            skip={page.skip}
                            total={total}
                            pageable={true}
                            resizable={true}
                            onPageChange={pageChange}
                        >
                            {dataColumns.map((column, index) => {
                                return (
                                    column.type !== COLUMNSTYPE.hidden && (
                                        <Column
                                            key={index}
                                            field={column.name}
                                            title={column.label}
                                            width={140}
                                            sortable={true}
                                        />
                                    )
                                );
                            })}
                        </Grid>

                    </div>}

            </div>


        </div>
        {showPreferences && <UserPreference entityName={'planningserieslibrary'} data={preferenceState} setState={() => { }} handleClose={onCloseUserPrefence} />}
    </>
    );
}

export default PlanningSeriesTab