/* eslint-disable */
import { LOGEVENT, MODULE } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";

export const SalesHelper = {};

SalesHelper.getBookingLogSchema = (dataItem) => {
    delete dataItem.Spots;
    // filter data key is isScheduled 
    let filterValue = [] ; 
    
    Object.keys(dataItem).forEach((key) => { 
    
        if(dataItem[key] && JSON.stringify(dataItem[key]).includes('isScheduled'))
        {
            filterValue.push(key);
        }
        
    });

    console.log(filterValue);

    filterValue.forEach((key) => {
         dataItem[key] = dataItem[key].daySpots;
    });

    console.log(dataItem);

    return dataItem;

}

SalesHelper.getSpotsData = (dataItem , event , message = "Show Spots") => {

    delete dataItem?.campaign;
    delete dataItem?.TimeRange;

    let logCapture = { event: event, module: MODULE.SHOW_SPOTS, data: dataItem, message: message, _id: dataItem?.Campaign_Id + '_' + dataItem?._id };

    console.log(logCapture);

    return logCapture;
    

}

SalesHelper.saveBookingLogFromSchedule = (draggedData , destinationDataItem) => {
    try {
        if (draggedData.length === 0) return;

        draggedData.forEach((item) => {
            let campaignId = item?.campaign?._id ?? "";
            delete item?.campaign;
            let logCapture = { event: LOGEVENT.SPOTS_SCHEDULED_ADD, module: MODULE.SHOW_SPOTS, data: {...item, NominalOnAir : destinationDataItem.ScheduleDate }, message: "", _id: campaignId + '_' + item?._id };
            console.log(logCapture);
            API.SaveLogs(logCapture);
        });
    } catch (e) {
        console.log(e);
    }
};

SalesHelper.removeBookingLogFromSchedule = (draggedData) => {
    try {
        if (draggedData.length === 0) return;

        draggedData.forEach((item) => {
            let campaignId = item?.Campaign?._id ?? "";
            delete item?.Campaign;
            delete item?.booking;
            delete item?.Style;
            let logCapture = { event: LOGEVENT.SPOTS_SCHEDULED_REMOVE, module: MODULE.SHOW_SPOTS, data: {...item, NominalOnAir: item.ScheduleDate}, message: "", _id: campaignId + '_' + item?.Booking_id };
            console.log(logCapture);
            API.SaveLogs(logCapture);
        });
    } catch (e) {
        console.log(e);
    }
};

SalesHelper.getCampaignLog = (dataItem) => {}
