import React, { useState } from "react";
import * as API from "../../framework/API/api";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { Grid, GridColumn as Column, GridNoRecords, } from "@progress/kendo-react-grid";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { FILEURL, INGESTION_ACTIVITY, LOCALSTORAGE_KEY } from "../../framework/constant/constant";
import { utility } from "../../framework/utility/utilityProvider";
import OttFilterTemplate from "../../framework/OTT/OttFilterTemplate";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useEffect } from "react";
import { EnumCell } from "../../framework/forms/helpercomponents/CustomGridCells/EnumCell";
import { ProviderCell } from "../../framework/forms/helpercomponents/CustomGridCells/ProviderCell";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import moment from "moment";

const IngestionReport = (props) => {
  const lang = useTranslation();
  const today = moment();

  const [gridData, setGridData] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(lang.no_record_found_error_message);
  const [isMinified, setIsMinified] = useState(utility.getValue(LOCALSTORAGE_KEY.isMenuMified));
  const [enableFilter, setEnableFilter] = useState(false);
  const [filterData, setFilterData] = useState(props?.item?.Filter ?? undefined);
  const [dataItem, setDataItem] = useState({ FromDate: moment().subtract(7, 'days').format('YYYY-MM-DD'), ToDate: today.format('YYYY-MM-DD'), Activity: { _id: INGESTION_ACTIVITY.All, Description: utility.getKeyByValue(INGESTION_ACTIVITY, INGESTION_ACTIVITY.All) } })
  const [activitys, setActivitys] = useState([])

  useEffect(() => {
    loadCombo()
  }, [])

  const loadCombo = async () => {
    let activityRes = Object.keys(INGESTION_ACTIVITY).map((x) => {
      return {
        _id: INGESTION_ACTIVITY[x],
        Description: x
      }
    })
    setActivitys(activityRes)
  }

  const onChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value })
  };

  const toGetFilteredData = (data) => {
    console.log(data);
    
    setEnableFilter(false);
    setFilterData(data);
  };

  const clearFilter = () => {
    setFilterData(undefined);
    setGridData([]);
    setLoadingStatus(lang.no_record_found_error_message);
  };



  const isValid = () => {
    if (dataItem.FromDate == undefined || dataItem.FromDate == "") {
      toast.error(lang.please_enter_from_date_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (dataItem.ToDate == undefined || dataItem.ToDate == "") {
      toast.error(lang.please_enter_to_date_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    return true;
  };

  const loadData = async () => {

    if (!isValid()) return;

    setLoadingStatus(lang.data_loading_error_message);
    let filterQuery = [];
    console.log(filterData);
    
    if (filterData && Object.keys(filterData).length != 0) {
      let assetTypeFilter = Object.keys(filterData?.AssetType)?.length > 0 && filterData?.AssetType?._id != 0 ? [['OttAssetTypeSID', '=', filterData?.AssetType?._id]] : [];
      let providerFilter = Object.keys(filterData?.Provider)?.length > 0 && filterData?.Provider?._id != 0 ? [['Provider', '=', filterData?.Provider?._id]] : [];
      let accessLevelFilter = filterData?.AccessLevel && Object.keys(filterData?.AccessLevel).length != 0 ? [['accessLevel', '=', filterData?.AccessLevel?._id]] : [];
      let vodTypeFilter = Object.keys(filterData?.VodType)?.length > 0 && filterData?.VodType?._id != 0 ? [['VodTypes._id', '=', filterData?.VodType?._id]] : [];
      let mediaCategorysFilter = filterData?.MediaCategory && filterData?.MediaCategory?.length > 0 ? [['Source.MediaCategory._id', "in", filterData?.MediaCategory?.map(x => x?._id)]] : [];
      let genresFilter = filterData?.Genres && filterData?.Genres?.length > 0 ? [['Source.Genres._id', "in", filterData?.Genres?.map(x => x?._id)]] : [];
      let releaseYearFilter = filterData?.ReleaseYear && filterData?.ReleaseYear != "" ? [["MetaData.ReleaseDate", ">=", new Date(`${filterData.ReleaseYear}-01-01`).getTime()], ["Source.ReleaseDate", "<=", new Date(`${filterData.ReleaseYear}-12-31`).getTime()]] : []
      let pgRatingsFilter = filterData?.PGRating && filterData?.PGRating?.length > 0 ? [['MetaData.PGRating._id', "in", filterData?.PGRating?.map(x => x?._id)]] : [];
      let languagesFilter = filterData?.Languages && filterData?.Languages?.length > 0 ? [['MetaData.Languages._id', "in", filterData?.Languages?.map(x => x?._id)]] : [];
      let CastAndCrews = filterData?.CastAndCrews && filterData?.CastAndCrews?.length > 0 ? [['MetaData.CastAndCrews._id', "in", filterData?.CastAndCrews?.map(x => x?._id)]] : [];
      let CensorRating = filterData?.CensorRating && filterData?.CensorRating?.length > 0 ? [['MetaData.CensorRating._id', "in" , filterData?.CensorRating?.map(x => x?._id)]] : [];
      // let createdFromFilter = filterData?.CreatedFrom && filterData?.CreatedFrom != "" ? [["addOn", ">=", new Date(`${filterData.CreatedFrom} 00:00:00`).getTime()]] : [];
      // let createdToFilter = filterData?.CreatedTo && filterData?.CreatedTo != "" ? [["addOn", "<=", new Date(`${filterData.CreatedTo} 23:59:59`).getTime()]] : [];

      filterQuery = [...assetTypeFilter, ...providerFilter, ...accessLevelFilter, ...vodTypeFilter, ...releaseYearFilter, ...mediaCategorysFilter, ...genresFilter, ...pgRatingsFilter, ...languagesFilter, ...CastAndCrews, ...CensorRating];
    }
    console.log(filterQuery);

    let archiveFilter = [];
    let fromDateFilter = [];
    let toDateFilter = [];

    if (dataItem.Activity._id == INGESTION_ACTIVITY.Archive) {
      archiveFilter = [["Archive", "=", true ]]
      fromDateFilter = dataItem?.FromDate ? [["modOn", ">=", new Date(`${dataItem?.FromDate} 00:00:00`).getTime()]] : [];
      toDateFilter = dataItem?.ToDate ? [["modOn", "<=", new Date(`${dataItem?.ToDate} 23:59:59`).getTime()]] : [];
      
    }
    else if (dataItem.Activity._id == INGESTION_ACTIVITY.Created) {
      fromDateFilter = dataItem?.FromDate ? [["addOn", ">=", new Date(`${dataItem?.FromDate} 00:00:00`).getTime()]] : [];
      toDateFilter = dataItem?.ToDate ? [["addOn", "<=", new Date(`${dataItem?.ToDate} 23:59:59`).getTime()]] : [];
    }
    else if (dataItem.Activity._id == INGESTION_ACTIVITY.Updated) {
      fromDateFilter = dataItem?.FromDate ? [["modOn", ">=", new Date(`${dataItem?.FromDate} 00:00:00`).getTime()]] : [];
      toDateFilter = dataItem?.ToDate ? [["modOn", "<=", new Date(`${dataItem?.ToDate} 23:59:59`).getTime()]] : [];
    }

    filterQuery = [...filterQuery, ...archiveFilter, ...fromDateFilter, ...toDateFilter]

    const res = await API.getIngestionReport(filterQuery);

    console.log(res.data);

    if (res.success) {
      if (res.data.length == 0) {
        setGridData([]);
        setLoadingStatus(`${lang.no_record_found_error_message}`);
        return;
      }
      setGridData(res.data);
      setLoadingStatus(`${lang.data_load_successfully_message}`);
      return;
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setGridData([]);
      setLoadingStatus(`${lang.no_record_found_error_message}`);
    }
  };

  const downloadExcel = async () => {
    if (gridData.length == 0) {
      toast.info(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
    console.log(gridData);

    var storeData = gridData.map((x) => {
      console.log(x);
      return {
          PotraitImageUrl: x.PotraitImageUrl ? x.PotraitImageUrl : "",
          LandscapeImageUrl: x.LandscapeImageUrl ? x.LandscapeImageUrl : "",
          Title: x.Title ? x.Title : "",
          AssetId: x.AssetId ? x.AssetId : "",
          Description: x.Description ? x.Description : "",
          OttAssetTypeSID: x.OttAssetTypeSID ? x.OttAssetTypeSID : "",
          Provider: x.Provider ? x.Provider : "",
          accessLevel: x.accessLevel ? x.accessLevel : 0,
          VodTypes: x.VodTypes?.Name ?? "",
          MediaCategory: x?.Source?.MediaCategory?.Description ?? "",
          Genres: x?.Source?.Genres?.map((y) => y.Description).join(",") ?? "",
          Duration: utility.convertMilisecondsToStringWithFrames(
            x?.Source?.Duration ?? 0
          ),
          Languages: x?.MetaData?.Languages?.length > 0 ? x.MetaData.Languages?.map((x) =>x.Description).join(",") : "",
          PGRating: x?.MetaData?.PGRating?.Description ?? "",
          CastAndCrew: x?.MetaData?.CastAndCrew?.length > 0 ? x?.MetaData?.CastAndCrew?.map((y) => y.Name).join(",") : "",
          CensorRating: x?.MetaData?.CensorRating?.Description ?? "",
          ProductionYear: x?.MetaData?.ProductionYear ?? "",
        };
      })
      
    console.log(storeData);
    var res = await API.exportCSV(storeData);

    if (res.success) {
      window.open(FILEURL.BASEURL + "downloadReport/" + res.data);
      toast.success(`${lang.successfully_download_success_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <>
      <h1 className="page-title txt-color-bludeDark">
        <i
          className="fa fa-table marlr"
          style={{ fontSize: "20px", color: "GrayText" }}
        ></i>
        <span>{lang.ingestion_report_page_breadcrumb}</span>
      </h1>
      <div className="row m-1">
        <div className="col-12">
          <div className="row">
            <div className="col-4">
              <div className="row">
                <div className="col-6">
                  <label htmlFor="TabView">{lang.from_date_label} *</label>
                  <input
                    className="form-control form-control-sm"
                    name="FromDate"
                    type="date"
                    value={dataItem.FromDate}
                    onChange={onChange}
                  />
                </div>
                <div className="col-6">
                  <label htmlFor="TabView">{lang.to_date_label} *</label>
                  <input
                    className="form-control form-control-sm"
                    type="date"
                    name="ToDate"
                    value={dataItem.ToDate}
                    onChange={onChange}
                  />
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="row mt-3">
                <div className="col-8" style={{ padding: "12px 0px 0px 16px" }}>
                  <div className="form-group" style={{ marginTop: "-28px" }}>
                    <label htmlFor="TabView">{lang.select_rule_label}</label>
                    <input
                      name="EnableFilter"
                      type="text"
                      className="form-control form-control-sm"
                      value={filterData?.Template?.Name ?? ""}
                      onChange={onChange}
                      disabled={true}
                    />
                  </div>
                </div>
                <div
                  className="col-4 d-flex"
                  style={{ padding: "0px 16px 0px 0px", marginTop: "-10px" }}>
                  <ActionButton
                    style={{ marginTop: "18px", height: "30px" }}
                    btnColor={"success"}
                    title={lang.select_button_tooltip}
                    name={"..."}
                    onClick={() => setEnableFilter(true)}
                  />
                  <ActionButton
                    style={{ marginTop: "18px", height: "30px" }}
                    btnColor={"danger"}
                    title={lang.clear_button_tooltip}
                    name={""}
                    icon={"xmark"}
                    onClick={clearFilter}
                  />
                </div>
              </div>
            </div>
            <div className="col-4">
              <div className="row">
                <div className="col-6">
                  <label>{lang.activity_dropdown_label} </label>
                  <DropDownList
                    style={{ backgroundColor: "white" }}
                    data={activitys}
                    name="Activity"
                    textField="Description"
                    dataItemKey="_id"
                    value={dataItem.Activity}
                    onChange={onChange}
                  />
                </div>
                <div className="col-6 mt-4" style={{ marginLeft: "-22px" }}>
                  <ActionButton
                    name={lang.show_button_text}
                    onClick={loadData}
                  />
                  <RoundButton
                    icon="download"
                    style={{ marginLeft: "12px" }}
                    onClick={() => downloadExcel()}
                    title={lang.download_button_tooltip}
                  />
                </div>
              </div>
            </div>
          </div>
          {enableFilter && (
            <OttFilterTemplate
              toGetFilterDataItem={toGetFilteredData}
              hideChannel={true}
              saveButtonName={lang.search_button_text}
              showDefaultButton={false}
              filterDataItem={filterData}
              setTemplate={setFilterData}
              onClose={setEnableFilter}
            />
          )}

          <div className="row mt-3">
            <div className="col-12">
              <Grid data={gridData} style={{ height: "65vh" }}>
                <GridNoRecords>{loadingStatus}</GridNoRecords>
                <Column field="PotraitImageUrl"
                  title={lang.potrait_image_url_column}
                  cell={ImageCell}
                  width={150}
                />
                <Column field="LandscapeImageUrl"
                  title={lang.landscape_image_url_column}
                  cell={ImageCell}
                  width={150}
                />
                <Column
                  field="Description"
                  title={lang.description_column}
                  width={170}
                />
                <Column
                  field="OttAssetTypeSID"
                  cell={EnumCell}
                  title={lang.asset_type_column}
                  width={150}
                />
                <Column
                  field="Provider"
                  cell={ProviderCell}
                  title={lang.provider_column}
                  width={100}
                />
                <Column
                  field="accessLevel"
                  cell={EnumCell}
                  title={lang.access_level_column}
                  width={170}
                />
                <Column
                  field="VodTypes.Name"
                  title={lang.vod_type_column}
                  width={150}
                />
                <Column
                  field="Source.MediaCategory.Description"
                  title={lang.media_category_type_column}
                  width={150}
                />
                <Column
                  field="Source.Duration"
                  title={lang.duration_column}
                  cell={TimeCell}
                  width={100}
                />
                <Column
                  field="Source.Genres"
                  format={"Description"}
                  title={lang.genres_column}
                  cell={ArrayCell}
                  width={150}
                />
                <Column
                  field="MetaData.Languages"
                  format={"Description"}
                  title={lang.language_column}
                  cell={ArrayCell}
                  width={150}
                />
                <Column
                  field="MetaData.PGRating.Description"
                  title={lang.pg_rating_column}
                  width={150}
                />
                <Column
                  field="MetaData.CastAndCrew"
                  format={"Name"}
                  title={lang.cast_and_crew_column}
                  cell={ArrayCell}
                  width={150}
                />
                <Column
                  field="MetaData.ProductionYear"
                  title={lang.production_year_column}
                  width={150}
                />
                <Column
                  field="MetaData.CensorRating.Description"
                  title={lang.censor_rating_column}
                  width={150}
                />
              </Grid>
            </div>
          </div>
        </div>
      </div>

      <div
        className={
          isMinified
            ? "scheduleFooter scheduleFooterMinified"
            : "scheduleFooter"
        }
      >
        <div className="flex-container-reverse flex-item-auto p-2">
          <div className="footerText">{loadingStatus}</div>
        </div>
      </div>
    </>
  );
};

export default IngestionReport;
