/* eslint-disable */
import React from 'react';
import logo from '../../../img/AutomateBLogoWhiteOrange.png';

const DigiSignSlideBar = ({ selectedValue, handleNavigationClick }) => {
    return (
        <div className="bg-dark text-white p-3 d-flex flex-column justify-content-between col-3">
            <div>
                <h2>Welcome to automateB Digital Signage Solution!</h2>
                <ul className="flex-column cursor-pointer">
                    {Object.keys(selectedValue).map(key => (
                        <li
                            key={key}
                            className="mb-2 d-flex align-items-center"
                            style={{ backgroundColor: selectedValue[key] ? '#23272b' : '', margin: '0px -14px' }}
                            onClick={() => handleNavigationClick(key)}
                        >
                            <input
                                className='ml-2'
                                type="checkbox"
                                name={key}
                                checked={selectedValue[key]}
                                value={selectedValue[key]}
                                readOnly
                            />
                            <a className="nav-link text-white">
                                {key.charAt(0).toUpperCase() + key.slice(1).replace(/([A-Z])/g, ' $1')}
                            </a>
                            <i className="fa fa-chevron-right ml-auto mt-2 mr-2" />
                        </li>
                    ))}
                </ul>
            </div>
            <div className="d-flex justify-content-center mb-3">
                <img src={logo} alt="Logo" style={{ width: '200px' }} />
            </div>
        </div>
    );
};

export default DigiSignSlideBar;
