
import { useTranslation } from "../../../../locale/useTranslation";

const DeleteRoundButton = (props) => {
    const lang = useTranslation();

    return <button disabled={props.disabled ?? false} title={props.title ?? lang.delete_button_tooltip} type="submit" onClick={props.onClick} style={{...props.style ?? {margin: "0px 02px", pointerEvents: props.disabled ? "none" : "auto", boxShadow: "none" }}} className="btn btn-default btn-circle btn-my">
        <i className="fa fa-trash fa-fw img-circle"></i>

    </button>
}

export default DeleteRoundButton;
