/* eslint-disable */
import React, {useEffect, useState } from "react";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CMSDashboard from "../platformLayout/tv/Dashboard/CMSDashboard";
import SalesDashboard from "./SalesDashboard";
import BookingStatusDashboard from "./BookingStatusDashboard";
import ContentDashboard from "./ContentDashboard";
import BookingSummary from "./BookingSummary";
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";
import { LOCALSTORAGE_KEY, MODULE } from "../../framework/constant/constant";
import MonetizationDashboard from "./MonetizationDashboard";
import WorkOrderDashboard from "./WorkOrderDashboard";
import * as API from '../../framework/API/api'
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

export const Dashboard = (props) => {
  const SELECTED_MENU = 'selectedMenu';
  const APP_VERSION = 'applicationversion';
  const lang = useTranslation();
  const tab = utility.getValue('DashboardTab');
  const navigate = useNavigate();

  const [tabNumber, setTabNumber] = React.useState(
    tab ? tab :
    utility.hasModuleRight(MODULE.DASHBOARD_SALES) ? "1" : 
    utility.hasModuleRight(MODULE.DASHBOARD_BOOKING_STATUS) ? "2" : 
    utility.hasModuleRight(MODULE.DASHBOARD_CONTENT) ? "3" : 
    utility.hasModuleRight(MODULE.DASHBOARD_CMS) ? "4" : 
    utility.hasModuleRight(MODULE.MONETIZATION) ? "5" : "6"
  ); 

  const [bookingSummaryDashboardData,setBookingSummaryDashboardData] = useState({
    totalCount : [],
    top10Agency : [],
    top10Client : [],
    topRevenueByLocation : [],
    topBookingByDealType : [],
    pendingVsOnAirBooking : [],
    totalMonthlyRevenue : []
})

useEffect(() => {
    versionCheck();
},[]);

const versionCheck = async() => {
    var versionRes = await API.getData(APP_VERSION,{ sort: { SID: -1 }});
    if(versionRes.success && versionRes.data.length > 0 && versionRes.data[0].Version !=  utility.getValue(LOCALSTORAGE_KEY.applicationVersion)?.Version){
        toast.info(`Application Version is updated`, {
            position: toast.POSITION.TOP_RIGHT
        });
        navigate('/'); 
        localStorage.clear();
        window.location.reload();
    }
}
  const handleTabChange = (event, newValue) => {
    setTabNumber(`${newValue}`);
    utility.setValue('DashboardTab',`${newValue}`);
  };
  utility.setValue(SELECTED_MENU,'Dashboard');


  return  <div className='row'>
            <div className='col-12'> 
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={tabNumber}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                {utility.hasModuleRight(MODULE.DASHBOARD_SALES) &&<Tab label={lang.booking_summary_dashboard} value="1" /> } 
                                {utility.hasModuleRight(MODULE.DASHBOARD_BOOKING_STATUS) &&<Tab label={lang.booking_status_dashboard} value="2" /> }     
                                {utility.hasModuleRight(MODULE.DASHBOARD_CONTENT) && <Tab label={lang.content_dashboard} value="3" />}
                                {utility.hasModuleRight(MODULE.DASHBOARD_CMS) &&<Tab label={lang.cms_dashboard} value="4" />}
                                {utility.hasModuleRight(MODULE.MONETIZATION) &&<Tab label={lang.monetization_dashboard} value="5" />}
                                {utility.hasModuleRight(MODULE.WORK_ORDER) &&<Tab label={lang.work_order_dashboard} value="6" />}  
                                {/* <Tab label={lang.booking_schedule_status} value="8" />              */}
                            </TabList>  
                        </Box>
                        {utility.hasModuleRight(MODULE.DASHBOARD_SALES) && <TabPanel value={"1"}>
                            <BookingSummary bookingSummaryDashboardData={bookingSummaryDashboardData} setBookingSummaryDashboardData = {setBookingSummaryDashboardData}/>
                        </TabPanel> } 
                        {utility.hasModuleRight(MODULE.DASHBOARD_BOOKING_STATUS) && <TabPanel value={"2"}>
                            <BookingStatusDashboard/>
                        </TabPanel> } 
                       {utility.hasModuleRight(MODULE.DASHBOARD_CONTENT) && <TabPanel value={"3"}>
                            <ContentDashboard/>  
                        </TabPanel> }
                        {utility.hasModuleRight(MODULE.DASHBOARD_CMS) &&<TabPanel value={"4"}>
                            <CMSDashboard/>  
                        </TabPanel> }
                        {utility.hasModuleRight(MODULE.MONETIZATION) && <TabPanel value={"5"}>
                            <MonetizationDashboard />
                        </TabPanel>}
                        {utility.hasModuleRight(MODULE.WORK_ORDER) && <TabPanel value={"6"}>
                            <WorkOrderDashboard />
                        </TabPanel>}
                    </TabContext>
                </Box>
            </div>  
        </div>
}


export default Dashboard;
