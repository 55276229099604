/* eslint-disable */
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";

export const ContentArrayImageCell = (props) => {

    const field = props?.field || "";
    const value = props?.dataItem[field];
    let displayValue = value?.map(v => v?.thumbnailUrl) ?? [];

    const itemsToShow = displayValue?.slice(0, 3);
    const remainingItemsCount = displayValue?.length - 3;

    return <td
        colSpan={props.colSpan}
        role={"gridcell"}
        aria-colindex={props.ariaColumnIndex}
        aria-selected={props.isSelected}
        {...useTableKeyboardNavigation(props.id)}
        {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
        }}
        style={props.style}>
        <div className='pl-1 pr-2' style={{ display: "flex", borderRadius: "5px", alignItems: "center", }}>
            {itemsToShow.map((item) => (
                <img key={item} src={item ?? 'https://media.comicbook.com/files/img/default-movie.png'} className="ml-2" alt="thumbnail" style={{ objectFit: 'cover', width: "72px", height: "35px", borderRadius: "5px", cursor: "pointer", borderWidth: "thin", boxShadow: "inset 0px 0px 0px 1px grey" }} />
            ))}
            {remainingItemsCount > 0 && (
                <p className='ml-2' style={{ color: "grey", fontSize: "12px", position: "relative", top: "8px", cursor: "pointer" }}>+ {remainingItemsCount} more</p>
            )}
        </div>
    </td>;
};