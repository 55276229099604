/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { ENTITYNAME } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import moment from "moment";
import TournamentTypeEditForm from "../masters/TournamentTypeEditForm";
import { utility } from "../../framework/utility/utilityProvider";
import BossDialog from "../../components/BossDialog";
import { Description } from "@mui/icons-material";

export const TournamentGroupEditForm = (props) => {

  const [dataItem, setDataItem] = useState({
    ...props.item,
    SID: props.item.SID ?? 0,
    Description: props.item.Description ?? '',
    TournamentType: props.item.TournamentType ?? {},
    Archive: props.item.Archive ?? false,
  });
  const [tournamentType, setTournamentType] = useState([]);
  const [posterOpen, setPosterOpen] = useState(false);
  const [showTournamentTypeEditForm, setShowTournamentTypeEditForm] = useState(false);

  const lang = useTranslation();

  useEffect(() => {
    loadcombo();
  }, []);

  const isValid = () => {

    if (dataItem.Description == "" || dataItem.Description == undefined) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (Object.keys(dataItem.TournamentType).length == 0) {
      toast.error(`${lang.please_select_tournament_type}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }


  const loadcombo = async () => {
    let tournamentType = await API.getDataLookup(ENTITYNAME.TournamentType, { sort: { Name: 1 } });
    setTournamentType(tournamentType.data);
  }

  const onchange = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }
  const handleSubmit = async () => {
    if (isValid()) {
      let localData = {
        SID: dataItem.SID,
        Description: dataItem.Description,
        TournamentType: { _id: dataItem.TournamentType._id, SID: dataItem.TournamentType.SID, Description: dataItem.TournamentType.Description },
        Archive: dataItem.Archive ?? false,
        checkDuplicate: true,
        query: [["Description", "=", dataItem.Description]]
      }
      if(props.item._id){
        localData = {...localData, _id: props.item._id}
      }
      delete localData.isSelected;
      let res = await API.saveData(ENTITYNAME.TournamentGroup, localData);
      utility.deleteLocalStorageItem(ENTITYNAME.TournamentGroup);
      if (res.success) {
        props.cancelEdit();
        //do not refresh update data by using slice
        props.refresh(res.data);
        return;
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }

  return (
    <>
      {<BossDialog
        title={props.item.SID > 0 ? props.item.Name : `${lang.tournament_group_dialog_header}`}
        onClose={props.cancelEdit}
        width={"50%"}
      >
        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
        <Form
          render={(formRenderProps) => (
            <FormElement>
              <div className="row mt-3">
                <div className="col-12">
                  <div className="row">
                    <div className="col-6 form-group">
                      <label htmlFor="TabView">{lang.description_label} *</label>
                      <input className="pl-2 form-control form-control-sm" type="text" name={"Description"} onChange={onchange} value={dataItem.Description} />
                    </div>
                    <div className="col-6 form-group">
                      <label htmlFor="TabView">{lang.tournament_type_label} *</label>
                      <div style={{ display: 'flex' }}>
                        <DropDownList
                          style={{ backgroundColor: "white" }}
                          data={tournamentType}
                          name="TournamentType"
                          textField="Description"
                          dataItemKey="_id"
                          value={dataItem.TournamentType}
                          onChange={onchange}
                          validator={(value) => value ? "" : "Please select the value"}
                        />
                        <button type="button" title='Create Tournament Type' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                          style={{ height: "29px", margin: '0px 0px 0px 5px' }} onClick={() => { setShowTournamentTypeEditForm(true) }}><i className="fa-solid fa-plus fa-xs"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-3  form-group">
                      <input type="checkbox" name={"Archive"} value={dataItem.Archive} checked={dataItem.Archive} onChange={onchange} />
                      <label className="pl-2" htmlFor="TabView">{lang.archive}</label>
                    </div>
                  </div>
                </div>
              </div>
            </FormElement>
          )} />
      </BossDialog>}
      {posterOpen && <CollectionSelection entityname={ENTITYNAME.OttPosters} closeForm={() => { setPosterOpen(false) }} setDataList={handlePosterImageSelect} title={lang.select_portal_image_url_collection_header} mode={"single"} width={"50vw"} />}
      {showTournamentTypeEditForm && <TournamentTypeEditForm item={{ SID: 0 }} cancelEdit={() => setShowTournamentTypeEditForm(false)} refresh={() => loadcombo()} />}

    </>
  )
}