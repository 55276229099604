/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import * as API from "../API/api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { utility } from "../utility/utilityProvider";
import { FixedSidebarWithIcons } from "../../components/FixedSidebarWithIcons";
import { CardList } from "../../components/CardList";
import { ASSETTYPE, ENTITYNAME, LOGEVENT, MODULE, PLATFORM } from "../constant/constant";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import { OttEditForm } from "./OttEditForm";
import OttAssetCollectionSelection from "./OttAssetCollectionSelection";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export const MyOttAssetContext = React.createContext({
  OttAssets: [],
  SetOttAssets: () => {},
});

export const ScreenSaverEditForm = (props) => {
  const location = useLocation();
  const RAILSOTTASSETDELETE = "RailsOttAssetDelete";
  const NEWRAILSOTTASSETDELETE = "NewRailsOttAssetDelete";

  const navigate = useNavigate();
  const { SID } = useParams();
  const lang = useTranslation();

  var CustomEditForm = props.customEditForm;

  const [dataItem, setDataItem] = useState({
    SID: SID,
    description: "",
    Platform: {},
    Archive: false,
  });

  const [OttAssets, SetOttAssets] = useState([]);
  const [assetLimit, setAssetLimit] = useState(1);
  const [openAsset, setOpenAsset] = useState(true);
  const [addAsset, setAddAsset] = useState(false);
  const [deleteOttAssetsSID, setDeleteOttAssetsSID] = useState([]);
  const [showOttAssetEditForm, setShowOttAssetEditForm] = useState(false);
  const [editFormAsset, setEditFormAsset] = useState({});
  const [assetOpenForm, setAssetOpenForm] = useState(false);
  const [platform, setPlatform] = useState([]);

  const addOttAssetRef = useRef();
  const setAddOttAssetRef = (data) => {
    addOttAssetRef.current = data;
  };

  var isFromCopy = location.state?.copy;

  useEffect(() => {
    loadCombo();
    if (SID > 0) {
      loadEditData();
    }
  }, []);

  const loadCombo = async () => {
    var platformRes = await API.getDataLookup(ENTITYNAME.Platform, { sort: { Description: 1 } });
    setPlatform(platformRes.data);
  };

  const loadEditData = async () => {
    var ottAssetTypes = Object.keys(ASSETTYPE).map((item, index) => {
      return { Description: item, ID: ASSETTYPE[item] };
    });

    var platformTypeData = Object.keys(PLATFORM).map((item, index) => {
      return { Description: item, _id: PLATFORM[item] };
    });

    let linkData = {
      link_collection: "ott_assets",
      link_field: "ott_assets",
    };

    var res = await API.getEntity(
      ENTITYNAME.ScreenSaver,
      parseInt(SID),
      linkData
    );

    if (res.success) {
      let data = {
        ...res.data,
        description: isFromCopy
          ? "copy of " + res.data.description
          : res.data.description,
        platformType: platformTypeData.find(
          (a) => a._id == res.data.platformType
        ),
        Archive: res.data.Archive ?? false,
      };

      //reset id to add as new entry
      if (isFromCopy) {
        data.SID = 0;
        delete data["_id"];
      }

      setDataItem(data);
      console.log(data.ott_assets);

      let assests = data.ott_assets.map((item) => {
        return {
          ...item,
          ottAssetType: ottAssetTypes.find((a) => a.ID == item.OttAssetTypeSID)
            ?.Description,
        };
      });

      SetOttAssets(assests);

      setAssesLimitFunction(data.OttRailTypeSID);
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleChange = (e) => {
 if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  };

  const setAssesLimitFunction = async (selectedRailTypeSID) => {
    var railsTypeLookup = await API.getDataLookup(ENTITYNAME.OttRailType);

    railsTypeLookup.data.forEach((obj) => {
      if (selectedRailTypeSID == obj.SID) {
        setAssetLimit(obj.AssetLimit);
      }
    });
  };

  const isValid = () => {
    if (dataItem.description == undefined || dataItem.description == "") {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (dataItem.Platform == undefined || Object.keys(dataItem.Platform).length == 0) {
        toast.error(`${lang.please_select_platform_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
        return false;
    }
    if (OttAssets.length == 0 && assetLimit != 0) {
      toast.error(`${lang.atleast_attach_one_ottassets_rails_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    return true;
  };

  const handleSubmit = async (e) => {
    if (!isValid()) return;
    const saveData = {
      _id: dataItem._id,
      SID: dataItem.SID,
      description: dataItem.description,
      Platform: {
        _id: dataItem.Platform._id,
        SID: dataItem.Platform.SID,
        Description: dataItem.Platform.Description
    },
      Archive: dataItem.Archive ?? false,
      ott_assets: OttAssets.length > 0 ? OttAssets.map((a) => a._id) : [],
    };

    let res = await API.saveData(ENTITYNAME.ScreenSaver, saveData);
    console.log(res);

    if (res.success) {
      let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_SCREEN_SAVER : LOGEVENT.UPDATE_SCREEN_SAVER, module: MODULE.SCREEN_SAVER, data: res.data, message: res.message };
      API.SaveLogs(logData);
      navigate(-1);
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const ondeleteAllOttAsset = (index, dataItem) => {
    console.log(OttAssets.length);
    SetOttAssets([]);
  };

  const deleteOttAsset = (index, dataItem) => {
    console.log(index);
    SetOttAssets((old) =>
      old.filter((item, i) => {
        return index !== i;
      })
    );
    if (dataItem.SID !== 0) {
      setDeleteOttAssetsSID((old) => [...old, dataItem.SID]);
    }
    toast.success(
      `${lang.delete_successfully_grid_data_success_toast_message}`,
      {
        position: toast.POSITION.TOP_RIGHT,
      }
    );
  };

  const editAssets = (index, dataItem) => {
    setAssetOpenForm(true);
    setEditFormAsset(dataItem);
  };

  const draggedAssetItem = useRef();
  const draggedOverAsset = useRef();
  const selectedDraggedItem = useRef();

  const onDragStart = (e, position, selectedDataItem) => {
    draggedAssetItem.current = position;
    selectedDraggedItem.current = selectedDataItem;
  };

  const onDragEnter = (e, position, containerName) => {
    console.log(containerName + position);

    draggedAssetItem.current = null;
    draggedOverAsset.current = null;
    selectedDraggedItem.current = null;

    if (containerName === "Destination") {
      draggedOverAsset.current = position;
    }
  };

  const moveUpDown = (index, dataItem, isMoveUp) => {
    if (index === 0 && isMoveUp) return;
    if (!isMoveUp && index === OttAssets.length - 1) return;

    const copyAssets = [...OttAssets];
    copyAssets.splice(index, 1);
    isMoveUp
      ? copyAssets.splice(index - 1, 0, dataItem)
      : copyAssets.splice(index + 1, 0, dataItem);

    SetOttAssets(copyAssets);
  };

  const onDropOnAssetsFromAssets = (e) => {
    console.log(draggedOverAsset);

    if (draggedOverAsset.current == null) return;

    const copyAssets = [...OttAssets];
    //  const dragItemAsset = copyAssets[draggedAssetItem.current];
    copyAssets.splice(draggedAssetItem.current, 1);
    copyAssets.splice(draggedOverAsset.current, 0, selectedDraggedItem.current);
    draggedAssetItem.current = null;
    draggedOverAsset.current = null;
    SetOttAssets(copyAssets);
  };

  const sideFixMenu = [
    {
      icon: "play",
      title: `${lang.sidefixmenu_rails_asset_tooltip}`,
      onClick: () => {
        setOpenAsset((old) => !old);
      },
    },
  ];

  const addOttAsset = (index, isAddUp) => {
    setAddAsset(true);

    setAddOttAssetRef({
      Index: index,
      IsAddUp: isAddUp,
    });
  };

  const handleAssetLibrary = (dataList) => {
    var data = [...OttAssets];
    var index = addOttAssetRef.current.Index;
    if (addOttAssetRef.current.IsAddUp) {
      SetOttAssets([
        ...data.slice(0, index),
        ...dataList,
        ...data.slice(index, data.length),
      ]);
    } else {
      SetOttAssets([
        ...data.slice(0, index + 1),
        ...dataList,
        ...data.slice(index + 1, data.length),
      ]);
    }
  };

  const getAssetMenu = (index, dataItem) => {
    return [
      {
        label: `${lang.options_actionbutton_menutext}`,
        items: [
          {
            label: `${lang.edit_actionbutton_option}`,
            icon: "pi pi-fw pi-pencil",
            command: () => editAssets(index, dataItem),
          },
          {
            label: `${lang.delete_actionbutton_option}`,
            icon: "pi pi-fw pi-trash",
            command: () => deleteOttAsset(index, dataItem),
          },
          {
            label: `${lang.moveup_actionbutton_option}`,
            icon: "pi pi-fw pi-arrow-up ",
            command: () => moveUpDown(index, dataItem, true),
          },
          {
            label: `${lang.movedown_actionbutton_option}`,
            icon: "pi pi-fw pi-arrow-down",
            command: () => moveUpDown(index, dataItem, false),
          },
          {
            label: `${lang.add_above_option}`,
            icon: "pi pi-arrow-circle-up",
            command: () => addOttAsset(index, true),
          },
          {
            label: `${lang.add_below_option}`,
            icon: "pi pi-arrow-circle-down",
            command: () => addOttAsset(index, false),
          },
        ],
      },
    ];
  };

  const cancelEdit = () => {
    utility.deleteLocalStorageItem(RAILSOTTASSETDELETE);
    utility.deleteLocalStorageItem(NEWRAILSOTTASSETDELETE);
    navigate(-1);
  };

  const handleAssetCancelEdit = () => {
    setAssetOpenForm(false);
  };

  const handleSubmitAssets = async (dataItem, dataToSave) => {
    dataToSave = dataToSave == null ? dataItem : dataToSave;

    var UpdatedData = {
      ...dataToSave,
      SID: !dataToSave.OttAssetSID ? dataToSave.SID : dataToSave.OttAssetSID,
    };

    delete UpdatedData.OttAssetSID;

    var res = await API.saveData(ENTITYNAME.OttAsset, UpdatedData);
    console.log(res);
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    var updatedOttAssets = OttAssets.map((asset) => {
      if (
        asset.OttAssetSID
          ? asset.OttAssetSID == res.data.SID
          : asset.SID == res.data.SID
      ) {
        return res.data;
      }
      return asset;
    });
    SetOttAssets(updatedOttAssets);
    utility.deleteLocalStorageItem(ENTITYNAME.OttAsset);
    setAssetOpenForm(false);
  };

  const customeModelFields = {
    Title: "Title",
    Description: "Description",
    PosterURL: "PotraitImageUrl",
    ThirdLine: "ottAssetType",
  };

  return (
    <>
      <FixedSidebarWithIcons menuModel={sideFixMenu} />
      <div className="myFlexContainer">
        {/* Asset */}
        {openAsset && (
          <CardList
            customeModelFields={customeModelFields}
            MenuModel={getAssetMenu}
            showSearch={true}
            containerName="Destination"
            cardData={OttAssets}
            onDeleteDataItem={deleteOttAsset}
            close={() => setOpenAsset(false)}
            title={lang.cardlist_rails_title}
            onAddClick={() => {
              addOttAsset(OttAssets.length - 1, false);
            }}
            onDeleteClick={() =>
              ConfirmDeleteAlert(
                () => ondeleteAllOttAsset(),
                () => {}
              )
            }
            onDragStart={onDragStart}
            onDragEnter={onDragEnter}
            onDrop={onDropOnAssetsFromAssets}
            inTileView={true}
            showTileView={false}
          />
        )}
        {/* Add Asset Library */}
        {addAsset && (
          <OttAssetCollectionSelection
            title={lang.select_assets_collection_header}
            entityname={ENTITYNAME.OttAsset}
            closeForm={() => setAddAsset(false)}
            setDataList={handleAssetLibrary}
            width={"60vw"}
            height={"64vh"}
          />
        )}

        {/* edit form */}
        <div className="main-layout">
          <Form
            initialValues={dataItem}
            render={(formRenderProps) => (
              <FormElement>
                <EditPageHeader
                  title={
                    `${lang.screen_saver_editpageheader} -> ` +
                    (dataItem.description
                      ? dataItem.description
                      : `${lang.new_title_editpageheader}`)
                  }
                  onSubmit={handleSubmit}
                  onCancel={cancelEdit}
                />
                <div
                  style={{
                    boxShadow: "0px 0px 10px 1px lightgrey",
                    backgroundColor: "#EEEEEE",
                    borderRadius: "10px",
                    height: "85vh",
                  }}
                >
                  <div className="row" style={{ margin: "5px" }}>
                    <div className="col-12 mt-2">
                      <div className="row form-group">
                        <div className="col-12">
                          <label htmlFor="">{lang.description_label} *</label>
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            name="description"
                            value={dataItem.description}
                            onChange={(e) => handleChange(e)}
                          />
                        </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-6">
                          <label htmlFor="">{lang.platform_label} *</label>
                          <DropDownList
                            style={{
                              backgroundColor: "white",
                            }}
                            data={platform}
                            name="Platform"
                            textField="Description"
                            dataItemKey="_id"
                            value={dataItem.Platform}
                            onChange={handleChange}
                          />
                        </div>
                      </div>
                      <div className="row form-group mt-2">
                        <div className="col-6">
                          <input
                            type="checkbox"
                            name={"Archive"}
                            checked={dataItem.Archive}
                            onChange={handleChange}
                          />
                          <label className="pl-1">{lang.archive}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </FormElement>
            )}
          />
        </div>
        {showOttAssetEditForm && (
          <CustomEditForm
            {...props} //to get other props from customEditForm
            cancelEdit={setShowOttAssetEditForm(false)}
            onSubmit={handleSubmit}
            //  item={editItem}
          />
        )}
        {assetOpenForm && (
          <OttEditForm
            {...props}
            item={editFormAsset}
            cancelEdit={handleAssetCancelEdit}
            onSubmit={handleSubmitAssets}
          />
        )}
      </div>
    </>
  );
};
