
import React from 'react'
import { Chart ,ChartSeries, ChartSeriesItem, ChartCategoryAxis,  ChartCategoryAxisItem, ChartTitle, ChartLegend, ChartTooltip, } from '@progress/kendo-react-charts';
import { useTranslation } from '../../../locale/useTranslation';
import { Loader } from '../../../framework/forms/helpercomponents/Loader';
import { seriesChartColorGenerator } from '../../../framework/constant/constant';

const ColumnChart= (props) => {
  let totalsr = [];
  let categories = props.categories ?? [];

  if(props.chartData != undefined &&  props.chartData.length > 0) {
    props.chartData.map((item) => {
      totalsr.push({ name : item.name ,data : [item.totalsr]});
    })
  }    
    return (
        <div className="row">
            <div className='col-12'>    
                <div className="panel panel-default" style={{color:"#333", backgroundColor:"#f5f5f5", borderColor:"#ddd"}}>
                    <div className="panel-heading" style={{padding:"10px 10px", borderColor:"1px solid transparent", borderBottom: "1px solid transparent", borderTopRightRadius:"1px"   // border-top-right-radius: 1px;
                       , borderTopLeftRadius :"1px", textAlignLast:"center"}}>{props.title}
                    </div>
                    {props?.isLoading ? 
                    <Loader height="53.5vh" bgColor="white"/>   :
                    <div className="panel-body"><iframe className="chartjs-hidden-iframe" style={{width:"100%",display:"block", border:"0px",height:"0px",margin:"0px", position:"absolute", inset:"0px"}}></iframe>
                        <Chart id="myDispatchChart" style={{display:"block", height:"340"}} seriesColors={seriesChartColorGenerator()}>
                            <ChartTitle/>
                            <ChartTooltip/>
                            <ChartLegend position="top" orientation="horizontal" />
                            <ChartCategoryAxis>
                                <ChartCategoryAxisItem categories={categories} startAngle={65} />
                            </ChartCategoryAxis>
                            <ChartSeries>                               
                              {totalsr.map((item,idx) => (<ChartSeriesItem
                                type="column"
                                key = {idx}
                                tooltip={{ visible : true}}
                                data={item.data}
                                name={item.name}
                              />))}                           
                            </ChartSeries>
                        </Chart>
                    </div>}
                </div>
            </div>
        </div>
       
    )
  }

export default ColumnChart;
