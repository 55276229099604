import { useState } from "react";
import ScheduleFooter from "./ScheduleFooter";
import Scheduler from "./Scheduler";

const SchedlingWrapper = () => {
    const [selectedState, setSelectedState] = useState(0);
    const [copyPasteDataItem, setCopyPasteDataItem] = useState(0);
    const [cutPasteDataItem, setCutPasteDataItem] = useState(0);
    const [statusMessage, setStatusMessage] = useState("");

    return <>
        <Scheduler
            setSelectedState={setSelectedState}
            setCopyPasteDataItem={setCopyPasteDataItem}
            setCutPasteDataItem={setCutPasteDataItem}
            setStatusMessage={setStatusMessage}
        />
        <ScheduleFooter
            onPreferenceFilter={() => { }}
            cutPasteDataItem={cutPasteDataItem}
            copyPasteDataItem={copyPasteDataItem}
            selectedState={selectedState}
            scheduleInfo={{
                parentCount: 0,
                commercialCount: 0,
                overRunUnderRunCount: 0,
                playlistStartTime: 0,
                playlistEndTime: 0
            }}
            statusMessage={statusMessage}
            onHeaderExpandChange={() => { }} searchWord={''} />
    </>
}

export default SchedlingWrapper;