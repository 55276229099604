
import React from 'react'
import BossDialog from '../../../../components/BossDialog';

export const DeleteConfirmDialogBox = (props) => {
  return (
    <div><BossDialog
    width={300}
  >
    <h6 style={{textAlign : "center"}}>{props.message}</h6>
    <div className="row" style={{margin : "30px" ,textAlign : "center"}}>
      <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary col-5 mr-1"
    //    onClick={() =>{ setDeletePermission(true); props.onClose()}}>
        onClick={() => {props.onClose(); props.onConfirmDelete()}}>

        Confirm
      </button>
      <button
        onClick={props.onClose}
        className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base col-5 ml-1"
      >
        Cancel
      </button>
    </div>
  </BossDialog></div>
  )
}
