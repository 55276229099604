/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME, PUBLISHING_SOURCE, PLATFORMTYPE, WORKORDER_STATUS, SOCKET_EVENTS, LOCALSTORAGE_KEY, MODULE, FILEURL, AUTOMATION } from "../../framework/constant/constant";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EditPageHeader } from "../../components/EditPageHeader";
import ContractCollectionSelection from "./ContractCollectionSelection";
import { ContractCommandCell, DropDownCell } from "./ContractCommandCell";
import { CheckboxCell } from '../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell';
import moment from "moment";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { utility } from "../../framework/utility/utilityProvider";
import socket from "../../framework/socket/socket";
import { ContractTitleBlackoutDateEditForm } from "./ContractTitleBlackoutDateEditForm";
import { ContractTitleEditForm } from "./ContractTitleEditForm";
import { ContractAddLinearRightsForm } from "./ContractAddLinearRightsForm";
import ContractPaymentSchedule from "./ContractPaymentSchedule";
import { MenuCommandCell } from "../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { contractTitleMenuModel } from "../../framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import MediaEpisodeHistory from "../Planning/MediaEpisodeHistory";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import ContractRunReport from "./ContractRunReport";
import BossDialog from "../../components/BossDialog";
import CollectionSelection from "../../framework/forms/CollectionSelection";

//when deleting contract delete mediadealrights on basis of source_id

function ContractEditForm(props) {

    const currentUser = utility.getValue(LOCALSTORAGE_KEY.userData);
    const { SID } = useParams();
    const loc = useLocation();
    const [licensor, setLicensor] = useState([]);
    const [playTime, setPlayTime] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [platform, setPlatform] = useState([]);
    const [region, setRegion] = useState([]);
    const [showMediaSelectionCollection, setShowMediaSelectionCollection] = useState(false);
    const [removedMedia, setRemovedMedia] = useState([]);
    const [tabNumber, setTabNumber] = React.useState("1");
    const [showBlackOutDate, setShowBlackOutDate] = useState(false);
    const [showWorkFlow, setShowWorkFlow] = useState(false);
    const [workFlow, setWorkFlow] = useState([]);
    const [showLinearEditForm, setShowLinearEditForm] = useState(false);
    const [contractPaymentScheduleData, setContractPaymentScheduleData] = useState({});
    const [filterData, setFilterData] = useState([]);
    const [showHistory, setShowHistory] = useState(false);
    const [showContractRunReport, setShowContractRunReport] = useState(false);
    const [showParentContract, setShowParentContract] = useState(false);

    const selectedIndexRef = useRef(0);
    const setSelectedIndexRef = (data) => {
        selectedIndexRef.current = data;
    }
    const [showContractTitleEditForm, setShowContractTitleEditForm] = useState(false);

    const navigate = useNavigate();
    const lang = useTranslation();

    let blankDataItem = {
        SID: SID,
        ContractNumber: "",
        Licensor: {},
        Payee: {},
        Archive: false,
        PromotionalExcerpts: '',
        GoverningLaws: '',
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [workflowItem, setWorkflowItem] = useState({})

    useEffect(() => {
        loadcombo();
        if (SID > 0) {
            loadEditData();
        }
    }, []);

    const MyArrayCell = (props) => (
        <ArrayCell {...props} />
    )

    const handleTabChange = (event, newValue) => {
        setTabNumber(`${newValue}`);
        setDataItem({
            ...blankDataItem,
            SID: SID,
            ContractNumber: dataItem.ContractNumber,
            PlatformType: PLATFORMTYPE[parseInt(newValue) - 1],
            Licensor: dataItem.Licensor,
            Payee: dataItem.Payee,
            Archive: dataItem.Archive,
            PromotionalExcerpts: dataItem.PromotionalExcerpts,
            GoverningLaws: dataItem.GoverningLaws,
            ParentContract: dataItem.ParentContract,
        });
        setContractPaymentScheduleData(contractPaymentScheduleData);
        setFilterData(gridData.filter(x => x.PlatformType.SID == newValue));
    };

    const loadEditData = async () => {

        var res = await API.getEntity(ENTITYNAME.Contract, parseInt(SID));
        console.log(res.data);
        if (res.success && res.data) {
            setDataItem({
                ...blankDataItem, ...res.data,
                ContractNumber: res.data.SID ? loc.state?.copy ? 'Copy of ' + res.data.ContractNumber : res.data.ContractNumber : res.data.ContractNumber,
            });


            if (res.data._id && !loc.state?.copy) {

                var mediaDealRightsRes = await API.getData(ENTITYNAME.MediaDealRights, { query: [['source', '=', 'contract'], ['source_id', '=', res.data._id?.toString() ?? ""]] });

                console.log(SID);
                console.log(mediaDealRightsRes);
                if (mediaDealRightsRes.success) {
                    setGridData(mediaDealRightsRes.data);
                    setFilterData(mediaDealRightsRes.data);
                }
                setContractPaymentScheduleData(res.data.PaymentSchedule);
            }

        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const loadcombo = async () => {
        var licensorRes = await API.getDataLookup(ENTITYNAME.Licensor, { sort: { Description: 1 } });
        setLicensor(licensorRes.data);
        var playTimeRes = await API.getDataLookup(ENTITYNAME.PlayTime, { sort: { Description: 1 } });
        setPlayTime(playTimeRes.data);
        // var platformType = await API.getDataLookup(ENTITYNAME.PlatformType, {sort: { Description: 1 }});
        // setPlatformType(platformType.data);
        var region = await API.getDataLookup(ENTITYNAME.Region, { sort: { Description: 1 } });
        setRegion(region.data);
        var platform = await API.getDataLookup(ENTITYNAME.Platform, { sort: { Description: 1 } });
        setPlatform(platform.data);
        var workFlows = await API.getDataLookup(ENTITYNAME.Workflow, { sort: { Name: 1 } });
        setWorkFlow(workFlows.data)

    }

    const onChange = (e) => {

        if (e.target.name == "IsUnlimited") {
            dataItem.Exhibitions = 0;
            dataItem.Repeats = 0;
            dataItem.PlayTimeNumber = 0;
            dataItem.PlayTime = {};
            setDataItem({ ...dataItem, IsUnlimited: !dataItem.IsUnlimited });
        }
        else if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, [e.target.name]: !dataItem[e.target.name] });
        } else if (e.target.name == 'IsExclusive') {
            setDataItem({ ...dataItem, IsExclusive: !dataItem.IsExclusive, ExclusiveStartDate: '', ExclusiveEndDate: '' });
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const onAddNonLinearMedia = () => {

        // validating form inputs first

        if (!utility.isValidDate(dataItem.PublishStartDate)) {
            toast.error(lang.invalid_start_date, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if (!utility.isValidDate(dataItem.PublishEndDate)) {
            toast.error(lang.invalid_end_date, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if (dataItem.PublishStartDate >= dataItem.PublishEndDate) {
            toast.error(`${lang.end_date_should_be_greater_than_start_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (dataItem.Platform == undefined || dataItem.Platform.length == 0) {
            toast.error(lang.please_select_platform_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        if (dataItem.Region == undefined || dataItem.Region.length == 0) {
            toast.error(`${lang.please_select_region_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        setShowMediaSelectionCollection(!showMediaSelectionCollection);
    }

    const updateLinearGridData = (dataList) => {

        // Grid data linear
        var linearGridData = gridData.filter((x) => x.PlatformType?.SID == PLATFORMTYPE[0].SID);

        const newlyAddedData = dataList.filter(x => !linearGridData.some(c => c.ContractTitle.media_id == x.ContractTitle.media_id));
        setGridData((old) => [...old, ...newlyAddedData]);
        setFilterData((old) => [...old, ...newlyAddedData]);
    }

    const updateNonLinearGridData = (dataList) => {

        var finalData = [];
        // preparing final data
        for (let i = 0; i < dataList.length; i++) {

            var obj = dataList[i];

            var data = {
                _id: undefined,
                ContractSID: 0, ContractNumber: '', Licensor: {}, Payee: {},
                PublishingSource: 'contract',
                ContractTitle: { media_id: obj._id, Title: obj.Title, AssetId: obj.AssetId },
                MediaCategory: obj.MediaCategory,
                PublishStartDate: new Date(dataItem.PublishStartDate).getTime(),
                PublishEndDate: new Date(dataItem.PublishEndDate).getTime(),
                PlatformType: PLATFORMTYPE[1],
                Platform: dataItem.Platform ?? [],
                Channel: [],
                Region: dataItem.Region ?? [],
                Amount: 0, Currency: {}, IsUnlimited: false, Exhibitions: 0, Repeats: 0, PlayTimeNumber: 0, PlayTime: {},
                IsExclusive: false, ExclusiveStartDate: 0, ExclusiveEndDate: 0, BlackOutDates: [],
                Archive: false,
            }

            finalData.push(data);
        }

        // Grid data linear
        var nonLinearGridData = gridData.filter((x) => x.PlatformType?.SID == PLATFORMTYPE[1].SID);
        const newlyAddedData = finalData.filter(x => !nonLinearGridData.some(c => c.ContractTitle.media_id == x.ContractTitle.media_id));

        setGridData((old) => [...old, ...newlyAddedData]);
        setFilterData((old) => [...old, ...newlyAddedData]);
    }

    // Functions to modify grid data
    const onRemoveContractTitle = (dataItem) => {

        console.log(gridData);

        const dropIndex = gridData.indexOf(dataItem);
        if (dropIndex > -1) { // only splice array when item is found
            gridData.splice(dropIndex, 1);
        }

        console.log(dataItem);
        console.log(gridData);

        setGridData(gridData);
        setFilterData(gridData.filter(x => x.PlatformType.SID == tabNumber));

        // Remove Only if Contract In Edit Stage
        if (dataItem._id) {
            setRemovedMedia((old) => [...old, dataItem]);
        }

        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const onEditContractTitle = (dataItem) => {
        setShowContractTitleEditForm(true);
        setSelectedIndexRef(dataItem);
    };

    const onEditBlackOutDate = (dataItem) => {
        setSelectedIndexRef(dataItem);
        setShowBlackOutDate(true);
    }

    const MyCommandCell = (props) => (
        <MenuCommandCell
            {...props}
            onEditBlackOutDate={onEditBlackOutDate}
            onEditContractTitle={onEditContractTitle}
            onRemoveContractTitle={onRemoveContractTitle}
            myMenuModel={contractTitleMenuModel}
        />
    )

    const CommandCell = (props) => (

        <ContractCommandCell
            {...props}
            onEditBlackOutDate={onEditBlackOutDate}
            onEditContractTitle={onEditContractTitle}
            onRemoveContractTitle={onRemoveContractTitle}
        />
    )

    const isValid = () => {

        if (dataItem.ContractNumber == '' || dataItem.ContractNumber == undefined) {
            toast.error(`${lang.please_enter_contarct_number_contract_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.Licensor).length == 0) {
            toast.error(`${lang.please_select_licensor_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.Payee).length == 0) {
            toast.error(`${lang.please_select_payee_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (gridData.length == 0) {
            toast.error(`${lang.please_attach_atleast_one_media_contract_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        return true;
    }

    const saveWorkFlow = () => {
        if (isValid()) {
            setShowWorkFlow(true)
        }
    }

    const onSave = async (saveAndCreateWorkOrder = false) => {

        if (saveAndCreateWorkOrder && Object.keys(workflowItem).length == 0) {
            toast.error(`${lang.please_select_workflow_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        const saveData = {
            _id: dataItem._id,
            SID: loc.state?.copy ? 0 : parseInt(dataItem.SID),
            ContractNumber: dataItem.ContractNumber,
            Licensor: {
                _id: dataItem.Licensor._id,
                SID: dataItem.Licensor.SID,
                Name: dataItem.Licensor.Name
            },
            Payee: {
                _id: dataItem.Payee._id,
                SID: dataItem.Payee.SID,
                Name: dataItem.Payee.Name
            },
            PromotionalExcerpts: dataItem.PromotionalExcerpts,
            GoverningLaws: dataItem.GoverningLaws,
            Archive: dataItem.Archive ?? false,
            ContractTitles: gridData.map(m => { return m.ContractTitle }),
            PaymentSchedule: contractPaymentScheduleData,
            ParentContract: dataItem?.ParentContract && Object.keys(dataItem?.ParentContract).length > 0 ? {
                _id: dataItem?.ParentContract?._id,
                ContractNumber: dataItem?.ParentContract?.ContractNumber
            } : {}
        }
        // need to implement validation
        if (loc.state?.copy) {
            delete saveData._id;
        }

        var res = await API.saveData(ENTITYNAME.Contract, saveData)
        let logData = { event: "", module: MODULE.CONTRACT, data: res.data, message: res.message };
        API.SaveLogs(logData);


        if (!res.success) {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            //create workorders

            if (saveAndCreateWorkOrder) {

                var workOrderRes = await API.createWorkOrder(res.data, workflowItem);
                console.log(workOrderRes);

                console.log("emitting")
                //fetch workflow
                var workflow = await API.getEntity(ENTITYNAME.Workflow, 1);
                socket.emit(SOCKET_EVENTS.onSocketNotification, { user: currentUser, module: MODULE.WORK_ORDER, data: workOrderRes.data, reciever: { role: workflow.data.StartingRole, user: { _id: '', SID: 0, name: 'All' } } });
            }

            // adding contract properties in titles for saving in media deal rights
            gridData.forEach((x) => {
                x.ContractNumber = dataItem.ContractNumber;
                x.ContractSID = res.data.SID;
                x.Licensor = {
                    _id: dataItem.Licensor._id,
                    SID: dataItem.Licensor.SID,
                    Name: dataItem.Licensor.Name
                };
                x.Payee = {
                    _id: dataItem.Payee._id,
                    SID: dataItem.Payee.SID,
                    Name: dataItem.Payee.Name
                };
                x.media_id = x.ContractTitle.media_id;
            });
            var response = await API.updateMediaDealRights(res.data, removedMedia, gridData, PUBLISHING_SOURCE.Contract);

            console.log(response.data);

            if (response.success) {
                navigate("/home/Contract");
            } else {
                toast.error(response.message, { position: toast.POSITION.TOP_RIGHT });
                return;
            }
        }

    }

    const saveContractTitleEditData = (data) => {

        let index = gridData.findIndex((x) => x.ContractTitle.media_id == data.ContractTitle.media_id);

        let newData = gridData.map((item, i) =>
            index === i && item.PlatformType.SID == data.PlatformType.SID
                ? {
                    ...data,
                    modBy: { _id: currentUser._id, name: currentUser.name }

                }
                : item
        );

        setGridData(newData);
        setFilterData(newData.filter(x => x.PlatformType.SID == tabNumber));
    }

    const setBlackOutDate = (dataItem) => {

        let newData = gridData.map((item) => {
            return (
                item._id === dataItem[0]._id
                    ? {
                        ...item,
                        BlackOutDates: dataItem[0].BlackOutDates,
                        modBy: { _id: currentUser._id, name: currentUser.name }
                    }
                    : item
            )
        });
        setGridData(newData);
        setFilterData(newData.filter(x => x.PlatformType.SID == tabNumber));
    }

    const handelContractPaymentSchedule = (dataItem) => {
        setContractPaymentScheduleData(dataItem);
    }

    var defaultKeys = [];

    const onSearch = (e) => {
        e.preventDefault();
        const keyword = e.target.value?.trim();
        var datakey = Object.keys(gridData[0]?.ContractTitle);
        datakey?.map((key) => {
            if (key == "Title" && !defaultKeys.includes(key)) {
                defaultKeys.push(key);
            } else if (key == "AssetId" && !defaultKeys.includes(key)) {
                defaultKeys.push(key);
            }
        });
        var dataStore = [];
        if (keyword != "") {
            defaultKeys?.map((item) => {
                var filterDataValue = gridData.filter((obj) => obj?.ContractTitle[item] && obj?.ContractTitle[item].toLowerCase().includes(keyword.toLowerCase()));
                if (filterDataValue.length != 0) {
                    dataStore.push(...filterDataValue);
                }
            });
            const uniqData = [...new Set(dataStore)];
            setFilterData(uniqData);
        } else {
            setFilterData(gridData);
        }
    }

    const downloadExcel = async () => {

        var getData = filterData?.filter((x) => x.PlatformType?.Description == PLATFORMTYPE[0].Description);

        if (getData.length == 0) {
            toast.info(`${lang.no_data_found_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        console.log(getData);

        var storeData = getData.map(x => {
            return {
                Title: x.ContractTitle.Title,
                AssetId: x.ContractTitle.AssetId,
                MediaCategory: x.MediaCategory.Description,
                Channel: x.Channel.map(obj => obj.FullChannelName).join(','),
                Region: x.Region.map(obj => obj.Description).join(','),
                Amount: x.Amount,
                Currency: x.Currency.Description,
                StartDate: moment(new Date(x.PublishStartDate)).format("YYYY-MM-DD"),
                EndDate: moment(new Date(x.PublishEndDate)).format("YYYY-MM-DD"),
                Exhibitions: x.Exhibitions,
                Repeats: x.Repeats,
                PlayTimeNumber: x.PlayTimeNumber,
                PlayTime: x.PlayTime.Description ? x.PlayTime.Description : "",
                IsUnlimited: x.IsUnlimited,
                IsExclusive: x.IsExclusive,
                ExclusiveStartDate: x.ExclusiveStartDate,
                ExclusiveEndDate: x.ExclusiveEndDate,
                BlackOutDates: x.BlackOutDates ? x.BlackOutDates?.map(obj => obj.StartDate + ' - ' + obj.EndDate).join(',') : "",

            }
        })
        var res = await API.exportCSV(storeData);
        if (res.success) {
            window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
            toast.success(`${lang.successfully_download_success_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const showMediaEpisodeHistory = () => {
        setShowHistory(!showHistory);
    }

    const parentContractData = (dataList) => {
        setDataItem({ ...dataItem, "ParentContract": dataList[0] });
    }
    
    return (<>
        <div className="row m-1">
            <div className="col-12">
                <EditPageHeader title={`${lang.contract_sub_menu}  -> ` + (dataItem.ContractNumber == "" ? `${lang.new_title_editpageheader}` : ` ${dataItem.ContractNumber}`)} onSubmit={() => { if (isValid()) { onSave(false) } }} onCancel={() => navigate(-1)} showSaveAndCreateWorkOrder={true} onSaveAndCreateWorkOrder={saveWorkFlow} />
                <div className="row" style={{ marginTop: "5px" }}>
                    <div className="col-3" style={{ marginTop: "0px" }}>
                        <div className="form-group">
                            <label htmlFor="">{lang.contract_number_label} *</label>
                            <input type="text" className="form-control form-control-sm" name="ContractNumber" value={dataItem.ContractNumber} onChange={onChange} required />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.licensor_label} *</label>
                            <DropDownList
                                style={{
                                    backgroundColor: "white",
                                }}
                                data={licensor}
                                name="Licensor"
                                textField="Name"
                                dataItemKey="_id"
                                value={dataItem.Licensor}
                                onChange={onChange}
                                validator={(value) => value ? "" : "Please select the value"}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.payee_label} *</label>
                            <DropDownList
                                style={{
                                    backgroundColor: "white",
                                }}
                                data={licensor}
                                name="Payee"
                                textField="Name"
                                dataItemKey="_id"
                                value={dataItem.Payee}
                                onChange={onChange}
                                validator={(value) => value ? "" : "Please select the value"}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="row">
                            <div className="form-group col-12">
                                <label htmlFor="">{lang.parent_contract_label}</label><br />
                                <div style={{ display: "flex" }}>
                                    <input name="ParentContract" type="text" className="d-inline form-control form-control-sm"  value={dataItem?.ParentContract?.ContractNumber || ""} onChange={onChange} disabled={true}/>

                                    <button type="button" title='Create Contract' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                                        style={{ height: "30px", marginLeft: "10px" }} onClick={() => {setShowParentContract(true)}}>
                                        {lang.select_button_text}
                                    </button>

                                    <button type="button" title='Create Contract' className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary "
                                        style={{ height: "30px", marginLeft: "10px" }}  onClick={() => {setDataItem({ ...dataItem, ParentContract: {} })}}>
                                        <i className="fa-solid fa-times fa-xs"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-4">
                        <div className="form-group">
                            <input type={"checkbox"} name={"Archive"}
                                onChange={(e) => onChange(e)}
                                value={dataItem.Archive}
                                checked={dataItem.Archive} />
                            <label className="ml-1">{lang.archive}</label>
                        </div>
                    </div>
                </div>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={tabNumber}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                <Tab label={lang.linear_label} value="1" />
                                <Tab label={lang.non_linear_label} value="2" />
                                <Tab label={lang.general_label} value="3" />
                                <Tab label={lang.payment_schedule_label} value="4" />
                            </TabList>
                        </Box>
                        <TabPanel value={"1"}>
                            {showLinearEditForm && <ContractAddLinearRightsForm onCancel={() => setShowLinearEditForm(false)} updateLinearGridData={updateLinearGridData} />}

                            <div className="row">
                                <div className="col">
                                    <AddRoundButton onClick={() => setShowLinearEditForm(true)} title={lang.add_linear_button_tooltip} />
                                    <RoundButton icon='download' onClick={() => downloadExcel()} style={{ marginLeft: '5px' }} title={lang.download_button_tooltip} />
                                    <RoundButton icon='history' onClick={() => showMediaEpisodeHistory()} style={{ marginLeft: '5px' }} title={lang.show_history_button_text} />
                                    <RoundButton icon='ioxhost' onClick={() => setShowContractRunReport(true)} style={{ marginLeft: '5px' }} title={lang.contract_run_report_button_tooltip} />
                                    <div className="pull-right">
                                        <div className="input-group input-group mb-3" >
                                            <input type="text" className="form-control shadow-inset-2 pt-0 pb-0 ml-2" id="searchInput" aria-label="type 2 or more letters" placeholder={lang.search_button_text} onChange={onSearch} />
                                            <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                                                <div className="input-group-append">
                                                    <span className="input-group-text">
                                                        <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                                    </span>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <Grid style={{ height: '50vh', overflow: "hidden" }}
                                data={filterData?.filter((x) => x.PlatformType?.Description == PLATFORMTYPE[0].Description)}>
                                <Column cell={MyCommandCell} width="55px" />
                                <Column field="ContractTitle.Title" title={lang.title_column} width={'150px'} editable={false} />
                                <Column field="ContractTitle.AssetId" title={lang.assetId_column} width={'150px'} editable={false} />
                                <Column field="MediaCategory.Description" title={lang.media_category_column} width={'150px'} editable={false} />
                                <Column field="Channel" format="FullChannelName" cell={MyArrayCell} width={'150px'} title={lang.channel_column} />
                                <Column field="Region" format="Description" cell={MyArrayCell} width={'150px'} title={lang.region_column} />
                                <Column field="Amount" width={'150px'} title={lang.amount_column} />
                                <Column field="RevShare" width={'150px'} title={lang.revenue_share_column} />
                                <Column field="Currency.Description" width={'150px'} title={lang.currency_column} />
                                <Column field="PublishStartDate" title={lang.start_date_column} cell={DateOnlyCell} width={'150px'} />
                                <Column field="PublishEndDate" title={lang.end_date_column} cell={DateOnlyCell} width={'150px'} />
                                <Column field="Exhibitions" title={lang.exhibition_column} editable={false} width={'150px'} />
                                <Column field="Repeats" title={lang.repeat_column} width={'125px'} editable={false} />
                                <Column field="PlayTimeNumber" title={lang.play_time_number_column} width={'125px'} editable={false} />
                                <Column field="PlayTime" title={lang.playTime_column} cell={DropDownCell} width={'150px'} editable={false} />
                                <Column field="IsUnlimited" title={lang.is_unlimited_column} width={'105px'} editable={false} cell={CheckboxCell} />
                                <Column field="IsExclusive" title={lang.is_exclusive_column} width={'105px'} editable={false} cell={CheckboxCell} />
                                <Column field="ExclusiveStartDate" title={lang.exclusive_start_date_column} cell={DateOnlyCell} width={'150px'} />
                                <Column field="ExclusiveEndDate" title={lang.exclusive_end_date_column} cell={DateOnlyCell} width={'150px'} />
                                <Column field="BlackOutDates" format="StartDate" cell={MyArrayCell} title={lang.black_out_start_date_column} width={'125px'} />
                                <Column field="BlackOutDates" format="EndDate" cell={MyArrayCell} title={lang.black_out_end_date_column} width={'125px'} />
                                <Column field="addBy.name" title={lang.added_by_column} width={'125px'} />
                                <Column field="modBy.name" title={lang.modified_by_column} width={'125px'} />
                            </Grid>
                            <div className="pull-right m-1" >{lang.total_label} {gridData?.filter((x) => x.PlatformType?.Description == PLATFORMTYPE[0].Description)?.length ?? 0}</div>
                        </TabPanel>
                        <TabPanel value={"2"}>
                            <div className="row">
                                <div className="col-3">
                                    <div className="form-group">
                                        <label htmlFor="">{lang.start_date_label}</label>
                                        <input type="date" className="form-control form-control-sm" name="PublishStartDate" value={dataItem.PublishStartDate} onChange={(e) => onChange(e)} />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <label htmlFor="">{lang.end_Date_label}</label>
                                        <input type="date" className="form-control form-control-sm" name="PublishEndDate" value={dataItem.PublishEndDate} onChange={(e) => onChange(e)} />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <label htmlFor="TabView">{lang.platform_label} *</label><br />
                                        <MultiSelect
                                            style={{ backgroundColor: "white" }}
                                            data={platform}
                                            name="Platform"
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.Platform}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-3">
                                    <div className="form-group">
                                        <label htmlFor="TabView">{lang.region_label} *</label><br />
                                        <MultiSelect
                                            style={{ backgroundColor: "white" }}
                                            textField="Description"
                                            dataItemKey="_id"
                                            data={region}
                                            name="Region"
                                            value={dataItem.Region}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-8">
                                    <AddRoundButton style={{ marginBottom: '5px' }} onClick={onAddNonLinearMedia} title={lang.attach_media_button_tooltip} />
                                </div>
                                <div className="col-4">
                                    <div className="pull-right">{lang.total_label} {gridData?.filter((x) => x.PlatformType?.Description == PLATFORMTYPE[1].Description)?.length ?? 0}</div>
                                </div>
                            </div>
                            <Grid style={{ height: "35vh", marginTop: "2px", overflow: "hidden", marginBottom: '20px' }}
                                data={gridData.filter((x) => x.PlatformType?.Description == PLATFORMTYPE[1].Description)}>
                                <Column cell={CommandCell} width="95px" />
                                <Column field="ContractTitle.Title" title={lang.title_column} editable={false} />
                                <Column field="ContractTitle.AssetId" title={lang.assetId_column} editable={false} />
                                <Column field="Platform" format="Description" cell={MyArrayCell} title={lang.platform_column} />
                                <Column field="Region" format="Description" cell={MyArrayCell} width={'150px'} title={lang.region_column} />
                                <Column field="PublishStartDate" title={lang.start_date_column} cell={DateOnlyCell} />
                                <Column field="PublishEndDate" title={lang.end_date_column} cell={DateOnlyCell} />
                            </Grid>
                        </TabPanel>
                        <TabPanel value={"3"}>
                            <div className="row">
                                <div className="col-12">
                                    <label>{lang.promotional_excerpts_label}</label>
                                    <textarea
                                        className="form-control form-control-sm"
                                        name="PromotionalExcerpts"
                                        style={{ height: '25vh' }}
                                        value={dataItem.PromotionalExcerpts}
                                        onChange={(e) => onChange(e)}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-12">
                                    <label>{lang.governing_laws_label}</label>
                                    <textarea
                                        className="form-control form-control-sm"
                                        name="GoverningLaws"
                                        style={{ height: '25vh' }}
                                        value={dataItem.GoverningLaws}
                                        onChange={(e) => onChange(e)}
                                    />

                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel value={"4"}>
                            <ContractPaymentSchedule data={gridData.filter((x) => x.PlatformType?.Description == PLATFORMTYPE[0].Description)} contractPaymentSchedule={contractPaymentScheduleData} setContractPaymentSchedule={handelContractPaymentSchedule} />
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>
        </div>
        {showMediaSelectionCollection && <ContractCollectionSelection addButtonTitle="Select" title={lang.media_library_dialog_header} wherestatement={['MediaCategory.isLong', '=', true]} setDataList={updateNonLinearGridData} entityname={ENTITYNAME.MediaEpisode} closeForm={onAddNonLinearMedia} width={"50vw"} height={"66vh"} />}
        {showBlackOutDate && <ContractTitleBlackoutDateEditForm data={selectedIndexRef.current} onCancel={() => setShowBlackOutDate(false)} setBlackOutDate={setBlackOutDate} />}
        {showWorkFlow &&
            <BossDialog title={lang.workflow_label} onClose={() => { setShowWorkFlow(false); setWorkflowItem({}) }} width={"300px"}>
                <div className="row">
                    <div className="col-12">
                        <EditPageHeader showTitle={false} onSubmit={() => onSave(true)} onCancel={() => { setShowWorkFlow(false); setWorkflowItem({}) }} />
                        <div className="row mt-2 mb-2">
                            <div className='col'>
                                <label>{lang.workflow_label} *</label>
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    data={workFlow}
                                    name="WorkFlow"
                                    textField="Name"
                                    dataItemKey="_id"
                                    value={workflowItem}
                                    onChange={(e) => setWorkflowItem(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </BossDialog>}
        {showContractTitleEditForm && <ContractTitleEditForm data={selectedIndexRef.current} onCancel={() => setShowContractTitleEditForm(false)} saveEditData={saveContractTitleEditData} />}
        {showHistory && <MediaEpisodeHistory isFromContract={true} showTitle={dataItem.ContractNumber} selectedData={gridData.filter((x) => x.PlatformType?.Description == PLATFORMTYPE[0].Description)} closeForm={() => setShowHistory(false)} />}
        {showContractRunReport && <ContractRunReport selectedData={gridData.filter((x) => x.PlatformType?.Description == PLATFORMTYPE[0].Description)} closeForm={() => setShowContractRunReport(false)} />}
        {showParentContract && <CollectionSelection entityname={ENTITYNAME.Contract} closeForm={() => setShowParentContract(false)} setDataList={parentContractData} title={lang.select_contract_collection_header}  width={"50vw"} mode={"single"} />}
    </>)
}

export default ContractEditForm;