/* eslint-disable */
import React, { useEffect } from "react";
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RefreshIcon from '@mui/icons-material/Refresh';
import SaveIcon from '@mui/icons-material/Save';
import DownloadIcon from '@mui/icons-material/Download';
import OpenIcon from '@mui/icons-material/CalendarToday';
import ContentCutIcon from '@mui/icons-material/ContentCut';
import ContentPasteIcon from '@mui/icons-material/ContentPaste';
import FilterIcon from '@mui/icons-material/FilterAlt';
import DeleteFooterIcon from '@mui/icons-material/CleaningServices';
import DeleteAllSecondaryEventIcon from '@mui/icons-material/Close';
import ApplyStyleSheetIcon from '@mui/icons-material/Image';
import SettingsSuggestIcon from '@mui/icons-material/SettingsSuggest';
import AssignmentReturnedIcon from '@mui/icons-material/AssignmentReturned';
import FactCheckIcon from '@mui/icons-material/FactCheck';
import PdfIcon from '@mui/icons-material/PictureAsPdf';
import { MONTH, SCHEDULETYPE } from "../../framework/constant/constant";
import { Switch } from "@progress/kendo-react-inputs";
import { ChangeCircle, CloseOutlined } from "@mui/icons-material";
import { useTranslation } from "../../locale/useTranslation";

export const ScheduleHeader = (props) => {
    const lang = useTranslation();
    const {
        date,
        selectedChannel,
        isAutoSave,
        handleScheduleDataSave,
        handleAutoSave,
        refresh,
        handleScheduleDeleteByButton,
        handleExport,
        exportPlanetCast,
        handleScheduleCopyByButton,
        handleScheduleCutByButton,
        handleSchedulePasteByButton,
        openPlanning,
        handleFilterDialog,
        deleteAllFooter,
        deleteAllSecondaryEvent,
        onStyleSheetSelection,
        handlePdfExport,
        onSchedulePreference,
        onCheckList,
        setShowFilterGrid,
        setShowFilterGridData,
        schedulerData,
        setSearchWord,
        showGlobalReplacement,
        downloadExcel,
        onDateChangeLeft,
        onDateChangeRight,
    } = props

    useEffect(() => {
    }, []);

    const onSearch = (e) => {

        const searchWord = e.target.value.toLowerCase();
        if (searchWord.length == 0) {
            setSearchWord('false');
            setShowFilterGrid(false);
            setShowFilterGridData([]);
        } else {
            setSearchWord(searchWord);
            setShowFilterGrid(true);
            var newFilterGridData = [];
            schedulerData.map(parent => {
                parent.children.map(child => {
                    if (child?.mediaEpisode?.Title?.toLowerCase().includes(searchWord) ||
                        child?.MediaId?.toLowerCase().includes(searchWord) ||
                        child?.mediaEpisode?.AssetId?.toLowerCase().includes(searchWord) ||
                        child?.mediaEpisode?.MediaCategory?.Description?.toLowerCase().includes(searchWord) ||
                        Object.keys(SCHEDULETYPE).some(key => key.toLowerCase().includes(searchWord) && SCHEDULETYPE[key] == child?.Type)) {

                        newFilterGridData.push(child);

                    }
                })
            });

            if (newFilterGridData.length > 0) {
                setShowFilterGridData(newFilterGridData);
            } else {
                setShowFilterGridData([]);
            }

        }
    }


    const otherIconStyles = { textAlign: "center", fontSize: "1.12em", color: "#444746", size: "0.6em", cursor: "pointer" };

    return <>
        <div className="row mt-2  mr-2 mb-3" style={{ fontFamily: "Roboto" }}>

            <div className="col-10" >
                <div className="flex-container" style={{ alignItems: "left", justifyContent: "left" }}>

                    <div title={lang.delete_button_tooltip} className="iconBase">
                        <DeleteIcon title='delete' style={otherIconStyles} onClick={handleScheduleDeleteByButton} />
                    </div>
                    <div title={lang.cut_button_tooltip} className="iconBase">
                        <ContentCutIcon style={otherIconStyles} onClick={handleScheduleCutByButton} />
                    </div>
                    <div title={lang.copy_Schedule_button_tooltip} className="iconBase">
                        <ContentCopyIcon style={otherIconStyles} onClick={handleScheduleCopyByButton} />
                    </div>
                    <div title={lang.paste_button_tooltip} className="iconBase">
                        <ContentPasteIcon style={otherIconStyles} onClick={handleSchedulePasteByButton} />
                    </div>

                    <div title={lang.refresh_button_tooltip} className="iconBase">
                        <RefreshIcon style={otherIconStyles} onClick={refresh} />
                    </div>
                    <div title={lang.save_button_tooltip} disabled={isAutoSave} className="iconBase">
                        <SaveIcon style={otherIconStyles} onClick={handleScheduleDataSave} />
                    </div>
                    <div title={lang.export_playlist_button_tooltip} className="iconBase">
                        <DownloadIcon style={otherIconStyles} onClick={handleExport} />
                    </div>
                    <div title={lang.export_planet_cast_button_tooltip} className="iconBase">
                        <AssignmentReturnedIcon style={otherIconStyles} onClick={exportPlanetCast} />
                    </div>
                    <div title={lang.export_excel_button_tooltip} onClick={downloadExcel} className="iconBase fa fa-file-excel pb-2" style={{ ...otherIconStyles, display: 'flex', alignItems: 'center' }} >
                        {/* export excel */}
                    </div>
                    <div title={lang.download_pdf_button_tooltip} className="iconBase">
                        <PdfIcon style={otherIconStyles} onClick={handlePdfExport} />
                    </div>
                    <div title={lang.open_planning_button_tooltip} className="iconBase">
                        <OpenIcon style={otherIconStyles} onClick={openPlanning} />
                    </div>
                    <div title={lang.delete_all_footer_button_tooltip} className="iconBase">
                        <DeleteFooterIcon style={otherIconStyles} onClick={deleteAllFooter} />
                    </div>

                    <div title={lang.delete_All_secondary_events_button_tooltip} className="iconBase">
                        <DeleteAllSecondaryEventIcon style={otherIconStyles} onClick={deleteAllSecondaryEvent} />
                    </div>

                    <div title={lang.global_replacement_button_tooltip} className="iconBase">
                        <ChangeCircle style={otherIconStyles} onClick={showGlobalReplacement} />
                    </div>

                    <div title={lang.apply_style_sheet_button_tooltip} className="iconBase">
                        <ApplyStyleSheetIcon style={otherIconStyles} onClick={onStyleSheetSelection} />
                    </div>
                    <div title={lang.schedule_preference_button_tooltip} className="iconBase">
                        <SettingsSuggestIcon style={otherIconStyles} onClick={onSchedulePreference} />
                    </div>
                    <div title={lang.check_list_button_tooltip} className="iconBase">
                        <FactCheckIcon style={otherIconStyles} onClick={onCheckList} />
                    </div>
                    <div title={lang.auto_save_button_tooltip} className="iconBase">
                        <Switch
                            size={"small"}
                            trackRounded={"medium"}
                            thumbRounded={"medium"}
                            defaultChecked={isAutoSave}
                            onChange={handleAutoSave}
                        />
                    </div>
                    <div>
                        <input
                            type="search"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="search"
                            aria-label="type 2 or more letters"
                            placeholder={lang.search_button_text}
                            onChange={onSearch}
                        />
                    </div>
                </div>
            </div>

            <div className="col-2" style={{ textAlign: "right" }}>
                <div className="row">
                    <div className="col">
                        <div className="row-reverse" style={{}}>
                            <span onClick={handleFilterDialog} style={{ fontFamily: "Roboto", fontWeight: "bold", fontSize: "18px", color: "#4b4d4d", cursor: "pointer" }} >{selectedChannel.name} </span>
                        </div>
                        <div>
                            <span> <i className="fa-lg fa-solid fa-square-caret-left fa-md pt-1 mr-1 pointer" onClick={onDateChangeLeft}></i> </span>
                            <span onClick={handleFilterDialog} style={{ fontFamily: "Roboto", fontSize: "16px", color: "#4b4d4d" }}>{date.getDate()} {MONTH[date.getMonth()]} {date.getFullYear()} </span>
                            <span style={{ padding: '0px', margin: '0px', color: "#4b4d4d" }} />
                            <span> <i className="fa-lg fa-solid fa-square-caret-right fa-md pt-1 ml-1 pointer" onClick={onDateChangeRight}></i> </span>
                        </div>


                    </div>

                </div>

            </div>

        </div>
        {/* horizontal Divider        */}
        {/* <div className="row mb-3" style={{ margin: "0px 0px 0px 0px", width: "99%", borderBottom: '1px solid black', borderRadius: '5px!important' }} >
        </div> */}

    </>
}




export default ScheduleHeader;