import { Error } from "@progress/kendo-react-labels";
import { MaskedTextBox } from "@progress/kendo-react-inputs";
import { utility } from "../../../utility/utilityProvider";
//timepicker component with format
export const TimeRangePicker = (fieldRenderProps) => {
    const { validationMessage, visited, ...others } = fieldRenderProps;
    const defaultValue = fieldRenderProps.mask ?? '00:00';
    return (
      <div>
        <MaskedTextBox
          {...others}
          mask="00:00"
          defaultValue="00:00"
          value={fieldRenderProps.value ? utility.validateShortTimeString(fieldRenderProps.value) : defaultValue}
        />
        {/* {visited && validationMessage && <Error>{validationMessage}</Error>} */}
      </div>
    );
  };