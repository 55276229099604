/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME, FILEURL } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { useTranslation } from "../../locale/useTranslation";
import moment from "moment";

export const ProgramTimeConsumptionReport = () => {

    const defaultDates = utility.getFirstAndLastDateOfMonth(); 

    const blankDataItem = {
        fromDate: defaultDates.FirstDate,
        toDate: defaultDates.LastDate,
        Channel: {},
        isTXStatus: false
    }
    
    const lang = useTranslation();
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [gridData, setGridData] = useState([]);
    const [channels, setChannels] = useState([]);
    const [message, setMessage] = useState("");
    const [showTXStatus, setShowTXStatus] = useState(false);

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        let channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannels(channelRes.data);
    }

    const onChange = (e) => {
        if(e.target.name == 'isTXStatus'){
            setDataItem({ ...dataItem, isTXStatus: !dataItem.isTXStatus });
        }else{
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    };

    const isValid = () => {
        if (dataItem.fromDate == undefined || dataItem.Channel == "") {
            toast.error(`${lang.please_select_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.toDate == undefined || dataItem.toDate == "") {
            toast.error(`${lang.please_select_to_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Channel == undefined || Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true
    }

    const loadData = async () => {

        if (!isValid()) return;

        setMessage(lang.loading_pleasewait);

        let payload = {
            fromDate: utility.getDateMilisecondFromExcelDateTimeMilisecond(dataItem.fromDate),
            toDate: moment(dataItem.toDate).endOf('day').toDate().getTime(),
            channelSID: dataItem.Channel.SID,
            isTXStatus: dataItem.isTXStatus
        }

        const res = await API.getProgramTimeConsumptionReport(payload);
        console.log(res);
        if (res.success) {
            // show column based on isTXStatus
            dataItem.isTXStatus ? setShowTXStatus(true) : setShowTXStatus(false);
            // set grid data
            if (res.data.length > 0) {
                setGridData(res.data);
            }
            else {
                setGridData([]);
            }
        }
        else {
            setGridData([]);
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        setMessage("");
    }

    const downloadExcel = async () => {

        if (gridData.length == 0) {
            toast.error(lang.no_record_found_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        setMessage(lang.downloading_please_wait_message);
        let payload = {
            fromDate: utility.getDateMilisecondFromExcelDateTimeMilisecond(dataItem.fromDate),
            toDate: moment(dataItem.toDate).endOf('day').toDate().getTime(),
            channelSID: dataItem.Channel.SID,
            isTXStatus: dataItem.isTXStatus
        }
        let res = await API.exportPrgramTimeConsumtionReport(payload);
        console.log(res);
        if (res.success) {
            window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
            toast.success(`${lang.successfully_download_success_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        setMessage("");
    }

    return (<>
        <h1 className="page-title txt-color-bludeDark">
            <i
                className="fa fa-table marlr"
                style={{ fontSize: "20px", color: "GrayText" }}
            ></i>
            <span>{lang.program_time_consumption_report_page_breadcrumb}</span>
        </h1>
        <div className="row m-1">
            <div className="col-12">
                <div className="row">
                    <div className="col-2">
                        <label className="form-label">{lang.from_date_label} *</label>
                        <input type="date" className="form-control form-control-sm" name="fromDate" value={dataItem.fromDate} onChange={onChange} />
                    </div>
                    <div className="col-2">
                        <label className="form-label">{lang.to_date_label} *</label>
                        <input type="date" className="form-control form-control-sm" name="toDate" value={dataItem.toDate} onChange={onChange} />
                    </div>
                    <div className="col-2">
                        <label className="form-label">{lang.channel_label} *</label>
                        <DropDownList
                            data={channels}
                            textField="FullChannelName"
                            dataItemKey="SID"
                            name="Channel"
                            onChange={onChange}
                            value={dataItem.Channel}
                        />
                    </div>
                    <div style={{marginTop: '25px'}}>
                        <input type="checkbox" name="isTXStatus" value={dataItem.isTXStatus} onChange={onChange} />
                        <label className="ml-1">{lang.is_tx_status_label}</label>
                    </div>
                    <div className="col mt-4">
                        <ActionButton name={lang.show_button_text} onClick={loadData} />
                        <RoundButton icon='download' onClick={() => downloadExcel()} style={{ marginLeft: '10px' }} title={lang.download_button_tooltip} />
                        <span className="ml-2">{message}</span>
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <Grid data={gridData} style={{height: '70vh'}} resizable={true}>
                            <Column field="Title" title={lang.title_column} />
                            <Column field="SeasonNumber" title={lang.season_number_column} />
                            {showTXStatus && <Column field="TXStatus.Description" title={lang.tx_status_column} />}
                            <Column field="TotalRuns" title={lang.total_runs_column} />
                            <Column field="TotalRunTime" title={lang.total_run_time_column} />
                        </Grid>
                    </div>
                </div>
            </div>
        </div>
    </>)
}
export default ProgramTimeConsumptionReport;