/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as API from "../../../framework/API/api_digitalSign";
import { COLUMNSTYPE, COMPANYTYPE, ENTITYNAME, LOCALSTORAGE_KEY, ORIENTATION } from "../../../framework/constant/constant";
import { TimeCell } from "../../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { utility } from "../../../framework/utility/utilityProvider";
import { DateCell, LocalDateTimeColumn } from "../../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { ArrayCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { LookupCell } from "../../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import { ImageCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { useTranslation } from "../../../locale/useTranslation";
import RefreshButton from "../../../framework/forms/helpercomponents/buttons/RefreshButton";
import CustomPaginationDropDown from "../../../framework/forms/helpercomponents/CustomGridCells/CustomPaginationDropDown";
import { EnumCell } from "../../../framework/forms/helpercomponents/CustomGridCells/EnumCell";
import RoundButton from "../../../framework/forms/helpercomponents/buttons/RoundButton";
import { colorCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ColorCell";
import ContentPreviewPopup from "../component/ContentPreviewPopup";
import { isArray } from "underscore";
import MyCompanyCell from "../component/MyCompanyCell";
import ActionButton from "../../../framework/forms/helpercomponents/buttons/ActionButton";

export const PlaylistCollection = (props) => {

    const lang = useTranslation();
    const dataColumns = props?.columns ?? [];
    let page_take = props.page_take ?? 50;
    let wherestatement = props?.wherestatement ?? [];
    const initialSort = { field: 'SID', dir: 'asc' };
    const entityName = props.entityname;
    let enablePreview = props.enablePreview ?? false;
    const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
    const [isEnterpriseLogin] = useState(company?.CompanyType == COMPANYTYPE.ENTERPRISE);
    let Company_id = props?.Company_id ? isArray(props?.Company_id) ? props?.Company_id : [props?.Company_id] : isEnterpriseLogin ? null : utility.getValue(LOCALSTORAGE_KEY.COMPANIES).map(x=>x?._id);

    const [sort, setSort] = useState([initialSort])
    const [selectedState, setSelectedState] = useState([]);// multiple selection grid
    const [gridDataToShow, setGridDataToShow] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isNoRecords, setIsNoRecords] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [page, setPage] = useState({
        skip: 0,
        take: page_take,
    });
    const [total, setTotal] = useState(1);
    const [footerItem, setFooterItem] = useState({
        SelectedItems: 0,
    })
    const [showPreview, setShowPreview] = useState(false);
    const [orientation, setOrientation] = useState(ORIENTATION.Landscape);
    const [filters, setFilters] = useState( props?.showOrientationButton ? [['orientation', '=', orientation]] : []);
    
    const DATA_ITEM_KEY = '_id';
    const SELECTED_FIELD = 'selected';

    useEffect(() => {
        loaddata({ per_page: page.take, current_page: 1, orderby: 'SID', direction: 'asc' }, wherestatement, searchText, filters, Company_id);
    }, [Company_id]);

    const onSearch = (e) => {
        e.preventDefault();
        let text = e.target.value?.trim();
        setSearchText(text);
    };

    // Search 
    useEffect(() => {
        let sortEvent = sort[0] ?? initialSort;
        if (searchText != "") {
            const timeOut = setTimeout(() => {
                setPage({ skip: 0, take: page_take });
                loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, filters, Company_id);
            }, 700);
            return () => clearTimeout(timeOut);
        } else {
            setPage({ skip: 0, take: page_take });
            loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, filters, Company_id);
        }
    }, [searchText]);


    const loaddata = async (pagination, wherestatement, searchText, filters , Company_id) => {
        setIsLoading(true);
        if(!Company_id && isEnterpriseLogin) return
        props.setSelectedMedia([]);
        setFooterItem({ SelectedItems: 0 })
        setSelectedState([])

        try {
            let json = [];
            if(props?.entityname == ENTITYNAME.DigitalSignContentGroup){
                json = await API.getContentGroup({ page: pagination.current_page, perPage: pagination.per_page, query: wherestatement }, dataColumns, searchText, filters, Company_id)
            }else{
                json = await API.getEntitiesWithSearch(props.entityname, { page: pagination.current_page, perPage: pagination.per_page, query: wherestatement }, dataColumns, searchText, filters, Company_id);
            }
            setGridDataToShow(json.data);
            setTotal(json.pagination.total);
        } catch (error) {
            console.log("error", error);
            setIsNoRecords(true);
            setGridDataToShow([]);
        }
        setIsLoading(false);
    }

    const pageChange = async (e) => {
        let current_page = e.page.skip / e.page.take + 1;
        let sortEvent = sort[0] ?? initialSort
        await loaddata({ per_page: page_take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, filters, Company_id)
        setPage(e.page);
    };

    const MyDatalookupCell = (props) => (
        <LookupCell {...props} dataColumns={dataColumns} />
    );

    const onSelectionChange = (event) => {

        const ctrlKey = event.ctrlKey || event.metaKey;
        const rowIndex = event.startRowIndex;
        const dataItems = event.dataItems;
        const dataItem = dataItems[rowIndex];
        const shiftKey = event.shiftKey;

        let newSelectedData = [];

        if (ctrlKey) {
            let alreadySelected = selectedState.some(item => item._id == dataItem._id);
            if (alreadySelected) {
                newSelectedData = selectedState.filter(item => item._id != dataItem._id)
            } else {
                newSelectedData = [...selectedState, dataItem]
            }
        } else if (shiftKey) {
            let start = Math.min(rowIndex, selectedState.length > 0 ? dataItems.findIndex(x => x._id == selectedState[0]._id) : 0);
            let end = Math.max(rowIndex, selectedState.length > 0 ? dataItems.findIndex(x => x._id == selectedState[0]._id) : 0);
            newSelectedData = dataItems.slice(start, end + 1);
        } else {
            let alreadySelected = selectedState.length == 1 && selectedState[0]._id == dataItem._id ? true : false
            if (alreadySelected) {
                newSelectedData = []
            } else {
                newSelectedData = [dataItem]
            }
        }
        setSelectedState(newSelectedData);
        props.setSelectedMedia(newSelectedData)
        setFooterItem({ SelectedItems: newSelectedData.length })

    };

    const GridRowRenderMediaLibrary = (tr, props) => {
        const trProps = {
            draggable: true,
            onDragStart: (e) => {
                console.log("Drag start from media library")
                const propsData = [props.dataItem]

                // On Media Library Drag Start, if One Item Is Selected, We are setting drag item as selected item
                if (selectedState && selectedState.length == 1) {
                    setSelectedState(propsData);
                }

                e.dataTransfer.clearData();
                if (selectedState.length > 1) {
                    e.dataTransfer.setData("text/plain", JSON.stringify({ src: entityName, propsData: selectedState }));

                } else {
                    e.dataTransfer.setData("text/plain", JSON.stringify({ src: entityName, propsData }));
                }

                console.log("Drag end from media library");
                // set drag image
                let img = document.createElement('drag-ghost');
                img.id = "drag-ghost";
                let data = props?.dataItem?.fileName?.toString()?.substring(0, 10);
                img.textContent =
                    selectedState.length > 1 ? (selectedState.length.toString() + " Selected - " + data) : data;
                img.style.opacity = 1;
                img.style.whiteSpace = "pre-line";
                img.style.color = 'black';
                img.style.backgroundColor = 'white';
                document.body.appendChild(img);
                e.dataTransfer.setDragImage(img, 0, 0);

            },
            onDragOver: e => {
                e.preventDefault();
            },
            onDragEnd: e => {
                console.log("Drag end from media library")
                // remove drag image
                let img = document.getElementById("drag-ghost");
                if (img)
                    document.body.removeChild(img);

            }
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    const onRefresh = () => {
        setIsLoading(true);
        setSort([initialSort])
        loaddata({ per_page: page.take, current_page: 1, orderby: 'SID', direction: 'asc' }, wherestatement, searchText, filters, Company_id);
    }

    const onSortChange = (e) => {
        setSort(e.sort)
        let sortEvent = e.sort[0] ?? initialSort
        setPage({
            skip: 0,
            take: page_take,
        })
        loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText, filters, Company_id);
    }

    const handleOrientation = () => {
        let newOrientation = orientation == ORIENTATION.Landscape ? ORIENTATION.Portrait : ORIENTATION.Landscape;
        setOrientation(newOrientation);
        let query = [['orientation', '=', newOrientation]];
        setFilters(query);
        setPage({skip: 0, take: page_take})
        loaddata({ per_page: page.take, current_page: 1, orderby: 'SID', direction: 'asc' }, wherestatement, searchText, query, Company_id);    
    }

    const onAddItemPlaylist = () => {
        console.log("Add item in playlist");
        props?.onDrop && props?.onDrop({}, selectedState, false, false );
        setSelectedState([]);
    }

    return (
        <div style={{ position: 'absolute', left: 0, height: "100%", width: '100%', padding: '5px', marginTop: '-12px', }}>

            <div className="flex-container">
                <h1 className="page-title txt-color-bludeDark" style={{margin: '0px'}}>
                    <i className="fa fa-table marlr" style={{ fontSize: "20px", color: "GrayText" }} />
                    <span>{props?.title ?? ""}</span>
                </h1>
                <div className="flex-container-reverse flex-item-auto">
                    <RefreshButton onClick={onRefresh} />
                    <RoundButton  onClick={props?.addNew}  title={lang.add_new_button_tooltip}/>
                    {enablePreview && selectedState.length > 0 && <RoundButton icon={'eye'} onClick={() => setShowPreview(true)} title={lang.preview_button_tooltip}/>}
                    {props?.showOrientationButton && 
                        <RoundButton btnColor='primary' iconColor='white' onClick={handleOrientation} icon={orientation == ORIENTATION.Portrait ? "mobile-screen-button" : "mobile-screen-button fa-rotate-90"}/>
                    }
                    {(props?.entityname == ENTITYNAME.DigitalSignContent && selectedState.length > 1) && <div style={{ position: 'absolute', bottom: '14%', zIndex: 10, left: 0, width: '100%', padding: '0px 6px 0px 6px' }}>
                        <button 
                            style={{
                                borderRadius: '6px', 
                                height: "calc(1.4285714286em + 10px)", 
                                width: '100%',
                                fontWeight: 500,
                                padding: "0px",
                            }} 
                            className="btn btn-success"
                            title={lang.add_button_tooltip} 
                            onClick={onAddItemPlaylist}
                        >{lang.add_button_text}</button>
                    </div>}
                </div>
            </div>
            <div className="flex-container">
                <div className="flex-container-reverse flex-item-auto">
                    <div className="input-group input-group martb" >
                        <input
                            type="text"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="searchInput"
                            placeholder={lang.search_button_text}
                            onChange={onSearch}
                            onFocus={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(true);
                                }
                            }}
                            onBlur={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(false);
                                }
                            }}
                        />
                        <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                </span>
                            </div>
                        </button>
                    </div>

                </div>
            </div>

            {isLoading && <div className="ml-1">
                <i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i>
            </div>}
            {isNoRecords && <div style={{ margin: "0px 500px" }}><h4><strong>{lang.no_records_found}</strong></h4></div>}

            {!isLoading && !isNoRecords &&
                <Grid
                    className="ScheduleMediaCollection"
                    data={gridDataToShow?.map(item => {
                        item[SELECTED_FIELD] = selectedState.some(dataItem => dataItem._id == item._id)
                        return item;
                    })}
                    sort={sort}
                    sortable={true}
                    onSortChange={onSortChange}
                    rowRender={GridRowRenderMediaLibrary}
                    reorderable={true}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: true,
                        cell: false,
                        mode: {
                            value: 'multiple',
                            label: 'Multiple selection mode'
                        },
                    }}
                    onSelectionChange={onSelectionChange}
                    pageable={true}
                    skip={page.skip}
                    take={page.take}
                    onPageChange={pageChange}
                    total={total}
                    resizable={true}
                    pager={(props) => <CustomPaginationDropDown {...props} />}
                >
                    {/* Rows Loop */}
                    {dataColumns?.map((column, index) => {
                        if(!isEnterpriseLogin && column.type === COLUMNSTYPE.company){
                            return;
                        }
                        if (column.type === COLUMNSTYPE.date) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={DateCell}
                                    title={column.label}
                                    width={column.width ?? 140}
                                    sortable={true}
                                />
                            );
                        } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={TimeCell}
                                    title={column.label}
                                    width={column.width ?? 140}
                                    sortable={true}
                                />
                            );
                        } else if (column.name === "SID") {
                            return;
                        } else if (column.type === COLUMNSTYPE.image) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={ImageCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                    sortable={false}
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.select) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyDatalookupCell}
                                    title={column.label}
                                    width={column.width ?? 140}
                                    sortable={false}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.localdatetime) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={LocalDateTimeColumn}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.enum) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={EnumCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                    format={column.format ?? "Description"} //display item in object
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.array) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={ArrayCell}
                                    title={column.label}
                                    width={column.width ?? 140}
                                    format={column.format ?? "Description"} //display item in object
                                    sortable={false}
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.color) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={colorCell}
                                    title={column.label}
                                    width={column.width ?? 100}
                                    sortable={false}
                                />
                            );
                        } else if (isEnterpriseLogin && column.type === COLUMNSTYPE.company) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyCompanyCell}
                                    title={column.label}
                                    width={column.width ?? 80}
                                    sortable={false}
                                />
                            );
                        } else {
                            return (
                                column.type !== COLUMNSTYPE.hidden && (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        title={column.label}
                                        width={column.width ?? 140}
                                        sortable={true}
                                    />
                                )
                            );
                        }
                    })}
                </Grid>}

            <div className='row' style={{ display: "flex" }}>
                <div style={{ marginLeft: "20px" }}><p>{'Selected Items'} : {footerItem.SelectedItems}</p></div>
            </div>
            {showPreview && <ContentPreviewPopup selectedItem={selectedState.length > 0 ? selectedState[0] : {}} onClose={() => setShowPreview(false)} />}
        </div>
    );
};
