import './App.css';
import './Styles/smartadmin-production.min.css'
import './Styles/smartadmin-production-plugins.min.css'
import './Styles/your_style.css'
import './Styles/BookingStatusDashboardStyle.css'
import Routes from './routes';

function App() {
  
  return <Routes />
}

export default App;