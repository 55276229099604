import { useEffect, useRef, useState } from "react";
import { utility } from "../utility/utilityProvider";
import { ContextMenu } from "primereact/contextmenu";
import EPGChannelEditForm from "../../modules/EPG/EPGChannelEditForm";

export const GridViewCollection = (props) => {


    const {gridData, pageSize} = props;
    const [tileViewGridData, setTileViewGridData] = useState([]);
    const selectedItemsRef = useRef([]);
    const contextMenuRef = useRef();
    const setSelectedItems = (data) => {
        selectedItemsRef.current = data;
    };
    const [showEditForm,setShowEditForm] = useState(false);

    useEffect(() => {
        setTileViewGridData(gridData.slice(0, pageSize));
    }, [props.gridData]);

    const menuModel = [
        { label: "Edit", icon: "fa-solid fa-pencil fa-lg", command: () => { setShowEditForm(true) }},
    ];

    const onContextMenu = (e, item) => {
        contextMenuRef.current.show(e);
        setSelectedItems(item);
    };

    return (
        <>
        <div
            style={{
            height: "80%",
            marginLeft: '50px',
            overflowY: "auto",
            overflowX: "hidden",
            display: "flex",
            alignItems: "start",
            justifyContent: "start",
            flexWrap: "wrap",
            }}
            onScroll={(e) => {
            if (
                e.target.scrollTop + e.target.clientHeight + 50 >
                e.target.scrollHeight
            ) {
                setTileViewGridData([
                ...tileViewGridData,
                ...gridData.slice(
                    tileViewGridData.length,
                    tileViewGridData.length + pageSize
                ),
                ]);
            } else if (e.target.scrollTop == 0) {
                setTileViewGridData(gridData.slice(0, pageSize));
            }
            }}
        >
            {tileViewGridData.map((item, index) => {
            return (
                <div style={{ flex: "25%" }}>
                <div
                    style={{ cursor: "pointer", margin: "10px", height: "220px", minWidth: "180px", maxWidth: "240px"}}
                    onContextMenu={() => {}}
                    onClick={() => {}}
                    className="card"
                    key={index}
                    title={utility.subString(item?.Description, 15)}
                >
                    <img src={item?.LogoUrl} style={{ height: "150px", objectFit: "cover" }} className="card-img-top" alt={item?.Description} />
                    <div style={{ display: "grid", alignContent: "space-around", height: "100%"}} >
                    <p className="card-title" style={{ fontSize: "medium", fontWeight: "500" }}
                    >
                        {utility.subString(item?.Description, 15)}
                    </p>
                    <div
                        className="card-subtitle mb-2 text-muted"
                        style={{ fontSize: "small" }}
                    >
                    </div>
                    </div>
                    <div
                        style={{
                        position: "absolute",
                        bottom: "5px",
                        right: "10px",
                        padding: "0 7px",
                        borderRadius: "5px",
                        cursor: "pointer",
                        }}
                        onClick={(e) => onContextMenu(e, item)}
                    >
                        <i className="fa-solid fa-ellipsis" />
                    </div>
                </div>
                </div>
            );
            })}
            <ContextMenu model={menuModel} ref={contextMenuRef} />
            {showEditForm && <EPGChannelEditForm item={selectedItemsRef.current} cancelEdit={() => setShowEditForm(false)} refresh={props.refresh}/>}
        </div>
        </>
    );
};
