import { useState } from "react";
import { toast } from "react-toastify";
import { JsonViewer } from '@textea/json-viewer';
import { FILEURL } from "../../framework/constant/constant";

const PdfToJson = () => {

    const [formData, setFormData] = useState(null);
    const [jsonData, setJsonData] = useState(null);

    const handleFileUpload = (e) => {
        const file = e.target.files[0];
        const formData = new FormData();
        formData.append("file", file);
        setFormData(formData);
    }

    const handleDownlodJson = async () => {
        if (!formData) {
            toast.error("Please select a file to upload");
        }
        const response = await fetch("http://localhost:3000/v2/file/readPdf", {
            method: "POST",
            body: formData
        });
        const res = await response.json();
        console.log(res.data);
        window.open(FILEURL.BASEURL + 'downloadCSV/' + res.data);
        if (!res.success) {
            toast.error(res.message);
        }
    }

    return (
        <div>
            <input name="file" type="file" accept="application/pdf" onChange={handleFileUpload} />
            <button className="btn btn-success" onClick={handleDownlodJson}>
                Convert to CSV
            </button>
            <button className="btn btn-danger" onClick={handleDownlodJson}>
                Convert 
            </button>

        </div >
    )
}

export default PdfToJson;