/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import * as API from "../API/api";
import { ENTITYNAME, LOGEVENT, MODULE } from "../constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { EditPageHeader } from "../../components/EditPageHeader";
import { DropdownWithDefaultValue } from "../forms/helpercomponents/dropdown/DropdownWithDefaultValue";
import { ImageBrowser } from "../forms/helpercomponents/selectFile/ImageBrowser";
import BossDialog from "../../components/BossDialog";

export const PosterEditForm = (props) => {

  const [dataItem, setDataItem] = useState({
    Title : '',
    Description : '',
    ImageUrl : '',
    OttPosterTypeSID : '',
    Archive: false
  });

  const [ottPosterType, setOttPosterType] = useState([]);
  const [imageSelected,setImageSelected] = useState(false);
  const [imageFiles, setImageFiles] = useState(new Set());
  const [showForm, setShowForm] = useState(false);
  const lang=useTranslation();

  useEffect(() => {

    loadcombo();
    setEditItem();
  }, [])

  const loadcombo = async () => {

    let ottPosterType = await API.getDataLookup(ENTITYNAME.OttPosterType, {sort: { Description: 1 }});
    
    setOttPosterType(ottPosterType.data);
  }

  const handleChange = (e) => {
    if(e.target.name == 'ImageUrl' && e.target?.files){
      if(e.target.files != undefined){
        setImageFiles(e.target.files[0]);
      }else{
        setImageFiles(new Set());
      }
    }
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  }

  function setEditItem() {

    let dataItemLocal = {};

    if (props.item.SID > 0) {
      console.log(props.item);
      dataItemLocal = {
        ...props.item,
        SID: props.item.copy ? 0 : props.item.SID,
        ImageUrl: props.item.ImageUrl,
        Description: props.item.copy ? 'copy of ' + props.item.Description : props.item.Description,
        Title: props.item.copy ? 'copy of ' + props.item.Title : props.item.Title,
        FilePath: props.item.FilePath,
        OttPosterTypeSID: props.item.OttPosterTypeSID,
        Archive: props.item.Archive

      }

    } else {
      dataItemLocal = props.item;
    }
    setDataItem(dataItemLocal);
    setShowForm(true);

  }

  const isValid = () => {
  
    if(!(dataItem.OttPosterTypeSID)){
      toast.error(`${lang.please_select_poster_type_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });                        
      return false;
    }
    if(dataItem.Title=="" || dataItem.Title==undefined){
      toast.error(`${lang.please_enter_title_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.ImageUrl == undefined || Object.keys(dataItem.ImageUrl).length == 0) {
      toast.error(`${lang.ott_assets_video_url_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
   
    return true;
  }

  const handleSubmit = async () => {

    if(isValid()){

      let imageUrl = dataItem.ImageUrl;

      console.log(imageFiles)
      if(imageFiles.size > 0){
        let resUrl = await API.uploadImage(imageFiles);
        if(!resUrl.success){
         toast.error(resUrl.message, {
           position: toast.POSITION.TOP_RIGHT
         });
         return;
        }
        imageUrl=resUrl.data;
        dataItem.ImageUrl = imageUrl;
     }

      const saveData = {
        _id: dataItem._id,
        SID: dataItem.SID,
        Title: dataItem.Title,
        Description: dataItem.Description,
        ImageUrl: imageUrl,
        FilePath: dataItem.FilePath,
        Archive: dataItem.Archive ?? false,
        OttPosterTypeSID: typeof dataItem.OttPosterTypeSID === 'object' ? dataItem.OttPosterTypeSID.SID : dataItem.OttPosterTypeSID,
      }
      
      const res = await API.saveData(ENTITYNAME.OttPosters,saveData);
      if(res.success){
        let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_POSTER : LOGEVENT.UPDATE_POSTER, module: MODULE.OTT_POSTERS, data: res.data, message: res.message };
        API.SaveLogs(logData);
        props.refresh();
        props.cancelEdit();
      }else{
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
      
    }
  }

  return (
    <>
      <div>
        <BossDialog
          title={props.item.SID > 0 ? props.item.Title : `${lang.create_poster_dialog_header}`}
          onClose={props.cancelEdit}
          width="700px"
          height="372px"
        >
          {showForm && <Form
            render={(formRenderProps) => (
              <FormElement>
                <EditPageHeader title={(props.item.SID > 0 ? dataItem.Title: `${lang.new_title_editpageheader}`)} onSubmit={handleSubmit} onCancel={props.cancelEdit}/>
                <div className="row">
                  <div className="col-12">

                    <div className="row mt-2">
                      <div className="col-6">
                        <label>{lang.poster_type_label} *</label>
                        <DropdownWithDefaultValue
                          data={ottPosterType}
                          name="OttPosterTypeSID"
                          textField="Description"
                          dataItemKey="_id"
                          value={dataItem.OttPosterTypeSID}
                          onChange={handleChange}
                        />
                      </div>
                      <div className="col-6">
                      <label>{lang.title_label} *</label>
                        <input
                          name={"Title"}
                          type = "text" 
                          className="form-control form-control-sm"
                          onChange={handleChange}
                          value={dataItem.Title}
                        />
                      </div>
                    </div>

                    <div className="row mt-2">
                      <div className="col-12">
                        <label>{lang.description_label}</label>
                        <input
                          name={"Description"}
                          className={'form-control form-control-sm'}
                          type = "text" 
                          onChange={handleChange}
                          value={dataItem.Description}
                        />
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-6">
                        <label>{lang.image_url_label} *</label>
                        <ImageBrowser name = {'ImageUrl'} value={dataItem.ImageUrl} onChange={handleChange} setImageSelected = {setImageSelected} hidePosterLibrary={true}/>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6" >
                        <Field
                          name={"Archive"}
                          component={Checkbox}
                          label={lang.archive}
                          checked={dataItem.Archive}
                          onChange={handleChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </FormElement>
            )}
          />}
        </BossDialog>
      </div>
    </>)
};