/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Grid, GridColumn as Column, } from "@progress/kendo-react-grid";
import { ShortTimeCell, TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";

import { useTranslation } from "../../locale/useTranslation";
import PreferenceRoundButton from "../../framework/forms/helpercomponents/buttons/PreferenceRoundButton";
import { UserPreference } from "../userPreference/UserPreference";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY, LOGEVENT, MODULE } from "../../framework/constant/constant";

import { DateCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { LookupCell } from "../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { ContextMenu } from "primereact/contextmenu";
import * as API from '../../framework/API/api';
import { useNavigate } from "react-router-dom";
import { bookingSocket } from "../../framework/socket/bookingSocket";
import { toast } from "react-toastify";
import { utility } from "../../framework/utility/utilityProvider";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const sortArray = [
    { key : 'Title' , label : 'Title'},
    { key : 'SpotRate' , label : 'Spot Rate'},
    { key : 'TimeRangeFrom' , label : 'Time Range'},
  ]


export const ScheduleBookedCommercialCollection = (props) => {

    const { bookingDataColumns, bookingData, loadBookingCommercialdata, isBookingLoading } = props

    const [selectedState, setSelectedState] = useState([]);
    const searchTextRef = useRef();

    const DATA_ITEM_KEY = '_id';
    const SELECTED_FIELD = 'selected';

    //possible props
    const [isNoRecords, setIsNoRecords] = useState(false);
    const [showPreferences, setShowPreferences] = useState(false);
    const [sorted,setSorted] = useState({ key : 'Title' , label : 'Title'});

    const lang = useTranslation();
    const navigate = useNavigate();
    const bookedCommercialCollectionCm = useRef();//FOR BOOKED COMMERCIAL COLLECTION RIGHT CLICK : CONTEXT MENU

    const [bookingFilterdata, setBookingFilterdata] = useState([]);
    const [footerItem, setFooterItem] = useState({
        SelectedItems: 0,
        TotalDuration: 0,
    })
    console.log(bookingData)

    useEffect(() => {
        //By Default Sorted by title
        var sortedData = bookingData.sort((a, b) => {
            const nameA = a.Title.toUpperCase();
            const nameB = b.Title.toUpperCase();
            if (nameA < nameB) {
              return -1;
            }
            if (nameA > nameB) {
              return 1;
            }
            return 0;
          });
        setBookingFilterdata(sortedData);
        if (searchTextRef.current.value.length > 0) {
            onSearch(searchTextRef.current);
        }
    }, [bookingData])



    const onCloseUserPrefence = () => {
        setShowPreferences(!showPreferences);
        loadBookingCommercialdata();
    }


    const onSearch = (saearchTarget) => {
        const keyword = saearchTarget.value.toLowerCase();
        if (keyword == '') {
            setBookingFilterdata(bookingData);
            return;
        }

        let results = bookingData.filter(((item) => {

            return item?.Title?.toLowerCase().includes(keyword)
                || item?.campaign?.Title?.toLowerCase().includes(keyword)
                || item?.campaign?.CampaignNo?.toLowerCase().includes(keyword)
                || item?.AssetId.toLowerCase().includes(keyword);
        })
        )
        setBookingFilterdata(results);

    };

    const onSelectionChange = (event) => {

        const ctrlKey = event.ctrlKey || event.metaKey;
        const rowIndex = event.startRowIndex;
        const dataItems = event.dataItems;
        const dataItem = dataItems[rowIndex];
        var newSelectedData = [];

        if (ctrlKey) {
            let alreadySelected = selectedState.some(item => item._id == dataItem._id);
            if (alreadySelected) {
                newSelectedData = selectedState.filter(item => item._id != dataItem._id)
            } else {
                newSelectedData = [...selectedState, dataItem]
            }
        } else {
            let alreadySelected = selectedState.length == 1 && selectedState[0]._id == dataItem._id ? true : false
            if (alreadySelected) {
                newSelectedData = []
            } else {
                newSelectedData = [dataItem]
            }
        }
        setSelectedState(newSelectedData);
        setFooterItem({ SelectedItems: newSelectedData.length, TotalDuration: newSelectedData.reduce((acc, x) => acc + x.Duration, 0) })
    }

    const MyDatalookupCell = (props) => (
        <LookupCell {...props} dataColumns={bookingDataColumns} />
    );

    const GridRowRenderBookedCommercial = (tr, props) => {
        const trProps = {
            draggable: true,
            onDragStart: (e) => {
                let propsData = [props.dataItem]

                // On Media Library Drag Start, if One Item Is Selected, We are setting drag item as selected item
                if (selectedState && selectedState.length == 1) {
                    setSelectedState(propsData);
                }
                e.dataTransfer.clearData();

                if (selectedState.length > 1) {
                    propsData = selectedState;
                }

                console.log(selectedState)

                e.dataTransfer.setData("text/plain", JSON.stringify({ src: ENTITYNAME.Booking, propsData }));

                // making selected list blank as it was creating duplicate 
                // old selected was still in selection and drops again with new ones
                setSelectedState([]);
            },

            onDragOver: e => {
                e.preventDefault()


            },
            onContextMenu: (e) => {
                e.preventDefault();
                setSelectedState([props.dataItem]);
                console.log(bookedCommercialCollectionCm.current)
                bookedCommercialCollectionCm.current?.show(e);

            },
            onWheel: (e) => {
                bookedCommercialCollectionCm.current?.hide(e);

            },
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    const onBookedCommercialMakeGoodPurge = async (makeGood, purge) => {

        if (selectedState.length <= 0) return;

        var response = await API.updateBookingSpotMakeGoodPurge([selectedState[0]._id], makeGood, purge);
        if (response.success) {
            toast.success(`Item ${purge ? 'Purged' : 'MakeGood'} successfully!!`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            loadBookingCommercialdata();
            bookingSocket.onMakeGood(selectedState[0]);
            var _event = makeGood ? LOGEVENT.MAKE_GOOD : LOGEVENT.PURGE;
            var _msg = makeGood ? LOGEVENT.MAKE_GOOD : LOGEVENT.PURGE;
            let logData = { event: _event, module: MODULE.SCHEDULING, data: selectedState[0], message: _msg + " FROM SCHEDULE" };
            API.SaveLogs(logData);
        }

    }

    const onOpenCampaign = () => {

        console.log(selectedState[0]);
        navigate(`/home/Campaign/${selectedState[0].campaign.SID}`, { state: { copy: false } })

    }
    const onSortChange = (e) => {
        var shortedByDate = [];
        if(e.target.value.key == 'Title'){
            shortedByDate = bookingFilterdata.sort((a, b) => {
                const nameA = a[e.target.value.key].toUpperCase();
                const nameB = b[e.target.value.key].toUpperCase();
                if (nameA < nameB) { return -1; }
                if (nameA > nameB) { return 1; }
                return 0;
              });
        }else{
            shortedByDate = bookingFilterdata.sort((a, b) => a[e.target.value.key] - b[e.target.value.key]);
        }
        setSorted(e.target.value);
        setBookingFilterdata(shortedByDate);    
      }

    return (
        <>
            <div style={{
                position: 'absolute',
                left: 0,
                height: "100%",
                width: '100%',
                padding: '5px',
                marginTop: '-12px',
            }}>

                <div className="flex-container">
                    <div className="ml-1">
                        <div><label htmlFor="TabView">Sort By</label></div>
                        <DropDownList
                            data={sortArray}
                            name="SortBy"
                            textField="label"
                            dataItemKey="key"
                            onChange={onSortChange}
                            value={sorted}
                            style={{ width: "100px" }}
                        />
                    </div>
                    <div className="flex-container-reverse flex-item-auto mt-4">
                        <PreferenceRoundButton onClick={() => { setShowPreferences(!showPreferences) }} />
                        <RoundButton icon={"refresh"} title={lang.refresh_button_tooltip} onClick={loadBookingCommercialdata} />
                    </div>
                </div>

                <div className="flex-container">
                    <div className="flex-container-reverse flex-item-auto">
                        <div className="input-group input-group martb" >
                            <input type="text" className="form-control shadow-inset-2 pt-0 pb-0"
                                id="searchInput"
                                aria-label="type 2 or more letters"
                                placeholder={lang.search_button_text}
                                ref={searchTextRef}
                                onChange={(e) => {

                                    onSearch(e.target);
                                }}
                                onFocus={(e) => {
                                    props.setOutSideFocus(true);
                                }}
                                onBlur={(e) => {
                                    props.setOutSideFocus(false);
                                }}
                            />
                            <button id="search" style={{ border: "0px", backgroundColor: "white", height: "40px" }}>
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <i style={{ margin: "5px 0px" }} className="fa fa-search" />
                                    </span>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>

                {isNoRecords && <div style={{ margin: "0px 500px" }}><h4><strong>{lang.no_records_found}</strong></h4></div>}
                {!isNoRecords && <Grid
                    className="ScheduleBookedCollection"
                    data={bookingFilterdata.map(item => {
                        item[SELECTED_FIELD] = selectedState.some(dataItem => dataItem._id == item._id)
                        return item;
                    })}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: true,
                        cell: false,
                        mode: {
                            value: 'multiple',
                            label: 'Multiple selection mode'
                        },
                    }}
                    onSelectionChange={onSelectionChange}
                    rowRender={GridRowRenderBookedCommercial}
                    resizable={true}
                    reorderable={true}
                >

                    {/* Rows Loop */}
                    {bookingDataColumns.map((column, index) => {
                        if (column.type === COLUMNSTYPE.date) {
                            return (
                                <Column
                                    key={index}
                                    field={column.name}
                                    cell={DateCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        } else if (column.name.includes("TimeRangeFrom") || column.name.includes("TimeRangeTo")) {
                            return (
                                <Column
                                    key={index}
                                    field={column.name}
                                    cell={ShortTimeCell}
                                    title={column.label}
                                    width={column.width ?? 140}
                                />
                            );
                        } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                            return (
                                <Column
                                    key={index}
                                    field={column.name}
                                    cell={TimeCell}
                                    title={column.label}
                                    width={column.width ?? 140}
                                />
                            );
                        } else if (column.name === "SID") {
                            return;
                        } else if (column.type === COLUMNSTYPE.image) {
                            return (
                                <Column
                                    key={index}
                                    field={column.name}
                                    cell={ImageCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.select) {
                            return (
                                <Column
                                    key={index}
                                    field={column.name}
                                    cell={MyDatalookupCell}
                                    title={column.label}
                                    width={column.width ?? 140}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.array) {
                            return (
                                <Column
                                    key={index}
                                    field={column.name}
                                    cell={ArrayCell}
                                    title={column.label}
                                    width={column.width ?? 140}
                                    format={column.format ?? "Description"}
                                />
                            );
                        } else {
                            return (
                                column.type !== COLUMNSTYPE.hidden && (
                                    <Column
                                        key={index}
                                        field={column.name}
                                        title={column.label}
                                        width={column.width ?? 140}
                                    />
                                )
                            );
                        }
                    })}

                </Grid>}
                <div className="row">
                    {isBookingLoading ?
                        <div className="pl-4">
                            <i style={{ textAlign: "left", width: "100%", color: "GrayText" }} className="fas fa-spinner fa-pulse mt-3"></i></div>
                        : <div className="col">
                            <label style={{ fontSize: "10px", marginLeft: "10px" }}>Selected Items : {footerItem.SelectedItems}</label>
                            <label style={{ fontSize: "10px", marginLeft: "10px" }}>Total Duration : {utility.convertMilisecondsToStringWithFrames(footerItem.TotalDuration)}</label>
                            <label style={{ fontSize: "10px", marginLeft: "10px" }}>Total Bookings : {bookingData.length}</label>
                            <label style={{ fontSize: "10px", marginLeft: "10px" }}>Filter Bookings : {bookingFilterdata.length}</label>
                        </div>
                    }
                </div>

                <ContextMenu
                    ref={bookedCommercialCollectionCm}
                    model={[
                        {
                            label: `Make Good`, icon: "pi pi-fw pi-window-minimize", command: () => {
                                onBookedCommercialMakeGoodPurge(true, false);
                            }
                        },
                        {
                            label: `Purge`, icon: "pi pi-fw pi-inbox", command: () => {
                                onBookedCommercialMakeGoodPurge(false, true);
                            }
                        },
                        {
                            label: `Open Campaign`, icon: "pi pi-fw pi-box", command: () => {
                                ///TODO: Please open the campaihn here in another window 
                                onOpenCampaign();

                            }
                        },
                    ]}
                />

                {showPreferences && <UserPreference entityName={ENTITYNAME.SCHEDULE_BOOKED_COMMERCIAL_GRID} handleClose={onCloseUserPrefence} />}
            </div>
        </>
    );
};
