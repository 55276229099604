/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import { ENTITYNAME } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";
import BossDialog from "../../components/BossDialog";

export const SalesTeamMasterEditForm = (props) =>  {
    const [dataItem, setDataItem] = useState({ 
        SID : props.item.SID,
        Name:  props.item.copy ? "Copy of " + props.item.Name : props.item.Name ?? "",
        Region: props.item.Region ?? {},
        Archive: props.item.Archive ?? false
    });
    const lang=useTranslation();
    const [region,setRegion]= useState([]);

    useEffect(() => {
        loadcombo();
        console.log(props.item)
    },[]);

    const isValid = (data) => {

      if(dataItem.Name=="" || dataItem.Name==undefined){
        toast.error(`${lang.please_enter_name_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
      if(Object.keys(dataItem.Region).length == 0){
        toast.error(`${lang.please_select_region_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }

        return true;
    }
    

    const loadcombo = async () => {
        var regionRes = await API.getDataLookup(ENTITYNAME.Region, {sort: { Description: 1 }});
        setRegion(regionRes.data);

        if(props.item.SID == 0){
          setDataItem({ ...dataItem, Region: utility.getDefaultItem(regionRes.data)})

        }
        
    }

    const onChange = (e) => {
      if(e.target.name == "Archive"){
        setDataItem({...dataItem, [e.target.name]: !dataItem[e.target.name]});
      }else{
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
    }

    const handleSubmit = async () => {

      if(isValid()){
        var localData = {
          
            SID : dataItem.SID,
            Name: dataItem.Name,
            Region:{_id:dataItem.Region._id, SID:dataItem.Region.SID, Description:dataItem.Region.Description},
            Archive : dataItem.Archive ?? false,
            checkDuplicate:true,
            query:[["Name", "=", dataItem.Name]]
        }
 
        var res = await API.saveData(ENTITYNAME.SalesTeam, localData);
        if(res.success){
            props.cancelEdit();
            props.refresh();
            utility.deleteLocalStorageItem(ENTITYNAME.SalesTeam)
            return;
        }else{
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        }
    }

  return (
    <>
        {<BossDialog
            title={props.item.SID > 0 ? props.item.Name : `${lang.sales_team_dailog_header}`}
            onClose={props.cancelEdit}
            width={"600px"}
        >
          <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false}/>
            <Form
            render={(formRenderProps) => (
                <FormElement>
                    <div className="row mt-2">
                    <div className="col-12">
                        <div className="row">
                          <div className="col-6 form-group">
                            <label htmlFor="TabView">{lang.name_label} *</label>
                            <input type="text" className="form-control form-control-sm" name="Name" value={dataItem.Name} onChange={onChange}/>
                          </div>  
                          <div className="col-6 form-group">
                          <label htmlFor="TabView">{lang.region_label} *</label>
                          <DropDownList
                              style={{
                                backgroundColor:"white",
                              }}
                              data={region}
                              name="Region"
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem.Region}
                              onChange={onChange}
                              validator={(value) => value ? "" : "Please select the value"}
                            />  
                          </div> 
                        </div> 

                        <div className="row">
                          <div className="col-3 form-group">
                            <input type={"checkbox"} name={"Archive"}
                              value={dataItem.Archive}
                              checked={dataItem.Archive}
                              onChange={ onChange}/>  
                              <label className='ml-1'>{lang.archive}</label>                              
                          </div> 
                        </div>

                    </div>
                    </div>
                </FormElement>
            )} />
        </BossDialog>}
    </>
  )
}