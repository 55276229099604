//to show duration in string format on collection
import { utility } from "../../../utility/utilityProvider";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";

export const DropdownCell = (props) => {

  const field = props.field || "";

  return <td
    colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...useTableKeyboardNavigation(props.id)}
    //BY COMMENTING WITH LINE FOR ONCLICK EVENT SELECTION FOR GRID BY CLICKING TIME CELL IS NOT WORKING
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    // style={{textAlign : 'center'}}
    style={props.style}
  >
    <MultiSelect
      data={props.data}
      textField={props.textField ?? "Description"}
      value={props.dataItem[field]}
      onChange={(e) => props?.onChange(e, props?.dataItem)}
      className={props.className}
      style={{ ...props?.style }}
    />
  </td>;
};
