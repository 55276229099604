/* eslint-disable */
import React ,{useState,useEffect} from 'react';
import { Form,FormElement } from "@progress/kendo-react-form";
import { Grid, GridColumn} from "@progress/kendo-react-grid";
import { EditPageHeader } from "../../components/EditPageHeader";
import * as API from "../../framework/API/api";
import { ENTITYNAME, LOCALSTORAGE_KEY, SCHEDULETYPE } from "../../framework/constant/constant";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { Switch } from "@mui/material";
import BossDialog from "../../components/BossDialog";

export const SchedulePreference = (props) => {

    const user = utility.getValue(LOCALSTORAGE_KEY.userData);
    const [hideDeleteConfirmation ,setHideDeleteConfirmation] = useState(false);

    useEffect(() => {
        loadEditData();
    },[])

    const [SchedulePreferenceData,setSchedulePreferenceData] = useState([
        {
            Id : 0,
            EventType:"Header",
            FontSize:13,
            ForegroundColor:'#000000',
            BackgroundColor:"#ffffff",
            ScheduleTypeID : SCHEDULETYPE.Header,
            MediaCategoryTypeSID : 0
        },
        {
            Id : 1,
            EventType:"Segment",
            FontSize:13,
            ForegroundColor:'#000000',
            BackgroundColor:"#ffffff",
            ScheduleTypeID : SCHEDULETYPE.Segment,
            MediaCategoryTypeSID : 0
        },
        {
            Id : 2,
            EventType:"UnderRun",
            FontSize:13,
            ForegroundColor:'#000000',
            BackgroundColor:"#ffffff"  ,
            ScheduleTypeID : SCHEDULETYPE.UnderRun,
            MediaCategoryTypeSID : 0  
        },
        {
            Id : 3,
            EventType:"OverRun",
            FontSize:13,
            ForegroundColor:'#000000',
            BackgroundColor:"#ffffff",
            ScheduleTypeID : SCHEDULETYPE.OverRun,
            MediaCategoryTypeSID : 0
        },
        ...props.mediaCategoryTypePreferenceData  
    ]);
   

    const [schedulePreferenceModel,setSchedulePreferenceModel]=useState({
        SID : 0,
        user_id : user._id,
        entityName : "schedulepreference",
        value : {
            EventType: SchedulePreferenceData,
            hideDeleteConfirmation : false,
        }
    })
    
    const [dataItem,setDataItem]=useState(schedulePreferenceModel.value.EventType[0]); 

    const loadEditData = async() => {
       
        var args = {query: [ ['user_id','=',user._id], ['entityName','=',"schedulepreference"] ]}
        var SchedulePreferenceRes = await API.getData(ENTITYNAME.UserPreference, args);

        if(SchedulePreferenceRes.data.length != 0){

            const savedPreference = SchedulePreferenceRes.data[0];

            // update preference color based on saved
            const updatedPref = SchedulePreferenceData.map((d) => {

                const categoryPref = savedPreference.value.EventType.find(x => x.EventType == d.EventType);

                return {
                    ...d,
                    FontSize: categoryPref != undefined ? parseInt(categoryPref.FontSize) : parseInt(d.FontSize),
                    BackgroundColor: categoryPref != undefined ? categoryPref.BackgroundColor : d.BackgroundColor,
                    ForegroundColor: categoryPref != undefined ? categoryPref.ForegroundColor : d.ForegroundColor
                };
            })

            setSchedulePreferenceModel({
                ...savedPreference, 
                value: {
                    EventType: updatedPref,
                    hideDeleteConfirmation : savedPreference.value.hideDeleteConfirmation ?? false
                }
            });
            setDataItem(updatedPref[0]);
            setHideDeleteConfirmation(savedPreference.value.hideDeleteConfirmation ?? false);
        }
        
    }

    const onChange = (e) => {
        var index = schedulePreferenceModel.value.EventType.findIndex((x) => x.Id == dataItem.Id);

        setDataItem({...dataItem, [e.target.name]: e.target.value});

        var newData = [...schedulePreferenceModel.value.EventType];
        newData.splice(index,1,{...dataItem, [e.target.name]: e.target.value});

        setSchedulePreferenceModel({
            ...schedulePreferenceModel,
            value : {
                ...schedulePreferenceModel.value,
                EventType : newData
            }
        });
    }
    
    const gridRowRender = (tr, props) => {

        const trProps = {
            style : {
                // fontSize : props.dataItem.FontSize,
                color : props.dataItem.ForegroundColor,
                backgroundColor: props.dataItem.BackgroundColor
            },
            onClick:(e)=>{ setDataItem(props.dataItem)}
            
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };
    
    const handleSubmit = async() => {

        var dataToSave = {
            ...schedulePreferenceModel,
            value : {
                ...schedulePreferenceModel.value,
                hideDeleteConfirmation : hideDeleteConfirmation ?? false
            }
        }

        //VALIDATION FOR FONT SIZE
        let inValidFontSize = dataToSave.value.EventType.find(x => parseInt(x.FontSize) < 10 || parseInt(x.FontSize) > 30);
        if(inValidFontSize){
            console.log(inValidFontSize)
            toast.error(`Please enter Font Size for ${inValidFontSize.EventType} between 10 - 30`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        var res = await API.saveData("userPreferenceColumn",dataToSave);
     
        if(res.success)
        {
            props.closeForm();

            // resetting local storage of preference
            utility.deleteLocalStorageItem(LOCALSTORAGE_KEY.schedulepreference)
            await API.getDataLookup(ENTITYNAME.UserPreference, {
                query: [["entityName","=","schedulepreference"],["user_id","=",user._id]],
                sort: {SID:1},
                page: null,
                perPage: null
            }, LOCALSTORAGE_KEY.schedulepreference);

            props.updateSchedulingPreferenceStyle();
        }
        else
        {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
              });
        }
    }

    const cancelEdit = () => {
        props.closeForm();
    }

    return(
        <>
        <BossDialog
            title={"Schedule Preference"}
            onClose={props.closeForm}
            width={700}
            height={550}
        >
        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={cancelEdit} showTitle={false}/>
        <h6>Selected the Event you want to change</h6>
        <div className="row border mt-3">
        <div className="col-4 mt-2">
            <Grid data={schedulePreferenceModel.value.EventType} style={{cursor:"pointer" ,height : "350px" }} rowRender={gridRowRender}>
                <GridColumn field="EventType" title="Value"/>
            </Grid>
        </div>
        <div className='col'>
        <Form
            render={(formRenderProps) => (
                <FormElement>
                    <div className="row mt-2 ml-2">
                        <div className="col-12 mr-4">
                            <div className='row mt-4'>
                                <label className="col-4">Events Type</label>
                                <input value={dataItem.EventType}  name="EventType" className="col-6" disabled = {true}/>
                            </div>
                            <div className='row mt-4'>
                                <label className="col-4 mt-2">Font Size</label>
                                <div className="col-5"><input
                                    type={"number"}
                                    className=" form-control form-control-sm"
                                    name={"FontSize"}
                                    value={dataItem.FontSize}
                                    onChange={onChange}
                                    min={10}
                                    max={30}
                                />
                                <p className="text-muted" style={{ fontSize: "9.5px",margin : '0px' }}>{`Font size should be b/w 10 & 30`}</p>
                                </div>
                            </div>
                            <div className='row mt-4'>
                            <label className="col-4 mt-1">Foreground Color</label>
                                <input
                                    type = 'color'
                                    className="col-2"
                                    name="ForegroundColor"
                                    value={dataItem.ForegroundColor}
                                    onChange={onChange}
                                    placement="right"
                                />
                            </div>
                            <div className='row mt-4'>
                                <label className="col-4 mt-1">Background Color</label>
                                <input
                                    type = 'color'
                                    className="col-2"
                                    name="BackgroundColor"
                                    value={dataItem.BackgroundColor}
                                    onChange={onChange}
                                    placement="right"
                                    />
                            </div>
                            <div className="row mt-3">
                                <div className="col-8">
                                    <label>Hide Delete Confirmation</label>
                                    <Switch name="hideDeleteConfirmation" checked={hideDeleteConfirmation} onChange={(e)=> {setHideDeleteConfirmation(e.target.checked);} } />
                                </div>
                            </div>
                        </div>

                    </div>
                </FormElement>
            )}>
        </Form>
        </div>
        </div>
        </BossDialog>
        </>
    )
}