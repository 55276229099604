/* eslint-disable */
import React, { useRef, useState, useEffect } from "react";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY, LOGEVENT } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import {DialogActionsBar } from "@progress/kendo-react-dialogs";
import {
  ListBox,
  ListBoxToolbar,
  processListBoxData,
} from "@progress/kendo-react-listbox";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import BossDialog from "../../components/BossDialog";

export const UserPreference = (props) => {

  const { entityName, preferenceColumnENntityName } = props;

  const lang = useTranslation();
  const SELECTED_FIELD = "selected";
  const [userPreferenceColumns, setUserPreferenceColumns] = useState({
    Available: [],
    Selected: [],
  });
  //Add this for media management preference column
  const finalPreferenceColumnENntityName = preferenceColumnENntityName && preferenceColumnENntityName.length > 0 ? preferenceColumnENntityName : entityName;

  const user = utility.getValue(LOCALSTORAGE_KEY.userData);

  useEffect(() => {
    loadUserPreferenceColumns();
  }, [])

  const loadUserPreferenceColumns = async () => {
    let columns = await API.getFormData(finalPreferenceColumnENntityName ?? entityName);

    // filtering columns by remiving hidden items such as _id and SID
    columns = columns.filter((item) => item.type && item.type != COLUMNSTYPE.hidden);

    let args = { query: [['user_id', '=', user._id], ['entityName', '=', finalPreferenceColumnENntityName]] }

    let selectedColumns = await API.getData(ENTITYNAME.UserPreference, args);

    selectedColumns.data.map((selectedColumn) => {
      let toBeRemovedItemIndex = columns.findIndex((item) => item.name == selectedColumn.name)
      if(toBeRemovedItemIndex > -1)columns.splice(toBeRemovedItemIndex, 1);
    })
    let data = columns.map((value) => ({ ...value, [SELECTED_FIELD]: false, _id: null, user_id: user._id }));
    let selectedData = selectedColumns.data.map((value) => ({ ...value, [SELECTED_FIELD]: false }));
    setUserPreferenceColumns({ ...userPreferenceColumns, Available: data, Selected: selectedData });
    
  }

  const handleItemClick = (event, data, connectedData) => {
    setUserPreferenceColumns({
      ...userPreferenceColumns,
      [data]: userPreferenceColumns[data].map((item) => {
        if (item.label === event.dataItem.label) {
          item[SELECTED_FIELD] = !item[SELECTED_FIELD];
        } else if (!event.nativeEvent.ctrlKey) {
          item[SELECTED_FIELD] = false;
        }
        return item;
      }),
      [connectedData]: userPreferenceColumns[connectedData].map((item) => {
        item[SELECTED_FIELD] = false;
        return item;
      }),
    });
  };
  const handleToolBarClick = (e) => {
    let toolName = e.toolName || "";
    let result = processListBoxData(
      userPreferenceColumns.Available,
      userPreferenceColumns.Selected,
      toolName,
      SELECTED_FIELD
    );
    console.log(result);
    setUserPreferenceColumns({
      ...userPreferenceColumns,
      Available: result.listBoxOneData,
      Selected: result.listBoxTwoData,
    });
  };

  //   //OnSave for Preference

  const onSavePreference = async () => {
    // props.setUserPreferenceColumns(userPreferenceColumns);

    let saveData = [];

    userPreferenceColumns.Selected.map(async (value, index) => {
      console.log(value),
      saveData.push({ ...value, entityName: finalPreferenceColumnENntityName, sequence: index });
    })
    let res = await API.saveUserPreference(saveData);
    
    let logData = { event:  LOGEVENT.USER_PREFERENCE , module: entityName ? entityName.toUpperCase() : preferenceColumnENntityName.toUpperCase(), data: saveData, message:  LOGEVENT.USER_PREFERENCE };
    API.SaveLogs(logData);

    console.log(res);
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    props.handleClose(true);
  }



  return (
    <div>
      <BossDialog title={`${lang.select_preference_for_dialog_header}` + (finalPreferenceColumnENntityName ?? entityName)} onClose={props.handleClose} width={800} >


        <div className="container">
          <div className="row justify-content-center">
            <div className="col k-pr-2">
              <h6>{lang.available_label}</h6>
              <ListBox
                style={{
                  height: 400,
                  width: "100%",
                }}
                data={userPreferenceColumns.Available}
                textField="label"
                selectedField={SELECTED_FIELD}
                onItemClick={(e) => handleItemClick(e, "Available", "Selected")}
                toolbar={() => {
                  return (
                    <ListBoxToolbar
                      tools={[
                        "moveUp",
                        "moveDown",
                        "transferTo",
                        "transferFrom",
                        // "transferAllTo",
                        // "transferAllFrom",
                        // "remove",
                      ]}
                      data={userPreferenceColumns.Available}
                      dataConnected={userPreferenceColumns.Selected}
                      onToolClick={handleToolBarClick}
                    />
                  );
                }}
              />
            </div>
            <div className="col k-pl-0">
              <h6>{lang.preference_label}</h6>
              <ListBox
                style={{
                  height: 400,
                  width: "100%",
                }}
                data={userPreferenceColumns.Selected}
                textField="label"
                selectedField={SELECTED_FIELD}
                onItemClick={(e) => handleItemClick(e, "Selected", "Available")}
              />
            </div>
          </div>
        </div>

        <div className="mt-2" style={{ color: "#1274AC", textAlign: "center", fontSize: "15px", marginBottom: '-10px' }}>{lang.note_preference}</div>


        <DialogActionsBar>
          <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={() => { props.handleClose(); }}>
            {lang.cancel_button_text}
          </button>
          <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base" onClick={() => onSavePreference()}>
            {lang.confirm_button_text}
          </button>
        </DialogActionsBar>
      </BossDialog>
    </div>
  )
}
