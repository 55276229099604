/* eslint-disable */
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as API_DigiSign from "../../framework/API/api_digitalSign";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import { LocalDateTimeColumn } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import CustomPaginationDropDown from "../../framework/forms/helpercomponents/CustomGridCells/CustomPaginationDropDown";
import BossDialog from "../../components/BossDialog";

const LastPlayItemForm = (props) => {

    const [gridData, setGridData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const per_page = props?.perPage ?? 50;
    const initialDataState = {
        skip: 0,
        take: per_page,
    };
    const [page, setPage] = useState(initialDataState);
    const [total, setTotal] = useState(0);

    useEffect(() => {
        loadData({  per_page: per_page }, props?.data?.SerialNumber);
    }, []);

    const loadData = async (pagination, serialNo) => {
        setIsLoading(true);
        let res = await API_DigiSign.getLastPlayItem({ perPage: pagination?.per_page, page: pagination?.page}, serialNo);
        console.log(res);
        if(res.success){
            setGridData(res.data);
            setTotal(res.pagination.total);
        }else{
            toast.error(res.message);
            setGridData([]);
        }
        setIsLoading(false);
    }

    const pageChange = async (e) => {
        let currentPage = e.page.skip / e.page.take;
        setPage({ skip: e.page.skip, take: e.page.take });
        loadData({ per_page: e.page.take, page: currentPage + 1 }, props?.data?.SerialNumber);
    };

    return (
        <BossDialog title={`Last Play Item (${props?.data?.Name})`} onClose={props?.onCloseForm} width="65%" height="65%">
            {isLoading && <Loader height="45vh"/>}
            {!isLoading && 
            <Grid 
                data={gridData} 
                resizable={true}
                pageable={true}
                skip={page.skip}
                take={page.take}
                total={total}
                onPageChange={pageChange}
                pager={(props) => <CustomPaginationDropDown {...props} />}
                style={{ height: "100%" }}
            >
                <GridColumn field="content.fileName" title="Content Name" />
                <GridColumn field="screenGroup.Name" title="Screen Group" />
                <GridColumn field="playlistItem.playlist.Name" title="Playlist" />
                <GridColumn field="time" title="time" cell={LocalDateTimeColumn} />
            </Grid>}
        </BossDialog>
    );
}
export default LastPlayItemForm;