import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Error } from "@progress/kendo-react-labels";

export const DropdownWithDefaultValue = (fieldRenderProps) => {

    const { validationMessage, visited, name, data, value, datakey, ...others } = fieldRenderProps;
    
    return (
      <div>
        <DropDownList
          {...others}
          name={name}
          data={data}
          defaultValue={data.find((item)=> item[datakey??"SID"]==value)}
          style={{backgroundColor : 'white' ,border : '1px solid lightgrey'}}
        />
        {visited && validationMessage && <Error>{validationMessage}</Error>}
      </div>
    );
  };