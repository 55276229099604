/* eslint-disable */
import RoundButton from "../buttons/RoundButton";
import { useState } from "react";
import { Grid, GRID_COL_INDEX_ATTRIBUTE, GridColumn } from "@progress/kendo-react-grid";
import { stringEnumCell } from "./PlatformCell";
import { DAYS } from "../../../constant/constant";
import { ArrayCell } from "./ArrayCell";
import { DateOnlyCell } from "./DateCell";
import { CheckboxCell } from "./CheckBoxCell";
import { useTranslation } from "../../../../locale/useTranslation";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import BossDialog from "../../../../components/BossDialog";

const PublishingCell = (props) =>{

    const [showData, setShowData] = useState(false);
    const lang = useTranslation();

    const handleShowData = () => {
        setShowData(!showData);
    }
    return (
        <td
            colSpan={props.colSpan}
            role={"gridcell"}
            aria-colindex={props.ariaColumnIndex}
            aria-selected={props.isSelected}
            {...{
            [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
            }}
            {...useTableKeyboardNavigation(props.id)}
            style={props.style}
        >
            <RoundButton style={{marginLeft: '25px'}} icon="eye" onClick={handleShowData} />
            <div style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {showData && <BossDialog title={"Publishing data"} onClose={handleShowData} width="700px" >
                    <Grid resizable={true} data={props?.dataItem?.Publishings} style={{ height: "45vh", marginTop: '5px',display: 'flex' }}>
                    <GridColumn field="Channel" format="FullChannelName" cell={ArrayCell} title={lang.channel_column} width={"200px"} />
                    <GridColumn field="PublishStartDate" title={lang.start_date_column} cell={DateOnlyCell} width={"100px"} />
                    <GridColumn field="PublishEndDate" title={lang.end_date_column} cell={DateOnlyCell} width={"100px"} />
                    <GridColumn field="TBA" title={lang.tba_column} editor="boolean" editable={false} cell={CheckboxCell} width={"80px"} />
                    <GridColumn field="Days" title={lang.days_column} cell={(props)=>stringEnumCell(props, DAYS , "SubName")} width={"180px"} />
                    <GridColumn field="addBy.name" title={'Added By'} width={'100px'} />
                    <GridColumn field="modBy.name" title={'Modified By'} width={'100px'} />
                </Grid>
                </BossDialog>}
            </div>
            </td>
    );
}

export default PublishingCell;