/* eslint-disable */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { Grid, GridColumn as Column, getSelectedState, GridNoRecords } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { ENTITYNAME } from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { RadioButton } from "@progress/kendo-react-inputs";
import { Expander } from '../platformLayout/tv/Dashboard/helperComponent/ExpandableTile';
import { InvoiceDenomination } from "./InvoiceDenomination";
import { getter } from "@progress/kendo-data-query";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { ContextMenu } from 'primereact/contextmenu';
import { toast } from 'react-toastify';
import { utility } from "../../framework/utility/utilityProvider";
import { useTranslation } from "../../locale/useTranslation";

const TooltipHeader  = (props) =>  {
    console.log(props.title)
    //Ask for tooltip here
      return (
      <a className="k-link" onClick={() => {}}>
          <span title={props.title}>{props.title}</span>
          {props.children}
        </a>
    )
  }


const BillingCollection = () => {
    const lang = useTranslation();
    const defaultDates = utility.getFirstAndLastDateOfMonth();

    const blankDataItem = {
        BillingFromDate: defaultDates.FirstDate,
        BillingToDate: defaultDates.LastDate,
        IsReconciledOnly: true,
        StatusMsg: "",
        Channel : { SID: 0, FullChannelName: "All" }
    }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [channel, setChannel] = useState([]);
    const [agency, setAgency] = useState([]);
    const [client, setClient] = useState([]);
    const [agencyRadioButton, setAgencyRadioButton] = useState(true);
    const [clientRadioButton, setClientRadioButton] = useState(false);
    const [billingData, setBillingData] = useState([]);

    const SELECTED_FIELD = "selected";
    const DATA_ITEM_KEY = "_id";
    const idGetter = getter(DATA_ITEM_KEY);
    const [selectedBilling, setSelectedBilling] = useState({});
    // const [selectedBillingToBill,setSelectedBillingToBill] =  useState({});
    const [selectedBillingsToBill, setSelectedBillingsToBill] = useState([]);
    const [selectedBillingLineDetails, setSelectedBillingLineDetails] = useState({});
    const [agencyClientInfo, setAgencyClientInfo] = useState({ Name: "NA", Email: "NA", Contact: "NA", Address1: "NA", Address2: "NA" });
    const [selectedCampaignOverAllDetail, setSelectedCampaignOverAllDetail] = useState({});

    var billingDetailForSidePanelInfoRef = useRef({});
    const setBillingDetailForSidePanelInfo = (data) => {
        billingDetailForSidePanelInfoRef.current = data;
    }
    const [statusMsg, setStatusMsg] = useState(lang.no_data_found_error_message);


    const billingLine = useRef();
    const ref = useRef();
    const [gridMessage, setGridMessage] = useState(lang.no_data_found_error_message);
    useEffect(() => {
        loadcombo();
        loadBillingData(blankDataItem);
    }, []);
    const [openForm, setFormOpen] = useState();

    const loadcombo = async () => {

        // CLEARING LOCAL STORAGE OF CLIENT AND AGENCY TO LOAD FRESH DATA EVERY TIME
        utility.deleteLocalStorageItem('agency');
        utility.deleteLocalStorageItem('client');

        var channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });

        channelRes.data.splice(0, 0, { SID: 0, FullChannelName: "All" });

        setChannel(channelRes.data);
        var agencyRes = await API.getDataLookup(ENTITYNAME.Agency, { sort: { Name: 1 } });
        // console.log("---- checking agency");
        // console.log(agencyRes.data);
        setAgency([{ SID: 0, Name: "All" }, ...agencyRes.data]);
        var clientRes = await API.getDataLookup(ENTITYNAME.Client, { sort: { Name: 1 } });

        // console.log("---- checking client");
        // console.log(clientRes.data);

        setClient([{ SID: 0, Name: "All" }, ...clientRes.data]);
    }

    const onChange = async (e) => {

        // if(e.target.name =="IsReconciledOnly")
        // {
        //     // e.target.value = !dataItem.IsReconciledOnly;
        //     setDataItem({ ...dataItem, IsReconciledOnly: !dataItem.IsReconciledOnly });
        // }else
        // {
        //     setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        // }

        // MAKING BLANK


        loadBillingData({ ...dataItem, [e.target.name]: e.target.value });
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        setBillingData([]);


    }

    const loadBillingData = async (filterObject) => {

        setStatusMsg(lang.please_wait_loading_data_billing_error_message);
        setGridMessage(lang.please_wait_loading_data_billing_error_message);
        var billingFilters = { ...filterObject };

        billingFilters.IsAgency = agencyRadioButton ? true : false;
        billingFilters.BillingFromDate = new Date(billingFilters.BillingFromDate).getTime(),
        billingFilters.BillingToDate = new Date(billingFilters.BillingToDate).getTime(),
            console.log(billingFilters);
        var billingData = await API.loadBillingData(billingFilters);
        console.log("CHecking Billing");
        console.log(billingData);

        if (billingData.success != undefined && billingData.success && billingData.data.length > 0) {
            console.log(billingData.data)
            setBillingData(billingData.data);
            setStatusMsg(lang.data_loaded_successfully_billing_template_message);
        } else {
            setGridMessage(lang.no_data_found_error_message);
            setStatusMsg(lang.no_data_found_error_message);
        }
    }

    const cardStyle = { maxWidth: "21vw", backgroundColor: "white", height: "28px", marginRight: "0px" };
    const cardTextStyle = { marginTop: "04px", marginLeft: "04px", fontSize: "11px", fontWeight: "300" };

    const cancelGenerateBill = () => {
        setFormOpen(false);
    }
    const onChangeRadioButton = (e) => {

        if (e.target.name == "AgencyButton") {
            setAgencyRadioButton(true);
            setClientRadioButton(false);
            delete dataItem.Client;
            updateContactInfo();
        } else {
            setAgencyRadioButton(false);
            setClientRadioButton(true);
            delete dataItem.Agency;
            updateContactInfo();
        }
    }
    const cardExpenderStyle = {
        fontSize: "11px",
        fontWeight: "700",
        maxWidth: "21vw",
        backgroundColor: "white",
        height: "42px",
        marginTop: "-05px",
        marginRight: "0px"

    };

    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedBilling,
                dataItemKey: DATA_ITEM_KEY,
            });


            //  console.log(newSelectedState);

            // var dd = billingData.find(i=>i._id.toString() ==newSelectedState.toString());
            //   var selectedBillingLine = billingData.find(x=>x.SID == newSelectedState);
            setSelectedBilling(newSelectedState);
            onSelection(newSelectedState);


            //  console.log(selectedBillingLine);

            //  console.log(billingData[0]);


        }
    );

    const onSelection = (selectedState) => {
        var selectedDataList = [];
        //loop for setting the selected obj in array
        billingData.map(async (obj) => {
            var key = obj._id;
            //if true means checked
            if (selectedState[key]) {
                selectedDataList.push(obj);
                setSelectedBillingLineDetails(obj);

                var filter = {};
                filter.campaignId = key;
                filter.FromDate = new Date(dataItem.BillingFromDate).getTime();
                filter.ToDate = new Date(dataItem.BillingToDate).getTime();
                console.log(filter);

                var billingData = await API.getCampaignOverAllDetails(filter);
                console.log(billingData.data);
                setSelectedCampaignOverAllDetail(billingData.data);
                setBillingDetailForSidePanelInfo(obj)
                updateContactInfo();
            }
        });

        setSelectedBillingsToBill(selectedDataList);
        // console.log(selectedDataList);


        // props.setDataList(selectedDataList);
    }

    // TO UPDATE CONTACT INFO SIDE PANEL
    const updateContactInfo = () => {

        console.log(billingDetailForSidePanelInfoRef.current);
        if (agencyRadioButton) {
            setAgencyClientInfo({
                Name: billingDetailForSidePanelInfoRef.current.Agency?.Name,
                Email: billingDetailForSidePanelInfoRef.current.Agency?.Email,
                Address1: billingDetailForSidePanelInfoRef.current.Agency?.Address,
                Address2: billingDetailForSidePanelInfoRef.current.Agency?.Address
            })
        } else {
            setAgencyClientInfo({
                Name: billingDetailForSidePanelInfoRef.current.Client?.Name,
                Email: billingDetailForSidePanelInfoRef.current.Client?.Email,
                Address1: billingDetailForSidePanelInfoRef.current.Client?.Address,
                Address2: billingDetailForSidePanelInfoRef.current.Client?.Address
            })
        }
    }

    const generateBill = () => {

        if (selectedBillingsToBill.length == 0) {
            toast.error(`${lang.please_select_a_billing_detail_first_billing_template_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        // console.log(selectedBillingsToBill);
        if (selectedBillingsToBill[0].ReconciledSpots == 0) {
            toast.error(`${lang.no_reconciled_spots_found_for_selected_details_billing_template_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        setFormOpen(true);
    }

    const gridRowRender = (tr, props) => {

        const trProps = {
            onContextMenu: (e) => { ref.current = props.dataItem; billingLine.current.show(e) },
        };
        // console.log(props.dataItem.ReconciledStatus);
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    const verticalScroll = {
        overflowX: 'hidden',
        // border:'1px solid red',
        width: '45vh',
        float: 'left',
        height: '18vh',
        position: 'relative'
    };

    return <>
        <div className="row mt-2" style={{ height: "10vh", fontSize: "10px", margin: "2px" }}>
            <div className="col-2">
                <div className="form-group">
                    <label htmlFor="TabView">{lang.channel_label}</label>
                    <DropDownList style={{ backgroundColor: "white" }}
                        data={channel}
                        name="Channel"
                        textField="FullChannelName"
                        defaultValue={channel[0]}
                        dataItemKey="_id"
                        value={dataItem.Channel}
                        onChange={onChange}
                        validator={(value) => value ? "" : "Please Select Channel"}
                    />
                </div>
            </div>
            <div className="col-2">
                <div className="form-group">
                    <label htmlFor="TabView">{lang.billing_period_label}</label>
                    <input
                        className="form-control form-control-sm"
                        name="BillingFromDate"
                        type={'date'}
                        value={dataItem.BillingFromDate}
                        onChange={onChange}
                    />
                </div>
            </div>
            <div className="col-2">
                <div className="form-group">
                    <label htmlFor="TabView">{lang.upto_label}</label>
                    <input
                        className="form-control form-control-sm"
                        name="BillingToDate"
                        type={'date'}
                        value={dataItem.BillingToDate}
                        onChange={onChange}
                    />
                </div>

            </div>
            <div className="col-2">
                <div className="form-group">
                    <div className="row">
                        <div className="col-2" style={{ padding: "0" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                            <RadioButton
                                name="AgencyButton"
                                checked={agencyRadioButton}
                                value={dataItem.AgencyButton}
                                label=""
                                onClick={onChangeRadioButton}
                            />
                        </div>
                        <label htmlFor="TabView">{lang.agency_label}</label>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <div className="col-2" style={{ padding: "0" }}>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <RadioButton
                                style={{ marginLeft: "5px" }}
                                name="ClientButton"
                                value={dataItem.ClientButton}
                                checked={clientRadioButton}
                                label=""
                                onClick={onChangeRadioButton}
                            />
                        </div> <label htmlFor="TabView">{lang.client_label}</label>
                    </div>
                    {agencyRadioButton ?
                        <DropDownList
                            style={{
                                backgroundColor: "white",
                            }}
                            data={agency}
                            name="Agency"
                            defaultValue={agency[0]}
                            textField="Name"
                            dataItemKey="_id"
                            value={dataItem.Agency}
                            onChange={onChange}
                            validator={(value) => value ? "" : "Please Select Client"}
                        /> :
                        <DropDownList
                            style={{
                                backgroundColor: "white",
                            }}
                            data={client}
                            name="Client"
                            defaultValue={client[0]}
                            textField="Name"
                            dataItemKey="_id"
                            value={dataItem.Client}
                            onChange={onChange}
                            validator={(value) => value ? "" : "Please Select Agency"}
                        />
                    }

                </div>
            </div>
            <div className="col-2">
                <ActionButton title={lang.generate_bill_button_tooltip} name={lang.generate_bill_button_text} onClick={generateBill} style={{ position: "absolute", marginTop: "18px" }} />
            </div>
        </div>
        <div className="row" style={{ height: "71vh", fontSize: "10px", margin: "2px", border: '1px solid black', borderRadius: '5px!important' }}>
            <div className="col-9 mt-2">
                <Grid data=
                    {billingData.map((item) => ({
                        ...item,
                        [SELECTED_FIELD]: selectedBilling[idGetter(item)]
                    }))}
                    selectedField={SELECTED_FIELD}
                    dataItemKey={DATA_ITEM_KEY}
                    selectable={{
                        enabled: true,
                        drag: false,
                        cell: false,
                        mode: "multiple",
                    }}
                    rowRender={gridRowRender}
                    onSelectionChange={onSelectionChange}
                    resizable={true}
                    style={{ height: "68vh" }}>

                    <GridNoRecords>
                        {gridMessage}
                    </GridNoRecords>
                    <Column field="CampaignNo" title={lang.campaign_no_column} width={120} />
                    <Column field="Title" title={lang.title_column} width={150} />
                    <Column field="Agency.Name" title={lang.agency_column} width={150} />
                    <Column field="Client.Name" title={lang.client_column} width={150} />
                    <Column field="Commission" title={lang.comission_column} width={120} />
                    <Column field="PeriodFrom" title={lang.from_column} width={100} cell={DateOnlyCell} />
                    <Column field="PeriodTo" title={lang.to_column} width={100} cell={DateOnlyCell} />
                    <Column field="TargetSpots" title={lang.total_budget_spot_column} width={150} />
                    <Column field="TotalBookedSpots" title={lang.total_booked_spot_column} width={150} />
                    <Column field="MissingSpots" title={lang.non_reconciled_spot_column} width={150} />
                    <Column field="ReconciledSpots" title={lang.reconciled_spots_column} width={150} />
                    <Column field="GeneratebBillSpots" title={lang.generated_bill_Spots_column} width={150} />
                    {/* <Column field="ReconciledStatus" title="Payment Recived Spots"/> */}
                </Grid>
                <ContextMenu ref={billingLine} model={
                    [
                        { label: `${lang.generate_bill_button_text}`, icon: 'pi pi-fw pi-wallet', command: () => { generateBill(); } },
                    ]
                } />
            </div>
            <div className="col-3 mt-1">
                <div className="card mt-1 border" style={{ height: "68vh", overflow: 'hidden', maxHeight: "80vh", overflowY: "auto" }}>
                    <Expander title={lang.contact_info_label}>
                        <table className="table table-bordered">
                            <tbody>
                                <tr>
                                    <th>{lang.contact_label}</th>
                                    <td>{agencyClientInfo.Name ?? "NA"}</td>
                                </tr>
                                <tr>
                                    <th>{lang.telephone_label}</th>
                                    <td>{agencyClientInfo.Contact ?? "NA"}</td>
                                </tr>
                                <tr>
                                    <th>{lang.email_label}</th>
                                    <td>{agencyClientInfo.Email ?? "NA"}</td>
                                </tr>
                                <tr>
                                    <th>{lang.address1_label}</th>
                                    <td>{agencyClientInfo.Address1 ?? "NA"}</td>
                                </tr>
                                <tr>
                                    <th>{lang.address2_label}</th>
                                    <td>{agencyClientInfo.Address2 ?? "NA"}</td>
                                </tr>
                            </tbody>
                        </table>
                    </Expander>
                    <Expander title={lang.agency_info_label}>
                        <table className="table table-bordered">
                            <thead className="border">
                                <tr className="border">
                                    <th className="border"></th>
                                    <th className="border">{lang.spots_label}</th>
                                    <th className="border">{lang.amounts_label}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="font-weight-bold">{lang.total_budget_label}</td>
                                    <td>{selectedCampaignOverAllDetail.TargetSpots ?? 0}</td>
                                    <td>{selectedCampaignOverAllDetail.TargetSpotsBudget ?? 0}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">{lang.total_booked_label}</td>
                                    <td>{selectedCampaignOverAllDetail.TotalBookedSpots ?? 0}</td>
                                    <td>{selectedCampaignOverAllDetail.TotalBookedAmount ?? 0}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">{lang.missing_label}</td>
                                    <td>{selectedCampaignOverAllDetail.MissingSpots ?? 0}</td>
                                    <td>{selectedCampaignOverAllDetail.MissingSpotsAmount ?? 0}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">{lang.reconciled_label}</td>
                                    <td>{selectedCampaignOverAllDetail.ReconciledSpots ?? 0}</td>
                                    <td>{selectedCampaignOverAllDetail.ReconciledSpotsAmount ?? 0}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">{lang.generated_bill_label}</td>
                                    <td>{selectedCampaignOverAllDetail.GeneratedBillSpots ?? 0}</td>
                                    <td>{selectedCampaignOverAllDetail.GeneratedBillSpotsAmount ?? 0}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">{lang.payment_recived_label}</td>
                                    <td>{0}</td>
                                    <td>{0}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">{lang.due_amount_label}</td>
                                    <td>{0}</td>
                                    <td>{0}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">{lang.credit_limit_label}</td>
                                    <td>{0}</td>
                                    <td>{0}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">{lang.credit_balance_label}</td>
                                    <td>{0}</td>
                                    <td>{0}</td>
                                </tr>

                            </tbody>
                        </table>
                    </Expander>
                    <Expander title={lang.selected_campaign_info_label}>
                        <table className="table table-bordered">
                            <thead className="border">
                                <tr className="border">
                                    <th className="border"></th>
                                    <th className="border">{lang.spots_label}</th>
                                    <th className="border">{lang.amounts_label}</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td className="font-weight-bold">{lang.total_budget_label}</td>
                                    <td>{selectedCampaignOverAllDetail.TargetSpots ?? 0}</td>
                                    <td>{selectedCampaignOverAllDetail.TargetSpotsBudget ?? 0}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">{lang.total_booked_label}</td>
                                    <td>{selectedCampaignOverAllDetail.TotalBookedSpots ?? 0}</td>
                                    <td>{selectedCampaignOverAllDetail.TotalBookedAmount ?? 0}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">{lang.missing_label}</td>
                                    <td>{selectedCampaignOverAllDetail.MissingSpots ?? 0}</td>
                                    <td>{selectedBillingLineDetails.MissingSpotsAmount ?? 0}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">{lang.reconciled_label}</td>
                                    <td>{selectedCampaignOverAllDetail.ReconciledSpots ?? 0}</td>
                                    <td>{selectedBillingLineDetails.ReconciledSpotsAmount?? 0}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">{lang.generated_bill_label}</td>
                                    <td>{selectedCampaignOverAllDetail.GeneratedBillSpots ?? 0}</td>
                                    <td>{selectedBillingLineDetails.GeneratedBillSpotsAmount ?? 0}</td>
                                </tr>
                                <tr>
                                    <td colSpan="3" style={{marginLeft: '5px',flex: 'none',fontWeight: "bold" }} >{lang.detail_for_selected_date_range_and_campaign_label}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">{lang.booked_label}</td>
                                    <td>{selectedCampaignOverAllDetail.TotalBookedSpots ?? 0}</td>
                                    <td>{selectedBillingLineDetails.TotalBookedSpots ? selectedBillingLineDetails.TotalBookedSpots * parseInt(selectedBillingLineDetails.SpotRate) : 0}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">{lang.missing_label}</td>
                                    <td>{selectedCampaignOverAllDetail.MissingSpots ?? 0}</td>
                                    <td>{selectedBillingLineDetails.MissingSpots ? selectedBillingLineDetails.MissingSpots * parseInt(selectedBillingLineDetails.SpotRate) : 0}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">{lang.reconciled_label}</td>
                                    <td>{selectedCampaignOverAllDetail.ReconciledSpots ?? 0}</td>
                                    <td>{selectedBillingLineDetails.ReconciledSpots  ? selectedBillingLineDetails.ReconciledSpots * parseInt(selectedBillingLineDetails.SpotRate) : 0}</td>
                                </tr>
                                <tr>
                                    <td className="font-weight-bold">{lang.generated_bill_label}</td>
                                    <td>{selectedCampaignOverAllDetail.GeneratedBillSpots ?? 0}</td>
                                    <td>{selectedBillingLineDetails.GeneratebBillSpots ? selectedBillingLineDetails.GeneratebBillSpots * parseInt(selectedBillingLineDetails.SpotRate) : 0} </td>
                                </tr>
                            </tbody>
                        </table>
                    </Expander>
                </div>
            </div>
        </div>
        <div className="row scheduleFooter">
            <div className="col-md-12" style={{ textAlign: "right" }}>
                <span style={{ fontWeight: "400", fontFamily: "Roboto", fontSize: "12px", color: "#ffffff" }}>{statusMsg}</span>
            </div>
        </div>
        {openForm && <InvoiceDenomination closeForm={cancelGenerateBill} billingData={selectedBillingsToBill[0]} billingFilter={dataItem} isAgency={agencyRadioButton} />}
    </>
}




export default BillingCollection;