
import React, { useEffect, useState } from 'react';
import { EditPageHeader } from './EditPageHeader';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { DropdownCell } from '../framework/forms/helpercomponents/CustomGridCells/DropdownCell';
import { toast } from 'react-toastify';
import * as API from "../framework/API/api";
import { utility } from '../framework/utility/utilityProvider';
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY } from '../framework/constant/constant';
import { CellRender, RowRender } from '../modules/Segment/renderers';
import { useTranslation } from '../locale/useTranslation';
import PreferenceRoundButton from '../framework/forms/helpercomponents/buttons/PreferenceRoundButton';
import { UserPreference } from '../modules/userPreference/UserPreference';
import { arrayEnumCell } from '../framework/forms/helpercomponents/CustomGridCells/PlatformCell';
import { DateCell, DateOnlyCell, LocalDateTimeColumn } from '../framework/forms/helpercomponents/CustomGridCells/DateCell';
import MyStatusCell from './MyStatusCell';
import { ArrayCell } from '../framework/forms/helpercomponents/CustomGridCells/ArrayCell';
import { CheckboxCell } from '../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell';
import { ImageCell } from '../framework/forms/helpercomponents/CustomGridCells/ImageCell';
import { TimeCell } from '../framework/forms/helpercomponents/CustomGridCells/TimeCell';
import { Loader } from '../framework/forms/helpercomponents/Loader';
import { Icon } from '@progress/kendo-react-common';
import { myTimeCellMilliseconds } from '../framework/forms/helpercomponents/inline grid cell/MyTimeCellInMillisconds';
import BossDialog from './BossDialog';

const UpdateMultipleEpisodeDialog = ({
    selectedEpisode,
    setSelectedEpisode,
    refresh,
    closeForm
}) => {

    const EDIT_FIELD = 'inEdit';

    const [updatedDataItemIds, setUpdatedDataItemIds] = useState([]);
    const [genre, setGenre] = useState([]);
    const [language, setLanguage] = useState([]);
    const lang = useTranslation();
    const [showPreferences, setShowPreferences] = useState(false);
    const [dataColumns, setDataColumns] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        loadMetaData();
        loadGenre();
        loadColumns();
    }, []);

    const loadColumns = async () => {
        let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
        let columns = await API.getFormData(ENTITYNAME.MediaEpisodeMultiEdit, currentUserId)??[];
        setDataColumns(columns);
    }

    const loadGenre = async () => {
        let res = await API.getData(ENTITYNAME.Genre);
        if (res.success) {
            setGenre(res.data);
        } else {
            toast.error('Couldn\'t get genre', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    }

    const loadMetaData = async () => {
        setIsLoading(true);
        let defaultLang = null;
        if (selectedEpisode.length > 0) {
            let langRes = await API.getData(ENTITYNAME.Language);
            if (langRes.success && langRes.data.length > 0) {
                defaultLang = utility.getDefaultItem(langRes.data);
                setLanguage(defaultLang);
            } else {
                toast.error('Couldn\'t get default language', {
                    position: toast.POSITION.TOP_RIGHT,
                })
                multipleUpdateFormClose();
                return;
            }
            let res = await API.getMetaDataFromMediaEpisode(selectedEpisode.map(x => x.SID));
            if (res.success) {
                const metaData = res.data;
                setSelectedEpisode(
                    selectedEpisode.map(x => {
                        let metaDataAttached = metaData?.find(m => m.MediaEpisodeSID == x.SID);
                        if (metaDataAttached) {
                            let synop = metaDataAttached.Synopsis?.filter(s => s?.Language?._id == defaultLang._id);
                            if (synop?.length > 0) {
                                return { 
                                    ...x, 
                                    MediaEpisode_id: metaDataAttached.MediaEpisode_id, 
                                    MediaEpisodeSID: metaDataAttached.MediaEpisodeSID, 
                                    metaDataId: metaDataAttached._id, 
                                    Synopsis: metaDataAttached.Synopsis, 
                                    synopsis: synop[0].Synopsis,
                                    dynamicField : metaDataAttached.dynamicField,
                                };
                            } else {
                                return { 
                                    ...x, 
                                    MediaEpisode_id: metaDataAttached.MediaEpisode_id, 
                                    MediaEpisodeSID: metaDataAttached.MediaEpisodeSID, 
                                    metaDataId: metaDataAttached._id, 
                                    Synopsis: [], 
                                    synopsis: "",
                                    dynamicField : metaDataAttached.dynamicField

                                };
                            }
                        }
                        return { ...x, MediaEpisode_id: x.SID, MediaEpisodeSID: x.SID, metaDataId: null, synopsis: "", dynamicField : {} };
                    })
                );
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                multipleUpdateFormClose();
            }
        }
        setIsLoading(false);
    }

    const multipleUpdateFormClose = () => {
        closeForm();
        setUpdatedDataItemIds([]);
    }

    const enterEdit = (dataItem, field) => {
        const newData = selectedEpisode.map(item => ({
            ...item,
            [EDIT_FIELD]: (item._id == dataItem._id && item.Sequence === dataItem.Sequence) ? field : undefined
        }));
        setSelectedEpisode(newData);
    };

    const exitEdit = () => {
        const newData = selectedEpisode.map(item => ({
            ...item,
            [EDIT_FIELD]: undefined
        }));
        setSelectedEpisode(newData);
    };
    
    const customCellRender = (td, props) => <CellRender originalProps={props} td={td} enterEdit={enterEdit} editField={EDIT_FIELD} />;

    const customRowRender = (tr, props) => <RowRender originalProps={props} tr={tr} exitEdit={exitEdit} editField={EDIT_FIELD} />;

    const itemChange = (event) => {
        let field = event.field || '';
        if (field == 'Duration') {
            event.dataItem[field] = utility.convertStringWithFramesToMilliseconds(event.value);
        } else {
            if(field.includes('.')){
                let keys = field.split('.');
                keys.length == 2 ? event.dataItem[keys[0]][keys[1]] = event.value : event.dataItem[keys[0]] = event.value
            } else {
                event.dataItem[field] = event.value;
            }
        }

        let newData = selectedEpisode.map(item => {
            if (item._id === event.dataItem._id) {
                return event.dataItem;
            }
            return item;
        });
        if (!updatedDataItemIds.includes(event.dataItem._id)) {
            setUpdatedDataItemIds([...updatedDataItemIds, event.dataItem._id]);
        }
        setSelectedEpisode(newData);
    };

    const handleMultipleUpdateSave = async () => {
        if (updatedDataItemIds.length == 0) {
            toast.success('Nothing to update', {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            // DELETE UN-WANTED KEY FROM DATA BEFORE SENDING TO API
            let dataToUpdate = selectedEpisode.filter(x => updatedDataItemIds.includes(x._id)).map(d => {

                let item = { ...d };
                
                //REMOVE METADATA KEYS
                delete item[EDIT_FIELD];
                delete item.metaDataId;
                delete item.MediaEpisode_id;
                delete item.MediaEpisodeSID;
                delete item.synopsis;
                delete item.Synopsis;
                delete item.dynamicField;


                return item;
            });

            // send data in chunks of 50 to api
            for (let i = 0; i < dataToUpdate.length; i += 50) {
                let media = dataToUpdate.slice(i, i + 50);
                let res = await API.bulkUpdate(ENTITYNAME.MediaEpisode, media);
                if (!res.success) {
                    toast.error(res.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    toast.error('Some data not updated', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setUpdatedDataItemIds(updatedDataItemIds.filter(x => !media.map(x => x._id).includes(x)));
                }
            }
            toast.success('Data updated successfully', { position: toast.POSITION.TOP_RIGHT });
        }
        await handleMultipleeditMetaData();
    }

    const handleMultipleeditMetaData = async () => {
        if (updatedDataItemIds.length == 0) {
            toast.success('Nothing to update', {
                position: toast.POSITION.TOP_RIGHT,
            });
        } else {
            let dataToUpdate = selectedEpisode.filter(x => updatedDataItemIds.includes(x._id)).map(d => {
                let item = {};
                let otherSynopsis = d.Synopsis?.filter(s => s.Language._id != language._id);
                if (otherSynopsis?.length > 0) {
                    item = { 
                        _id: d.metaDataId, 
                        MediaEpisode_id: d.MediaEpisode_id, 
                        MediaEpisodeSID: d.MediaEpisodeSID, 
                        Synopsis: [...otherSynopsis, { Language: language, Synopsis: d.synopsis }],
                        dynamicField: d.dynamicField
                    };
                } else {
                    item = { 
                        _id: d.metaDataId, 
                        MediaEpisode_id: d.MediaEpisode_id, 
                        MediaEpisodeSID: d.MediaEpisodeSID, 
                        Synopsis: [{ Language: language, Synopsis: d.synopsis }],
                        dynamicField: d.dynamicField
                    };
                }
                return item;
            });
            // send data in chunks of 50 to api
            for (let i = 0; i < dataToUpdate.length; i += 50) {
                let media = dataToUpdate.slice(i, i + 50);
                let res = await API.bulkUpdate(ENTITYNAME.MediaEpisodeMetaData, media);
                if (!res.success) {
                    toast.error(res.message, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    toast.error('Some data not updated', {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    setUpdatedDataItemIds(updatedDataItemIds.filter(x => !media.map(x => x._id).includes(x)));
                }
            }
            toast.success('Meta Data updated successfully', { position: toast.POSITION.TOP_RIGHT });
        }
        multipleUpdateFormClose();
        refresh();
    }

    const onChangeGenre = (event, dataItem) => {
        setSelectedEpisode(selectedEpisode.map(x => x._id == dataItem._id ? { ...x, Genres: event.value.map(e => ({ _id: e._id, Description: e.Description })) } : x));
        if (!updatedDataItemIds.includes(dataItem._id)) {
            setUpdatedDataItemIds([...updatedDataItemIds, dataItem._id]);
        }
    }

    const onChangeSubGenre = (event, dataItem) => {
        setSelectedEpisode(selectedEpisode.map(x => x._id == dataItem._id ? { ...x, SubGenres: event.value.map(e => ({ _id: e._id, Description: e.Description })) } : x));
        if (!updatedDataItemIds.includes(dataItem._id)) {
            setUpdatedDataItemIds([...updatedDataItemIds, dataItem._id]);
        }
    }

    let timeOut;
    const handleColumnResize = (event) => {
      try {
        if (event.index == 0) return;
        if (dataColumns == undefined || dataColumns.length == 0 || dataColumns.length < event.index) return;
        
        let column = dataColumns[event.index];
        let payload = {
          entityName: column?.entityName,
          name: column?.name,
          width: event.newWidth
        };
        clearTimeout(timeOut);
        timeOut = setTimeout(() => {
          API.updateUserPreferenceColumnWidth(payload);
        }, 700);
      } catch (error) {
        console.error("Error handling column resize:", error);
      }
    };

    return (
        <BossDialog title={'Update multiple'} onClose={multipleUpdateFormClose} width="90%" height="90%">
            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: '10px' }}>
                <EditPageHeader title={""} onSubmit={handleMultipleUpdateSave} onCancel={multipleUpdateFormClose} showTitle={false} />
                <PreferenceRoundButton
                    icon={"gear"}
                    title={lang.preference_button_tooltip}
                    onClick={() => { setShowPreferences(true) }}
                />
            </div>
            {isLoading ? <Loader /> :
            <Grid
                data={selectedEpisode}
                style={{ height: "90%", width: "100%", overflow: "auto" }}
                editField={EDIT_FIELD}
                cellRender={customCellRender}
                rowRender={customRowRender}
                onItemChange={itemChange}
                resizable={true}
                onColumnResize={handleColumnResize}
            >
                {dataColumns.map((column, index) => {
                    const titleWithIcon = column.editable ? (
                        <span>
                            {column.label} <Icon style={{fontSize: '10px'}} name="pencil" />
                        </span>
                    ) : column.label;

                    if (column.type === COLUMNSTYPE.date) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={DateOnlyCell}
                                title={titleWithIcon}
                                width={column.width ?? 200}
                                editable={column.editable}
                            />
                        );
                    } else if (column.type === COLUMNSTYPE.datetime) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={DateCell}
                                title={titleWithIcon}
                                width={column.width ?? 200}
                                editable={column.editable}
                            />
                        );
                    }
                    else if (column.name.includes("Duration")) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={myTimeCellMilliseconds}
                                title={titleWithIcon}
                                width={column.width ?? 200}
                                editable={column.editable}
                            />
                        );
                    } else if (column.name === "SID") {
                        return;
                    } else if (column.type === COLUMNSTYPE.image) {
                        return (
                            <GridColumn
                                sortable={false}
                                key={index}
                                field={column.name}
                                cell={ImageCell}
                                title={titleWithIcon}
                                width={column.width ?? 100}
                                editable={column.editable}
                            />
                        );
                    }
                    else if (column.type === COLUMNSTYPE.checkbox) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={CheckboxCell}
                                title={titleWithIcon}
                                width={column.width ?? 80}
                                editable={column.editable}
                            />
                        );
                    }
                    else if (column.type === COLUMNSTYPE.array) {
                        return (
                            <GridColumn
                                sortable={false}
                                key={index}
                                field={column.name}
                                cell={ArrayCell}
                                title={titleWithIcon}
                                width={column.width ?? 200}
                                format={column.format ?? "Description"} //display item in object
                                editable={column.editable}
                            />
                        );
                    }
                    else if (column.type === COLUMNSTYPE.status) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={MyStatusCell}
                                title={titleWithIcon}
                                width={column.width ?? 200}
                                editable={column.editable}
                            />
                        );
                    }
                    else if (column.type === COLUMNSTYPE.localdatetime) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={LocalDateTimeColumn}
                                title={titleWithIcon}
                                width={column.width ?? 200}
                                editable={column.editable}
                            />
                        );
                    }
                    else if (column.type === COLUMNSTYPE.arrayenum) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={arrayEnumCell}
                                title={titleWithIcon}
                                width={column.width ?? 80}
                                editable={column.editable}
                            />
                        );
                    }
                    else if (column.type === COLUMNSTYPE.multiselectdropdown) {
                        return (
                            <GridColumn
                                key={index}
                                field={column.name}
                                cell={(props) => <DropdownCell {...{ ...props, data: genre, onChange: column.name == "Genres" ? onChangeGenre : onChangeSubGenre }} />}
                                title={titleWithIcon}
                                width={column.width ?? 80}
                                editable={column.editable}
                            />
                        );
                    }
                    else {
                        return (
                            column.type !== COLUMNSTYPE.hidden && (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    title={titleWithIcon}
                                    width={column.width ?? 200}
                                    editable={column.editable}
                                />
                            )
                        );
                    }
                })}
            </Grid>}
            {showPreferences && <UserPreference entityName={ENTITYNAME.MediaEpisodeMultiEdit} preferenceColumnENntityName={ENTITYNAME.MediaEpisodeMultiEdit} handleClose={() => {setShowPreferences(false); loadColumns();}} />}
        </BossDialog>
    );
}

export default UpdateMultipleEpisodeDialog;