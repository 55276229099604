/* eslint-disable */

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { PlanningHelper } from "../../Planning/helper/PlanningHelper";

//to show item stored in array on collection
export const MyPublishedChannelArrayCell = (props) => {

    const [isHover, setIsHover] = useState(false);
    const navigate = useNavigate();

    const field = props.field || "";
    let value = [];
    if(field.includes('.')){
      let keys = field.split('.');
      value = props.dataItem[keys[0]] ? props.dataItem[keys[0]][keys[1]] ? props.dataItem[keys[0]][keys[1]] : []  : [];
    }
    else{
      value =  props.dataItem[field]; 
    } 

    const onChannelClick = (item) => {   
        
        var data = {
            planningdate: new Date(item.SlotDateTime).toISOString(),
            channels: [item.Channel],
        }

        PlanningHelper.setPlanningPublishDataInLocalStorage(data);
        navigate('/home/planning');

        //doubt the channel name is not showing from routing here
    }
    
    return <td key={props?.dataItem?._id}>
      {value?.length > 0 ? value.map((x,index) => (

        <span  
            style={{color : isHover ? 'blue' : 'black',cursor : 'pointer'}} 
            onClick={() => onChannelClick(x)}
            onMouseEnter={() => setIsHover(true)}
            onMouseLeave={() => setIsHover(false)}
            key={props?.dataItem?._id}
        >
            {x.Channel.FullChannelName}{index == value?.length -1 ?  "" : ", "}
        </span>

      )) : ""}
    </td>;
  };