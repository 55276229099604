import React, { startTransition, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as API from "../../framework/API/api";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY } from '../../framework/constant/constant';
import { Loader } from '../../framework/forms/helpercomponents/Loader';
import { utility } from '../../framework/utility/utilityProvider';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { DateCell, DateOnlyCell, LocalDateTimeColumn } from '../../framework/forms/helpercomponents/CustomGridCells/DateCell';
import { TimeCell } from '../../framework/forms/helpercomponents/CustomGridCells/TimeCell';
import { ImageCell } from '../../framework/forms/helpercomponents/CustomGridCells/ImageCell';
import { LookupCell } from '../../framework/forms/helpercomponents/CustomGridCells/LookupCell';
import { MyToggleButton } from '../../framework/forms/helpercomponents/CustomGridCells/MyToggleButton';
import { ArrayCell } from '../../framework/forms/helpercomponents/CustomGridCells/ArrayCell';
import { CheckboxCell } from '../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell';
import { HrefCell } from '../../framework/forms/helpercomponents/CustomGridCells/HrefCell';
import { EnumCell } from '../../framework/forms/helpercomponents/CustomGridCells/EnumCell';
import { PlatformCell, arrayEnumCell } from '../../framework/forms/helpercomponents/CustomGridCells/PlatformCell';
import MyStatusCell from '../../components/MyStatusCell';
import { IconCell } from '../../framework/forms/helpercomponents/CustomGridCells/IconCell';
import BackRoundButton from '../../framework/forms/helpercomponents/buttons/BackRoundButton';
import { useNavigate } from 'react-router-dom';
import EditRoundButton from '../../framework/forms/helpercomponents/buttons/EditRoundButton';

const MediaEpisodesBySeriesAssetIdCollection = (props) => {

    const AssetId = window.location.pathname.split("/").pop();

    const navigate = useNavigate();

    const [data, setData] = useState([]);
    const [filterData, setFilterData] = useState([]);
    const [series, setSeries] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [dataColumns, setDataColumns] = useState([]);

    useEffect(() => {
        loadData();
    }, []);

    const getSeriesIdFromAssetId = async () => {
        let res = await API.getData(ENTITYNAME.Series, { query: ["AssetId", "=", AssetId] });
        if (res.success && res.data.length > 0) {
            setSeries(res.data[0]);
            return res.data[0]._id;
        } else {
            return null;
        }
    }

    const loadData = async () => {
        setIsLoading(true);

        let seriesId = await getSeriesIdFromAssetId();
        if (seriesId) {
            let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
            let columns = await API.getFormData(ENTITYNAME.Season, currentUserId);
            setDataColumns(columns);
            let res = await API.getData(ENTITYNAME.Season, { query: [`Series_Id`, '=', seriesId] });
            if (res.success) {
                setData(res.data);
            } else {
                toast.error(res.message, { position: toast.POSITION.TOP_RIGHT });
            }
        } else {
            toast.error('series not found for this AssetId', { position: toast.POSITION.TOP_RIGHT });
        }
        setIsLoading(false);
    }

    const onSearch = (e) => {
        e.preventDefault();
        let text = e.target.value?.trim();
        startTransition(() => {
            if (text == "") {
                setFilterData(data);
            } else {
                setFilterData(data.filter((item) => item.Title.toLowerCase().includes(text.toLowerCase())));
            }
        })
    }

    const MyEditCell = (props) => {
        return (
            <td>
                <EditRoundButton
                    title={'Edit'}
                    onClick={() => navigate(`/home/MediaEpisode/Season/${props?.dataItem?.AssetId}`)}
                />
            </td>
        );
    }

    return (
        <div>
            <h1 className="page-title txt-color-bludeDark mb-0 mt-0">
                <i
                    className="fa fa-table mr-1"
                    style={{ fontSize: "20px", color: "GrayText" }}
                ></i>
                <span>{series?.Title ?? ''}</span>
            </h1>
            <div className="flex-container">
                <div style={{ display: "flex", alignItems: "center" }}>
                    <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
                </div>
                <div className="flex-container-reverse flex-item-auto">
                    <div className="input-group input-group w-300 martb" >
                        <input
                            type="text"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="searchInput"
                            placeholder={'Search'}
                            onChange={onSearch}
                        />
                        <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                </span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>
            {isLoading && <Loader />}
            {!isLoading &&
                <Grid
                    style={{
                        height: "78vh",
                    }}
                    data={filterData.length > 0 ? filterData : data}
                    resizable={true}
                >
                    {/* Edit Row */}
                    <GridColumn
                        width="60px"
                        cell={MyEditCell}
                        locked={true}
                    />
                    {/* Rows Loop */}
                    {dataColumns.map((column, index) => {
                        if (column.type === COLUMNSTYPE.date) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={DateOnlyCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                            // }else if (column.name === COLUMNSTYPE.time) {
                        } else if (column.type === COLUMNSTYPE.datetime) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={DateCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                            // }else if (column.name === COLUMNSTYPE.time) {
                        } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={TimeCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        } else if (column.name === "SID") {
                            return;
                            //we are not returning any value for SID column
                        } else if (column.type === COLUMNSTYPE.image) {
                            return (
                                <GridColumn
                                    sortable={false}
                                    key={index}
                                    field={column.name}
                                    cell={ImageCell}
                                    title={column.label}
                                    width={column.width ?? 100}
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.select) {
                            return (
                                <GridColumn
                                    sortable={false}
                                    key={index}
                                    field={column.name}
                                    cell={(props) => <LookupCell {...props} dataColumns={dataColumns} />}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.toggle) {
                            return (
                                <GridColumn
                                    sortable={false}
                                    key={index}
                                    field={column.name}
                                    cell={(props) => <MyToggleButton
                                        {...props}
                                        entityName={ENTITYNAME.MediaEpisode}
                                        size={"small"}
                                    />}
                                    title={column.label}
                                    width={85}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.checkbox) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={CheckboxCell}
                                    title={column.label}
                                    width={column.width ?? 80}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.array) {
                            return (
                                <GridColumn
                                    sortable={false}
                                    key={index}
                                    field={column.name}
                                    cell={ArrayCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                    format={column.format ?? "Description"} //display item in object
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.href) {
                            return (
                                <GridColumn
                                    sortable={false}
                                    key={index}
                                    field={column.name}
                                    cell={HrefCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                    format={column.format}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.enum) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={EnumCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                    format={column.format ?? "Description"} //display item in object
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.platform) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={PlatformCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.status) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyStatusCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.localdatetime) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={LocalDateTimeColumn}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.icon) {
                            return (
                                <GridColumn
                                    sortable={false}
                                    key={index}
                                    field={column.name}
                                    cell={IconCell}
                                    title={column.label}
                                    width={column.width ?? 80}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.arrayenum) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={arrayEnumCell}
                                    title={column.label}
                                    width={column.width ?? 80}
                                />
                            );
                        }
                        else {
                            return (
                                column.type !== COLUMNSTYPE.hidden && (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        title={column.label}
                                        width={column.width ?? 200}
                                    />
                                )
                            );
                        }
                    })}
                </Grid>}
        </div>
    );
}

export default MediaEpisodesBySeriesAssetIdCollection;
