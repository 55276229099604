/* eslint-disable */
import React, { useEffect, useState } from "react";
import { ENTITYNAME } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { EditPageHeader } from "../../components/EditPageHeader";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import BossDialog from "../../components/BossDialog";

export const FinancialYearEditForm = (props) => {
    const lang=useTranslation();

    const [dataItem, setDataItem] = useState({});

    useEffect(() => {
        if(props.item.SID > 0 || props.item.copy){
            loadEdit();
        }
    }, []);

    const loadEdit = () => {
        let editData = {
            SID: props.item.SID,
            Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
            FromDate: utility.convertMilisecondsToDateString(props.item.FromDate) ?? "",
            ToDate: utility.convertMilisecondsToDateString(props.item.ToDate) ?? "",
            Archive: props.item.Archive ?? false,
        }
        setDataItem(editData)
    }

    const isValid = () => {

        if (dataItem.Description == "" || dataItem.Description == undefined || !dataItem.Description.trim()) {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.FromDate == "" || !utility.convertStringDatetoMilliseconds(dataItem.FromDate)) {
            toast.error(`${lang.please_select_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.ToDate == "" || !utility.convertStringDatetoMilliseconds(dataItem.ToDate)) {
            toast.error(`${lang.please_select_to_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (utility.convertStringDatetoMilliseconds(dataItem.ToDate) <= utility.convertStringDatetoMilliseconds(dataItem.FromDate)) {
            toast.error(`${lang.to_date_should_be_greater_than_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;

    }

    const onChange = (e) => {
        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const handleSubmit = async () => {

        if (!isValid()) return;

        let localData = {
            SID: dataItem.SID ?? 0,
            Description: dataItem.Description,
            FromDate: utility.convertStringDatetoMilliseconds(dataItem.FromDate),
            ToDate: utility.convertStringDatetoMilliseconds(dataItem.ToDate),
            Archive: dataItem.Archive ?? false,
            checkDuplicate: true,
            query: [["Description", "=", dataItem.Description]]
        }
        console.log(localData)

        let res = await API.saveData(ENTITYNAME.FinancialYear, localData);
        if (res.success) {
            props.cancelEdit();
            props.refresh();
            utility.deleteLocalStorageItem(ENTITYNAME.FinancialYear);
            return;
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    return (
        <>
            <BossDialog
                title={props.item.Description ? props.item.Description : `${lang.financial_year_dialog_header}`}
                onClose={props.cancelEdit}
                width={"40%"}
            >
                <div className="row">
                    <div className="col-12">
                        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                        <div className="row mt-2">
                            <div className="col-12 form-group">
                                <label htmlFor="TabView">{lang.description_label} *</label>
                                <input type="text" className="form-control form-control-sm" name="Description" onChange={onChange} value={dataItem.Description} />
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">{lang.from_date_label} *</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-sm"
                                        name="FromDate"
                                        value={dataItem.FromDate}
                                        onChange={onChange}
                                        min={dataItem.From}
                                        max={dataItem.To}
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">{lang.to_date_label} *</label>
                                    <input
                                        type="date"
                                        className="form-control form-control-sm"
                                        name="ToDate"
                                        value={dataItem.ToDate}
                                        onChange={onChange}
                                        min={dataItem.From}
                                        max={dataItem.To}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className="row mt-2 ">
                            <div className="col-3">
                                <input type="checkbox" name="Archive" value={dataItem.Archive} checked={dataItem.Archive} onChange={onChange} />
                                <label htmlFor="TabView" className="pl-2">{lang.archive}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </BossDialog>
        </>
    )
}