
import React, { useEffect, useRef, useState } from 'react';
import { EditPageHeader } from '../../components/EditPageHeader';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { CellRender, RowRender } from '../Segment/renderers';
import { utility } from '../../framework/utility/utilityProvider';
import { myTimeCellMilliseconds } from '../../framework/forms/helpercomponents/inline grid cell/MyTimeCellInMillisconds';
import { SchedulingHelper } from '../Scheduling/helper/SchedulingHelper';
import { ENTITYNAME, LOCALSTORAGE_KEY, LOGEVENT, MODULE } from '../../framework/constant/constant';
import * as API from "../../framework/API/api";
import { toast } from 'react-toastify';
import { TimeCell } from '../../framework/forms/helpercomponents/CustomGridCells/TimeCell';
import BossDialog from '../../components/BossDialog';

const HeaderLockPopup = ({ onClose, header, segments, nextHeaderStartTime }) => {
    const EDIT_FIELD = 'inEdit';

    const [isFinal, setIsFinal] = useState(false);
    const [newSegments, setNewSegments] = useState(segments ?? []);
    const editedSegementsRef = useRef(segments ?? []);
    console.log(header);
    console.log(nextHeaderStartTime);
    console.log(newSegments);
    useEffect(() => {
        updateScheduleColorPreferenceOnFirstLoad();
    }, []);

    const updateScheduleColorPreferenceOnFirstLoad = async () => {
        const user = utility.getValue(LOCALSTORAGE_KEY.userData);
        await API.getDataLookup(ENTITYNAME.UserPreference, {
            query: [["entityName", "=", "schedulepreference"], ["user_id", "=", user._id]],
            sort: { SID: 1 },
            page: null,
            perPage: null
        }, LOCALSTORAGE_KEY.schedulepreference);
        setNewSegments(segments?.map(item => ({ ...item, style: SchedulingHelper.getScheduleColorPreference(item) })) ?? []);
        editedSegementsRef.current = segments?.map(item => ({ ...item, style: SchedulingHelper.getScheduleColorPreference(item) })) ?? [];
    }

    const enterEdit = (dataItem, field) => {
        const newData = newSegments.map(item => ({
            ...item,
            [EDIT_FIELD]: (item._id == dataItem._id && item.Sequence === dataItem.Sequence) ? field : undefined
        }));
        editedSegementsRef.current = newData;
        setNewSegments(newData);
    };

    const exitEdit = () => {
        const newData = newSegments.map(item => ({
            ...item,
            [EDIT_FIELD]: undefined
        }));
        editedSegementsRef.current = newData;
        setNewSegments(newData);
    };

    const customCellRender = (td, props) => <CellRender originalProps={props} td={td} enterEdit={enterEdit} editField={EDIT_FIELD} />;

    const customRowRender = (tr, props) => <RowRender originalProps={props} tr={{ ...tr, props: { ...tr.props, style: { ...props.dataItem.style } } }} exitEdit={exitEdit} editField={EDIT_FIELD} />;

    const itemChange = event => {
        let field = event.field || '';
        if (field == 'SlotDateTime') {
            let time = new Date(event.dataItem.SlotDateTime).setUTCHours(0, 0, 0, 0);
            event.dataItem[field] = time + utility.convertStringWithFramesToMilliseconds(event.value);
        }

        let newData = newSegments.map(item => {
            if (item._id === event.dataItem._id) {
                return event.dataItem;
            }
            return item;
        });
        editedSegementsRef.current = newData;
        setNewSegments(newData);
    };

    const onSubmit = async () => {
        if (!isFinal) {
            let updatedDataRes = await API.lockItem(header, newSegments, false, nextHeaderStartTime);
            console.log(updatedDataRes);
            if (updatedDataRes.success) {
                setNewSegments(updatedDataRes.data);
                setIsFinal(true);
                return;
            } else {
                toast.error(updatedDataRes.message, { position: toast.POSITION.TOP_RIGHT });
                return;
            }
        } else {
            let updatedDataRes = await API.lockItem(header, editedSegementsRef.current, true, nextHeaderStartTime);
            if (updatedDataRes.success) {
                toast.success("Item locked successfully", { position: toast.POSITION.TOP_RIGHT });
                let logData = { event: LOGEVENT.LOCK_PLANNING_ITEM, module: MODULE.PLANNING, data:{headerStartTime: header.SlotDateTime, nextHeaderStartTime: nextHeaderStartTime, header: header, segmnets: editedSegementsRef.current}, message: LOGEVENT.LOCK_PLANNING_ITEM };
                API.SaveLogs(logData);
                onClose(true);
            } else {
                toast.error(updatedDataRes.message, { position: toast.POSITION.TOP_RIGHT });
                onClose();
            }
        }
    }

    const onPopupClose = () => {
        if (isFinal) {
            setIsFinal(false);
            setNewSegments(segments ?? []);
            editedSegementsRef.current = segments ?? [];
        } else {
            onClose();
        }
    }

    return (
        <>
            <BossDialog
                title={"Lock Item"}
                onClose={onClose}
                width={'850px'}
            >
                <EditPageHeader saveButtonTitle={isFinal ? 'Save' : 'OK'} title={""} onSubmit={onSubmit} onCancel={onPopupClose} showTitle={false} />
                <div>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <p className='my-1'>Header Start Time : {utility.convertMilisecondsToStringWithFrames(header.SlotDateTime)}</p>
                        {nextHeaderStartTime != null && <p className='my-1'>Next Header Start Time : {utility.convertMilisecondsToStringWithFrames(nextHeaderStartTime)}</p>}
                    </div>
                    <Grid
                        data={newSegments}
                        style={{ height: '300px' }}
                        editField={EDIT_FIELD}
                        cellRender={customCellRender}
                        rowRender={customRowRender}
                        onItemChange={itemChange}
                    >
                        <GridColumn field="BreakNo" title="BreakNo" width={70} editable={false} />
                        <GridColumn field="mediaEpisode.Title" title="Title" width={180} editable={false} />
                        <GridColumn field="MediaId" title="MaterialID" width={180} editable={false} />
                        <GridColumn field="mediaEpisode.AssetId" title="AssetId" editable={false} />
                        <GridColumn field="Duration" title="Duration" cell={TimeCell} editable={false} />
                        <GridColumn field="SlotDateTime" title="SlotDateTime" cell={myTimeCellMilliseconds} editable={true} />
                    </Grid>
                </div>
            </BossDialog>
        </>
    );
}

export default HeaderLockPopup;
