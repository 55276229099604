import { MaskedTextBox } from "@progress/kendo-react-inputs";
import { utility } from "../../../utility/utilityProvider";
//timepicker component with format
export const TimePickerWithFormat = (fieldRenderProps) => {

  let isWithFrames = fieldRenderProps.isWithFrames ?? true;

  const onChange = (e) => {
    var event = {
      ...e,
      target: {
        ...e.target,
        value: utility.validateTimeString(e.target.value),
        name: e.target.name
      },
    }
    fieldRenderProps.onChange(event);
  }
  return (
    <div>
      <MaskedTextBox
        {...fieldRenderProps}
        mask={isWithFrames ? "00:00:00:00" : "00:00:00"}
        defaultValue= {isWithFrames ? "00:00:00:00" : "00:00:00"}
        value={isWithFrames ? utility.validateTimeString(fieldRenderProps.value) : utility.validateTimeString(fieldRenderProps.value,isWithFrames).slice(0,8)}
        onChange={onChange}
        disabled={fieldRenderProps?.disabled ?? false}
      />
    </div>
  );
};