import React from 'react'
import { EditPageHeader } from '../../components/EditPageHeader'
import { useState } from 'react'
import * as API from "../../framework/API/api";
import { ENTITYNAME } from '../../framework/constant/constant';
import { toast } from 'react-toastify';
import { utility } from '../../framework/utility/utilityProvider';
import { useTranslation } from '../../locale/useTranslation';
import { ImageBrowser } from '../../framework/forms/helpercomponents/selectFile/ImageBrowser';
import BossDialog from '../../components/BossDialog';

const ProviderEditForm = (props) => {

  const lang = useTranslation();
  const blankDataItem = {
    ...props.item,
    SID: props.item.SID ?? 0,
    Description: props.item.Description ?? '',
    LogoUrl: props.item.LogoUrl ?? '',
    Archive: props.item.Archive ?? false,
    IsDefault: props.item.IsDefault ?? false
  }

  const [dataItem, setDataItem] = useState(blankDataItem);
  const [imageSelected,setImageSelected] = useState(false);
  const [imageFiles, setImageFiles] = useState(new Set());

  const isValid = () => {
    if (dataItem.Description == "" || dataItem.Description == undefined) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.LogoUrl == "" || dataItem.LogoUrl == undefined) {
      toast.error(`${lang.please_enter_logourl_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const Submit = async () => {

    if (isValid()) {

      let LogoUrl = dataItem.LogoUrl;
      if(imageFiles.size > 0){
        let resUrl = await API.uploadImage(imageFiles);
        if(!resUrl.success){
         toast.error(`${lang.upload_fail_error_message}`, {
           position: toast.POSITION.TOP_RIGHT
         });
         return;
        }
        LogoUrl = resUrl.data
         dataItem.LogoUrl = LogoUrl
      }

      const data = {
        _id: dataItem._id,
        SID: dataItem.SID,
        Description: dataItem.Description,
        LogoUrl: LogoUrl,
        Archive: dataItem.Archive ?? false,
        IsDefault: dataItem.IsDefault ?? false
      }
      console.log(data)
      let res = await API.saveData(ENTITYNAME.OttProvider, data);
      if(dataItem?.IsDefault){
        await API.setAsDefault(ENTITYNAME.OttProvider, data._id);
      }
      if (res.success) {
        props.cancelEdit();
        props.refresh();
        utility.deleteLocalStorageItem(ENTITYNAME.OttProvider)
        return;
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  }

  const onChange = (e) => {
    if (e.target.name == 'Archive') {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    }
    else if (e.target.name == 'IsDefault') {
      setDataItem({ ...dataItem, IsDefault: !dataItem.IsDefault });
    }
    else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value })
    }
  }

  const handleChange = (e) => {
    if(e.target.name == 'LogoUrl' && e.target?.files){
      if(e.target.files != undefined){
        setImageFiles(e.target.files[0]);
      }else{
        setImageFiles(new Set());
      }
    }
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  }

  return (
    <div>
      <BossDialog
        title={props.item.Description ?? `${lang.create_provider_dialog_header}`}
        onClose={props.cancelEdit}
        width={"600px"}
      >
        <EditPageHeader
          title={""}
          onSubmit={Submit}
          onCancel={props.cancelEdit}
          showTitle={false}
        />
        <div className='row'>
          <div className='col-12'>
            <div className='row mt-2'>
              <div className='col'>
                <label htmlFor='Description'>{lang.description_label}*</label>
                <input className="form-control form-control-sm mt-2" type='text' name='Description' value={dataItem.Description} onChange={onChange} />
              </div>
            </div>
            <div className="row mt-2">
              <div className="col-12">
                <label>{lang.image_url_label} *</label>
                  <ImageBrowser name = {'LogoUrl'} value={dataItem.LogoUrl} onChange={handleChange} setImageSelected = {setImageSelected} hidePosterLibrary={true}/>
                </div>
            </div>
            <div className='row mt-3'>
              <div className='col'>
                <input type={'checkbox'}
                  name={"Archive"}
                  value={dataItem.Archive}
                  checked={dataItem.Archive}
                  onClick={onChange}

                />
                <label className='ml-2'>{lang.archive}</label>
              </div>
              <div className='col'>
                <input 
                  type={'checkbox'}
                  name={"IsDefault"}
                  value={dataItem.IsDefault}
                  checked={dataItem.IsDefault}
                  onClick={onChange}

                />
                <label className='ml-2'>{lang.is_default_label}</label>
              </div>
            </div>
          </div>
        </div>
      </BossDialog>
    </div>
  )
}

export default ProviderEditForm
