import React, { useEffect, useState } from "react";
import { COMPANYTYPE, ENTITYNAME, LOCALSTORAGE_KEY } from "../../framework/constant/constant";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { EditPageHeader } from "../../components/EditPageHeader";
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import DigitalSignCollectionSelection from "./DigitalSignCollectionSelection";
import { DigitalSign_ContentColumns } from "../../framework/utility/customColumns";
import { EnumCell } from "../../framework/forms/helpercomponents/CustomGridCells/EnumCell";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as API from "../../framework/API/api_digitalSign";
import { useLocation, useParams } from "react-router-dom";
import { ByteToSizeCell } from "../../framework/forms/helpercomponents/CustomGridCells/ByteToSizeCell";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import { utility } from "../../framework/utility/utilityProvider";
import { DropDownList } from "@progress/kendo-react-dropdowns";

const DigitalSignContentGroupEditForm = (props) => {

  const lang = useTranslation();
  const navigate = useNavigate();
  const { SID } = useParams();
  const loc = useLocation();

  const blankData = {
    Name: "",
    Archive: false,
  };

  const [dataItem, setDataItem] = useState(blankData);
  const [showContentForm, setShowContentForm] = useState(false);
  const [gridData, setGridData] = useState([]);
  const company = utility.getValue(LOCALSTORAGE_KEY.COMPANY);
  const companies = utility.getValue(LOCALSTORAGE_KEY.COMPANIES);
  const [isEnterpriseLogin] = useState(company?.CompanyType == COMPANYTYPE.ENTERPRISE);
  const [selectedCompany, setSelectedCompany] = useState((SID == 0 && companies?.length > 0) ? companies[0] : {});

  const companyId = isEnterpriseLogin ? selectedCompany?._id : company?._id

  useEffect(() => {
    if (SID > 0) {
      loadEditData();
    }
  }, []);

  const loadEditData = async () => {

    let linkData = {
      link_collection: "digitalsign_content",
      link_field: "Content",
    };

    let res = await API.getEntityWithLookup(ENTITYNAME.DigitalSignContentGroup, SID, linkData);
    console.log(res);
    if (res.success) {
      setDataItem({
        ...res.data,
        Name: loc.state.copy ? "Copy of " + res.data.Name : res.data.Name,
      });
      setGridData(res.data.Content);
      setSelectedCompany(companies?.length > 0 ? companies.find(x => x?._id == res.data?.Company_id) : {});
    }
  };

  const onChange = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  };

  const handleSelectedContent = (dataList) => {
    let newData = utility.checkDuplicateInData(dataList, gridData, "fileName")
    setGridData([...gridData, ...newData]);
  };

  const onContentDelete = (_id) => {
    let filtered = gridData.filter((x) => x._id !== _id);
    setGridData(filtered);
  };

  const MyDeleteCell = (props) => {
    return (
      <td>
        <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => onContentDelete(props.dataItem._id), () => { })} />
      </td>
    );

  };

  const isValid = () => {
    if (dataItem.Name == undefined || dataItem.Name == "") {
      toast.error(`${lang.please_enter_name_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    if (isEnterpriseLogin && (!selectedCompany || Object.keys(selectedCompany).length == 0)) {
      toast.info(lang.please_select_company_error_message, {
        position: toast.POSITION.TOP_RIGHT
      })
      return false;
    }
    if (gridData.length == 0) {
      toast.error(`${lang.please_attach_atleast_one_content_error_message}`, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return false;
    }
    return true;
  }

  const handleSubmit = async () => {
    if (!isValid()) return;
    const localData = {
      _id: dataItem._id,
      SID: dataItem.SID,
      Name: dataItem.Name,
      Content: gridData.length > 0 ? gridData.map((x) => x._id) : [],
      Archive: dataItem.Archive ?? false,
      Company_id: isEnterpriseLogin ? selectedCompany._id : company?._id,
      checkDuplicate: true,
      query: [["Name", "=", dataItem.Name]],
    };
    if (loc.state.copy) {
      delete localData._id;
      delete localData.SID;
    }

    let res = await API.saveData(ENTITYNAME.DigitalSignContentGroup, localData);
    console.log(res);
    if (!res.success) {
      toast.error(res.message, { position: toast.POSITION.TOP_RIGHT, });
    } else {
      navigate(-1);
    }
  };

  return (
    <>
      <div className="row m-1">
        <div className="col-12">
          <EditPageHeader
            title={dataItem?.Name != "" ? dataItem?.Name : lang.content_group_editpage_header}
            onSubmit={handleSubmit}
            onCancel={() => navigate(-1)}
          />
          <div className="row">
            <div className="col-6">
              <label htmlFor="">{lang.name_label}*</label>
              <input
                type="text"
                className="form-control form-control-sm"
                name="Name"
                value={dataItem.Name}
                onChange={onChange}
              />
            </div>
            {isEnterpriseLogin && <div className="col-6">
              <label htmlFor="TabView">{lang.company_label} *</label>
              <DropDownList
                style={{ backgroundColor: "white" }}
                data={companies}
                name="Company"
                textField="Name"
                dataItemKey="_id"
                value={selectedCompany}
                onChange={(e) => setSelectedCompany(e.target.value)}
              />
            </div>}
          </div>
          <div className="row">
            <div className="col mt-4">
              <input
                name="Archive"
                type="checkbox"
                value={dataItem.Archive}
                checked={dataItem.Archive}
                onChange={onChange}
              />
              <label className="ml-1">{lang.archive}</label>
            </div>
          </div>
          <div className="row mb-4 mt-3">
            <div className="col-12">
              <AddRoundButton title={lang.add_content_button_tooltip} onClick={() => setShowContentForm(true)} />
              <Grid
                data={gridData}
                style={{ height: "70vh", marginTop: "10px" }}
              >
                <Column editable={false} cell={MyDeleteCell} width={"60px"} />
                <Column
                  field="thumbnailUrl"
                  title={"Thumbnail"}
                  editable={false}
                  cell={ImageCell}
                  width={"100px"}
                />
                <Column
                  field="fileName"
                  title={"File Name"}
                  editable={false}
                  width={200}
                />
                <Column
                  field="bytes"
                  title={"Size"}
                  editable={false}
                  cell={ByteToSizeCell}
                  width={200}
                />
                <Column
                  field="content_type"
                  title={"Type"}
                  editable={false}
                  cell={EnumCell}
                  width={200}
                />
                <Column
                  field="orientation"
                  title={"Orientation"}
                  editable={false}
                  cell={EnumCell}
                  width={200}
                />
                <Column
                  field="format"
                  title={"Format"}
                  editable={false}
                  width={200}
                />
              </Grid>
            </div>
          </div>
        </div>
      </div>
      {showContentForm && (
        <DigitalSignCollectionSelection wherestatement={["Company_id", "=", companyId]} setDataList={handleSelectedContent} customColumns={DigitalSign_ContentColumns} title={lang.digital_sign_add_content_dialog_header} entityname={ENTITYNAME.DigitalSignContent} closeForm={() => setShowContentForm(false)} width={"50vw"} />
      )}
    </>
  );
};

export default DigitalSignContentGroupEditForm;
