/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { useTranslation } from '../../../locale/useTranslation';
import { TimeCell } from '../../../framework/forms/helpercomponents/CustomGridCells/TimeCell';
import { PROTOTYPETRIGGERTYPEENUM, SCHEDULECOLORPREFERENCE } from '../../../framework/constant/constant';
import SecondaryEventGanntView from './SecondaryEventGanntView';
import { utility } from '../../../framework/utility/utilityProvider';

export const SchedulingSecondaryEvent = (props) => {

    const { selectedState = {}, showTimeLineView = false, hideTriggerStartEnd = false } = props;
    const lang = useTranslation();
    const [secondaryEvents, setSecondaryEvents] = useState([]);
    const [timelineViewData, setTimelineViewData] = useState([]);
    const [viewMode, setViewMode] = useState(true);
    const handleView = () => { setViewMode(!viewMode) }

    useEffect(() => {
        if (selectedState != undefined && selectedState.Prototypes) {
            if (selectedState.Prototypes.length > 0) {
                let finalData = getSecondaryEventsWithActualTriggers(selectedState, selectedState.Prototypes);
                setSecondaryEvents(finalData);
                setTimelineViewData(finalData);              
            } else {
                setSecondaryEvents([]);
                setTimelineViewData([]);
            }
        }

    }, [selectedState]);

    const getSecondaryEventsWithActualTriggers = (selectedSchedule, secondaryEvents) => {

        let finalSe = [];

        if(secondaryEvents && secondaryEvents.length > 0){


            for (let index = 0; index < secondaryEvents.length; index++) {
                let element = secondaryEvents[index];
                const selectedScheduleSlotEndTime = selectedSchedule.SlotDateTime + selectedSchedule.Duration;

                // we will calculate start trigger first
                if(element.StartTriggerType && element.StartTriggerType.ID == PROTOTYPETRIGGERTYPEENUM.Duration){
                    element.TriggerStartTime = element.IsStartNegative ? selectedSchedule.SlotDateTime - element.StartOffset : selectedSchedule.SlotDateTime + element.StartOffset;
                }
                else if(element.StartTriggerType && element.StartTriggerType.ID == PROTOTYPETRIGGERTYPEENUM.ParentEnd){
                    element.TriggerStartTime = element.IsStartNegative ? (selectedScheduleSlotEndTime - element.StartOffset) : (selectedScheduleSlotEndTime + element.StartOffset);
                }
                else{ // default parent start
                    element.TriggerStartTime = element.IsStartNegative ? selectedSchedule.SlotDateTime - element.StartOffset : selectedSchedule.SlotDateTime + element.StartOffset;
                }



                // we will calculate end trigger now
                if(element.EndTriggerType && element.EndTriggerType.ID == PROTOTYPETRIGGERTYPEENUM.Duration){
                    element.TriggerEndTime = element.IsEndNegative ?  selectedSchedule.SlotDateTime - element.EndOffset : selectedScheduleSlotEndTime + element.EndOffset;
                }
                else if(element.EndTriggerType && element.EndTriggerType.ID == PROTOTYPETRIGGERTYPEENUM.ParentStart){
                    element.TriggerEndTime = element.IsEndNegative ? (selectedSchedule.SlotDateTime - element.EndOffset) : (selectedSchedule.SlotDateTime + element.EndOffset);
                }
                else{ // default parent end
                    element.TriggerEndTime = element.IsEndNegative ? selectedScheduleSlotEndTime - element.EndOffset : selectedScheduleSlotEndTime + element.EndOffset;
                }

                finalSe.push(element);
            }
        }
        return finalSe;
    }

    const GridRowRenderScheduleSecondaryEvents = (tr, props) => {
        let style = SCHEDULECOLORPREFERENCE.lightgrey;
        const trProps = {
            style: style,
            draggable: false,
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    return (
        <div style={{
            position: 'absolute',
            left: 0,
            height: "80%",
            width: '100%',
            padding: '8px',
            marginTop: '-15px'
        }}>
            {showTimeLineView && <> <div style={{ float: 'right', marginTop: "-6px", cursor: "pointer", fontSize: '12px' }} onClick={handleView} title={viewMode ? lang.grid_view_label : lang.time_line_view_tooltip}><i className={`fas ${viewMode ? 'fa-table-cells' : 'fa-timeline'} fa-xl`} /></div><br /></>}

            {viewMode ?
                <Grid
                    data={secondaryEvents}
                    rowRender={GridRowRenderScheduleSecondaryEvents}
                    style={{ height: "100%" }}
                    resizable={true}
                >
                    <Column field="Name" title={lang.name_column} width="120px" />
                    <Column field="ID" title={lang.id_column} width="120px" />
                    <Column field="ProtoTypeSource.Name" title={lang.source_column} width="120px" />
                    <Column field="StartTriggerType.Description" title={lang.start_type_column} width="120px" />
                    <Column field="StartOffset" title={lang.start_offset_column} cell={TimeCell} width="120px" />
                    {!hideTriggerStartEnd && <Column field="TriggerStartTime" title={lang.trigger_start_time_label} cell={TimeCell} width="120px" />}
                    <Column field="EndTriggerType.Description" title={lang.end_type_column} width="120px" />
                    <Column field="EndOffset" title={lang.end_offset_column} cell={TimeCell} width="120px" />
                    {!hideTriggerStartEnd && <Column field="TriggerEndTime" title={lang.trigger_end_time_label} cell={TimeCell} width="120px" />}
                    <Column field="Text1" title={lang.text_1_column} width="180px" />
                    <Column field="Text2" title={lang.text_2_column} width="180px" />
                    <Column field="Text3" title={lang.text_3_column} width="180px" />

                </Grid>
                :
                <SecondaryEventGanntView selectedState={selectedState} timelineViewData={timelineViewData} />}
        </div>

    )
}
