import { useTranslation } from "../../../../locale/useTranslation";

const AddRoundButton = (props) => {
    const lang = useTranslation();

    return <button disabled={props.disabled ?? false} title={props.title ? props.title : lang.add_button_tooltip} type="submit" onClick={props.onClick} style={{ ...props.style ?? { margin: "0px 03px" }, pointerEvents: props.disabled ? "none" : "auto", boxShadow: "none" }} className="btn btn-default btn-circle btn-my">
        <i className="fa fa-plus fa-fw img-circle"></i>

    </button>
}

export default AddRoundButton;
