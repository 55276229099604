/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME, FILEURL, PLANNINGTYPE, SCHEDULETYPE } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";


export const MamAssetReport = () => {

    const blankDataItem = {
        ScheduleDate: "",
        Channel: {},
    }
    
    const lang = useTranslation();
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [gridData, setGridData] = useState([]);
    const [channel, setChannel] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(
        `${lang.no_record_found_error_message}`
    );

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        var channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelRes.data);
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    };

    const isValid = () => {
        if (!utility.isValidDate(dataItem.ScheduleDate)) {
            toast.error(`${lang.please_select_schedule_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true
    }

    const loadData = async () => {

        if (!isValid()) return;

        setLoadingStatus(lang.data_loading_error_message)

        // Schedule Payload
        var payload = {
            ScheduleDate: (new Date(dataItem.ScheduleDate)).setHours(0, 0, 0, 0),
            ScheduleEndDate: (new Date(dataItem.ScheduleDate)).setHours(23, 59, 59),
            channelSID: dataItem.Channel.SID
        }

        // Loading Schedule data
        const resScheduleData = await API.getScheduling(payload);

        if(resScheduleData.data.length == 0){
            toast.info(`${lang.no_data_found_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            setGridData([]);
            setLoadingStatus(`${lang.no_record_found_error_message}`);
            return;
        }

        // Loading Media Assets
        var resMediaAssets = await API.getData(ENTITYNAME.MediaMamAssets);

        // filter header from schedule.
        const filteredSchedule = resScheduleData.data.filter(x => x.Type != SCHEDULETYPE.Header);
  
        // Finding uniqie Media IDs
        const uniqueScheduleMediaIds = [...new Map(filteredSchedule?.map(item => [item["MediaId"], item])).values()];
        
        var tempArray = []

        for (const uniqueMedia of uniqueScheduleMediaIds) 
        {
            let status = "MISSING";  // Default status

            // Checking if media is present in Media Assets
            const mediaAsset = resMediaAssets.data.find(x => x.AssetId == uniqueMedia.MediaId);

            if(mediaAsset && mediaAsset._id)
            {
                status = mediaAsset.ActualDuration !== uniqueMedia.Duration ? "DURATION MISMATCH" : "READY";
            }

            tempArray.push({
                Title: uniqueMedia.mediaEpisode.Title,
                MediaId: uniqueMedia.MediaId,
                Type: uniqueMedia.mediaEpisode.MediaCategory.Description,
                Duration: uniqueMedia.Duration,
                Status: status,
                MamDuration: mediaAsset?.ActualDuration??0
            });
        }

        setGridData(tempArray)
        setLoadingStatus(`${lang.no_record_found_error_message}`);

    }

    const downloadExcel = async () => {

        if (gridData.length == 0) {
            return;
        }

        console.log(gridData);

        var storeData = gridData.map(x => {
            return {
                ...x,
                Duration: utility.convertMilisecondsToStringWithFrames(x.Duration),
                MamDuration: utility.convertMilisecondsToStringWithFrames(x.MamDuration)
            }
        })
        var res = await API.exportCSV(storeData);
        if (res.success) {
            window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
            toast.success(`${lang.successfully_download_success_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    return (<>
        <h1 className="page-title txt-color-bludeDark">
            <i
                className="fa fa-table marlr"
                style={{ fontSize: "20px", color: "GrayText" }}
            ></i>
            <span>{lang.mam_asset_report_page_breadcrumb}</span>
        </h1>
        <div className="row m-1">
            <div className="col-12">
                <div className="row">
                    <div className="col-3">
                        <label className="form-label">{lang.schedule_date_label} *</label>
                        <input type="date" className="form-control form-control-sm" name="ScheduleDate" value={dataItem.ScheduleDate} onChange={onChange} />
                    </div>
                    <div className="col-3">
                        <label className="form-label">{lang.channel_label} *</label>
                        <DropDownList
                            data={channel}
                            textField="FullChannelName"
                            dataItemKey="SID"
                            name="Channel"
                            onChange={onChange}
                            value={dataItem.Channel}
                        />
                    </div>
                    <div className="col mt-4">
                        <ActionButton name={lang.show_button_text} onClick={loadData} />
                        <RoundButton icon='download' onClick={() => downloadExcel()} style={{ marginLeft: '10px' }} title={lang.download_button_tooltip} />
                    </div>
                </div>
                <div className="row mt-3">
                    <div className="col-12">
                        <Grid data={gridData} style={{height: '70vh'}}>
                            <GridNoRecords>{loadingStatus}</GridNoRecords>
                            <Column field="Title" title={lang.title_column} />
                            <Column field="MediaId" title={lang.media_id_column} />
                            <Column field="Type" title={lang.type_column} />
                            <Column field="Duration" title={lang.schedule_duration_column} cell={TimeCell} />
                            <Column field="MamDuration" title={lang.mam_duration_column} cell={TimeCell} />
                            <Column field="Status" title={lang.status_column} />
                        </Grid>
                    </div>
                </div>
            </div>
        </div>

    </>)
}
export default MamAssetReport;