/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME, FILEURL, LOCALSTORAGE_KEY, } from "../../framework/constant/constant";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import { Grid, GridColumn as Column, GridNoRecords, } from "@progress/kendo-react-grid";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { MediaFilterTemplate } from "../Planning/MediaFilterTemplate";
import { DataHelper } from "../../framework/helper/DataHelper";

export const MediaMasterReport = (props) => {

  const lang = useTranslation();
  const [dataItem, setDataItem] = useState({});
  const [gridData, setGridData] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(lang.no_record_found_error_message);
  const [isMinified] = useState(utility.getValue(LOCALSTORAGE_KEY.isMenuMified));
  const [enableFilter, setEnableFilter] = useState(false);
  const [filterData, setFilterData] = useState(props?.item?.Filter ?? undefined);
  const [selectedTemplate, setSelectedTemplate] = useState(props?.item?.Filter?.Template ?? {});

  const toGetFilteredData = (data) => {
    setEnableFilter(false);
    setFilterData(data);
    loadData(data);
  };

  const onChange = async (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  };

  const loadData = async (filterData, download = false) => {

    setLoadingStatus(lang.data_loading_error_message);

    let finalFilterData = DataHelper.getMediaFilterQuery(filterData, ENTITYNAME.MediaEpisode);
    console.log(filterData);
    const res = await API.getMediaMasterReport(finalFilterData, download);

    if (res.success) {
      if (download) {
        window.open(FILEURL.BASEURL + 'downloadReport/' + res.fileName)
        toast.success('Successfully Exported!', {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      if (res.data && res.data.length == 0) {
        setGridData([]);
        setLoadingStatus(lang.no_record_found_error_message);
        return;
      }
      setGridData(res.data);
      setLoadingStatus(lang.data_load_successfully_message);
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      setGridData([]);
      setLoadingStatus(lang.no_record_found_error_message);
    }
  };

  const downloadExcel = async () => {
    if (gridData.length == 0) {
      toast.info(lang.no_data_found_error_message, {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    await loadData(filterData, true);
  }

  const clearFilter = () => {
    setFilterData(undefined)
    setGridData([])
    setLoadingStatus(lang.no_record_found_error_message)
  }

  return (
    <>
      <h1 className="page-title txt-color-bludeDark">
        <i
          className="fa fa-table marlr"
          style={{ fontSize: "20px", color: "GrayText" }}
        ></i>
        <span>{lang.media_master_report_page_breadcrumb}</span>
      </h1>
      <div className="row m-1">
        <label htmlFor="TabView">{lang.select_rule_label}</label>
        <div className="col-12 d-flex" style={{ marginLeft: '-15px' }}>
          <input
            name="EnableFilter"
            style={{ width: "800px" }}
            type="text"
            className="form-control form-control-sm"
            value={selectedTemplate?.Name ?? ''}
            onChange={onChange}
            disabled={true}
          />
          <ActionButton
            style={{ height: '31px', borderRadius: '5px' }}
            btnColor={"success"}
            title={lang.select_button_tooltip}
            name={"..."}
            onClick={() => setEnableFilter(true)}
          />
          <ActionButton
            style={{ height: '31px', borderRadius: '5px' }}
            btnColor={"danger"}
            title={lang.clear_button_tooltip}
            name={""}
            icon={"xmark"}
            onClick={clearFilter}
          />
          <RoundButton
            icon="download"
            style={{ marginLeft: '10px', marginTop: '0px' }}
            onClick={downloadExcel}
            title={lang.download_button_tooltip}
          />
        </div>
      </div>
      {enableFilter && (
        <MediaFilterTemplate
          filterDataItem={filterData}
          toGetFilterDataItem={toGetFilteredData}
          closeFilterPopup={() => setEnableFilter(false)}
          hideFields={true}
          isNonLinearFileds={true}
          saveButtonName={lang.save_button_text}
          setSelectedTemplate={setSelectedTemplate}
          selectedTemplate={selectedTemplate}
        />
      )}

      <div className="row mt-3">
        <div className="col-12">
          <Grid data={gridData} style={{ height: "66vh" }}>
            <GridNoRecords>{loadingStatus}</GridNoRecords>
            <Column
              field="Title"
              title={lang.title_column}
              width={150}
            />
            <Column
              field="AssetId"
              title={lang.assetId_column}
              width={100}
            />
            <Column
              field="Description"
              title={lang.description_column}
              width={170}
            />
            <Column
              field="MediaCategory.Description"
              title={lang.media_category_column}
              width={150}
            />
            <Column
              field="MediaCategoryType.Description"
              title={lang.media_category_type_column}
              width={150}
            />
            <Column
              field="Duration"
              title={lang.duration_column}
              cell={TimeCell}
              width={100}
            />
            <Column
              field="Genres"
              format={"Description"}
              title={lang.genres_column}
              cell={ArrayCell}
              width={150}
            />
            <Column
              field="Brand.Name"
              title={lang.brand_column}
              width={150}
            />
            <Column
              field="Product.Name"
              title={lang.product_column}
              width={150}
            />
            <Column
              field="MetaData.Languages"
              format={"Description"}
              title={lang.language_column}
              cell={ArrayCell}
              width={150}
            />
            <Column
              field="MetaData.PGRating.Description"
              title={lang.pg_rating_column}
              width={150}
            />
            <Column
              field="MetaData.CastAndCrew"
              format={"Description"}
              title={lang.cast_and_crew_column}
              cell={ArrayCell}
              width={150}
            />
            <Column
              field="MetaData.CensorRating.Description"
              title={lang.censor_rating_column}
              width={150}
            />
            <Column
              field="MetaData.ProductionYear"
              title={lang.production_year_column}
              width={150}
            />
          </Grid>
        </div>
      </div>
      <div className={isMinified ? "scheduleFooter scheduleFooterMinified" : "scheduleFooter"}>
        <div className="flex-container-reverse flex-item-auto p-2">
          <div className="footerText">{loadingStatus}</div>
        </div>
      </div>
    </>
  );
};
export default MediaMasterReport;
