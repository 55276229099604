import { useTranslation } from "../../../../locale/useTranslation";

const CancelRoundButton = (props) => {
    const lang=useTranslation();
    return <button  title={lang.cancel_button_tooltip} type="submit" onClick={props.onClick} style={{margin:"0px 02px"}} className="btn btn-default btn-circle btn-my" >
       <i className="fa fa-xmark fa-fw img-circle"></i>
       
    </button>
}

export default CancelRoundButton;
