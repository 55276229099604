/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { COLUMNSTYPE, ENTITYNAME } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import BossDialog from "../../components/BossDialog";

export const FormFields = (props) => {

  const [dataItem, setDataItem] = useState({
    ...props.item,
    _id: props.item._id,
    name: props.item.name ?? "",
    label: props.item.label ?? "",
    sequence: props.item.sequence ?? 1,
    type: props.item.type ?? '',
    entityName: props.item.entityName ?? "",
    module: props.item.module ?? "",
    format: props.item.format ?? "",
    maxLength: props.item.maxLength ?? 200,
    width: props.item.width ?? 200,
    required: props.item.required ?? false,
    Archive: props.item.Archive ?? false,
    IsImportable : props.item.IsImportable ?? false
  });

  const lang = useTranslation();

  useEffect(() => {

  }, []);

  const isValid = () => {
    if (dataItem.name == "" || dataItem.name == undefined) {
      toast.error(`${lang.please_enter_name_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (!utility.isNumeric(dataItem.sequence)) {
      toast.error(`${lang.please_enter_valid_sequnce_number_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true;
  }

  const onChange = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    } else if (e.target.name == "required") {
      setDataItem({ ...dataItem, required: !dataItem.required });
    } else if (e.target.name == "IsImportable") {
      setDataItem({ ...dataItem, IsImportable: !dataItem.IsImportable });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }
  const handleSubmit = async () => {

    if (isValid()) {
      let localData = {
        ...dataItem,
        _id: dataItem._id,
        name: dataItem.name,
        label: dataItem.label,
        sequence: parseInt(dataItem.sequence),
        type: dataItem.type,
        entityName: dataItem.entityName,
        module: dataItem.module,
        format: dataItem.format,
        maxLength: dataItem.maxLength,
        width: dataItem.width,
        required: dataItem.required ?? false,
        Archive: dataItem.Archive ?? false,
        IsImportable : dataItem.IsImportable ?? false
      }

      let res = await API.saveData(ENTITYNAME.FormFields, localData);
      console.log(res);
      if (res.success) {
        props.editgrid({ ...localData, _id: res.data._id });
        return;
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }
  }

  return (
    <>
      {<BossDialog
        title={!props.item._id ? `${lang.new_create_formfield_dialog_header}` : props.item.module}
        onClose={props.cancelEdit}
        width={"990px"}
        height={"480px"}
      >
        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
        <Form
          initialValues={dataItem}
          render={(formRenderProps) => (
            <FormElement>
              <div className="row mt-2">
                <div className="col-12">
                  <div className="row mt-2">
                    <div className="col-6 form-group">
                      <label>{lang.name_label} *</label>
                      <input
                        name={"name"}
                        type="text"
                        className="form-control form-control-sm"
                        onChange={onChange}
                        value={dataItem.name}
                      />
                    </div>
                    <div className="col-6 form-group">
                      <label>{lang.label}</label>
                      <input
                        name={"label"}
                        type="text"
                        className="form-control form-control-sm"
                        onChange={onChange}
                        value={dataItem.label}
                      />
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-6 form-group">
                      <label>{lang.sequence_label}</label>
                      <input
                        className="form-control form-control-sm"
                        name={"sequence"}
                        type="number"
                        onChange={onChange}
                        value={dataItem.sequence}
                      />
                    </div>
                    <div className="col-6">
                      <label>{lang.type_label}</label>
                      <DropDownList
                        data={Object.keys(COLUMNSTYPE)}
                        name={"type"}
                        className="form-control form-control-sm"
                        onChange={onChange}
                        value={dataItem.type}
                      />
                    </div>

                  </div>
                  <div className="row mt-2">

                    <div className="col-4">
                      <label>{lang.entity_name_label}</label>
                      <input
                        name={"entityName"}
                        type="text"
                        className="form-control form-control-sm"
                        onChange={onChange}
                        value={dataItem.entityName}
                      />
                    </div>
                    <div className="col-4">
                      <label>{lang.module_column}</label>
                      <input
                        name={"module"}
                        type="text"
                        className="form-control form-control-sm"
                        onChange={onChange}
                        value={dataItem.module}
                      />
                    </div>
                    <div className="col-4">
                      <label>{lang.format_label}</label>
                      <input
                        name={"format"}
                        type="text"
                        className="form-control form-control-sm"
                        onChange={onChange}
                        value={dataItem.format}
                      />
                    </div>
                  </div>

                  <div className="row mt-2">
                    <div className="col-6 form-group">
                      <label>{lang.max_length_label}</label>
                      <input
                        className="form-control form-control-sm"
                        name={"maxLength"}
                        type="number"
                        onChange={onChange}
                        value={dataItem.maxLength}
                      />
                    </div>
                    <div className="col-6 form-group">
                      <label>{lang.width_label}</label>
                      <input
                        className="form-control form-control-sm"
                        name={"width"}
                        type="number"
                        onChange={onChange}
                        value={dataItem.width}
                      />
                    </div>

                  </div>
                  <div className="row mt-2">
                    <div className="col-3  form-group">
                      <input type="checkbox" name={"required"} value={dataItem.required} checked={dataItem.required} onChange={onChange} />
                      <label className="pl-1" htmlFor="TabView">{lang.required_label}</label>
                    </div>
                    <div className="col-3  form-group">
                      <input type="checkbox" name={"IsImportable"} value={dataItem.IsImportable} checked={dataItem.IsImportable} onChange={onChange} />
                      <label className="pl-1" htmlFor="TabView">{lang.isImportable_label}</label>
                    </div>
                    <div className="col-3  form-group">
                      <input type="checkbox" name={"Archive"} value={dataItem.Archive} checked={dataItem.Archive} onChange={onChange} />
                      <label className="pl-1" htmlFor="TabView">{lang.archive}</label>
                    </div>
                  </div>
                </div>
              </div>
            </FormElement>
          )}
        />
      </BossDialog>}
    </>
  )
}