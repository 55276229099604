/* eslint-disable */
import React, { useEffect, useState } from 'react'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { Form, FormElement } from '@progress/kendo-react-form'
import { ENTITYNAME, LOGEVENT, MEDIACATEGORIES, MODULE } from '../../framework/constant/constant'
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton'
import * as API from '../../framework/API/api';
import { TimePickerWithFormat } from '../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat'
import { utility } from '../../framework/utility/utilityProvider'
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation'
import InfoRoundButton from '../../components/InfoRoundButton'
import BossDialog from '../../components/BossDialog'

const AddNewEpisodesForm = (props) => {

  const [content, setContent] = useState([]);
  const lang = useTranslation();
  const blankDataItem = {
    NumberOfEpisodes: 1,
    Duration: "00:00:00:00",
    Content: {},
    StartingEpisode: props.episodes.length > 0 ? Math.max(...props.episodes.map((obj) => parseInt(obj.EpisodeNumber))) + 1 : 1,
  }
  const [dataItem, setDataItem] = useState(blankDataItem);

  useEffect(() => {
    loadCombo();
  }, [])

  const loadCombo = async () => {
    var content = await API.getDataLookup(ENTITYNAME.Content, { sort: { Description: 1 }, query: ['Archive', '=', false] });
    var filteredContent = content.data.filter((item) => item.MediaCategoryType.MediaCategorySID == MEDIACATEGORIES.Episode);
    setContent(filteredContent);
    setDataItem({ ...dataItem, Content: filteredContent[0] })
  };

  const onChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  }

  const isValid = () => {

    if (parseInt(dataItem.NumberOfEpisodes) <= 0) {
      toast.error(`${lang.number_of_episode_can_be_greater_than_zero_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (parseInt(dataItem.StartingEpisode) <= 0) {
      toast.error(`${lang.staring_episode_can_be_greater_than_zero_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    //CHECK VALID EPISODE RANGE
    let existingEpisodeNo = props.episodes && props.episodes.length > 0 ? props.episodes.map((x) => parseInt(x.EpisodeNumber)) : [];
    let invalidEpisodeNo = [];
    for (let i = 0; i < dataItem.NumberOfEpisodes; i++) {
      let newEpisodeNo = parseInt(dataItem.StartingEpisode) + i;
      if (existingEpisodeNo.includes(newEpisodeNo)) {
        invalidEpisodeNo.push(newEpisodeNo)
      }
    }

    if (invalidEpisodeNo.length > 0) {
      toast.error(`Episode Number ${invalidEpisodeNo.join(',').length > 10 ? `${invalidEpisodeNo.join(',').substring(0, 9)}... and more` : invalidEpisodeNo.join(',')} already exists!!! `, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (utility.convertStringWithFramesToMilliseconds(dataItem.Duration) <= 0) {
      toast.error(`${lang.duration_should_be_greater_than_zero_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const onSubmit = async () => {

    if (isValid()) {

      var season_Id = '';
      if (props.seasonData.length == undefined) {
        season_Id = props.seasonData._id
      } else {
        season_Id = props.seasonData[0].data.Season_id
      }

      var durationLocal = utility.convertStringWithFramesToMilliseconds(dataItem.Duration);

      if (durationLocal == 0) {
        toast.error(`${lang.invalid_duration_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }

      const data = {
        seasonId: season_Id,
        contentsid: dataItem.Content.SID,
        episodes: parseInt(dataItem.NumberOfEpisodes),
        duration: durationLocal,
        startingepisode: parseInt(dataItem.StartingEpisode),
        Genres: props.seasonData?.Genres ?? [],
        SubGenres: props.seasonData?.SubGenres ?? [],
        Synopsis: props.seasonData?.Synopsis ?? '',
        ReferenceCode: props.seasonData?.SeasonCode ?? "",
        IsAddingEpisodes: true
      }

      console.log(data);
      var res = await API.createSeriesMediaEpisodes(data);
      if (!res.success) return;

      var payload = {
        data: { Season_id: season_Id, },
        entityName: ENTITYNAME.MediaEpisode
      }

      var updateSeasonEpisodeCount = await API.updateSeasonEpisodeCount(payload);
      console.log(updateSeasonEpisodeCount);

      console.log(res.data);
      if (res.data.length > 0) {
        res.data.forEach((item) => {
          let logData = { event: LOGEVENT.CREATE_EPISODE, module: MODULE.EPISODE, data: item.data, message: "Create Epidose" };
          API.SaveLogs(logData);
        })
      }
      props.setEpisodes(res.data);
      props.cancelEdit();
    }

  }


  return (<>
    <BossDialog
      title={lang.episode_dialog_header}
      onClose={props.cancelEdit}
      width={"480px"}
    >
      <div>
        <ActionButton style={{ borderRadius: '5px' }} title={lang.create_button_tootltip} name={lang.create_button_text} onClick={onSubmit} />
        <ActionButton style={{ borderRadius: '5px' }} title={lang.cancel_button_tooltip} name={lang.cancel_button_text} btnColor={'danger'} onClick={props.cancelEdit} />
      </div>
      <Form
        render={(formRenderProps) => (
          <FormElement>
            <div className="row" style={{ margin: '0px' }}>
              <div className="col-12 mt-2">
                <div className='row'>

                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="TabView">{lang.content_label} *</label>
                      <InfoRoundButton message={lang.used_to_configure_prefix_suffix_and_incremental_next_number_for_assetid_tooltip}></InfoRoundButton>
                      <DropDownList
                        data={content}
                        name="Content"
                        textField="Description"
                        dataItemKey="_id"
                        value={dataItem.Content}
                        onChange={onChange}
                      />
                    </div>
                  </div>
                  <div className='col-6'>
                    <div className="form-group">
                      <label htmlFor="">{lang.no_of_episodes_label} *</label>
                      <input name="NumberOfEpisodes" type="number" className="form-control form-control-sm" value={dataItem.NumberOfEpisodes} onChange={(e) => onChange(e)} min={1} required />
                    </div>
                  </div>

                  <div className='col-6'>
                    <div className="form-group">
                      <label htmlFor="">{lang.starting_episode_label} *</label>
                      <input
                        name="StartingEpisode"
                        type="number"
                        className="form-control form-control-sm"
                        value={dataItem.StartingEpisode}
                        onChange={(e) => onChange(e)}
                        min={1}
                        required
                      />
                    </div>
                  </div>

                  <div className="col-6">
                    <div className="form-group">
                      <label htmlFor="">{lang.duration_label} *</label>
                      <TimePickerWithFormat
                        className="form-control form-control-sm"
                        name="Duration"
                        value={dataItem.Duration}
                        onChange={(e) => onChange(e)}
                        required
                      />


                    </div>
                  </div>
                </div>
              </div>
            </div>
          </FormElement>
        )} />
    </BossDialog>
  </>)
}

export default AddNewEpisodesForm