import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './Styles/default-ocean-blue.css'
import App from './App';
import reportWebVitals from './reportWebVitals';
import {
  unstable_HistoryRouter as Router
} from 'react-router-dom'
import { history } from './components/history';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>

    <Router history={history}>
        <App />
    </Router>

  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
