/* eslint-disable */
import { EditPageHeader } from "../../components/EditPageHeader";
import { useTranslation } from "../../locale/useTranslation";
import { useEffect, useState } from "react";
import { toast } from "react-toastify";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import { TIMECODEMARKERS } from "../../framework/constant/constant";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import { utility } from "../../framework/utility/utilityProvider";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import InfoRoundButton from "../../components/InfoRoundButton";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import BossDialog from "../../components/BossDialog";

const MarkerEditForm = (props) => {
    const lang = useTranslation();
    const blankDataItem = {In: '00:00:00:00', Out: '00:00:00:00', Duration:'00:00:00:00', Tcm: {} }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [gridData, setGridData] = useState([]);
    const [showForm, setShowForm] = useState(false);//setShowForm
    
     useEffect(() => {
      if(props.markerData && props.markerData.length > 0 )
      {
        setGridData(props.markerData)
      }
     
    }, [])


    const onChange = async (e) => {
        var value = e.target.value;
        if (e.target.name == "In" || e.target.name == "Out" || e.target.name == "Duration") value = utility.validateTimeString(value);      
        setDataItem({ ...dataItem, [e.target.name]: value });      
        if (e.target.name == "Out") {
          var Out = utility.convertStringWithFramesToMilliseconds(value);
          var In = utility.convertStringWithFramesToMilliseconds(dataItem.In);      
          if (Out > In) {
            var duration = utility.convertMilisecondsToStringWithFrames(Out - In);
            setDataItem(old => { return { ...old, ["Duration"]: duration } })
          }      
        }
        if (e.target.name == "Duration") {
          console.log(value);
          var duration = utility.convertStringWithFramesToMilliseconds(value);
          var In = utility.convertStringWithFramesToMilliseconds(dataItem.In);
          var OutRes = utility.convertMilisecondsToStringWithFrames(In + duration);
          setDataItem(old => { return { ...old, ["Out"]: OutRes } })
        }
        if (e.target.name == "In") {
          var out = utility.convertStringWithFramesToMilliseconds(dataItem.Out);
          var inTime = utility.convertStringWithFramesToMilliseconds(value);
          if (out > inTime) {
            var duration = utility.convertMilisecondsToStringWithFrames(out - inTime);
            setDataItem(old => { return { ...old, ["Duration"]: duration } })
          }
        }
        if (e.target.name == "Tcm") {
          if (e.target.value.length == TIMECODEMARKERS.length) {
            setDataItem({ ...dataItem, Tcm: Tcm });
          }
          else {
            setDataItem({ ...dataItem, Tcm: e.target.value });
          }
        }        
      };

    const isValid = () => {
      if (dataItem.In == undefined || dataItem.In.includes("_")) {
        toast.error(`${lang.please_enter_valid_value_cut_tcin_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
  
      if (dataItem.Out == undefined || dataItem.Out.includes("_") || dataItem.In > dataItem.Out) {
        toast.error(`${lang.please_enter_valid_value_out_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
  
      if (dataItem.Duration == undefined || dataItem.Duration.includes("_") || dataItem.Duration.includes("00:00:00:00")) {
        toast.error(`${lang.invalid_duration_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
      if (Object.keys(dataItem.Tcm).length == 0) {
        toast.error(`${lang.please_select_time_code_marker_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      } 
        return true;
    }

    const handleSubmit = async () => {
        if (!isValid()) return;
        let finalData ={
          In: utility.convertStringWithFramesToMilliseconds(dataItem.In ?? "00:00:00:00"),
          Out : utility.convertStringWithFramesToMilliseconds(dataItem.Out ?? "00:00:00:00"),
          Duration : utility.convertStringWithFramesToMilliseconds(dataItem.Duration ?? "00:00:00:00") , 
          Tcm:  {ID : dataItem.Tcm.ID, Description:dataItem.Tcm.Description}
      }
    console.log(finalData)
    setGridData([...gridData, finalData]); 
    props?.setMarkerData([...gridData, finalData]);

        setDataItem(blankDataItem);
        setShowForm(false);
    }

    const deleteMarker = async (dataItem) => {
        let deleteData = gridData?.filter(x=> x?.Tcm.ID != dataItem?.Tcm.ID );
        setGridData(deleteData);
        props?.setMarkerData(deleteData);
    }

  
    const MyCommandCell = (props) => (
        <td>
            <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => deleteMarker(props.dataItem), () => { })} />
        </td>
    )

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", padding: "10px 20px 20px 20px" }}>
                        <RoundButton style={{ marginBottom: '5px' }} icon={'link'} title={lang.create_marker_button_tooltip} onClick={() => setShowForm(true)} />
                        <Grid data={gridData} style={{ height: "30vh" }}>
                            <GridColumn cell={MyCommandCell} width={"100px"} locked={true} />
                            <GridColumn field="Tcm.Description" title={lang.Tcm} editable={false} />
                            <GridColumn field="In" title={lang.in_column} editable={false} cell={TimeCell} />
                            <GridColumn field="Out" title={lang.out_column} editable={false} cell={TimeCell} />
                            <GridColumn field="Duration" title={lang.duration_column} editable={false} cell={TimeCell} />
                        </Grid>
                    </div>
                </div>
            </div>
            {showForm && <BossDialog
                title={lang.file_attachment_dialog_header}
                onClose={() => {setShowForm(false);  setDataItem(blankDataItem);}}
                width={"450px"}
            >
                <div className="m-1">
                    <EditPageHeader showTitle={false} onSubmit={handleSubmit} onCancel={() => {setShowForm(false);  setDataItem(blankDataItem);}} />
                    <div className="row">
                    <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">{lang.in_label} </label>
                            <InfoRoundButton message={lang.duration_label_tooltip}></InfoRoundButton>
                            <TimePickerWithFormat
                              name="In"
                              value={dataItem.In}
                              onChange={(e) => onChange(e)}
                              required
                            />
                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">{lang.out_label}</label>
                            <InfoRoundButton message={lang.duration_label_tooltip}></InfoRoundButton>
                            <TimePickerWithFormat
                              name="Out"
                              value={dataItem.Out}
                              onChange={(e) => onChange(e)}
                              required
                            />
                          </div>
                        </div>
                    </div>
                    <div className="row">
                    <div className="col-6 form-group">
                            <label htmlFor="TabView">{lang.time_code_markers} *</label>
                            <DropDownList
                                style={{ backgroundColor:"white" }}
                                data={TIMECODEMARKERS}
                                name="Tcm"
                                textField="Description"
                                dataItemKey="ID"
                                value={dataItem.Tcm}
                                onChange={(e) => onChange(e)}
                                validator={(value) => value ? "" : "Please select the value"}
                                />  
                        </div>
                    <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">{lang.marker_duration_label} *</label>
                            <InfoRoundButton message={lang.duration_label_tooltip}></InfoRoundButton>
                            <TimePickerWithFormat
                              name="Duration"
                              value={dataItem.Duration}
                              onChange={(e) => onChange(e)}
                              required
                            />
                          </div>
                        </div>
                        
                    </div>
                   
                </div>
            </BossDialog>}
        </>
    );
}

export default MarkerEditForm;