import React, { useEffect, useState } from 'react'
import { EditPageHeader } from '../../components/EditPageHeader'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import BossDialog from '../../components/BossDialog'
import { useTranslation } from '../../locale/useTranslation'
import * as API from "../../framework/API/api";
import { toast } from 'react-toastify'
import { ENTITYNAME } from '../../framework/constant/constant'

const AddTagsForm = (props) => {

    const lang = useTranslation();

    const [dataItem, setDataItem] = useState({
        Platform: props?.data?.Platform ?? {},
        AddTag: props?.data?.AddTag ?? "",
        index: props?.data?.index ?? null,
    })
    const [platform, setPlateform] = useState([])

    useEffect(() => {
        loadCombo()
    }, [])

    const loadCombo = async () => {
        let platformRes = await API.getDataLookup(ENTITYNAME.Platform, { query: ['Archive', '!=', true], sort: { Description: 1 } })
        setPlateform(platformRes.data)
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value })
    }

    const isValid = () => {
        if (dataItem.Platform == undefined || dataItem.Platform == "") {
            toast.error(lang.please_select_platform_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false
        }
        if (dataItem.AddTag == undefined || dataItem.AddTag == "") {
            toast.error(lang.please_select_ad_tag_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false
        }
        return true;
    }
    const handleSubmit = () => {
        if (!isValid()) return;
        let finalData = {
            Platform: dataItem.Platform && Object.keys(dataItem.Platform).length > 0 ? {
                _id: dataItem.Platform._id,
                Description: dataItem.Platform.Description
            } : {},
            AddTag: dataItem.AddTag,
            index: dataItem.index ?? null,
        }
        props?.handleTagData(finalData)
        props?.onClose()
    }

    return (
        <div>
            <BossDialog width={"30vw"} title={lang.ad_tag_label} onClose={props.onClose} >
                <EditPageHeader
                    showTitle={false}
                    onSubmit={handleSubmit}
                    onCancel={props.onClose}
                />
                <div className='row mt-2'>
                    <div className='col-12'>
                        <label>{lang.platform_label} *</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            className='form-control form-control-lm'
                            data={platform}
                            name="Platform"
                            textField="Description"
                            dataItemKey="_id"
                            value={dataItem.Platform}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className='row mt-2'>
                    <div className='col-12'>
                        <label>{lang.ad_tag_column} *</label>
                        <input type='text' className='form-control form-control-lm' name='AddTag' value={dataItem.AddTag} onChange={onChange}></input>
                    </div>
                </div>
            </BossDialog>
        </div>
    )
}

export default AddTagsForm
