/* eslint-disable */
import React, { useEffect, useState } from 'react';
import * as API from "../../framework/API/api";
import { ENTITYNAME } from '../../framework/constant/constant';
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import { EditPageHeader } from '../../components/EditPageHeader';
import { ImageBrowser } from '../../framework/forms/helpercomponents/selectFile/ImageBrowser';
import BossDialog from '../../components/BossDialog';

const EPGChannelEditForm = (props) => {

  const lang = useTranslation();
  let blankDataItem = {
    SID: props.item.SID ?? 0,
    Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
    ImageUrl: props.item.LogoUrl ?? '',
    Archive: props.item.Archive ?? false,
  }

  const [dataItem, setDataItem] = useState(blankDataItem);
  const [imageFiles, setImageFiles] = useState(new Set());

  const onChange = (e) => {
    if(e.target.name == 'Archive'){
        setDataItem({ ...dataItem, Archive: !dataItem.Archive });
    }else{
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  };

  const handleChange = (e) => {
    if(e.target.name == 'ImageUrl' && e.target?.files){
      if(e.target.files != undefined){
        setImageFiles(e.target.files[0]);
      }else{
        setImageFiles(new Set());
      }
    }
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  }

  const isValid = () => {
    if(!dataItem.Description || dataItem.Description == ''){
      toast.error(lang.please_enter_description_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if(dataItem.ImageUrl == undefined || Object.keys(dataItem.ImageUrl).length == 0){
      toast.error(lang.please_enter_image_url_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const onSaveData = async () => {

    if(!isValid())return;
    let imageUrl =dataItem.ImageUrl
    if(imageFiles.size > 0){
      let resUrl = await API.uploadImage(imageFiles);
      if(!resUrl.success){
       toast.error(`${lang.upload_fail_error_message}`, {
         position: toast.POSITION.TOP_RIGHT
       });
       return;
      }
      imageUrl=resUrl.data;
     dataItem.ImageUrl = imageUrl;
   }

     var data = {
        SID: dataItem.SID,
        Description: dataItem.Description,
        LogoUrl: imageUrl,
        Archive: dataItem.Archive,
     }
     console.log(data);
     var res = await API.saveData(ENTITYNAME.EPGChannel , data);
     if(res.success){
        props.cancelEdit();
        props.refresh();
        utility.deleteLocalStorageItem(ENTITYNAME.EPGChannel);
        return;
    }else{
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
     
 };

    const handleImageSelect = (dataList) => {
        setDataItem({ ...dataItem, ImageUrl: dataList[0].ImageUrl });
    }

  return (
    <BossDialog
      title={props.item.SID > 0 ? dataItem.Description : `${lang.create_channel_dialog_header}`}
      onClose={props.cancelEdit}
      width={"35vw"}
    >
      <div className="row">
        <div className=" col-12">
          <EditPageHeader onSubmit={onSaveData} onCancel={props.cancelEdit} showTitle={false} />
          <div className="row mt-2" >
            <div className='col-12'>
              <div className="form-group">
                <label htmlFor="">{lang.description_label} *</label>
                <input name="Description" type="text" className="form-control form-control-sm" value={dataItem.Description} onChange={(e) => onChange(e)} />
              </div>
            </div>
          </div>
          <div className="row mt-2">
            <div className="col-12">
                <label>{lang.logo_url_label} *</label>
                    <ImageBrowser name = {'ImageUrl'} value={dataItem.ImageUrl} onChange={handleChange}  setSelectedImage= {handleImageSelect}/>
                </div>
          </div>
          <div className="row mt-3">
            <div className="col-6">
              <input type="checkbox" name={"Archive"} checked={dataItem.Archive} onChange={onChange} /> 
              <label className='pl-1' >{lang.archive}</label>
            </div>
          </div>
        </div>
      </div>
    </BossDialog>
  )
}

export default EPGChannelEditForm;