import { ConfirmDeleteAlert } from "../../../../../ConfirmAlert";
import { PlaylistHelper } from "../../../../../modules/DigitalSign/Playlist/PaylistHelper";
import { BOOKINGSTATUS, ENTITYNAME, LOCALSTORAGE_KEY, MEDIACATEGORIES, MODULE, ROLETYPE } from "../../../../constant/constant";
import { utility } from "../../../../utility/utilityProvider";

export const collectionMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;
  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) }, // pi-trash
        {
          label: 'Create From', icon: 'pi pi-fw pi-copy', command: () => {
            //alert(props.openNewEditForm);
            props.enterEdit({ ...props.dataItem, SID: props.openNewEditForm ? props.dataItem.SID : 0, _id: props.openNewEditForm ? props.dataItem._id : null, copy: true });
          }
        },
        props?.module && { label: 'Show logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate({ 
          Module: props?.module,
          path: ('/home/AuditLog' + "?module=" + props?.module + "&_id="+ props?.dataItem?._id + "&title=" + (props?.dataItem?.Title != undefined ? props?.dataItem?.Title : props?.dataItem?.Description != undefined ? props?.dataItem?.Description : props?.dataItem?.Name) )
        }) }
      ].filter(Boolean)
    }
  ]
  return menu;
}

export const collectionQuickEditMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        { label: 'Quick Edit', icon: 'fa-fw fa-solid fa-pen-to-square', command: () => props.enterEdit({ ...props.dataItem, quickEdit: true }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
        { label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => ConfirmDeleteAlert(() => props.onDelete(props.dataItem), () => { }) },
      ]
    }
  ]
  return menu;
}

export const seriesMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false, history: false, prevPath: `&index=${props.dataIndex}` }) },
        { label: 'Quick Edit', icon: 'fa-fw fa-solid fa-pen-to-square', command: () => props.enterEdit({ ...props.dataItem, quickEdit: true, history: false, prevPath: `&index=${props.dataIndex}` }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
        { label: 'History', icon: "pi pi-fw pi-history", command: () => props.history({ ...props.dataItem, history: true }) },
        { label: 'Deal Rights', icon: "pi pi-fw pi-play", command: () => props.publishingDealRights(props.dataItem) },
        { label: 'Show Series', icon: "pi pi-fw pi-eye", command: () => props.showSeasons(props.dataItem) },
        { label: 'Show logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate({ 
          Module: MODULE.MEDIA_EPISODE_SERIES,
          path: ('/home/AuditLog' + "?module=" + MODULE.MEDIA_EPISODE_SERIES +"&_id="+ props.dataItem._id + "&title=" + props.dataItem.Title)
        }) },
      ]
    }
  ]
  return menu;
}

export const collectionQuickEditWithoutDeleteMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;
  
  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false, history: false, prevPath: `&index=${props.dataIndex}` }) },
        { label: 'Quick Edit', icon: 'fa-fw fa-solid fa-pen-to-square', command: () => props.enterEdit({ ...props.dataItem, quickEdit: true, history: false, prevPath: `&index=${props.dataIndex}` }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
        { label: 'History', icon: "pi pi-fw pi-history", command: () => props.history({ ...props.dataItem, history: true }) },
        { label: 'Fast Segmentation', icon: "pi pi-fw pi-bolt", command: () => props.onFastSegmentation({ ...props.dataItem}) },
        props?.module == MODULE.MEDIA_EPISODE_PROGRAM && { label: 'Deal Rights', icon: "pi pi-fw pi-play", command: () => props.publishingDealRights(props.dataItem) },
        { label: 'Show logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate({ 
          Module: props?.module,
          path: ('/home/AuditLog' + "?module=" + props?.module + "&_id="+ props.dataItem._id + "&title=" + props.dataItem.Title)
        }) },
      ].filter(Boolean)
    }
  ]
  return menu;
}

export const collectionCampaignMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
        { label: 'Create From', icon: 'pi pi-fw pi-copy', command: () => props.enterEdit({ ...props.dataItem, copy: true }) },
        { label: 'Show Spots', icon: 'pi pi-fw pi-server', command: () => props.toNavigate({ ...props.dataItem, path: '/home/ShowSpots' }) },
        { label: 'Show Campaign logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate({ 
          Module: MODULE.CAMPAIGN,
          path: ('/home/AuditLog' + "?module=" + MODULE.CAMPAIGN +"&_id="+ props.dataItem._id + "&title=" + props.dataItem.Title)
        }) },
        { label: 'Show Booking logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate(
          {
            Module: MODULE.BOOKING,
            path: ('/home/AuditLog' + "?module=" + MODULE.BOOKING +"&_id="+ props.dataItem._id + "&title=" + props.dataItem.Title)
          }) },
        { label: 'Show Spots logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate(
          {
            Module: MODULE.SHOW_SPOTS,
            path: ('/home/AuditLog' + "?module=" + MODULE.SHOW_SPOTS +"&_id="+ props.dataItem._id + "&title=" + props.dataItem.Title)
          }
        ) },
      ]
    }
  ]
  return menu;
}

export const schedulerDeleteModel = (props) => {

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => props.onDelete(props.dataItem) },
      ]
    }
  ]
  return menu;
}

export const collectionMediaMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false, prevPath: `&index=${props.dataIndex}` }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
        { label: 'Create From', icon: 'pi pi-fw pi-copy', command: () => props.enterEdit({ ...props.dataItem, copy: true, prevPath: `&index=${props.dataIndex}` }) }, 
        { label: 'Deal Rights', icon: "pi pi-fw pi-play", command: () => props.publishingDealRights(props.dataItem) },
        { label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => ConfirmDeleteAlert(() => props.onDelete(props.dataItem), () => { }) },
        props?.module && { label: 'Show logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate({ 
          Module: props?.module,
          path: ('/home/AuditLog' + "?module=" + props?.module + "&_id="+ props?.dataItem?._id + "&title=" + (props?.dataItem?.Title != undefined ? props?.dataItem?.Title : props?.dataItem?.Description != undefined ? props?.dataItem?.Description : props?.dataItem?.Name) )
        }) }
      ].filter(Boolean)
    }
  ]
  return menu;
}

export const collectionJobsMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;

  const menu = [
    {
      label: 'Options',
      items: [
        // { label: 'Show', icon: 'pi pi-fw pi-eye', command: () => props.toNavigate({...props.dataItem, path :`/home/Jobs/${props.dataItem.SID}`,attachMedia:false})},
        { label: 'Show', icon: 'pi pi-fw pi-eye', command: () => props.enterEdit({ ...props.dataItem, copy: false, attachMedia: false }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
        { label: 'Attach Media', icon: 'pi pi-fw pi-paperclip', command: () => props.enterEdit({ ...props.dataItem, copy: false, attachMedia: true }) },

      ]
    }
  ]
  return menu;
}
export const collectionUserMenuModel = (props) => {

  let currentUser = utility.getValue(LOCALSTORAGE_KEY.userData);
  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        { label: 'Create From', icon: 'fa-fw fa-solid fa-pen-to-square', command: () => props.enterEdit({ ...props.dataItem, copy: true }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
        { label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => ConfirmDeleteAlert(() => props.onDelete(props.dataItem), () => { }) },
      ]
    }
  ]

  if (currentUser.RoleType != undefined && (currentUser.RoleType.ID == ROLETYPE.SuperAdmin || currentUser.RoleType.ID == ROLETYPE.Admin)) {
    menu[0].items.push({ label: 'Reset Password', icon: "fa-solid fa-wrench", command: () => props.onResetPassword(props.dataItem) });
    menu[0].items.push({ label: 'Kill User Session', icon: "fa-solid fa-power-off", command: () => props.onKillUserSession(props.dataItem) });
  }
  return menu;
}
export const ChannelMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;
  let isDefault = props?.dataItem?.IsDefault ? props?.dataItem?.IsDefault : false;

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
        { label: 'Set As Default', icon: 'pi pi-fw pi-check-circle', command: () => props.setAsDefault(props.dataItem) },
      ]
    }
  ]
  return menu;
}

export const contractTitleMenuModel = (props) => {

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.onEditContractTitle({ ...props.dataItem }) },
        { label: 'Edit Black Out Date', icon: 'pi pi-fw pi-stop-circle', command: () => props.onEditBlackOutDate({ ...props.dataItem }) },
        { label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => ConfirmDeleteAlert(() => props.onRemoveContractTitle(props.dataItem), () => { }) },
      ]
    }
  ]

  return menu;
}

export const seasonMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false, history: false, prevPath: `&index=${props.dataIndex}` }) },
        { label: 'Quick Edit', icon: 'fa-fw fa-solid fa-pen-to-square', command: () => props.enterEdit({ ...props.dataItem, quickEdit: true, history: false }), prevPath: `&index=${props.dataIndex}` },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
        { label: 'History', icon: "pi pi-fw pi-history", command: () => props.history({ ...props.dataItem, history: true }) },
        { label: 'Publish to Ott', icon: "pi pi-fw pi-check-square", command: () => props.publish(props.dataItem._id) },
        { label: 'Deal Rights', icon: "pi pi-fw pi-play", command: () => props.publishingDealRights(props.dataItem) },
        { label: 'Show Episodes', icon: "pi pi-fw pi-eye", command: () => props.showEpisodes(props.dataItem) },
        { label: 'Show logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate({ 
          Module: MODULE.MEDIA_EPISODE_SEASON,
          path: ('/home/AuditLog' + "?module=" + MODULE.MEDIA_EPISODE_SEASON +"&_id="+ props.dataItem._id + "&title=" + props.dataItem.Title)
        }) },
      ]
    }
  ]
  return menu;
}

export const OttMenuModel = (props) => {

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        { label: 'Show logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate({ 
          Module: MODULE.OTTMENU,
          path: ('/home/AuditLog' + "?module=" + MODULE.OTTMENU + "&_id="+ props.dataItem._id + "&title=" + props.dataItem.Name)
        }) },
      ]
    }
  ]
  return menu;
}

export const collectionMenuModelAsDefualt = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;
  let showLogs = props.showLogs ?? false;
  let isDefault = props?.dataItem?.IsDefault ? props?.dataItem?.IsDefault : false;
  
  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) }, // pi-trash
        {
          label: 'Create From', icon: 'pi pi-fw pi-copy', command: () => {
            //alert(props.openNewEditForm);
            props.enterEdit({ ...props.dataItem, SID: props.openNewEditForm ? props.dataItem.SID : 0, _id: props.openNewEditForm ? props.dataItem._id : null, copy: true });
          }
        },
        { label: 'Set As Default', icon: 'pi pi-fw pi-check-circle', command: () => props.setAsDefault(props.dataItem) },
        showLogs && { label: 'Show logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate({ 
          Module: props?.module,
          path: ('/home/AuditLog' + "?module=" + props?.module + "&_id="+ props?.dataItem?._id + "&title=" + (props?.dataItem?.Title != undefined ? props?.dataItem?.Title : props?.dataItem?.Description != undefined ? props?.dataItem?.Description : props?.dataItem?.Name) )
        }) }
      ].filter(Boolean)
    }
  ]

  return menu;
}

export const collectionMMCQuickEditWithoutDeleteMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false, history: false }) },
        { label: 'Quick Edit', icon: 'fa-fw fa-solid fa-pen-to-square', command: () => props.enterEdit({ ...props.dataItem, quickEdit: true, history: false }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
        { label: 'History', icon: "pi pi-fw pi-history", command: () => props.history({ ...props.dataItem, history: true }) },
        { label: 'MEC', icon: "pi pi-fw pi-file-excel ", command: () => props.exportMECXML({ ...props.dataItem, exportMEC: true, history: false }) },
        { label: 'MMC', icon: "pi pi-fw pi-file-excel ", command: () => props.exportMMCXML({ ...props.dataItem, mmcAndMec: true, history: false }) },
        { label: 'Ott Package Delivery Schedule', icon: "pi pi-fw pi-flag-fill ", command: () => props.ottPackageDeliverySchedule({ ...props.dataItem, mmcAndMec: false, history: false }) },
      ]
    }
  ]
  return menu;
}

export const collectionPageMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) }, // pi-trash
        {
          label: 'Create From', icon: 'pi pi-fw pi-copy', command: () => {
            //alert(props.openNewEditForm);
            props.enterEdit({ ...props.dataItem, SID: props.openNewEditForm ? props.dataItem.SID : 0, _id: props.openNewEditForm ? props.dataItem._id : null, copy: true });
          }
        },
        { label: 'Tv Preview', icon: 'fa-fw fa-solid fa-television', command: () => props.preview({ ...props.dataItem, isMobile: false }) }, // pi-trash
        { label: 'Mobile Preview', icon: 'pi pi-fw pi-mobile', command: () => props.preview({ ...props.dataItem, isMobile: true }) }, // pi-trash
        { label: 'Show Rails', icon: 'fa-fw fa-solid fa-eye', command: () => props.showRails(props.dataItem) },
        { label: 'Attach Rails', icon: 'pi pi-fw pi-link', command: () => props.attachRails(props.dataItem) },
        { label: 'Show logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate({ 
          Module: MODULE.PAGE,
          path: ('/home/AuditLog' + "?module=" + MODULE.PAGE + "&_id="+ props.dataItem._id + "&title=" + props.dataItem.Name)
        }) },
      ]
    }
  ]
  return menu;
}

export const collectionWithoutCommandMenuModel = (props) => {

  const menu = [
    {
      label: 'Options Not Available',
    }
  ]

  return menu;
}

export const collectionPlanningLockScheduleMenuModel = (props) => {

  const menu = [
    { label: 'Force Unlock', icon: 'pi pi-fw pi-lock-open', command: () => props.onForceUnlock(props.dataItem) },
  ]

  return menu;
}

export const collectionAnnouncementReconcileMenuModel = (props) => {

  const menu = [
    { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem }) },
    { label: `${props.dataItem.BookingStatusSID._id == BOOKINGSTATUS.None ? 'Reconcile' : 'Not Reconcile'}`, icon: 'pi pi-fw pi-sync', command: () => props.reconcile({ ...props.dataItem }) },
  ]

  return menu;
}

export const exportTemplateMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
      ]
    }
  ]
  return menu;
}

export const collectionRailsMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) }, // pi-trash
        {
          label: 'Create From', icon: 'pi pi-fw pi-copy', command: () => {
            //alert(props.openNewEditForm);
            props.enterEdit({ ...props.dataItem, SID: props.openNewEditForm ? props.dataItem.SID : 0, _id: props.openNewEditForm ? props.dataItem._id : null, copy: true });
          }
        },
        { label: 'Show Campaign', icon: 'pi pi-fw pi-eye', command: () => props.showOttCampaign(props.dataItem) },
        { label: 'Attach Campaign', icon: 'pi pi-fw pi-link', command: () => props.attachCampaign(props.dataItem) },
        { label: 'Show logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate({ 
          Module: MODULE.RAILS,
          path: ('/home/AuditLog' + "?module=" + MODULE.RAILS + "&_id="+ props.dataItem._id + "&title=" + props.dataItem.Title)
        }) },
      ]
    }
  ]
  return menu;
}

export const collectionSegmentMenuModel = (props) => {

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.onEdit(props.dataItem) },
        { label: 'Remove Segment', icon: 'pi pi-fw pi-trash', command: () => ConfirmDeleteAlert(() => props.removeSegment(props.dataItem), () => { }) },
        { label: 'Add Segement QC', icon: 'pi pi-fw pi-sitemap', command: () => props.onAddSegmentQC(props.dataItem) },
        { label: 'History', icon: 'pi pi-fw pi-history', command: () => props.history(props.dataItem) },
      ]
    }
  ]
  return menu;
}

export const collectionMovieMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false, history: false }) },
        { label: 'Quick Edit', icon: 'fa-fw fa-solid fa-pen-to-square', command: () => props.enterEdit({ ...props.dataItem, quickEdit: true, history: false }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
        { label: 'History', icon: "pi pi-fw pi-history", command: () => props.history({ ...props.dataItem, history: true }) },
        { label: 'MEC', icon: "pi pi-fw pi-file-excel ", command: () => props.exportMECXML({ ...props.dataItem, exportMEC: true, history: false }) },
        { label: 'MMC', icon: "pi pi-fw pi-file-excel ", command: () => props.exportMMCXML({ ...props.dataItem, mmcAndMec: true, history: false }) },
        { label: 'Ott Package Delivery Schedule', icon: "pi pi-fw pi-flag-fill ", command: () => props.ottPackageDeliverySchedule({ ...props.dataItem, mmcAndMec: false, history: false }) },
        { label: 'Deal Rights', icon: "pi pi-fw pi-play", command: () => props.publishingDealRights(props.dataItem) },
        { label: 'Show logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate({
          Module: MODULE.MEDIA_EPISODE_MOVIE,
          path: ('/home/AuditLog' + "?module=" + MODULE.MEDIA_EPISODE_MOVIE + "&_id=" + props.dataItem._id + "&title=" + props.dataItem.Title)
        }) },
        { label: 'Planning logs', icon: 'pi pi-fw pi-external-link', command: () => props.planningLogs(props.dataItem) },
        { label: 'Segmentation Export', icon: 'pi pi-fw pi-file-excel', command: () => props.onExportSegmentationData({ ...props.dataItem, history: false }) },
       
      ]
    }
  ]
  return menu;
}

export const collectionBreakMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
        { label: 'Create From', icon: 'pi pi-fw pi-copy', command: () => props.enterEdit({ ...props.dataItem, copy: true }) },
        { label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => ConfirmDeleteAlert(() => props.onDelete(props.dataItem), () => { }) },
        { label: 'Show logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate({ 
          Module: MODULE.MEDIA_EPISODE_BREAK,
          path: ('/home/AuditLog' + "?module=" + MODULE.MEDIA_EPISODE_BREAK + "&_id="+ props.dataItem._id + "&title=" + props.dataItem.Title)
        }) },
      ]
    }
  ]
  return menu;
}

export const collectionDummyMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
        { label: 'Create From', icon: 'pi pi-fw pi-copy', command: () => props.enterEdit({ ...props.dataItem, copy: true }) },
        { label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => ConfirmDeleteAlert(() => props.onDelete(props.dataItem), () => { }) },
        { label: 'Show logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate({ 
          Module: MODULE.MEDIA_EPISODE_DUMMY,
          path: ('/home/AuditLog' + "?module=" + MODULE.MEDIA_EPISODE_DUMMY + "&_id="+ props.dataItem._id + "&title=" + props.dataItem.Title)
        }) },
      ]
    }
  ]
  return menu;
}

export const collectionMediaEpisodeGroupMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
        props?.module && { label: 'Show logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate({ 
          Module: props?.module,
          path: ('/home/AuditLog' + "?module=" + props?.module + "&_id="+ props?.dataItem?._id + "&title=" + (props?.dataItem?.Title != undefined ? props?.dataItem?.Title : props?.dataItem?.Description != undefined ? props?.dataItem?.Description : props?.dataItem?.Name) )
        }) }
      ].filter(Boolean)
    }
  ]
  return menu;
}

export const collectionMediaInterstitialModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
        { label: 'Create From', icon: 'pi pi-fw pi-copy', command: () => props.enterEdit({ ...props.dataItem, copy: true }) }, 
        { label: 'Show Schedule', icon: 'pi pi-fw pi-external-link', command: () => props.onShowSchedule(props.dataItem) }, 
        props?.module && { label: 'Show logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate({ 
          Module: props?.module,
          path: ('/home/AuditLog' + "?module=" + props?.module + "&_id="+ props?.dataItem?._id + "&title=" + (props?.dataItem?.Title != undefined ? props?.dataItem?.Title : props?.dataItem?.Description != undefined ? props?.dataItem?.Description : props?.dataItem?.Name) )
        }) },
        props?.module == MODULE.MEDIA_EPISODE_COMMERCIAL && { label: 'Preview', icon: "pi pi-fw pi-eye", command: () => props.onCommercialPreview(props.dataItem) },
      ].filter(Boolean)
    }
  ]
  return menu;
}

export const collectionProgramMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;
  
  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false, history: false }) },
        { label: 'Quick Edit', icon: 'fa-fw fa-solid fa-pen-to-square', command: () => props.enterEdit({ ...props.dataItem, quickEdit: true, history: false }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
        { label: 'History', icon: "pi pi-fw pi-history", command: () => props.history({ ...props.dataItem, history: true }) },
        { label: 'Fast Segmentation', icon: "pi pi-fw pi-bolt", command: () => props.onFastSegmentation({ ...props.dataItem}) },
        props?.module == MODULE.MEDIA_EPISODE_PROGRAM && { label: 'Deal Rights', icon: "pi pi-fw pi-play", command: () => props.publishingDealRights(props.dataItem) },
        { label: 'Show logs', icon: 'pi pi-fw pi-external-link', command: () => props.toNavigate({ 
          Module: props?.module,
          path: ('/home/AuditLog' + "?module=" + props?.module + "&_id="+ props.dataItem._id + "&title=" + props.dataItem.Title)
        }) },
        { label: 'Planning logs', icon: 'pi pi-fw pi-external-link', command: () => props.planningLogs(props.dataItem) },
        { label: 'Segmentation Export', icon: 'pi pi-fw pi-file-excel', command: () => props.onExportSegmentationData({ ...props.dataItem, history: false }) },
       
      ].filter(Boolean)
    }
  ]
  return menu;
}

export const collectionDigitalSignScreenMenuModel = (props) => {
  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        { label: 'Attach group', icon: 'pi pi-fw pi-link', command: () => props.onAttachGroup(props.dataItem) },
        { label: 'New group from screen', icon: 'pi pi-fw pi-link', command: () => props.onNewGroupCreate(props.dataItem)},
        { label: 'Show last play item', icon: 'pi pi-fw pi-external-link', command: () => props.onLastPlayItem(props.dataItem)},
        { label: 'Show Asrun Report' , icon: 'pi pi-fw pi-external-link', command: () => props.onAsrunReport(props.dataItem)} ,
        { label: 'Restart App' , icon: 'pi pi-fw pi-power-off', command: () => props.onRestart(props.dataItem)} ,
        { label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => ConfirmDeleteAlert(() => props.onDelete(props.dataItem), () => { }) },
      ]
    }
  ]
  return menu;
}

export const collectionPlaylistMenuModel = (props) => {

  let playlistMenu = PlaylistHelper.getPlaylistMenu(props);

  const menu = [
    {
      label: 'Options',
      items: playlistMenu.filter(Boolean)
    }
  ]
  return menu;
}

export const collectionMenuModelWithDelete = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;

  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        {
          label: 'Create From', icon: 'pi pi-fw pi-copy', command: () => {
            //alert(props.openNewEditForm);
            props.enterEdit({ ...props.dataItem, SID: props.openNewEditForm ? props.dataItem.SID : 0, _id: props.openNewEditForm ? props.dataItem._id : null, copy: true });
          }
        },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) },
        { label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => ConfirmDeleteAlert(() => props.onDelete(props.dataItem), () => { }) },
      ]
    }
  ]
  return menu;
}

export const collectionCompanyMenuModel = (props) => {
  
  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        { label: 'Delete', icon: 'pi pi-fw pi-trash', command: () => ConfirmDeleteAlert(() => props.onDelete(props.dataItem), () => { }) },
      ]
    }
  ]
  return menu;
}

export const collectionJobScheduleMenuModel = (props) => {

  let archive = props.dataItem == undefined ? false : props.dataItem.Archive ?? false;
  const menu = [
    {
      label: 'Options',
      items: [
        { label: 'Edit', icon: 'pi pi-fw pi-pencil', command: () => props.enterEdit({ ...props.dataItem, copy: false }) },
        { label: archive ? 'Un-Archive' : 'Archive', icon: 'pi pi-fw pi-inbox', command: () => props.onArchive(props.dataItem) }, // pi-trash
        { label: 'Create From', icon: 'pi pi-fw pi-copy', command: () => {
            props.enterEdit({ ...props.dataItem, SID: props.openNewEditForm ? props.dataItem.SID : 0, _id: props.openNewEditForm ? props.dataItem._id : null, copy: true });
          }
        },
        { label: props.dataItem.Disable ? "Run": 'Stop', icon: props.dataItem.Disable ? 'pi pi-fw pi-play' : 'pi pi-fw pi-stop-circle', command: () => props.handleStartStopJob({ ...props.dataItem }) },
        { label: "Show Jobs", icon: 'pi pi-fw pi-bars', command: () => props.showJobs({ ...props.dataItem }) },
        { label: "Test Run Job", icon: 'pi pi-fw pi-cog', command: () => props.showTestJobPopup({ ...props.dataItem }) },
      ].filter(Boolean)
    }
  ]
  return menu;
}