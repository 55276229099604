/* eslint-disable */
import { useRef, useState } from "react";
import ManageContentHeader from "./manageContentHeader";
import ManageContentFoooter from "./manageContentFooter";
import ManageContentCollection from "./manageContentCollection";

const ManageContent = () => {

    const footerRef = useRef();
    const collectionRef = useRef();

    return (<>
        <ManageContentHeader onLoad={(item)=> collectionRef.current.handleLoad(item)} setSearchText={(text) => collectionRef.current.setSearchText(text)} setFilterTemplate={(data)=> collectionRef.current.setFilterTemplate(data)} setViewMode={(view)=> collectionRef.current.setViewMode(view)} onExport={(mediaCategory)=> collectionRef.current.onExport(mediaCategory)} />
        <div style={{ height: "75vh" }}>
            <ManageContentCollection ref={collectionRef} setMessage={(msg)=>footerRef.current?.setMessage(msg)} setSelectedItemCount={(count)=>footerRef.current?.setSelectedItemCount(count)} />
        </div>
        <ManageContentFoooter ref={footerRef} />
    </>)
}

export default ManageContent;