/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { ENTITYNAME,IMPORTTYPE,FILEURL} from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import moment from "moment";
import { utility } from "../../framework/utility/utilityProvider";


const ImportAsRun = (props) => {

    const {importType} = props;
   //console.log(props.importType);
    const [dataItem, setDataItem] = useState({Channel : {} , fileupload : '',ReplacePlanning : false});
    const [files,setFiles] = useState([]);
    const [statusFiles,setStatusFiles] = useState([]);
    const fileRef = useRef(null);

    const [channel, setChannel] = useState([]);
    const lang=useTranslation();

    useEffect(() => { loadcombo(); }, []);

    const loadcombo = async () => {           
        var channelRes = await API.getDataLookup(ENTITYNAME.Channel);
        setChannel(channelRes.data);
        setDataItem({...dataItem , Channel : utility.getDefaultItem(channelRes.data)})
    }   

    const onChange = (e) => {    

        setDataItem({ ...dataItem, [e.target.name]: e.target.value });

        if(e.target.name == "fileupload"){
            //add validation for wrong file
            var extn = e.target.files[0].name.slice(e.target.files[0].name.length - 4 ,e.target.files[0].name.length)
            if(extn == '.csv' || extn == '.xlsx' || extn == '.txt'){
                setFiles((old) => [...old,{file : e.target.files[0]}]);
                updateAsRunFileStatus(e.target.files[0].name,`${lang.read_to_upload_import_message}`,"");
            }else{
                toast.error(lang.please_select_csv_file_error_message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }

        if(e.target.name == 'ReplacePlanning'){
            setDataItem({ ...dataItem, ReplacePlanning : !dataItem.ReplacePlanning });
        }
        
    }

    const updateAsRunFileStatus = (fileName ,status,message) => {

        setStatusFiles((old)=> [...old, {fileName:fileName, Status : status, Message : message}])

    }

    const IsValid = ()=>
    {
        var isValid = true;

        if(dataItem.Channel.SID == undefined) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
              });
            isValid = false;
            return isValid;
        }

        if(files.length == 0) {
            toast.error(`${lang.please_select_file_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
              });
            isValid = false;
            return isValid;
        }

        return isValid;

    }

    const handleDownload = ()=>
    {
        var filePath = FILEURL.BASEURL + 'downloadTemplate/'+ (importType == IMPORTTYPE.Planning ? 'ImportPlanningTemplate.csv' : 'ImportAsRunTemplate.csv') ;    
        window.open(filePath);
        // toast.info(`${lang.downlaod_file_error_message}`, {
        //     position: toast.POSITION.TOP_RIGHT
        //   });
    }

    const handleSubmit = async() => {
        setStatusFiles([]);

     // VALIDATION
     if(!IsValid()){
        return;
     }


     switch(importType)
     {
        case IMPORTTYPE.AsRun : 
        ImportAsRun();
        break;
        case IMPORTTYPE.Planning :  
        // ImportPlanning();
        readFileAndDisplay();
        break;

        default:
            toast.error(`${lang.no_import_type_defined_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
              });
     }

       setFiles([]);
       
       fileRef.current.value = null;

    }

    const ImportAsRun = async ()=>
    {
        var localFiles = [...files];
        //to import list of files
        localFiles.map(async(obj,index) => {
            var fileName = obj.file.name;
            var resCSV = await API.readAsRunData(obj.file,dataItem.Channel.SID);
            console.log(resCSV);
    

            // saving imported asrun DTO
            if(resCSV.success){
                updateAsRunFileStatus(fileName,`${lang.upload_Successfully_import_success_message}`,resCSV.message)
                var responseSave = await API.saveAsRunData(resCSV.data);

                console.log(responseSave);

                if(responseSave.success) {
                    updateAsRunFileStatus(fileName,`${lang.asrun_Save_Successfully_import_success_message}`,responseSave.message)
                }else{
                    updateAsRunFileStatus(fileName,`${lang.asrun_save_failed_import_error_message}`,responseSave.message)
                }
            }else{
                updateAsRunFileStatus(fileName,`${lang.update_failed_import_error_message}`,resCSV.message)
            }

        })
    }

    //to show the readed file on UI then import button for click
    const readFileAndDisplay = async() => {

           
        var res = await API.readFile(files[0].file);
     
        var planningItems = [];//FOR VALID ROW
        var inValidRows = [];

        if(res.success){

            //CHECK IF THE READ DATA HAS TIME,DATE and HOUSENUMBER COLUMNS
            if(!res.data.columns.includes('Date')){
                toast.error(lang.date_column_is_not_present_in_this_csv_file_label, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            if(!res.data.columns.includes('Time')){
                toast.error(lang.time_column_is_not_present_in_this_csv_file_label, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }

            if(!res.data.columns.includes('HouseNumber')){
                toast.error(lang.house_number_column_is_not_present_in_this_csv_file_label, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return;
            }


            // ADDING ONLY VALID ROWS
            res.data.data.forEach(item => {           

                //check valid date format
                if(item.Date.length == 0 && !moment(item.Date, 'YYYY-MM-DD', true).isValid()){
                    
                    inValidRows.push(item)
                    
                    //checking valid time format
                }else if(false){     //TODO //item.Time.length == 0 && !moment(item.Time, 'hh:mm:ss', true).isValid()
                    
                    console.log(item.Time);
                    inValidRows.push(item)

                //checking if assetId missing
                }else if(item.HouseNumber.length == 0){

                    inValidRows.push(item)

                }else{
                    var scheduleDate = new Date(item.Date).getTime();
                    var slotDateTime = new Date(`${item.Date} ${item.Time}`).getTime();
                    var assetId = item.HouseNumber;

                    var planning = {
                        Channel : dataItem.Channel,
                        ScheduleDate : scheduleDate,
                        SlotDateTime : slotDateTime,
                        AssetId : assetId
                    }

                    planningItems.push(planning); 
                }                      
                
            });
            await ImportPlanning(planningItems);
            console.log(inValidRows);
            console.log(planningItems);

        }else {
            updateAsRunFileStatus(files[0].name,`${lang.import_planning_failed_import_message}`,planningResponse.message);
            setDataItem({...dataItem,ReplacePlanning : false})
        }

    }

    const ImportPlanning = async (planningData) => {

        //boolean to replicate data 
        var planningResponse = await API.importPlanning(planningData,dataItem.ReplacePlanning);

        if(planningResponse.MediaNotFound.length > 0){
            updateAsRunFileStatus(files[0].file.name,`${lang.assest_not_found_import_message}`, planningResponse.MediaNotFound.map(elem=>elem.AssetId).join(","));
        }

        updateAsRunFileStatus(files[0].file.name,planningResponse.message,planningResponse.message);
        setDataItem({...dataItem,ReplacePlanning : false})
        // updateAsRunFileStatus(files[0].file.name,planningResponse.message,planningResponse.message); 
                    
    }

      const borderWithTitle = {
        position:"relative",
        fontWeight:"500",
        fontSize:"12px",
        top:"-0.8em",
        marginLeft:"0.8em",
        display:"inline",
        backgroundColor: "white" 
      };

    return <>
        <div className="row m-1">
            <div className="col-12">
                <div className="row">                   
                    <div className="col-3">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.channel_label}</label>
                                <DropDownList
                                style={{
                                backgroundColor:"white",
                                }}
                                data={channel}
                                name="Channel"
                                textField="FullChannelName"
                                dataItemKey="_id"
                                value={dataItem.Channel}
                                onChange={onChange}
                                validator={(value) => value ? "" : "Please select the value"}
                            />
                        </div>                                    
                    </div>
                    <div className="col-3" style={{marginTop:"20px"} }>
                        <label className="custom-file-updated-label" style={{margin:"0px",height : "32px"}}>
                        <input ref={fileRef}  type="file" 
                            accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel, .csv, text/plain " 
                            name="fileupload" className="custom-file-updated-input" hidden="hidden" id="fileupload" onChange={onChange} /> 
                        {lang.add_file_label}</label>
                        <ActionButton icon={"fa-solid fa-upload"} style = {{padding: "5px 12px"}} title={lang.file_upload_tooltip} onClick = {handleSubmit} />
                        {importType == IMPORTTYPE.Planning && <ActionButton icon={"fa-download"} style = {{padding: "5px 12px"}} title={lang.download_template_tooltip} onClick = {handleDownload}  />    }                                                                
                    </div> 
                    {importType == IMPORTTYPE.Planning && <div className="col-3" style={{margin : '27px 0px'}}>
                        <div>
                            <input 
                                style={{ backgroundColor:"white", width:'30px' }}
                                type={'checkbox'} 
                                name="ReplacePlanning"
                                value={dataItem.ReplacePlanning} 
                                checked={dataItem.ReplacePlanning}
                                onChange={onChange}
                            />
                            <label htmlFor="TabView">{'Replace Existing Planning'}</label>
                        </div>                                    
                    </div>}                                          
                </div>                
            </div>
            <div style={{ margin:"10px 10px 0px 10px",height: "37vh"}}>
                <div style={borderWithTitle}>&nbsp;{lang.import_file_label} &nbsp;
                </div>
                <Grid data={files} style={{ margin:"-05px 10px 10px 10px",
                        height: "34vh", width:"81.4vw"}}>
                    <Column field="file.name" title={lang.file_name_column} />
                </Grid>
            </div>
            <div style={{ margin:"10px 10px 0px 10px", height: "37vh"}}>
                <div style={borderWithTitle}>&nbsp; {lang.status_label} &nbsp;
                </div>
                <Grid data={statusFiles} style={{ margin:"-05px 10px 10px 10px",
                        height: "34vh", width:"81.4vw"}}>               
                      <Column field="fileName" title={lang.file_name_column} />
                    <Column field="Status" title={lang.status_column} />
                    <Column field="Message" title={lang.message_column} />
                </Grid>
            </div>
        </div>
    </>
}
export default ImportAsRun;