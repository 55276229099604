/* eslint-disable */
import React, { useEffect, useState } from "react";
import * as API from '../../../framework/API/api'
import { ENTITYNAME } from "../../../framework/constant/constant";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useTranslation } from "../../../locale/useTranslation";
import AddRoundButton from "../../../framework/forms/helpercomponents/buttons/AddRoundButton";
import SaveRoundButton from "../../../framework/forms/helpercomponents/buttons/SaveRoundButton";
import CancelRoundButton from "../../../framework/forms/helpercomponents/buttons/CancelRoundButton";
import EditRoundButton from "../../../framework/forms/helpercomponents/buttons/EditRoundButton";
import DeleteRoundButton from "../../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmDeleteAlert } from "../../../ConfirmAlert";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function IngestionTemplateParamFilters(props) {

    const [dataItem, setDataItem] = useState({
        // Synopsis:"",
    });
    const[i, setI] = useState(0);
    const[editMode, setEditMode] = useState(false);
    const[toggle, setToggle] = useState(false);
    const lang=useTranslation();

    const onChange = (e) => {
        setDataItem({...dataItem, [e.target.name]: e.target.value});
    };

    const addSynopsis = (index) => {
      if(dataItem.paramkey=="" || dataItem.paramkey==undefined){
        toast.error(`${lang.please_enter_key_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      if(dataItem.paramvalue=="" || dataItem.paramvalue==undefined){
        toast.error(`${lang.please_enter_value_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return;
      }
      const paramsObject = {
        ...dataItem,
        index: index??i,        
      }
      setI(i+1);
      //add validation
      props.updateParams([...props.data,paramsObject]);
      setDataItem({});
      clearData();
    }
    const MyCommandCell = (props)=>(
      <div style={{display:"flex"}}>
      <EditRoundButton onClick={()=>editFormGrid(props.dataItem)}/>
      <DeleteRoundButton onClick={()=>ConfirmDeleteAlert(()=>removeSynopsis(props.dataItem.index),()=>{})}/>
      </div>
    )

    const editFormGrid = (data)=>{
      setDataItem(data);
      setToggle(true);
      setEditMode(true);
    }

    const removeSynopsis = (index) => {
      props.updateParams([
        ...props.data.slice(0, index),
        ...props.data.slice(index + 1, props.data.length)
      ]);
      setI(i-1);
      clearData();
      toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    // console.log(props.data);

    const editSynopsis = ()=>{
      props.data[dataItem.index] = dataItem
      props.updateParams(props.data);
      setEditMode(false);
      clearData();
    }

    const clearData = ()=>{
      setDataItem({paramkey:'',paramvalue:''});
      setEditMode(false);
      setToggle(false);
    }
  
  return (<>
  <div className="row">
      {!toggle&&
      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
      

      <div className="row">
        <div className="col-md-3 col-lg-3 col-sm-3 col-xs-3 mb-2">

        <AddRoundButton onClick={() => setToggle(true)}/>  

        </div>
        <div className="col-md-9 col-lg-9 col-sm-9 col-xs-9 mt-2">

        <label className="text-muted pull-right mr-2"><small>{lang.ingestion_template_note_label}</small></label>  

        </div>
      </div>

      <div className="row">
        <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">

        <Grid data={props.data} style={{ height: "20vh" }}>
          <GridColumn cell={MyCommandCell} width={"60px"} locked={true} />
          <GridColumn field="paramkey" title={lang.key_column} editable={false} />
          <GridColumn field="paramvalue" title={lang.value_column} editable={false} />
        </Grid>

        </div>
      </div>      
      </div>
      }
      {/* {toggle&&<div className="col-4" style={{paddingTop:"30px"}}>
        <Grid data={props.data} style={{ height: "30vh" ,width:"60vw"}}>
          <GridColumn cell={MyCommandCell} width={"60px"} locked={true} />
          <GridColumn field="paramkey" title="Key" editable={false} />
          <GridColumn field="paramvalue" title="Value" editable={false} />
        </Grid>
      </div>} */}
      
      </div>

      <div className="row">
      {toggle&&
      <div className="col-md-12 col-lg-12 col-sm-12 col-xs-12">
        {!editMode&&<SaveRoundButton onClick={() => addSynopsis()} />}
        {editMode&&<SaveRoundButton onClick={() => {editSynopsis()}}/>}
        <CancelRoundButton onClick={clearData}/>
          <div className="row ml-0" style={{ border: "groove 1px",  width: "650px",height: "30vh"}} >
            <div className="col-12 mt-2">
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="">{lang.key_label} *</label>
                    <input type="text" className="form-control form-control-sm" 
                    name="paramkey" 
                    value={dataItem.paramkey}
                    onChange={(e) => onChange(e)} />
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="">{lang.value_label} *</label>
                    <input type="text" className="form-control form-control-sm" 
                    name="paramvalue" 
                    value={dataItem.paramvalue}
                    onChange={(e) => onChange(e)}/>
                  </div>
                </div>
              </div>

              
            </div>                      
          </div>
        </div>  }
      </div>
  </>)
}

export default IngestionTemplateParamFilters
