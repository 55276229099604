import Card from 'react-bootstrap/Card';
import { ENTITYNAME } from '../../../constant/constant';
import { useEffect, useState } from 'react';
import * as API from "../../../API/api";

export const ProviderCell = (props) => {
  const field = props.field || "";
  const width = props.width ?? "50px";
  const height = props.height ?? "50px";
  const providerId = props.dataItem[field];
  const [imageUrl, setImageUrl] = useState('https://media.comicbook.com/files/img/default-movie.png');

  useEffect(() => {
    getProviderLogo(providerId);
  }, [providerId]);

  const getProviderLogo = async (providerId) => {
    let providerRes = await API.getData(ENTITYNAME.OttProvider, {query: ["_id", "=", providerId]});
    if(providerRes?.success && providerRes.data && providerRes.data.length > 0){
        setImageUrl(providerRes.data[0].LogoUrl);
    }else{
        setImageUrl('https://media.comicbook.com/files/img/default-movie.png');
    }
  }

  let imgStyle = { objectFit: "cover", height: height, borderRadius: "8px", width: width }
  
  const handleError = () => {
    setImageUrl('https://media.comicbook.com/files/img/default-movie.png');
  }

  return <td className={props?.className ?? ''} style={props?.style ?? {}}>
    <Card.Img onError={handleError} src={imageUrl} style={imgStyle} />
  </td>;
};