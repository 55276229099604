import React, { useState } from 'react'
import { EditPageHeader } from '../../../components/EditPageHeader'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import BossDialog from '../../../components/BossDialog'

const HeaderRowAndSheetPopup = (props) => {

    const [sheet,setSheet] = useState(props.sheets[0] ?? "Sheet1");

    const [dataItem,setDataItem] = useState({
        HeaderRowIndex : 1,
        SheetIndex : 0
    })

    const onChange = (e) => {
        if(e.target.name === "Sheet"){
            setSheet(e.target.value)
            let index = props.sheets.findIndex((x) => x === e.target.value)
            setDataItem({ ...dataItem, SheetIndex: parseInt(index) })
            props.setHeaderRowSheetIndex({ ...dataItem, SheetIndex: parseInt(index) })
        } else {
            setDataItem({ ...dataItem, [e.target.name]: parseInt(e.target.value) })
            props.setHeaderRowSheetIndex({ ...dataItem, [e.target.name]: parseInt(e.target.value) })
        }
    }

    return (
        <BossDialog
            title={"Choose Header Row and Sheet"}
            onClose={props.onClose}
            width={"350px"}
        >
        <EditPageHeader onSubmit={props.onConfirm} saveButtonTitle={'Confirm'} onCancel={props.onClose} showTitle={false} />

            <div className="form-group">
                <label htmlFor="">Header Row *</label>
                <input type="number" name="HeaderRowIndex" className="form-control"  value={dataItem.HeaderRowIndex} onChange={onChange} min={1}/>
            </div>

            <div className="form-group">
            <label htmlFor="">Sheet *</label>
            <DropDownList
                style={{ height: "38px", backgroundColor: "white" }}
                data={props.sheets}
                name="Sheet"
                value={sheet}
                onChange={onChange}
            />
            </div>
        
        </BossDialog>
    )
}

export default HeaderRowAndSheetPopup