import { useTranslation } from "../../../../locale/useTranslation";

const SaveRoundButton = (props) => {
    const lang=useTranslation();
    
    return <button  title={lang.save_button_tooltip} type="button" onClick={props.onClick} style={{margin:"02px 02px"}} className="btn btn-default btn-circle btn-my">
       <i className="fa fa-floppy-disk fa-fw img-circle"></i>
      
    </button>
}

export default SaveRoundButton;
