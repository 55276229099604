/* eslint-disable */
import React, { useState, useEffect } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useTranslation } from "../../../locale/useTranslation";
import * as API from "../../../framework/API/api";
import { ENTITYNAME } from "../../../framework/constant/constant";
import { Grid, GridColumn as Column, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import ActionButton from "../../../framework/forms/helpercomponents/buttons/ActionButton";
import 'react-toastify/dist/ReactToastify.css';
import { toast } from 'react-toastify';
import { DateCell } from "../../../framework/forms/helpercomponents/CustomGridCells/DateCell";

const ProgramScheduleClash = (props) => {

    var showTitle = props.showTitle ?? true;
    var myTitle = props.title ?? "";

    const lang = useTranslation();
    const [channel, setChannel] = useState([]);
    const [dataItem, setDataItem] = useState({
        Channel: "",
        FromDate: '',
        ToDate: '',
    });
    const [gridData, setGridData] = useState(false);
    const [loadingStatus, setLoadingStatus] = useState(
        `${lang.no_record_found_error_message}`
    );

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        var channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelRes.data);
    };

    const onChange = (event) => {
        setDataItem({ ...dataItem, [event.target.name]: event.target.value, });
    };

    const isValid = () => {

        if (Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.FromDate == '' || dataItem.FromDate == undefined) {
            toast.error(`${lang.please_select_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.ToDate == '' || dataItem.ToDate == undefined) {
            toast.error(`${lang.please_select_to_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const showGridData = async () => {
        setGridData([]);
        if (!isValid()) return;

        var fdate = new Date(dataItem.FromDate);
        var tdate = new Date(dataItem.ToDate);
        var fromdate = fdate.getTime();
        var todate = tdate.getTime();
        var Payload = {
            "Channel": dataItem.Channel.SID,
            "FromDate": fromdate,
            "ToDate": todate
        }

        var res = await API.getProgramScheduleClash(Payload);
        console.log(res);

        if(res.success && res.data.length > 0){
            setLoadingStatus(`${lang.data_loading_error_message}`);
            setGridData(res.data);
        }else{
            toast.info(`${lang.no_data_found_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            setLoadingStatus(`${lang.no_record_found_error_message}`);
            return;
        }
    }

    return (
        <>
            {showTitle && (
                <>
                    <h1 className="page-title txt-color-bludeDark">
                        <i
                            className="fa fa-table marlr"
                            style={{ fontSize: "20px", color: "GrayText" }}
                        ></i>
                        <span>{myTitle}</span>
                    </h1>
                </>
            )}
            <div className="row">
                <div className="col-2">
                    <div className="form-group">
                        <label htmlFor="TabView">{lang.channel_label} *</label>
                        <DropDownList
                            style={{
                                backgroundColor: "white",
                            }}
                            data={channel}
                            name="Channel"
                            textField="FullChannelName"
                            dataItemKey="_id"
                            value={dataItem.Channel}
                            onChange={onChange}
                        />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group">
                        <label htmlFor="">{lang.from_date_label} </label>
                        <input
                            type="date"
                            className="form-control form-control-sm"
                            name="FromDate"
                            value={dataItem.FromDate}
                            onChange={onChange}
                            max = {dataItem.ToDate}
                        />
                    </div>
                </div>
                <div className="col-2">
                    <div className="form-group">
                        <label htmlFor="">{lang.to_date_label} </label>
                        <input
                            type="date"
                            className="form-control form-control-sm"
                            name="ToDate"
                            value={dataItem.ToDate}
                            onChange={onChange}
                            min = {dataItem.FromDate}
                        />
                    </div>
                </div>
                <div className="col-2" style={{ textAlign: "left", marginTop: "20px" }}>
                    <ActionButton title={lang.show_button_tooltip} name={lang.show_button_text} onClick={showGridData} />
                </div>
            </div>
            <Grid data={gridData} style={{ height: "70vh" }} >
                <GridNoRecords>{loadingStatus}</GridNoRecords>
                <GridColumn field="MovieName" title={lang.media_column} editable={false} width={"150px"} />
                <GridColumn field="Channel.FullChannelName" title={lang.primary_channel_column} editable={false} />
                <GridColumn field="clashChannel.FullChannelName" title={lang.clash_channel_column} editable={false} />
                <GridColumn field="SourceSlotDateTime" title={lang.slot_time_column} editable={false} cell={DateCell} />
                <GridColumn field="ClashedSlotDateTime" title={lang.clashed_time_Column} editable={false} cell={DateCell} />
            </Grid>

        </>
    );
}
export default ProgramScheduleClash;