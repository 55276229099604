import React, { useEffect, useRef, useState } from 'react'
import { EditPageHeader } from '../../components/EditPageHeader';
import { useTranslation } from '../../locale/useTranslation';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { ENTITYNAME, MEDIACATEGORIES } from '../../framework/constant/constant';
import * as API from '../../framework/API/api'
import { toast } from 'react-toastify';
import { TimePickerWithFormat } from '../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat';
import { utility } from '../../framework/utility/utilityProvider';
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import CollectionSelection from '../../framework/forms/CollectionSelection';
import moment from 'moment';
import BossDialog from '../../components/BossDialog';

const PlanningInsertProgramGroup = (props) => {

    const lang = useTranslation();
    const planningGroupItemsRef = useRef({ Name : ''});
    const [dataItem, setDataItem] = useState({
        // SlotDate : '',
        Time : '00:00:00:00',
        isLoop : false,
        enableFixedGaps : false,
        fixedGap: '00:00:00:00',
        limitSlotsPerDay: false,
        slotsLimitPerDay: 4,
        twoEpisodesTogether: false,
        startingEpisode: {}
    })
    const [showCollection,setShowCollection] = useState(false);
    const [programGroupEpisodes, setProgramGroupEpisodes] = useState([]);

    useEffect(() => {
        loadcombo()
    }, [])

    const loadcombo = async () => {
    }

    const onChange = (e) => {
        if(e.target.name == 'isLoop'){
            setDataItem({ ...dataItem, isLoop : !dataItem.isLoop });
        }
        else if(e.target.name == 'enableFixedGaps'){
            setDataItem({ ...dataItem, enableFixedGaps : !dataItem.enableFixedGaps });
        }
        else if(e.target.name == 'limitSlotsPerDay'){
            setDataItem({ ...dataItem, limitSlotsPerDay : !dataItem.limitSlotsPerDay });
        }
        else if(e.target.name == 'twoEpisodesTogether'){
            setDataItem({ ...dataItem, twoEpisodesTogether : !dataItem.twoEpisodesTogether });
        }
        else{
            setDataItem({ ...dataItem, [e.target.name] : e.target.value });
        }
    }

    const isValid = () => {

        if (Object.keys(planningGroupItemsRef.current).length == 0) {
            toast.error(`Please select program group first`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.isLoop && !utility.isValidDate(dataItem.SlotDate)) {
            toast.error(`Please enter valid slot date`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.isLoop && (dataItem.Time == undefined || dataItem.Time.includes('_'))) {
            toast.error(`Please enter valid time`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.enableFixedGaps && (dataItem.fixedGap == undefined || dataItem.fixedGap.includes('_'))) {
            toast.error(`Please enter valid gap`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.limitSlotsPerDay && (dataItem.slotsLimitPerDay == undefined || !utility.isNumeric(dataItem.slotsLimitPerDay))) {
            toast.error(`Please enter valid slots limit per day`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if(dataItem.isLoop){

            let slotDateTimeFrom = props.selectedSlotDateTime + props.offSetTime
            let slotDateTimeTo = utility.convertStringDatetoMilliseconds(dataItem.SlotDate) + 
            utility.convertStringWithFramesToMilliseconds(dataItem.Time);
            console.log(slotDateTimeTo <= slotDateTimeFrom);
            if (slotDateTimeTo <= slotDateTimeFrom) {
                toast.error(`SlotDateTime should be greater than the selected slot cell SlotDate Time`, {
                    position: toast.POSITION.TOP_RIGHT
                });
                return false;
            }

        }
        

        return true;

    }

    const onInsertProgramGroupPlanning = async () => {

        if(!isValid()) return;

        let payload = {
            programGroup : planningGroupItemsRef.current,
            selectedSlotDateTime : props.selectedSlotDateTime + props.offSetTime,
            channel : props.channel,
            isLoop : dataItem.isLoop,
            slotDateTime : dataItem.isLoop ? 
                utility.convertStringDatetoMilliseconds(dataItem.SlotDate) + 
                utility.convertStringWithFramesToMilliseconds(dataItem.Time) : 0,
            enableFixedGaps : dataItem.enableFixedGaps,
            fixedGap : dataItem.enableFixedGaps ? utility.convertStringWithFramesToMilliseconds(dataItem.fixedGap) : 0,
            limitSlotsPerDay : dataItem.limitSlotsPerDay,
            slotsLimitPerDay : dataItem.limitSlotsPerDay ? dataItem.slotsLimitPerDay : 0,
            twoEpisodesTogether : dataItem.twoEpisodesTogether,
            startingEpisode: dataItem.startingEpisode?._id??""
            
        }

        console.log(payload);

        props.status(lang.inserting_program_group_status_label);

        let resInsertProgramGroup = await API.insertProgramGroup(payload);

        if(resInsertProgramGroup.success){
            props.status(lang.successfully_created_group_label);
            props.refreshPlanning();
            onClose();
        }else{
            toast.error(resInsertProgramGroup.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    const onClose = () => {
        props.onClose();
        setDataItem({ isLoop : false })
    }

    const selectProgramGroup = (programGroups) => {
        if (programGroups.length > 0) {
            planningGroupItemsRef.current = programGroups[0];
            
            loadProgramGroupMediaEpisodes(programGroups[0]);
            
        }
    }

    const loadProgramGroupMediaEpisodes = async (pg) => {
        if (pg && pg.SID > 0) {
            let linkData = {
                link_collection: 'mediaepisode',
                link_field: 'MediaEpisodes'
            }
            let res = await API.getEntity(ENTITYNAME.MediaEpisodeGroup, pg?.SID, linkData);
            setProgramGroupEpisodes((old) => [...res?.data?.MediaEpisodes]);

            setDataItem({ ...dataItem, startingEpisode : res?.data?.MediaEpisodes && res?.data?.MediaEpisodes.length > 0 ? res?.data?.MediaEpisodes[0] : {} });
        }
    }


    return (
        <>
            <BossDialog title={lang.insert_program_group_label_planning} onClose={onClose} width={'500px'}>
                <EditPageHeader saveButtonTitle={'Insert'} title={""} onSubmit={onInsertProgramGroupPlanning} onCancel={onClose} showTitle={false} />
                <div className='row mt-1'>  
                    <div className="col-10" style={{ padding : "0px 0px 0px 16px" }}>
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.program_group_sub_menu}</label>
                            <input name="Title" type="text" className="form-control form-control-sm" value={planningGroupItemsRef.current.Name} onChange={()=>""} disabled={true} />
                        </div>
                    </div>
                    <div className="col-2" style={{ padding : "0px 16px 0px 0px" }}> 
                        <ActionButton 
                            style={{marginTop: "18px", height:"30px"}} 
                            btnColor={'success'} 
                            title={lang.select_button_tooltip} 
                            name={"..."} 
                            onClick={() => { setShowCollection(true)}}
                        />
                    </div>  
                          
                </div>
                <div className='row'>  
                    <div className="col-10" style={{ padding : "0px 0px 0px 16px" }}>
                        <div className="form-group">
                        <label htmlFor="TabView">{"Starting Episode"}</label>
                          <DropDownList
                            style={{ backgroundColor: 'white' }}
                            data={programGroupEpisodes}
                            name="startingEpisode"
                            textField="AssetId"
                            dataItemKey="_id"
                            value={dataItem.startingEpisode}
                            onChange={onChange}
                          />
                        </div>
                    </div>
                          
                </div>
                <div className="row">
                    <div className="col-3">
                        <input type = "checkbox" name="isLoop" value={dataItem.isLoop} checked= {dataItem.isLoop} onChange={onChange}/>  
                        <label htmlFor="TabView" className="pl-2">{'Loop'}</label> 
                    </div> 
                </div>
                
                {dataItem.isLoop && <div className="row mt-2">
                    <div className="col-6">
                        <label htmlFor="TabView">{lang.slot_date_column} *</label>
                        <div className="form-group">
                            <input className="form-control form-control-sm" type="date" name="SlotDate" onChange={onChange} value={dataItem.SlotDate} />
                        </div>
                    </div>
                    <div className="col-6">
                        <label htmlFor="">{lang.time_column} *</label>
                        <TimePickerWithFormat
                            className="form-control form-control-sm"
                            name="Time"
                            value={dataItem.Time}
                            onChange={(e) => onChange(e)}
                            required
                        />
                    </div>
                </div>}

                {(dataItem.isLoop || dataItem.enableFixedGaps) && <hr></hr>}

                <div className="row">
                    <div className="col-12">
                        <input type = "checkbox" name="enableFixedGaps" value={dataItem.enableFixedGaps} checked= {dataItem.enableFixedGaps} onChange={onChange}/>  
                        <label htmlFor="TabView" className="pl-2">{'Fixed Gap'}</label>
                    </div> 
                </div>

                {dataItem.enableFixedGaps && <div className="row mt-2">
                <div className="col-6">
                        <label htmlFor="">Gap *</label>
                        <TimePickerWithFormat
                            className="form-control form-control-sm"
                            name="fixedGap"
                            value={dataItem.fixedGap}
                            onChange={(e) => onChange(e)}
                            required
                        />
                    </div>
                <div className="col-6 mt-4">
                        <input type = "checkbox" name="twoEpisodesTogether" value={dataItem.twoEpisodesTogether} checked= {dataItem.twoEpisodesTogether} onChange={onChange}/>  
                        <label htmlFor="TabView" className="pl-2">{'Two Episodes Together'}</label>
                    </div>
                </div>}

                {(dataItem.limitSlotsPerDay || dataItem.enableFixedGaps) && <hr></hr>}

                <div className="row">
                    <div className="col-12">
                        <input type = "checkbox" name="limitSlotsPerDay" value={dataItem.limitSlotsPerDay} checked= {dataItem.limitSlotsPerDay} onChange={onChange}/>  
                        <label htmlFor="TabView" className="pl-2">{'Limit Slots Per Day'}</label>
                    </div> 
                </div>

                {dataItem.limitSlotsPerDay && <div className="row mt-2">
                <div className="col-6">
                        <label htmlFor="">Limit *</label>
                        <input type = "text" className="form-control form-control-sm" name="slotsLimitPerDay" value={dataItem.slotsLimitPerDay} checked= {dataItem.slotsLimitPerDay} onChange={onChange}/>  
                    </div>
                </div>}

                {showCollection && (<CollectionSelection
                    title={lang.select_program_group_label}
                    entityname={ENTITYNAME.MediaEpisodeGroup}
                    wherestatement={['MediaCategory.isLong', '=', true]}
                    setDataList={selectProgramGroup}
                    mode={"single"}
                    closeForm={() => { setShowCollection(false) }}
                />)}
            </BossDialog>
        </>

    )
}

export default PlanningInsertProgramGroup