/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useTranslation } from "../../locale/useTranslation";
import { toast } from "react-toastify";
import * as API from "../../framework/API/api_digitalSign";
import Card from "../../components/Card";
import { ENTITYNAME, LOCALSTORAGE_KEY, MODULE, orientations, PLAYLIST_STATUS, SOCKET_ACTION, SOCKET_EVENTS } from "../../framework/constant/constant";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import MyStatusCell from "../../components/MyStatusCell";
import { utility } from "../../framework/utility/utilityProvider";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { DigitalSignHelper } from "./helper/DigitalSignHelper";
import useDigiSocket from "../../framework/socket/useDigiFSocket";
import digiSocket from "../../framework/socket/digiSocket";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import DigitalSignScreenGroupCollectionSelection from "./DigitalSIgnScreenGroupCollectionSelection";
import BossDialog from "../../components/BossDialog";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Slider, Switch } from "@mui/material";
import { EditPageHeader } from "../../components/EditPageHeader";
import { BASEURL } from "../../framework/constant/baseurl";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";

const ScreenEditForm = () => {

  const { SID } = useParams();
  const loc = useLocation();
  const navigate = useNavigate();
  const lang = useTranslation();

  const blankDataItem = {
    SID: 0,
    Name: "",
    screenGroup: {},
    SerialNumber: "",
    playlist: [],
    playlistItem: [],
    Orientation: 0,
    reboot: {
      isRebootEnabled: false,
      isAutoRestartEnabled: false,
      rebootTime: 0,
      autoRestartDuration: 0
    },
    archive: false,
  }

  const [dataItem, setDataItem] = useState(blankDataItem);
  const [showScreenGroupPopup, setShowScreenGroupPopup] = useState(false);
  const [showPlaylistPopup, setShowPlaylistPopup] = useState(false);
  const [selectedOrientation, setSelectedOrientation] = useState(orientations[0]);
  const [isEdit, setIsEdit] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [volume, setVolume] = useState(50);
  const [brightness, setBrightness] = useState(100);
  const [isModified, setIsModified] = useState(false);
  const [isTakingScreenShotLoader, setIsTakingScreenShotLoader] = useState(false);

  const [name, setName] = useState();
  const nameRef = useRef();

  useDigiSocket(SOCKET_EVENTS.onSocketData, (socketData) => {

    switch (socketData.action) {
      case SOCKET_ACTION.DIGI_SCREEN_ON:
      case SOCKET_ACTION.DIGI_SCREEN_OFF:
        loadEditData();
        break;
      default:
        break;
    }
  })


  useEffect(() => {
    let companyId = utility.getValue(LOCALSTORAGE_KEY.COMPANY)?._id;
    digiSocket.emit(SOCKET_EVENTS.joinCompany, companyId);
    return () => {
      digiSocket.emit(SOCKET_EVENTS.leaveCompany, companyId);
    }
  }, []);

  useEffect(() => {
    if (SID > 0) {
      loadEditData();
    }
  }, []);

  const loadData = async (screenId) => {
    setIsLoading(true);
    let json = await API.getDigitalSignScreen(
      { query: ["_id", "=", screenId] }
    );
    if (json.success && json.data.length > 0) {
      let data = json.data[0];
      setDataItem({ ...data, reboot: data?.reboot ?? dataItem.reboot, activeAgo: utility.timeAgo(data?.activeAgo ?? 0) });
      setName(data.Name);
      setIsActive(data.active);
      setBrightness(data?.brightness ?? 100);
      setVolume(data?.volume ?? 50);
      setSelectedOrientation(orientations.filter((o) => o.value === (data?.orientation ?? 0))[0]);
    }
    setIsLoading(false);
  }

  const loadEditData = async () => {
    let res = loc?.state?.item ?? {};
    if (res._id) {
      loadData(res._id);
    } else {
      delete res?.screenGroupId;
      console.log(res);
      setDataItem(res);
      if (res.reboot) {
        setDataItem({ ...dataItem, reboot: res?.reboot ?? dataItem.reboot });
      }
      setName(res?.Name ?? "");
      setIsActive(res?.active);
      setBrightness(res?.brightness ?? 100);
      setVolume(res?.volume ?? 50);
      setSelectedOrientation(orientations.filter((o) => o.value == res?.orientation ?? 0)[0]);
    }
  }

  const handleInputClick = (value) => {
    setSelectedOrientation(value);
    onChange({ target: { name: 'Orientation', value } });
    setDataItem({ ...dataItem, Orientation: value });
    let finalData = DigitalSignHelper.saveScreenEntity({ ...dataItem, Orientation: value });
    API.saveData(ENTITYNAME.DigitalSignScreen, finalData);
  };

  const onChange = (e) => {
    if (e.target.name === "isRebootEnabled") {
      setIsModified(true)
      setDataItem({ ...dataItem, reboot: { ...dataItem.reboot, isRebootEnabled: !dataItem.reboot.isRebootEnabled } });
    }
    else if (e.target.name === "isAutoRestartEnabled") {
      setIsModified(true)
      setDataItem({ ...dataItem, reboot: { ...dataItem.reboot, isAutoRestartEnabled: !dataItem.reboot.isAutoRestartEnabled } });
    }
    else if (e.target.name === "rebootTime" || e.target.name === "autoRestartDuration") {
      setIsModified(true)
      setDataItem({ ...dataItem, reboot: { ...dataItem.reboot, [e.target.name]: utility.convertStringWithFramesToMilliseconds(e.target.value) } });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  }

  const borderStyle = {
    border: "1px solid #ced4da", /* Your existing border styles */
    boxShadow: "0 0 10px rgba(0, 0, 0, 0.1)", /* Add a subtle box shadow for a 3D effect */
    backgroundColor: "#FAFAFA",
    borderRadius: "8px",
  }

  const colorText = {
    color: "#919496",
    letterSpacing: '1px',
    textTransform: "uppercase"
  }

  const onNameChange = async (name) => {
    let finalData = DigitalSignHelper.saveScreenEntity({ ...dataItem, Name: name });
    let res = await API.saveData(ENTITYNAME.DigitalSignScreen, finalData);
    if (res.success) {
      toast.success(lang.update_successfully_label, {
        position: toast.POSITION.TOP_RIGHT
      });
      setDataItem({ ...dataItem, Name: name });
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const handleSelectedGroup = async (data) => {

    let selectedGroupValue = data && data.length > 0 ? data[0] : {};

    if (dataItem.screenGroup._id == selectedGroupValue._id) {
      toast.error(lang.same_screen_group_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    let finalData = DigitalSignHelper.saveScreenEntity({ ...dataItem, screenGroup: selectedGroupValue });
    let res = await API.saveData(ENTITYNAME.DigitalSignScreen, finalData);
    if (res.success) {
      let playlist = await API.getData(ENTITYNAME.DigitalSignPlaylist, { query: ["screenGroup", "in", [selectedGroupValue._id]] });
      setDataItem({ ...finalData, playlist: playlist.data });
      toast.success(lang.update_successfully_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      loadEditData();
      await API.sendEvent(res.data.socketID, { action: SOCKET_ACTION.RESTART_APP, module: MODULE.ALL, data: null });

    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    setShowScreenGroupPopup(false);
  }

  const myCommandCell = (props) => {
    return (
      <td>
        <RoundButton icon="external-link" onClick={() => window.open(`/home/PlaylistItem?_id=${props.dataItem._id}`)} />
      </td>
    );
  }

  const MyPlaylistStatuscell = (props) => (
    <div>
      <MyStatusCell style={{ width: '100px' }} title={utility.getKeyByValue(PLAYLIST_STATUS, props.dataItem.Status)} tileBackColor="#508D4E" />
    </div>
  )

  const screenControlSave = async () => {
    let finalData = DigitalSignHelper.saveScreenEntity({ ...dataItem, orientation: selectedOrientation.value, volume: volume, brightness: brightness });
    console.log(finalData);
    let res = await API.saveData(ENTITYNAME.DigitalSignScreen, finalData);
    if (res.success) {
      setIsModified(false);
      toast.success('Screen settings updated', {
        position: toast.POSITION.TOP_RIGHT
      });
      await API.sendEvent(dataItem.socketID, { action: SOCKET_ACTION.DIGI_PLAY_PLAYLIST, module: MODULE.ALL, data: null });

    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  const restartScreen = async () => {
    await API.sendEvent(dataItem.socketID, { action: SOCKET_ACTION.RESTART_APP, module: MODULE.ALL, data: null });
  }

  const takeScreenShot = async () => {
    setIsTakingScreenShotLoader(true);
    await API.sendEvent(dataItem.socketID, { action: SOCKET_ACTION.LIVE_SCREENSHOT, module: MODULE.ALL, data: null });
    setTimeout(() => {
      setIsTakingScreenShotLoader(false);
      loadEditData();
    }, 3000);
  }

  return (<>
    <div className="row m-1 mb-5">
      <div className="col-12 p-0">
        <h1 className="page-title txt-color-bludeDark d-flex ml-1" style={{ marginLeft: '-10px', alignItems: "center", justifyContent: "space-between" }}>
          <div>
            <BackRoundButton onClick={() => navigate("/home/digitalsignScreen")} /> <i
              className="fa fa-table marlr"
              style={{ fontSize: "20px", color: "GrayText" }}
            ></i>
            <input ref={nameRef} value={name} onChange={(e) => setName(e.target.value)} onKeyUp={(e) => {
              if (e.key == 'Enter') {
                nameRef.current.blur();
              }
            }} onFocus={() => setIsEdit(true)} onBlur={() => { onNameChange(name); setIsEdit(false); }} style={isEdit ? { border: "1px solid grey", borderRadius: "5px" } : {}} />
          </div>
          <div style={{ fontSize: '15px', padding: "5px 10px", letterSpacing: "1px", marginLeft: "5px" }} className={`badge badge-${isActive ? "success" : "danger"}`}>
            {isActive ? "Active" : "Inactive"}
          </div>
        </h1>
        {isModified && <div style={{ position: "relative", bottom: "20px", left: "10px" }}>
          <EditPageHeader showTitle={false} onSubmit={screenControlSave} onCancel={() => navigate("/home/digitalsignScreen")} />
        </div>}

        <div className="pl-2" style={{ display: "flex" }}>
          <div className="col-12 p-0">
            <div className="row pl-3">
              <div className="col-5 p-0">
                <div style={{ height: "280px", width: "100%", position: "relative" }}>
                  <div style={{ position: "absolute", top: "-20px", display: "flex", justifyContent: "space-between", alignItems: "start", width: "100%" }}>
                    <p>Last ScreenShot</p>
                    <i className={`fa fa-arrows-rotate fa-fw img-circle`} style={{ cursor: "pointer" }} onClick={loadEditData}></i>
                  </div>
                  <div style={{ width: "100%", height: "100%", position: "relative", display: "flex", justifyContent: "center", alignItems: "center", background: "black", borderRadius: "5px", }}>
                    {(dataItem?.screenShotUrl == null || dataItem?.screenShotUrl.length < 10 || dataItem?.screenShotUrl == undefined || isLoading) ? <p style={{ color: "white", fontSize: "20px", textAlign: "center" }}>{isLoading ? <i className="fa fa-spinner fa-pulse" /> : "No ScreenShot"}</p> : <img src={BASEURL + '/' + dataItem?.screenShotUrl + '?' + new Date().getTime()} style={{ aspectRatio: "16/9", objectFit: "contain", maxHeight: "100%", maxWidth: "100%", }} />}
                  </div>
                </div>
                <div>
                  <div className="row mt-2">
                    <div className="col-6" style={{ cursor: "pointer" }} onClick={() => { restartScreen() }}>
                      <div className="border p-2 form-group with-3d-effect mb-2" style={{ ...borderStyle, height: '110px' }}>
                        <div className="row">
                          <div className="col-12" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", top: '25px' }}>
                            <i className="fa fa-power-off" style={{ fontSize: "20px", color: "purple" }}></i>
                            <div style={{ textTransform: "uppercase" }}>{lang.restart_app_label}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="col-6" style={{ cursor: "pointer", pointerEvents: isTakingScreenShotLoader ? "none" : "auto" }} onClick={() => { takeScreenShot() }}>
                      <div className="border p-2 form-group with-3d-effect mb-2" style={{ ...borderStyle, height: '110px' }}>
                        <div className="row">
                          <div className="col-12" style={{ display: "flex", justifyContent: "center", alignItems: "center", flexDirection: "column", top: '25px' }}>
                            <i className={`fa fa-${isTakingScreenShotLoader ? "spinner fa-pulse" : "camera"}`} style={{ fontSize: "20px", color: "purple" }}></i>
                            <div style={{ textTransform: "uppercase" }}>Take ScreenShot</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="border p-2 form-group with-3d-effect mb-2" style={borderStyle}>
                    <div className="row p-3">
                      <div className="col-3 text-center cursor-pointer" onClick={() => setShowPlaylistPopup(true)}>
                        <label style={colorText}>{lang.playlist_label}</label>
                        <div >{dataItem?.playlist?.length ?? 0}</div>
                      </div>
                      <div style={{ borderLeft: '1px solid #E0E0E0', marginLeft: "-1px" }}></div>
                      <div className="col-6 text-center cursor-pointer" onClick={() => setShowScreenGroupPopup(true)}>
                        <label style={colorText}>{lang.screen_group_label}</label>
                        <div>{dataItem?.screenGroup?.Name ?? ''}</div>
                      </div>
                      <div style={{ borderLeft: '1px solid #E0E0E0', marginLeft: "-1px" }}></div>
                      <div className="col-3">
                        <label style={colorText}>{lang.player_label}</label>
                        <div>{dataItem?.screenDetail?.Os ?? ''}</div>
                      </div>
                    </div>
                  </div>
                  {/* screen controls */}
                  <div className="row mt-2">
                    <div className="col-12">
                      <div className="border p-4 form-group with-3d-effect mb-2" style={{
                        ...borderStyle,
                        backgroundColor: '#f8f9fa',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                      }}>
                        <div className="row">
                          <div className="col-md-4 mb-3">
                            <label style={{ ...colorText, marginBottom: '0.5rem' }}>Orientation</label>
                            <DropDownList
                              data={orientations}
                              textField="text"
                              dataItemKey="value"
                              value={selectedOrientation}
                              onChange={(e) => {
                                if (!isModified) {
                                  setIsModified(true);
                                }
                                setSelectedOrientation(e.target.value);
                              }}
                              style={{ width: '100%' }}
                            />
                          </div>
                          <div className="col-md-4 mb-3">
                            <label style={{ ...colorText, marginBottom: '0.5rem' }}>Volume</label>
                            <Slider
                              min={0}
                              max={100}
                              step={1}
                              value={volume}
                              valueLabelDisplay="auto"
                              onChange={(e) => {
                                if (!isModified) {
                                  setIsModified(true);
                                }
                                setVolume(e.target.value)
                              }}
                              style={{ width: '100%' }}
                            />
                          </div>
                          <div className="col-md-4 mb-3">
                            <label style={{ ...colorText, marginBottom: '0.5rem' }}>Brightness</label>
                            <Slider
                              min={0}
                              max={100}
                              step={1}
                              value={brightness}
                              valueLabelDisplay="auto"
                              onChange={(e) => {
                                if (!isModified) {
                                  setIsModified(true);
                                }
                                setBrightness(e.target.value)
                              }
                              }
                              style={{ width: '100%', color: 'orange' }}
                              trackColor="orange"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* reboot */}
                  <div className="row mt-1">
                    <div className="col-6">
                      <div className="border p-3 form-group with-3d-effect mb-2" style={{
                        ...borderStyle,
                        backgroundColor: '#f8f9fa',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                      }}>
                        <Switch name={"isRebootEnabled"}
                          size={"lg"}
                          onChange={onChange}
                          checked={dataItem.reboot.isRebootEnabled}
                          color="success"
                          trackColor="white"
                        />
                        <span className="" style={{ ...colorText, marginBottom: '0.5rem', fontSize: "16px", fontWeight: "initial" }}>{lang.daily_reboot_label}</span>
                        <br></br>
                        <div class="time-group ml-2">
                          <span style={{ ...colorText, marginBottom: '0.5rem' }}>{lang.restart_time_label} :</span>
                          <TimePickerWithFormat
                            isWithFrames={false}
                            className="form-control-sm"
                            name="rebootTime"
                            value={utility.convertMilisecondsToStringWithFrames(dataItem.reboot.rebootTime ?? 0)}
                            onChange={onChange}
                            style={{ width: "90px" }}
                            disabled={!dataItem.reboot.isRebootEnabled}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="border p-3 form-group with-3d-effect mb-2" style={{
                        ...borderStyle,
                        backgroundColor: '#f8f9fa',
                        boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
                      }}>
                        <Switch name={"isAutoRestartEnabled"}
                          size={"lg"}
                          onChange={onChange}
                          checked={dataItem.reboot.isAutoRestartEnabled}
                          color="success"
                          trackColor="white"
                        />
                        <span className="" style={{ ...colorText, marginBottom: '0.5rem', fontSize: "16px", fontWeight: "initial" }}>{lang.is_auto_restart_enabled_label}</span>
                        <br></br>
                        <div class="time-group ml-2">
                          <span style={{ ...colorText, marginBottom: '0.5rem' }}>{lang.every_label} :</span>
                          <TimePickerWithFormat
                            isWithFrames={false}
                            className="form-control-sm"
                            name="autoRestartDuration"
                            value={utility.convertMilisecondsToStringWithFrames(dataItem.reboot.autoRestartDuration ?? 0)}
                            onChange={onChange}
                            style={{ width: "90px" }}
                            disabled={!dataItem.reboot.isAutoRestartEnabled}
                          />
                        </div>



                      </div>
                    </div>
                  </div>

                </div>
              </div>
              <div className="col-7 pb-2">
                <div className="mr-3" style={{ height: "100%", borderRadius: "5px" }}>
                  <div className="border p-2 form-group with-3d-effect mb-2" style={{ ...borderStyle, height: "100%" }}>
                    <div className="row">
                      <div className="col-12">
                        <div className="float-left mt-1" style={colorText}>{lang.playing_now_label}
                        </div>
                        {dataItem?.active && <div className='float-right mt-1'>
                          <RoundButton style={{ float: 'right' }} icon="external-link" onClick={() => window.open(`/home/PlaylistItem/${dataItem?.playlistId}`)} />
                        </div>}
                      </div>
                    </div>
                    {dataItem?.active ?
                      <div className="row mt-2">
                        <div className="col-12">
                          <Grid data={dataItem?.playlistItem} resizable={true} style={{ height: "560px" }}>
                            <GridColumn field="content.thumbnailUrl" title="Thumbnail" cell={ImageCell} width={"80px"} />
                            <GridColumn field="content.fileName" title="Title" width={"200px"} />
                            <GridColumn field="content.fileName" title="Description" width={"200px"} />
                            <GridColumn field="overlay.length" title="Overlays" width={"80px"} />
                            <GridColumn field="content.resource_type" title="Type" />
                          </Grid>
                        </div>
                      </div>
                      : <p style={{ ...colorText, fontSize: "20px", position: 'absolute', top: '50%', left: '35%' }}>Screen is InActive</p>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row mt-2 pl-2">
          <div className="col-12">
            <div className="row">
              {Object?.keys(dataItem?.screenDetail ?? {})?.map((key, index) => (
                <div className="col-4" key={index}>
                  <Card header={key} description={dataItem?.screenDetail[key]} />
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div >
    {showScreenGroupPopup && <DigitalSignScreenGroupCollectionSelection title={lang.add_screen_group_dialog_header} entityname={ENTITYNAME.DigitalSignScreenGroup} wherestatement={["Archive", "=", false]} closeForm={() => setShowScreenGroupPopup(false)} setDataList={handleSelectedGroup} seletedState={[dataItem?.screenGroup]} width="600px" Height="300px" mode="single" />}
    {showPlaylistPopup && <BossDialog title={lang.playlist_dialog_header} onClose={() => setShowPlaylistPopup(false)} width="650px">
      <Grid
        data={dataItem?.playlist}
        style={{ height: "350px" }}
      >
        <GridColumn cell={myCommandCell} width="60px" />
        <GridColumn title="Name" field="Name" />
        <GridColumn title="Status" field="Status" width={200} cell={MyPlaylistStatuscell} />
      </Grid>
    </BossDialog>}

  </>
  )
}
export default ScreenEditForm