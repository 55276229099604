/* eslint-disable */
import React, { useState, useEffect } from "react";
import * as API from "../../framework/API/api";
import { useTranslation } from "../../locale/useTranslation";
import { getSelectedState, Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import { BOOKINGAPPROVALSTATUS, ENTITYNAME, LOCALSTORAGE_KEY } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { ShortTimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { getter } from "@progress/kendo-data-query";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { toast } from "react-toastify";
import { utility } from "../../framework/utility/utilityProvider";
import BossDialog from "../../components/BossDialog";
export const BookingApprovel = (props) => {

    const lang = useTranslation();
    const SELECTED_FIELD = "selected";
    const DATA_ITEM_KEY = "_id";
    const idGetter = getter(DATA_ITEM_KEY);

    const user = utility.getValue(LOCALSTORAGE_KEY.userData);

    const [gridData, setGridData] = useState([]);
    const [status, setStatus] = useState(Object.keys(BOOKINGAPPROVALSTATUS).filter(x => x == "Pending" || x == "Rejected").map((item) => {
        return { "Description": item, "_id": BOOKINGAPPROVALSTATUS[item] }
    }));
    const [dataItem, setDataItem] = useState({
        Status: status[0],
        ApproveStatus: false
    });
    const [selectedState, setSelectedState] = useState({});
    const [selectedItem, setSelectedItem] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(lang.data_loading_error_message);


    useEffect(() => {
        loadData(status[0]);
    }, []);

    const loadData = async (status) => {
        const res = await API.getData(ENTITYNAME.Booking, { query: [["Campaign_Id", "=", props.data._id], ["Status", "=", status._id]] });
        console.log(res.data);
        setGridData(res.data);
        if(res.data.length == 0 ){
            setLoadingStatus(lang.no_record_found_error_message);
        }
    }

    const onChange = (e) => {
        if (e.target.name == "Status") {
            setLoadingStatus(lang.data_loading_error_message);
            setDataItem({ ...dataItem, Status: e.target.value })
            loadData(e.target.value);
            setSelectedItem([]);
            setSelectedState({});
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value })
        }
    }

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);

        var selectedIds = Object.keys(newSelectedState);
        var tempSelected = [];

        selectedIds.map((value) => {
            if (newSelectedState[value]) {
                var filter = gridData.find((obj) => obj._id == value);
                tempSelected.push(filter);
            }
        });
        setSelectedItem(tempSelected);
    };

    const onHeaderSelectionChange = (event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);

        var selectedIds = Object.keys(newSelectedState);
        var tempSelected = [];

        if (checked) {
            selectedIds.map((value) => {
                if (newSelectedState[value]) {
                    console.log(gridData);
                    var filtered = gridData.find((obj) => obj._id == value);
                    tempSelected.push(filtered);
                }
            });
        }
        setSelectedItem(tempSelected);
    };

    const gridRowRender = (tr, props) => {
        const trProps = {
            onContextMenu: (e) => {
                ref.current = props.dataItem;
                if (Object.keys(selectedState).length == 0) {
                    toast.error(`${lang.please_select_atleast_one_item}`, {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    return;
                }
                cm.current.show(e);
            },
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    const updateBookingStatus = async (bookingStatus) => {
        var selectedIds = Object.keys(selectedState);
        var tempSelected = [];

        selectedIds.map((value) => {
            if (selectedState[value]) {
                var filtered = gridData.find((obj) => obj._id == value);
                tempSelected.push(filtered);
            }
        });
        setSelectedItem(tempSelected);

        if (selectedItem.length == 0) {
            toast.error(`${lang.please_select_atleast_one_item}`, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
        else {
            var data = [];
            selectedItem.map((item) => {
                data.push({
                    ...item,
                    "Status": bookingStatus,
                    "Approver": { _id: user?._id ?? "", name: user?.name ?? "" }
                })
            })
            console.log(data);
            const res = await API.saveData(ENTITYNAME.Booking, data);
            console.log(res.data);
            if (res.success) {
                toast.success(`${lang.booking_status_updated_successfully}`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
                setSelectedItem([]);
                setSelectedState({});
                loadData(dataItem.Status)
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
        }
    }


    return (<>
    <BossDialog
            title={"Status"}
            onClose={props.onClose}
            width={"850px"}
        >
            <div className="row">
                <div className="col-12">
                    <div className="float-left">
                        <ActionButton btnColor={"success"} name={lang.approve_button_text} onClick={() => updateBookingStatus(BOOKINGAPPROVALSTATUS.Approved)}/>
                        <ActionButton btnColor={"danger"} name={lang.reject_button_text} onClick={() => updateBookingStatus(BOOKINGAPPROVALSTATUS.Rejected)}/>
                    </div>
                    <div className="float-right">
                        <label>{lang.status_label} </label>
                        &nbsp;&nbsp;
                        <DropDownList
                            style={{
                                backgroundColor: "white",
                                width: "150px"
                            }}
                            data={status}
                            name="Status"
                            textField="Description"
                            dataItemKey="_id"
                            value={dataItem.Status}
                            onChange={(e) => { onChange(e) }}
                        />
                    </div>
                </div>
            </div>
            <div className="row mt-3">
                <div className="col-12">
                    <Grid data={
                        gridData.map((item) => ({
                            ...item,
                            [SELECTED_FIELD]: selectedState[idGetter(item)],
                        }))}
                        selectedField={SELECTED_FIELD}
                        selectable={{
                            enabled: true,
                            drag: false,
                            cell: false,
                            mode: "multiple",
                        }}
                        dataItemKey={DATA_ITEM_KEY}
                        onSelectionChange={onSelectionChange}
                        onHeaderSelectionChange={onHeaderSelectionChange}
                        rowRender={gridRowRender}
                        style={{ height: '50vh' }}>
                        <GridNoRecords>{loadingStatus}</GridNoRecords>
                        <Column
                            field={SELECTED_FIELD}
                            width="50px"
                            headerSelectionValue={
                                gridData.findIndex(
                                    (item) => !selectedState[idGetter(item)]
                                ) === -1
                            }
                            locked={true}
                            filterable={false}
                        />
                        <Column field="BookingDate" title={lang.booking_date_column} cell={DateOnlyCell} />
                        <Column field="SpotRate" title={lang.spot_rate_column} />
                        <Column field="Rate10Sec" title={lang.rate10sec_column} />
                        <Column field="Position.Description" title={lang.position_column} />
                        <Column field="TimeRangeFrom" title={lang.from_column} cell={ShortTimeCell} />
                        <Column field="TimeRangeTo" title={lang.to_column} cell={ShortTimeCell} />
                    </Grid>
                </div>
            </div>
        </BossDialog>
    </>)
}
export default BookingApprovel;