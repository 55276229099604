/* eslint-disable */
import React, { useState } from 'react';
import ConfettiExplosion from 'react-confetti-explosion';
import image from '../../../img/dswelcome.png';
import DigiSignSlideBar from './DigitalSignSlideBar';
import DigiSignDisplay from './DigitalSIgnDisplay';
import { utility } from '../../../framework/utility/utilityProvider';
import { PRODUCTS } from '../../../framework/constant/constant';
import { useNavigate } from 'react-router-dom';
import * as API from '../../../framework/API/api';
import { toast } from 'react-toastify';
import activateScreenImg from '../../../img/activatescreen.png';
import createPlaylistImg from '../../../img/createplaylist.png';
import welcomeScreenImg from '../../../img/welcomescreen.png';
import uploadContentImg from '../../../img/uploadcontent.png';
import BossDialog from '../../../components/BossDialog';

const DigitalSignWelcomeForm = ({email, password}) => {


    const navigate = useNavigate();
    
    const data = [
        {
            name: 'welcomeScreen',
            title: 'Welcome to automateB Digital Signage Solution!',
            content: "To help you get started, we've added quick steps that shows you how to get started with automateB.",
            image: welcomeScreenImg
        },
        {
            name: 'activateScreen',
            title: 'Activate Screen',
            content: "Download automateB application using below QR code add activate screen by putting PIN displayed on screen.",
            image: activateScreenImg
        },
        {
            name: 'uploadContent',
            title: 'Upload Content',
            content: "You can upload images and videos in automateB and use them to create playlist for different screen group.",
            image: uploadContentImg
        },
        {
            name: 'createPlaylist',
            title: 'Create a Playlist',
            content: "You can create playlist for different screen groups and apply multiple overlays upon primary playing content like images and shapes.",
            image: createPlaylistImg
        }
    ];

    const [selectedData, setSelectedData] = useState(data[0]);
    const [selectedValue, setSelectedValue] = useState({
        welcomeScreen: true,activateScreen: false,
        uploadContent: false,createPlaylist: false
    });
    const [buttonName, setButtonName] = useState('Next');

    const handleNavigationClick = (section) => {
        let value = { welcomeScreen: false, activateScreen: false, uploadContent: false, createPlaylist: false };
        value[section] = true;
        setSelectedValue(value);
        const selected = data.find(x => x.name === section);
        setSelectedData(selected);
        setButtonName(selected.name === 'createPlaylist' ? `Let's Go` : 'Next');
    };

    const handleNextClick = () => {
        let selected = selectedValue;
        if (selected.welcomeScreen) {
            handleNavigationClick('activateScreen');
        } else if (selected.activateScreen) {
            handleNavigationClick('uploadContent');
        } else if (selected.uploadContent) {
            handleNavigationClick('createPlaylist');
            setButtonName(`Let's Go`);
        } else if (selected.createPlaylist) {
            handleSumbit();
        }
    };

    const handleSumbit = async () => {
        let loginRes = await API.login(email, password);
        if (loginRes.success) {
            let loginDataRes = await utility.setLoginData(loginRes);
            if (loginDataRes?.data?.Company && loginDataRes?.data?.Company?.Products?.length == 1 && loginDataRes?.data.Company?.Products.some(x => x == PRODUCTS.DigitalSignage)) {
                navigate('/home/digitalsignDashboard');
            } else {
                navigate('/home');
            }
        }
        else {
            toast.error(loginRes.message)
        }
    }

    return (
        <BossDialog width="1040px" showHeader={false}>
            <ConfettiExplosion zIndex={10005} force={0.8} duration={3000} particleCount={550} width={5000} />
            <div className='row' style={{ height: '105.2%', overflow:'hidden', color: 'white', margin: '-20px' }}>
                <DigiSignSlideBar selectedValue={selectedValue} handleNavigationClick={handleNavigationClick} />
                <DigiSignDisplay selectedData={selectedData} buttonName={buttonName} handleNextClick={handleNextClick} handleSkipClick={handleSumbit} />
            </div>
        </BossDialog>
    );
};

export default DigitalSignWelcomeForm;
