import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from '../../locale/useTranslation';
import { MONTH, JOB_SCHEDULE_TASK, LOCALSTORAGE_KEY } from '../../framework/constant/constant';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import BossDialog from '../../components/BossDialog';
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import RefreshButton from '../../framework/forms/helpercomponents/buttons/RefreshButton';
import * as API from "../../framework/API/api"
import { utility } from '../../framework/utility/utilityProvider';

const JobScheduleTestRunPopup = (props) => {

    const lang = useTranslation();
    const [dataItem, setDataItem] = useState({});
    const [disable, setDisable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [partnerIngetionTemplates, setPartnerIngetionTemplates] = useState([]);

    const responseRef = useRef(null);
    const [response, _setResponse] = useState({});
    const setResponse = (data) => {
        responseRef.current = data
        _setResponse(data)
    }

    const [transactionId, setTransactionId] = useState(null);

    useEffect(() => {
        setDataItem({ ...props.jobSchedule, Status: "Pending" });
        setPartnerIngetionTemplates([{ ...props.jobSchedule.IngestionTemplate }]);
    }, []);

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value })
    }

    const onTestRun = async () => {

        const user = utility.getValue(LOCALSTORAGE_KEY.userData);

        setDisable(true);
        setDataItem({ ...dataItem, Status: 'Running' })

        console.log('deg');

        let currentDate = new Date();
        let transaction_id = `test-${currentDate.getSeconds()}${currentDate.getMinutes()}${currentDate.getHours()}${currentDate.getFullYear()}${currentDate.getMonth()}${currentDate.getDate()}`
        console.log(transaction_id)
        setTransactionId(transaction_id);

        let payload = {
            job_id: props.jobSchedule._id,
            transaction_id: transaction_id,
            user: { _id: user?._id, name: user?.name }
        }

        let testResponse = await API.runTestScheduleJob(payload);
        setResponse(JSON.stringify(testResponse, null, 2));
        setDataItem({ ...dataItem, Status: 'Complete' })
        setDisable(false)
        console.log(testResponse)
    }

    const onRefresh = () => {
        setDataItem({ ...dataItem, Status: 'Complete' })
        setDisable(false)
        setResponse(response);
    }

    return (
        <BossDialog
            title={`Test Job Schedule`}
            onClose={props.closeForm}
            width={"50%"}
        >

            <div className="row">
                <div className='col-12 display-flex'>
                    <div className='float-left'>
                        <h1 className="txt-color-bludeDark" style={{ margin: 0 }}>
                            <span style={{ fontSize: '15px' }}>{props.jobSchedule.Name}</span>
                        </h1>
                    </div>
                    <div className='float-right'>
                        <span className="mt-2" title={dataItem.Status}
                            style={{
                                fontSize: '12px',
                                color: 'white',
                                // backgroundColor: 'green',
                                backgroundColor: dataItem.Status == "Complete" ? 'green' : dataItem.Status == "Pending" ? '#FF9800' : 'red',
                                padding: '5px 10px',
                                // border: `1px solid green`,
                                border: `1px solid ${dataItem.Status == "Complete" ? 'green' : dataItem.Status == "Pending" ? '#FF9800' : 'red'}`,
                                borderRadius: '5px'
                            }}>{dataItem.Status}</span>

                    </div>
                </div>
                <div className="col-12">
                    <label htmlFor="TabView">{lang.task_label} *</label>
                    <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={Object.keys(JOB_SCHEDULE_TASK).map((obj) => { return { _id: JOB_SCHEDULE_TASK[obj], Description: obj } })}
                        name="Task"
                        textField="Description"
                        dataItemKey="_id"
                        value={dataItem.Task}
                        onChange={onChange}
                        disabled={true}
                    />
                </div>
                {/* <div className="col-6">
                      <label>{lang.command_label} *</label>
                      <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Command"} onChange={onChange} value={dataItem.Command} />
                    </div> */}
            </div>

            <div className="row mt-2">

                {dataItem.Task && dataItem.Task._id != JOB_SCHEDULE_TASK.PartnerIngestion && <div className="col-12">
                    <label>{lang.command_label} *</label>
                    <textarea className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "65px" }} name={"Command"} onChange={onChange} value={dataItem.Command} />
                </div>}

                {dataItem.Task && dataItem.Task._id == JOB_SCHEDULE_TASK.PartnerIngestion && <div className="col-12 mt-1">
                    <label htmlFor="TabView">{lang.partner_ingestion_template} *</label>
                    <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={partnerIngetionTemplates}
                        name="IngestionTemplate"
                        textField="TemplateName"
                        dataItemKey="_id"
                        value={dataItem.IngestionTemplate}
                        onChange={onChange}
                        disabled={true}

                    />
                </div>}

                {dataItem.Task && dataItem.Task._id == JOB_SCHEDULE_TASK.PartnerIngestion &&
                    <div className="col-12 mt-1">
                        <label>{lang.partner_ingestion_base_url} *</label>
                        <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"IngestionBaseUrl"} onChange={onChange} value={dataItem.IngestionBaseUrl} />
                    </div>}
            </div>
            <div className='row mt-3'>
                <div className='col-6'>
                    <ActionButton
                        title={"Test Run"}
                        name={"Test Run"}
                        style={{ margin: 0 }}
                        btnColor={disable ? 'secondary' : 'primary'}
                        onClick={onTestRun}
                        disabled={disable}
                    />
                </div>

                {transactionId && <div className='col-6'>
                    <div className="float-right" style={{ paddingTop: "5px" }}>
                        <RefreshButton onClick={onRefresh} />
                    </div>
                </div>}

            </div>
            {transactionId && <div className="row mt-2">
                <div className="col-12">
                    <label>{lang.response_area_label} *</label>
                    <textarea
                        className="form-control form-control-sm"
                        type="text"
                        name={"Response"}
                        onChange={onChange}
                        value={response}
                        rows={10} />
                </div>
            </div>}
        </BossDialog>
    )
}

export default JobScheduleTestRunPopup