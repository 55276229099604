/* eslint-disable */
import React, { useEffect, useState } from "react";
import { ENTITYNAME, QCSTATUS } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat";
import BossDialog from "../../components/BossDialog";

export const SegmentQC = (props) => {

    const blankDataItem = {
        QCStaus: props.segmentData?.QC?.QCStaus ?? {},
        VideoType: props.segmentData?.QC?.VideoType ?? {},
        VideoCodec: props.segmentData?.QC?.VideoCodec ?? {},
        VideoAspectRatio: props.segmentData?.QC?.VideoAspectRatio ?? {},
        FrameRate: props.segmentData?.QC?.FrameRate ?? {},
        Note: props.segmentData?.QC?.Note ?? "",
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [videoType, setVideoType] = useState([]);
    const [videoCodec, setVideoCodec] = useState([]);
    const [videoAspectRatio, setVideoAspectRatio] = useState([]);
    const [frameRate, setFrameRate] = useState([]);
    const lang = useTranslation();

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        var videoTypeRes = await API.getDataLookup(ENTITYNAME.VideoType, { sort: { Description: 1 } });
        setVideoType(videoTypeRes.data);
        var videoCodecRes = await API.getDataLookup(ENTITYNAME.VideoCodec, { sort: { Description: 1 } });
        setVideoCodec(videoCodecRes.data);
        var videoAspectRatioRes = await API.getDataLookup(ENTITYNAME.VideoAspectRatio, { sort: { Description: 1 } });
        setVideoAspectRatio(videoAspectRatioRes.data);
        var frameRateRes = await API.getDataLookup(ENTITYNAME.FrameRate, { sort: { Description: 1 } });
        setFrameRate(frameRateRes.data);
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {
        if (Object.keys(dataItem.QCStaus).length == 0) {
            toast.error(`${lang.please_select_qc_status_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.VideoType).length == 0) {
            toast.error(`${lang.please_select_video_type_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.VideoCodec).length == 0) {
            toast.error(`${lang.please_select_video_codec_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.VideoAspectRatio).length == 0) {
            toast.error(`${lang.please_select_video_aspect_ratio_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.FrameRate).length == 0) {
            toast.error(`${lang.please_select_frame_rate_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Note == "" || dataItem.Note == undefined) {
            toast.error(`${lang.please_enter_note_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {

        if (isValid()) {
            var saveData = {
                ...props.segmentData,
                QC: { ...dataItem }
            }
            console.log(saveData);
            var res = await API.saveData(ENTITYNAME.MediaEpisodeSegment, saveData);
            console.log(res.data);
            if (res.success) {
                props.onClose();
                props.updateSegement(res.data);
            } else {
                toast.error(res.message);
            }
        }
    }

    return (
        <>
            <BossDialog
                title={props.data?.Title + lang.segment_qc_page_header}
                onClose={props.onClose}
                width={"630px"}
                height={"430px"}
            >
                <div className="row">
                    <div className="col-12">
                        <EditPageHeader onSubmit={handleSubmit} onCancel={props.onClose} showTitle={false} />
                        <div className="row mt-2">
                            <div className="col-6">
                                <label>{lang.duration_label} *</label>
                                <TimePickerWithFormat
                                    name="Duration"
                                    value={utility.convertMilisecondsToStringWithFrames(props.segmentData.ActualDuration)}
                                    className="form-control form-control-sm"
                                    disabled
                                />
                            </div>
                            <div className="col-6">
                                <label>{lang.qc_status_label} *</label>
                                <DropDownList
                                    data={QCSTATUS}
                                    style={{ backgroundColor: 'white' }}
                                    name="QCStaus"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.QCStaus}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-6">
                                <label>{lang.video_type_label} *</label>
                                <DropDownList
                                    data={videoType}
                                    style={{ backgroundColor: 'white' }}
                                    name="VideoType"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.VideoType}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="col-6">
                                <label>{lang.video_codec_label} *</label>
                                <DropDownList
                                    data={videoCodec}
                                    style={{ backgroundColor: 'white' }}
                                    name="VideoCodec"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.VideoCodec}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col-6">
                                <label>{lang.video_aspect_ratio_label} *</label>
                                <DropDownList
                                    data={videoAspectRatio}
                                    style={{ backgroundColor: 'white' }}
                                    name="VideoAspectRatio"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.VideoAspectRatio}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="col-6">
                                <label>{lang.frame_rate_label} *</label>
                                <DropDownList
                                    data={frameRate}
                                    style={{ backgroundColor: 'white' }}
                                    name="FrameRate"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.FrameRate}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="row mt-2">
                            <div className="col">
                                <label>{lang.note_label} *</label>
                                <textarea
                                    name={"Note"}
                                    type="text"
                                    style={{ height: '14vh' }}
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.Note}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </BossDialog>
        </>
    );
}