/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import * as API from "../../framework/API/api";
import { ENTITYNAME, LOGEVENT, MODULE, JOB_SCHEDULE_TASK, INGESTIONTEMPLATETYPES } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { EditPageHeader } from "../../components/EditPageHeader";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import BossDialog from "../../components/BossDialog";
import { DropDownList } from "@progress/kendo-react-dropdowns";

export const JobsScheduleEditForm = (props) => {

  const lang = useTranslation();
  const emailRegex = new RegExp(/\S+@\S+\.\S+/);

  const [scheduledataItem, setScheduleDataItem] = useState({
    Minutes: '*',
    Hours: '*',
    Days: '*',
    Months: '*',
    Weeks: '*',
  });

  const [dataItem, setDataItem] = useState({
    Name: '',
    Description: '',
    Command: '',
    Schedule: '',
    Task: {
      _id: JOB_SCHEDULE_TASK.RunAPI,
      Description: Object.keys(JOB_SCHEDULE_TASK).find((x) => JOB_SCHEDULE_TASK[x] == JOB_SCHEDULE_TASK.RunAPI)
    },
    IngestionTemplate: {},
    IngestionBaseUrl: "",
    ExecuteShell: '',
    Email: '',
    Disable: false,
    EnableErrorLog: false,
    Notification: false,
    Archive: false
  });

  const [partnerIngetionTemplates, setPartnerIngetionTemplates] = useState([]);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    loadCombo();
    if (props.item.SID > 0) {
      setEditItem();
    }
  }, [])

  const loadCombo = async () => {
    let partnerIngetionTemplates = await API.getDataLookup(ENTITYNAME.PartnerIntegrationTemplate);
    let apiType = partnerIngetionTemplates.success && partnerIngetionTemplates.data ?
      partnerIngetionTemplates.data.filter((x) => x.IngestionTemplateType.SID == INGESTIONTEMPLATETYPES.API) : [];
    setPartnerIngetionTemplates(apiType);
  }

  const handleScheduleChange = (e) => {
    setScheduleDataItem({ ...scheduledataItem, [e.target.name]: e.target.value });
  }
  const handleChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  }

  function setEditItem() {
    let scheduleArray = props.item.Schedule?.split(" ");
    let data = {
      ...props.item,
      Name: props.item.copy ? 'copy of ' + props.item.Name : props.item.Name,
      Description: props.item.copy ? 'copy of ' + props.item.Description : props.item.Description,
      Command: props.item.Command ?? "",
      Disable: props.item.Disable ?? "",
      EnableErrorLog: props.item.EnableErrorLog,
      Archive: props.item.Archive
    }

    let ScheduleData = scheduleArray.length > 0 ? {
      Minutes: scheduleArray[0] ?? '*',
      Hours: scheduleArray[1] ?? '*',
      Days: scheduleArray[2] ?? '*',
      Months: scheduleArray[3] ?? '*',
      Weeks: scheduleArray[4] ?? '*',
    } : {}
    setScheduleDataItem(ScheduleData)
    setDataItem(data);
    setShowForm(true);

  }

  const isValid = () => {

    if (dataItem.Name == "" || dataItem.Name == undefined) {
      toast.error(`${lang.please_enter_name_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Description == "" || dataItem.Description == undefined) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (Object.keys(dataItem.Task).length == 0) {
      toast.error(`${lang.please_select_task_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.Task?._id == JOB_SCHEDULE_TASK.RunAPI || dataItem.Task?._id == JOB_SCHEDULE_TASK.RunShell) {
      if (dataItem.Command == "" || dataItem.Command == undefined) {
        toast.error(`${lang.please_enter_command_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
    }

    if (dataItem.Task?._id == JOB_SCHEDULE_TASK.PartnerIngestion) {

      if (dataItem.IngestionTemplate == {} || Object.keys(dataItem.IngestionTemplate).length == 0) {
        toast.error(`${lang.please_select_ingestion_template}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }

      if (dataItem.IngestionBaseUrl == undefined || dataItem.IngestionBaseUrl == "") {
        toast.error(`${lang.please_enter_ingestion_base_url}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
      
    }


    if (dataItem.Notification && (dataItem.Email == undefined || dataItem.Email == "" || !emailRegex.test(dataItem.Email))) {
      toast.error(`${lang.please_enter_email_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true;
  }



  const setScheduleByShortCut = async (scheduleAction = {}, startup = false) => {
    if (startup) {
      setScheduleDataItem({ ...scheduledataItem, Minutes: "*", Hours: "*", Days: "*", Months: "*", Weeks: "*" });
    }
    else {
      setScheduleDataItem({ ...scheduledataItem, ...scheduleAction });
    }
  }

  const handleSubmit = async (saveAndRun = false) => {

    if (isValid()) {

      const saveData = {
        ...dataItem,
        _id: dataItem._id,
        Name: dataItem.Name,
        Description: dataItem.Description,
        Command: dataItem.Command ?? "",
        Schedule: ((scheduledataItem.Minutes ?? "*") + " " + (scheduledataItem.Hours ?? "*") + " " +
          (scheduledataItem.Days ?? "*") + " " + (scheduledataItem.Months ?? "*") + " " + (scheduledataItem.Weeks ?? "*")),
        Task: dataItem.Task,
        ExecuteShell: dataItem.ExecuteShell,
        IngestionTemplate: dataItem.IngestionTemplate,
        IngestionBaseUrl: dataItem.IngestionBaseUrl,
        Disable: saveAndRun ? false : dataItem.Disable,
        EnableErrorLog: dataItem.EnableErrorLog ?? false,
        Archive: dataItem.Archive ?? false,
      }
      console.log(saveData);
      const res = await API.saveData(ENTITYNAME.JobsSchedule, saveData);
      if (res.success) {
        let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_JOB_SCHEDULE : LOGEVENT.UPDATE_JOB_SCHEDULE, module: MODULE.JOBS_SCHEDULE, data: res.data, message: res.message };
        API.SaveLogs(logData);
        props.refresh();
        props.cancelEdit();

        //STOP AND START ALL SCHEDULE AGAIN ON ADDING NEW JOB SCHEDULE
        if (!saveData.Disable) API.scheduleJobs();
      } else {
        toast.error(res.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }

    }
  }

  return (
    <>
      {<BossDialog
        title={props.item.SID > 0 ? props.item.Name : `${lang.create_jobs_schedule_dialog_header}`}
        onClose={props.cancelEdit}
        width="650px"
      >
        <EditPageHeader
          title={(props.item.SID > 0 ? dataItem.Name : `${lang.new_title_editpageheader}`)}
          onSubmit={() => handleSubmit()}
          showSaveAndRun={true}
          onSaveAndRun={() => handleSubmit(true)}
          onCancel={props.cancelEdit}
        />

        <Form
          initialValues={dataItem}
          render={(formRenderProps) => (
            <FormElement>
              <div className="row">
                <div className="col-12">

                  <div className="row mt-2">
                    <div className="col-6">
                      <label>{lang.name_label} *</label>
                      <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Name"} onChange={handleChange} value={dataItem.Name} />
                    </div>
                    <div className="col-6">
                      <label>{lang.description_label} *</label>
                      <input className="form-control form-control-sm" type="text" name={"Description"} onChange={handleChange} value={dataItem.Description} />
                    </div>
                  </div>
                  {/* <div className="row mt-2">
                    <div className="col-12">
                      <label>{lang.execute_shell_label} *</label>
                      <textarea className="form-control form-control-sm" type="text" name={"ExecuteShell"} onChange={handleChange} value={dataItem.ExecuteShell} />
                    </div>
                  </div> */}
                  <div className="row mt-2">
                    <div className="col-12">
                      <label htmlFor="TabView">{lang.task_label} *</label>
                      <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={
                          Object.keys(JOB_SCHEDULE_TASK).map((key) => {
                            return {
                              _id: JOB_SCHEDULE_TASK[key],
                              Description: key
                            }
                          })
                        }
                        name="Task"
                        textField="Description"
                        dataItemKey="_id"
                        value={dataItem.Task}
                        onChange={handleChange}
                      />
                    </div>
                    {dataItem.Task && dataItem.Task._id == JOB_SCHEDULE_TASK.PartnerIngestion && <div className="col-12 mt-2">
                      <label htmlFor="TabView">{lang.partner_ingestion_template} *</label>
                      <DropDownList
                        style={{ backgroundColor: "white" }}
                        data={partnerIngetionTemplates}
                        name="IngestionTemplate"
                        textField="TemplateName"
                        dataItemKey="_id"
                        value={dataItem.IngestionTemplate}
                        onChange={handleChange}
                      />
                    </div>}

                    {dataItem.Task && dataItem.Task._id == JOB_SCHEDULE_TASK.PartnerIngestion &&
                      <div className="col-12">
                        <label>{lang.partner_ingestion_base_url} *</label>
                        <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"IngestionBaseUrl"} onChange={handleChange} value={dataItem.IngestionBaseUrl} />
                      </div>}
                    {/* <div className="col-6">
                      <label>{lang.command_label} *</label>
                      <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "35px" }} name={"Command"} onChange={handleChange} value={dataItem.Command} />
                    </div> */}
                  </div>
                  <div className="mt-3">
                    <label htmlFor="">{lang.quick_schedule_lebel}</label>
                  </div>
                  <div className="row" style={{ margin: '8px 0px 0px -10px' }}>
                    <div>
                      <ActionButton style={{ borderRadius: "4px" }} title={lang.startup_button_tooltip} name={lang.startup_button_title} btnColor={'info'} onClick={(e) => { setScheduleByShortCut({}, true) }} />
                    </div>
                    <div>
                      <ActionButton style={{ borderRadius: "4px" }} title={lang.hourly_button_tooltip} name={lang.hourly_button_title} btnColor={'info'} onClick={(e) => { setScheduleByShortCut({ Hours: "*/1" }) }} />
                    </div>
                    <div>
                      <ActionButton style={{ borderRadius: "4px" }} title={lang.daily_button_tooltip} name={lang.daily_button_title} btnColor={'info'} onClick={(e) => { setScheduleByShortCut({ Days: "*/1" }); }} />
                    </div>
                    <div>
                      <ActionButton style={{ borderRadius: "4px" }} title={lang.weekly_button_tooltip} name={lang.weekly_button_title} btnColor={'info'} onClick={(e) => { setScheduleByShortCut({ Weeks: "*/1" }); }} />
                    </div>
                    <div>
                      <ActionButton style={{ borderRadius: "4px" }} title={lang.monthly_button_tooltip} name={lang.monthly_button_title} btnColor={'info'} onClick={(e) => { setScheduleByShortCut({ Months: "*/1" }); }} />
                    </div>
                    <div>
                      <ActionButton style={{ borderRadius: "4px" }} title={lang.yearly_button_tooltip} name={lang.yearly_button_title} btnColor={'info'} onClick={(e) => { setScheduleByShortCut({ Months: "12" }); }} />
                    </div>
                  </div>
                  <div className="row mt-2">
                    <div className="col-2" style={{ paddingRight: '0px' }}>
                      <label htmlFor="">{lang.minute_label}</label>
                      <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px' }} name={"Minutes"} onChange={handleScheduleChange} value={scheduledataItem.Minutes} />
                    </div>
                    <div className="col-2" style={{ paddingRight: '0px' }}>
                      <label htmlFor="">{lang.hour_label}</label>
                      <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px' }} name={"Hours"} onChange={handleScheduleChange} value={scheduledataItem.Hours} />
                    </div>
                    <div className="col-2" style={{ paddingRight: '0px' }}>
                      <label htmlFor="">{lang.day_Of_month_label}</label>
                      <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px' }} name={"Days"} onChange={handleScheduleChange} value={scheduledataItem.Days} />
                    </div>
                    <div className="col-2" style={{ paddingRight: '0px' }}>
                      <label htmlFor="">{lang.month_label}</label>
                      <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px' }} name={"Months"} onChange={handleScheduleChange} value={scheduledataItem.Months} />
                    </div>
                    <div className="col-2" style={{ paddingRight: '0px' }}>
                      <label htmlFor="">{lang.week_label}</label>
                      <input className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px' }} name={"Weeks"} onChange={handleScheduleChange} value={scheduledataItem.Weeks} />
                    </div>

                  </div>
                  <div className="row mt-2">
                    {dataItem.Task && dataItem.Task._id != JOB_SCHEDULE_TASK.PartnerIngestion && <div className="col-12">
                      <label>{lang.command_label} *</label>
                      <textarea className="form-control form-control-sm" type="text" style={{ border: 'solid lightgrey 1px', height: "95px" }} name={"Command"} onChange={handleChange} value={dataItem.Command} />
                    </div>}

                  </div>
                  <div className="row" style={{ marginTop: '25px' }} >
                    <div className="col-4">
                      <Field
                        name={"Notification"}
                        component={Checkbox}
                        label={lang.enable_notification_label}
                        checked={dataItem.Notification}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-3" style={{marginLeft: "-42px"}}>
                      <Field
                        name={"EnableErrorLog"}
                        component={Checkbox}
                        label={lang.enable_error_log_lebel}
                        checked={dataItem.EnableErrorLog}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-2" >
                      <Field
                        name={"Disable"}
                        component={Checkbox}
                        label={lang.disable_lebel}
                        checked={dataItem.Disable}
                        onChange={handleChange}
                      />
                    </div>
                    <div className="col-3" >
                      <Field
                        name={"Archive"}
                        component={Checkbox}
                        label={lang.archive}
                        checked={dataItem.Archive}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  {dataItem.Notification && <div className="mt-3">
                      <input
                        className="form-control form-control-sm"
                        name={"Email"}
                        value={dataItem.Email}
                        type="text"
                        onChange={handleChange}
                        style={{ border: 'solid lightgrey 1px', height: "35px" }}
                        placeholder="Enter Email"
                      />
                      <p className="text-muted" style={{ fontSize: "10px" }}>Note : Please use comma (,) to separate multiple emails</p>
                    </div>}
                </div>
              </div>
            </FormElement>
          )}
        />
      </BossDialog>}
    </>)
};