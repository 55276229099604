/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import * as API from "../../framework/API/api";
import { EditPageHeader } from '../../components/EditPageHeader';
import { DropDownList, MultiSelect } from '@progress/kendo-react-dropdowns';
import { useTranslation } from '../../locale/useTranslation';
import { ENTITYNAME, PLATFORMTYPE, PUBLISHING_SOURCE } from '../../framework/constant/constant';
import { utility } from '../../framework/utility/utilityProvider';
import AddRoundButton from '../../framework/forms/helpercomponents/buttons/AddRoundButton';
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import ContractCollectionSelection from './ContractCollectionSelection';
import moment from 'moment';
import DeleteRoundButton from '../../framework/forms/helpercomponents/buttons/DeleteRoundButton';
import { ConfirmDeleteAlert } from '../../ConfirmAlert';
import BossDialog from '../../components/BossDialog';

export const ContractAddLinearRightsForm = (props) => {

    let blankDataItem = {
        SID: 0,
        ContractSID: 0,
        ContractNumber: '',
        PublishStartDate: moment(new Date()).format("YYYY-MM-DD"),
        PublishEndDate: moment(new Date()).add(1, 'd').format("YYYY-MM-DD"),
        PublishingSource: PUBLISHING_SOURCE.Contract,
        PlatformType: PLATFORMTYPE[0],
        Platform: [],
        Licensor: {},
        Payee: {},
        Channel: [],
        Region: [],
        Amount: 0,
        RevShare: 0,
        Currency: {},
        IsUnlimited: true,
        Exhibitions: 0,
        Repeats: 0,
        MinGap: 0,
        PlayTimeNumber: 0,
        PlayTime: {},
        IsExclusive: false,
        ExclusiveStartDate: '',
        ExclusiveEndDate: '',
        Archive: false,
        MediaCategory: {}
    }

    const [dataItem, setDataItem] = useState(blankDataItem)
    const [playTime, setPlayTime] = useState([]);
    const [channel, setChannel] = useState([]);
    const [region, setRegion] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [showCollectionSelection, setShowCollectionSelection] = useState(false);
    const [currency, setCurrency] = useState([]);

    const lang = useTranslation();

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        let playTimeRes = await API.getDataLookup(ENTITYNAME.PlayTime, { sort: { Description: 1 } });
        setPlayTime(playTimeRes.data);
        let channelRes = await API.getValidChannels();
        setChannel(channelRes.data);
        let region = await API.getDataLookup(ENTITYNAME.Region, { sort: { Description: 1 } });
        setRegion(region.data);
        let currencyRes = await API.getDataLookup(ENTITYNAME.Currency, { sort: { Description: 1 } });
        setCurrency(currencyRes.data);
        setDataItem({ ...dataItem, Channel: [utility.getDefaultItem(channelRes.data)], Currency: utility.getDefaultItem(currencyRes.data) })
    }

    const onChange = (e) => {

        if (e.target.name == "IsUnlimited") {
            dataItem.Exhibitions = 0;
            dataItem.Repeats = 0;
            dataItem.PlayTimeNumber = 0;
            dataItem.PlayTime = {};
            setDataItem({ ...dataItem, IsUnlimited: !dataItem.IsUnlimited });
        }
        else if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, [e.target.name]: !dataItem[e.target.name] });
        } else if (e.target.name == 'IsExclusive') {
            setDataItem({ ...dataItem, IsExclusive: !dataItem.IsExclusive, ExclusiveStartDate: '', ExclusiveEndDate: '' });
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {
        if (!utility.isValidDate(dataItem.PublishStartDate)) {
            toast.error(lang.invalid_start_date, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!utility.isValidDate(dataItem.PublishEndDate)) {
            toast.error(lang.invalid_end_date, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.PublishStartDate >= dataItem.PublishEndDate) {
            toast.error(`${lang.end_date_should_be_greater_than_start_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        // for linear
        if (dataItem.Channel.length == 0) {
            toast.error(lang.please_select_channel_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Region.length == 0) {
            toast.error(`${lang.please_select_region_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Amount < 0) {
            toast.error(`${lang.amount_should_be_negative_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.RevShare < 0) {
            toast.error(`${lang.revenue_share_should_be_negative_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(dataItem.Currency).length == 0) {
            toast.error(`${lang.please_select_Currency_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!dataItem.IsUnlimited && dataItem.Exhibitions < 1) {
            toast.error(`${lang.exhibition_should_not_be_zero_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!dataItem.IsUnlimited && dataItem.Repeats < 1) {
            toast.error(`${lang.repeat_should_not_be_zero_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!dataItem.IsUnlimited && dataItem.PlayTimeNumber < 1) {
            toast.error(`${lang.play_time_number_should_not_be_zero_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!dataItem.IsUnlimited && Object.keys(dataItem.PlayTime).length == 0) {
            toast.error(`${lang.please_select_play_time_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.IsExclusive && !utility.isValidDate(dataItem.ExclusiveStartDate)) {
            toast.error(lang.please_select_start_date_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.IsExclusive && !utility.isValidDate(dataItem.ExclusiveEndDate)) {
            toast.error(lang.please_select_end_date_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const onSave = () => {

        if (!isValid()) return;

        if (gridData.length == 0) {
            toast.error(lang.please_attach_atleast_one_media_contract_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        let finalData = prepareFinalData();
        console.log(finalData);
        props.updateLinearGridData(finalData);
        props.onCancel();
    }

    const prepareFinalData = () => {

        let finalData = [];

        for (const element of gridData) {

            let obj = element;

            let data = {
                _id: undefined,
                ContractSID: 0,
                ContractNumber: '',
                Licensor: {},
                Payee: {},
                PublishingSource: PUBLISHING_SOURCE.Contract,
                ContractTitle: { media_id: obj._id, Title: obj.Title, AssetId: obj.AssetId },
                MediaCategory: {
                    _id: obj.MediaCategory._id,
                    SID: obj.MediaCategory.SID,
                    Description: obj.MediaCategory.Description,
                    isLong: obj.MediaCategory.isLong
                },
                PublishStartDate: new Date(dataItem.PublishStartDate).getTime(),
                PublishEndDate: new Date(dataItem.PublishEndDate).getTime(),
                PlatformType: {
                    _id: dataItem.PlatformType._id,
                    SID: dataItem.PlatformType.SID,
                    Description: dataItem.PlatformType.Description
                },
                Platform: [],
                Channel: dataItem.Channel.map(x => {
                    return {
                        _id: x._id,
                        SID: x.SID,
                        FullChannelName: x.FullChannelName,
                    }
                }) ?? [],
                Region: dataItem.Region.map(y => {
                    return {
                        _id: y._id,
                        SID: y.SID,
                        Description: y.Description
                    }
                }) ?? [],
                Amount: dataItem.Amount == "" ? 0 : parseFloat(dataItem.Amount),
                RevShare: parseFloat(dataItem.RevShare),
                Currency: dataItem?.Currency ? {
                    _id: dataItem.Currency._id,
                    SID: dataItem.Currency.SID,
                    Description: dataItem.Currency.Description
                } : {},
                IsUnlimited: dataItem.IsUnlimited ?? false,
                Exhibitions: !dataItem.IsUnlimited ? parseInt(dataItem.Exhibitions) : 0,
                Repeats: !dataItem.IsUnlimited ? parseInt(dataItem.Repeats) : 0,
                MinGap: dataItem.MinGap ? parseInt(dataItem.MinGap) : 0,
                PlayTimeNumber: !dataItem.IsUnlimited ? parseInt(dataItem.PlayTimeNumber) : 0,
                PlayTime: !dataItem.IsUnlimited ? { _id: dataItem.PlayTime._id, SID: dataItem.PlayTime.SID, Description: dataItem.PlayTime.Description } : {},
                IsExclusive: dataItem.IsExclusive,
                ExclusiveStartDate: dataItem.IsExclusive ? new Date(dataItem.ExclusiveStartDate).getTime() : 0,
                ExclusiveEndDate: dataItem.IsExclusive ? new Date(dataItem.PublishEndDate).getTime() : 0,
                BlackOutDates: [],
                Archive: false,
            }
            finalData.push(data);
        }

        return finalData;
    }

    const viewCollectionSelection = () => {
        setShowCollectionSelection(true);
    }

    const handleGridData = (dataList) => {
        // setGridData((old) => [...old, ...dataList.filter(x => !gridData.some(g => g.SID == x.SID))]);
        console.log(dataList)
        let newData = utility.checkDuplicateInData(dataList, gridData, "Title")
        setGridData([...gridData, ...newData]);
    }

    const onRemoveAsset = (dataItem) => {
        let filteredGridData = gridData.filter((obj) => obj._id != dataItem._id)
        setGridData(filteredGridData);
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    };

    const CommandCell = (props) => (
        <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => onRemoveAsset(props.dataItem), () => { })} />
    )

    return (
        <>
            <BossDialog title={lang.add_linear_page_header} onClose={props.onCancel} width={"800px"}>
                <div className="row">
                    <div className="col-12">
                        <EditPageHeader showTitle={false} onSubmit={onSave} onCancel={props.onCancel} />
                        <div className="row mt-2">
                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="">{lang.start_date_label} *</label>
                                    <input type="date" className="form-control form-control-sm" name="PublishStartDate" value={dataItem.PublishStartDate} onChange={(e) => onChange(e)} />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="">{lang.end_Date_label} *</label>
                                    <input type="date" className="form-control form-control-sm" name="PublishEndDate" value={dataItem.PublishEndDate} onChange={(e) => onChange(e)} />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="TabView">{lang.channel_label} *</label><br />
                                    <MultiSelect
                                        style={{ backgroundColor: "white" }}
                                        data={channel}
                                        name="Channel"
                                        textField="FullChannelName"
                                        dataItemKey="_id"
                                        value={dataItem.Channel}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="TabView">{lang.region_label} *</label><br />
                                    <MultiSelect
                                        style={{ backgroundColor: "white" }}
                                        textField="Description"
                                        dataItemKey="_id"
                                        data={region}
                                        name="Region"
                                        value={dataItem.Region}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                        </div>
                        <div className='row form-group'>
                            <div className="col-3">
                                <label htmlFor="">{lang.amount_label} *</label>
                                <input type="number" className="form-control form-control-sm" name="Amount" value={dataItem.Amount} onChange={(e) => onChange(e)} min={0} />
                            </div>
                            <div className="col-3">
                                <label htmlFor="">{lang.revenue_share_label}</label>
                                <input type="number" className="form-control form-control-sm" name="RevShare" value={dataItem.RevShare} onChange={(e) => onChange(e)} min={0} />
                            </div>
                            <div className="col-3">
                                <label htmlFor="">{lang.currency_label} *</label>
                                <DropDownList
                                    style={{
                                        backgroundColor: "white",
                                    }}
                                    data={currency}
                                    name="Currency"
                                    textField="Description"
                                    dataItemKey="_id"
                                    value={dataItem.Currency}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="col-3">
                                <div className="form-group">
                                    <label htmlFor="">{lang.minimum_gap_label} </label>
                                    <input type="Number" className="form-control form-control-sm" name="MinGap" value={dataItem.MinGap} onChange={onChange} min="0" required />
                                </div>
                            </div>
                        </div>
                        <div className="row form-group">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-6">
                                        <input type={"checkbox"} name={"IsUnlimited"}
                                            onChange={(e) => onChange(e)}
                                            value={dataItem.IsUnlimited}
                                            checked={dataItem.IsUnlimited} />
                                        <label className="ml-1">{lang.is_unlimited_label}</label>
                                    </div>
                                    <div className='col-6'>
                                        <input name="IsExclusive" type={"checkbox"} value={dataItem.IsExclusive} checked={dataItem.IsExclusive} onClick={onChange} onChange={() => { }} />
                                        <label className='ml-1'>{lang.exclusive_label}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-6">
                                {!dataItem.IsUnlimited && <div className="row">
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="">{lang.exhibition_label} *</label>
                                            <input type="Number" className="form-control form-control-sm" name="Exhibitions" value={dataItem.Exhibitions} onChange={onChange} min="0" required />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="">{lang.repeat_label} *</label>
                                            <input type="Number" className="form-control form-control-sm" name="Repeats" value={dataItem.Repeats} onChange={onChange} min="0" required />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="">{lang.play_time_number_label} *</label>
                                            <input type="Number" className="form-control form-control-sm" name="PlayTimeNumber" value={dataItem.PlayTimeNumber} onChange={onChange} min="0" required />
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <div className="form-group">
                                            <label htmlFor="TabView">{lang.playTime_label} *</label>
                                            <DropDownList
                                                style={{
                                                    backgroundColor: "white",
                                                }}
                                                data={playTime}
                                                name="PlayTime"
                                                textField="Description"
                                                dataItemKey="_id"
                                                value={dataItem.PlayTime}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                </div>}
                            </div>
                            <div className="col-6">
                                {dataItem.IsExclusive && <div className="row mb-3">
                                    <div className="col-6">
                                        <label htmlFor="">{lang.start_date_label} *</label>
                                        <input type={'date'} name={"ExclusiveStartDate"} value={dataItem.ExclusiveStartDate} onChange={onChange} max={dataItem.ExclusiveEndDate} className="form-control-sm"
                                            style={{ width: '100%', backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', paddingLeft: '5px' }} label={lang.start_date_label}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <label htmlFor="">{lang.end_Date_label} *</label>
                                        <input type={'date'} name={"ExclusiveEndDate"} onChange={onChange} value={dataItem.ExclusiveEndDate} min={dataItem.ExclusiveStartDate} className="form-control-sm"
                                            style={{ width: '100%', backgroundColor: 'white', border: '1px solid lightgrey', borderRadius: '5px', paddingLeft: '5px' }} label={lang.end_Date_label}
                                        />
                                    </div>
                                </div>}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-8">
                        <AddRoundButton style={{ marginBottom: '5px' }} onClick={viewCollectionSelection} title={lang.add_linear_button_tooltip} />
                    </div>
                    <div className="col-4">
                        <div className="pull-right">{lang.total_label} {gridData?.length ?? 0}</div>
                    </div>
                </div>
                <Grid style={{ height: '30vh', marginTop: "2px", overflow: "hidden" }}
                    data={gridData}>
                    <Column cell={CommandCell} width="40px" />
                    <Column field="Title" title={lang.title_column} width={'auto'} editable={false} />
                    <Column field="AssetId" title={lang.assetId_column} editable={false} />
                    <Column field="MediaCategory.Description" title={lang.media_category_column} editable={false} />
                    {/* <Column field="Channel" format="FullChannelName" cell={ArrayCell} width={'150px'} title={lang.channel_column} />
                    <Column field="Region" format="Description" cell={ArrayCell} width={'150px'} title={lang.region_column} />
                    <Column field="Amount" width={'150px'} title={lang.amount_column} />
                    <Column field="Currency.Description" width={'150px'} title={lang.currency_column} />
                    <Column field="PublishStartDate" title={lang.start_date_column} width={'150px'} />
                    <Column field="PublishEndDate" title={lang.end_date_column} width={'150px'} />
                    <Column field="Exhibition" title={lang.exhibition_column} editable={false} width={'150px'}/>
                    <Column field="Repeat" title={lang.repeat_column} width={'125px'} editable={false} />
                    <Column field="PlayTimeNumber" title={lang.play_time_number_column} width={'125px'} editable={false} />
                    <Column field="PlayTime" title={lang.playTime_column} cell={DropDownCell} width={'150px'} editable={false} />
                    <Column field="IsUnlimited" title={lang.is_unlimited_column} width={'105px'} editable={false} cell={CheckboxCell} />
                    <Column field="IsExclusive" title={lang.is_exclusive_column} width={'105px'} editable={false} cell={CheckboxCell} />
                    <Column field="ExclusiveStartDate" title={lang.exclusive_start_date_column} width={'150px'} />
                    <Column field="ExclusiveEndDate" title={lang.exclusive_end_date_column} width={'150px'} /> */}
                </Grid>
            </BossDialog>
            {showCollectionSelection && <ContractCollectionSelection addButtonTitle={lang.select_button_text} title={lang.media_library_dialog_header} wherestatement={['MediaCategory.isLong', '=', true]} setDataList={handleGridData} entityname={ENTITYNAME.MediaEpisode} closeForm={() => setShowCollectionSelection(false)} width={"50vw"} height={"66vh"} />}
        </>
    )
}
