import * as React from "react";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import moment from 'moment';
import { COLUMNSTYPE } from "../../constant/constant";
import { utility } from "../../utility/utilityProvider";
import { TimePickerWithFormat } from '../helpercomponents/timepicker/TimePickerWithFormat'
import { useTranslation } from "../../../locale/useTranslation";
import * as API from "../../API/api"
import BossDialog from "../../../components/BossDialog";
import ImagePreviewPage from "../../../components/ImagePreviewPage";
import PreviewRoundButton from "./buttons/PreviewRoundButton";


const EditForm = (props) => {

  //possible props
  //props.dataColumns
  //props.item
  //props.onSubmit
  //props.cancelEdit
  //props.entityname
  //props.showPopup
  //props.onChange

  const showPopup = props.showPopup ?? true;
  const lang = useTranslation();
  const [dataColumns, setDataColumns] = useState([]);
  const [dataItem, setDataItem] = useState(props.item.SID == 0 ? props.item : {
    ...props?.item,
    Archive: false,
    IsDefault: false,
  });
  const { register, formState: { errors }, control } = useForm();

  const [previewPageOpen, setPreviewPageOpen] = useState(false);
  const [imagePreview, setImagePreview] = useState(false);

  useEffect(() => {

    loadColumns()
    setEditItem();
    // eslint-disable-next-line
  }, [])

  const loadColumns = async () => {
    if(props.dataColumns && props.dataColumns.length > 0){
      setDataColumns(props.dataColumns)
    } else {
      const columns = await API.getFormData(props.entityname);
      setDataColumns(columns)
    }
  }

  const handleChange = (e) => {
    if (e.target.name == 'Archive') {
      let changeData = { ...dataItem, Archive: !dataItem.Archive };
      setDataItem(changeData);
      if (props.onChange) {

        let saveData = {
          SID: changeData.SID,
          Archive: changeData.Archive ?? false
        }
        dataColumns.map((col) => {
          if (col.type == 'time') {
            saveData = utility.getObjectWithNestedKeySperatedByDot(saveData, col.name, utility.convertStringWithFramesToMilliseconds(utility.getDataInsideObject(changeData, col.name)));

          } else if (col.type == 'date') {
            saveData = utility.getObjectWithNestedKeySperatedByDot(saveData, col.name, new Date(utility.getDataInsideObject(changeData, col.name)).getTime());
          } else {
            saveData = utility.getObjectWithNestedKeySperatedByDot(saveData, col.name, utility.getDataInsideObject(changeData, col.name));
          }
        })
        props.onChange(saveData);

      }
    } else if (e.target.name == 'IsDefault') {
      let changeData = { ...dataItem, IsDefault: !dataItem.IsDefault };
      setDataItem(changeData);
      if (props.onChange) {

        let saveData = {
          SID: changeData.SID,
          IsDefault: changeData.IsDefault ?? false
        }
        dataColumns.map((col) => {
          if (col.type == 'time') {
            saveData = utility.getObjectWithNestedKeySperatedByDot(saveData, col.name, utility.convertStringWithFramesToMilliseconds(utility.getDataInsideObject(changeData, col.name)));

          } else if (col.type == 'date') {
            saveData = utility.getObjectWithNestedKeySperatedByDot(saveData, col.name, new Date(utility.getDataInsideObject(changeData, col.name)).getTime());
          } else {
            saveData = utility.getObjectWithNestedKeySperatedByDot(saveData, col.name, utility.getDataInsideObject(changeData, col.name));
          }
        })
        props.onChange(saveData);
      }
    }
    else {
      let changeData = utility.getObjectWithNestedKeySperatedByDot(dataItem, e.target.name, e.target.value);

      setDataItem(changeData);

      if (props.onChange) {
        let saveData = {
          SID: changeData.SID,
          Archive: changeData.Archive ?? false
        }
        dataColumns.map((col) => {



          if (col.type == 'time') {
            saveData = utility.getObjectWithNestedKeySperatedByDot(saveData, col.name, utility.convertStringWithFramesToMilliseconds(utility.getDataInsideObject(changeData, col.name)));
          } else if (col.type == 'date') {
            saveData = utility.getObjectWithNestedKeySperatedByDot(saveData, col.name, new Date(utility.getDataInsideObject(changeData, col.name)).getTime());
          } else {
            saveData = utility.getObjectWithNestedKeySperatedByDot(saveData, col.name, utility.getDataInsideObject(changeData, col.name));
          }

        })
        props.onChange(saveData);
      }
    }
  }

  function setEditItem() {

    var dataItemLocal = { ...props.item };

    if (props.item.SID > 0) {

      dataColumns.map((col) => {
        if (col.type == 'time') {
          dataItemLocal = {
            ...dataItemLocal,
            [col.name]: utility.convertMilisecondsToStringWithFrames(props.item[col.name])
          }
        } else if (col.type == 'date') {
          dataItemLocal = {
            ...dataItemLocal,
            [col.name]: moment(new Date(props.item[col.name])).format('YYYY-MM-DD'),
          }
        } else {
          dataItemLocal = {
            ...dataItemLocal,
            [col.name]: props.item[col.name]
          }
        }
      })

    } else {
      dataItemLocal = props.item;
    }
    setDataItem(dataItemLocal);
  }

  const handleSubmit = async (e) => {

    if (dataItem.Archive == undefined) {
      dataItem.Archive = false
    }
    if (dataItem.IsDefault == undefined) {
      dataItem.IsDefault = false
    }
    let saveData = {
      _id: dataItem._id,
      SID: dataItem.SID,
      Archive: dataItem.Archive ?? false,
      IsDefault: dataItem.IsDefault ?? false
    }

    dataColumns.map((col) => {
      if (col.type == 'time') {
        saveData = utility.getObjectWithNestedKeySperatedByDot(saveData, col.name, utility.convertStringWithFramesToMilliseconds(utility.getDataInsideObject(dataItem, col.name)));

      } else if (col.type == 'date') {
        saveData = utility.getObjectWithNestedKeySperatedByDot(saveData, col.name, new Date(utility.getDataInsideObject(dataItem, col.name)).getTime());
      } else {
        saveData = utility.getObjectWithNestedKeySperatedByDot(saveData, col.name, utility.getDataInsideObject(dataItem, col.name));
      }
    })
    props.onSubmit(e, saveData);
    showPopup && utility.deleteLocalStorageItem(props.entityname);
    showPopup && props.cancelEdit();
  }

  const onClosedPreview = async () => {
    setPreviewPageOpen(false);

}

  const getInput = (column, index) => {
    //for reactforms hooks
    //https://react-hook-form.com/get-started

    switch (column.type) {
      case COLUMNSTYPE.input:
        return <div className="form-group row" key={index}>

          <div className="col">
            <label htmlFor={column.name}>{column.label}</label>
            <input key={index} className="form-control" type="text" id={column.name} style={{ height: "35px" }} defaultValue={utility.getDataInsideObject(props.item, column.name)} {...register(column.name, { required: column.required === 1, maxLength: 100 })} onChange={(e) => { handleChange(e); }} required />
            {errors[column.name] && <small id={column.name + 'help'} className="form-text text-muted">{lang.please_check_label} {column.label}</small>}
          </div>
        </div>

      case COLUMNSTYPE.hidden:
        return <input key={index} className="form-control" type="hidden" onChange={(e) => handleChange(e)} id={column.name} defaultValue={utility.getDataInsideObject(props.item, column.name)} {...register(column.name)} />

      case COLUMNSTYPE.date:

        var value = moment(utility.getDataInsideObject(props.item, column.name)).utc().format('YYYY-MM-DD')

        return <div className="form-group row" key={index}>

          <div className="col">
            <label htmlFor={column.name}>{column.label}</label>
            <input key={index} className="form-control" type="date" onChange={(e) => handleChange(e)} id={column.name} defaultValue={value} {...register(column.name, { required: column.required === 1, maxLength: 100 })} />
            {errors[column.name] && <small id={column.name + 'help'} className="form-text text-muted">{lang.please_check_label} {column.label}</small>}
          </div>
        </div>

      case COLUMNSTYPE.number:
        return <div className="form-group row" key={index}>

          <div className="col">
            <label htmlFor={column.name}>{column.label + " :"}</label>
            <input key={index} className="form-control" type="number" onChange={(e) => handleChange(e)} id={column.name} defaultValue={utility.getDataInsideObject(props.item, column.name)} {...register(column.name, { required: column.required === 1, maxLength: 100 })} />
            {errors[column.name] && <small id={column.name + 'help'} className="form-text text-muted">{lang.please_check_label} {column.label}</small>}
          </div>
        </div>
      case COLUMNSTYPE.time:
        return <div className="form-group row" key={index}>

          <div className="col">
            <label htmlFor={column.name}>{column.label + " :"}</label>
            <TimePickerWithFormat name={column.name} onChange={handleChange} id={column.name} value={utility.getDataInsideObject(dataItem, column.name)} />
            {errors[column.name] && <small id={column.name + 'help'} className="form-text text-muted">{lang.please_check_label} {column.label}</small>}
          </div>
        </div>
      // case 'select':
      //   return ComboBox;
      case COLUMNSTYPE.checkbox:
        return <div className="form-group row" key={index}>

          <div className="col">
            <label htmlFor={column.name}>{column.label + " :"}</label>
            <input key={index} name={column.name} className="form-control" type="checkbox" onChange={handleChange} id={column.name} value={utility.getDataInsideObject(dataItem, column.name)} defaultValue={utility.getDataInsideObject(dataItem, column.name)} checked={utility.getDataInsideObject(dataItem, column.name)} style={{ width: '15px', paddingBottom: '30px' }} />
            {errors[column.name] && <small id={column.name + 'help'} className="form-text text-muted">{lang.please_check_label} {column.label}</small>}
          </div>
        </div>

        case COLUMNSTYPE.image:
          return <div className="form-group row" key={index} style={{ gap: '1px',display: "flex", padding: "0px" }}>

            <div className="col">
              <label htmlFor={column.name}>{column.label}</label>
             <input key={index} className="form-control" type="text" id={column.name} style={{ height: "35px" }} defaultValue={utility.getDataInsideObject(props.item, column.name)} {...register(column.name, { required: column.required === 1, maxLength: 100 })} onChange={(e) => { handleChange(e); }} required />
              {errors[column.name] && <small id={column.name + 'help'} className="form-text text-muted">{lang.please_check_label} {column.label}</small>}
            </div>
            <div className="mt-4 mr-3">
              <PreviewRoundButton onClick={(e) => { setPreviewPageOpen(true); setImagePreview(utility.getDataInsideObject(props.item, column.name)) }} />
            </div>
            
          </div>
      // case 'email':
      //   return Input;
      // case 'textarea':
      //   return TextArea;
      // case 'time':
      //   return TimePicker;
      // case 'label':
      //   return Text;
      default:
        return <>
        </>;

    }

  }


  return showPopup ? <BossDialog title={`Edit Data`} onClose={props.cancelEdit}>
    <div className='mar'>
      <form onSubmit={handleSubmit}>

        {dataColumns.map((column, index) => (
          !column.name.includes("addBy") && !column.name.includes("modBy") && !column.name.includes("addOn") && !column.name.includes("modOn") && getInput(column, index)
        ))}

        <input type="submit" className="btn btn-primary marlr" />
        <input type="button" onClick={props.cancelEdit} value="Close" className="btn btn-cancel marlr" />


      </form>
    </div>



  </BossDialog> : <>

    <div className="row">
      {props.dataColumns.map((column, index) => {
        return <div className="col-6">
          {getInput(column, index)}
        </div>
      })}
    </div>
    { previewPageOpen &&
      <ImagePreviewPage image={imagePreview} onClose={onClosedPreview}></ImagePreviewPage>
    }
  </>
};

export default EditForm;