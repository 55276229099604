import { DropDownList } from '@progress/kendo-react-dropdowns'
import React, { useState } from 'react'
import { useTranslation } from '../../../locale/useTranslation'
import { toast } from 'react-toastify';
import DeleteRoundButton from '../../../framework/forms/helpercomponents/buttons/DeleteRoundButton';
import { ConfirmDeleteAlert } from '../../../ConfirmAlert';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { OPERATORS, RESTRICTIONS_OPERATORS } from '../../../framework/constant/constant';
import AddRoundButton from '../../../framework/forms/helpercomponents/buttons/AddRoundButton';

const IngestionTemplateRestrictionSection = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        column: '',
        operator: '',
        value: ''
    }

    const [dataItem, setDataItem] = useState(blankDataItem);

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {

        if (!dataItem.column) {
            toast.error(`${lang.please_select_column_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (!dataItem.operator || Object.keys(dataItem.operator).length == 0) {
            toast.error(`${lang.please_select_operator_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (!dataItem.value || dataItem.value.length == 0) {
            toast.error(`${lang.please_enter_value_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        return true;
    }

    const onAddResctriction = () => {
        //CHECK VALIDATION
        if (!isValid()) return;

        console.log(dataItem)

        props.setRestrictions([...props.restrictions, dataItem]);
        setDataItem(blankDataItem);
    }

    const onDeleteRestriction = (index) => {
        let tempRestrictions = [...props.restrictions];
        tempRestrictions.splice(index, 1)
        props.setRestrictions(tempRestrictions);
    }

    const MyCommandCell = (props) => (
        <div className="m-2" style={{ display: "flex" }}>
            <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => onDeleteRestriction(props.dataIndex), () => { })} />
        </div>
    )

    return (

        <div key={"9999"} className="col-12" style={{ padding : "0px"}}>
            <fieldset style={{ border: "1px solid #ccc", padding: "15px", borderRadius: "5px" }}>
                <legend style={{ padding: "0 10px", fontSize: "1.05em" }}>Restrictions</legend>
                <div className="row">
                    <div className={`form-group ${props.isAPISelected ?  "col-2" : "col-3"}`}>
                        <label htmlFor="">{lang.column_label}</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={props.keysList}
                            name={"column"}
                            textField="label"
                            value={dataItem.column}
                            onChange={onChange}
                        />
                    </div>
                    <div className={`form-group ${props.isAPISelected ?  "col-2" : "col-1"}`}>
                        <label htmlFor="">{lang.operator_label} </label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={RESTRICTIONS_OPERATORS}
                            name={"operator"}
                            textField="Operator"
                            value={dataItem.operator}
                            onChange={onChange}
                        />
                    </div>
                    <div className={`form-group ${props.isAPISelected ?  "col-2" : "col-3"}`}>
                        <label htmlFor="TabView">{lang.value_label} </label>
                        <input
                            type="text"
                            className="form-control form-control-sm"
                            name="value"
                            value={dataItem.value}
                            onChange={onChange}
                        />
                    </div>
                    <div style={{ marginTop: "20px" }}>
                        <AddRoundButton onClick={onAddResctriction} />
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Grid data={props.restrictions} style={{ height: "21vh" }}>
                            <GridColumn cell={MyCommandCell} width={"50px"} locked={true} />
                            <GridColumn field="column.label" title={lang.column_label} editable={false} />
                            <GridColumn field="operator.Description" title={lang.operator_column} editable={false} />
                            <GridColumn field="value" title={lang.value_column} editable={false} />
                        </Grid>
                    </div>
                </div>
            </fieldset>
        </div>

    )
}

export default IngestionTemplateRestrictionSection