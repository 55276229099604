import { TimePickerWithFormat } from "../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat"
import { utility } from "../../framework/utility/utilityProvider"
import { Grid, GridColumn } from "@progress/kendo-react-grid"
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton"
import { myTimeCellMilliseconds } from "../../framework/forms/helpercomponents/inline grid cell/MyTimeCellInMillisconds"
import { Input } from "@progress/kendo-react-inputs"
import BossDialog from "../../components/BossDialog"

export const VirtualSegmentationPopup = ({
    onCancelVirtualSegmentationPopup,
    onVirtualSegmentationSplitClick,
    onSaveVirtualSegmentation,
    inlineEdit,
    virtualSegmentationData,
    setVirtualSegmentationData,
    selectedSegment

}) => {
    return <BossDialog
        title={'Virtual Segmentation'}
        width={"550px"}
        onClose={onCancelVirtualSegmentationPopup}

    >
        <div className='row mb-2'>
            <div className="col-4">
                <label>Title : </label>
            </div>
            <div className="col-7">
                <Input disabled={true} value={selectedSegment.mediaEpisode
                    .Title} />
            </div>
        </div>

        <div className='row mb-2'>
            <div className="col-4">
                <label>Slot Time : </label>
            </div>
            <div className="col-7">
                <TimePickerWithFormat disabled={true} value={utility.convertMilisecondsToStringWithFrames(selectedSegment.SlotDateTime)} />
            </div>
        </div>

        <div className='row mb-2'>
            <div className="col-4">
                <label>Duration : </label>
            </div>
            <div className="col-7">
                <TimePickerWithFormat value={utility.convertMilisecondsToStringWithFrames(selectedSegment.Duration)} disabled={true} />
            </div>
        </div>

        <div className='row mb-2'>
            <div className="col-4">
                <label>No. of segments : </label>
            </div>
            <div className="col-2">
                <Input type="number" min={2} value={virtualSegmentationData.noOfSeg} onChange={(e) => {
                    setVirtualSegmentationData({ ...virtualSegmentationData, noOfSeg: e.target.value })
                }} />
            </div>
            <div className="col-6">
                <ActionButton title={'Split'} name={'Split'} onClick={onVirtualSegmentationSplitClick} />
            </div>
        </div>

        <Grid
            className="mb-3"
            style={{ height: "150px" }}
            data={virtualSegmentationData.gridData}
            resizable={true}
            editField="inEdit"
            onItemChange={inlineEdit}
        >
            <GridColumn title="Title" field="mediaEpisode.Title" />
            <GridColumn title="MediaId" field="VirtualSegmentKey" />
            <GridColumn title="Duration" field="Duration" cell={myTimeCellMilliseconds} editable={true} />
        </Grid>

        <div className='row mx-1'>
            <label >Difference Duration : </label><label className={`pl-1 ${virtualSegmentationData.diffDur == "00:00:00:00" ? 'text-success' : 'text-danger'}`} > {virtualSegmentationData.diffDur} </label>
            <label className="pl-4">Total Duration : {virtualSegmentationData.totalDur}</label>
        </div>

        <ActionButton btnColor={"success"} style={{ marginTop: "20px", marginRight: "10px", float: "right" }} title={'Save'} name={'Save'} onClick={onSaveVirtualSegmentation} />
        <ActionButton btnColor={'danger'} style={{ marginTop: "20px", marginRight: "10px", float: "right" }} title={'Cancel'} name={'Cancel'} onClick={onCancelVirtualSegmentationPopup} />
    </BossDialog>
}