/* eslint-disable */
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { memo, useEffect, useState } from "react";
import * as API from '../../../framework/API/api'
import { ENTITYNAME, LOCALSTORAGE_KEY, MEDIACATEGORIES, PLATFORMTYPE } from "../../../framework/constant/constant";
import RoundButton from "../../../framework/forms/helpercomponents/buttons/RoundButton";
import { useTranslation } from "../../../locale/useTranslation";
import { ScheduleFilter } from "../../Scheduling/SchedulingFilter";
import { utility } from "../../../framework/utility/utilityProvider";
import PreferenceRoundButton from "../../../framework/forms/helpercomponents/buttons/PreferenceRoundButton";
import { UserPreference } from "../../userPreference/UserPreference";
import { MediaFilterTemplate } from "../../Planning/MediaFilterTemplate";
import { DataHelper } from "../../../framework/helper/DataHelper";

const ManageContentHeader = (props) => {

    const lang = useTranslation();

    const textStyle = { padding: "0 5px", display: "inline-block", fontSize: "13px", fontWeight: '400', marginBottom: "0px" }
    const buttonStyle = { padding: "0 5px", display: "inline-block", backgroundColor: "white", height: '30px', width: "150px" }

    const [mediaCategories, setMediaCategories] = useState([]);
    const [mediaCategory, setMediaCategory] = useState({});
    const [showFilterDropdown, setShowFilterDropDown] = useState(false);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [templates, setTemplates] = useState([]);
    const [filterPopupOpen, setFilterPopupOpen] = useState({
        isLong: false,
        isShort: false
    });
    const [isFilterCleared, setIsFilterCleared] = useState(false);
    const [planningFilterTemplate, setPlanningFilterTemplate] = useState([]);
    const [schedulingFilterTemplate, setSchedulingFilterTemplate] = useState([]);
    const [viewMode, setViewMode] = useState(1);
    const [editMode, setEditMode] = useState(true);
    const [showPreferences, setShowPreferences] = useState(false);
    const [filterDataItem, setFilterDataItem] = useState({});
    const entityName = mediaCategory?.SID == MEDIACATEGORIES.Series ? ENTITYNAME.Series : mediaCategory?.SID == MEDIACATEGORIES.Season ? ENTITYNAME.Season : ENTITYNAME.MediaEpisode;
    const preferenceEntityName = mediaCategory?.SID == MEDIACATEGORIES.Trailer ? "program" : mediaCategory?.Description?.toLowerCase();

    useEffect(() => {
        loadCombo();
    }, [])

    const loadCombo = async () => {

        let mediaCategoryRes = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { SID: 1 } });
        if (mediaCategoryRes.success && mediaCategoryRes.data.length > 0) {
            setMediaCategories(mediaCategoryRes.data);
            setMediaCategory(mediaCategoryRes.data[0]);
            // filter DropDown
            let planningFilterTemplateRes = await API.getDataLookup(ENTITYNAME.PlanningFilterTemplate, { sort: { Name: 1 } });
            setPlanningFilterTemplate(planningFilterTemplateRes.data);
            let schedulingFilterTemplateRes = await API.getDataLookup(ENTITYNAME.SchedulingFilterTemplate, { sort: { Name: 1 } });
            setSchedulingFilterTemplate(schedulingFilterTemplateRes.data);

            let temp = mediaCategoryRes.data[0].isLong ? planningFilterTemplateRes.data : schedulingFilterTemplateRes.data;
            let userId = utility.getValue(LOCALSTORAGE_KEY.user_id)
            let filteredTemplate = temp.filter((x) => x.FilterModel.MediaCategory.some((y) => y.SID == mediaCategoryRes.data[0].SID) && (x.user_id == 0 || x.user_id == userId))
            setTemplates(filteredTemplate);
        } else {
            setMediaCategories([]);
            setMediaCategory({});
            setTemplates([]);
        }
    }

    const onLoad = () => {
        setShowFilterDropDown(false);
        props.onLoad(mediaCategory);
    }

    const onChange = async (e) => {
        setMediaCategory(e.target.value);
        let temp = e.target.value?.isLong ? planningFilterTemplate : schedulingFilterTemplate;
        let filteredTemplate = temp.filter((x) => x.FilterModel.MediaCategory.some((y) => y.SID == e.target.value?.SID));
        setTemplates(filteredTemplate);
        setSelectedTemplate({});
    }

    const onExport = () => {
        props.onExport(mediaCategory);
    }

    const onEditFilterTemplate = () => {
        setIsFilterCleared(false);
        setEditMode(true);
        setFilterPopupOpen(mediaCategory.isLong ? { isShort: false, isLong: true } : { isLong: false, isShort: true });
    }

    const onShowFilterTemplate = () => {
        setSelectedTemplate({});
        setShowFilterDropDown(!showFilterDropdown);
        showFilterDropdown && props.setFilterTemplate([]);
    }

    const onFilterTemplateChange = (e) => {
        setSelectedTemplate(e.target.value);
        if (mediaCategory.isLong) {
            toGetFilteredData(e.target.value.FilterModel);
        } else {
            toGetFilteredDataShortMedia(e.target.value.FilterModel);
        }
    }

    const toGetFilteredData = (data) => {
        console.log(data);
        setFilterDataItem(data);
        // Filter Query
        let finalFilterQuery = DataHelper.getMediaFilterQuery(data, entityName);
        console.log(finalFilterQuery);
        props?.setFilterTemplate(finalFilterQuery);
    }

    const onClickAdvancedFilter = () => {
        setFilterPopupOpen(mediaCategory.isLong ? { isShort: false, isLong: true } : { isLong: false, isShort: true });
        setSelectedTemplate({});
        setEditMode(false);
    }

    let timeOut;
    const onSearch = (e) => {
        clearTimeout(timeOut);
        timeOut = setTimeout(() => {
            props?.setSearchText(e.target.value);
        }, 700);
    }

    const toGetFilteredDataShortMedia = (data) => {
        console.log(data);
        let finalFilterQuery = [];
        if (Object.keys(data).length != 0) {
            let mediaCategoryFilter = data.MediaCategory.length != 0 ? [["MediaCategory._id", 'in', data.MediaCategory.map((item) => item._id)]] : [];
            let mediaCategoryTypeFilter = data.MediaCategoryType.length != 0 ? [["MediaCategoryType._id", 'in', data.MediaCategoryType.map((item) => item._id)]] : [];
            let productFilter = data.Product.length != 0 ? [["Product._id", 'in', data.Product.map((item) => item._id)]] : [];
            let brandFilter = data.Brand.length != 0 ? [["Brand._id", 'in', data.Brand.map((item) => item._id)]] : [];
            let durationFilter = data.Duration.Condition > 0 ? ["Duration", `${data.Duration.Operator}`, data.Duration.Condition] : []
            let createdFromFilter = data?.CreatedFrom != "" ? [["addOn", ">=", utility.convertStringDatetoMilliseconds(data.CreatedFrom)]] : [];
            let createdToFilter = data?.CreatedTo != "" ? [["addOn", "<=", utility.convertStringEndDateToMilliSeconds(data.CreatedTo)]] : [];

            finalFilterQuery = [...mediaCategoryFilter, ...mediaCategoryTypeFilter, ...productFilter, ...brandFilter, durationFilter, ...createdFromFilter, ...createdToFilter];
        }
        props?.setFilterTemplate(finalFilterQuery);
    }

    const handleView = () => {
        setViewMode(!viewMode);
        props?.setViewMode(viewMode);
    }

    const onCloseUserPrefence = () => {
        onLoad();
        setShowPreferences(!showPreferences);
    }

    return (
        <div style={{ display: "flex", alignItems: "center", backgroundColor: "#F4F5FA", border: "1px solid #ededed", overflow: 'auto', padding: '10px', height: "80px", overflowY: "hidden" }}>
            <div className="flex-container">
                <div style={{ marginRight: "15px" }}>
                    <h5 style={textStyle}>Media Category :</h5><br />
                    <DropDownList
                        style={buttonStyle}
                        data={mediaCategories}
                        dataItemKey="_id"
                        textField="Description"
                        value={mediaCategory}
                        onChange={onChange}
                        name="mediaCategory"
                    />
                </div>
                <RoundButton btnColor={'primary'} style={{ alignSelf: "end", margin: "16px 10px", color: "whitesmoke" }} icon={'eye'} title={'Load'} name={'Load'} onClick={onLoad} />
                <RoundButton btnColor={'warning'} style={{ alignSelf: "end", margin: "16px 0px", color: "whitesmoke" }} icon={'download'} title={'Export'} name={'Export'} onClick={onExport} />
                <div className="flex-container-reverse flex-item-auto mt-3">
                    <div className="m-1 ml-2" style={{ paddingTop: "9px" }}><PreferenceRoundButton
                        icon={"gear"}
                        title={lang.preference_button_tooltip}
                        onClick={() => { setShowPreferences(!showPreferences) }}
                    /></div>
                    {mediaCategory.isLong && <div style={{ marginTop: "20px", marginLeft: '10px', cursor: "pointer", fontSize: '12px' }} onClick={handleView} title={viewMode ? lang.grid_view_label : lang.time_line_view_tooltip}><i className={`fas ${viewMode ? 'fa-table-cells' : 'fa-timeline'} fa-xl`} /></div>}

                    {showFilterDropdown && <div className="input-group w-300 martb">
                        <DropDownList
                            style={{ width: selectedTemplate && Object.keys(selectedTemplate).length > 0 ? '200px' : '250px' }}
                            data={templates}
                            name="Template"
                            textField="Name"
                            dataItemKey="_id"
                            value={selectedTemplate}
                            onChange={onFilterTemplateChange}
                            title="Filter Template"
                        />
                        {selectedTemplate && Object.keys(selectedTemplate).length > 0 && <button title="Edit Filter Template" style={{ border: "0px", backgroundColor: "white", height: "40px", marginLeft: "5px" }} onClick={onEditFilterTemplate} aria-label="Edit Template">
                            <div className="input-group-append" >
                                <span className="input-group-text" style={{ backgroundColor: "white" }}>
                                    <i style={{ margin: "5px 0px", color: "black" }} className="fa fa-pencil" />
                                </span>
                            </div>
                        </button>}
                        <button title="Advanced Search" style={{ border: "0px", backgroundColor: "white", height: "40px", marginLeft: "5px" }} onClick={() => onClickAdvancedFilter()} aria-label="Advanced Search">
                            <div className="input-group-append" >
                                <span className="input-group-text" style={{ backgroundColor: isFilterCleared ? "black" : "white" }}>
                                    <i style={{ margin: "5px 0px", color: isFilterCleared ?  "white" : "black" }} className="fa fa-list" />
                                </span>
                            </div>
                        </button>
                    </div>}
                    <div className="input-group w-300 martb" style={{ margin: '10px 5px 10px 0px' }}>
                        <input
                            type="text"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="searchInput"
                            placeholder={lang.search_button_text}
                            onChange={onSearch}
                            onFocus={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(true);
                                }
                            }}
                            onBlur={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(false);
                                }
                            }}
                        />
                        <button title={showFilterDropdown ? "Clear Filters" : "Filters"} style={{ border: "0px", backgroundColor: "white", height: "40px", marginLeft: "0px" }} onClick={onShowFilterTemplate} aria-label="Advanced Search">
                            <div className="input-group-append" >
                                <span className="input-group-text" style={!showFilterDropdown ? { backgroundColor: "white" } : { backgroundColor: "black" }}>
                                    <i style={{ margin: "4px 0px", color: !showFilterDropdown ? "black" : "white" }} className="fa fa-filter" />
                                </span>
                            </div>
                        </button>
                    </div>

                </div>
            </div>
            {filterPopupOpen.isShort && <ScheduleFilter
                data={selectedTemplate?.FilterModel}
                toGetFilterDataItem={toGetFilteredDataShortMedia}
                isFilterCleared={setIsFilterCleared}
                closeFilterPopup={() => { setFilterPopupOpen({ isShort: false, isLong: false }) }}
                height={"38px"}
                disableMediaCategory={true}
                onClear={() => { }}
                mediaCategory={mediaCategory?.SID}
                templateData={templates}
                setSelectedTemplate={setSelectedTemplate}
                selectedTemplate={selectedTemplate._id == 0 ? null : selectedTemplate}
            />}
            {filterPopupOpen.isLong && <MediaFilterTemplate
                filterDataItem={filterDataItem}
                showTemplate={false}
                toGetFilterDataItem={toGetFilteredData}
                isFilterCleared={setIsFilterCleared}
                closeFilterPopup={() => setFilterPopupOpen({ isShort: false, isLong: false })}
                disableMediaCategory={true}
                mediaCategorySID={mediaCategory?.SID}
                isNonLinearFileds={true}
                editMode={editMode}
                setSelectedTemplate={setSelectedTemplate}
                selectedTemplate={selectedTemplate}
                onClear={() => { setEditMode(false); setIsFilterCleared(false) }}
                setTemplates={setTemplates}
            />}
            {showPreferences && <UserPreference entityName={entityName} preferenceColumnENntityName={preferenceEntityName} handleClose={onCloseUserPrefence} />}

        </div>
    );
}

export default memo(ManageContentHeader);