//for using inline cell in grid when updating data inline 
import { MaskedTextBox } from "@progress/kendo-react-inputs";
import { utility } from "../../../utility/utilityProvider";
export const myTimeCellMilliseconds = (props) => {

  return (
    <td>
      <MaskedTextBox
        {...props}
        style={{ border: '0px none' }}
        mask="00:00:00:00"
        value={utility.convertMilisecondsToStringWithFrames(props.dataItem[props.field]).replaceAll('_', '0')}
        onChange={e => props.onChange({
          dataItem: props.dataItem,
          field: props.field,
          value: utility.validateTimeString(e.value.replaceAll('_', '0')),
        })}
      />
    </td>
  );
}