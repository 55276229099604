/* eslint-disable */
import React, { useState, useEffect} from "react";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { ENTITYNAME } from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import { utility } from "../../framework/utility/utilityProvider";
import { Loader } from "../../framework/forms/helpercomponents/Loader";

const ManageAsRun = () => {

    const defaultDates = utility.getFirstAndLastDateOfMonth();

    const [dataItem, setDataItem] = useState({
        FromDate : defaultDates.FirstDate,
        ToDate : defaultDates.LastDate
    });
    const [channel, setChannel] = useState([]);
    const [gridData,setGridData] = useState([]);
    const [isLoading,setIsLoading] = useState(false);
    const lang=useTranslation();

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {    
        let channelRes = await API.getDataLookup(ENTITYNAME.Channel);
        setChannel(channelRes.data);
        setDataItem({...dataItem , Channel : utility.getDefaultItem(channelRes.data)})
    }

    const onChange = (e) => {
        setDataItem({...dataItem, [e.target.name]: e.target.value });
    }

    const getFileNames = async() => {
        setGridData([]);

        if(dataItem.Channel == undefined){
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
              });
            return;
        }

        if(dataItem.FromDate == undefined || new Date(dataItem.FromDate).getTime() < 0){
            toast.error(`${lang.please_select_from_date}`, {
                position: toast.POSITION.TOP_RIGHT
              });
            return;
        }

        if(dataItem.ToDate == undefined || new Date(dataItem.ToDate).getTime() <= 0 || new Date(dataItem.ToDate).getTime() <= new Date(dataItem.FromDate).getTime()){
            toast.error(`${lang.please_select_to_date_greater_than_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
              });
            return;
        }
        setIsLoading(true);

        let data = {
            Channel : dataItem.Channel,
            FromDate : new Date(dataItem.FromDate).getTime(),
            ToDate : new Date(dataItem.ToDate).getTime()
        }
        
        const res = await API.loadImportedAsRun(data);
        console.log(res);

        //setting timer to cancel isLoading screen if no record found
        res.data.length == 0 && setTimeout(() => setIsLoading(false),3000);
        if(res.data.length == 0) return;
        let tempData = [];
        res.data.map((item) => tempData.push({ImportFileName : item}))
        setGridData(tempData)
    }

    const deleteAsRunFile = async(item) => {
        console.log(item);
        const res = await API.deleteImportedAsRunFile(item);
        if(!res.success){
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
              });
              setIsLoading(false);
        }else{
            let newData = gridData.filter((obj) => obj.ImportFileName != item.ImportFileName);
            setGridData(newData);
            toast.success(res.message, {
                position: toast.POSITION.TOP_RIGHT
              });
              setIsLoading(false);
        }
    }

    const MyCommandCell = (props) => (
        <DeleteRoundButton onClick={()=>ConfirmDeleteAlert(()=>deleteAsRunFile(props.dataItem),()=>{})}/>
      );

    return <div>
        
          <>
            <h1 className="page-title txt-color-bludeDark">
              <i
                className="fa fa-table marlr"
                style={{ fontSize: "20px", color: "GrayText" }}
              ></i>
              <span>{'Manage AsRun'}</span>
            </h1>
          </>
        
       
        <div className="row" style={{height:"08vh", fontSize:"10px", margin:"2px"}}>
            <div className="col-10">
                <div className="row">
                    <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="TabView">{lang.channel_label}</label>
                                    <DropDownList
                                    style={{
                                    backgroundColor:"white",
                                    }}
                                    data={channel}
                                    name="Channel"
                                    textField="FullChannelName"
                                    dataItemKey="_id"
                                    value={dataItem.Channel}
                                    onChange={onChange}
                                    validator={(value) => value ? "" : "Please select the value"}
                                />
                            </div>                                    
                    </div>
                    <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="">{lang.from_date_label} </label>
                                <input
                                    type="date"
                                    className="form-control form-control-sm"
                                    name="FromDate"
                                    value={dataItem.FromDate}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                        <div className="col-3">
                            <div className="form-group">
                                <label htmlFor="">{lang.to_date_label}</label>
                                <input
                                    type="date"
                                    className="form-control form-control-sm"
                                    name="ToDate"
                                    value={dataItem.ToDate}
                                    onChange={onChange}
                                />
                            </div>
                        </div>
                       
                        <div className="col-3" style={{marginTop:"20px"} }>
                            <ActionButton title={lang.load_button_tooltip} name={lang.load_button_text} onClick = {getFileNames}  />                                    
                        </div>                        
                </div>
            </div>
        </div>
   
        {gridData.length == 0 && isLoading ?
        <Loader />:  <Grid data={gridData} style={{ margin:"0px 10px 10px 10px",borderRadius: '5px!important',height: "75vh", width:"83.4vw"}}>
            <Column cell={MyCommandCell} locked={true} width={'35px'} />
            <Column field="ImportFileName" title={lang.import_file_name_column}  />
        </Grid>}
    </div>
}




export default ManageAsRun;

