
import { Outlet, useNavigate } from 'react-router-dom';
import Navbar from '../../modules/Navbar/Navbar'
import Header from '../Navbar/header';
import { useState, useEffect, useContext, useTransition, createContext } from "react";
import { utility } from '../../framework/utility/utilityProvider';
import { LOCALSTORAGE_KEY, MODULE, SOCKET_ACTION, SOCKET_EVENTS } from '../../framework/constant/constant';
import { ToastContainer } from 'react-toastify';
import useSocket from '../../framework/socket/useSocket';
import { useTranslation } from '../../locale/useTranslation';
import { ConfirmAlertOk } from '../../ConfirmAlert';
// import socketContext from '../../framework/socket/socketContext';

const Main = () => {

  const [isMinified, setIsMinified] = useState(false);
  const [showSessonKillDialog, setShowSessonKillDialog] = useState(false);

  const lang = useTranslation();
  const navigate = useNavigate();

  useEffect(() => {
    setIsMinified(() => utility.getValue(LOCALSTORAGE_KEY.isMenuMified));
    //ctrl + / listner
    document.addEventListener("keydown", onkeydown);
    return () => {
      document.removeEventListener("keydown", onkeydown);
    }

  }, []);

  const onkeydown = (event) => {
    if (event.ctrlKey && event.key == "/") {
      const event = new Event('searchBarOpen');
      document.dispatchEvent(event);
    }
  }

  //  const socket =  useSocket(SOCKET_EVENTS.onSocketData, (socketData)=>{

  //     let data = socketData.data;
  //     switch (socketData.action) {
  //       case SOCKET_ACTION.GRID_REFRESH:
  //         //TODO:
  //         break;
  //       default:
  //         break;
  //     }
  //   })

  //socket.emit(SOCKET_EVENTS.onSocketData, {action: SOCKET_ACTION.GRID_REFRESH, data:"test"})

  //socket
  useSocket(SOCKET_EVENTS.onSocketData, (socketData) => {

    if (socketData.module != MODULE.ALL) return;

    switch (socketData.action) {
      case SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY:
        utility.deleteLocalStorageItem(socketData.data);
        break;
      case SOCKET_ACTION.KILL_USER_SESSION:
        if (socketData.data._id == utility.getValue(LOCALSTORAGE_KEY.userData)._id) {
          setShowSessonKillDialog(true);
        }
        break;
      default:
        break;
    }
  });

  const onMinifyMenuClick = () => {
    setIsMinified(!isMinified);
    utility.setValue(LOCALSTORAGE_KEY.isMenuMified, !isMinified);
    const event = new Event('isMinified');
    window.dispatchEvent(event);
  }

  return <>

    <Header onMinifyMenuClick={onMinifyMenuClick} ></Header>
    <div className='mainb'>

      <Navbar onMinifyMenuClick={onMinifyMenuClick} isMinified={isMinified} />

      {/* Container */}
      <div className="main-container" style={{ transition: 'all 0.3s linear' }}>
        <Outlet />
      </div>
      {showSessonKillDialog && ConfirmAlertOk(
        () => {
          utility.clearLocalStorage();
          navigate('/');
        }, // on Click yes
        `${lang.session_terminated_label}`, //title
        `${lang.your_session_has_been_terminated_label}`// message
      )}
      <ToastContainer />
    </div>
  </>

}

export default Main;