/* eslint-disable */
import React, { useState } from "react";
import * as API from "../../../src/framework/API/api";
import { useEffect } from "react";
import { ENTITYNAME, MEDIACATEGORIES } from "../../framework/constant/constant";
import SalesDashboardTile from "./SalesDashboardTile";
import { GlobalContext } from "../../dataContext/globalContext";
import { useTranslation } from "../../locale/useTranslation";
import { toast } from "react-toastify";

const ContentDashboard = () => {

  const [dataCount , setDataCount ] = useState({
    programCount : 0,
    movieCount : 0,
    parentSeriesCount : 0,
    seriesCount : 0,
    commercialCount : 0,
    interstitialCount : 0,
    fillersCount : 0,
    musicCount : 0,
  })

  const lang = useTranslation();

  useEffect(() => {
    loadContentData();
  }, [])

  const loadContentData = async () => {

    let contentRes = await API.getContentCounts();

    console.log(contentRes.data);

    if(contentRes.success){
      setDataCount({
        ...dataCount,
        programCount : contentRes.data.find(x => x.SID === MEDIACATEGORIES.Program)?.count ?? 0,
        movieCount : contentRes.data.find(x=> x?.SID == MEDIACATEGORIES.Movie)?.count ?? 0,
        commercialCount : contentRes.data.find(x=> x?.SID == MEDIACATEGORIES.Commercial)?.count ?? 0,
        interstitialCount : contentRes.data.find(x=> x?.SID == MEDIACATEGORIES.Interstitial)?.count ?? 0,
        fillersCount : contentRes.data.find(x=> x?.SID == MEDIACATEGORIES.Filler)?.count ?? 0,
        musicCount : contentRes.data.find(x=> x?.SID == MEDIACATEGORIES.Music)?.count ?? 0,
        parentSeriesCount : contentRes.data.find(x=> x?.SID == MEDIACATEGORIES.Series)?.count ?? 0,
        seriesCount : contentRes.data.find(x=> x?.SID == MEDIACATEGORIES.Season)?.count ?? 0,
      })
    }else{
      toast.error(contentRes.message);
    }

  };

  return (
    <GlobalContext.Consumer>{item =>
      <div className="widget-body">
        <div className="row" style={{ marginLeft: "3px" }}>
          <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-3">
            <SalesDashboardTile
              iconBackColor="#f58d05"
              count={dataCount.programCount}
              icon="fa-sharp fa-solid fa-computer"
              status={lang.program_label}
              description={lang.view_details_label}
              route="/home/MediaEpisode/Program"
            />

          </div>
          <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-3">
            <SalesDashboardTile
              iconBackColor="#1ca666"
              count={dataCount.movieCount}
              icon="fa-sharp fa-solid fa-film"
              status={lang.movie_label}
              description={lang.view_details_label}
              route="/home/MediaEpisode/Movie"
            />
          </div>
          <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-3">
            <SalesDashboardTile
              iconBackColor="#c72848"
              count={dataCount.parentSeriesCount}
              icon="fa-sharp fa-solid fa-compact-disc"
              status={lang.parent_series_lable}
              description={lang.view_details_label}
              route="/home/MediaEpisode/Series"
            />
          </div>
          <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-3">
            <SalesDashboardTile
              iconBackColor="#3c83c9"
              count={dataCount.seriesCount}
              icon="fa-sharp fa-solid fa-peace"
              status={lang.series_only_label}
              description={lang.view_details_label}
              route="/home/MediaEpisode/Season"
            />
          </div>
        </div>
        <div className="row" style={{ marginLeft: "3px" }}>
          <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-3">
            <SalesDashboardTile
              iconBackColor="#FA6166"
              count={dataCount.commercialCount}
              icon="fa-sharp fa-solid fa-cash-register"
              status={lang.commercial_label}
              description={lang.view_details_label}
              route="/home/MediaEpisode/Commercial"
            />

          </div>
          <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-3">
            <SalesDashboardTile
              iconBackColor="#FF69B3"
              count={dataCount.interstitialCount}
              icon="fa-sharp fa-solid fa-podcast"
              status={lang.interstitial_label}
              description={lang.view_details_label}
              route="/home/MediaEpisode/Interstitial"
            />
          </div>
          <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-3">
            <SalesDashboardTile
              iconBackColor="#98BC62"
              count={dataCount.fillersCount}
              icon="fa-sharp fa-solid fa-filter"
              status={lang.filler_label}
              description={lang.view_details_label}
              route="/home/MediaEpisode/Filler"
            />
          </div>
          <div className="salesDashboard_forCard col-sm-12 col-md-6 col-lg-3">
            <SalesDashboardTile
              iconBackColor="#E3B448"
              count={dataCount.musicCount}
              icon="fa-sharp fa-solid fa-music"
              status={lang.music_label}
              description={lang.view_details_label}
              route="/home/MediaEpisode/Music"
            />
          </div>
        </div>

      </div>}
    </GlobalContext.Consumer>
  );
};

export default ContentDashboard;
