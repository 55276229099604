/* eslint-disable */
import React, { useEffect, useState } from 'react';
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { EditPageHeader } from '../../components/EditPageHeader';
import { Checkbox } from "@progress/kendo-react-inputs";
import * as API from "../../framework/API/api"
import { ENTITYNAME } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import AddRoundButton from '../../framework/forms/helpercomponents/buttons/AddRoundButton';
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import CollectionSelection from '../../framework/forms/CollectionSelection';
import { ConfirmDeleteAlert } from '../../ConfirmAlert';
import DeleteRoundButton from '../../framework/forms/helpercomponents/buttons/DeleteRoundButton';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import BossDialog from '../../components/BossDialog';

const OttSegmentEditForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        SID: 0,
        Description: "",
        Coupons: [],
        Archive: false
    }

    useEffect(() => {
        pageRender();
    }, [])

    const pageRender = async () => {
        await loadCombo();
        if (props.item.SID > 0) {
            await loadEditData();
        }
    }


    const loadCombo = async () => {
        let getproductData = await API.getDataLookup(ENTITYNAME.OttProduct, { sort: { Description: 1 }, query: ['Archive', '=', false] });
        let productsFilterData = getproductData.data.filter((obj) => {
            let productSegmentIds = obj?.Segments.map(x => x.Description);
            if (productSegmentIds.includes(props.item.Description)) {
                return obj;
            }
        });
        setProductsData(productsFilterData);
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [gridData, setGridData] = useState([]);
    const [tabNumber, setTabNumber] = React.useState("1");
    const [showCoupon, setShowCoupon] = useState(false);
    const [productsData, setProductsData] = useState([]);


    const loadEditData = async () => {

        let linkData = {
            link_collection: 'coupon',
            link_field: 'Coupons',
        }

        let res = await API.getEntity(ENTITYNAME.OttSegment, parseInt(props.item.SID), linkData);

        console.log(res);

        if (res.success) {
            setDataItem(res.data);
            setGridData(res.data.Coupons ?? []);
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

    }

    const onChangeForm = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {

        if (dataItem.Description == "" || dataItem.Description == undefined) {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true

    }
    const handleTabChange = (event, newValue) => {
        setTabNumber(`${newValue}`);
    };

    const handleSubmit = async () => {

        if (isValid()) {
            const saveData = {
                SID: dataItem.SID ?? 0,
                Description: dataItem.Description,
                Coupons: gridData.length > 0 ? gridData.map((x) => x._id) : [],
                Archive: dataItem.Archive ?? false,
                query: [["Description", "=", dataItem.Description]]

            }
            console.log(saveData);
            let res = await API.saveData(ENTITYNAME.OttSegment, saveData);
            if (res.success) {
                utility.deleteLocalStorageItem(ENTITYNAME.OttSegment);
                props.cancelEdit();
                props.refresh();
                return;
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const handleCouponData = (dataList) => {
        let newData = utility.checkDuplicateInData(dataList, gridData, "Name")
        setGridData([...gridData, ...newData]);
    }

    const removeCoupon = (item) => {
        setGridData(gridData.filter((x) => x._id != item._id));
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const MyCommandCell = (props) => (
        <div className="mt-1" style={{ display: "flex" }}>
            <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => removeCoupon(props.dataItem), () => { })} />
        </div>
    )

    return (
        <BossDialog
            title={props.item.Description ?? `${lang.create_segment_dialog_header}`}
            onClose={props.cancelEdit}
            width={"900px"}
            height={"578px"}
        >
            <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
            <Form
                initialValues={dataItem}
                render={(formRenderProps) => (
                    <FormElement>
                        <div className='row mt-2'>
                            <div className='col-12'>
                                <div className='row mt-2'>
                                    <div className='col'>
                                        <label htmlFor="TabView">{lang.description_label} *</label>
                                        <input
                                            name={"Description"}
                                            type="text"
                                            className="form-control form-control-sm"
                                            onChange={onChangeForm}
                                            value={dataItem.Description}
                                        />
                                    </div>
                                </div>
                                <div className='row ml-1 mt-3'>
                                    <Field
                                        style={{border: "1px solid grey"}}
                                        name={"Archive"}
                                        component={Checkbox}
                                        label={lang.archive}
                                        onChange={onChangeForm}
                                    />
                                </div>
                                <Box sx={{ width: '100%', typography: 'body1' }}>
                                    <TabContext value={tabNumber}>
                                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                            <TabList onChange={handleTabChange} aria-label="tabs example">
                                                <Tab label={lang.coupon_label} value="1" />
                                                {props.item.SID != 0 && <Tab label={lang.product_label} value="3" />}
                                            </TabList>
                                        </Box>
                                        <TabPanel value={"1"}>
                                            <div className="row mt-1">
                                                <div className="col-8">
                                                    <AddRoundButton onClick={() => setShowCoupon(true)} title={lang.add_coupon_tooltip} />
                                                </div>
                                                <div className="col-4">
                                                    <h5 className="pull-right">{lang.coupon_label}</h5>
                                                </div>
                                            </div>
                                            <div className="row ml-2 mr-2">
                                                <Grid data={gridData} style={{ height: '200px' }}>
                                                    <GridColumn cell={MyCommandCell} width={"35px"} locked={true} />
                                                    <GridColumn field="Name" title={lang.name_column} editable={false} />
                                                    <GridColumn field="Code" title={lang.code_column} editable={false} />
                                                    <GridColumn field="DiscountType.Type" title={lang.discount_type_column} editable={false} />
                                                    <GridColumn field="DiscountValue" title={lang.discount_value_column} editable={false} />
                                                </Grid>
                                            </div>
                                        </TabPanel>
                                        {props.item.SID != 0 && <TabPanel value={"3"}>
                                            {/* product in which this coupons attached */}
                                            <div className="row ml-2 mr-2">
                                                <Grid data={productsData} style={{ height: '250px' }}>
                                                    <GridColumn field="Title" title={lang.title_column} editable={false} />
                                                    <GridColumn field="Description" title={lang.description_column} editable={false} />
                                                </Grid>
                                            </div>
                                        </TabPanel>}
                                    </TabContext>
                                </Box>
                                {showCoupon && <CollectionSelection entityname={ENTITYNAME.Coupon} title={lang.select_coupon_collection} closeForm={() => setShowCoupon(false)} setDataList={handleCouponData} width={"50vw"} height={"60vh"} />}
                            </div>
                        </div>
                    </FormElement>
                )}
            />
        </BossDialog >
    )
}
export default OttSegmentEditForm;
