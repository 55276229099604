/* eslint-disable */
import React, { useRef, useState } from "react";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { EditPageHeader } from "../../components/EditPageHeader";
import MediaEpisodeAssetEditForm from "../../modules/MediaLibrary/MediaEpisodeAssetEditForm";
import * as API from '../API/api';
import { ASSETTYPE, LOGEVENT, MODULE, OTTROUTETYPE } from "../constant/constant";
import BossDialog from "../../components/BossDialog";

export const OttEditForm = (props) => {

  const lang = useTranslation();

  const [assetEntity, setAssetEntity] = useState({});

  const setDataEntity = (entity) => {
    setAssetEntity(entity)
  }

  //function to check the validation in form
  async function isValid() {

    //validation to attach media if assetType is MediaAsset
    let assetTypeID = typeof assetEntity.OttAssetTypeSID === 'object' ? assetEntity.OttAssetTypeSID.ID : assetEntity.OttAssetTypeSID;
    if(assetTypeID != ASSETTYPE.Text){
      if (assetTypeID == ASSETTYPE["Media Asset"] && Object.keys(assetEntity.Source).length == 0) {
        toast.error(`${lang.please_attach_media_first}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }

      if(!assetEntity.VodTypes || Object.keys(assetEntity.VodTypes).length==0){
        toast.error(`${lang.please_select_vod_type_validation_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }

      if (assetEntity.Title == "" || assetEntity.Title == undefined) {
        toast.error(`${lang.please_enter_title_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }

      if (assetTypeID == ASSETTYPE.Web && Object.keys(assetEntity.WebAssets).length == 0) {
        toast.error(`${lang.please_select_web_assets_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }

      if ((assetEntity.routeType == OTTROUTETYPE.Page || assetEntity.routeType.ID == OTTROUTETYPE.Page) && (assetEntity.page_id == "" || assetEntity.page_id == undefined)) {
        toast.error(`${lang.please_select_page}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }

      if ((assetEntity.routeType == OTTROUTETYPE.PiPage || assetEntity.routeType.Description == 'PiPage') && Object.keys(assetEntity.Source).length == 0) {
        toast.error(`${lang.please_select_asset_type_to_media_asset_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }

      if ((assetEntity.routeType == OTTROUTETYPE.Player || assetEntity.routeType.ID == OTTROUTETYPE.Player)
        && assetEntity.Videos.length == 0) {
        toast.error(`${lang.please_attach_atleast_one_video_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }

      if ((assetEntity.routeType == OTTROUTETYPE.Deeplink || assetEntity.routeType.Description == 'Deeplink') && !assetEntity.Videos.some(x => x.OttVideoType.Description == "Deeplink")) {
        toast.error(`${lang.please_add_deeplink_in_video_list_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }

      if (assetEntity.Videos.length == 0) {
        toast.error(`${lang.please_attach_atleast_one_video_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }

      if (assetEntity.Posters.length == 0) {
        toast.error(`${lang.please_attach_atleast_one_poster_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }

      //media asset
      if (Object.keys(assetEntity.Source).length > 0) {
        var checkQuery = ['Source._id', '=', assetEntity.Source._id]
        if (props.item._id != undefined) {
          checkQuery = [['Source._id', '=', assetEntity.Source._id], ['_id', '!=', props.item._id]]
        }

        //check if the selected media is attached to another asset in db
        var attachedInAsset = await API.getData(props.entityname, { query: checkQuery })

        if (attachedInAsset.data.length > 0) {
          toast.error(`${lang.media_is_already_attached_with_another_asset_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          return false;
        }

      }
    }else{
      if (assetEntity.Title == "" || assetEntity.Title == undefined) {
        toast.error(`${lang.please_enter_title_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
    }

    return true;
  }

  const onSubmit = async () => {

    console.log(assetEntity);

    if (await isValid()) {

      let saveData = {     
        SID: assetEntity.SID,
        OttAssetTypeSID: typeof assetEntity.OttAssetTypeSID === 'object' ? assetEntity.OttAssetTypeSID.ID : assetEntity.OttAssetTypeSID,
        StartDate: new Date(assetEntity.StartDate).getTime(),
        EndDate: new Date(assetEntity.EndDate).getTime(),
        Title: assetEntity.Title,
        Description: assetEntity.Description,
        Archive: assetEntity.Archive ?? false,
        Source: {},
        page_id: typeof assetEntity.page_id === 'object' ? assetEntity.page_id._id : assetEntity.page_id,
        routeType: typeof assetEntity.routeType === 'object' ? assetEntity.routeType.ID : assetEntity.routeType,
        RentalRetailPrice : assetEntity.VodTypes.Name == 'TVOD' ? assetEntity.RentalRetailPrice : 0,
        RentalWholesalePrice :assetEntity.VodTypes.Name == 'TVOD' ? assetEntity.RentalWholesalePrice : 0,
        accessLevel: assetEntity.accessLevel?._id,
        IsOttAsset:assetEntity.IsOttAsset,
        IsOttReady:assetEntity.IsOttReady,
        VodTypes : assetEntity.VodTypes && Object.keys(assetEntity.VodTypes).length > 0 ? {
          _id:  assetEntity.VodTypes._id,
          SID:  assetEntity.VodTypes.SID,
          Name: assetEntity.VodTypes.Name
        } : {},
        Provider : assetEntity?.Provider ? assetEntity?.Provider?._id : "",
        UploadVideo:assetEntity.UploadVideo,
        VideoStatus:assetEntity.VideoStatus,
        Videos:assetEntity.Videos,
        Posters:assetEntity.Posters,
        WebAssets: assetEntity?.WebAssets ? assetEntity?.WebAssets?._id : null,
        Segments: (assetEntity?.Segments && assetEntity?.Segments?.length > 0 ) ? assetEntity?.Segments?.map((x) => {
          return {
            _id: x?._id,
            Description: x?.Description
          }
        } ) : []
       }
      if(assetEntity._id && assetEntity._id.length != 0){
        saveData._id = assetEntity._id
      }

      if (saveData.OttAssetTypeSID == ASSETTYPE["Media Asset"]) {
        saveData.Source = {
          _id: assetEntity.Source._id,
          SID: assetEntity.Source.SID,
          Title:assetEntity.Source.Title,
          MediaCategory: assetEntity.Source.MediaCategory,
          entityName: assetEntity.Source.entityName
        }
        //save IsOttAsset = true in media also
        let mediaData = await API.getData(assetEntity.Source.entityName, { query: ['_id', '=', assetEntity.Source._id] });
        let saveMediaItem = {
          ...mediaData.data[0],
          IsOttAsset: true
        }
        console.log(assetEntity.Source.entityName);
        let saveRes = await API.saveData(assetEntity.Source.entityName, saveMediaItem);
        console.log(saveRes);

      }
      
      props.onSubmit({}, saveData);

    }
    else {
      console.log('Not saved');
    }
  };

  return (
    <>
      <BossDialog
        title={props.item?.copy ? `${lang.assets_dialog_header}` : props.item?.Title ? props.item?.Title : `${lang.assets_dialog_header}`}
        onClose={props.cancelEdit}
        width={"830px"}
        height={"700px"}
      >
        <EditPageHeader showTitle={false} onSubmit={onSubmit} onCancel={props.cancelEdit} />
        <MediaEpisodeAssetEditForm source={'self'} dataItem={props.item} setDataEntity={setDataEntity} />
      </BossDialog>
    </>
  )
};