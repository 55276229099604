/* eslint-disable */
import React,{useState} from 'react';
import { Form,FormElement } from "@progress/kendo-react-form";
import { Grid, GridColumn} from "@progress/kendo-react-grid";
import { useTranslation } from '../../../locale/useTranslation';

export const EventStyle = (props) => {

    const [dataItem,setDataItem]=useState(props.eventStyleData[0]);
    const lang=useTranslation();

    const onChange = (e) => {
        setDataItem({...dataItem, [e.target.name]: e.target.value});

        var index = props.eventStyleData.findIndex((x) => x.Id == dataItem.Id);
        props.eventStyleData.splice(index,1,{...dataItem, [e.target.name]: e.target.value});
        props.setEventStyle(props.eventStyleData);
    }

    const gridRowRender = (tr, props) => {

        const trProps = {
            style : {
                //fontSize : props.dataItem.FontSize,
                color : props.dataItem.ForegroundColor,
                backgroundColor: props.dataItem.BackgroundColor
            },
            onClick:(e)=>{ setDataItem(props.dataItem)}
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    return(
        <>
        <h6>{lang.note_select_the_events_you_want_to_change}</h6>
        <div className="row border pb-2  mt-3">
            <div className="col-4 mt-2">
                <Grid data={props.eventStyleData} style={{cursor:"pointer"}}
                    rowRender={gridRowRender}>
                    <GridColumn field="EventType" title={lang.value_column}/>
                </Grid>
            </div>
            <div className='col'>
            <Form
                render={(formRenderProps) => (
                    <FormElement>
                        <div className="row mt-2 ml-2">
                            <div className="col-12 mr-4">
                                <div className='row mt-4'>
                                    <label className="col-4">{lang.events_type_lable}</label>
                                    <input value={dataItem.EventType} name="EventType" className="col-6" disabled = {true}/>
                                </div>
                                <div className='row mt-4'>
                                    <label className="col-4 mt-2">{lang.font_size_label}</label>
                                    <input
                                        type={"number"}
                                        className="col-4 ml-3 form-control form-control-sm"
                                        name={"FontSize"}
                                        value={dataItem.FontSize}
                                        onChange={onChange}
                                    />
                                </div> 
                                <div className='row mt-4'>
                                <label className="col-4 mt-1">{lang.foreground_Color_label}</label>
                                    <input
                                        type = 'color'
                                        className="col-2"
                                        name="ForegroundColor"
                                        value={dataItem.ForegroundColor}
                                        onChange={onChange}
                                        placement="right"
                                    />
                                </div>
                                <div className='row mt-4'>
                                    <label className="col-4 mt-1">{lang.background_color_label}</label>
                                    <input
                                        type = 'color'
                                        className="col-2"
                                        name="BackgroundColor"
                                        value={dataItem.BackgroundColor}
                                        onChange={onChange}
                                        placement="right"
                                        />
                                </div>
                            </div>
                            
                        </div>
                    </FormElement>
                )}>
            </Form>
            </div>
        </div>
        {/* <div>
            <p className="text-muted" style={{ fontSize: "10.5px" ,marginBottom : 0}}>{`Note : Priority of color preference will be on the basis of TX Mode ( TX Mode - Live, Repeat & Delayed > Genre color preference > TX Mode - TX )`}</p>
        </div> */}
        </>
    )
}