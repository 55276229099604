import React from 'react';
import BossDialog from '../../../../components/BossDialog';

function DeleteErrorDialogBox(props) {
  return (
    <div>
        <BossDialog width={300} title={props.title} onClose={props.onClose}>        
        <h6 style={{textAlign : "center"}}>{props.message}</h6>
        <p style={{textAlign : "center",color: "red"}}>{props.errorMessage}</p>
        <div className="row" style={{margin : "30px" ,textAlign : "center"}}>
        <button style={{textAlign : "center" ,margin:"0px 60px"}} className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-primary col-5 mr-1"
        onClick={() => props.onClose()}>
            Okay.
        </button>
        </div>
        </BossDialog>
    </div>
  )
}

export default DeleteErrorDialogBox;