/* eslint-disable */
import React, { useState } from 'react'
import { EditPageHeader } from '../../components/EditPageHeader';
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MODULE, OTT_POSTER_TYPE } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import { ImageBrowser } from '../../framework/forms/helpercomponents/selectFile/ImageBrowser';
import BossDialog from '../../components/BossDialog';

const TournamentTypeEditForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        ...props.item,
        Description: props.item.copy ? "Copy of " + props.item.Description : props.item.Description ?? "",
        Icon: props.item.Icon ?? "",
        Archive: props.item.Archive ?? false,
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [imageFiles, setImageFiles] = useState(new Set());

    const onChange = (e) => {
        if (e.target.name === 'Archive') {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive });
        } else if (e.target.name == 'Icon' && e.target?.files) {
            console.log( e.target?.files);
            if (e.target.files != undefined) {
                setImageFiles(e.target.files[0]);
            } else {
                setImageFiles(new Set());
            }
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {

        if (dataItem.Description == undefined || dataItem.Description == "") {
            toast.error(`${lang.please_enter_description_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;

    }

    const handleSubmit = async () => {

        if (isValid()) {

            let imageUrl = dataItem.Icon;

            console.log(imageFiles)
            if (imageFiles.size > 0) {
                let resUrl = await API.uploadImage(imageFiles);
                if (!resUrl.success) {
                    toast.error(resUrl.message, {
                        position: toast.POSITION.TOP_RIGHT
                    });
                    return;
                }
                imageUrl = resUrl.data;
                dataItem.Icon = imageUrl;
            }

            const saveData = {
                _id: dataItem._id,
                SID: dataItem.SID,
                Description: dataItem.Description,
                Icon: imageUrl,
                Archive: dataItem.Archive ?? false,
                checkDuplicate: true,
                query: [["Description", "=", dataItem.Description]]

            }
            console.log(saveData);
            const res = await API.saveData(ENTITYNAME.TournamentType, saveData);
            if (res.success) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.TournamentType, data: res.data, message: res.message };
                API.SaveLogs(logData);
                props.cancelEdit();
                props.refresh();
                utility.deleteLocalStorageItem(ENTITYNAME.TournamentType);
            } else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const handleSelectedImage = (dataList) => {
        setDataItem({ ...dataItem, Icon: dataList[0].ImageUrl });
    }

    return (
        <>
            <BossDialog
                title={props.item.Description ?? lang.create_tournament_type_dialog_header}
                onClose={props.cancelEdit}
                width={"620px"}
            >
                <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />

                <div className='row mt-2'>
                    <div className='col-12'>
                        <div className='row mt-2'>
                            <div className='col-6'>
                                <label>{lang.description_label} *</label>
                                <input
                                    name={"Description"}
                                    type="text"
                                    className="form-control form-control-sm"
                                    onChange={onChange}
                                    value={dataItem.Description}
                                />
                            </div>
                            <div className='col-6'>
                                <label>{lang.icon_url_label}</label>
                                <ImageBrowser name={'Icon'} value={dataItem.Icon} onChange={onChange} setSelectedImage={handleSelectedImage} />
                            </div>
                        </div>
                        <div className='row'>
                            <div className='col-6 ml-1'>
                                <input
                                    style={{ border: "1px solid grey" }}
                                    name={"Archive"}
                                    type='checkbox'
                                    onChange={onChange}
                                />
                                <label className="ml-2">{lang.archive}</label>
                            </div>
                        </div>
                    </div>
                </div>
            </BossDialog >
        </>
    )
}

export default TournamentTypeEditForm;