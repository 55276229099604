import { useTranslation } from "../../../../locale/useTranslation";

const FilterRoundButton = (props) => {
    const lang=useTranslation();
    
    return <button  title={lang.filter_button_tooltip} type="submit" onClick={props.onClick} style={{margin:"0px 01px"}} className="btn btn-default btn-circle btn-my">
       <i className="fa fa-filter fa-fw img-circle"></i>
      
    </button>
}

export default FilterRoundButton;
