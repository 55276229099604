/* eslint-disable */
import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Grid,
  GridColumn as Column,
  getSelectedState,
  GridNoRecords,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import {
  checkBackToBackMediaCategoryType,
  checkClientSpotSeperation,
  checkPreviewed,
  checkSalesBreakLimit,
  checkSecondaryEventDuplication,
  checkSecondaryEventScheduled,
  checkSegmentTXReady,
  checkTimeRange,
  checkValidDate,
  checkValidMedia,
  clashCheck,
  compareSegments,
  getBackToBackBrandsInSchedule,
  getBackToBackCommercialInSchedule,
  getBackToBackMediaInSchedule,
  getBackToBackProductsInSchedule,
  getBreakOverRun,
  getBreakUnderRun,
  getEpisodeSequencePlanning,
  getInvalidSlotPlanning,
  getSameBreakSameBrand,
  getSameBreakSameCommercial,
  getSegmentAttachedPlanning,
  getUnderRunAndOverRunInSchedule,
  hasNoSegments,
  missingSegmentAttachment,
  segmentSequence,
  validatePreviousDayEndTime,
  validateTimeCodeAndDuration,
  BackToBackSameEpisodeCheck,
} from "./checkListHelper";
import { DateCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import * as API from "../../framework/API/api";
import { CHECKLIST, ENTITYNAME, LOCALSTORAGE_KEY, MODULE, SCHEDULETYPE } from "../../framework/constant/constant";
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from "react-toastify";
import { EditPageHeader } from "../../components/EditPageHeader";
import BossDialog from "../../components/BossDialog";

const DATA_ITEM_KEY = "SID";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const CheckLists = (props) => {

  const user = utility.getValue(LOCALSTORAGE_KEY.userData);

  const [selectedState, setSelectedState] = useState({});
  const [status,setStatus] = useState('Loading...')
  // const [selectedGridData, setSelectedGridData] = useState([]);

  const selectedGridDataRef = useRef([]);
  const setSelectedGridData = (data) => {
    selectedGridDataRef.current = data
  }


  const gridColumnsRef = useRef([]);
  const setGridColumns = (data) => {
    gridColumnsRef.current = data
  }
  // const [loading,setLoading] = useState(true);

  const [scheduleCheckList, setScheduleCheckList] = useState([]);
  const lang = useTranslation();
  useEffect(() => {
    loadData();
  }, []);


  const loadData = async () => {

    if (props.module == MODULE.PLANNING) {
      let args = { query: ['module', '=', MODULE.PLANNING] }
      let preferenceRes = await API.getData(ENTITYNAME.CheckList, args);
      console.log(preferenceRes);
      if (preferenceRes.data.length == 0) {
        toast.error(`${lang.please_configure_checklist_preference_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        setStatus('No Record Found')
        return false;
      }
      for (let i = 0; i < preferenceRes.data.length; i++) {
        var checklistData = preferenceRes.data;
        switch (checklistData[i].SID) {

          case CHECKLIST.InvalidSlot:
            console.log("Invaild Slot");
            var invalidSlotMediaList = await getInvalidSlotPlanning(props.data);
            var dataItem = {
              ...checklistData[i],
              Status:
              invalidSlotMediaList.length == 0
                  ? `${lang.success_label}`
                  : `${lang.failure_lable}`,
              List: invalidSlotMediaList ?? [],
            };
            checklistData[i] = dataItem;
            break;

          case CHECKLIST.EpisodeSequence:
            var episodeSequenceMediaList = await getEpisodeSequencePlanning(props.data);
            var dataItem = {
              ...checklistData[i],
              Status:
              episodeSequenceMediaList.length == 0
                  ? `${lang.success_label}`
                  : `${lang.failure_lable}`,
              List: episodeSequenceMediaList,
            };
            checklistData[i] = dataItem;
            break;

          case CHECKLIST.SegmentAttached:
            var segmentAttachedMediaList = await getSegmentAttachedPlanning(props.data);
            var dataItem = {
              ...checklistData[i],
              Status:
              segmentAttachedMediaList.length == 0
                  ? `${lang.success_label}`
                  : `${lang.failure_lable}`,
              List: segmentAttachedMediaList,
            };
            checklistData[i] = dataItem;
            break;

            case CHECKLIST.BackToBackEpisode:
              var backToBackEpisodeCheck = await BackToBackSameEpisodeCheck(props.data);
              var dataItem = {
                ...checklistData[i],
                Status:
                backToBackEpisodeCheck.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: backToBackEpisodeCheck,
              };
              checklistData[i] = dataItem;
            break;

          default:
            var dataItem = {
              ...checklistData[i],
              Status: "Pending",
              List: [],
            };
            checklistData[i] = dataItem;
            break;
        }
      }

      console.log(checklistData);
      setScheduleCheckList(checklistData);
    }
    else {

      let args = { query: [['user_id', '=', user._id], ['entityName', '=', ENTITYNAME.CheckListPreference]] }
      let preferenceRes = await API.getData(ENTITYNAME.UserPreference, args);

      if (preferenceRes.success) {

        if (preferenceRes.data.length == 0 || preferenceRes.data[0].checklist.length == 0) {
          toast.error(`${lang.please_configure_checklist_preference_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          setStatus('No Record Found')
          return false;
        }

        for (let i = 0; i < preferenceRes.data[0].checklist.length; i++) {
          var checklistData = preferenceRes.data[0].checklist;
          switch (checklistData[i].SID) {

            //DONE
            case CHECKLIST.BackToBackMedia:
              var mediaList = await getBackToBackMediaInSchedule(props.data);
              var dataItem = {
                ...checklistData[i],
                Status:
                  mediaList.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: mediaList ?? [],
              };
              checklistData[i] = dataItem;
              break;

            //DONE
            case CHECKLIST.BackToBackProduct:
              var productMediaList = await getBackToBackProductsInSchedule(props.data);
              var dataItem = {
                ...checklistData[i],
                Status:
                  productMediaList.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: productMediaList,
              };
              checklistData[i] = dataItem;
              break;

            //DONE
            case CHECKLIST.BackToBackBrand:
              var brandMediaList = await getBackToBackBrandsInSchedule(props.data);
              var dataItem = {
                ...checklistData[i],
                Status:
                  brandMediaList.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: brandMediaList,
              };
              checklistData[i] = dataItem;
              break;

            //DONE
            case CHECKLIST.BackToBackCommercial:
              var commercialMediaList = await getBackToBackCommercialInSchedule(props.data);
              var dataItem = {
                ...checklistData[i],
                Status:
                  commercialMediaList.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: commercialMediaList,
              };
              checklistData[i] = dataItem;
              break;

            //DONE
            case CHECKLIST.BookedCommercialNotUsed:
              var dataItem = {
                ...checklistData[i],
                Status:
                  props.bookedCommercialData.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: props.bookedCommercialData.map((obj) => {
                  return {
                    Title: obj.mediaEpisode.Title,
                    // SlotDateTime : obj.SlotDateTime,
                    AssetId: obj.mediaEpisode.AssetId,
                    Type: Object.keys(SCHEDULETYPE).find(key => SCHEDULETYPE[key] === obj.Type) ?? obj.mediaEpisode.MediaCategory.Description,
                    Message: 'Booked Commercial Not Used',
                  }
                })
              };
              checklistData[i] = dataItem;
              break;

            //DONE
            case CHECKLIST.UnderRunOverRun:
              var mediaOUList = await getUnderRunAndOverRunInSchedule(props.data);
              var dataItem = {
                ...checklistData[i],
                Status:
                  mediaOUList.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: mediaOUList,
              };
              checklistData[i] = dataItem;
              break;

            //DONE
            case CHECKLIST.Segmented:
              var hasNoSegmentList = await hasNoSegments(props.data);
              var dataItem = {
                ...checklistData[i],
                Status:
                  hasNoSegmentList.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: hasNoSegmentList,
              };
              checklistData[i] = dataItem;
              break;

            //DONE
            case CHECKLIST.SegmentSequence:
              var invalidSequenceList = await segmentSequence(
                props.data
              );
              var dataItem = {
                ...checklistData[i],
                Status:
                  invalidSequenceList.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: invalidSequenceList,
              };
              checklistData[i] = dataItem;
              break;

            //DONE
            case CHECKLIST.SameBreakSameCommercial:
              var mediaList = await getSameBreakSameCommercial(props.data);
              var dataItem = {
                ...checklistData[i],
                Status:
                  mediaList.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: mediaList,
              };
              checklistData[i] = dataItem;
              break;

            //DONE
            case CHECKLIST.SameBreakSameBrand:
              var mediaList = await getSameBreakSameBrand(props.data);
              var dataItem = {
                ...checklistData[i],
                Status:
                  mediaList.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: mediaList,
              };
              checklistData[i] = dataItem;
              break;

            //DONE
            case CHECKLIST.Previewed:
              var mediaList = await checkPreviewed(props.data);

              var dataItem = {
                ...checklistData[i],
                Status:
                  mediaList.Previewed.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: mediaList.Previewed,
              };
              checklistData[i] = dataItem;
              break;

            //DONE
            case CHECKLIST.SegmentTXReady:
              var hasNotTXReadyHeader = await checkSegmentTXReady(
                props.data
              );
              var dataItem = {
                ...checklistData[i],
                Status:
                  hasNotTXReadyHeader.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: hasNotTXReadyHeader,
              };
              checklistData[i] = dataItem;
              break;

            //TODO
            case CHECKLIST.MissingSegmentAttachment:
              var hasNoSubtitleAttachedHeader = await missingSegmentAttachment(
                props.data
              );
              var dataItem = {
                ...checklistData[i],
                Status:
                  hasNoSubtitleAttachedHeader.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: hasNoSubtitleAttachedHeader,
              };
              checklistData[i] = dataItem;
              break;


            //DONE
            case CHECKLIST.CompareSegments:
              var invalidSegmentHeaders = await compareSegments(
                props.data
              );
              var dataItem = {
                ...checklistData[i],
                Status:
                  invalidSegmentHeaders.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: invalidSegmentHeaders,
              };
              checklistData[i] = dataItem;
              break;

            //DONE
            case CHECKLIST.CheckTimeRange:
              var invalidData = await checkTimeRange(
                props.data
              );
              var dataItem = {
                ...checklistData[i],
                Status:
                  invalidData.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: invalidData,
              };
              checklistData[i] = dataItem;
              break;

            //DONE
            case CHECKLIST.ClashCheck:
              var invalidData = await clashCheck();
              var dataItem = {
                ...checklistData[i],
                Status:
                  invalidData.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: invalidData,
              };
              checklistData[i] = dataItem;
              break;

            //DONE
            case CHECKLIST.SalesBreakLimit:
              var invalidData = await checkSalesBreakLimit(
                props.data
              );
              var dataItem = {
                ...checklistData[i],
                Status:
                  invalidData.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: invalidData,
              };
              checklistData[i] = dataItem;
              break;

            //DONE
            case CHECKLIST.SecondaryEventScheduled:
              var invalidData = await checkSecondaryEventScheduled(
                props.data
              );
              var dataItem = {
                ...checklistData[i],
                Status:
                  invalidData.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: invalidData,
              };
              checklistData[i] = dataItem;
              break;

            //DONE
            case CHECKLIST.SecondaryEventDuplication:
              var invalidData = await checkSecondaryEventDuplication(
                props.data
              );
              var dataItem = {
                ...checklistData[i],
                Status: invalidData.length == 0
                  ? `${lang.success_label}`
                  : `${lang.failure_lable}`,
                List: invalidData,
              };
              checklistData[i] = dataItem;
              break;

            case CHECKLIST.ValidDate:
              var invalidData = await checkValidDate(
                props.data
              );
              var dataItem = {
                ...checklistData[i],
                Status: invalidData.length == 0
                  ? `${lang.success_label}`
                  : `${lang.failure_lable}`,
                List: invalidData,
              };
              checklistData[i] = dataItem;
              break;

            case CHECKLIST.BreakItemUnderRun:
              var invalidData = await getBreakUnderRun(
                props.data
              );
              var dataItem = {
                ...checklistData[i],
                Status: invalidData.length == 0
                  ? `${lang.success_label}`
                  : `${lang.failure_lable}`,
                List: invalidData,
              };
              checklistData[i] = dataItem;
              break;

            case CHECKLIST.BreakItemOverRun:
              var invalidData = await getBreakOverRun(
                props.data
              );
              var dataItem = {
                ...checklistData[i],
                Status: invalidData.length == 0
                  ? `${lang.success_label}`
                  : `${lang.failure_lable}`,
                List: invalidData,
              };
              checklistData[i] = dataItem;
              break;

            //DONE
            case CHECKLIST.ClientSpotSeperation:
              var invalidData = await checkClientSpotSeperation(props.data);
              var dataItem = {
                ...checklistData[i],
                Status:
                  invalidData.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: invalidData,
              };
              checklistData[i] = dataItem;
              break;
            
            case CHECKLIST.ValidMedia:
              var invalidData = await checkValidMedia(props.data);
              console.log(invalidData);
              var dataItem = {
                ...checklistData[i],
                Status:
                  invalidData.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: invalidData,
              };
              checklistData[i] = dataItem;
              break;

            case CHECKLIST.BackToBackMediaCategoryType:
              var invalidData = await checkBackToBackMediaCategoryType(props.data);
              var dataItem = {
                ...checklistData[i],
                Status:
                  invalidData.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: invalidData,
              };
              checklistData[i] = dataItem;
              break;

            case CHECKLIST.ValidateTimeCodeAndDuration:
              var invalidData = await validateTimeCodeAndDuration(props.data);
              var dataItem = {
                ...checklistData[i],
                Status:
                  invalidData.length == 0
                    ? `${lang.success_label}`
                    : `${lang.failure_lable}`,
                List: invalidData,
              };
              checklistData[i] = dataItem;
              break;

            case CHECKLIST.ValidatePreviousDayEndTime:
              var isValid = await validatePreviousDayEndTime(props.scheduleTimeInfo);
              var dataItem = {
                ...checklistData[i],
                Status: isValid ? `${lang.success_label}` : `${lang.failure_lable}`,
                List: [],
              };
              checklistData[i] = dataItem;
              break;

            default:
              var dataItem = {
                ...checklistData[i],
                Status: "Pending",
                List: [],
              };
              checklistData[i] = dataItem;
              break;
          }
        }

        setScheduleCheckList(checklistData);
      }


    }
  };

  const MyIconCell = (props) => {
    var bool = props.dataItem.Status == `${lang.success_label}` ? true : false;
    return (
      <td style={{ textAlign: "center" }}>
        <i
          className={`fa-solid fa-circle-${bool ? 'check' : 'xmark'} fa-lg`}
          style={{ backgroundColor: 'white', color: bool ?'green' : 'red' ,borderRadius : '7px'}}
          aria-hidden="true"
        ></i>
      </td>
    );
  };

  const onGoToSchedule = (dataItem) => {
    props.onGoToSchedule(dataItem)
  }

  const GoToScheduleCell = (props) => {
    return (
      <td>
        <button 
          className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-base"
          title="Go to Schedule"
          onClick={() => onGoToSchedule(props.dataItem)}
        >
          <i className="fa-solid fa-arrow-up-right-from-square"/>
        </button>
      </td>
    );
  };

  const onSelectionChange = useCallback((event) => {

      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });
      setSelectedState(newSelectedState);

      var selectedItem = scheduleCheckList.find(
        (item) => Object.keys(newSelectedState)[0] == item.SID
      );
      if (selectedItem) {
        setSelectedGridData(selectedItem.List);
        if(selectedItem.List.length > 0) setGridColumns(Object.keys(selectedItem.List[0]))

      };
    },
    [scheduleCheckList]
  );

  return (
    <>
      <BossDialog
        title={lang.check_lists_dialog_header}
        onClose={props.closeForm}
        width={'50vw'}
      >
        <EditPageHeader saveButtonTitle={"Export"} onSubmit={() => props.onExport(scheduleCheckList)} onCancel={props.closeForm} showTitle={false}/>
        <Grid
          data={scheduleCheckList?.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}
          dataItemKey={DATA_ITEM_KEY}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: "single",
          }}
          onSelectionChange={onSelectionChange}
          style={{ height: "220px", marginTop: "04px", overflow: "hidden" }}
        >
          <GridNoRecords>{status}</GridNoRecords>
          <Column cell={MyIconCell} width={80} />
          <Column field="Description" title={lang.description_column} />
          <Column field="Status" title={lang.status_column}/>
        </Grid>
        <br />
        <Grid data={selectedGridDataRef.current} style={{ height: "250px" }}>
          {props.module != MODULE.PLANNING && <Column editable={false} width={60} cell={GoToScheduleCell}/>}
          {gridColumnsRef.current.map((column, index) => {
              return (
                  <Column
                    key={index}
                    field={column}
                    title={column}
                    editable={false}
                    width={200}
                  />
              );
          })}
        </Grid>
      </BossDialog>
    </>
  );
};
export default CheckLists;
