import { memo, useEffect, useState } from "react";
import CancelButton from "../../../framework/forms/helpercomponents/buttons/CancelButton";
import SaveButton from "../../../framework/forms/helpercomponents/buttons/SaveButton";
import * as API from '../../../framework/API/api'
import { ENTITYNAME, MEDIACATEGORIES, PLATFORMTYPE, PUBLISHING_SOURCE } from "../../../framework/constant/constant";
import { Checkbox } from "@progress/kendo-react-inputs";
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { toast } from "react-toastify";
import AddRoundButton from "../../../framework/forms/helpercomponents/buttons/AddRoundButton";
import AudioTrack from "../AudioTrack";
import MediaDealRightEditForm from "./component/mediaDealRightEditForm";
import moment from "moment";
import { utility } from "../../../framework/utility/utilityProvider";
import { ConfirmDeleteAlert } from "../../../ConfirmAlert";
import DeleteRoundButton from "../../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { DataHelper } from "../../../framework/helper/DataHelper";
import { useTranslation } from "../../../locale/useTranslation";

const MultipleContentEditPanel = (props) => {

    const { selectedItems, setSelectedItems, entityName, mediaCategory, gridData, setGridData } = props;

    const lang = useTranslation();
    const subHeadingStyle = { fontFamily: "Roboto", fontSize: "13px", fontWeight: "500", letterSpacing: "0.2px", lineHeight: "18px", color: "#191825", margin: "7px 10px", padding: 0 }
    const style = { display: "grid", gridTemplateColumns: "0.9fr 1.1fr", alignItems: "start", padding: "10px 0" }
    const width = "235px";
    const tableDataStyle = { fontFamily: "Roboto", fontSize: "13px", fontWeight: "400", letterSpacing: "0.2px", lineHeight: "18px", color: "#3C4048", padding: "10px" };
    const isHideMetaDataFields = entityName == ENTITYNAME.Series || entityName == ENTITYNAME.Season;

    const [pgRatings, setPGRatings] = useState([]);
    const [showMediadealrights, setShowMediadealrights] = useState({ show: false, isLinear: false });
    const [censorRatings, setCensorRatings] = useState([]);
    const [languages, setLanguages] = useState([]);
    const [categories, setCategories] = useState([]);
    const [genres, setGenres] = useState([]);
    const [selectedFields, setSelectedFields] = useState({
        PGRating: { isSelected: false, value: null },
        CensorRating: { isSelected: false, value: null },
        Languages: { isSelected: false, value: null },
        ProductionYear: { isSelected: false, value: null },
        Categories: { isSelected: false, value: null },
        Genres: { isSelected: false, value: null },
        SubGenres: { isSelected: false, value: null },
        AudioTrack: { isSelected: false, value: null },
        linearRights: { isSelected: false, value: [] },
        nonLinearRights: { isSelected: false, value: [] },
        Keywords: { isSelected: false, value: null },
        // OttAsset: { isSelected: false, value: [] }
    });

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        let pgRes = await API.getDataLookup(ENTITYNAME.PGRating, { sort: { Description: 1 } });
        if (pgRes.success) setPGRatings(pgRes.data);
        let crRes = await API.getDataLookup(ENTITYNAME.CensorRating, { sort: { Description: 1 } });
        if (crRes.success) setCensorRatings(crRes.data);
        let languages = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
        if (languages.success) setLanguages(languages.data);
        let categories = await API.getDataLookup(ENTITYNAME.ContentCategory, { sort: { Name: 1 } });
        if (categories.success) setCategories(categories.data);
        let genreRes = await API.getDataLookup(ENTITYNAME.Genre, { sort: { Description: 1 } });
        if (genreRes.success) setGenres(genreRes.data);
    }

    const onCheckBoxSelection = (e) => {
        let key = e.target.name;
        setSelectedFields((old) => ({ ...old, [key]: { ...old[key], isSelected: e.target.value } }));
    }

    const onFieldValueChange = (e) => {
        let key = e.target.name;
        setSelectedFields((old) => ({ ...old, [key]: { ...old[key], value: e.target.value } }));
    }

    const setAudioTrackData = (data) => {
        setSelectedFields((old) => ({ ...old, AudioTrack: { ...old.AudioTrack, value: data } }));
    }

    // const onSave = async () => {

    //     let saveData = {};   

    //     Object.keys(selectedFields).map((key) => {
    //         if (selectedFields[key].isSelected && (key !== 'Genres' && key !== 'SubGenres' && key !== 'linearRights' && key !== 'nonLinearRights')) {
    //             saveData[key] = selectedFields[key].value;
    //         }
    //     }); 

    //     if (selectedFields.Genres.isSelected) {
    //         let saveGenres = { Genres: selectedFields.Genres.value?.length > 0 ? selectedFields.Genres.value?.map(x=> {
    //             return { _id: x._id, Description: x.Description}
    //         }) : [] };
    //         selectedItems.map(async (item) => {
    //             let genresSaveRs = await API.saveData(entityName, { ...item, ...saveGenres });
    //             if (!genresSaveRs.success) {
    //                 toast.error('Failed to save');
    //             }
    //         });
    //     }
    //     if (selectedFields.SubGenres.isSelected) {
    //         let saveSubGenres = { SubGenres: selectedFields.SubGenres.value?.length > 0 ? selectedFields.SubGenres.value?.map(x=> {
    //             return { _id: x._id, Description: x.Description}
    //         }) : []};
    //         selectedItems.map(async (item) => {
    //             let genresSaveRs = await API.saveData(entityName, { ...item, ...saveSubGenres });
    //             if (!genresSaveRs.success) {
    //                 toast.error('Failed to save');
    //             }
    //         });
    //     }

    //     if (selectedFields.linearRights.isSelected) {
    //         let saveLinearRights = selectedFields.linearRights.value.map(item => { delete item._id; return item });
    //         const publishingSource = mediaCategory?.SID == MEDIACATEGORIES.Series ? PUBLISHING_SOURCE.Series : mediaCategory?.SID == MEDIACATEGORIES.Season ? PUBLISHING_SOURCE.Season : PUBLISHING_SOURCE.MediaEpisode;
    //         selectedItems.map(async (item) => {
    //             let mediaDealRightsRes = await API.updateMediaDealRights(item, [], saveLinearRights, publishingSource);
    //             if (!mediaDealRightsRes.success) {
    //                 toast.error('Failed to save');
    //             }
    //         });
    //     }
    //     if (selectedFields.nonLinearRights.isSelected) {
    //         let saveNonLinearRights = selectedFields.nonLinearRights.value.map(item => { delete item._id; return item });
    //         const publishingSource = mediaCategory?.SID == MEDIACATEGORIES.Series ? PUBLISHING_SOURCE.Series : mediaCategory?.SID == MEDIACATEGORIES.Season ? PUBLISHING_SOURCE.Season : PUBLISHING_SOURCE.MediaEpisode;
    //         selectedItems.map(async (item) => {
    //             let mediaDealRightsRes = await API.updateMediaDealRights(item, [], saveNonLinearRights, publishingSource);
    //             if (!mediaDealRightsRes.success) {
    //                 toast.error('Failed to save');
    //             }
    //         });
    //     }

    //     if(Object.keys(saveData).length == 0){
    //         toast.success('Saved Successfully'); 
    //         setGridData();
    //         return;
    //     }

    //     selectedItems.map(async (item) => {
    //         if(isHideMetaDataFields){
    //             let finalData ={
    //                 ...item,
    //                 Languages: saveData?.Languages?.length > 0 ? saveData.Languages?.map(x => {return { _id: x._id, Description: x.Description }}) : [],
    //                 PGRating: (saveData?.PGRating && Object.keys(saveData.PGRating).length > 0) ? { _id: saveData.PGRating?._id, Description: saveData.PGRating?.Description } : {},
    //                 ProductionYear: saveData?.ProductionYear,
    //                 Keywords: saveData?.Keywords
    //             }
    //             let response = await API.saveData(entityName, finalData);
    //             if (!response.success) toast.error('Failed to save');
    //         }else {
    //             let finalData = DataHelper.saveMetaDataEntity({...item.Metadata, ...saveData}, item);
    //             let mediaepisodemetadataSaveRes = await API.saveData(ENTITYNAME.MediaEpisodeMetaData, finalData);
    //             if (!mediaepisodemetadataSaveRes.success) toast.error('Failed to save');
    //         }
    //     });
    //     toast.success('Saved Successfully');
    //     onLoad();

    // }
    
    const onSave = async () => {
        let saveData = {};

        Object.keys(selectedFields).map((key) => {
            if (selectedFields[key].isSelected && (key !== 'Genres' && key !== 'SubGenres' && key !== 'linearRights' && key !== 'nonLinearRights')) {
                saveData[key] = selectedFields[key].value;
            }
        });

        const updateSelectedItem = (itemId, updatedFields) => {
            setSelectedItems((prevItems) =>
                prevItems.map(item => item._id === itemId ? { ...item, ...updatedFields } : item)
            );
            setGridData((prevGrid) =>
                prevGrid.map(gridItem => gridItem._id === itemId ? { ...gridItem, ...updatedFields } : gridItem)
            );
        };

        if (selectedFields.Genres.isSelected) {
            let saveGenres = { Genres: selectedFields.Genres.value?.map(x => ({ _id: x._id, Description: x.Description })) || [] };
            await Promise.all(selectedItems.map(async (item) => {
                let genresSaveRs = await API.saveData(entityName, { ...item, ...saveGenres });
                if (!genresSaveRs.success) {
                    toast.error('Failed to save');
                } else {
                    updateSelectedItem(item._id, saveGenres);
                }
            }));
        }

        if (selectedFields.SubGenres.isSelected) {
            let saveSubGenres = { SubGenres: selectedFields.SubGenres.value?.map(x => ({ _id: x._id, Description: x.Description })) || [] };
            await Promise.all(selectedItems.map(async (item) => {
                let subGenresSaveRs = await API.saveData(entityName, { ...item, ...saveSubGenres });
                if (!subGenresSaveRs.success) {
                    toast.error('Failed to save');
                } else {
                    updateSelectedItem(item._id, saveSubGenres);
                }
            }));
        }

        if (selectedFields.linearRights.isSelected) {
            let saveLinearRights = selectedFields.linearRights.value.map(item => {
                delete item._id;
                return item;
            });
            const publishingSource = mediaCategory?.SID === MEDIACATEGORIES.Series ? PUBLISHING_SOURCE.Series
                : mediaCategory?.SID === MEDIACATEGORIES.Season ? PUBLISHING_SOURCE.Season
                    : PUBLISHING_SOURCE.MediaEpisode;

            await Promise.all(selectedItems.map(async (item) => {
                let mediaDealRightsRes = await API.updateMediaDealRights(item, [], saveLinearRights, publishingSource);
                if (!mediaDealRightsRes.success) {
                    toast.error('Failed to save');
                } else {
                    updateSelectedItem(item._id, { Publishings: saveLinearRights });
                }
            }));
        }

        if (selectedFields.nonLinearRights.isSelected) {
            let saveNonLinearRights = selectedFields.nonLinearRights.value.map(item => {
                delete item._id;
                return item;
            });
            const publishingSource = mediaCategory?.SID === MEDIACATEGORIES.Series ? PUBLISHING_SOURCE.Series
                : mediaCategory?.SID === MEDIACATEGORIES.Season ? PUBLISHING_SOURCE.Season
                    : PUBLISHING_SOURCE.MediaEpisode;

            await Promise.all(selectedItems.map(async (item) => {
                let mediaDealRightsRes = await API.updateMediaDealRights(item, [], saveNonLinearRights, publishingSource);
                if (!mediaDealRightsRes.success) {
                    toast.error('Failed to save');
                } else {
                    updateSelectedItem(item._id, { Publishings: saveNonLinearRights });
                }
            }));
        }

        if (Object.keys(saveData).length === 0) {
            toast.success('Saved Successfully');
            return;
        }

        await Promise.all(selectedItems.map(async (item) => {
            let finalData = isHideMetaDataFields ? {
                ...item,
                Languages: saveData?.Languages?.map(x => ({ _id: x._id, Description: x.Description })) || [],
                PGRating: saveData?.PGRating && Object.keys(saveData.PGRating).length > 0 ?
                    { _id: saveData.PGRating._id, Description: saveData.PGRating.Description } : {},
                ProductionYear: saveData?.ProductionYear,
                Keywords: saveData?.Keywords
            } : DataHelper.saveMetaDataEntity({ ...item.Metadata, ...saveData }, item);

            let response = await API.saveData(isHideMetaDataFields ? entityName : ENTITYNAME.MediaEpisodeMetaData, finalData);
            if (!response.success) {
                toast.error('Failed to save');
            } else {
                updateSelectedItem(item._id, isHideMetaDataFields ? finalData : { Metadata: finalData });
            }
        }));

        toast.success('Saved Successfully');
    };


    const onMediadealrightsSave = (data) => {
        console.log(data);
        if (data.PlatformType.SID == PLATFORMTYPE[0].SID) {
            setSelectedFields((old) => ({ ...old, linearRights: { ...old.linearRights, value: [...old.linearRights.value, data] } }));
        }
        else {
            setSelectedFields((old) => ({ ...old, nonLinearRights: { ...old.nonLinearRights, value: [...old.nonLinearRights.value, data] } }));
        }
    }

    const onMediadealrightsClose = (isLinear) => {
        setShowMediadealrights({ show: !showMediadealrights.show, isLinear: isLinear });
    }

    const onMediadealrightsDelete = async (data) => {
        if (data.PlatformType.SID == PLATFORMTYPE[0].SID) {
            setSelectedFields((old) => ({ ...old, linearRights: { ...old.linearRights, value: old.linearRights.value.filter((item) => item._id !== data._id) } }));
        }
        else {
            setSelectedFields((old) => ({ ...old, nonLinearRights: { ...old.nonLinearRights, value: old.nonLinearRights.value.filter((item) => item._id !== data._id) } }));
        }
    }

    return (
        <div style={{ position: "absolute", top: "91px", right: selectedItems.length > 0 ? 0 : "-500px", width: "500px", height: "76%", backgroundColor: "white", transition: 'all 0.3s linear', overflow: 'auto' }} >
            <div style={{ padding: "0 10px" }}>
                <div className="flex-container mb-2">
                    <h1 style={{ fontFamily: "Roboto", fontSize: "24px" }}>Edit Multiple Assets ({selectedItems.length})</h1>
                    <div className="flex-container-reverse flex-item-auto" style={{ padding: '9px' }}>
                        <SaveButton onClick={onSave} />
                        <CancelButton onClick={() => setSelectedItems([])} />
                    </div>
                </div>
                {/* PGRating */}
                {mediaCategory.isLong && <div style={style}>
                    <div>
                        <Checkbox name="PGRating" value={selectedFields.PGRating.isSelected} onChange={onCheckBoxSelection} />
                        <span style={subHeadingStyle}>Pg Rating</span>
                    </div>
                    <DropDownList
                        disabled={!selectedFields.PGRating.isSelected}
                        style={{ width: width }}
                        data={pgRatings}
                        name="PGRating"
                        textField="Description"
                        dataItemKey="_id"
                        onChange={onFieldValueChange}
                    />
                </div>}
                {/* Censor Rating */}
                {!isHideMetaDataFields && mediaCategory.isLong && <div style={style}>
                    <div>
                        <Checkbox name="CensorRating" value={selectedFields.CensorRating.isSelected} onChange={onCheckBoxSelection} />
                        <span style={subHeadingStyle}>Censor Rating</span>
                    </div>
                    <DropDownList
                        disabled={!selectedFields.CensorRating.isSelected}
                        style={{ width: width }}
                        data={censorRatings}
                        name="CensorRating"
                        textField="Description"
                        dataItemKey="_id"
                        onChange={onFieldValueChange}
                    />
                </div>}
                {/* Languages */}
                {mediaCategory.isLong && <div style={style}>
                    <div>
                        <Checkbox name="Languages" value={selectedFields.Languages.isSelected} onChange={onCheckBoxSelection} />
                        <span style={subHeadingStyle}>Languages</span>
                    </div>
                    <MultiSelect
                        disabled={!selectedFields.Languages.isSelected}
                        style={{ width: width, maxHeight: "60px", overflow: "auto", border: "1px solid #E0E0E0", borderRadius: "4px", padding: "0 10px" }}
                        data={languages}
                        name="Languages"
                        textField="Description"
                        dataItemKey="_id"
                        onChange={onFieldValueChange}
                    />
                </div>}
                {/* Production Year */}
                {mediaCategory.isLong && <div style={style}>
                    <div>
                        <Checkbox name="ProductionYear" value={selectedFields.ProductionYear.isSelected} onChange={onCheckBoxSelection} />
                        <span style={subHeadingStyle}>Production Year</span>
                    </div>
                    <input
                        disabled={!selectedFields.ProductionYear.isSelected}
                        name='ProductionYear'
                        onChange={onFieldValueChange}
                        type="number"
                        style={{ width: width, height: "30px", border: "1px solid #E0E0E0", borderRadius: "4px", padding: "0 10px" }}
                    />
                </div>}
                {/* Keywords */}
                {mediaCategory.isLong && <div style={style}>
                    <div>
                        <Checkbox name="Keywords" value={selectedFields.Keywords.isSelected} onChange={onCheckBoxSelection} />
                        <span style={subHeadingStyle}>Keywords</span>
                    </div>
                    <input
                        disabled={!selectedFields.Keywords.isSelected}
                        name='Keywords'
                        onChange={onFieldValueChange}
                        type="text"
                        style={{ width: width, height: "30px", border: "1px solid #E0E0E0", borderRadius: "4px", padding: "0 10px" }}
                    />
                </div>}
                {/* Categories */}
                {!isHideMetaDataFields && mediaCategory.isLong && <div style={style}>
                    <div>
                        <Checkbox name="Categories" value={selectedFields.Categories.isSelected} onChange={onCheckBoxSelection} />
                        <span style={subHeadingStyle}>Categories</span>
                    </div>
                    <MultiSelect
                        disabled={!selectedFields.Categories.isSelected}
                        style={{ width: width, maxHeight: "60px", overflow: "auto", border: "1px solid #E0E0E0", borderRadius: "4px", padding: "0 10px" }}
                        data={categories}
                        dataItemKey="_id"
                        textField="Name"
                        onChange={onFieldValueChange}
                        name={"Categories"}
                    />
                </div>}
                {/* Genres */}
                {mediaCategory.isLong && <div style={style}>
                    <div>
                        <Checkbox name="Genres" value={selectedFields.Genres.isSelected} onChange={onCheckBoxSelection} />
                        <span style={subHeadingStyle}>{lang.genres_label}</span>
                    </div>
                    <MultiSelect
                        disabled={!selectedFields.Genres.isSelected}
                        style={{ width: width, maxHeight: "60px", overflow: "auto", border: "1px solid #E0E0E0", borderRadius: "4px", padding: "0 10px" }}
                        data={genres}
                        dataItemKey="_id"
                        textField="Description"
                        onChange={onFieldValueChange}
                        name={"Genres"}
                    />
                </div>}
                {/* Sub Genres */}
                {mediaCategory.isLong && <div style={style}>
                    <div>
                        <Checkbox name="SubGenres" value={selectedFields.SubGenres.isSelected} onChange={onCheckBoxSelection} />
                        <span style={subHeadingStyle}>{lang.sub_genres_label}</span>
                    </div>
                    <MultiSelect
                        disabled={!selectedFields.SubGenres.isSelected}
                        style={{ width: width, maxHeight: "60px", overflow: "auto", border: "1px solid #E0E0E0", borderRadius: "4px", padding: "0 10px" }}
                        data={genres}
                        dataItemKey="_id"
                        textField="Description"
                        onChange={onFieldValueChange}
                        name={"SubGenres"}
                    />
                </div>}
                {/* Audio Track */}
                {!isHideMetaDataFields && mediaCategory.isLong && <div style={style}>
                    <div>
                        <Checkbox name="AudioTrack" value={selectedFields.AudioTrack.isSelected} onChange={onCheckBoxSelection} />
                        <span style={subHeadingStyle}>Audio Track</span>
                    </div>
                    <div style={{ width: width, borderRadius: "4px" }}>
                        <AudioTrack disabled={!selectedFields.AudioTrack.isSelected} dataItem={{ AudioTrack: [] }} setAudioTrackData={setAudioTrackData} />
                    </div>
                </div>}
                {/* linear */}
                <div style={style}>
                    <div>
                        <Checkbox name="linearRights" value={selectedFields.linearRights.isSelected} onChange={onCheckBoxSelection} />
                        <span style={subHeadingStyle}>Linear Rights</span>
                    </div>
                    <div style={{ width: width, borderRadius: "4px" }}>
                        <AddRoundButton disabled={!selectedFields.linearRights.isSelected} onClick={() => onMediadealrightsClose(true)} />
                        <div style={{ overflow: "auto", marginTop: "10px" }}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Channel</th>
                                        <th className="text-nowrap" scope="col">Start Date</th>
                                        <th className="text-nowrap" scope="col">End Date</th>
                                        <th className="text-nowrap" scope="col">Is Unlimited</th>
                                        <th scope="col">Exhibition</th>
                                        <th className="text-nowrap" scope="col">Repeat</th>
                                        <th className="text-nowrap" scope="col">Play Time Number</th>
                                        <th className="text-nowrap" scope="col">Play Time</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedFields.linearRights.value?.map((publishingData, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><DeleteRoundButton disabled={!selectedFields.linearRights.isSelected} onClick={() => ConfirmDeleteAlert(() => onMediadealrightsDelete(publishingData), () => { })} /></td>
                                                <td className="text-nowrap" style={tableDataStyle}>
                                                    {utility.displayArrayInPipe(publishingData?.Channel, 'FullChannelName')}
                                                </td>
                                                <td className="text-nowrap" style={tableDataStyle}>
                                                    {moment(publishingData?.PublishStartDate).utc().format('DD-MM-YYYY')}
                                                </td>
                                                <td className="text-nowrap" style={tableDataStyle}>
                                                    {moment(publishingData?.PublishEndDate).utc().format('DD-MM-YYYY')}
                                                </td>
                                                <td style={tableDataStyle}>
                                                    <Checkbox style={{ borderColor: "grey" }} disabled={true} value={publishingData?.IsUnlimited} />
                                                </td>
                                                <td style={tableDataStyle}>
                                                    {publishingData?.Exhibitions}
                                                </td>
                                                <td style={tableDataStyle}>
                                                    {publishingData?.Repeats}
                                                </td>
                                                <td style={tableDataStyle}>
                                                    {publishingData?.PlayTimeNumber}
                                                </td>
                                                <td style={tableDataStyle}>
                                                    {publishingData?.PlayTime?.Description}
                                                </td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/* non-linear */}
                <div style={style}>
                    <div>
                        <Checkbox name="nonLinearRights" value={selectedFields.nonLinearRights.isSelected} onChange={onCheckBoxSelection} />
                        <span style={subHeadingStyle}>Non-Linear Rights</span>
                    </div>
                    <div style={{ width: width, borderRadius: "4px" }}>
                        <AddRoundButton disabled={!selectedFields.nonLinearRights.isSelected} onClick={() => onMediadealrightsClose(false)} />
                        <div style={{ overflow: "auto", marginTop: "10px" }}>
                            <table className="table">
                                <thead>
                                    <tr>
                                        <th scope="col"></th>
                                        <th scope="col">Platform</th>
                                        <th className="text-nowrap" scope="col">Start Date</th>
                                        <th className="text-nowrap" scope="col">End Date</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {selectedFields.nonLinearRights.value?.map((publishingData, index) => {
                                        return (
                                            <tr key={index}>
                                                <td><DeleteRoundButton disabled={!selectedFields.nonLinearRights.isSelected} onClick={() => ConfirmDeleteAlert(() => onMediadealrightsDelete(publishingData), () => { })} /></td>
                                                <td className="text-nowrap" style={tableDataStyle}>
                                                    {utility.displayArrayInPipe(publishingData?.Platform, 'Description')}
                                                </td>
                                                <td className="text-nowrap" style={tableDataStyle}>
                                                    {moment(publishingData?.PublishStartDate).utc().format('DD-MM-YYYY')}
                                                </td>
                                                <td className="text-nowrap" style={tableDataStyle}>
                                                    {moment(publishingData?.PublishEndDate).utc().format('DD-MM-YYYY')}
                                                </td>
                                            </tr>
                                        )
                                    })}

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {/* <div style={style}>
                    <div>
                        <Checkbox name="OttAsset" value={selectedFields.OttAsset.isSelected} onChange={onCheckBoxSelection} />
                        <span style={subHeadingStyle}>Ott Asset</span>
                    </div>
                    <div>
                        <ActionButton name={'Enable Ott Asset'} />
                    </div>
                </div> */}
            </div>
            {showMediadealrights.show && <MediaDealRightEditForm isLinear={showMediadealrights.isLinear} onSave={onMediadealrightsSave} onClose={onMediadealrightsClose} />
            }
        </div>
    )
};

export default memo(MultipleContentEditPanel);