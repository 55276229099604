/* eslint-disable */
import { useState, useEffect } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from 'react-toastify';
import { LocalDateTimeColumn } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { utility } from "../../framework/utility/utilityProvider";
import { useTranslation } from "../../locale/useTranslation";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { ENTITYNAME, FILEURL, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import moment from "moment";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import { AuditLogResponse } from "./AuditLogResponse";


export const AuditLogCollection = (props) => {

  //possible props
  let page_take = props.page_take ?? 50;
  let showBack = props.showBack ?? true;
  let showTitle = props.showTitle ?? true;
  let gridRowRender = props.gridRowRender;
  let filterable = props.filterable ?? false;
  let sortable = props.sortable ?? false;
  let isPageable = props.isPageable ?? false;
  let wherestatement = props.wherestatement;
  let andWhereStatement = props.andWhereStatement;
  let myTitle = props.title ?? "";

  const [gridData, setGridData] = useState([]);
  const [total, setTotal] = useState(1);
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [showArchived, setShowArchived] = useState(false);
  const [modules, setModules] = useState([]);
  const [showResponse, setShowResponse] = useState([]);
  const [showResponseOpen, setShowResponseOpen] = useState(false);
  const [activitys, setActivitys] = useState([]);
  const [oldValue, setOldValue] = useState({});
  const [newValue, setNewValue] = useState({});
  const [detailsValue, setDetailsValue] = useState({});

  const location = useLocation();

  // location.search its means there is no query parameters we do not load data
  const urlParams = new URLSearchParams(location?.search ?? "");
  const _id = urlParams?.get('_id');
  const module = urlParams?.get('module');
  const title = urlParams?.get('title') ?  myTitle + " of " + urlParams?.get('title') : myTitle;
  const sloatDateTime = urlParams?.get('sloatDateTime');
  const channelSID = urlParams?.get('channel');
  const header_id = urlParams?.get('Header_id');

  const [dataItem, setDataItem] = useState({
    StartDate: (location.search != "" && module == MODULE.SCHEDULING) ? moment(parseInt(sloatDateTime)).startOf("day").format("YYYY-MM-DD") : location.search != "" ? moment().startOf("months").format("YYYY-MM-DD") : moment(new Date()).subtract(1, 'w').format("YYYY-MM-DD"),
    EndDate: (location.search != "" && module == MODULE.SCHEDULING) ? moment(parseInt(sloatDateTime)).endOf("day").format("YYYY-MM-DD") : location.search != "" ? moment().endOf("months").format("YYYY-MM-DD") : moment(new Date()).add(1, 'd').format("YYYY-MM-DD"),
    Module: {},
    Activity: { Name: "ALL", SID: 0 },
  });

  const [page, setPage] = useState({
    skip: 0,
    take: page_take,
  });

  //only to get wherestatement from href cell
  const lang = useTranslation();

  let href = location.state ? location.state.href : false;
  if (href) {
    wherestatement = location.state.wherestatement
  }

  useEffect(() => {
    loadcombo();
    // location.search its means there is no query parameters we do not load data
    if (location.search != "" && location.search != null) {
      getDataFromQuery();
    }
  }, []);

  const getDataFromQuery = async () => {
    let filterQuerys = [];
    if(module == MODULE.SCHEDULING){
      filterQuerys = [["Value.Channel.SID", "=", parseInt(channelSID)], ["Module", "=", parseInt(module)], ["Value.SlotDateTime", ">=", new Date(dataItem.StartDate).getTime()], ["Value.SlotDateTime", "<=", new Date(dataItem.EndDate).getTime() + 86400000]];
    }else if(module == MODULE.PLANNING){
      filterQuerys = [_id ? ["Entity_id", "like", _id] : ["Header_id", "like", header_id], ["Module", "=", parseInt(module)], ["Value.SlotDateTime", ">=", new Date(dataItem.StartDate).getTime()], ["Value.SlotDateTime", "<=", new Date(dataItem.EndDate).getTime() + 86400000]];
    }
    else{
      filterQuerys = [["Entity_id", "like", _id], ["Module", "=", parseInt(module)], ["EntryDateTime", ">=", new Date(dataItem.StartDate).getTime()], ["EntryDateTime", "<=", new Date(dataItem.EndDate).getTime() + 86400000]];
    }
    console.log(filterQuerys);
    loaddata({ per_page: page_take, current_page: 1 }, filterQuerys, andWhereStatement, "", showArchived);
  }

  const loadcombo = async () => {
    let modulesRes = await API.getDataLookup(ENTITYNAME.Module, {sort: { Name: 1 }});
    if(location.search != "" && location.search != null){
      setDataItem({ ...dataItem, Module: modulesRes.data.find(x => x.SID  == module) });
    }
    setModules([...modulesRes?.data, { Name: "LOG IN", _id: "", SID: MODULE.LOGIN }]);

    let activityRes = Object.keys(LOGEVENT).map((key) => {
      return { Name: LOGEVENT[key], SID: key }
    })
    activityRes.unshift({ Name: "ALL", SID: 0 });
    setActivitys(activityRes)
  }

  const loaddata = async (pagination, wherestatement, andWhereStatement, searchText, archive) => {
    try {
      setIsLoading(true);
      let json = await API.getEntitiesWithSearch(props.entityname,
        {
          query: wherestatement,
          page: pagination.current_page,
          perPage: pagination.per_page,
          archive: archive
        }, "", searchText);
      setGridData(json.data);
      setTotal(json.pagination.total);
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
      setIsLoading(false);
    }
  }


  const pageChange = async (e) => {
    let current_page = e.page.skip / e.page.take + 1;
    if(dataItem.Activity.Name == "ALL"){
      var filterQuerys = [["Module", "=", dataItem.Module.SID], ["EntryDateTime", ">=", new Date(dataItem.StartDate).getTime()], ["EntryDateTime", "<=", new Date(dataItem.EndDate).getTime() + 86400000]]
    }else{
      var filterQuerys = [["Module", "=", dataItem.Module.SID], ["Event", "=", dataItem.Activity.Name], ["EntryDateTime", ">=", new Date(dataItem.StartDate).getTime()], ["EntryDateTime", "<=", new Date(dataItem.EndDate).getTime() + 86400000]]
    }
    await loaddata({ per_page: e.page.take, current_page: current_page, orderby: "SID", direction: "asc" }, filterQuerys, andWhereStatement,showArchived);

    setPage(e.page);
  };

  const onChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  }

  const isValidFilter = () => {

    if (Object.keys(dataItem.Module).length == 0) {
      toast.error(`${lang.please_select_module_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (Object.keys(dataItem.Activity).length == 0) {
      toast.error(`${lang.please_select_Activity_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (!utility.isValidDate(dataItem.StartDate)) {
      toast.error(`${lang.please_select_start_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;

    }
    if (!utility.isValidDate(dataItem.EndDate)) {
      toast.error(`${lang.please_select_end_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.StartDate > dataItem.EndDate) {
      toast.error(`${lang.end_date_should_be_greater_than_start_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true;
  }

  const showFilterData = () => {

    if (isValidFilter()) {      
      if (dataItem.Activity.Name == "ALL") {
        var filterQuerys = [["Module", "=", dataItem.Module.SID], ["EntryDateTime", ">=", new Date(dataItem.StartDate).getTime()], ["EntryDateTime", "<=", new Date(dataItem.EndDate).getTime() + 86400000]]
      }else{
        var filterQuerys = [["Module", "=", dataItem.Module.SID], ["Event", "=", dataItem.Activity.Name], ["EntryDateTime", ">=", new Date(dataItem.StartDate).getTime()], ["EntryDateTime", "<=", new Date(dataItem.EndDate).getTime() + 86400000]]
      }
      loaddata({ per_page: page_take, current_page: 1 }, filterQuerys, andWhereStatement, "", showArchived);
    }

    setPage({ skip: 0, take: page_take });

  }

  // Export Excel

  const downloadExcel = async () => {

    if (gridData.length == 0) {
        toast.error(`${lang.no_record_found_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
        return;
    }
    let storeData = gridData.map(item => {
        return {
          EntryDateTime: moment(item.EntryDateTime).format('YYYY-MM-DD HH:mm:ss'),
          Message: item.Message,
          User: item.User.name,
          Event: item.Event,
        }
    })
    console.log(storeData);
    let res = await API.exportCSV(storeData);
    if (res.success) {
        window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
        toast.success(`${lang.successfully_download_success_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
    else {
        toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT
        });
    }
}

  const handleShowResponse = async (item) => {

    let getOldData = await API.getData(ENTITYNAME.UserActivityLogs, {
      query: [
          ["Entity_id", "=", item.Entity_id],
          ["EntryDateTime", '<', item.EntryDateTime],
          ['Module', '=', item.Module]
      ],
      sort: { EntryDateTime: -1 },
      page: 1,
      perPage: 1,
    });

    setNewValue(item?.Value ? item?.Value : {});
    setOldValue(getOldData.data.length != 0 ? getOldData.data[0]?.Value : {});
    setDetailsValue({
      EntryDateTime: moment(item?.EntryDateTime).format('YYYY-MM-DD HH:mm:ss'),
      User: item?.User?.name,
      Event: item?.Event
    });
    setShowResponseOpen(true);

  }

  const RoundedButton = (props) => <RoundButton icon={"eye"} style={{ marginLeft: '20px', marginTop: '5px' }} title={lang.show_response_label} onClick={() => handleShowResponse(props.dataItem)} />

  const onCloseResponse = () => {
    setShowResponseOpen(false);
    setShowResponse([]);
  }

  return (
      <div>
        {showTitle && (
          <>
            <h1 className="page-title txt-color-bludeDark">
              <i
                className="fa fa-table marlr"
                style={{ fontSize: "20px", color: "GrayText" }}
              ></i>
              <span>{title}</span>
            </h1>
          </>
        )}
        <div className="flex-container">
          <div className="martb mar">
            {showBack && (
              <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
            )}
          </div>

          <div className="flex-container-reverse flex-item-auto mb-2 mr-2">

            <div className="m-1" style={{ paddingTop: "12px" }}>
              <RoundButton
                icon={"download"}
                btnColor={"warning"}
                style={{ margin: "0px 03px" }}
                // iconColor={"white"}
                title={lang.download_button_tooltip}
                onClick={downloadExcel}
              /></div>
            <div className="mt-1" style={{ paddingTop: "12px" }} >
              <ActionButton title={lang.show_button_tooltip} name={lang.show_button_text} onClick={showFilterData} />
            </div>
            <div className="ml-3 mr-2" style={{ marginTop: '-7px' }}>
              <label htmlFor="">{lang.end_Date_label} *</label>
              <input
                name={"EndDate"}
                className="form-control form-control-sm"
                type={'date'}
                value={dataItem.EndDate}
                onChange={onChange}
              />
            </div>
            <div className="ml-2" style={{ marginTop: '-7px' }}>
              <label htmlFor="">{lang.start_date_label} *</label>
              <input
                name={"StartDate"}
                className="form-control form-control-sm"
                type={'date'}
                value={dataItem.StartDate}
                onChange={onChange}
              />

            </div>
            <div className="col-2" style={{ marginTop: '-7px' }}>
              <label htmlFor="TabView">{lang.activity_label} *</label>
              <DropDownList
                style={{
                  backgroundColor: "white",
                }}
                data={activitys}
                name="Activity"
                textField="Name"
                dataItemKey="SID"
                value={dataItem.Activity}
                onChange={onChange}
              />
            </div>

            <div className="col-2" style={{ marginTop: '-7px' }}>
              <label htmlFor="TabView">{lang.module_label} *</label>
              <DropDownList
                style={{
                  backgroundColor: "white",
                }}
                data={modules}
                name="Module"
                textField="Name"
                dataItemKey="SID"
                value={dataItem.Module}
                onChange={onChange}
              />
            </div>
          </div>
        </div>
        {isLoading && <Loader />}

        {!isLoading &&
          <Grid
            style={props.gridstyle ?? {
              height: "76vh",
            }}
            data={gridData}
            rowRender={gridRowRender}
            filterable={filterable}
            sortable={sortable}
            pageable={isPageable}
            skip={page.skip}
            take={page.take}
            onPageChange={pageChange}
            total={total}
          >
            <GridColumn field="Event" title={lang.event_column} />
            <GridColumn field="User.name" title={lang.user_column} />
            <GridColumn field="EntryDateTime" title={lang.entry_date_and_time_column} cell={LocalDateTimeColumn} />
            <GridColumn field="Message" title={lang.message_column} />
            <GridColumn
              width={"100px"}
              cell={RoundedButton}
              title={lang.show_details_column}
            />
          </Grid>}

        {showResponseOpen && <AuditLogResponse details={detailsValue} oldValue={oldValue} newValue={newValue} onClose={()=> onCloseResponse()} />}
      </div>
  );
};
