/* eslint-disable */
import React, { useState, useEffect, useRef  } from "react";
import * as API from "../../framework/API/api";
import { FILEURL, REPORTTYPE, ENTITYNAME, MODULETYPES } from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useTranslation } from "../../locale/useTranslation";
import BossDialog from "../../components/BossDialog";


export const MissingMediaReport = (props) => {

    const blankDataItem = { ModuleType: {}, ReportType: REPORTTYPE[0] }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const navigate = useNavigate();
    const [channel, setChannel] = useState([]);
    const lang = useTranslation();
    const payloadRef = useRef({});
    const selectedChannelRef = useRef();
    const setSelectedChannelRef = (data) => {
        selectedChannelRef.current = data;
    }

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        var channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelRes.data);
    }

    const isValidforExport = () => {

        if (dataItem.Channel == undefined || Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.ScheduleDate == undefined || dataItem.ScheduleDate.length == 0) {
            toast.error(`${lang.please_select_schedule_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        
        return true;
    }

    const exportData = async () => {
        if (isValidforExport()) {
            var scheduleDate = new Date(dataItem.ScheduleDate).getTime();
            payloadRef.current = {
                scheduledate : scheduleDate,
                channelsid : selectedChannelRef.current.SID,
            }
            console.log(payloadRef.current)
            loadData();     
        }
    }

    const loadData = async () => {        
        var res = await API.getMissingMediaReport(payloadRef.current);
        if (res.success) {
            window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
                toast.success('Successfully Exported!', {
                    position: toast.POSITION.TOP_RIGHT
                });
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };

    const onChange = async (e) => {      
        if (e.target.name == 'Channel') {
            setSelectedChannelRef(e.target.value)
        }  
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    };

    return (<>
        {<BossDialog
            title={lang.missing_media_report_dialog_header}
            onClose={() => navigate('/home')}
            width={"650px"}
        >
        
            <div className="col-12" style={{ margin: "02px 0px 0px 0px" }}>
                <div className="row">         
                    <div className="col-12 form-group">
                        <label htmlFor="TabView">{lang.channel_label} *</label>
                        <DropDownList
                            data={channel}
                            name={"Channel"}
                            textField="FullChannelName"
                            dataItemKey="_id"
                            value={dataItem.Channel}
                            onChange={onChange}
                        />
                    </div>                
                </div>
                <div className="row">
                    <div className="col-12 form-group">
                        <label htmlFor="TabView">{lang.schedule_date_label} *</label>
                        <input className="form-control form-control-sm" type={'date'} name={"ScheduleDate"} value={dataItem.ScheduleDate} onChange={onChange} />
                    </div>
                </div>
            </div>
            <div className="col-4" style={{ margin: "02px 0px 0px 0px" }}>
            <div className="row">
            <div className="col-12 form-group">
                <ActionButton title={lang.export_button_tooltip} name={lang.export_button_text} onClick={exportData} style={{ margin: '0px', borderRadius: '5px' }} />
                </div>  </div>  
            </div>
            
        </BossDialog>}
    </>)
}
export default MissingMediaReport;