import React, { useEffect, useState } from 'react'
import { EditPageHeader } from '../../components/EditPageHeader';
import { useTranslation } from '../../locale/useTranslation';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { ENTITYNAME, MEDIACATEGORIES } from '../../framework/constant/constant';
import * as API from '../../framework/API/api'
import { toast } from 'react-toastify';
import { TimePickerWithFormat } from '../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat';
import { utility } from '../../framework/utility/utilityProvider';
import BossDialog from '../../components/BossDialog';

const PlanningInsertDummyPopup = (props) => {

    const lang = useTranslation();
    const [mediaCategory, setMediaCategory] = useState([]);
    const [dataItem, setDataItem] = useState({})

    useEffect(() => {
        loadcombo()
    }, [])

    const loadcombo = async () => {
        let mediaCategoryRes = await API.getDataLookup(ENTITYNAME.MediaCategory, { sort: { SID: 1 } });
        let filteredIsLong = mediaCategoryRes.data.filter((x) => x.SID == MEDIACATEGORIES.Program || x.SID == MEDIACATEGORIES.Movie)
        setMediaCategory(filteredIsLong);
        setDataItem({ ...dataItem, MediaCategory: mediaCategoryRes.data.find((x) => x.SID == MEDIACATEGORIES.Program) })
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {

        if (!dataItem.MediaCategory || Object.keys(dataItem.MediaCategory).length == 0) {
            toast.error(`${lang.please_select_media_category_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.Title == undefined || dataItem.Title.trim() == "") {
            toast.error(`${lang.please_enter_title_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        if (dataItem.Duration == undefined || dataItem.Duration.includes('_') || utility.convertStringWithFramesToMilliseconds(dataItem.Duration) == 0) {
            toast.error(`${lang.duration_should_be_greater_than_zero_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

        return true;

    }

    const onInsertDummyPlanning = async () => {

        if (!isValid()) return;

        //PAYLOAD TO FETCH DUMMY MOVIE,PROGRAM,EPISODE
        let payload = {
            mediaCategory: dataItem.MediaCategory,
            isDummy: true
        }

        console.log(payload)
        let dummyMediaRes = await API.getDummyMedia(payload);
        console.log(dummyMediaRes)

        if(dummyMediaRes.success){
            //INSERT DUMMY IN PLANNING
            let startDateTime = props.selectedSlot.SlotDateTime;
            let selectedChannelIndex = props.selectedSlot.channelIndex;
            let selectedChannel = props.getSelectedChannel(selectedChannelIndex);

            console.log("insert dummy called");
            await props.addPlanningItem(
                startDateTime, 
                selectedChannel,
                dummyMediaRes.data,
                null,
                false,
                dataItem.Title,
                utility.convertStringWithFramesToMilliseconds(dataItem.Duration)
            );
            props.onClose()
        }else{
            toast.error(dummyMediaRes.message, {
                position: toast.POSITION.TOP_RIGHT
            });
            console.log(dummyMediaRes)
        }
    }

    const onClose = () => {
        props.onClose();
        setDataItem({
            MediaCategory: mediaCategory.find((x) => x.SID == MEDIACATEGORIES.Program),
            Title: '',
            Duration: '00:00:00:00',
        })
    }


    return (
        <>
            <BossDialog
                title={lang.insert_dummy_dialog_title}
                onClose={onClose}
                width={'500px'}
            >
                <EditPageHeader saveButtonTitle={'Insert'} title={""} onSubmit={onInsertDummyPlanning} onCancel={onClose} showTitle={false} />
                <div className="row mt-2">
                    <div className="col-6 form-group">
                        <label htmlFor="TabView">{lang.media_category_label} *</label>
                        <DropDownList
                            style={{ backgroundColor: "white" }}
                            data={mediaCategory}
                            name="MediaCategory"
                            textField="Description"
                            dataItemKey="_id"
                            value={dataItem.MediaCategory}
                            onChange={onChange}
                        />
                    </div>
                    <div className="col-6">
                        <label htmlFor="">{lang.duration_label} *</label>
                        <TimePickerWithFormat
                            className="form-control form-control-sm"
                            name="Duration"
                            value={dataItem.Duration}
                            onChange={(e) => onChange(e)}
                            required
                        />
                    </div>
                    <div className="col-12">
                        <label htmlFor="TabView">{lang.title_label} *</label>
                        <div className="form-group">
                            <input className="form-control form-control-sm" type="text" name="Title" onChange={onChange} value={dataItem.Title} />
                        </div>
                    </div>
                </div>
            </BossDialog>
        </>

    )
}

export default PlanningInsertDummyPopup