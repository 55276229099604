import { useTranslation } from "../../../../locale/useTranslation";

const RefreshButton = (props) => {

    const lang = useTranslation();

    return <button title={props.title ?? lang.refresh_button_tooltip} type="submit" onClick={props.onClick} style={props.style ?? {margin:"0px 02px"}} className = {`btn btn-default bg-${props.btnColor} btn-circle btn-my`} >
       <i className="fa fa-arrows-rotate fa-fw img-circle"></i>
    </button>
}

export default RefreshButton;
