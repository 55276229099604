/* eslint-disable */
import React, { useEffect, useState } from "react";
import { ENTITYNAME, LOCALSTORAGE_KEY, MODULE, SOCKET_EVENTS, WORKORDER_STATUS } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { DateCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { utility } from "../../framework/utility/utilityProvider";
import ContractCollectionSelection from "../Contract/ContractCollectionSelection";
import { useNavigate } from "react-router-dom";
import socket from "../../framework/socket/socket";
import BossDialog from "../../components/BossDialog";

export const WorkOrderEditForm = (props) =>  {
    const currentDateMillisec = utility.getLocalDateTimeToUtcMiliseconds(new Date());
    const currentUser = utility.getValue(LOCALSTORAGE_KEY.userData);
    const lang = useTranslation();
    const navigate = useNavigate();
    const [dataItem, setDataItem] = useState({ 

        SID : props.item.SID,
        Title:  props.item.Title,
        CurrentStatus : props.item.CurrentStatus ?? '',
        Workflow: props.item.Workflow ?? {},
        CurrentRole: props.item.CurrentRole ?? {},
        User : props.item.User ??  {_id : '',SID : 0,name : 'All'},
        Notes: props.item.Notes ?? '',
        History : props.item.History ?? [],
        CreatedDate : currentDateMillisec,
        Media : props.item.Media ?? {Title : ''},
        Archive : props.item.Archive ?? false,

    });
    
    const [workflow, setWorkflow] = useState([]);
    const [allUsers, setAllUsers] = useState([]);
    const [users,setUsers] = useState([{_id : '',SID : 0,name : 'All'}]);
    const [showAssignToMe,setShowAssignToMe] = useState(false);

    const [mediaOpen, setMediaOpen] = useState(false);
    const [selectedMedia,setSelectedMedia] = useState(props.item.Media ?? {Title : ''});


    useEffect(() => {
        loadcombo();
    },[]);

    const isValid = () => {

      if(dataItem.Title == "" || dataItem.Title == undefined){
        toast.error(`${lang.please_enter_title_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
      if(Object.keys(dataItem.CurrentStatus).length==0){    
        toast.error(`${lang.please_select_status_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });                        
        return false;
      }
      if(Object.keys(dataItem.Workflow).length==0){    
        toast.error(`${lang.please_select_workflow_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });                        
        return false;
      }
      if(Object.keys(dataItem.CurrentRole).length==0){    
        toast.error(`${lang.please_select_role_users_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });                        
        return false;
      }
      if(Object.keys(dataItem.Media).length==0){    
        toast.error(`${lang.please_attach_media_first}`, {
          position: toast.POSITION.TOP_RIGHT
        });                        
        return false;
      }
      return true;
  
    }
    

    const loadcombo = async () => {
        var workflow = await API.getDataLookup(ENTITYNAME.Workflow, {sort: { Description: 1 }});
        setWorkflow(workflow.data);
        var users = await API.getDataLookup(ENTITYNAME.Users, {sort: { name : 1 }});
        setAllUsers(users.data);

        //fetch user if SID > 0
        if(props.item.SID > 0){
          var filteredUser = [];
          users.data.map((item) => {
            var count = item.Role.filter(x => x.SID == props.item.CurrentRole.SID).length;
            if(count > 0){
                filteredUser.push(item);
            }
          })

          if(props.item.User.SID == 0){
            filteredUser.map((x) => {
               if(x._id == currentUser._id){
                 setShowAssignToMe(true);
               }
            })
          }

        setUsers([{_id : '',SID : 0,name : 'All'},...filteredUser]);
        }
    }

    const onchange = (e) => {
      if(e.target.name == "Workflow"){
        setDataItem({ ...dataItem, [e.target.name]: e.target.value, Role : {},User : {_id : '',SID : 0,name : 'All'}});
      }else if(e.target.name == "CurrentRole"){
        setDataItem({ ...dataItem, [e.target.name]: e.target.value ,User : {_id : '',SID : 0,name : 'All'}});

        var filteredUser = [];
        allUsers.map((item) => {
            var count = item.Role.filter(x => x.SID == e.target.value.SID).length;
            if(count > 0){
                filteredUser.push(item);
            }
        })

        if(dataItem.User.SID == 0){
          filteredUser.map((x) => {
             if(x._id == currentUser._id){
               setShowAssignToMe(true);
             }
          })
        }

        setUsers([{_id : '',SID : 0,name : 'All'},...filteredUser]);
      }else if(e.target.name == "User"){
        setDataItem({ ...dataItem,CurrentStatus : WORKORDER_STATUS[0], [e.target.name]: e.target.value });
      }else if(e.target.name == "Archive"){
        setDataItem({ ...dataItem, Archive : !dataItem.Archive });
      }else{
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
      }
    }

    const handleSubmit = async () => {

      if(isValid()){ 

        //ADD LOGS IN HISTORY
        var historyDataItem = {
            Status : dataItem.CurrentStatus,
            Date : currentDateMillisec,
            Role : dataItem.CurrentRole,
            User : { _id : dataItem.User._id  ,Name : dataItem.User.name}
        }

        var localData = {...dataItem,
            SID : dataItem.SID,
            Title:  dataItem.Title,
            CurrentStatus : dataItem.CurrentStatus ?? '',
            Workflow: dataItem.Workflow ?? {},
            CurrentRole: dataItem.CurrentRole ?? {},
            Notes: dataItem.Notes ?? '',
            History : [...dataItem.History,historyDataItem] ?? [],
            CreatedDate : currentDateMillisec,
            User : {_id : dataItem.User._id, SID : dataItem.User.SID, name : dataItem.User.name },
            Media : dataItem.Media ?? {},
            Archive : dataItem.Archive ?? false,
            checkDuplicate:true,
            query:[["Title", "=", dataItem.Title]]
        }


        console.log(localData)

        var res = await API.saveData(ENTITYNAME.WorkOrder, localData);
        if(res.success){
            props.cancelEdit();
            props.refresh();
            console.log("emitting")
            socket.emit(SOCKET_EVENTS.onSocketNotification, { user : currentUser,  module: MODULE.WORK_ORDER, data : [localData] ,reciever : { role : localData.CurrentRole,  user : localData.User } });
            return;
        }else{
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        }
    }

    const openMedia = (e) => {    
        if(Object.keys(selectedMedia).length > 1){
          navigate(`/home/MediaEpisode/${selectedMedia.MediaCategory.Description}/${selectedMedia.SID}`,{state : {copy : false}})
        }else{
          console.log('Select Media');
          toast.info(lang.select_media_info_message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
        e.preventDefault();
    }

    const handleMediaSelect = (dataList,src) => {
        setSelectedMedia(dataList[0]);
    
        var tempSource = src;
    
        if(src.Description != 'Series' && src.Description != 'Season'){
            tempSource = {Description : 'mediaepisode'}
        }
    
        setDataItem({
          ...dataItem,
          Media : {_id : dataList[0]._id, SID : dataList[0].SID,  Title : dataList[0].Title ,MediaCategory : dataList[0].MediaCategory, entityName : tempSource.Description.toLowerCase()}
        })
    
      }
    
    const deleteSelectedMedia = () =>{
        setSelectedMedia({Title: ""});
        setDataItem({...dataItem, Media : {} })
    }

    const onAssignToMe = () => {
      setDataItem({ ...dataItem,CurrentStatus : WORKORDER_STATUS[0], User : {_id : currentUser._id , SID : currentUser.SID , name : currentUser.name}});
    }

  

  return (
    <>
        {<BossDialog
            title={props.item.SID > 0 ? props.item.Title : `${lang.workorder_dialog_header}`}
            onClose={props.cancelEdit}
            width={"900px"}
            height={"670px"}
        >
        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false}/>

                    <div className="row mt-3">
                    <div className="col-12">
                        <div className="row">
                            <div className="col-9 form-group">
                                <label htmlFor="TabView">{lang.title_label} *</label>
                                <input className="pl-2" type = "text" style={{border : 'solid lightgrey 1px' , height : "30px"}} name= {"Title"} onChange={onchange} value={dataItem.Title}/>
                            </div> 
                             
                            <div className="col-3 form-group">
                            <label htmlFor="TabView">{lang.status_label} *</label>
                            <DropDownList
                                style={{ backgroundColor:"white" }}
                                data={WORKORDER_STATUS}
                                name="CurrentStatus"
                                textField="Description"
                                dataItemKey="ID"
                                value={dataItem.CurrentStatus}
                                onChange={onchange}
                                validator={(value) => value ? "" : "Please select the value"}
                                />  
                            </div>
                        </div>

                        <div className="row">
                            <div className="col-4 form-group">
                            <label htmlFor="TabView">{lang.workflow_label} *</label>
                            <DropDownList
                                style={{ backgroundColor:"white" }}
                                data={workflow}
                                name="Workflow"
                                textField="Name"
                                dataItemKey="_id"
                                value={dataItem.Workflow}
                                onChange={onchange}
                                disabled={props.item.SID > 0 ? true : false}
                                validator={(value) => value ? "" : "Please select the value"}
                                />  
                            </div>
                            <div className="col-4 form-group">
                            <label htmlFor="TabView">{lang.department_label} *</label>
                            <DropDownList
                                style={{ backgroundColor:"white" }}
                                data={dataItem.Workflow.Roles ?? []}
                                name="CurrentRole"
                                textField="Name"
                                dataItemKey="_id"
                                value={dataItem.CurrentRole}
                                onChange={onchange}
                                validator={(value) => value ? "" : "Please select the value"}
                                />  
                            </div>
                            <div className="col-4 form-group">
                            <label htmlFor="TabView">{lang.user_column} </label>
                            <DropDownList
                                style={{ backgroundColor:"white" }}
                                data={users}
                                name="User"
                                textField="name"
                                dataItemKey="_id"
                                value={dataItem.User}
                                onChange={onchange}
                                validator={(value) => value ? "" : "Please select the value"}
                                />
                              {showAssignToMe && dataItem.User.SID == 0 && <label htmlFor="TabView" style={{color : 'blue' , textAlign :"right"}} onClick={onAssignToMe}> Assign to me </label>}
                            </div>
                        </div>

                        <div className="row" >
                        <div className="col-8" >
                            <div className="form-group">
                                <label htmlFor="">{lang.media_label} *</label>
                                <input
                                  name={"Media"}
                                  className="form-control form-control-sm"
                                  type = {'text'}
                                  value = {selectedMedia.Title}
                                  disabled = {true}
                                  onChange={()=>""}
                                />
                            </div>
                          </div>
                          
                          <button type="button" className="ml-2 k-button k-button-md k-rounded-md k-button-solid k-button-solid-success"
                            style={{ height: "30px", marginTop: "20px" }} onClick={(e) => { setMediaOpen(true); e.preventDefault(); }}>
                            {'...'}
                          </button>

                          {/* button to show media */}
                          <button type="button" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-warning"
                            style={{ height: "30px", marginTop: "20px", marginLeft : '3px' }} onClick={openMedia}>
                            <i className="fa-solid fa-eye"></i>
                          </button>
                          <button type="button" className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-info"
                            style={{ height: "30px", marginTop: "20px", marginLeft : '3px'}} onClick={(e)=>{deleteSelectedMedia();  e.preventDefault() }}>
                            <i className="fa-solid fa-trash"></i>
                          </button>
                      </div>

                        <div className="row">
                            <div className="col-12 form-group">
                                <div><label htmlFor="TabView">{lang.notes_label} </label></div>
                                <div><textarea className="pl-2" type = "text" style={{border : 'solid lightgrey 1px' ,width : '100%',height:'120px'}} name= {"Notes"} onChange={onchange} value={dataItem.Notes}/></div>                 
                            </div>
                        </div>

                        <div className="row">
                          <div className="col-3  form-group">
                            <input type = "checkbox" name= {"Archive"} value={dataItem.Archive} checked= {dataItem.Archive} onChange={onchange}/>  
                            <label className="pl-2" htmlFor="TabView">{lang.archive}</label> 
                          </div>
                        </div>

                        <div className="row" style={{ margin : "3px" }}>
                            <div>{lang.history_label}</div>
                            <Grid style={{ height: "20vh"}} data={props.item.History ?? []}>
                                <Column field="Status.Description" title={lang.status_column} editable={false} />
                                <Column field="Date" title={lang.date_column} editable={false} cell={DateCell} />
                                <Column field="Role.Name" title={lang.department_label} editable={false} />
                                <Column field="User.Name" title={lang.user_column} editable={false} />
                            </Grid>
                        </div>


                    </div>
                    </div>

        {mediaOpen && <ContractCollectionSelection addButtonTitle={lang.select_button_text} title={lang.media_library_dialog_header} wherestatement={['MediaCategory.isLong','=',true]} setDataList={handleMediaSelect} entityname={ENTITYNAME.MediaEpisode} closeForm={() => setMediaOpen(false)} mode = {'single'}  width={"50vw"} height={"60vh"} hideSeriesSeason={true}/>}
        </BossDialog>}
    </>

  )
}