import React, { useEffect } from 'react';
import { ProgressBar } from "@progress/kendo-react-progressbars";
import useSocket from '../../socket/useSocket';
import { LOCALSTORAGE_KEY, SOCKET_ACTION, SOCKET_EVENTS } from '../../constant/constant';
import { startTransition } from 'react';

const ProgressBarPopup = ({ ModuleID }) => {

    const [show, setShow] = React.useState({
        okButton: false,
        progressBarPopup: false,
    });
    const [progressData, setProgressData] = React.useState({
        message: '',
        progress: 0,
    });

    useSocket(SOCKET_EVENTS.onProgressUpdate, (socketData) => {
        let userId = localStorage.getItem(LOCALSTORAGE_KEY.user_id).replace(/"/g, '');
        if (socketData.module != ModuleID) return;
        switch (socketData.action) {
            case SOCKET_ACTION.PROGRESS:
                if (socketData.userId && socketData.userId.length > 0 && socketData.userId != userId) return;
                onprogress(socketData.data);
                break;

            default:
                break;
        }
    })

    const onprogress = (data) => {
        setProgressData({
            message: data.message,
            progress: data.progress,
        });
        setShow({
            okButton: data.progress == 100 ? true : false,
            progressBarPopup: true,
        });
    }


    return (
        show.progressBarPopup && <div id='myProgressBar'>
            <div className='myProgressBarBox'>
                <ProgressBar
                    label={(props) => {
                        return <strong>{props.value}%</strong>;
                    }}
                    labelPlacement='center'
                    progressStyle={{ backgroundColor: "green" }}
                    value={progressData.progress}
                    style={{ width: "360px" }}
                />
                <div className='message'>
                    <p>{progressData.message}</p>
                </div>
                {show.okButton && <button onClick={() => { setShow({ ...show, progressBarPopup: false }); }} style={{ width: "50px" }} className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base bg-success text-white' >Ok</button>}
            </div>
        </div>
    );
}

export default ProgressBarPopup;
