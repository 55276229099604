/* eslint-disable */
import React, { useEffect, useState } from "react";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import * as API from "../framework/API/api";
import { EditPageHeader } from "./EditPageHeader";
import { useTranslation } from "../locale/useTranslation";
import { ENTITYNAME } from "../framework/constant/constant";
import { toast } from "react-toastify";
import BossDialog from "./BossDialog";

const LanguageTranslator = (props) => {

    const lang=useTranslation();

    const blankDataItem = {
        Language : {},
        TextArea1 : "",
        TextArea2: "",
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [languages, setLanguages] = useState([]);

    useEffect(() => {
        loadCombo();
    }, []);

    const loadCombo = async () => {
        let languageRes  = await API.getDataLookup(ENTITYNAME.Language, {sort: { Description: 1 }});
        setLanguages(languageRes.data);
    }


    const onChange = (e) => {
        const { name, value } = e.target;
        setDataItem({
            ...dataItem,
            [name]: value
        });
    }
    
    const isValid = () =>{
        if( dataItem.TextArea2 == undefined || dataItem.TextArea2 == ""){
            toast.error(`${lang.please_translate_first_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const isValidOnTranslate = () =>{
        if( dataItem.TextArea1 == undefined || dataItem.TextArea1 == ""){
            toast.error(`${lang.please_enter_text_area_first_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if( dataItem.Language == undefined || Object.keys(dataItem.Language).length == 0){
            toast.error(`${lang.please_select_language_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if( dataItem.Language.GoogleApiCode == undefined || dataItem.Language.GoogleApiCode == ""){
            toast.error(`${lang.missing_google_api_code_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const handleTranslate = async () => {

        if(!isValidOnTranslate()) return;

        let data = {
            text : dataItem.TextArea1,
            target : dataItem.Language?.GoogleApiCode,
        }
        let res = await API.languageTranslator(data.text, data.target);
        console.log(res);
        if(res.success){
            setDataItem({
                ...dataItem,
                TextArea2 : res.data
            });
        }
        else{
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }
    
    const handleSubmit = async () => {
        console.log("here");
        if(!isValid()) return;
        props.onSubmit(dataItem.TextArea2);
    }

    return (
        <BossDialog
            title={lang.translate_dialog_header}
            onClose={props.cancelEdit}
            width={"600px"}
            height={"430px"}
        >
            <div className="row">
                <div className="col-12">
                    <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />

                    <div className="row mt-2 form-group">
                        <div className="col-12">
                            <label htmlFor="TabView">{lang.text_area_label} *</label>
                            <textarea
                                name="TextArea1"
                                className="form-control form-control-sm"
                                value={dataItem.TextArea1}
                                onChange={onChange}
                            />
                        </div>
                    </div> 
                    <div className="row form-group">
                        <div className="col-12">
                            <label htmlFor="TabView">{lang.language_label} *</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={languages}
                                name="Language" 
                                textField="Description"
                                dataItemKey="_id"
                                value={dataItem.Language}
                                onChange={onChange}
                            />
                        </div>
                    </div> 
                    {/* <ActionButton style={{marginLeft: "0px"}} name={lang.translate_button_name} onClick={handleTranslate}/>  */}
                    <div className="row mt-2 form-group">
                         <div className="col-12">
                             <button style={{borderRadius: '5px'}} className="btn btn-sm btn-success" onClick={handleTranslate}>
                                 <i className="fa-solid fa-language"></i> {lang.translate_button_name}
                             </button>
                         </div>
                    </div>
                    <div className="row form-group mt-2">
                        <div className="col-12">
                            <label htmlFor="TabView">{lang.text_area_label} *</label>
                            <textarea
                                name="TextArea2"
                                className="form-control form-control-sm"
                                value={dataItem.TextArea2}
                                onChange={onChange}
                                disabled
                            />
                        </div>
                    </div>
                </div>
            </div>
        </BossDialog>
    )
}

export default LanguageTranslator;
