/* eslint-disable */
import React, { useState, useEffect, memo, useRef } from 'react'
import { Grid, GridColumn as Column, GridNoRecords } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { DateOnlyCell } from '../../framework/forms/helpercomponents/CustomGridCells/DateCell';
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import RoundButton from '../../framework/forms/helpercomponents/buttons/RoundButton';
import moment from 'moment';
import { FILEURL, PLANNINGTYPE } from '../../framework/constant/constant';
import BossDialog from '../../components/BossDialog';

const ContractRunReport = memo(({ selectedData, closeForm }) => {

    var data = Array.isArray(selectedData) ? selectedData : [selectedData];

    useEffect(() => {
        loadContractRunReport();
    }, []);

    const lang = useTranslation();

    const [gridData, setGridData] = useState([]);
    const [loadingStatus, setLoadingStatus] = useState(`${lang.data_loading_error_message}`);

    const loadContractRunReport = async () => {

        var tempData = []

        for (let i = 0; i < data.length; i++) {
            var res = await API.getContractRunReport(data[i]);
            console.log(res.data);
            if (res.success) {
                tempData = [...tempData, ...res.data]
            }
            else {
                setLoadingStatus(`${lang.no_record_found_error_message}`);
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }

        }
        setGridData(() => tempData.map(data => {
            return {
                ...data,
                AssetId: data.mediaEpisode ? data.mediaEpisode[0].AssetId : "",
                mediaEpisode: data.mediaEpisode ? data.mediaEpisode[0] : "",
            }
        }))
        if (gridData.length == 0) {
            setLoadingStatus(`${lang.no_record_found_error_message}`);
        }

    }

    const downloadExcel = async () => {

        if (gridData.length == 0) {
            toast.info(lang.no_data_found_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }

        var storeData = gridData.map(x => {
            return {
                Title: x.mediaEpisode.Title,
                AssetId: x.mediaEpisode.AssetId,
                TotalRun: x.TotalRun,
                ExhibitionNumber: x.ExhibitionNumber,
                RepeatNumber: x.RepeatNumber
            }
        })
        var res = await API.exportCSV(storeData);
        if (res.success) {
            window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
            toast.success(`${lang.successfully_download_success_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    return (
        <>
            <BossDialog
                title={lang.contract_run_report_label}
                onClose={closeForm}
                width={"50%"}
            >
                {gridData.length > 0 && <RoundButton icon='download' onClick={() => downloadExcel()} style={{ marginLeft: '5px' }} title={lang.download_button_tooltip} />
                }
                <Grid data={gridData} style={{ height: "50vh", marginTop: "04px", overflow: "hidden" }}>
                    <GridNoRecords>
                        {loadingStatus}
                    </GridNoRecords>
                    <Column field="mediaEpisode.Title" title={lang.title_column} width={188} />
                    <Column field="AssetId" title={lang.assetId_column} width={188} />
                    <Column field="Total" title={lang.total_run_column} width={180} />
                    <Column field="ExhibitionNumber" title={lang.exhibition_no_column} width={180} />
                    <Column field="RepeatNumber" title={lang.repeat_no_column} width={180} />
                </Grid>
            </BossDialog>
        </>
    )
});

export default ContractRunReport;