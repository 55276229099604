import React, { useState } from 'react'
import { EditPageHeader } from '../../components/EditPageHeader'
import { useTranslation } from '../../locale/useTranslation'
import { toast } from 'react-toastify'
import { ENTITYNAME, LOGEVENT, MODULE } from '../../framework/constant/constant'
import { utility } from '../../framework/utility/utilityProvider'
import * as API from "../../framework/API/api";
import BossDialog from '../../components/BossDialog'

const DealTypeEditForm = (props) => {
    const lang = useTranslation();
  
    const blankDataItem = {
      ...props.item,
      SID: props?.item?.SID ?? 0,
      Description: props?.item?.copy ? 'copy of ' + props?.item?.Description : props?.item?.Description ?? '',
      Archive: props?.item?.Archive ?? false
    }
  
    const [dataItem, setDataItem] = useState(blankDataItem);
 
    function validationCheck() {
  
      if (dataItem.Description == undefined || dataItem.Description == "") {
        toast.error(`${lang.please_enter_description_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false;
      }
      return true;
    }
  
    const handleSubmit = async (e) => {
  
      if (validationCheck()) {
        const saveData = {
          _id: dataItem._id,
          SID: dataItem.SID ?? 0,
          Description: dataItem.Description,
          Archive: dataItem.Archive ?? false,
          checkDuplicate: true,
          query: [["Description", "=", dataItem.Description]],
        }
        console.log(saveData);
  
        let res = await API.saveData(ENTITYNAME.DealType, saveData);
        if (res.success) {
          let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.DEALTYPE, data: res.data, message: res.message };
          API.SaveLogs(logData);
          utility.deleteLocalStorageItem(ENTITYNAME.DealType);
          props.cancelEdit();
          props.refresh();
        }
        else {
          toast.error(res.message, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
      }
    }
  
    const onChange = (e) => {
  
      if (e.target.name == "Archive") {
        setDataItem({ ...dataItem, Archive: !dataItem.Archive });
      } else {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
      }
  
    }
  
    return (
      <BossDialog
        title={props.item.SID > 0 ? props.item.Description : "Create Deal Type"}
        onClose={props.cancelEdit}
        width={"500px"}
      >
        <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
        <div className="row mt-2">
                <div className="col-12">
                    <label>{lang.description_label} *</label>
                    <input
                        name={"Description"}
                        type="text"
                        className="form-control form-control-sm"
                        onChange={onChange}
                        value={dataItem.Description}
                    />
                </div>
                <div className="col-3 mt-3" >
                    <input type={"checkbox"} name={"Archive"}
                        onChange={(e) => onChange(e)}
                        value={dataItem.Archive}
                        checked={dataItem.Archive} />
                    <label className="ml-1">{lang.archive}</label>
                </div>
            </div>
      </BossDialog>)
}

export default DealTypeEditForm
