/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import CollectionSelection from "../forms/CollectionSelection.js";
import * as API from '../API/api.js'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { RailsColumns } from "../utility/customColumns.js";
import { utility } from "../utility/utilityProvider.js";
import ActionButton from '../forms/helpercomponents/buttons/ActionButton.js'
import { FixedSidebarWithIcons } from "../../components/FixedSidebarWithIcons.js";
import { CardList } from "../../components/CardList.js";
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { ACCESS_LEVEL, ENTITYNAME, LOGEVENT, MODULE, RAILTYPE, SOCKET_ACTION, SOCKET_EVENTS } from "../constant/constant.js";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation.js";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import 'material-symbols';
import RoundButton from "../forms/helpercomponents/buttons/RoundButton.js";
import Preview from "../../modules/platformLayout/tv/homePage/components/Preview.js";
import { Switch } from "@mui/material";
import socket from "../socket/socket.js";
import BossDialog from "../../components/BossDialog.jsx";

export const MyRailsContext = React.createContext({
  Rails: [],
  SetRails: () => { }

});

export const PageEditForm = (props) => {

  const location = useLocation();
  const navigate = useNavigate();
  const { SID } = useParams();

  let CustomEditForm = props.customEditForm
  const PAGEOTTASSETDELETE = 'PageOttAssetDelete';
  const NEWPAGEOTTASSETDELETE = 'NewPageOttAssetDelete';

  const [dataItem, setDataItem] = useState({
    SID: 0,
    Name: '',
    DisplayName: '',
    icon: {},
    accessLevel: {_id: 0, Description: 'Both'},
    Archive: false,
    showAppBar: false,
    IsParentMenuItem : false,
    SubMenu : {}
  });
  const [selectionCollectionOpen, setSelectionCollectionOpen] = useState(false);
  const [railOpen, setRailOpen] = useState(false);
  const [Rails, SetRails] = useState([]);
  const [deleteRailsSID, setDeleteRailsSID] = useState([]);
  const [openRail, setOpenRail] = useState(true);
  const [addRail, setAddRail] = useState(false);
  const [showRailsEditForm, setShowRailsEditForm] = useState(false);
  const [showIconUrl, setShowIconUrl] = useState(false);
  const lang = useTranslation();
  const [icons, _setIcons] = useState([]);
  const [showPreviewCollection, setShowPreviewCollection] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [subMenus,_setSubMenus] = useState([]);
  const [accessLevels, _setAccessLevel] = useState([]);
  const [selectedIconColor, setSelectedIconColor] = useState({
    Mobile: true,
    TV: true,
    Web: true,
    Tab : true
  })
  const [railsCollectionIcon, setRailsCollectionIcon] = useState({
    Mobile: true,
    TV: true,
    Web: true,
    Tab : true
  })

  const subMenusRef = useRef();
  const setSubMenus = (data) => {
    subMenusRef.current = data;
    _setSubMenus(data);
  }

  const iconsRef = useRef();
  const setIcons = (data) => {
    iconsRef.current = data;
    _setIcons(data);
  }

  const accessLevelRef = useRef();
  const setAccessLevels = (data) => {
    accessLevelRef.current = data;
    _setAccessLevel(data);
  }
  let isFromCopy = location.state?.copy;

  useEffect(() => {
    pageRender();
    // eslint-disable-next-line
  }, [])

  const pageRender = async() => {
    await loadcombo();

    if (SID > 0) {
      await loadEditData();
    }

  }

  const loadcombo = async () => {
    let iconsRes = await API.getDataLookup(ENTITYNAME.OttIcons, { sort: { Description: 1 }, query: ['Archive', '=', false] });
    setIcons(iconsRes.data);

    let menuRes = await API.getDataLookup(ENTITYNAME.OttMenu, { sort: { Description: 1 }, query: ['Archive', '=', false] });
    setSubMenus(menuRes.data);

    let accessLevelRes = Object.keys(ACCESS_LEVEL).map((key, index) => {
      return { _id: index, Description: key }
    })
    setAccessLevels(accessLevelRes);
  }

  const handleChange = (e) => {
    if (e.target.name == "Archive") {
      setDataItem({ ...dataItem, Archive: !dataItem.Archive })
    } else if (e.target.name == "showAppBar") {
      setDataItem({ ...dataItem, showAppBar: !dataItem.showAppBar })
    } else if (e.target.name == "IsHomePage") {
      setDataItem({ ...dataItem, IsHomePage: !dataItem.IsHomePage })
    }  else if(e.target.name === "IsParentMenuItem"){
      setDataItem({ ...dataItem, IsParentMenuItem: !dataItem.IsParentMenuItem });
    } else {
      setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
  };

  const handleSelectionCollectionForm = () => {
    setSelectionCollectionOpen(!selectionCollectionOpen);
    setRailOpen(false);
  }

  const handleRailDataList = (dataList) => {
    //added from Rail selection
    SetRails(old => [...old, ...dataList.map((rail) => Object.assign(rail, { SID: 0, RailSID: rail.SID }))]);
  }

  const loadEditData = async () => {

    let ott_types = await API.getDataLookup(ENTITYNAME.OttRailType);
    let ott_Platform_Types = await API.getDataLookup(ENTITYNAME.Platform);

    let linkData = {
      link_collection: 'ott_rails',
      link_field: 'ott_rails'
    }

    let res = await API.getEntity(ENTITYNAME.Page, parseInt(SID), linkData);
   
    if (res.success) {
      let data = {
        ...res.data,
        Name: isFromCopy ? "copy of " + res.data.Name : res.data.Name,
        icon: iconsRef.current.find((x) => x.iconCode == res.data.IconUrl),
        accessLevel: accessLevelRef.current.find((x) => x._id == res.data.accessLevel),
        SubMenu : res.data?.IsParentMenuItem ? subMenusRef.current.find((x) => x._id == res.data.SubMenu) : {}
      }

      //reset id to add as new entry
      if (isFromCopy) {
        data.SID = 0;
        delete data['_id']
      }

      setDataItem(data);
     
      //if come from collection then it would not be null 
     
      let rails = [];
      if(location.state?.item != null)
      {
         rails = location.state.item.Rails;
      }
      else
      {
        let json = await API.getPages({query:['SID', '=', parseInt(SID)]})  
        if(json.data.length > 0)
        {
          console.log(json.data[0].Rails);
          rails = json.data[0].Rails
        }
        
      }
        let final_data_rail = rails.map((item) => {
          return {
            ...item,
            PotraitImageUrl: ott_types.data?.find((data) => data.SID == item.OttRailTypeSID)?.Icon,
            RailType: ott_types.data?.find((data) => data.SID == item.OttRailTypeSID)?.Description,
            PlatformTypes: ott_Platform_Types.data?.filter((data) => item.PlatformSIDs?.includes(data.SID))
          }
        });
        SetRails(final_data_rail)

      
     
    } 
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  }

  //function to check the validation in form
  function isValid() {

    if (dataItem.Name === "" || dataItem.Name === undefined) {
      toast.error(`${lang.please_enter_name_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.icon === undefined || Object.keys(dataItem.icon).length == 0) {
      toast.error(`${lang.please_select_icon_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!dataItem.accessLevel || Object.keys(dataItem.accessLevel).length == 0) {
      toast.error(`${lang.please_select_access_level_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (dataItem.IsParentMenuItem && Object.keys(dataItem.SubMenu).length == 0) {
      toast.error(`${lang.please_select_sub_menu_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if(Rails.length > 1 && Rails.some(x => x.OttRailTypeSID == RAILTYPE.IsFullPage)){
        toast.error(`${lang.some_attached_rails_are_full_page_type_and_can_be_attached_independently_only_error_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        return false
    }

    return true;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isValid()) {
      const data = {
        _id: dataItem._id,
        SID: dataItem.SID,
        Name: dataItem.Name,
        DisplayName: dataItem.DisplayName,
        IconUrl: dataItem.icon?.iconCode,
        accessLevel: dataItem.accessLevel?._id ?? ACCESS_LEVEL.Both,
        ott_rails: Rails.length > 0 ?  Rails.map(Rail => Rail._id) : [],
        Archive: dataItem.Archive ?? false,
        showAppBar: dataItem.showAppBar ?? false,
        IsParentMenuItem : dataItem.IsParentMenuItem ?? false,
        SubMenu : dataItem.IsParentMenuItem ? dataItem.SubMenu._id : ''
      }

      console.log(data)

      let res = await API.saveData(ENTITYNAME.Page, data)
      if (res.success) {
        let logData = {event: data.SID==0 ? LOGEVENT.CREATE_PAGE : LOGEVENT.UPDATE_PAGE, module: MODULE.PAGE, data: res.data, message: res.message };
        API.SaveLogs(logData);
        utility.deleteLocalStorageItem(ENTITYNAME.Page);
        toast.info(`${lang.successfully_saved_updated_message}`, {
          position: toast.POSITION.TOP_RIGHT
        });
        if(data.SID == 0 || isFromCopy){
          navigate('/home/Page');
        }
        //delay for 1 sec
        setTimeout(() => {

          socket.emit(SOCKET_EVENTS.onSocketData, {
            action: SOCKET_ACTION.PLATFORM_PAGE_REFRESH,
            module: MODULE.PLATFORM,
            data: {"pageId": res?.data?._id},
            // user: { name: userData.name, _id: userData._id }
        });
          
        },200)

        
      }
    }
  }



  const deleteRails = (index, dataItem) => {
    console.log(index);
    SetRails(old => old.filter((item, i) => {
      return index !== i;
    }))
    if (dataItem.SID !== 0) {
      setDeleteRailsSID(old => [...old, dataItem.SID])
    }
  }

  // const editRails = (index, dataItem) => {
  //   dataItem?.RailSID ? window.open(`/home/Rails/${dataItem.RailSID}`, '_blank') : window.open(`/home/Rails/${dataItem.SID}`, '_blank')
  // }

  const editRails = (index, dataItem) => {
    const url = dataItem?.RailSID ? `/home/Rails/${dataItem.RailSID}` : `/home/Rails/${dataItem.SID}`;
    
    // Open the new tab
    window.open(url, '_blank');
  
    // Optionally, you can preload resources for this URL before opening the new tab
    preloadResources(url);  // Implement this function to preload resources
  };
  
  
  const preloadResources = (url) => {
    const link = document.createElement('link');
    link.rel = 'prefetch';
    link.href = url;
    document.head.appendChild(link);
  };
  

  const draggedRailItem = useRef();
  const draggedRailLibraryItem = useRef();
  const draggedOverRail = useRef();
  const selectedDraggedItem = useRef();

  const onDragStart = (e, position, selectedDataItem) => {
    draggedRailItem.current = position;
    selectedDraggedItem.current = selectedDataItem;
  };

  const onDragEnter = (e, position, containerName) => {
    console.log(containerName + position);

    draggedRailItem.current = null;
    draggedOverRail.current = null;
    selectedDraggedItem.current = null;

    if (containerName === "Destination") {
      draggedOverRail.current = position;
    }
  };

  const moveUpDown = (index, dataItem, isMoveUp) => {
    if (index === 0 && isMoveUp) return;
    if (!isMoveUp && index === Rails.length - 1) return;

    console.log(dataItem);
    const copyRails = [...Rails];
    copyRails.splice(index, 1);
    isMoveUp ? copyRails.splice(index - 1, 0, dataItem) : copyRails.splice(index + 1, 0, dataItem);

    SetRails(copyRails);
  }

  const onDropOnRailsFromRails = (e) => {
    console.log(draggedOverRail);

    if (draggedOverRail.current == null) return;
    if (selectedIconColor.Mobile == false || selectedIconColor.TV == false || selectedIconColor.Web == false || selectedIconColor.Tab == false ) {
      toast.error(`${lang.please_select_all_platforms_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    
    const copyRails = [...Rails];
    copyRails.splice(draggedRailItem.current, 1);
    copyRails.splice(draggedOverRail.current, 0, selectedDraggedItem.current);
    draggedRailItem.current = null;
    draggedOverRail.current = null;
    SetRails(copyRails);
  }

  const onDropOnAssetsFromAssetLibrary = (e) => {
    
    //Object is coming from Other container
    console.log(draggedOverRail);

    if (draggedOverRail.current == null) return;

    const copyRails = [...Rails];
    copyRails.splice(draggedOverRail.current + 1, 0, Object.assign(selectedDraggedItem.current, { SID: 0, RailSID: selectedDraggedItem.current.SID }));
    draggedRailLibraryItem.current = null;
    draggedOverRail.current = null;
    console.log(copyRails);
    SetRails(copyRails);
    setSelectedIconColor({
      Mobile: true,
      TV: true,
      Web: true
    })
  }



  const sideFixMenu = [
    {
      icon: 'play',
      title: `${lang.sidefixmenu_rails_tooltip}`,
      onClick: () => { setOpenRail(old => !old) }
    },
  ]

  const getRailMenu = (index, dataItem) => {
    return [
      {
        label: `${lang.options_actionbutton_menutext}`,
        items: [
          { label: `${lang.edit_actionbutton_option}`, icon: 'pi pi-fw pi-pencil', command: () => editRails(index, dataItem) },
          { label: `${lang.delete_actionbutton_option}`, icon: 'pi pi-fw pi-trash', command: () => deleteRails(index, dataItem) },
          { label: `${lang.moveup_actionbutton_option}`, icon: 'pi pi-fw pi-arrow-up ', command: () => moveUpDown(index, dataItem, true) },
          { label: `${lang.movedown_actionbutton_option}`, icon: 'pi pi-fw pi-arrow-down', command: () => moveUpDown(index, dataItem, false) },
          { label: `${lang.campaign_actionbutton_option}`, icon: 'pi pi-fw pi-calendar', command: () => editRails(index, dataItem) },
        ]
      }
    ]
  }

  const handleIconUrl = (dataList) => {
    setDataItem({ ...dataItem, IconUrl: dataList[0].ImageUrl });
  }

  return (
    <>
      <FixedSidebarWithIcons menuModel={sideFixMenu} />
      <div className="myFlexContainer">
        {/* Rails */}
        {openRail && <CardList selectedIconColor={selectedIconColor} setSelectedIconColor={setSelectedIconColor} MenuModel={getRailMenu} customCard={true} showSearch={true} containerName="Destination" cardData={Rails} onDeleteDataItem={deleteRails} close={() => setOpenRail(false)} title={lang.cardlist_page_rails_title} onAddClick={() => { setAddRail(old => !old) }} onDragStart={onDragStart} onDragEnter={onDragEnter} onDrop={onDropOnRailsFromRails} inTileView={true} showTileView={false} />}
        {/* Add Rail Library */}
        {addRail && openRail && <CardList selectedIconColor={railsCollectionIcon} setSelectedIconColor={setRailsCollectionIcon} containerName="Source" customCard={true} showSearch={true} entityName={ENTITYNAME.Rails} close={() => setAddRail(false)} title={lang.cardlist_rails_page_title} onDragStart={onDragStart} onDragEnter={onDragEnter} onDrop={onDropOnAssetsFromAssetLibrary} inTileView={true} showTileView={false} />}

        {/* edit form */}
        <div className="main-layout">
          <Form
            initialValues={dataItem}
            onSubmit={handleSubmit}
            render={(formRenderProps) => (
              <FormElement>
                <div className="row mt-3 mb-3">
                  <div className="col float-left">
                    <ActionButton title={lang.save_button_tooltip} name={lang.save_button_text} onClick={(e) => handleSubmit(e)} />
                    <ActionButton title={lang.cancel_button_tooltip} name={lang.cancel_button_text} btnColor={'danger'} onClick={() => { utility.deleteLocalStorageItem(PAGEOTTASSETDELETE); utility.deleteLocalStorageItem(NEWPAGEOTTASSETDELETE); navigate("/home/Page"); }} />
                  </div>
                  {dataItem.SID > 0 && <div className="float-right mr-3">
                    <RoundButton icon={"tv"} title={lang.tv_preview_label} onClick={() => setShowPreviewCollection(true)} />
                    <RoundButton icon={"mobile"} title={lang.mobile_preview_label} onClick={() => { setShowPreviewCollection(true); setIsMobile(true) }} />
                  </div>}
                </div>
                <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px" }}>
                  <div className="row m-1">
                    <div className="col-12 mt-2">
                      <div className="row">
                      <div className="form-group col-6">
                        <label htmlFor="">{lang.name_label} *</label>
                        <input name="Name" type="text" className="form-control form-control-sm" id="Name" value={dataItem.Name} onChange={(e) => handleChange(e)} />
                      </div>
                        <div className="form-group col-6">
                        <label htmlFor="">{lang.display_name_label}</label>
                        <input name="DisplayName" type="text" className="form-control form-control-sm" id="DisplayName" value={dataItem.DisplayName} onChange={(e) => handleChange(e)} />
                      </div>
                      </div>
                      <div className="row form-group">
                        <div className="col-6">
                          <label htmlFor="">{lang.access_level_label} *</label>
                          <DropDownList
                            data={accessLevels}
                            style={{ backgroundColor: 'white' }}
                            name="accessLevel"
                            textField="Description"
                            dataItemKey="_id"
                            value={dataItem.accessLevel}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-5">
                          <label htmlFor="">{lang.icon_label} *</label>
                          <DropDownList
                            data={icons}
                            style={{ backgroundColor: 'white' }}
                            name="icon"
                            textField="Description"
                            dataItemKey="_id"
                            value={dataItem.icon}
                            onChange={handleChange}
                          />
                        </div>
                        <div className="col-1">
                          <span style={{ fontSize: '23px', marginLeft: '-15px' }} className="material-symbols-outlined mt-4">{dataItem.icon?.iconCode}</span>
                        </div>
                      </div>

                      <div className="row">
                        {dataItem.IsParentMenuItem && <div className="form-group col-6">
                          <label htmlFor="">{lang.submenu_label} *</label>
                          <DropDownList
                            data={subMenus}
                            style={{ backgroundColor: 'white' }}
                            name="SubMenu"
                            textField="Name"
                            dataItemKey="_id"
                            value={dataItem.SubMenu}
                            onChange={handleChange}
                          />
                        </div>}
                      </div>

                      <div className="row">

                        <div className="col-3  form-group">
                          <input type="checkbox" name={"showAppBar"} value={dataItem.showAppBar} checked={dataItem.showAppBar} onChange={(e) => handleChange(e)} />
                          <label className="pl-2" htmlFor="TabView">{lang.show_app_bar_label}</label>
                        </div>

                        <div className="col-3  form-group">
                          <input type="checkbox" name={"Archive"} value={dataItem.Archive} checked={dataItem.Archive} onChange={(e) => handleChange(e)} />
                          <label className="pl-2" htmlFor="TabView">{lang.archive}</label>
                        </div>

                        <div className="col-3">
                          <Field
                            name={"IsParentMenuItem"}
                            component={Switch}
                            label={lang.is_parent_menu_label}
                            onLabel={lang.is_parent_menu_label}
                            offLabel={lang.is_parent_menu_label}
                            size={"small"}
                            onChange={(e) => handleChange(e)}
                            checked={dataItem.IsParentMenuItem}
                          />
                          <label className="pl-2" htmlFor="TabView">{lang.is_parent_menu_label}</label>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </FormElement>
            )} />
        </div>
        {railOpen && <CollectionSelection entityname={"Rails"} customColumns={RailsColumns} setDataList={handleRailDataList} closeForm={handleSelectionCollectionForm} width={"40vw"} />}
        {showRailsEditForm && <CustomEditForm
          {...props}
          cancelEdit={(setShowRailsEditForm(false))}
          onSubmit={handleSubmit} />
        }
        {showIconUrl && <CollectionSelection entityname={ENTITYNAME.OttPosters} closeForm={() => setShowIconUrl(false)} setDataList={handleIconUrl} title={lang.select_icon_url_collection_header} wherestatement={['OttPosterTypeSID', '=', 4]} mode={"single"} width={"55vw"} />}
        {showPreviewCollection && <BossDialog
          title={isMobile ? lang.mobile_preview_label : lang.tv_preview_label}
          onClose={() => { setShowPreviewCollection(false); setIsMobile(false) }}
          width={isMobile ? "750px" : "1200px"}
          height={isMobile ? "580px" : "700px"}
        >
          <div className="row">
            <div className={isMobile ? "col mt-4" : "col"} style={isMobile ? { marginLeft: '13%', height: '100%', scale: '0.7', color: 'white' } : { marginTop: '-5px', marginLeft: '4px' }}>
              <div className="row" style={isMobile ? { display: "flex" } : { display: "block" }}>
                <Preview isMobile={isMobile} pageData={dataItem} ></Preview>
              </div>
            </div>
          </div>
        </BossDialog>}
      </div>
    </>)
};