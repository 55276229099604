import React from 'react'
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from '@progress/kendo-react-data-tools';

const MyInfoCell = (props) => {
    const field = props.field;
    const value = props.dataItem[field];
    let style = {
      ...props.style,
      backgroundColor: props.style.background,
    }

    let newStyle = {};

    if (value && value.length > 0) {
      newStyle = {
        ...props.style,
        backgroundColor: props.cellBackgroundColor ? props.cellBackgroundColor : 'red',
        color : props.cellTextColor ? props.cellTextColor : props.style.color,
        display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center",
        border: '0.05em solid #9a9c92', borderRadius: '4px',
        textAlign: props.titleTextAlign ? props.titleTextAlign : "center",
        fontWeight: props.titleFontWeight ? props.titleFontWeight : "normal",
        fontSize: props.titleFontSize ? props.titleFontSize : "12px",
        verticalAlign: props.titleVerticalAlignment ? props.titleVerticalAlignment : "center",
        fontFamily: props.titleFontFamily ? props.titleFontFamily : "Roboto",
        letterSpacing: "0.6px",
      }
    }

    return <td
      colSpan={props.colSpan}
      role={"gridcell"}
      title={value} //tooltip
      aria-colindex={props.ariaColumnIndex}
      aria-selected={props.isSelected}
      {...{
        [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
      }}
      {...useTableKeyboardNavigation(props.id)}
      // style={{textAlign : 'center'}}
      style={style}
    >
      <span style={newStyle} id={props.dataItem?._id}>
        {value && value.length > 15 ? value.substring(0, 15) + '...' : value}
      </span>
    </td>;
}

export default MyInfoCell