/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import * as API from "../../../framework/API/api_digitalSign";
import { LookupCell } from "../../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import { ImageCell } from "../../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { useTranslation } from "../../../locale/useTranslation";
import RefreshButton from "../../../framework/forms/helpercomponents/buttons/RefreshButton";
import { toast } from "react-toastify";
import { getter } from "@progress/kendo-data-query";
import DeleteRoundButton from "../../../framework/forms/helpercomponents/buttons/DeleteRoundButton";

export const PlaylistOverlayGrid = (props) => {

    const { selectedOverlayAsset } = props

    const lang = useTranslation();

    const [selectedState, setSelectedState] = useState([]);// multiple selection grid
    const [gridDataToShow, setGridDataToShow] = useState([]);
    const [asset, _setAsset] = useState(null);
    const assetRef = useRef(null);
    const setAsset = (data) => {
        _setAsset(data);
        assetRef.current = data
    }
    const [isLoading, setIsLoading] = useState(false);
    const [isNoRecords, setIsNoRecords] = useState(true);
    const DATA_ITEM_KEY = '_id';
    const SELECTED_FIELD = 'selected';
    const idGetter = getter(DATA_ITEM_KEY);

    useEffect(() => {
        setAsset(selectedOverlayAsset);
        if (selectedOverlayAsset) {
            loadData();
        } else {
            setIsLoading(false);
            setIsNoRecords(true);
            setGridDataToShow([]);
        }
    }, [selectedOverlayAsset]);

    const loadData = async () => {
        setIsLoading(true);
        let overlayIds = assetRef.current?.overlay ?? [];
        if (overlayIds.length == 0) {
            setIsLoading(false);
            setIsNoRecords(true);
            return;
        }
        let res = await API.getOverlaysByOverlayIds(overlayIds);
        console.log(res);
        if (res.success) {
            setGridDataToShow(res.data);
            setIsLoading(false);
            setIsNoRecords(false);
        } else {
            setIsLoading(false);
            setIsNoRecords(true);
            setGridDataToShow([]);
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });

        setSelectedState(newSelectedState);
    }

    const myDeleteCell = (event) => {
        return (
            <td>
                <DeleteRoundButton title={'Delete Overlay'} onClick={() => {
                    let overlayIds = [event.dataItem._id];
                    props.deleteOverlays(asset, overlayIds);
                    setGridDataToShow(gridDataToShow.filter(item => item._id != event.dataItem._id));
                    setAsset({ ...asset, overlay: asset.overlay.filter(id => id != event.dataItem._id) });
                }} />
            </td>
        );
    }

    return (
        <div style={{ height: "100%" }}>
            {isLoading && <i style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", fontSize: "26px" }} className="fas fa-spinner fa-pulse"></i>
            }
            {isNoRecords && <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%" }}><h6><strong>{'No Overlays Found'}</strong></h6></div>}

            {!isLoading && !isNoRecords &&
                <>
                    <p style={{ height: "20px", fontSize: "16px", fontWeight: "bold", paddingLeft: "10px" }}>{asset?.asset?.Title}     <RefreshButton onClick={() => loadData(asset)} />
                        <DeleteRoundButton title={'Delete Selected Overlays'} onClick={() => {
                            let overlayIds = Object.keys(selectedState);
                            overlayIds = overlayIds.filter(id => selectedState[id]);
                            if (overlayIds.length == 0) {
                                toast.error('No Overlay Selected', {
                                    position: toast.POSITION.TOP_RIGHT
                                })
                                return;
                            }
                            props.deleteOverlays(asset, overlayIds);
                            setGridDataToShow(gridDataToShow.filter(item => !overlayIds.includes(item._id)));
                            setAsset({ ...asset, overlay: asset.overlay.filter(id => !overlayIds.includes(id)) });
                        }} />
                    </p>
                    <Grid
                        style={
                            { height: "calc(100% - 40px)", width: "100%" }
                        }
                        data={gridDataToShow?.map(item => {
                            return { ...item, [SELECTED_FIELD]: selectedState[idGetter(item)], };
                        })}
                        dataItemKey={DATA_ITEM_KEY}
                        selectedField={SELECTED_FIELD}
                        selectable={{
                            enabled: true,
                            drag: true,
                            cell: false,
                            mode: {
                                value: 'multiple',
                                label: 'Multiple selection mode'
                            },
                        }}
                        resizable={true}
                        onSelectionChange={onSelectionChange}
                    >
                        <GridColumn width="60px"  cell={myDeleteCell} />
                        <GridColumn
                            field={'ImageUrl'}
                            cell={(props) => <ImageCell {...{ ...props, height: 30, objectFit: "contain" }} />}
                            title={'ImageUrl'}
                            width={80}
                        />
                        <GridColumn
                            field="Name"
                            title="Name"
                            width={200}
                        />
                        <GridColumn
                            field="Text"
                            title="Text"
                            width={200}
                        />
                        <GridColumn
                            field="Position.Description"
                            title="Position"
                            width={200}
                        />
                    </Grid>
                </>}
        </div>
    );
};
