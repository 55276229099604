import { useState, useEffect, startTransition, useRef } from 'react';
import * as API from '../../../API/api'
import { toast } from 'react-toastify';

const DynamicDropdown = ({
    data,
    enityname,
    defaultValue = "",
    textField = "Description",
    width = "100%",
    name = enityname ?? textField,
    value,
    onChange = (e) => { }
}) => {

    const [orignalData, setOrignalData] = useState([]);
    const [myData, setMyData] = useState([]);
    const [dropdDownValue, setDropdDownValue] = useState("");
    const [isFocused, setIsFocused] = useState(false);
    const isItemClickedRef = useRef(false);

    useEffect(() => {
        loadData();
    }, [enityname ?? data]);

    useEffect(() => {
        loadDropDownValue();
    }, [value]);

    const loadDropDownValue = () => {
        setDropdDownValue(value && (value[textField] ?? defaultValue[textField] ?? ""));
    }

    const loadData = async () => {
        
        if (data && data.length > 0) {
            setOrignalData(data);
            setMyData(data);
        } else if (enityname) {
                let result = await API.getDataLookup(enityname);
                if (result.success) {
                    setOrignalData(result.data);
                    setMyData(result.data);
                }
            }
    }

    const handleSearch = (e) => {
        if (isItemClickedRef.current) {
            isItemClickedRef.current = false;
        }
        if (!isFocused) {
            setIsFocused(true);
        }
        setDropdDownValue(e.target.value);
        if (e.target.value.length > 0) {
            startTransition(() => {
                let newData = orignalData.filter(item => item[textField]?.toLowerCase().includes(e.target.value.toLowerCase()));
                if (newData.length === 0 && enityname) {
                    setMyData([{ [textField]: <>{e.target.value}<span style={{ color: "#0A6EBD" }}>{` (New ${enityname})`}</span></>, new: true, value: e.target.value }]);
                } else {
                    setMyData(newData);
                }
            })
        } else {
            if(e.target.value == ""){
                onChange({ ...e, target: { ...e.target, name: name, value: "" } });
                return;
            }
            startTransition(() => {
                setMyData(orignalData);
            })
        }
    }

    const onBlur = () => {
        setTimeout(() => {
            setIsFocused(false);
            if (!isItemClickedRef.current && !orignalData.some(item => item[textField] == dropdDownValue)) {
                setDropdDownValue("");
            }
        }, 200);
    }
    const onFocus = () => {
        setIsFocused(true);
        setMyData(orignalData);
    }

    const createNew = async (value) => {
        const result = await API.saveData(enityname, { [textField]: value, Archive: false });
        if (result.success) {
            toast.success("New " + enityname + " created");
            localStorage.removeItem(enityname);
            loadData();
            return { success: true, data: result.data };
        } else {
            toast.error(result.message);
            return { success: false, data: null };
        }
    }

    const onItemClick = async (e) => {
        isItemClickedRef.current = true;
        console.log(e.target.value)
        if (e.target.value?.new) {
            let isCreated = await createNew(e.target.value.value);
            if (isCreated.success) {
                setDropdDownValue(isCreated.data[textField]);
                setIsFocused(false);
                onChange({ ...e, target: { ...e.target, value: isCreated.data } });
            }
        } else {
            setDropdDownValue(e.target.value[textField]);
            setIsFocused(false);
            onChange(e);
        }
    }

    return (

        <div style={{ width: width, position: "relative" }}>
            <input
                style={{
                    height: "33px",
                    width: "100%",
                    paddingLeft: "10px",
                    fontSize: "14px",
                    borderRadius: "10px",
                }}
                type="text"
                className="form-control shadow-inset-2 pt-0 pb-0"
                placeholder={"Select " + name}
                onChange={handleSearch}
                value={dropdDownValue}
                onBlur={onBlur}
                onFocus={onFocus}

            />

            <div
                id='dynamicDropdown'
                style={{
                    position: "absolute",
                    marginTop: "5px",
                    width: "100%",
                    maxHeight: isFocused ? "200px" : "0",
                    backgroundColor: "#fefefe",
                    borderRadius: "10px",
                    boxShadow: "0 4px 6px rgba(32, 33, 36, .28)",
                    overflow: "hidden",
                    overflowY: "auto",
                }}
            >
                {
                    myData.map((item, index) => {

                        return <button name={name} key={index} className="dataItem" onClick={(e) => {
                            e.target = { ...e.target, name: name, value: item }
                            onItemClick(e);
                        }}>

                            {item[textField]}

                            {/* <div className="dataitemText">{item.AssetId} - {item.MediaCategory[textField]}</div> */}

                        </button>
                    })
                }
            </div>
        </div>
    );
}

export default DynamicDropdown;
