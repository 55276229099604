import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { utility } from "../../../utility/utilityProvider";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";
import { SCHEDULETYPE, TX_MODE, TX_STATUS } from "../../../constant/constant";

export const TXModeCell = (props) => {

  let value = props.dataItem.segment ?
    props.dataItem.segment.SegmentType ?
      props.dataItem.segment.SegmentType.TXMode ?
        props.dataItem.segment.SegmentType.TXMode.Name : utility.getKeyByValue(TX_MODE, props.dataItem.segment.SegmentType?.TXModeSID)
      : "No Segment"
    : "No Segment";
  let style = {
    ...props.style,
    backgroundColor: props.style.background,
  }

  let newStyle = {};

  if (value && value.length > 0) {
    newStyle = {
      ...props.style,
      color: 'black',
      backgroundColor: 'white',
      display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center",
      border: '0.05em solid #9a9c92', borderRadius: '4px',
      textAlign: props.titleTextAlign ? props.titleTextAlign : "center",
      fontWeight: props.titleFontWeight ? props.titleFontWeight : "normal",
      fontSize: props.titleFontSize ? props.titleFontSize : "12px",
      verticalAlign: props.titleVerticalAlignment ? props.titleVerticalAlignment : "center",
      fontFamily: props.titleFontFamily ? props.titleFontFamily : "Roboto",
      letterSpacing: "0.6px",
    }
  }

  return <td
    colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    {...useTableKeyboardNavigation(props.id)}
    style={style}
  >
    {props.dataItem.Type == SCHEDULETYPE.Header && <span style={newStyle} id={props.dataItem?._id}>
      {value && (value == "LIVE") && <i className="mr-1 fa fa-circle" style={{ color: 'red', fontSize: '12px' }} />}
      {value}
    </span>}
  </td>;
};

export const TXStatusCell = (props) => {

  let value = props.dataItem.TXStatus ? utility.getKeyByValue(TX_STATUS, props.dataItem.TXStatus.SID) : ''

  let style = {
    ...props.style,
    backgroundColor: props.style.background,
  }

  let newStyle = {};

  if (value && value.length > 0) {
    newStyle = {
      ...props.style,
      color: 'black',
      backgroundColor: 'white',
      display: "flex", textAlign: "center", alignItems: "center", justifyContent: "center",
      border: '0.05em solid #9a9c92', borderRadius: '4px',
      textAlign: props.titleTextAlign ? props.titleTextAlign : "center",
      fontWeight: props.titleFontWeight ? props.titleFontWeight : "normal",
      fontSize: props.titleFontSize ? props.titleFontSize : "12px",
      verticalAlign: props.titleVerticalAlignment ? props.titleVerticalAlignment : "center",
      fontFamily: props.titleFontFamily ? props.titleFontFamily : "Roboto",
      letterSpacing: "0.6px",
    }
  }

  return <td
    colSpan={props.colSpan}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    {...useTableKeyboardNavigation(props.id)}
    style={style}
  >
    {props.dataItem.Type == SCHEDULETYPE.Header && <span style={newStyle} id={props.dataItem?._id}>
      {props.dataItem.TXStatus && (props.dataItem.TXStatus.SID == TX_STATUS.Live) && <i className="mr-1 fa fa-circle" style={{ color: 'red', fontSize: '12px' }} />}
      {value}
    </span>}
  </td>;
};