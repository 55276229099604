/* eslint-disable */
import React, { useRef, useState } from 'react';
import DeleteRoundButton from '../../framework/forms/helpercomponents/buttons/DeleteRoundButton';
import EditRoundButton from '../../framework/forms/helpercomponents/buttons/EditRoundButton';
import { ConfirmDeleteAlert } from '../../ConfirmAlert';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { toast } from 'react-toastify';
import { EditPageHeader } from '../../components/EditPageHeader';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import AddRoundButton from '../../framework/forms/helpercomponents/buttons/AddRoundButton';
import { useTranslation } from '../../locale/useTranslation';
import BossDialog from '../../components/BossDialog';

export const ContractTitleBlackoutDateEditForm = (props) => {

    let blankDataItem = {
        Channel: {},
        StartDate: "",
        EndDate: "",
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [showAddBlackOutDate, setShowAddBlackOutDate] = useState(false);
    const [gridData, setGridData] = useState(props.data?.BlackOutDates ?? []);
    const [editMode, setEditMode] = useState(false);
    const lang = useTranslation();
    const selectedIndexRef = useRef(0);
    const setSelectedIndexRef = (data) => {
        selectedIndexRef.current = data;
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const onSave = () => {

        if(gridData.length==0){
            toast.error(`${lang.please_add_atleast_one_black_out_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        var data= {
            ...props.data,
            BlackOutDates: gridData
        }
        props.setBlackOutDate([data]);
        props.onCancel();
        setShowAddBlackOutDate(false);
     }

    const removeBlackOutDate = (dataItem) => {
        var filterData = gridData.filter(x => x.Channel.SID !== dataItem.Channel.SID);
        setGridData(filterData);
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editBlackOutDate = (data) => {
        let dataItem = data.dataItem;
        setSelectedIndexRef(data.dataIndex);
        setDataItem(dataItem);
        setShowAddBlackOutDate(true);
        setEditMode(true);
    }

    const MyCommandCell = (props) => (
        <div className="mt-1" style={{ display: "flex" }}>
            <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => removeBlackOutDate(props.dataItem), () => { })} />
            <EditRoundButton onClick={() => editBlackOutDate(props)} />
        </div>
    )

    const isValid = () => {
        if (!dataItem.Channel || Object.keys(dataItem.Channel).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.StartDate === undefined || dataItem.StartDate === "") {
            toast.error(`${lang.please_select_start_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.EndDate === undefined || dataItem.EndDate === "") {
            toast.error(`${lang.please_select_end_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.StartDate > dataItem.EndDate) {
            toast.error(`${lang.end_date_should_be_greater_than_start_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if(new Date(dataItem.StartDate).getTime() < new Date(props.data.PublishStartDate).getTime()  ||
          new Date(dataItem.EndDate).getTime() < new Date(props.data.PublishStartDate).getTime() ||
           new Date(dataItem.StartDate).getTime() > new Date(props.data.PublishEndDate).getTime()  ||
             new Date(dataItem.EndDate).getTime() > new Date(props.data.PublishEndDate).getTime()){
            toast.error(`${lang.black_out_date_should_be_within_publishing_dates_range_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const onSaveBlackOutDate = () => {
        if (isValid()) {
            var data = {
                Channel: dataItem.Channel,
                StartDate: dataItem.StartDate,
                EndDate: dataItem.EndDate
            }
            if(editMode){
                let local = [...gridData]
                local[selectedIndexRef.current] = data;
                setGridData(local);
                setEditMode(false);
            }else{
                setGridData([...gridData, data]);
            }
            setShowAddBlackOutDate(false);
            setDataItem(blankDataItem);
        }
    }

    return (
        <>
            <BossDialog title={lang.edit_black_out_date_button_tooltip} onClose={props.onCancel} width={"700px"}>
                <div className="row">
                    <div className="col-12">
                        <EditPageHeader showTitle={false} onSubmit={onSave} onCancel={props.onCancel} />
                        <div className="row mt-2">
                            <div className='col'>
                                <AddRoundButton onClick={() => setShowAddBlackOutDate(true)} title={lang.add_black_out_date_button_tooltip} />

                                <Grid data={gridData} style={{ height: "30vh", margin: "10px 0px 0px 0px" }}>
                                    <GridColumn cell={MyCommandCell} width={"70px"} locked={true} />
                                    <GridColumn field="Channel.FullChannelName" title={lang.channel_column} editable={false} />
                                    <GridColumn field="StartDate" title={lang.start_date_column} editable={false} />
                                    <GridColumn field="EndDate" title={lang.end_Date_label} editable={false} />

                                </Grid>
                            </div>
                        </div>
                    </div>
                </div>
                {showAddBlackOutDate && <BossDialog title={lang.black_out_date_dialog_header} onClose={() => { setShowAddBlackOutDate(false); setDataItem(blankDataItem) }} width={"400px"} height={"250px"}>
                    <div className="row">
                        <div className="col-12">
                            <EditPageHeader showTitle={false} onSubmit={onSaveBlackOutDate} onCancel={() => { setShowAddBlackOutDate(false); setDataItem(blankDataItem) }} />
                            <div className="row mt-2">
                                <div className="col-12">
                                    <label htmlFor="">{lang.channel_label} *</label>
                                    <DropDownList
                                        style={{ backgroundColor: "white" }}
                                        data={props.data.Channel ?? []}
                                        name="Channel"
                                        textField="FullChannelName"
                                        dataItemKey="_id"
                                        value={dataItem.Channel}
                                        onChange={onChange}
                                    />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-6">
                                    <label htmlFor="">{lang.start_date_label} *</label>
                                    <input type="date" className="form-control form-control-sm" name="StartDate" value={dataItem.StartDate} onChange={onChange} />
                                </div>
                                <div className="col-6">
                                    <label htmlFor="">{lang.end_Date_label} *</label>
                                    <input type="date" className="form-control form-control-sm" name="EndDate" value={dataItem.EndDate} onChange={onChange} />
                                </div>
                            </div>
                        </div>
                    </div>
                </BossDialog>
                }

            </BossDialog>
        </>
    )
}
