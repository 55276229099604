import { useState } from "react"
import { useNavigate } from "react-router-dom";
import ActionButton from "../framework/forms/helpercomponents/buttons/ActionButton";

const ErrorComponent = () => {

    const navigate = useNavigate();

    return <div className="container">
        <h1>ACCESS DENIED !!</h1>
        <ActionButton title={""} disabled={false} name={"Home"} onClick={() => navigate("/home")} />
    </div>

}

export default ErrorComponent