/* eslint-disable */
import React, { useState } from 'react'
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { EditPageHeader } from '../../components/EditPageHeader';
import { Checkbox } from "@progress/kendo-react-inputs";
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MODULE } from "../../framework/constant/constant";
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import { utility } from '../../framework/utility/utilityProvider';
import { TimePickerWithFormat } from '../../framework/forms/helpercomponents/timepicker/TimePickerWithFormat';
import BossDialog from '../../components/BossDialog';

const TimeRangeEditForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        ...props.item,
        TimeRange: props.item.TimeRange ?? "",
        CommercialLimit: utility.convertMilisecondsToStringWithFrames(props.item.CommercialLimit) ?? '00:00:00:00',
        TimeIn: props.item.TimeIn ?? "",
        TimeOut: props.item.TimeOut ?? "",
        Archive: props.item.Archive ?? false
    }

    const [dataItem, setDataItem] = useState(blankDataItem);

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {
        if(!dataItem.CommercialLimit || dataItem.CommercialLimit == '00:00:00:00'){
            toast.error(`${lang.please_enter_valid_commercial_limit_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {

        if (isValid()) {
            const saveData = {
                _id: dataItem._id,
                SID: dataItem.SID,
                TimeRange: dataItem.TimeRange,
                CommercialLimit: utility.convertStringWithFramesToMilliseconds(dataItem.CommercialLimit),
                TimeIn: dataItem.TimeIn,
                TimeOut: dataItem.TimeOut,
                Archive: dataItem.Archive,
            }
            console.log(saveData);
            let res = await API.saveData(ENTITYNAME.TimeRange, saveData);
            console.log(res);
            if (res.success) {
                let logData = { event: res.message == "updated" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.TIME_RANGE, data: res.data, message: res.message };
                API.SaveLogs(logData);
                utility.deleteLocalStorageItem(ENTITYNAME.TimeRange);
                props.refresh();
                props.cancelEdit();
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    return (
        <>
            <BossDialog
                title={lang.time_range_label}
                onClose={props.cancelEdit}
                width={"650px"}
            >
                <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                <Form
                    initialValues={dataItem}
                    render={(formRenderProps) => (
                        <FormElement>
                            <div className='row mt-2'>
                                <div className='col-12'>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <label>{lang.time_range_label} *</label>
                                            <input
                                                name={"TimeRange"}
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={onChange}
                                                value={dataItem.TimeRange}
                                                disabled
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <label>{lang.commercial_limit_label} *</label>
                                            <TimePickerWithFormat
                                                className="form-control form-control-sm"
                                                name="CommercialLimit"
                                                value={dataItem.CommercialLimit}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <label>{lang.time_in_label} *</label>
                                            <input
                                                name={"TimeIn"}
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={onChange}
                                                value={dataItem.TimeIn}
                                                disabled
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <label htmlFor="TabView">{lang.time_out_label} *</label>
                                            <input
                                                name={"TimeOut"}
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={onChange}
                                                value={dataItem.TimeOut}
                                                disabled
                                            />
                                        </div>
                                    </div>

                                    <div className='row '>
                                        <div className='col-6 mt-4'>
                                            <Field
                                                style={{border: "1px solid grey"}}
                                                name={"Archive"}
                                                component={Checkbox}
                                                label={lang.archive}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormElement>
                    )}
                />
            </BossDialog>
        </>
    )
}

export default TimeRangeEditForm;