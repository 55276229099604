/* eslint-disable */
import { useEffect, useRef, useState } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { useTranslation } from "../../locale/useTranslation";
import { EditPageHeader } from "../../components/EditPageHeader";
import { ENTITYNAME } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { DateOnlyCell, DateTimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import EditRoundButton from "../../framework/forms/helpercomponents/buttons/EditRoundButton";
import moment from "moment";
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { toast } from "react-toastify";
import BossDialog from "../../components/BossDialog";

const TargetsEditForm = (props) => {

    const lang = useTranslation();

    const blankDataItem = {
        FromDate: '',
        ToDate: '',
        DayPart: {},
        Weightage: 50,
        PlayViaPlayCount: false,
        PlayCount: 0
    }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [showTargetForm, setShowTargetForm] = useState(false);
    const [dayParts, setDayParts] = useState([]);
    const [gridData, setGridData] = useState(props?.targetData ?? []);
    const [editMode, setEditMode] = useState(false);
    const selectedIndexRef = useRef(0);
    const setSelectedIndexRef = (data) => {
      selectedIndexRef.current = data;
    }

    useEffect(() => {
        loadCombo();
    }, []);

    const loadCombo = async () => {
        let datPartRes = await API.getDataLookup(ENTITYNAME.DayPart, { sort: { Description: 1 } });
        setDayParts(datPartRes.data);
    }

    const onChange = (e) => {
        if(e.target.name == "PlayViaPlayCount"){
            setDataItem({ ...dataItem, PlayViaPlayCount: !dataItem.PlayViaPlayCount });
        }else{
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
        }
    }

    const isValid = () => {
        if(dataItem.FromDate == undefined || dataItem.FromDate == ""){
            toast.error(lang.please_enter_from_date_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
        if(dataItem.ToDate == undefined || dataItem.ToDate == ""){
            toast.error(lang.please_enter_to_date_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
        if(dataItem.ToDate < dataItem.FromDate){
            toast.error(lang.from_date_should_be_less_than_to_date_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
        if(dataItem.DayPart == undefined || Object.keys(dataItem.DayPart).length == 0){
            toast.error(lang.please_select_day_part_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
        
        if(!dataItem.PlayViaPlayCount && dataItem.Weightage <= 0){
            toast.error(lang.please_enter_correct_weightage_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
        
        if(dataItem.PlayViaPlayCount && dataItem.PlayCount <= 0){
            toast.error(lang.please_enter_correct_play_count_error_message, {
                position: toast.POSITION.TOP_RIGHT
            })
            return false;
        }
        return true;
    }

    const handleSubmit = () => {
        if(!isValid()) return;

        const finalData = {
            FromDate: new Date(dataItem?.FromDate).getTime(),
            ToDate: new Date(dataItem?.ToDate).getTime(),
            DayPart: Object.keys(dataItem?.DayPart)?.length > 0 ? {
                _id: dataItem?.DayPart?._id,
                Description: dataItem?.DayPart?.Description,
                FromTime: dataItem?.DayPart?.FromTime,
                ToTime: dataItem?.DayPart?.ToTime,
                Channels: dataItem?.DayPart?.Channels?.length > 0 ? dataItem?.DayPart?.Channels : [],
                ValidDays: dataItem?.DayPart?.ValidDays,
                IsAllChannel: dataItem?.DayPart?.IsAllChannel
            } : {},
            Weightage: parseInt(dataItem?.Weightage),
            PlayCount: parseInt(dataItem?.PlayCount),
            PlayViaPlayCount: dataItem?.PlayViaPlayCount ?? false
        }
        if(editMode){
            let local = [...gridData]
            local[selectedIndexRef.current] = finalData;
            setGridData(local);
            props?.setTargetData(local);
        }else{
            setGridData([...gridData, finalData]);
            props?.setTargetData([...gridData, finalData]);
        }
        setEditMode(false);
        setShowTargetForm(false);
        setDataItem(blankDataItem);
    }

    const onEdit = (props) => {
        let data = {
            ...props.dataItem,
            FromDate: moment(new Date(props?.dataItem?.FromDate)).format('YYYY-MM-DD'),
            ToDate: moment(new Date(props?.dataItem?.ToDate)).format('YYYY-MM-DD')
        }
        setSelectedIndexRef(props.dataIndex);
        setDataItem(data);
        setEditMode(true);
        setShowTargetForm(true);
    }

    const deleteSelectedValue = (index) => {
        let removeData = [...gridData.slice(0, index), ...gridData.slice(index + 1, gridData.length)]
        setGridData(removeData);
        props?.setTargetData(removeData);
    }

    const MyCommandCell = (props) => {
        return (
            <td>
                <EditRoundButton onClick={() => onEdit(props)} />
                <DeleteRoundButton title={lang.delete_all_button_tooltip} onClick={() => ConfirmDeleteAlert(() => deleteSelectedValue(props.dataIndex), () => { })} />
            </td>
        );
    };

    const deleteAllData = () => {
        setGridData([]);
        props?.setTargetData([]);
    }

    return (
        <>
            <div className="row">
                <div className="col-12">
                    <RoundButton onClick={() => setShowTargetForm(true)} />
                    <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => deleteAllData([]), () => { })} />
                    <Grid data={gridData} resizable={true} style={{height: '40vh', marginBottom: '15px'}}>
                        <GridColumn cell={MyCommandCell} width={100} />
                        <GridColumn field="FromDate" title={lang.from_date_column} cell={DateOnlyCell} />
                        <GridColumn field="ToDate" title={lang.to_date_column} cell={DateOnlyCell} />
                        <GridColumn field="DayPart.Description" title={lang.day_part_label} />
                        <GridColumn field="Weightage" title={lang.weightage_column} />
                        <GridColumn field="PlayCount" title={lang.play_count_column} />
                        <GridColumn field="PlayViaPlayCount" title={lang.isplaybycount_column} cell={CheckboxCell}/>
                    </Grid>
                </div>
            </div>
            {showTargetForm &&
                <BossDialog title={lang.targets_label} onClose={() => setShowTargetForm(false)} width={"50%"}>
                    <div className="row">
                        <div className="col-12">
                            <EditPageHeader showTitle={false} onSubmit={handleSubmit} onCancel={() => {setShowTargetForm(false); setDataItem(blankDataItem)}}/>
                            <div className="row mt-2">
                                <div className="col-6">
                                    <label>{lang.from_date_column} *</label>
                                    <input type="date" name="FromDate" className="form-control form-control-sm" value={dataItem.FromDate} onChange={onChange} />
                                </div>
                                <div className="col-6">
                                    <label>{lang.to_date_column} *</label>
                                    <input type="date" name="ToDate" className="form-control form-control-sm" value={dataItem.ToDate} onChange={onChange} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-6">
                                    <label>{lang.day_part_label} *</label>
                                    <DropDownList
                                        style={{backgroundColor : 'white'}}
                                        data={dayParts}
                                        textField="Description"
                                        dataItemKey="_id"
                                        name="DayPart"
                                        value={dataItem.DayPart}
                                        onChange={onChange}
                                    />
                                </div>
                                <div className="col-6">
                                    <label>{lang.weightage_label} *</label>
                                    <input type="number" name="Weightage" className="form-control form-control-sm" value={dataItem.Weightage} onChange={onChange} />
                                </div>
                            </div>
                            <div className="row mt-2">
                                <div className="col-6">
                                    <label>{lang.play_count_label} *</label>
                                    <input type="number" name="PlayCount" className="form-control form-control-sm" value={dataItem.PlayCount} onChange={onChange} />
                                </div>
                                <div className="col-6 mt-4">
                                    <input type="checkbox" name="PlayViaPlayCount" value={dataItem.PlayViaPlayCount} checked={dataItem.PlayViaPlayCount} onChange={onChange} />
                                    <label className="ml-1">{lang.isplaybycount_label}</label>
                                </div>
                            </div>
                        </div>
                    </div>
                </BossDialog>
            }
        </>
    )
}

export default TargetsEditForm;