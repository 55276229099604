import React, { useEffect, useState } from 'react'

const ImageCard = (props) => { 

    const [imageUrl, setImageUrl] = useState('https://media.comicbook.com/files/img/default-movie.png');

    useEffect(() => {

      if(props.img){
        setImageUrl(props.img)
      }

    },[props.img])

    const handleError = () => {      
        setImageUrl('https://media.comicbook.com/files/img/default-movie.png');
    }

  return (
    <img
        style={props.style ?? { borderRadius: "10px", boxShadow: "0px 0px 10px 1px black" }} 
        width={props.width ?? "200px"} 
        height={props.height ?? "280px"}
        src={imageUrl} 
        alt={props.alt ?? 'Dummy'}
        onError={handleError} 
    />
  )
}

export default ImageCard