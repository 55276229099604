import React from 'react'

const IconSquareButton = (props) => {

    let enableInput = props.inputField ?? false;
    let onFileChange = props.onFileChange ?? (() => { });
    let onClick = props.onClick ?? (() => { });

    return (
        <div>   
            <button 
                className="m-3 btn btn-default btn-circle btn-my" 
                style={{ 
                    width: props.width ?? '150px', 
                    height: props.height ?? '150px', 
                    borderRadius: props.borderRadius ?? '25px', 
                    padding: '0px', margin: '0px 0px 0px 2px',
                    backgroundColor:props.backgroundColor ?? "grey" 
                }} 
                onClick={() => { onClick() }} 
                title={props.title}
            >
                <label className={`fa ${props.icon ?? 'fa-image'}`} style={{ color: props.iconColor ?? 'white', fontSize: props.iconSize ?? '75px', padding: '7px 9px', margin: '0px', cursor: 'pointer' }}>
                    {enableInput && <input
                        name={"file"}
                        className='custom-file-updated-input form-control form-control-sm'
                        type={props.buttonType ?? "file"}
                        accept={props?.accept ?? "image/*,video/*"}
                        multiple hidden="hidden" onChange={(e) => { onFileChange(e) }} />}
                </label>
            </button>
            <div className='text-center'>{props?.name}</div>
        </div>
    )
}

export default IconSquareButton