/* eslint-disable */
import { useEffect, useState } from "react";
import * as API from '../../framework/API/api'
import { ENTITYNAME } from "../../framework/constant/constant";
import { toast } from "react-toastify";
import { useTranslation } from "../../locale/useTranslation";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { EditPageHeader } from "../../components/EditPageHeader";
import { utility } from "../../framework/utility/utilityProvider";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import BossDialog from "../../components/BossDialog";

const AudioTrack = (props) => {

    const tableDataStyle = { fontFamily: "Roboto", fontSize: "13px", fontWeight: "400", letterSpacing: "0.2px", lineHeight: "18px", color: "#3C4048", padding: "10px 10px" }

    const lang = useTranslation();
    const [dataItem, setDataItem] = useState({
        Language: {},
        TrackNumber: '',
    });
    const [language, setLanguage] = useState([]);
    const [openLanguageGroupProtoType, setOpenLanguageGroupProtoType] = useState(false);
    const [template, setTemplate] = useState([]);
    const [selectedTemplate, setSelectedTemplate] = useState({});
    const [templateName, setTemplateName] = useState('');
    const [saveAsTemplate, setSaveAsTemplate] = useState(false);
    const [gridData, setGridData] = useState([]);
    const [gridDataTemplate, setGridDataTemplate] = useState(props.data ?? []);
    const [hideValue, setHideValue] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);

    useEffect(() => {
        loadcombo();
    }, []);

    const onChange = (e) => {
        if (e.target.name == 'isUpdate') {
            setIsUpdate(e.target.checked);
        }
        if (e.target.name == 'Template') {
            setGridData(e.target.value.GroupDetails);
            setSelectedTemplate(e.target.value);
            setDataItem({ ...dataItem, [e.target.name]: e.target.value });
            setHideValue(true);
            return;
        }
        if (e.target.name == 'templateName') {
            setTemplateName(e.target.value);
            return;
        }
        if (e.target.name == 'SaveAsTemplate') {
            return;
        }
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }
    const loadcombo = async () => {
        var languages = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
        setLanguage(languages.data);
        var templateRes = await API.getDataLookup(ENTITYNAME.LanguageGroup);
        setTemplate(templateRes.data);
    }

    const duplicateCheckLanguage = (dataList) => {
        var duplicate = []
        var newData = []
        dataList?.map((obj) => {
            var Temp = gridDataTemplate?.filter((item) => item?.Language?.SID == obj?.Language?.SID);
            if (Temp.length > 0) {
                duplicate = [...duplicate, ...Temp];
            }
            else {
                newData = [...newData, obj];
            }
        })
        return newData
    }

    const onSaveTemplate = async () => {
        if (gridData.length == 0) {
            toast.error(`${lang.please_select_atleast_one_language_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        if (saveAsTemplate && (templateName == undefined || templateName == "")) {
            toast.error(`${lang.please_enter_template_name_error_toast}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        console.log(gridData);
        var data = {
            SID: dataItem.Template.SID ?? 0,
            Name: templateName == "" ? dataItem.Template.Name : templateName,
            GroupDetails: gridData.map((obj) => {
                return{
                    ...obj,
                    Language:{
                        _id: obj.Language._id,
                        SID: obj.Language.SID, 
                        Description: obj.Language.Description
                    }
                }
            }),
        }
        if (saveAsTemplate ? saveAsTemplate : isUpdate) {
            var res = await API.saveData(ENTITYNAME.LanguageGroup, data);
            setSelectedTemplate(res?.data);
            setGridDataTemplate([...gridDataTemplate, ...duplicateCheckLanguage(data.GroupDetails)]);
            onClear();
            setOpenLanguageGroupProtoType(false);
            utility.deleteLocalStorageItem(ENTITYNAME.LanguageGroup);
        }
        setGridDataTemplate([...gridDataTemplate, ...duplicateCheckLanguage(data.GroupDetails)]);
        onClear();
        setOpenLanguageGroupProtoType(false);
        props.setAudioTrackData([...gridDataTemplate, ...duplicateCheckLanguage(data.GroupDetails)]);
    }

    const isValid = () => {
        if (!dataItem.Language || Object.keys(dataItem.Language).length == 0) {
            toast.error(`${lang.please_select_language_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!dataItem.TrackNumber) {
            toast.error(`${lang.please_enter_track_number_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (gridData.filter(x => x.Language.SID == dataItem.Language.SID).length > 0) {
            toast.error(`${lang.language_already_added_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (gridData.filter(x => x.TrackNumber == dataItem.TrackNumber).length > 0) {
            toast.error(`${lang.track_number_already_added_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true
    }
    const setGridValue = () => {
        if (isValid()) {
            var data = {
                Language:{
                    _id: dataItem.Language._id,
                    SID: dataItem.Language.SID, 
                    Description: dataItem.Language.Description
                },
                TrackNumber: dataItem.TrackNumber,
            }
            setGridData([...gridData, data]);
            setDataItem({
                Template: isUpdate ? dataItem.Template : {},
                Language: {},
                TrackNumber: '',
            });
        }

    }

    const onClear = () => {
        setDataItem({
            TrackNumber: '',
            Template: {}
        });
        setSaveAsTemplate(false);
        setSelectedTemplate({});
        setTemplateName('');
        setGridData([]);
        setHideValue(false);
        setIsUpdate(false);
    }

    const onCancel = () => {
        setOpenLanguageGroupProtoType(false);
        setSelectedTemplate({});
        setDataItem({});
        setSaveAsTemplate(false);
        setTemplateName('');
        setGridData([]);
        setHideValue(false);
        setIsUpdate(false);
    }

    const deleteTemplate = async () => {

        const res = await API.deleteData(ENTITYNAME.LanguageGroup, selectedTemplate.SID);
        utility.deleteLocalStorageItem(ENTITYNAME.LanguageGroup);
        onClear();
        loadcombo();
    }

    const clearSelectedTemplate = () => {
        setDataItem({
            Template: {
                Name: ''
            },
            Language: {},
            TrackNumber: '',
        });
        onClear();
    }

    const removeGridLanguage = (dataItem) => {
        var data = gridData.filter(x => x.TrackNumber != dataItem.TrackNumber);
        setGridData(data);
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const MyCommandCell = (props) => (
        <div className="mt-1" style={{ display: "flex" }}>
            <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => removeGridLanguage(props.dataItem), () => { })} />
        </div>
    )

    const audioTrackDelete = (dataItem) => {
        var data = gridDataTemplate.filter(x => x.TrackNumber != dataItem.TrackNumber);
        props.setAudioTrackData(data);
        setGridDataTemplate(data);
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const MyCommandCellMain = (props) => (
        <div className="mt-1" style={{ display: "flex" }}>
            <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => audioTrackDelete(props.dataItem), () => { })} />
        </div>
    )

    return (
        <>
            <div>
                <AddRoundButton disabled={props.disabled ?? false} title={lang.add_language_group_prototype_button_tooltip} onClick={() => { setOpenLanguageGroupProtoType(true); loadcombo() }} />
                <table className="table mt-2">
                    <thead>
                        <tr>
                            <th style={{ width: "40px" }} scope="col"></th>
                            <th scope="col">{lang.language_column}</th>
                            <th scope="col">{lang.track_number_label}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {gridDataTemplate?.map((AudioTrack, index) => {
                            return (
                                <tr key={index}>
                                    <td>
                                        <DeleteRoundButton disabled={props.disabled ?? false} onClick={() => ConfirmDeleteAlert(() => audioTrackDelete(AudioTrack), () => { })} />
                                    </td>
                                    <td style={tableDataStyle}>{AudioTrack?.Language?.Description}</td>
                                    <td style={tableDataStyle}>{AudioTrack?.TrackNumber}</td>
                                </tr>
                            )
                        })}

                    </tbody>
                </table>
            </div>
            {openLanguageGroupProtoType &&
                <BossDialog
                    title={`${lang.add_language_group_prototype_button_tooltip}`}
                    onClose={onCancel}
                    width={"750px"}
                    height={saveAsTemplate ? saveAsTemplate : isUpdate ? "540px" : "490px"}
                >
                    <div className='row'>
                        <div className="col-12">
                            <EditPageHeader showTitle={false} onSubmit={onSaveTemplate} onCancel={onCancel} />
                            <div className="row mt-2">
                                <div className="col-4">
                                    <label htmlFor="TabView">{lang.template_label} : </label>
                                </div>
                                <div className={hideValue ? "col-7" : "col-8"}>
                                    <div className="form-group">
                                        <DropDownList
                                            data={template}
                                            name={"Template"}
                                            textField="Name"
                                            dataItemKey="_id"
                                            value={dataItem.Template}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                {hideValue && <div className="ml-2">
                                    <button className={`btn btn-default btn-circle btn-my`} onClick={() => ConfirmDeleteAlert(() => deleteTemplate(), () => { })} type="button" title={lang.delete_button_tooltip} style={{ marginLeft: "-15px" }}>
                                        <i className="fa fa-trash" aria-hidden="true" ></i>
                                    </button>
                                </div>}
                                {hideValue && <div className="ml-3">
                                    <button className={`btn btn-default btn-circle btn-my`} onClick={clearSelectedTemplate} type="button" title={lang.clear_button_tooltip} style={{ marginLeft: "-15px" }}>
                                        <i className="fa fa-remove" aria-hidden="true" ></i>
                                    </button>
                                </div>}
                            </div>
                            {!hideValue &&
                                <div className="row">
                                    <div className="col-4">
                                        <label htmlFor="TabView">{lang.save_as_template_label} :</label>
                                    </div>
                                    <div className="col-8">
                                        <div className="form-group">
                                            <input
                                                name={"SaveAsTemplate"}
                                                type={"checkbox"}
                                                value={saveAsTemplate}
                                                onClick={() => setSaveAsTemplate(!saveAsTemplate)}
                                                checked={saveAsTemplate}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                </div>}

                            {hideValue && <div className="row">
                                <div className="col-4">
                                    <label htmlFor="TabView">{lang.update_template_label} :</label>
                                </div>
                                <div className="col-8">
                                    <div className="form-group">
                                        <input type={"checkbox"} name={"isUpdate"}
                                            onChange={(e) => onChange(e)}
                                            value={isUpdate}
                                            checked={isUpdate} />
                                    </div>
                                </div>
                            </div>}

                            {(saveAsTemplate ? saveAsTemplate : isUpdate) && <div className="row">
                                <div className="col-4 mt-2">
                                    <label htmlFor="TabView">{lang.template_name_label} * : </label>
                                </div>
                                <div className="col-8">
                                    <div className="form-group">
                                        <input
                                            type="text"
                                            className="form-control form-control-sm"
                                            name="templateName"
                                            value={templateName ? templateName : dataItem?.Template?.Name}
                                            onChange={onChange} />
                                    </div>
                                </div>
                            </div>}

                            {(!isUpdate ? !hideValue : hideValue) && <div className="row">
                                <div className="col-6">
                                    <div className="form-group">
                                        <lable>{lang.language_label} *</lable>
                                        <DropDownList
                                            style={{
                                                backgroundColor: "white",
                                            }}
                                            className="mt-1"
                                            data={language}
                                            name="Language"
                                            textField="Description"
                                            dataItemKey="_id"
                                            value={dataItem.Language}
                                            onChange={onChange}
                                        />
                                    </div>
                                </div>
                                <div className="col-5">
                                    <div className="form-group">
                                        <label>{lang.track_number_label} *</label>
                                        <input type="number" className="form-control form-control-sm mt-1" name="TrackNumber" value={dataItem.TrackNumber} onChange={onChange} />
                                    </div>
                                </div>
                                <div style={{ marginTop: '20px' }}>
                                    <div className="form-group">
                                        <RoundButton title={lang.add_button_tooltip} onClick={setGridValue} />
                                    </div>
                                </div>
                            </div>}

                            <div className="row m-2">
                                <Grid data={gridData} style={{ height: '180px', margin: '5px 0px' }}>
                                    <GridColumn cell={MyCommandCell} width={"35px"} locked={true} />
                                    <GridColumn field="Language.Description" title={lang.language_column} editable={false} />
                                    <GridColumn field="TrackNumber" title={lang.track_number_label} editable={false} />
                                </Grid>
                            </div>
                        </div>
                    </div>
                </BossDialog>}
        </>
    )

}

export default AudioTrack;