import { Menu } from "primereact/menu"
import { useRef } from "react"
import "primereact/resources/themes/lara-light-indigo/theme.css";  //theme
import "primereact/resources/primereact.min.css";                  //core css
import "primeicons/primeicons.css"; 
import "../contextMenu/contextmenu.css"

const  MenuButton= (props) => {
    const menu=useRef(null)
    //title
    //onClick
    //props
    //icon
    return <>
    <button style={{ margin: "0px 2px 2px 2px" }} onClick={(e)=>menu.current.toggle(e)} title={props.title} className={`btn btn-default bg-${props.btnColor} btn-circle btn-my`} >
        <i className={`fa fa-${props.icon ?? 'ellipsis'} fa-fw img-circle`} style={props.color ?? { color: props.iconColor }} ></i>
    </button>
    <Menu model={props.collectionMenuModel(props.props)} popup ref={menu}/> 
    </>
}

export default MenuButton;