import { useTranslation } from "../../../../locale/useTranslation";
import { utility } from "../../../utility/utilityProvider";
const BackRoundButton = (props) => {
    const lang=useTranslation();
    const SELECTED_MENU = 'selectedMenu';
    const PREV_SELECTEDMENU = 'lastSelectedMenu';

    const onClick = () => {
        var prevMenu = utility.getValue(PREV_SELECTEDMENU);
        utility.setValue(SELECTED_MENU,prevMenu);
        props.onClick();
    }


    return <button  title={props.title??lang.back_button_tooltip} type="submit" onClick={onClick} style={{margin:"0px 02px"}} className="btn btn-default bg-primary btn-circle btn-my" >
       <i className={`fa fa-${props.icon??'chevron-left'} fa-fw img-circle`} style={{color:"white"}}></i>
    </button>
}

export default BackRoundButton;
