/* eslint-disable */
import React, { useState, useEffect } from "react";
import { Grid, GridColumn as Column, GridColumn } from "@progress/kendo-react-grid";
import { ENTITYNAME, LOCALSTORAGE_KEY, COLUMNSTYPE } from "../../framework/constant/constant";
import * as API from "../../framework/API/api";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { json, useNavigate } from "react-router-dom";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { MenuCommandCell } from "../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import EditForm from '../../framework/forms/helpercomponents/editForm'
import { collectionMenuModel } from "../../framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { utility } from "../../framework/utility/utilityProvider";
import { DeleteConfirmDialogBox } from "../../framework/forms/helpercomponents/alert/DeleteConfirmDialogBox";
import DeleteErrorDialogBox from "../../framework/forms/helpercomponents/alert/DeleteErrorDialogBox";
import { UserPreference } from "../userPreference/UserPreference";
import { MyToggleButton } from "../../framework/forms/helpercomponents/CustomGridCells/MyToggleButton";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import { toast } from 'react-toastify';
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import { orderBy } from "@progress/kendo-react-data-tools";

const initialSort = [
  {
    field: "_id",
    dir: "asc",
  },
];

export const FormFieldsCollection = (props) => {
  //possible props
  var page_take = props.page_take ?? 30;
  var showAdd = props.showAdd ?? true;
  var showBack = props.showBack ?? true;
  var showCommandCell = props.showCommandCell ?? true;
  var showEdit = props.showEdit ?? true;
  var showDelete = props.showDelete ?? true;
  var showTitle = props.showTitle ?? true;
  var gridRowRender = props.gridRowRender;
  var CustomEditForm = props.customEditForm ?? EditForm;
  var filterable = props.filterable ?? false;
  var sortable = props.sortable ?? true;
  var isPageable = props.isPageable ?? true;
  var wherestatement = props.wherestatement;
  var andWhereStatement = props.andWhereStatement;
  var customColumns = props.customColumns ?? [];
  var openNewEditForm = props.openNewEditForm ?? false;
  var myTitle = props.title ?? "";
  var myMenuModel = props.menuModel ?? collectionMenuModel;
  // saving addition as we need to pass in other component which already has there own props param, By vishal on Sep 2022 
  var entityName = props.entityname;
  var showPreferenceButton = props.showPreferenceButton ?? true;
  var hideArchiveProps = props.hideArchive ?? false;

  // example custome columns[]
  const navigate = useNavigate();
  const lang = useTranslation();

  const [gridData, setGridData] = useState([]);
  const [filterGridData, setFilterGridData] = useState([]);
  const [total, setTotal] = useState(1);
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState({ SID: 1 });
  const [warningDialog, setWarningDialog] = useState(false);
  const [selectedDataItem, setSelectedDataItem] = useState({});
  const [deleteError, setDeleteError] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [isNoRecords, setIsNoRecords] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [dataColumns, setDataColumns] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showArchived, setShowArchived] = useState(false);
  const [hideArchive, setHideArchive] = useState(hideArchiveProps);
  const [entityNameData, setEntityNameData] = useState([]);
  const [dataItem, setDataItem] = useState({})
  const [sort, setSort] = useState(initialSort);

  const [dataState, setGridDataState] = useState({
    take: page_take,
    skip: 0,
  });

  useEffect(() => {
    loaddata({ per_page: page_take }, wherestatement, andWhereStatement, searchText);
  }, [props.entityname, props.gridData, props.title]);

  const onSearch = (e) => {
    e.preventDefault();
    let text = e.target.value?.trim();
    setSearchText(text);

    let finalData = gridData.filter((obj) => obj.entityName == dataItem.filterName.entityName).sort((a, b) => a.sequence - b.sequence);

    if (!text || text == "") {
      setFilterGridData(finalData);
    } else {
      let data = finalData.filter((item) => {
        return item.name.toLowerCase().includes(text.toLowerCase()) || item.label.toLowerCase().includes(text.toLowerCase()) || item.type.toLowerCase().includes(text.toLowerCase()) || item.sequence.toString().includes(text);
      });
      setFilterGridData(data);
    }
  };

  const enterEdit = (item) => {
    console.log("onEnterEdit");
    if (openNewEditForm === false) {
      setOpenForm(true);
      setEditItem(item);
    } // need to navigate to full page edit view
    else {
      navigate(`${item.SID}`, { state: { copy: item.copy ? true : false, quickEdit: item.quickEdit ? true : false } });
    }
  };

  const handleNavigate = (item) => {
    navigate(`${item.path}`, { state: item });
  }

  //onSubmit from EDIRFORM
  const handleSubmit = async (dataItem, dataToSave) => {
    console.log("handleSUbmit");
    //if datatosave is null then dataitem is datasave
    dataToSave = dataToSave == null ? dataItem : dataToSave;

    var isDuplicate = await utility.checkDuplicate(props.entityname, dataToSave);

    if (!isDuplicate) {
      setOpenForm(true);
      toast.error(`${lang.entry_already_exist_try_with_different_values_collection_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    var res = await API.saveData(props.entityname, dataToSave);
    console.log(res);
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    //for new created item
    if (dataItem.SID === 0) {
      setGridData([...gridData, dataItem]);
    } else {
      //UPDATE DATA GRID
      let newData = gridData.map((item) => {
        if (dataItem.SID === item.SID) {
          item = { ...dataItem };
        }
        return item;
      });
      setGridData(newData);
    }

    //to load grid with new data
    loaddata({ per_page: page_take }, wherestatement, andWhereStatement, searchText);
    setOpenForm(false);
  };

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleDelete = async (dataItem) => {
    setSelectedDataItem(dataItem);
    console.log(dataItem);
    setWarningDialog(true);

  };

  const handleArchive = async (dataItem) => {
    console.log({
      ...dataItem,
      Archive: !dataItem.Archive,
    });
    var resArchiveUpdated = await API.saveData(entityName, {
      ...dataItem,
      Archive: !dataItem.Archive
    })
    if (resArchiveUpdated.success) {
      console.log(`Archive is been updated to ${!dataItem.Archive}`);
      loaddata({ per_page: page_take }, wherestatement, andWhereStatement, searchText);
    } else {
      console.log(resArchiveUpdated.message);
    }
  }

  const onCloseUserPrefence = () => {
    loaddata({ per_page: page_take }, wherestatement, andWhereStatement, searchText);
    setShowPreferences(!showPreferences);

  }

  //Edit Row
  const MyCommandCell = (props) => (

    <MenuCommandCell
      {...props}
      enterEdit={enterEdit}
      cancelEdit={handleCancelEdit}
      onSubmit={handleSubmit}
      item={editItem}
      dataColumns={dataColumns}
      onDelete={handleDelete}
      onArchive={handleArchive}
      showEdit={showEdit}
      showDelete={showDelete}
      toNavigate={handleNavigate}
      myMenuModel={myMenuModel}
    />
  );

  const MyCustomToggleButton = (props) => (
    <MyToggleButton
      {...props}
      entityName={entityName}
      size={"small"}
    />
  );

  const MyDateCell = (props) => <DateOnlyCell {...props} />;
  const MyArrayCell = (props) => <ArrayCell {...props} />;

  const MyTimeCell = (props) => <TimeCell {...props} />;

  const MyDatalookupCell = (props) => (
    <LookupCell {...props} dataColumns={dataColumns} />
  );

  const MyImageCell = (props) => <ImageCell {...props} />;

  const MyCheckBoxCell = (props) => <CheckboxCell {...props} />;


  const loaddata = async (pagination, wherestatement, andWhereStatement, searchText, archive) => {
    console.log("loading");
    if (props.gridColumns) {
      setDataColumns(props.gridColumns);
      setGridData(props.gridData);
      setIsLoading(false);
    } else {
      try {
        var currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
        var columns = await API.getFormData(props.entityname, currentUserId);
        var json = await API.getEntitiesWithSearch(props.entityname, { query: wherestatement, archive: archive }, columns, searchText);
        // var modules = await API.getFormFieldEntityName();
        // console.log(modules);
        var tempEntityName = []
        var tempdata = []

        //to ask if we get the entity name from backend directly
        json.data.map((obj) => {
          if (obj.entityName != undefined && obj.module != undefined) {
            if (!tempEntityName.includes(obj.entityName)) {
              tempEntityName.push(obj.entityName)
              tempdata.push(obj)

            }
          }
        })

        setGridData(json.data);

        setEntityNameData(tempdata.sort((a, b) => {
          if ((a.module ?? a.entityName).toLowerCase() < (b.module ?? a.entityName).toLowerCase()) {
            return -1;
          }
          if ((a.module ?? a.entityName).toLowerCase() > (b.module ?? a.entityName).toLowerCase()) {
            return 1;
          }
          return 0;
        }));

        //set first item in drop down
        setDataItem({ ...dataItem, filterName: tempdata[0] });
        let filteredData = json.data.filter((obj) => obj.entityName == tempdata[0].entityName).sort((a, b) => a.sequence - b.sequence);
        setFilterGridData(filteredData);
        // setTotal(json.pagination.total);
        //if custmoe colum is coming from compemnent level then no need  a column from Server API

        customColumns.length > 0
          ? setDataColumns(customColumns)
          : setDataColumns(columns);
        setIsLoading(false);
        console.log(columns)


      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
        setIsNoRecords(true);
      }
    }
  };

  const onChange = (e) => {
    if (e.target.name == 'filterName') {
      var filteredData = [];
      if (e.target.value.module == "All") {
        filteredData = gridData;
      } else {
        filteredData = gridData.filter((obj) => (obj.entityName == e.target.value.entityName));
      }
    }
    setFilterGridData(filteredData.sort((a, b) => a.sequence - b.sequence));
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  }

  const handleDailogClose = () => {
    setWarningDialog(!warningDialog);
  }
  const handleErrorDialogClose = () => {
    setDeleteError(!deleteError);
    setDeleteErrorMessage("");
  }
  const errorHandler = (message) => {
    setWarningDialog(!warningDialog);
    setDeleteError(true);
    setDeleteErrorMessage(message);
  }

  const onConfirmDelete = async () => {
    const res = await API.deleteData(props.entityname, selectedDataItem.SID);
    console.log("Loading");
    if (!res.success) {
      console.log(res.message);
      errorHandler(res.message);
      return;
    }
    loaddata({ per_page: page_take }, wherestatement, andWhereStatement, '');
    utility.deleteLocalStorageItem(props.entityname);
  }

  const dataStateChange = async (e) => {
    var current_page = e.dataState.skip / e.dataState.take + 1;

    await loaddata({
      per_page: e.dataState.take,
      current_page: current_page,
      orderby: "SID",
      direction: "asc",
    }, searchText);
    setGridDataState(e.dataState);
  };

  const onArchiveClick = () => {
    setShowArchived(!showArchived);
    if (!showArchived) {
      loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, true);
    } else {
      loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, false);
    }

  }

  const refreshCollection = () => {
    loaddata({ per_page: page_take }, wherestatement, andWhereStatement, "", showArchived);
    setOpenForm(false);
    setSearchText('');
  }

  const editgrid = (data) => {
    console.log(data);
    let filterGridDataLocal = [...filterGridData];
    filterGridDataLocal = filterGridDataLocal.map(item => {
      if (data._id == item._id) {
        console.log(item);
        return data;
      }
      return item;
    })


    if (!filterGridDataLocal.some((item) => item._id == data._id)) {
      // here it is new value data has been added on filter data 
      filterGridDataLocal = [...filterGridData, data];
    }
    // update ui
    setFilterGridData(filterGridDataLocal.sort((a, b) => a.sequence - b.sequence));
    setOpenForm(false)

  }

  return (
    <>
      <div>
        {showTitle && (
          <>
            <h1 className="page-title txt-color-bludeDark">
              <i
                className="fa fa-table marlr"
                style={{ fontSize: "20px", color: "GrayText" }}
              ></i>
              <span>{myTitle === "" ? props.entityname : myTitle}</span>
            </h1>
          </>
        )}
        <div className="flex-container">
          <div className="martb mar">
            {showBack && (
              <BackRoundButton
                onClick={() => navigate(-1)}
              />
            )}

            {showAdd && (
              <AddRoundButton
                onClick={() => enterEdit({ SID: 0 })}
              />
            )}
          </div>


          <div className="flex-container-reverse flex-item-auto">
            <div className="input-group input-group w-300 martb" >
              <input
                type="text"
                className="form-control shadow-inset-2 pt-0 pb-0"
                id="searchInput"
                placeholder={lang.search_button_text}
                onChange={onSearch}
                value={searchText}
                onFocus={(e) => {
                  if (props.setOutSideFocus) {
                    props.setOutSideFocus(true);
                  }
                }}
                onBlur={(e) => {
                  if (props.setOutSideFocus) {
                    props.setOutSideFocus(false);
                  }
                }}
              />
              <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                  </span>
                </div>
              </button>
            </div>
            {showPreferenceButton && <div className="mar" style={{ paddingTop: "12px" }}>
              <RoundButton
                icon={"gear"}
                title={lang.preference_button_tooltip}
                onClick={() => { setShowPreferences(!showPreferences) }}
              />
            </div>
            }
            <div className="ml-2" style={{ marginTop: "11px" }}>
              <RoundButton
                icon={"refresh"}
                title={lang.refresh_button_tooltip}
                onClick={refreshCollection}
              />
            </div>
            <div >
              <DropDownList
                data={[...entityNameData]}
                name="filterName"
                textField="module"
                onChange={onChange}
                value={dataItem.filterName}
                style={{ width: "140px", marginTop: "11px", backgroundColor: 'white' }}
                validator={(value) => value ? "" : "Please select value"}
              />
            </div>
            {!hideArchive && <div className="d-inline mt-3 mr-2">
              <input type='checkbox' name='FilteredArchive' checked={showArchived} onClick={onArchiveClick} onChange={() => { }} />  {lang.archive}
            </div>}
          </div>
        </div>
        {isLoading && <Loader />}

        {isNoRecords && <div style={{ margin: "0px 500px" }}><h4><strong>{lang.no_records_found}</strong></h4></div>}
        {!isLoading && !isNoRecords && <Grid
          style={{
            height: "76vh",
          }}
          data={orderBy(filterGridData, sort)}
          sortable={sortable}
          sort={sort}
          onSortChange={(e) => {
            setSort(e.sort);
          }}
          rowRender={gridRowRender}
          filterable={filterable}
          {...dataState}
          pageable={isPageable}
          onDataStateChange={dataStateChange}
          total={total}
        >
          {/* Edit Row */}

          {showCommandCell && (
            <GridColumn cell={MyCommandCell} width={"60px"} locked={true} resizable={false} />
          )}

          {/* Rows Loop */}
          {dataColumns.map((column, index) => {
            if (column.type === COLUMNSTYPE.date) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyDateCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
              // }else if (column.name === COLUMNSTYPE.time) {
            } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyTimeCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            } else if (column.name === "SID") {
              return;
              //we are not returning any value for SID column
            } else if (column.type === COLUMNSTYPE.image) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyImageCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            } else if (column.type === COLUMNSTYPE.select) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyDatalookupCell}
                  title={column.label}
                  width={column.width ?? 200}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.toggle) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyCustomToggleButton}
                  title={column.label}
                  width={column.width ?? 80}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.checkbox) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyCheckBoxCell}
                  title={column.label}
                  width={column.width ?? 80}
                />
              );
            }
            else if (column.type === COLUMNSTYPE.array) {
              return (
                <GridColumn
                  key={index}
                  field={column.name}
                  cell={MyArrayCell}
                  title={column.label}
                  width={column.width ?? 200}
                  format={column.format ?? "Description"} //display item in object
                />
              );
            } else {
              return (
                column.type !== COLUMNSTYPE.hidden && (
                  <GridColumn
                    key={index}
                    field={column.name}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                )
              );
            }
          })}
        </Grid>}

        {openForm && (
          <CustomEditForm
            {...props} //to get other props from customEditForm
            cancelEdit={handleCancelEdit}
            onSubmit={handleSubmit}
            editgrid={editgrid} //to refresh the collection when data added
            item={editItem}
            dataColumns={dataColumns}
          />
        )}

        {warningDialog &&
          <DeleteConfirmDialogBox message={`Do you really want to delete ${props.title ?? props.entityname}`} onClose={handleDailogClose} onConfirmDelete={onConfirmDelete} />}

        {deleteError &&
          <DeleteErrorDialogBox message={`Data of ${props.title ?? props.entityname} con Not be deleted  Due to Foreign Key Inclusion`} onClose={handleErrorDialogClose} errorMessage={deleteErrorMessage} title={props.title} />}
        {showPreferences && <UserPreference entityName={entityName} handleClose={onCloseUserPrefence} />}

      </div>
    </>
  );
};