/* eslint-disable */
import React from 'react';

const DigiSignDisplay = ({ selectedData, buttonName, handleNextClick, handleSkipClick }) => {
    return (
        <div className="col bg-secondary text-white p-4 flex-grow-1" style={{ flex: 1 }}>
            <h2>{selectedData?.title}</h2>
            <p className='mt-2' style={{fontSize:'15px', height:'40px'}}>
                {selectedData?.content}
            </p>
            <img src={selectedData?.image ?? ""} alt="Demo" style={{ maxWidth: '100%', height:'71%', overflow:'hidden', borderRadius: '5px' }} />
            <div className="content-buttons d-flex justify-content-between mt-2">
                <button className="btn btn-secondary rounded" onClick={handleSkipClick}>Skip</button>
                <button className="btn btn-primary rounded" onClick={handleNextClick}>{buttonName}</button>
            </div>
        </div>
    );
};

export default DigiSignDisplay;
