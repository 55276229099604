/* eslint-disable */
import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { PlanningStyle } from "../planningPreference/PlanningStyle";
import { General } from "./General";
import { EventStyle } from "../planningPreference/EventStyle";
import { EditPageHeader } from "../../../components/EditPageHeader";
import * as API from "../../../framework/API/api";
import { ENTITYNAME, LOCALSTORAGE_KEY, LOGEVENT, MODULE, PLANNINGEVENTSTYLE, PLANNINGEVENTSTYLE_PRIORITY } from "../../../framework/constant/constant";
import { utility } from "../../../framework/utility/utilityProvider";
import { toast } from 'react-toastify';
import { useTranslation } from '../../../locale/useTranslation';
import { GenreStyle } from './GenreStyle';
import BossDialog from '../../../components/BossDialog';

export const PlanningPreference = (props) => {

    const [tabNumber, setTabNumber] = React.useState("1");
    const user = utility.getValue(LOCALSTORAGE_KEY.userData);
    const lang = useTranslation();

    const [planningPreferenceModel, setPlanningPreferenceModel] = useState({

        SID: 0,//zero for new user
        entityName: "planningpreference",
        user_id: user._id,
        value: {
            SelectedColumns: [],   //change in selected column 
            EventStyle: PLANNINGEVENTSTYLE,
            StartType : "Nominal",
            TimeZone: {
                DayStartTime: 21600000,
                PrimeStartTime: 21600000,
                PrimeEndTime: 64800000
            },
            SlotDuration: 60,
            View: {},
            ShowFormat: false,
            ShowNoSeg: false,
            ShowContract: false,
            IsGenreColorPreference: false,
            GenreStyle: []
        }

    });

    useEffect(() => {
        loadEditData();
    }, []);

    const handleTabChange = (event, newValue) => {
        setTabNumber(`${newValue}`);
    };

    const updatePlanningPreferenceModel = (data) => {
        setPlanningPreferenceModel((old) => ({ ...old, value: { ...old.value, SelectedColumns: data } }))
    }

    const getEventStyle = (data) => {
        setPlanningPreferenceModel((old) => ({ ...old, value: { ...old.value, EventStyle: data } }))
    }

    const getGenreStyle = (data) => {
        setPlanningPreferenceModel((old) => ({ ...old, value: { ...old.value, GenreStyle: data } }))
    }

    const getSlotDuration = (data) => {
        setPlanningPreferenceModel((old) => ({ ...old, value: { ...old.value, SlotDuration: data } }))
    }

    const getStartType = (data) => {
        setPlanningPreferenceModel((old) => ({ ...old, value: { ...old.value, StartType: data.StartType } }))
    }

    const getTimeZone = (data) => {
        setPlanningPreferenceModel((old) => ({
            ...old, value: {
                ...old.value, TimeZone: {
                    DayStartTime: data.DayStartTime,
                    PrimeStartTime: utility.convertStringWithFramesToMilliseconds(data.PrimeStartTime),
                    PrimeEndTime: utility.convertStringWithFramesToMilliseconds(data.PrimeEndTime)
                }
            }
        }))
    }
    const getShowFormat = (data) => {
        setPlanningPreferenceModel((old) => ({ ...old, value: { ...old.value, ShowFormat: data } }))
    }
    const getShowNoSeg = (data) => {
        setPlanningPreferenceModel((old) => ({ ...old, value: { ...old.value, ShowNoSeg: data } }))
    }
    const getShowContract = (data) => {
        setPlanningPreferenceModel((old) => ({ ...old, value: { ...old.value, ShowContract: data } }))
    }

    const getShowGenre = (data) => {
        setPlanningPreferenceModel((old) => ({ ...old, value: { ...old.value, IsGenreColorPreference: data } }))
    }

    const setView = (data) => {
        setPlanningPreferenceModel((old) => ({ ...old, value: { ...old.value, View: data } }))
    }

    const setWeekViewStart = (data) => {
        setPlanningPreferenceModel((old) => ({ ...old, value: { ...old.value, weekStartDay: data } }))
    }

    const loadEditData = async () => {

        var args = { query: [['user_id', '=', user._id], ['entityName', '=', "planningpreference"]] }
        var planningPreferenceRes = await API.getData(ENTITYNAME.UserPreference, args);

        if (planningPreferenceRes.data.length > 0) {

            //SET EVENT STYLE PRIORITY WISE
            let eventStyle = planningPreferenceRes.data[0]?.value?.EventStyle?.length < 6 ? 
            [ ...planningPreferenceRes.data[0].value.EventStyle, { Id: 7, EventType: "SDD", FontSize: 10, ForegroundColor: '#000000', BackgroundColor: "#26355D" }] : planningPreferenceRes.data[0].value.EventStyle;

            if(eventStyle && eventStyle.length > 0){
                eventStyle = eventStyle.map((x) => { return { ...x, Sequence : PLANNINGEVENTSTYLE_PRIORITY[x.EventType] ?? 100 }})
            }


            setPlanningPreferenceModel({
                ...planningPreferenceRes.data[0],
                value : {
                    ...planningPreferenceRes.data[0]?.value,
                    StartType : planningPreferenceRes.data[0]?.value?.StartType ?? "Nominal",
                    EventStyle : eventStyle.sort((a, b) => (a.Sequence > b.Sequence) ? 1 : -1)
                }
            });
        }

    }

    const handleSubmit = async () => {

        if(planningPreferenceModel.value.IsGenreColorPreference && planningPreferenceModel.value.GenreStyle.length == 0){
            toast.error(lang.please_add_atleast_one_genre_value_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return;
        }
        let dataToSave = {
            ...planningPreferenceModel,
            value: {
                ...planningPreferenceModel.value,
                IsGenreColorPreference: planningPreferenceModel.value.IsGenreColorPreference ?? false,
                GenreStyle: planningPreferenceModel.value.IsGenreColorPreference ? planningPreferenceModel.value.GenreStyle : []
            },
        }

        console.log(dataToSave);
        let res = await API.saveData("userPreferenceColumn", dataToSave);
        if (res.success) {
            props.closeForm();

            // resetting local storage of preference
            utility.deleteLocalStorageItem(LOCALSTORAGE_KEY.planningpreference)
            await API.getDataLookup(ENTITYNAME.UserPreference, {
                query: [["entityName", "=", "planningpreference"], ["user_id", "=", user._id]],
                sort: { SID: 1 },
                page: null,
                perPage: null
            }, LOCALSTORAGE_KEY.planningpreference);

            props.updatePlanningPreferenceStyle();

            let logData = { event: LOGEVENT.PLANNING_PREFERENCE, module: MODULE.PLANNING, data: dataToSave, message: LOGEVENT.PLANNING_PREFERENCE };
            API.SaveLogs(logData);
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    return (
        <BossDialog
            title={lang.planing_preference_dialog_header}
            onClose={props.closeForm}
            width={810}
            height={599}
        >
            <div>
                <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.closeForm} showTitle={false} />
                <Box sx={{ width: '100%', typography: 'body1' }}>
                    <TabContext value={tabNumber}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                                <Tab label={lang.events_label} value="1" />
                                <Tab label={lang.columns_label} value="2" />
                                <Tab label={lang.general_label} value="3" />
                                <Tab label={lang.genre_label} value="4" />
                            </TabList>
                        </Box>
                        <TabPanel value={"1"}>
                            <EventStyle eventStyleData={planningPreferenceModel.value.EventStyle} setEventStyle={getEventStyle} />
                        </TabPanel>
                        <TabPanel value={"2"}>
                            <PlanningStyle entityName={'programschedule'} selectedColumns={planningPreferenceModel.value.SelectedColumns} updatePlanningPreferenceModel={updatePlanningPreferenceModel} />
                        </TabPanel>
                        <TabPanel value={"3"}>
                            <General timeZone={planningPreferenceModel.value.TimeZone} startType={planningPreferenceModel.value.StartType} weekStartDay={planningPreferenceModel.value.weekStartDay} setShowViewType={planningPreferenceModel.value.View} slotDurationData={planningPreferenceModel.value.SlotDuration} showFormatData={planningPreferenceModel.value.ShowFormat} showNoSegData={planningPreferenceModel.value.ShowNoSeg} showContractData={planningPreferenceModel.value.ShowContract} setSlotDurationValue={getSlotDuration} setTimeZone={getTimeZone} setViewValue={setView} setShowFormat={getShowFormat} setShowNoSeg={getShowNoSeg} setShowContract={getShowContract} setWeekViewStart={setWeekViewStart} getStartType={getStartType} />
                        </TabPanel>
                        <TabPanel value={"4"}>
                            <GenreStyle genreStyleData={planningPreferenceModel?.value?.GenreStyle ?? []} showGenre={planningPreferenceModel?.value?.IsGenreColorPreference} setGenreStyle={getGenreStyle} getShowGenre={getShowGenre}/>
                        </TabPanel>
                    </TabContext>
                </Box>
            </div>
        </BossDialog>
    )
}