/* eslint-disable */
import React, { useEffect, useState } from "react";
import * as API from '../../framework/API/api'
import { Form, FormElement } from "@progress/kendo-react-form";
import { ENTITYNAME } from "../../framework/constant/constant";
import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { DropDownList, MultiSelect } from "@progress/kendo-react-dropdowns";
import { toast } from 'react-toastify';
import AudioTrack from "./AudioTrack";
import { useTranslation } from "../../locale/useTranslation";

function MediaTemplateMataDataEditForm(props) {
  const [pgRatings, setPGRatings] = useState([]);
  const [censorRatings, setCensorRatings] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [categories, setCategories] = useState([]);
  const [mediaTechnicalPrototypes, setMediaTechnicalPrototypes] = useState([]);
  const [tabNumber, setTabNumber] = React.useState("1");
  const [audioTrackData, setAudioTrackData] = useState(props.metaData?.AudioTrack);
  const lang = useTranslation();

  const [dataItem, setDataItem] = useState(props?.metaData);

  useEffect(() => {
    loadcombo();
      setAudioTrackData(props.metaData?.AudioTrack);
      setDataItem(props?.metaData)
    
  }, [props.metaData, props.SID]);

  const handleTabChange = (event, newValue) => {
    setTabNumber(`${newValue}`);
  };

  const loadcombo = async () => {
    var pgRes = await API.getDataLookup(ENTITYNAME.PGRating, { sort: { Description: 1 } });
    setPGRatings(pgRes.data);
    var crRes = await API.getDataLookup(ENTITYNAME.CensorRating, { sort: { Description: 1 } });
    setCensorRatings(crRes.data);
    var mtpRes = await API.getDataLookup(ENTITYNAME.MediaTechnicalPrototype, { sort: { Description: 1 } });
    setMediaTechnicalPrototypes(mtpRes.data);
    var languages = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
    setLanguages(languages.data);
    var categories = await API.getDataLookup(ENTITYNAME.ContentCategory, { sort: { Name: 1 } });
    setCategories(categories.data);
  }

  const onChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });    
    props.getMetaData({
      ...dataItem,
      [e.target.name]: e.target.value,
      AudioTrack: audioTrackData
    });
  }

  //not using now
  const setMetaDataInMainObject = ( audioTrack = audioTrackData) => {
    const saveData = {
      ...dataItem,
      Languages: dataItem.Languages && dataItem.Languages.length > 0 ? dataItem.Languages?.map((item) => {
        return {
          _id: item._id,
          SID: item.SID,
          Description: item.Description
        }
      }) : [],
      PGRating: dataItem.PGRating && Object.keys(dataItem.PGRating).length > 0 ? {
        _id: dataItem.PGRating._id,
        SID: dataItem.PGRating.SID,
        Description: dataItem.PGRating.Description
      } : {},
      CensorRating: dataItem.CensorRating && Object.keys(dataItem.CensorRating).length > 0 ? {
        _id: dataItem.CensorRating._id,
        SID: dataItem.CensorRating.SID,
        Description: dataItem.CensorRating.Description
      } : {},
      MediaTechnicalPrototype: dataItem.MediaTechnicalPrototype && Object.keys(dataItem.MediaTechnicalPrototype).length > 0 ? {
        _id: dataItem.MediaTechnicalPrototype._id,
        SID: dataItem.MediaTechnicalPrototype.SID,
        Description: dataItem.MediaTechnicalPrototype.Description
      } : {},
      Categories: dataItem.Categories && dataItem.Categories.length > 0 ? dataItem.Categories?.map((item) => {
        return {
          _id: item._id,
          SID: item.SID,
          Name: item.Name,
          parent: item.parent
        }
      }) : [],
      ProductionYear: dataItem.ProductionYear,
      AudioTrack: audioTrack,
    }
    props.getMetaData(saveData);
    props.expand && props.expand(true);
  }


  const setAudioTrack = (data) => {
    console.log(data)
    setAudioTrackData(() => data);
    setMetaDataInMainObject(data);
  }

  return (
    <div>
      <Form
        render={(formRenderProps) => (
          <FormElement>
            <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px" }}>
              <div className="row" style={{ margin: "0px" }}>
                <Box sx={{ width: '100%', typography: 'body1' }}>
                  <TabContext value={tabNumber}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                      <TabList onChange={handleTabChange} aria-label="lab API tabs example">
                        <Tab label={lang.general_label} value="1" />
                        <Tab label={lang.audio_track_label} value="2" />
                      </TabList>
                    </Box>
                    <TabPanel value={"1"}>
                      <div className="row" style={{ margin: "5px" }}>
                        <div className="col-9 mt-1">
                          <div className="row">
                            <div className="col-6 form-group">
                              <label htmlFor="TabView">{lang.pg_rating_label}</label><br />
                              <DropDownList
                                style={{
                                  backgroundColor: "white",
                                }}
                                data={pgRatings}
                                name="PGRating"
                                textField="Description"
                                dataItemKey="_id"
                                value={dataItem.PGRating}
                                onChange={onChange}
                              />
                            </div>
                            <div className="col-6 form-group">
                              <label htmlFor="TabView">{lang.censor_rating_label}</label><br />
                              <DropDownList
                                style={{
                                  backgroundColor: "white",
                                }}
                                data={censorRatings}
                                name="CensorRating"
                                textField="Description"
                                dataItemKey="_id"
                                value={dataItem.CensorRating}
                                onChange={onChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6 form-group">
                              <label htmlFor="">{lang.language_label}</label>
                              <MultiSelect
                                data={languages}
                                dataItemKey="_id"
                                textField="Description"
                                value={dataItem.Languages}
                                onChange={onChange}
                                name={"Languages"}
                              />
                            </div>
                            <div className="col-6 form-group">
                              <label htmlFor="TabView">{lang.technical_prototype_label}</label><br />
                              <DropDownList
                                style={{
                                  backgroundColor: "white",
                                }}
                                data={mediaTechnicalPrototypes}
                                name="MediaTechnicalPrototype"
                                textField="Description"
                                dataItemKey="_id"
                                value={dataItem.MediaTechnicalPrototype}
                                onChange={onChange}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6 form-group">
                              <label htmlFor="">{lang.production_year_label}</label>
                              <input type="text" className="form-control form-control-sm" name="ProductionYear" value={dataItem.ProductionYear} onChange={(e) => onChange(e)} required />
                            </div>

                            <div className="col-6 form-group">
                              <label htmlFor="">{lang.keywords_label}</label>
                              <input type="text" className="form-control form-control-sm" name="Keywords" value={dataItem.Keywords} onChange={(e) => onChange(e)} required />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-6 form-group">
                              <label htmlFor="">{lang.categories_label}</label>
                              <MultiSelect
                                dataItemKey="_id"
                                data={categories}
                                textField="Name"
                                value={dataItem.Categories}
                                onChange={onChange}
                                name={"Categories"}
                              />
                            </div>
                            <div className="col-6 form-group">
                              <label htmlFor="">{lang.mamID_label}</label>
                              <input type="text" className="form-control form-control-sm" name="MamID" value={dataItem.MamID} onChange={(e) => onChange(e)} required />
                            </div>
                          </div>
                        </div>
                      </div>
                    </TabPanel>
                    <TabPanel value={"2"}>
                      <AudioTrack data={audioTrackData ?? []} setAudioTrackData={setAudioTrack} />
                    </TabPanel>
                  </TabContext>
                </Box>
              </div>
            </div>
          </FormElement>
        )} />
    </div>
  )
}

export default MediaTemplateMataDataEditForm
