/* eslint-disable */
import React, { useCallback, useEffect, useState } from "react";
import {
  Grid,
  GridColumn,
  getSelectedState,
} from "@progress/kendo-react-grid";
import { getter } from "@progress/kendo-react-common";
import * as API from "../../framework/API/api";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { ENTITYNAME, MEDIACATEGORIES } from "../constant/constant";
import BossDialog from "../../components/BossDialog";

const DATA_ITEM_KEY = "SID";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const IsShortMediaCollectionSelection = (props) => {

  const lang = useTranslation();
  const [gridData, setGridData] = useState([]);
  const [dataColumns, setDataColumns] = useState([
    {
      "name": "Title",
      "label": "Title",
      "sequence": 1,
      "required": true,
      "type": "input",
      "entityName": "MediaEpisode",
      "maxLength": 250,
      "format": null,
      "width": null
    },
    {
      "name": "AssetId",
      "label": "Asset Id",
      "sequence": 4,
      "required": true,
      "type": "input",
      "entityName": "MediaEpisode",
      "maxLength": 20,
      "format": null,
      "width": null
    }
  ]);
  const [searchText, setSearchText] = useState('');

  const [mediaCategories, setMediaCategories] = useState([]);
  let [selectedMediaCategory, setSelectedMediaCategory] = useState({});

  const [selectedState, setSelectedState] = useState({});
  const [count, setCount] = useState(0);


  useEffect(() => {
    // loaddata();
    loaddata(searchText);
    loadcombo();
  }, []);

  const loadcombo = async () => {
    let mediaCategories = await API.getData(ENTITYNAME.MediaCategory);
    setMediaCategories(mediaCategories.data);
  }

  const getFilterQueryAndEntityName = () => {
    let obj = { entityName: 'mediaepisode', wherestatement: ['MediaCategory.SID', '=', MEDIACATEGORIES.Interstitial] }
    if (selectedMediaCategory && selectedMediaCategory.SID > 0) {
      obj.entityName = 'mediaepisode'
      obj.wherestatement = ['MediaCategory.SID', '=', selectedMediaCategory.SID];

    }
    return obj;
  }

  const loaddata = async (searchText) => {
    try {

      let filter = getFilterQueryAndEntityName()
      let json = await API.getEntitiesWithSearch(filter.entityName, { query: filter.wherestatement }, dataColumns, searchText);

      setGridData(json.data);
      setCount(json.data.length);

    } catch (error) {
      console.log("error", error);
    }

  };

  const onSelectionChange = useCallback(
    (event) => {
      const newSelectedState = getSelectedState({
        event,
        selectedState: selectedState,
        dataItemKey: DATA_ITEM_KEY,
      });

      setSelectedState(newSelectedState);

    }, [selectedState]
  );

  const onHeaderSelectionChange = useCallback((event) => {

    const checkboxElement = event.syntheticEvent.target;
    const checked = checkboxElement.checked;
    const newSelectedState = {};
    event.dataItems.forEach((item) => {
      newSelectedState[idGetter(item)] = checked;
    });
    setSelectedState(newSelectedState);

  }, []);



  //implementing search

  const onSearchEnter = (event) => {
    if (event.key === "Enter") {
      // Cancel the default action, if needed
      event.preventDefault();
      onSearchClick();
    }
  }

  const handleServerSideSearch = (e) => {
    setSearchText(() => e.target.value);

    if (e.target.value == '') {
      loaddata('');
    }
  };

  const onSearchClick = () => {

    if (searchText != '') {
      loaddata(searchText);
    } else {
      loaddata('');
    }

  }


  const checkIfItemExist = (item) => {

    let isFound = false;
    let existingEntries = props.existingItems ?? [];
    if (props.checkDuplicate ?? false) {
      isFound = existingEntries.some(element => {
        if (element.SID === item.SID) {
          return true;
        }
        return false;
      });
    }
    return isFound;

  };

  //to sendDataList to RailsEditForm and to close the collection
  const onSelection = () => {

    let selectedDataList = [];
    //loop for setting the selected obj in array
    gridData.map((obj) => {
      let key = obj.SID;
      //if true means checked
      if (selectedState[key]) {
        // checking duplicate as well
        if (!checkIfItemExist(obj)) {
          selectedDataList.push(obj);
        }
      }
    })

    if (selectedDataList.length != 0) {
      props.setDataList(selectedDataList, selectedMediaCategory);
      props.closeForm();
    } else {
      toast.info(`${lang.please_select_first_contract_collection_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  }

  const onChange = (e) => {
    selectedMediaCategory = e.target.value
    setSelectedMediaCategory(selectedMediaCategory)
    loaddata(searchText);
  }

  return (
    <BossDialog
      title={props.title ?? "ADD"}
      onClose={props.closeForm}
      width={props.width ?? "75vw"}
      height={props.height ?? "67vh"}>
      <div>
        <div className="row ml-1 mb-3" >
          <div className="col-5">
            <div className="row">
              <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-success col-2 mr-2" onClick={onSelection} >{lang.select_button_text}</button>
              <button className="k-button k-button-md k-rounded-md k-button-solid k-button-solid-error col-2" onClick={props.closeForm} >{lang.cancel_button_text}</button>
            </div>
          </div>
          <div className="col-3">
            <DropDownList
              style={{ backgroundColor: "white", height: "38px" }}
              data={mediaCategories}
              textField="Description"
              defaultValue={mediaCategories[0]}
              onChange={onChange}
            />
          </div>
          <div className="col-4" >
            <div className="input-group input-group mb-3" >
              <input type="text" className="form-control shadow-inset-2 pt-0 pb-0" id="searchInput" aria-label="type 2 or more letters" placeholder={lang.search_button_text} onKeyDown={onSearchEnter} onChange={handleServerSideSearch} />
              <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} onClick={onSearchClick}>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                  </span>
                </div>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div>
        <Grid
          data={gridData.map((item) => ({
            ...item,
            [SELECTED_FIELD]: selectedState[idGetter(item)],
          }))}

          dataItemKey={DATA_ITEM_KEY}
          selectedField={SELECTED_FIELD}
          selectable={{
            enabled: true,
            drag: false,
            cell: false,
            mode: props.mode ?? "multiple",
          }}
          onSelectionChange={onSelectionChange}
          onHeaderSelectionChange={onHeaderSelectionChange}
          style={{ height: "42vh", wdith: "60vw" }}
        >
          <GridColumn
            field={SELECTED_FIELD}
            width="50px"
            headerSelectionValue={
              gridData.findIndex((item) => !selectedState[idGetter(item)]) === -1
            }
          />
          <GridColumn field="AssetId" title={lang.assetId_column} editable={false} />
          <GridColumn field="Title" title={lang.title_column} editable={false} />
          <GridColumn field="MediaCategory.Description" title={lang.media_category_column} editable={false} />
        </Grid>
      </div>
      <div className="text-right mt-1">{`Total : ${count}`}</div>
    </BossDialog>
  );
};

export default IsShortMediaCollectionSelection;
