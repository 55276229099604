/* eslint-disable */
import React, { useEffect, useState } from 'react';
import * as API from '../../../framework/API/api';
import * as API_DIGITAL_SIGN from '../../../framework/API/api_digitalSign';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import { useTranslation } from '../../../locale/useTranslation';
import { toast, ToastContainer } from 'react-toastify';
import DigitalSignWelcomeForm from './DigitalSignWelcomeForm';
import { useNavigate } from 'react-router-dom';
import image from '../../../img/The-Power-of-Billboard-Advertising-1.jpg';
import logo from '../../../img/automatbLogo.png';

const DigitalSignSignUpForm = () => {

    const lang = useTranslation();
    const navigate = useNavigate();
    const urlParams = new URLSearchParams(location?.search ?? "");
    const welcomeScreen = urlParams?.get('welcomeScreen');

    useEffect(() => {
        if (welcomeScreen) {
            setIsShowWelcomeForm(true);
        } else {
            setIsShowWelcomeForm(false);
        }
    }, [welcomeScreen]);

    const blankDataItem = {
        name: '',
        email: '',
        password: '',
        companyName: '',
        countryCode: '',
        confirmPassword: '',
        phone: '',
        industry: {},
        // networkScreenSize: {},
        // howDidYouFoundUs: {},
    }

    const [dataItem, setDataItem] = useState(blankDataItem);
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [isShowWelcomeForm, setIsShowWelcomeForm] = useState(false);
    const emailRegex = new RegExp(/\S+@\S+\.\S+/);
    const [dataValue, setDataValue] = useState({
        countryCodes: [],
        industries: [],
        networkScreenSizes: [],
        howDidYouFoundUs: [],
    });

    useEffect(() => {
        loadCombo();
    }, []);

    const loadCombo = async () => {
        let res = await API_DIGITAL_SIGN.getSignupMasters();
        if (res.success) {
            setDataValue(res.data);
            setDataItem({ ...dataItem, countryCode: res.data.countryCodes[0].Value, networkScreenSize: res.data.networkScreenSizes[0] });
        } else {
            toast.error(res.message);
        }
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {
        if (dataItem.name == undefined || dataItem.name == '' || !dataItem.name?.toString().trim()) {
            toast.error(lang.please_enter_name_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.email == undefined || dataItem.email == "" || !emailRegex.test(dataItem.email)) {
            toast.error(lang.please_enter_email_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.password == undefined || dataItem.password == '' || !dataItem.password?.toString().trim()) {
            toast.error(lang.please_enter_password_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.confirmPassword == undefined || dataItem.confirmPassword == '' || !dataItem.confirmPassword?.toString().trim()) {
            toast.error(lang.please_enter_confirm_password_users_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.password !== dataItem.confirmPassword) {
            toast.error(lang.confirm_password_do_not_match_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.companyName == undefined || dataItem.companyName == '' || !dataItem.companyName?.toString().trim()) {
            toast.error(lang.please_enter_company_name_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.countryCode == undefined || dataItem.countryCode == "") {
            toast.error(lang.please_select_country_code_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.phone == undefined || dataItem.phone == '' || !dataItem.phone?.toString().trim()) {
            toast.error(lang.please_enter_phone_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        let countryData = dataValue.countryCodes.find((item) => item.Value == dataItem.countryCode);
        if (dataItem.phone?.length < countryData?.NoLength) {
            toast.error(lang.please_enter_correct_phone_number_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (dataItem.industry == undefined || Object.keys(dataItem.industry).length == 0) {
            toast.error(lang.please_select_industry_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        // if (dataItem.networkScreenSize == undefined || Object.keys(dataItem.networkScreenSize).length == 0) {
        //     toast.error(lang.please_select_network_screen_size_error_message, {
        //         position: toast.POSITION.TOP_RIGHT,
        //     });
        //     return false;
        // }
        // if (dataItem.howDidYouFoundUs == undefined || Object.keys(dataItem.howDidYouFoundUs).length == 0) {
        //     toast.error(lang.please_select_how_did_you_found_us_error_message, {
        //         position: toast.POSITION.TOP_RIGHT,
        //     });
        //     return false;
        // }
        return true;
    }

    const handleSignup = async (e) => {
        e.preventDefault();

        if (!isValid()) return;
        let countryData = dataValue.countryCodes.find((item) => item.Value == dataItem.countryCode);
        let finalData = {
            name: dataItem.name,
            email: dataItem.email?.toLowerCase(),
            password: dataItem.password,
            companyName: dataItem.companyName,
            countryCode: countryData.Code,
            countryPrefix: countryData.Code,
            currencyCode: countryData.CurrencyCode,
            phone: dataItem.phone,
            industry: dataItem.industry ? {
                _id: dataItem.industry?._id,
                Description: dataItem.industry?.Description
            } : {},
            // networkScreenSize: dataItem.networkScreenSize ? {
            //     _id: dataItem.networkScreenSize?._id,
            //     Description: dataItem.networkScreenSize?.Description
            // } : {},
            // howDidYouFoundUs: dataItem.howDidYouFoundUs ? {
            //     _id: dataItem.howDidYouFoundUs?._id,
            //     Description: dataItem.howDidYouFoundUs?.Description
            // } : {},
        }
        console.log(finalData);
        let response = await API_DIGITAL_SIGN.onDigitalSignup(finalData);
        if (response.success) {
            navigate('?welcomeScreen=true');
        } else {
            toast.error(response.message);
        }
    };

    const labelStyle = {
        color: '#7b838a',
        textTransform: 'uppercase',
        letterSpacing: '.1rem'
    };

    return (
        <>
            <div className="container-fluid vh-100 d-flex" style={{ padding: "0px", backgroundColor: 'rgba(0, 0, 0, 0.6)' }}>
                <div
                    className="position-absolute w-100 h-100"
                    style={{
                        backgroundImage: `url(${image})`,
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                        filter: 'blur(10px)',
                        zIndex: -1,
                        position: 'relative',
                    }}
                ></div>
                <div className="d-flex justify-content-end align-items-center w-100 text-center" style={{ color: 'white', lineHeight: 1, marginLeft: '10%' }}>
                    <div style={{ fontSize: '50px', fontWeight: 600, letterSpacing: '.2rem' }}>
                        Manage Your Digital Signage Efficiently, Anytime & from Anywhere
                        {/* Your Gateway to <span style={{fontSize: '60px', color: '#FC6701'}}>Seamless Signage</span> */}
                    </div>
                </div>
                <div className="d-flex justify-content-end align-items-center w-100 h-100" style={{ marginRight: '10%' }}>
                    <div className="col-auto d-flex flex-column justify-content-end align-items-start p-5 bg-white" style={{ borderRadius: '5px' }}>
                        <img src={logo} alt="Logo" className="mb-2" style={{ width: '200px' }} />
                        <h2 style={{ fontSize: '3rem', marginBottom: '10px', lineHeight: 1, fontWeight: 600, letterSpacing: '.1rem' }}>{lang.sign_up_label}</h2>
                        <div>
                            <label style={labelStyle} htmlFor="name">{lang.name_label} *</label>
                            <input
                                style={{ width: "173%", display: 'inline-block' }}
                                type="text"
                                className="form-control form-control-sm"
                                name="name"
                                placeholder="Enter name"
                                value={dataItem.name}
                                onChange={onChange}
                            />
                        </div>
                        <div className='mt-1'>
                            <label style={labelStyle} htmlFor="email">{lang.email_address_label} *</label>
                            <input
                                style={{ width: "141%", display: 'inline-block' }}
                                type="email"
                                className="form-control form-control-sm"
                                name="email"
                                placeholder="Enter email"
                                value={dataItem.email}
                                onChange={onChange}
                            />
                        </div>
                        <div className="mt-1 row">
                            <div className='col-6'>
                                <label style={labelStyle} htmlFor="password">{lang.password_label} *</label>
                                <input
                                    type={showPassword ? 'text' : 'password'}
                                    style={{ width: "111%", display: "block", padding: "10px 20px" }}
                                    className="form-control form-control-sm"
                                    name="password"
                                    placeholder="Password"
                                    value={dataItem.password}
                                    onChange={onChange}
                                />
                                <i style={{ color: '#7b838a', position: "absolute", top: "57%", right: "0px", cursor: "pointer" }} className={`icon-append fa ${showPassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => setShowPassword(!showPassword)} />
                            </div>
                            <div className='col-6'>
                                <label style={labelStyle} htmlFor="confirm password">{lang.confirm_password_label} *</label>
                                <input
                                    type={showConfirmPassword ? 'text' : 'password'}
                                    style={{ width: "111%", display: "block", padding: "10px 20px" }}
                                    name="confirmPassword"
                                    className="form-control form-control-sm"
                                    placeholder="Confirm Password"
                                    value={dataItem.confirmPassword}
                                    onChange={onChange}
                                />
                                <i style={{ color: '#7b838a', position: "absolute", top: "57%", right: "0px", cursor: "pointer" }} className={`icon-append fa ${showConfirmPassword ? 'fa-eye' : 'fa-eye-slash'}`} onClick={() => setShowConfirmPassword(!showConfirmPassword)} />
                            </div>
                        </div>
                        <div className="mt-1">
                            <label style={labelStyle} htmlFor="companyName">{lang.company_name_label} *</label>
                            <input
                                style={{ width: "130%", display: 'inline-block' }}
                                type="text"
                                className="form-control form-control-sm"
                                name="companyName"
                                placeholder="Enter Company Name"
                                value={dataItem.companyName}
                                onChange={onChange}
                            />
                        </div>
                        <div className="mt-1">
                            <label style={labelStyle} htmlFor="countryCode">{lang.country_code_label} *</label>
                            <select
                                style={{ width: "123%", display: 'inline-block' }}
                                className="form-control form-control-sm"
                                name="countryCode"
                                value={dataItem.countryCode}
                                onChange={onChange}
                            >
                                {dataValue.countryCodes.map((country) => {
                                    return (<option key={country.SID} value={country.Value}>
                                        {country.Description} ({country.Value})
                                    </option>)
                                })
                                }
                            </select>
                        </div>
                        <div className="mt-1">
                            <label style={labelStyle} htmlFor="phone">{lang.phone_no_label} *</label>
                            <input
                                style={{ width: "165%", display: 'inline-block' }}
                                type="number"
                                className="form-control form-control-sm"
                                name="phone"
                                placeholder="Enter phone number"
                                value={dataItem.phone}
                                onChange={(e) => {
                                    const value = e.target.value;
                                    if (/^\d*$/.test(value) && value.length <= dataValue?.countryCodes?.find((item) => item?.Value == dataItem?.countryCode)?.NoLength) {
                                        onChange(e);
                                    }
                                }}
                                maxLength={dataValue?.countryCodes?.find((item) => item?.Value == dataItem?.countryCode)?.NoLength}
                            />
                        </div>
                        <div className="mt-1">
                            <label style={labelStyle} htmlFor="industry">{lang.industries_label} *</label>
                            <br />
                            <DropDownList
                                style={{ backgroundColor: 'white', width: "300px" }}
                                data={dataValue.industries}
                                dataItemKey="_id"
                                textField="Description"
                                name='industry'
                                value={dataItem.industry}
                                onChange={onChange}
                            />
                        </div>
                        {/* <div className="form-group">
                                <label htmlFor="networkScreenSize">{lang.network_screen_size_label} *</label>
                                <DropDownList
                                    style={{ backgroundColor: 'white' }}
                                    data={dataValue.networkScreenSizes}
                                    dataItemKey="_id"
                                    textField="Description"
                                    name='networkScreenSize'
                                    value={dataItem.networkScreenSize}
                                    onChange={onChange}
                                />
                            </div>
                            <div className="form-group">
                                <label htmlFor="howYouFoundUs">{lang.how_did_you_found_us_label} *</label>
                                <DropDownList
                                    style={{ backgroundColor: 'white' }}
                                    data={dataValue.howDidYouFoundUs}
                                    dataItemKey="_id"
                                    textField="Description"
                                    name='howDidYouFoundUs'
                                    value={dataItem.howDidYouFoundUs}
                                    onChange={onChange}
                                />
                            </div> */}
                        <p className='mt-1 mb-1'>{lang.already_have_an_account_label} <a onClick={() => navigate('/')} className="text-primary cursor-pointer">{lang.sign_in_label}</a></p>
                        <button type="submit" className="btn btn-primary btn-block" onClick={handleSignup} >{lang.sign_up_label}</button>

                    </div>
                </div>
            </div>
            <ToastContainer />
            {isShowWelcomeForm && <DigitalSignWelcomeForm email={dataItem.email} password={dataItem.password} />}
        </>
    )
}

export default DigitalSignSignUpForm;