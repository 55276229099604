import { useTranslation } from "../../../../locale/useTranslation";

const SaveButton = (props) => {
    const lang = useTranslation();
    return <button title={props.tooltip ? props.tooltip : lang.save_button_tooltip} style={props.style ?? { borderRadius: "4px" }} type="submit" onClick={props.onClick} className={`btn-square btn-${props.btnColor ?? 'primary'}`} >
        &nbsp; {props.title ?? lang.save_button_text} &nbsp;
    </button>
}

export default SaveButton;
