/* eslint-disable */

import { DAYS, PLATFORM, PRODUCTS } from "../../../constant/constant";

//to show item stored in array on collection
export const PlatformCell = (props) => {

    const field = props?.field || "";
    const value = props?.dataItem[field];

    let displayValue = '';
    if(value == undefined || value.length == 0)
    {
      displayValue = '';
    }else
    {
      const valueArray = value?.split(","); 

      valueArray?.map((obj, index) => {
        if(obj != undefined){
          displayValue = displayValue + (PLATFORM.find(x => x.SID == obj)?.Description??"") + (index == valueArray?.length -1 ?  "" : ", ");
        }else{
          displayValue = ''
        }
      })
    }
    
    return <td>
      {displayValue}
    </td>;
  };

export const stringEnumCell = (props, enumValue , display="Description" ) => {

  const field = props?.field || "";
  const value = props?.dataItem[field];

  let displayValue = '';
  if(value == undefined || value.length == 0)
  {
    displayValue = '';
  }else
  {
    const valueArray = value?.split(","); 

    valueArray?.map((obj, index) => {
      if(obj != undefined){
        displayValue = displayValue + (enumValue.find(x => (x.SID ? x.SID : x.ID) == obj)[display] ?? "") + (index == valueArray?.length -1 ?  "" : ", ");
      }else{
        displayValue = ''
      }
    })
  }
  return <td>
    {displayValue}
  </td>;
};

export const arrayEnumCell = (props) => {

  let foriegnKey = {
    Products: PRODUCTS,
  }

  const field = props.field || "";
  const value = props.dataItem[field];

  let displayValue = '';
  if(value == undefined || value.length == 0)
  {
    displayValue = '';
  }
  else{
    Object.keys(foriegnKey[field]).forEach((key, index) => {
      if(value.includes(foriegnKey[field][key])){
        displayValue = displayValue + key + (index == Object.keys(foriegnKey[field]).length -1 ?  "" : ", ");
      }
    })
  }
  return <td>
    {displayValue}
  </td>;
  
};

export const daysCell = (props) => {

  const field = props?.field || "";
  const value = props?.dataItem[field];
  let displayValue = '';

  if(value == undefined || value?.length == 0){
    displayValue = '';
  }else{
    DAYS.filter((obj) => {
      if(value?.includes(obj?.ID)){
        displayValue = displayValue + obj?.Name + ", ";
      }
    })
  }

  return <td>
    {displayValue}
  </td>;
  
};