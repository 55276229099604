/* eslint-disable */
import React from 'react'
import { useState } from 'react';
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Input } from "@progress/kendo-react-inputs";
import { EditPageHeader } from "../../../components/EditPageHeader";
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import moment from 'moment';
import { LocalDateOnlyCell } from '../../../framework/forms/helpercomponents/CustomGridCells/DateCell';
import { toast } from 'react-toastify';
import { useTranslation } from '../../../locale/useTranslation';
import EditRoundButton from '../../../framework/forms/helpercomponents/buttons/EditRoundButton';
import DeleteRoundButton from '../../../framework/forms/helpercomponents/buttons/DeleteRoundButton';
import AddRoundButton from '../../../framework/forms/helpercomponents/buttons/AddRoundButton';
import { ConfirmDeleteAlert } from '../../../ConfirmAlert';
import { useRef } from 'react';
import { utility } from '../../../framework/utility/utilityProvider';
import BossDialog from '../../../components/BossDialog';

export const Billing = (props) => {

  const blankDataItem = {
    Description: props.data.Description ?? "",
    FromDate: props.data.FromDate ?? moment(new Date()).format('YYYY-MM-DD'),
    ToDate: props.data.ToDate ?? moment(new Date()).format('YYYY-MM-DD'),
    Amount: props.data.Amount ?? '',
    GeneratedBill_Id: props.data.GeneratedBill_Id ?? ''
  }
  const [dataItem, setDataItem] = useState(blankDataItem);
  const [openBilling, setOpenBilling] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [i, setI] = useState(0);
  const lang = useTranslation();
  const selectedIndexRef = useRef(0);
  const setSelectedIndexRef = (data) => {
    selectedIndexRef.current = data;
  }

  const onChangeForm = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  }

  const isValid = () => {

    if (dataItem.Description == "" || dataItem.Description == undefined) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if(!dataItem.Description.trim()) {
      toast.error(`${lang.please_enter_description_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Amount == "" || dataItem.Amount == undefined) {
      toast.error(`${lang.please_enter_amount_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Amount < 0) {
      toast.error(`${lang.amount_can_not_be_negative_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!utility.isValidDate(dataItem.FromDate)) {
      toast.error(`${lang.please_enter_from_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if(props.campaignData.PeriodFrom > dataItem.FromDate){
      toast.error(`${lang.from_date_can_not_be_less_than_campaign_start_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (!utility.isValidDate(dataItem.ToDate)) {
      toast.error(`${lang.please_enter_to_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    
    if(props.campaignData.PeriodTo < dataItem.ToDate){
      toast.error(`${lang.to_date_can_not_be_greater_than_campaign_end_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });    
      return false;
    }
    if(dataItem.FromDate > dataItem.ToDate){
      toast.error(`${lang.from_date_should_be_less_than_to_date_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });    
      return false;
    }
    return true;
  }

  const onAddBilling = async () => {

    if (isValid()) {

      const saveData = {
        ...dataItem,
        FromDate: new Date(dataItem.FromDate).getTime(),
        ToDate: new Date(dataItem.ToDate).getTime(),
        Description: dataItem.Description,
        Amount: dataItem.Amount,
      }
      if (!saveData.success) {
        setOpenBilling(false)
        props.setDataList([...props.data, saveData])
        setOpenBilling(false)
        setEditMode(false)
        setI(i + 1);
        setDataItem(blankDataItem);
      }
      else {
        toast.error(saveData.message, {
          position: toast.POSITION.TOP_RIGHT
        });
      }
    }


  }

  const handleSubmit = async (index) => {
    if (editMode) {
      editBilling()
    }
    else {
      onAddBilling(index);
    }
  }

  const MyCommandCell = (props) => (
    <div className="mt-1" style={{ display: "flex" }}>
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => deleteData(props.dataIndex), () => { })} />
      <EditRoundButton onClick={() => editData(props)} />
    </div>
  )

  const deleteData = (index) => {
    if (props.data.length == 1) {
      props.setDataList([]);
      setI(i - 1);
    }
    else {
      props.setDataList([
        ...props.data.slice(0, index),
        ...props.data.slice(index + 1, props.data.length)
      ]);
      setI(i - 1);
    }
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const editData = (data) => {

    let dataItem = data.dataItem;
    setSelectedIndexRef(data.dataIndex);
    setDataItem({
      ...dataItem,
      FromDate: moment(new Date(dataItem.FromDate)).format("YYYY-MM-DD"),
      ToDate: moment(new Date(dataItem.ToDate)).format("YYYY-MM-DD")
    });
    setOpenBilling(true);
    setEditMode(true);
  }

  const editBilling = () => {
    let local = [...props.data]
    local[selectedIndexRef.current] = dataItem;
    if(!isValid()) return;
    props.setDataList(local);
    setEditMode(false);
    setOpenBilling(false);
    setDataItem(blankDataItem);

  }

  const cancelEdit = () => {
    setOpenBilling(false);
    setDataItem(blankDataItem)
  }

  return (
    <>
      <AddRoundButton title={lang.add_billing_button_tooltip} style={{ marginLeft: "-15px" }} onClick={() => setOpenBilling(true)} />
      <Grid data={props.data} style={{ height: "40vh", margin: "5px -15px 40px -15px" }}>
        <GridColumn cell={MyCommandCell} width={"70px"} locked={true} />
        <GridColumn field="Description" title={lang.description_column} editable={false} />
        <GridColumn field="Amount" title={lang.amount_column} editable={false} />
        <GridColumn field="FromDate" title={lang.from_date_column} editable={false} cell={LocalDateOnlyCell} />
        <GridColumn field="ToDate" title={lang.to_date_column} editable={false} cell={LocalDateOnlyCell} />
      </Grid>
      {openBilling && <BossDialog
        title={lang.billing_dialog_header}
        onClose={cancelEdit}
        width={700}
        height={290}
      >
        <EditPageHeader title={""} onSubmit={() => { handleSubmit() }} onCancel={cancelEdit} showTitle={false} />
        <Form
          render={(formRenderProps) => (
            <FormElement>
              <div className="row mt-2">
                <div className='col-12'>
                  <div className='row'>
                    <div className="col-6">
                      <label>{lang.description_label} *</label>
                      <input
                        name={"Description"}
                        type="text"
                        style={{ border: 'solid lightgrey 1px', height: "35px" }}
                        component={Input}
                        value={dataItem.Description}
                        onChange={onChangeForm}
                      />
                    </div>
                    <div className="col-6">
                      <label>{lang.amount_label} *</label>
                      <input type="number" className="form-control pl-3"
                        name="Amount"
                        value={dataItem.Amount}
                        onChange={onChangeForm}
                      />
                    </div>
                  </div>
                  <div className='row mt-2'>
                    <div className='col-6'>
                      <div className="form-group">
                        <label>{lang.from_date_label} *</label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          name="FromDate"
                          value={dataItem.FromDate}
                          onChange={onChangeForm}
                          min={props.campaignData.PeriodFrom}
                          max={props.campaignData.PeriodTo}
                        />
                      </div>
                    </div>
                    <div className='col-6'>
                      <div className="form-group">
                        <label>{lang.to_date_label} *</label>
                        <input
                          type="date"
                          className="form-control form-control-sm"
                          name="ToDate"
                          value={dataItem.ToDate}
                          onChange={onChangeForm}
                          max={props.campaignData.PeriodTo}
                          min={props.campaignData.PeriodFrom}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </FormElement>
          )}
        />
      </BossDialog>}
    </>
  )
}
