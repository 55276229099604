/* eslint-disable */
import React, { useEffect, useState } from "react";
import { Form, FormElement } from "@progress/kendo-react-form";
import * as API from "../../framework/API/api"
import { ENTITYNAME } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { Checkbox } from "@progress/kendo-react-inputs";
import BossDialog from "../../components/BossDialog";

const ContentCategoryEditForm = (props) => {

    const [name, setName] = useState('');
    const [parents, setParents] = useState([]);
    const [parent, setParent] = useState({});
    const [archive, setArchive] = useState(false);
    const lang = useTranslation();


    useEffect(() => {
        loadData();
    }, [])


    const loadData = async () => {

        var parentRes = await API.getDataLookup(ENTITYNAME.ContentCategory);
        if (parentRes.success) {
            const nullItem = { _id: null, SID: Math.random(), Archive: false, Name: "null", parent: null };
            setParents([nullItem, ...parentRes.data]);
            if (props.item.copy || props.item.SID > 0) {
                setParent(props.item.parent == null ? nullItem : parentRes.data.find(x => x._id == props.item.parent));
                setName(props.item.copy ? 'copy of ' + props.item.Name : props.item.Name ?? '');
                setArchive(props.item.Archive);
            }
        } else {
            toast.error(parentRes.message);
        }

    }


    const handleSubmit = async (e) => {


        const saveData = {
            SID: props.item.SID ?? 0,
            Archive: archive,
            Name: name,
            parent: parent._id
        }

        props.onSubmit(e, saveData);
        props.refresh();
        props.cancelEdit();
    }

    const onChangeForm = (e) => {
        if (e.target.name == "Name") {
            setName(e.target.value);
        }
        else if (e.target.name == "Archive") {
            setArchive(!archive);
        }
        else if (e.target.name == "parent") {
            setParent(e.target.value);
        }
    }

    return (
        <>
            <BossDialog
                title={props.item.SID > 0 ? props.item.Name : "Content Category"}
                onClose={props.cancelEdit}
                width={"300px"}
                height={"max-content"}
            >
                <Form
                    render={() => (
                        <FormElement>
                            <div className="row">
                                <div className="col-12">

                                    <div className="row mt-2">
                                        <div className="col">
                                            <label>{lang.name_label}</label>
                                            <input
                                                name={"Name"}
                                                type="text"
                                                className="form-control form-control-sm"
                                                onChange={onChangeForm}
                                                value={name}
                                            />
                                        </div>
                                    </div>

                                    <div className="row mt-2">
                                        <div className="col">
                                            <label>{lang.parent_label}</label>
                                            <DropDownList
                                                data={parents}
                                                name={"parent"}
                                                textField="Name"
                                                dataItemKey="_id"
                                                value={parent}
                                                onChange={onChangeForm}
                                            />
                                            <p className="list-unstyled blockquote-footer">{lang.note_for_parent_select_null_label}</p>

                                        </div>

                                    </div>
                                    <div className="row">
                                        <div className="col mt-3">
                                            <Checkbox
                                                name={"Archive"}
                                                style={{ marginLeft: "5px", marginBottom: "20px" }}
                                                value={archive}
                                                onChange={onChangeForm}
                                            />
                                            <label className="pl-1">{lang.archive}</label>
                                        </div>
                                    </div>
                                    
                                </div>
                            </div>
                        </FormElement>
                    )}
                />
                <EditPageHeader title={""} onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
            </BossDialog>
        </>)
};

export default ContentCategoryEditForm;