export const OttAssetColumns = [

    {

        "name": "SID",
        "type": "hidden",
        "label": "S I D",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 99,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {

        "name": "PotraitImageUrl",
        "type": "image",
        "label": "Portrait Image",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 1,
        "maxLength": 200,
        "format": null,
        "width": 130,
        "displaymember": null
    },
    {

        "name": "LandscapeImageUrl",
        "type": "image",
        "label": "Landscape Image ",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 2,
        "maxLength": 200,
        "format": null,
        "width": 210,
        "displaymember": null
    },
    {

        "name": "Title",
        "type": "input",
        "label": "Title",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 3,
        "maxLength": 200,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {

        "name": "Description",
        "type": "input",
        "label": "Description",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 9,
        "maxLength": 200,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {

        "name": "OttAssetTypeSID",
        "type": "select",
        "label": "Asset Type",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 10,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {

        "name": "VideoPlaybackUrl",
        "type": "input",
        "label": "Video Playback Url",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 8,
        "maxLength": 200,
        "format": null,
        "width": null,
        "displaymember": null
    },
]

export const OttPosterCollectionSelectionColumns = [
    {
        "name": "Archive",
        "type": "checkbox",
        "label": "Archive",
        "required": 0,
        "endpoint": null,
        "entityname": "ott_posters",
        "sequence": 1,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "ImageUrl",
        "type": "image",
        "label": "Image Url",
        "required": 0,
        "endpoint": null,
        "entityname": "ott_posters",
        "sequence": 2,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Description",
        "type": "input",
        "label": "Description",
        "required": 0,
        "endpoint": null,
        "entityname": "ott_posters",
        "sequence": 3,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Title",
        "type": "input",
        "label": "Title",
        "required": 0,
        "endpoint": null,
        "entityname": "ott_posters",
        "sequence": 4,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "FilePath",
        "type": "input",
        "label": "File Path",
        "required": 0,
        "endpoint": null,
        "entityname": "ott_posters",
        "sequence": 4,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    }
]

export const OttAssetColumnsForDraggableCollection = [

    {

        "name": "SID",
        "type": "hidden",
        "label": "S I D",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 99,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {

        "name": "Title",
        "type": "input",
        "label": "Title",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 3,
        "maxLength": 200,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {

        "name": "Description",
        "type": "input",
        "label": "Description",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 9,
        "maxLength": 200,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {

        "name": "PotraitImageUrl",
        "type": "image",
        "label": "Portrait Image",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 1,
        "maxLength": 130,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {

        "name": "LandscapeImageUrl",
        "type": "image",
        "label": "Landscape Image ",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 2,
        "maxLength": 210,
        "format": null,
        "width": null,
        "displaymember": null
    },

    {

        "name": "OttAssetTypeSID",
        "type": "select",
        "label": "Asset Type",
        "required": 0,
        "endpoint": null,
        "entityname": "OttAsset",
        "sequence": 10,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
]

export const RailsColumns = [
    {
        "name": "SID",
        "type": "hidden",
        "label": "S I D",
        "required": 0,
        "endpoint": null,
        "entityname": "Rails",
        "sequence": 99,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Publish",
        "type": "toggle",
        "label": "Publish",
        "required": 1,
        "endpoint": null,
        "entityname": "Rails",
        "sequence": 99,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Title",
        "type": "input",
        "label": "Title",
        "required": 0,
        "endpoint": null,
        "entityname": "Rails",
        "sequence": 1,
        "maxLength": 200,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "DisplayTitle",
        "type": "input",
        "label": "Display Title",
        "required": 0,
        "endpoint": null,
        "entityname": "Rails",
        "sequence": 5,
        "maxLength": 200,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "OttRailTypeSID",
        "type": "select",
        "label": "Type",
        "required": 0,
        "endpoint": null,
        "entityname": "Rails",
        "sequence": 6,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    }
];

export const OttPosterColumns = [
    {
        "name": "SID",
        "type": "hidden",
        "label": "S I D",
        "required": 0,
        "endpoint": null,
        "entityname": "OttPosters",
        "sequence": 99,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Title",
        "type": "input",
        "label": "Title",
        "required": 1,
        "endpoint": null,
        "entityname": "OttPosters",
        "sequence": 1,
        "maxLength": 200,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Description",
        "type": "input",
        "label": "Description",
        "required": 0,
        "endpoint": null,
        "entityname": "OttPosters",
        "sequence": 2,
        "maxLength": 10000,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "FilePath",
        //changed
        "type": "hidden",
        "label": "File Path",
        "required": 1,
        "endpoint": null,
        "entityname": "OttPosters",
        "sequence": 3,
        "maxLength": 1000,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "ImageUrl",
        //changed
        "type": "input",
        "label": "Image Url",
        "required": 1,
        "endpoint": null,
        "entityname": "OttPosters",
        "sequence": 4,
        "maxLength": 1000,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "OttPosterTypeSID",
        "type": "select",
        "label": "Poster Type",
        "required": 0,
        "endpoint": null,
        "entityname": "OttPosters",
        "sequence": 5,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Archive",
        //changed
        "type": "checkbox",
        "label": "Archive",
        "required": 0,
        "endpoint": null,
        "entityname": "OttPosters",
        "sequence": 6,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    }
];

export const UserColumns = [
    {

        "name": "SID",
        "type": "hidden",
        "label": "S I D",
        "required": 0,
        "endpoint": null,
        "entityname": "users",
        "sequence": 99,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null

    },
    {
        "name": "name",
        "type": "input",
        "label": "Name",
        "required": 1,
        "endpoint": null,
        "entityname": "users",
        "sequence": 1,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "userName",
        "type": "input",
        "label": "User Name",
        "required": 1,
        "endpoint": null,
        "entityname": "users",
        "sequence": 2,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "DepartmentSID",
        "type": "select",
        "label": "Department",
        "required": 1,
        "endpoint": null,
        "entityname": "users",
        "sequence": 3,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "RoleSID",
        "type": "select",
        "label": "Role",
        "required": 1,
        "endpoint": null,
        "entityname": "users",
        "sequence": 4,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "PhoneNo",
        "type": "input",
        "label": "Phone No",
        "required": 0,
        "endpoint": null,
        "entityname": "users",
        "sequence": 5,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Email",
        "type": "input",
        "label": "Email",
        "required": 0,
        "endpoint": null,
        "entityname": "users",
        "sequence": 6,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Address",
        "type": "input",
        "label": "Address",
        "required": 0,
        "endpoint": null,
        "entityname": "users",
        "sequence": 7,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "usersSID",
        "type": "select",
        "label": "Reporting To",
        "required": 0,
        "endpoint": null,
        "entityname": "users",
        "sequence": 8,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Archive",
        "type": "hidden",
        "label": "Archive",
        "required": 0,
        "endpoint": null,
        "entityname": "users",
        "sequence": 9,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },

];

export const ContentCategoryColumns = [
    {

        "name": "SID",
        "type": "hidden",
        "label": "S I D",
        "required": 0,
        "endpoint": null,
        "entityname": "ContentCategory",
        "sequence": 99,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null

    },
    {
        "name": "Description",
        "type": "input",
        "label": "Description",
        "required": 1,
        "endpoint": null,
        "entityname": "ContentCategory",
        "sequence": 1,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "ContentCategorySID",
        "type": "select",
        "label": "Parent Content",
        "required": 0,
        "endpoint": null,
        "entityname": "ContentCategory",
        "sequence": 2,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Archive",
        "type": "checkbox",
        "label": "Archive",
        "required": 0,
        "endpoint": null,
        "entityname": "ContentCategory",
        "sequence": 3,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    }
];

export const MediaEpisodeColumns = [
    {
        "name": "SID",
        "label": "S I D",
        "sequence": 99,
        "required": false,
        "type": "hidden",
        "entityName": "MediaEpisode",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "Title",
        "label": "Title",
        "sequence": 1,
        "required": true,
        "type": "input",
        "entityName": "MediaEpisode",
        "maxLength": 250,
        "format": null,
        "width": null
    },
    {
        "name": "Duration",
        "label": "Duration",
        "sequence": 2,
        "required": true,
        "type": "input",
        "entityName": "MediaEpisode",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "Content",
        "label": "Content",
        "sequence": 3,
        "required": true,
        "type": "select",
        "entityName": "MediaEpisode",
        "maxLength": null,
        "format": null,
        "width": null,
        "endpoint": "Content"
    },
    {
        "name": "AssetId",
        "label": "Asset Id",
        "sequence": 4,
        "required": true,
        "type": "input",
        "entityName": "MediaEpisode",
        "maxLength": 20,
        "format": null,
        "width": null
    },
    {
        "name": "EpisodeNumber",
        "label": "Episode Number",
        "sequence": 5,
        "required": true,
        "type": "input",
        "entityName": "MediaEpisode",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "MediaCategory",
        "label": "Media Category",
        "sequence": 6,
        "required": true,
        "type": "select",
        "entityName": "MediaEpisode",
        "maxLength": null,
        "format": null,
        "width": null,
        "endpoint": "MediaCategory"
    },
    {
        "name": "MediaCategoryType",
        "label": "Media Category Type",
        "sequence": 7,
        "required": true,
        "type": "select",
        "entityName": "MediaEpisode",
        "maxLength": null,
        "format": null,
        "width": null,
        "endpoint": "MediaCategoryType"
    },
    {
        "name": "Archive",
        "label": "Archive",
        "sequence": 13,
        "required": false,
        "type": "checkbox",
        "entityName": "MediaEpisode",
        "maxLength": 1,
        "format": null,
        "width": null
    }
]

export const MediaEpisodeSynopsisColumns = [
    {
        "name": "SID",
        "type": "hidden",
        "label": "S I D",
        "required": 0,
        "endpoint": null,
        "entityname": "MediaEpisodeSynopsis",
        "sequence": 99,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "LanguageSID",
        "type": "select",
        "label": "Language",
        "required": 1,
        "endpoint": "Language",
        "entityname": "MediaEpisodeSynopsis",
        "sequence": 2,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    },
    {
        "name": "Synopsis",
        "type": "input",
        "label": "Synopsis",
        "required": 1,
        "endpoint": null,
        "entityname": "MediaEpisodeSynopsis",
        "sequence": 3,
        "maxLength": null,
        "format": null,
        "width": null,
        "displaymember": null
    }
]

export const SegmentDetailsColumns = [
    {
        "name": "SegmentTypeSID",
        "label": "Segment Type",
        "sequence": 3,
        "required": true,
        "type": "select",
        "entityName": "mediaepisodesegment",
        "maxLength": null,
        "format": null,
        "width": null,
        "endpoint": "SegmentType"
    },
    {
        "name": "ActualDuration",
        "label": "Actual Duration",
        "sequence": 4,
        "required": true,
        "type": "time",
        "entityName": "mediaepisodesegment",
        "maxLength": null,
        "format": null,
        "width": null
    },
]

export const CampaignColumns = [
    {
        "name": "_id",
        "label": "_id",
        "sequence": 99,
        "required": false,
        "type": "hidden",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "Title",
        "label": "Title",
        "sequence": 2,
        "required": false,
        "type": "input",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "CampaignNo",
        "label": "Campaign No",
        "sequence": 1,
        "required": false,
        "type": "input",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "TargetSpots",
        "label": "Target Spots",
        "sequence": 8,
        "required": false,
        "type": "input",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },

    {
        "name": "PeriodTo",
        "label": "Period To",
        "sequence": 5,
        "required": false,
        "type": "date",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "OrderDate",
        "label": "Order Date",
        "sequence": 3,
        "required": false,
        "type": "date",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },

    {
        "name": "Agency.Name",
        "label": "Agency",
        "sequence": 11,
        "required": false,
        "type": "input",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "Product.Name",
        "label": "Product",
        "sequence": 15,
        "required": false,
        "type": "input",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "TargetSeconds",
        "label": "Target Seconds",
        "sequence": 9,
        "required": false,
        "type": "input",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },

    {
        "name": "DealType.Description",
        "label": "Deal Type",
        "sequence": 16,
        "required": false,
        "type": "input",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "PeriodFrom",
        "label": "Period From",
        "sequence": 4,
        "required": false,
        "type": "date",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "Commission",
        "label": "Commission",
        "sequence": 10,
        "required": false,
        "type": "input",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null,
    },
    {
        "name": "Brands",
        "label": "Brands",
        "sequence": 14,
        "required": false,
        "type": "array",
        "entityName": "campaign",
        "maxLength": null,
        "format": "Name",
        "width": null
    },
    {
        "name": "Currency.Description",
        "label": "Currency",
        "sequence": 13,
        "required": false,
        "type": "input",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "TargetBudget",
        "label": "Target Budget",
        "sequence": 7,
        "required": false,
        "type": "input",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "Client.Name",
        "label": "Client",
        "sequence": 12,
        "required": false,
        "type": "input",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "CampaignType.Description",
        "label": "Campaign Type",
        "sequence": 6,
        "required": false,
        "type": "input",
        "entityName": "campaign",
        "maxLength": null,
        "format": null,
        "width": null
    },
    {
        "name": "Channels",
        "label": "Channels",
        "sequence": 17,
        "required": false,
        "type": "array",
        "entityName": "campaign",
        "maxLength": null,
        "format": "FullChannelName",
        "width": null
    },
    {
        "name": "Commercials",
        "label": "Commercials",
        "sequence": 18,
        "required": false,
        "type": "array",
        "entityName": "campaign",
        "maxLength": null,
        "format": 'Title',
        "width": null
    }
]

export const DigitalSign_ContentColumns = [
    {
        "name": "thumbnailUrl",
        "label": "Thumbnail",
        "sequence": 1,
        "required": false,
        "type": "image",
        "maxLength": null,
        "format": null,
        "width": 100,
        "Archive": false
    },
    {
        "name": "fileName",
        "label": "FileName",
        "sequence": 2,
        "required": false,
        "type": "input",
        "maxLength": null,
        "format": null,
        "width": 300,
        "Archive": false
    },
    {
        "name": "content_type",
        "label": "Type",
        "sequence": 3,
        "required": false,
        "type": "enum",
        "maxLength": null,
        "format": null,
        "width": 150,
        "Archive": false
    },
    {
        "name": "orientation",
        "label": "Orientation",
        "sequence": 4,
        "required": false,
        "type": "enum",
        "maxLength": null,
        "format": null,
        "width": 150,
        "Archive": false
    },
    {
        "name": "Company_id",
        "label": "Company",
        "sequence": 5,
        "required": false,
        "type": "company",
        "format": null,
        "width": 180,
    }
]

export const DigitalSign_OverlaysColumns = [
    {
        "name": "Name",
        "label": "Name",
        "sequence": 1,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "OverlayType.Description",
        "label": "Overlay Type",
        "sequence": 2,
        "required": false,
        "type": "input",
        "format": null,
        "width": 150,
    },
    {
        "name": "ImageUrl",
        "label": "Image Url",
        "sequence": 3,
        "required": false,
        "type": "image",
        "format": null,
        "width": 100,
    },
    {
        "name": "ShapeHeight",
        "label": "Shape Height",
        "sequence": 4,
        "required": false,
        "type": "input",
        "format": null,
        "width": 100,
    },
    {
        "name": "ShapeWidth",
        "label": "Shape Width",
        "sequence": 5,
        "required": false,
        "type": "input",
        "format": null,
        "width": 120,
    },
    {
        "name": "BackgroundColor",
        "label": "Background Color",
        "sequence": 6,
        "required": false,
        "type": "color",
        "format": null,
        "width": 100,
    },
    {
        "name": "BorderRadius",
        "label": "Border Radius",
        "sequence": 7,
        "required": false,
        "type": "input",
        "format": null,
        "width": 100,
    },
    {
        "name": "Text",
        "label": "Text",
        "sequence": 8,
        "required": false,
        "type": "input",
        "format": null,
        "width": 200,
    },
    {
        "name": "TextPosition.Description",
        "label": "Text Position",
        "sequence": 9,
        "required": false,
        "type": "input",
        "format": null,
        "width": 150,
    },
    {
        "name": "Position.Description",
        "label": "Position",
        "sequence": 10,
        "required": false,
        "type": "input",
        "format": "",
        "width": 150,
    },
    {
        "name": "FontSize",
        "label": "Font Size",
        "sequence": 11,
        "required": false,
        "type": "input",
        "format": null,
        "width": 100,
    },
    {
        "name": "FontWeight",
        "label": "Font Weight",
        "sequence": 12,
        "required": false,
        "type": "input",
        "format": null,
        "width": 100,
    },
    {
        "name": "FontFamily",
        "label": "Font Family",
        "sequence": 13,
        "required": false,
        "type": "input",
        "format": null,
        "width": 150,
    },
    {
        "name": "FontColor",
        "label": "Font Color",
        "sequence": 14,
        "required": false,
        "type": "color",
        "format": null,
        "width": null,
    },
    {
        "name": "Opacity",
        "label": "Opacity",
        "sequence": 15,
        "required": false,
        "type": "input",
        "format": null,
        "width": 100,
    },
    {
        "name": "TestOpacity",
        "label": "TestOpacity",
        "sequence": 16,
        "required": false,
        "type": "input",
        "format": null,
        "width": 100,
    },
    {
        "name": "Layer",
        "label": "Layer",
        "sequence": 17,
        "required": false,
        "type": "input",
        "format": null,
        "width": 100,
    },
    {
        "name": "IsTextMarquee",
        "label": "Text Margin",
        "sequence": 18,
        "required": false,
        "type": "checkbox",
        "format": null,
        "width": 100,
    },
    {
        "name": "Archive",
        "label": "Archive",
        "sequence": 19,
        "required": false,
        "type": "checkbox",
        "format": null,
        "width": 100,
    },
    {
        "name": "addOn",
        "label": "add On",
        "sequence": 20,
        "required": false,
        "type": "date",
        "format": null,
        "width": 180,
    },
    {
        "name": "addBy.name",
        "label": "add By",
        "sequence": 21,
        "required": false,
        "type": "input",
        "format": "",
        "width": 150,
    },
    {
        "name": "modBy.name",
        "label": "mod By",
        "sequence": 22,
        "required": false,
        "type": "input",
        "format": null,
        "width": 150
    },
    {
        "name": "modOn",
        "label": "mod On",
        "sequence": 23,
        "required": false,
        "type": "date",
        "format": null,
        "width": 180,
    },
    {
        "name": "Company_id",
        "label": "Company",
        "sequence": 24,
        "required": false,
        "type": "company",
        "format": null,
        "width": 180,
    }
]

export const DigitalSign_PlansColumns = [
    {
        "name": "plan.Name",
        "label": "Name",
        "sequence": 1,
        "required": false,
        "type": "input",
        "format": null,
        "width": 100,
    },
    {
        "name": "plan.Price",
        "label": "Price",
        "sequence": 2,
        "required": false,
        "type": "input",
        "format": null,
        "width": 150,
    },
    {
        "name": "plan.Tenure",
        "label": "Tenure",
        "sequence": 3,
        "required": false,
        "type": "number",
        "format": null,
        "width": 100,
    },
    {
        "name": "balanceScreens",
        "label": "Balance Screens",
        "sequence": 3,
        "required": false,
        "type": "number",
        "format": null,
        "width": 100,
    },
    {
        "name": "plan.storage",
        "label": "Storage",
        "sequence": 4,
        "required": false,
        "type": "input",
        "format": null,
        "width": 100,
    },
    {
        "name": "Company_id",
        "label": "Company",
        "sequence": 5,
        "required": false,
        "type": "company",
        "format": null,
        "width": 180,
    }
];

export const MediaDealRights_columns = [
    {
        "_id" : "63de24b8a0754d8212b6def3",
        "name" : "Channel",
        "label" : "Channel",
        "sequence" : 1,
        "required" : false,
        "type" : "array",
        "entityName" : "mediadealrights",
        "maxLength" : null,
        "format" : "FullChannelName",
        "width" : 200,
        "module" : "Media Deal Rights",
        "Archive" : false
    },
    {
        "_id" : "63de24b8a0754d8212b6dee8",
        "name" : "PublishStartDate",
        "label" : "Publish Start Date",
        "sequence" : 2,
        "required" : false,
        "type" : "date",
        "entityName" : "mediadealrights",
        "maxLength" : null,
        "format" : null,
        "width" : 150,
        "module" : "Media Deal Rights",
        "Archive" : false
    },
    {
        "_id" : "63de24b8a0754d8212b6dee9",
        "name" : "PublishEndDate",
        "label" : "Publish End Date",
        "sequence" : 3,
        "required" : false,
        "type" : "date",
        "entityName" : "mediadealrights",
        "maxLength" : null,
        "format" : null,
        "width" : 150,
        "module" : "Media Deal Rights",
        "Archive" : false
    },
    {
        "_id" : "63de24b8a0754d8212b6def5",
        "name" : "IsUnlimited",
        "label" : "Is Unlimited",
        "sequence" : 14,
        "required" : false,
        "type" : "checkbox",
        "entityName" : "mediadealrights",
        "maxLength" : null,
        "format" : null,
        "width" : 100.0,
        "module" : "Media Deal Rights",
        "Archive" : false
    },
    {
        "_id" : "63de24b8a0754d8212b6deea",
        "name" : "Exhibitions",
        "label" : "Exhibitions",
        "sequence" : 3,
        "required" : false,
        "type" : "input",
        "entityName" : "mediadealrights",
        "maxLength" : null,
        "format" : null,
        "width" : 100.0,
        "module" : "Media Deal Rights",
        "Archive" : false
    },
    {
        "_id" : "63de24b8a0754d8212b6deeb",
        "name" : "Repeats",
        "label" : "Repeats",
        "sequence" : 4,
        "required" : false,
        "type" : "input",
        "entityName" : "mediadealrights",
        "maxLength" : null,
        "format" : null,
        "width" : 100.0,
        "module" : "Media Deal Rights",
        "Archive" : false
    },
    {
        "_id" : "63de24b8a0754d8212b6deed",
        "name" : "PlayTime.Description",
        "label" : "Play Time",
        "sequence" : 6,
        "required" : false,
        "type" : "input",
        "entityName" : "mediadealrights",
        "maxLength" : null,
        "format" : null,
        "width" : 150,
        "module" : "Media Deal Rights",
        "Archive" : false
    },
    {
        "_id" : "63de24b8a0754d8212b6deec",
        "name" : "PlayTimeNumber",
        "label" : "Play Time Number",
        "sequence" : 5,
        "required" : false,
        "type" : "input",
        "entityName" : "mediadealrights",
        "maxLength" : null,
        "format" : null,
        "width" : 100.0,
        "module" : "Media Deal Rights",
        "Archive" : false
    },
    {
        "_id" : "63de24b8a0754d8212b6deef",
        "name" : "PlatformType.Description",
        "label" : "Platform Type",
        "sequence" : 8,
        "required" : false,
        "type" : "input",
        "entityName" : "mediadealrights",
        "maxLength" : null,
        "format" : null,
        "width" : 100.0,
        "module" : "Media Deal Rights",
        "Archive" : false
    },
    {
        "_id" : "63de24b8a0754d8212b6def6",
        "name" : "PublishingSource",
        "label" : "Publishing Source",
        "sequence" : 15,
        "required" : false,
        "type" : "input",
        "entityName" : "mediadealrights",
        "maxLength" : null,
        "format" : null,
        "width" : 100.0,
        "module" : "Media Deal Rights",
        "Archive" : false
    }

];

export const MediaEpisodeSegment_columns = [
    {
        "_id" : "63de24b8a0754d8212b6def3x",
        "name" : "SegmentType.Description",
        "label" : "Segment Type",
        "sequence" : 1,
        "required" : false,
        "type" : "input",
        "entityName" : "mediasegment",
        "maxLength" : null,
        "format" : null,
        "width" : 200,
        "module" : "Media Deal Rights",
        "Archive" : false
    },
    {
        "_id" : "63de24b8a0754d8212b6dee8x",
        "name" : "SegmentType.TXMode.Name",
        "label" : "Tx Mode",
        "sequence" : 2,
        "required" : false,
        "type" : "input",
        "entityName" : "mediasegment",
        "maxLength" : null,
        "format" : null,
        "width" : 150,
        "module" : "Media Deal Rights",
        "Archive" : false
    },
    {
        "_id" : "63de24b8a0754d8212b6dee9x",
        "name" : "Channels",
        "label" : "Channels",
        "sequence" : 3,
        "required" : false,
        "type" : "array",
        "entityName" : "mediasegment",
        "maxLength" : null,
        "format" : "FullChannelName",
        "width" : 150,
        "module" : "Media Deal Rights",
        "Archive" : false
    },
    {
        "_id" : "63de24b8a0754d8212b6def5x",
        "name" : "Language.Description",
        "label" : "Language",
        "sequence" : 14,
        "required" : false,
        "type" : "input",
        "entityName" : "mediasegment",
        "maxLength" : null,
        "format" : null,
        "width" : 100.0,
        "module" : "Media Deal Rights",
        "Archive" : false
    },
    {
        "_id" : "63de24b8a0754d8212b6deeax",
        "name" : "PgRating.Description",
        "label" : "PG Rating",
        "sequence" : 3,
        "required" : false,
        "type" : "input",
        "entityName" : "mediasegment",
        "maxLength" : null,
        "format" : null,
        "width" : 100.0,
        "module" : "Media Deal Rights",
        "Archive" : false
    },
    {
        "_id" : "63de24b8a0754d8212b6deebx",
        "name" : "Version.Name",
        "label" : "Version",
        "sequence" : 4,
        "required" : false,
        "type" : "input",
        "entityName" : "mediasegment",
        "maxLength" : null,
        "format" : null,
        "width" : 100.0,
        "module" : "Media Deal Rights",
        "Archive" : false
    },
    {
        "_id" : "63de24b8a0754d8212b6deedx",
        "name" : "SegmentNumber",
        "label" : "Media ID",
        "sequence" : 6,
        "required" : false,
        "type" : "input",
        "entityName" : "mediasegment",
        "maxLength" : null,
        "format" : null,
        "width" : 150,
        "module" : "Media Deal Rights",
        "Archive" : false
    },
    {
        "_id" : "63de24b8a0754d8212b6deecx",
        "name" : "ActualDuration",
        "label" : "ActalDuration",
        "sequence" : 5,
        "required" : false,
        "type" : "time",
        "entityName" : "mediasegment",
        "maxLength" : null,
        "format" : null,
        "width" : 100.0,
        "module" : "Media Deal Rights",
        "Archive" : false
    },

];

export const DigitalSign_CompanyPlansColumns = [
    {
        "name": "plan.Name",
        "label": "Name",
        "sequence": 1,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "plan.Price",
        "label": "Price",
        "sequence": 2,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "plan.Tenure",
        "label": "Tenure",
        "sequence": 3,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "plan.screens",
        "label": "Screens",
        "sequence": 4,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "plan.storage",
        "label": "Storage",
        "sequence": 5,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "balanceScreens",
        "label": "Balance Screens",
        "sequence": 6,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "Company_id",
        "label": "Company",
        "sequence": 7,
        "required": false,
        "type": "company",
        "format": null,
        "width": 180,
    }
]

export const DigitalSign_PlansMasterColumns = [
    {
        "name": "Name",
        "label": "Name",
        "sequence": 1,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "Price",
        "label": "Price",
        "sequence": 2,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "Tenure",
        "label": "Tenure",
        "sequence": 3,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "screens",
        "label": "Screens",
        "sequence": 4,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "storage",
        "label": "Storage",
        "sequence": 5,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "isDefault",
        "label": "Default",
        "sequence": 6,
        "required": false,
        "type": "checkbox",
        "format": null,
        "width": 180,
    },
    {
        "name": "Company_id",
        "label": "Company",
        "sequence": 7,
        "required": false,
        "type": "company",
        "format": null,
        "width": 180,
    }
]

export const DigitalSign_ContentGroupColumns = [
    {
        "name": "Name",
        "label": "Name",
        "sequence": 1,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "Archive",
        "label": "Archive",
        "sequence": 2,
        "required": false,
        "type": "checkbox",
        "format": null,
        "width": 150,
    },
    {
        "name": "Company_id",
        "label": "Company",
        "sequence": 3,
        "required": false,
        "type": "company",
        "format": null,
        "width": 180,
    }
]

export const DigitalSign_CompanyColumns = [
    {
        "name": "Name",
        "label": "Name",
        "sequence": 1,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "Plan.Name",
        "label": "Plan Name",
        "sequence": 1,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "Plan.Tenure",
        "label": "Plan Tenure",
        "sequence": 1,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "Plan.screens",
        "label": "Plan Screens",
        "sequence": 1,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "Plan.storage",
        "label": "Plan Storage",
        "sequence": 1,
        "required": false,
        "type": "input",
        "format": null,
        "width": 180,
    },
    {
        "name": "usersCount",
        "label": "Users",
        "sequence": 1,
        "required": false,
        "type": "input",
        "format": null,
        "width": 100,
    },
    {
        "name": "Archive",
        "label": "Archive",
        "sequence": 2,
        "required": false,
        "type": "checkbox",
        "format": null,
        "width": 150,
    },
    
]

export const DigitalSign_ModuleColumns = [
    {
        "name": "Name",
        "label": "Name",
        "sequence": 1,
        "required": false,
        "type": "input",
        "format": null,
        "width": "auto",
    },
]

export const Avod_ConfigurationColumns = [
    {
        "name": "Title",
        "label": "Title",
        "sequence": 2,
        "required": false,
        "type": "input",
        "entityName": "avod_configuration",
        "maxLength": null,
        "format": null,
        "width": "auto",
    },
    {
        "name": "Segment.Description",
        "label": "Segment",
        "sequence": 3,
        "required": false,
        "type": "input",
        "entityName": "avod_configuration",
        "maxLength": null,
        "format": null,
        "width": "auto",
    },
    {
        "name": "Platforms",
        "label": "Platforms",
        "sequence": 4,
        "required": false,
        "type": "array",
        "entityName": "avod_configuration",
        "maxLength": null,
        "format": "Description",
        "width": "auto",
    },
    {
        "name": "Tags",
        "label": "Ad Tags",
        "sequence": 5,
        "required": false,
        "type": "array",
        "entityName": "avod_configuration",
        "maxLength": null,
        "format": "AddTag",
        "width": "auto",
    },
];

export const Ingestion_log_Column = [
    {
        "name": "Data.Title",
        "label": "Title",
        "sequence": 2,
        "required": false,
        "type": "input",
        "maxLength": null,
        "format": null,
        "width": "auto",
    },
    {
        "name": "EntryDateTime",
        "label": "Date Time",
        "sequence": 3,
        "required": false,
        "type": "input",
        "maxLength": null,
        "format": null,
        "width": "auto",
    },
    {
        "name": "Status",
        "label": "Status",
        "sequence": 4,
        "required": false,
        "type": "input",
        "maxLength": null,
        "format": null,
        "width": "auto",
    },
    {
        "name": "Message",
        "label": "Message",
        "sequence": 5,
        "required": false,
        "type": "input",
        "maxLength": null,
        "format": null,
        "width": "auto",
    },
];