/* eslint-disable */
import React, { useEffect, useState } from "react";
import * as API from '../../framework/API/api'
import { ENTITYNAME } from "../../framework/constant/constant";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import SaveRoundButton from "../../framework/forms/helpercomponents/buttons/SaveRoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import CancelRoundButton from "../../framework/forms/helpercomponents/buttons/CancelRoundButton";
import EditRoundButton from "../../framework/forms/helpercomponents/buttons/EditRoundButton";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";

function SecondaryEventPrototypeRules(props) {

    const [dataItem, setDataItem] = useState({
        // Synopsis:"",
    });
    const[i, setI] = useState(0);
    const[editMode, setEditMode] = useState(false);
    const[toggle, setToggle] = useState(false);
    const [rules, setRules] = useState([]);
    const lang=useTranslation();

    useEffect(() => {
        loadCombo();
    },[])

    const loadCombo = async () => {
      var res = await API.getDataLookup(ENTITYNAME.SecondaryEventDynamicRule, {sort: { Name: 1 }});
      setRules(() => res.data);

    //   if(rules?.length > 0)
    //   {
    //     setDataItem((oldval) => ({...oldval, SecondaryEventDynamicRule: rules[0]}));
    //   }
    }

    const onChange = (e) => {
        setDataItem({...dataItem, [e.target.name]: e.target.value});
    };

    const isValidforAdd = () => 
    {           
        if(dataItem.TextNumber == undefined || dataItem.TextNumber == "" )
        {
            toast.error(`${lang.please_enter_text_no_error_message}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if(dataItem.TextNumber < 0)
        {
            toast.error(`${lang.text_number_can_not_be_negative_error_message}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if(dataItem.SecondaryEventDynamicRule == undefined || Object.keys(dataItem.SecondaryEventDynamicRule).length == 0)
        {
            toast.error(`${lang.please_select_the_value_in_rule_first_error_message}` , {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }

       var duplicate =  props.data.filter((obj) => 
            obj.TextNumber.toLowerCase() == dataItem.TextNumber.toLowerCase() 
         );

        if(!editMode && duplicate.length > 0)
        {
            toast.error( `${dataItem.TextNumber} ${lang.already_exists_global_error_message}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
       
    }

    const addRules = (index) => {
        if(isValidforAdd())
        {
            const rulesObject = {
                ...dataItem,
                index: index??i
              }
              setI(i+1);
              //add validation
              props.setRulesData([...props.data,rulesObject]);
              setDataItem({TextNumber:''});
              clearData();
        }
     
     
    }
    const MyCommandCell = (props)=>(
      <div style={{display:"flex", margin:'3px'}}>
      <EditRoundButton onClick={()=>editFormGrid(props.dataItem)}/>
      <DeleteRoundButton onClick={()=>ConfirmDeleteAlert(()=>removeSynopsis(props.dataItem.index),()=>{})}/>
      </div>
    )

    const editFormGrid = (data)=>{
      setDataItem(data);
      setToggle(true);
      setEditMode(true);
    }

    const removeSynopsis = (index) => {
      props.setRulesData([
        ...props.data.slice(0, index),
        ...props.data.slice(index + 1, props.data.length)
      ]);
      setI(i-1);
      clearData();
      toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    // console.log(props.data);

    const editRules = ()=>{
      props.data[dataItem.index] = dataItem
      if(!isValidforAdd())return;
      props.setRulesData(props.data);
      setEditMode(false);
      clearData();
    }

    const clearData = ()=>{
      setDataItem({TextNumber:'',SecondaryEventDynamicRule:'', Prefix:''});
      setEditMode(false);
      setToggle(false);
    }
  
  return (<>
  <div className="row">
      {!toggle&&<div className="col-12">
      <AddRoundButton onClick={() => setToggle(true)} style={{marginBottom: "5px"}}/>
        <Grid data={props.data} style={{ height: "30vh", marginBottom: "20px" }}>
          <GridColumn cell={MyCommandCell} width={"75px"} locked={true} />
          <GridColumn field="TextNumber" title={lang.text_number_column} editable={false} />
          <GridColumn field="Prefix" title={lang.prefix_column} editable={false} />
          <GridColumn field="SecondaryEventDynamicRule.Name" title={lang.rule_column} editable={false} />
        </Grid>
      </div>}
      {toggle&&<div className="col-6" style={{paddingTop:"30px", marginBottom: "20px"}}>
        <Grid data={props.data} style={{ height: "30vh" }}>
          <GridColumn cell={MyCommandCell} width={"75px"} locked={true} />
          <GridColumn field="TextNumber" title={lang.text_number_column} editable={false} />
          <GridColumn field="Prefix" title={lang.prefix_column} editable={false} />
          <GridColumn field="SecondaryEventDynamicRule.Name" title={lang.rule_column} editable={false} />
        </Grid>
      </div>}
      {toggle&&<div className="col-6">
        {!editMode&&<AddRoundButton onClick={() => addRules()} />}
        {editMode&&<SaveRoundButton onClick={() => {editRules()}}/>}
        <CancelRoundButton onClick={clearData}/>
          <div className="row ml-2" >
            <div className="col-12 mt-2" style={{ border: "groove 1px"}}>
              <div className="row">
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="">{lang.text_number_label} *</label>
                    <input type="number" className="form-control form-control-sm" 
                    name="TextNumber" 
                    value={dataItem.TextNumber}
                    onChange={(e) => onChange(e)} required />
                  </div>
                </div>
                <div className="col-6">
                  <div className="form-group">
                    <label htmlFor="">{lang.prefix_label}</label>
                    <input type="text" className="form-control form-control-sm" 
                    name="Prefix" 
                    value={dataItem.Prefix}
                    onChange={(e) => onChange(e)} required />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12">
                  <div className="form-group">
                    <label htmlFor="TabView">{lang.rule_label} *</label><br/>
                    <DropDownList
                    style={{
                      backgroundColor:"white",
                    }}
                    data={rules}
                    name="SecondaryEventDynamicRule"
                    textField="Name"
                    dataItemKey="_id"
                    value={dataItem.SecondaryEventDynamicRule}
                    onChange={onChange}
                    validator={(value) => value ? "" : "Please select the value"}
                   />
                  </div>
                </div>
              </div>
            </div>                      
          </div>
        </div>  }
      </div>
  </>)
}

export default SecondaryEventPrototypeRules
