import { useState, useEffect } from "react";
import SaveButton from "../../../../framework/forms/helpercomponents/buttons/SaveButton";
import CancelButton from "../../../../framework/forms/helpercomponents/buttons/CancelButton";
import { ACCESS_LEVEL, ASSETTYPE, ENTITYNAME, OTTROUTETYPE } from "../../../../framework/constant/constant";
import * as API from '../../../../framework/API/api'
import { toast } from "react-toastify";
import MediaEpisodeAssetEditForm from "../../MediaEpisodeAssetEditForm";
import { useTranslation } from "../../../../locale/useTranslation";
import RoundButton from "../../../../framework/forms/helpercomponents/buttons/RoundButton";
import { utility } from "../../../../framework/utility/utilityProvider";
import BossDialog from "../../../../components/BossDialog";
import { DataHelper } from "../../../../framework/helper/DataHelper";

const OttAssesTab = (props) => {

  const { mediaEpisode, gridData, setGridData, setMediaEpisode, entityName } = props;
  const none = "N/A";

  const [showEdit, setShowEdit] = useState(false);
  const boxStyle = { margin: "10px 5px", padding: "10px", borderRadius: "10px", backgroundColor: "white", wordWrap: "break-word" }
  const headingStyle = { fontFamily: "Roboto", fontSize: "16px", fontWeight: "700", letterSpacing: "0.2px", lineHeight: "18px", color: "#191825", padding: "10px 0" }
  const subHeadingStyle = { fontFamily: "Roboto", fontSize: "14px", fontWeight: "700", letterSpacing: "0.2px", lineHeight: "18px", color: "#191825", margin: "7px 0", padding: 0 }
  const subHeadingDetailStyle = { fontFamily: "Roboto", fontSize: "14px", fontWeight: "400", letterSpacing: "0.2px", lineHeight: "18px", color: "#3C4048", padding: 0, marginBottom: "25px" }

  const lang = useTranslation();
  const [ottAsset, setOttAsset] = useState({});
  const [loading, setLoading] = useState(true);
  const [isOttAsset, setIsOttAsset] = useState(false);

  useEffect(() => {
    loadData();
  }, [mediaEpisode])

  const loadData = async () => {
    setLoading(true);
    setIsOttAsset(mediaEpisode?.IsOttAsset ?? false);
    let ottAssetRes = await API.getData(ENTITYNAME.OttAsset, { query: [['Source._id', '=', mediaEpisode._id], ['Source.entityName', '=', entityName]] });
    if (ottAssetRes.success && ottAssetRes.data.length > 0) {
      let ottProviderRes = await API.getDataLookup(ENTITYNAME.OttProvider, { query: ['Archive', '!=', true] });
      let ottAsset = {
        ...ottAssetRes.data[0],
        Provider: ottProviderRes.success ? ottProviderRes.data.find(x=> x?._id == ottAssetRes.data[0].Provider) : {}
      }
      setOttAsset(ottAsset);
    }
    setLoading(false);
  }

  const onClose = () => {
    setShowEdit(!showEdit);
  }

  const isValidAsset = () => {

    if (!ottAsset.IsOttAsset) return true;

    if(!ottAsset.VodTypes || Object.keys(ottAsset.VodTypes).length == 0){
      toast.error(lang.please_select_vod_type_validation_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if ((ottAsset.routeType == OTTROUTETYPE.Page || ottAsset.routeType.Description == 'Page') && (ottAsset.page_id == "" || ottAsset.page_id == undefined)) {
      toast.error(lang.please_select_page, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if ((ottAsset.routeType == OTTROUTETYPE.Player || ottAsset.routeType.ID == OTTROUTETYPE.Player)
      && ottAsset.Videos.length == 0) {
      toast.error(lang.please_attach_atleast_one_video_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if ((ottAsset.routeType == OTTROUTETYPE.Deeplink || ottAsset.routeType.Description == 'Deeplink') && !ottAsset.Videos.some(x => x.OttVideoType.Description == "Deeplink")) {
      toast.error(lang.please_add_deeplink_in_video_list_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (ottAsset.Videos.length == 0) {
      toast.error(lang.please_attach_atleast_one_video_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    if (ottAsset.Posters.length == 0) {
      toast.error(lang.please_attach_atleast_one_poster_error_message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }

    return true;
  }

  const onSave = async () => {

    if (!isValidAsset()) return;

    let saveData = DataHelper.getMediaOttAssetsEntity(ottAsset, mediaEpisode);
    await API.saveData(entityName, { _id: mediaEpisode._id, IsOttAsset: ottAsset?.IsOttAsset ?? false });
    setMediaEpisode({ ...mediaEpisode, IsOttAsset: ottAsset?.IsOttAsset ?? false });
    let updateData = gridData.map((obj) => {
      if (obj.SID === mediaEpisode?.SID) {
          return {
              ...obj,
              IsOttAsset: ottAsset?.IsOttAsset
          }
      }else{
          return obj
      }
    })
    setGridData(updateData);
    setIsOttAsset(ottAsset?.IsOttAsset ?? false);
    delete saveData?.IsOttAsset;
    await API.saveData(ENTITYNAME.OttAsset, saveData);

    onClose();

  }

  //asset
  const setAssetEntity = (data) => {
    setOttAsset({ ...ottAsset, ...data });
  }

  return (
    <>
      {/* Genral */}
      <div style={boxStyle}>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center", margin: 0, padding: 0 }}>
          <h1 style={headingStyle}>Ott Asset</h1>
          <RoundButton icon={'pen'} title={'Edit'} onClick={onClose} />
        </div>
        {loading ? <div style={{ display: "flex", height: "200px", justifyContent: "center", alignItems: "center" }}>
          <i className="fa fa-spinner fa-spin fa-xl " />
        </div> :
          !isOttAsset
            ?
            <div>
              <p style={subHeadingDetailStyle}>This assest is not enabled for VOD. Please edit and enable it for Ott Asset.</p>
            </div>
            :
            <div>
              <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
                <div>
                  <h2 style={subHeadingStyle}>{lang.asset_type_label}</h2>
                  <p style={subHeadingDetailStyle}>{utility.getEnumValue(ASSETTYPE, ottAsset?.OttAssetTypeSID) ?? none}</p>
                  <h2 style={subHeadingStyle}>{lang.vodtype_label}</h2>
                  <p style={subHeadingDetailStyle}>{ottAsset?.VodTypes?.Name ?? none}</p>
                  <h2 style={subHeadingStyle}>{lang.access_level_label}</h2>
                  <p style={subHeadingDetailStyle}>{utility.getEnumValue(ACCESS_LEVEL, ottAsset?.accessLevel) ?? none}</p>
                  <h2 style={subHeadingStyle}>{lang.provider_label}</h2>
                  <p style={subHeadingDetailStyle}>{ottAsset?.Provider?.Description ?? none}</p>
                </div>
                <div>
                  <h2 style={subHeadingStyle}>{lang.route_type_label}</h2>
                  <p style={subHeadingDetailStyle}>{utility.getEnumValue(OTTROUTETYPE, ottAsset?.routeType) ?? none}</p>
                  <h2 style={subHeadingStyle}>{lang.posters_label}</h2>
                  <p style={subHeadingDetailStyle}>{ottAsset?.Posters?.length ?? none}</p>
                  <h2 style={subHeadingStyle}>{lang.videos_label}</h2>
                  <p style={subHeadingDetailStyle}>{ottAsset?.Videos?.length ?? none}</p>
                </div>
              </div>
            </div>
        }

      </div>

      {showEdit && <BossDialog
        title={'Edit Ott Asset'}
        onClose={onClose}
        width={"70vw"}
        height={"770px"}
      >
        <SaveButton onClick={onSave}></SaveButton>
        <CancelButton onClick={onClose}></CancelButton>
        <div className="mt-3"></div>
        <MediaEpisodeAssetEditForm dataItem={{...ottAsset, IsOttAsset: isOttAsset}} source={ENTITYNAME.MediaEpisode} sourceEntity={mediaEpisode} entityname={ENTITYNAME.OttAsset} setDataEntity={setAssetEntity} />
      </BossDialog>
      }
    </>
  )
}

export default OttAssesTab;