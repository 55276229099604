/* eslint-disable */
import { utility } from "../../../framework/utility/utilityProvider";
import SecondaryEventTimeLineView from "./SecondaryEventTimeLineView";

const SecondaryEventGanntView = (props) => {
    
    const { timelineViewData, selectedState } = props;
    // Start and end times from the selected state, adjusted by 10 minutes
    const tenMinDiff = 600000
    const startDateTime = new Date(utility.convertMilisecondsToDateTimeString(selectedState.SlotDateTime - tenMinDiff));
    const endDateTime = new Date(utility.convertMilisecondsToDateTimeString(selectedState.EndSlotDateTime + tenMinDiff));

    return (
        <SecondaryEventTimeLineView startDateTime={startDateTime} endDateTime={endDateTime} data={timelineViewData} slotDuration={1} />
    );
};

export default SecondaryEventGanntView;
