/* eslint-disable */
import React, { useState, useEffect, useCallback, createContext, useRef } from 'react'
import { Field, Form, FormElement } from "@progress/kendo-react-form";
import { EditPageHeader } from '../../components/EditPageHeader';
import { Checkbox } from "@progress/kendo-react-inputs";
import * as API from "../../framework/API/api"
import { ENTITYNAME, LOGEVENT, MEDIACATEGORIES, MODULE } from "../../framework/constant/constant";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { Grid, GridColumn as Column, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import CollectionSelection from '../../framework/forms/CollectionSelection';
import { toast } from 'react-toastify';
import { useTranslation } from '../../locale/useTranslation';
import AddRoundButton from '../../framework/forms/helpercomponents/buttons/AddRoundButton';
import DeleteRoundButton from '../../framework/forms/helpercomponents/buttons/DeleteRoundButton';
import { utility } from '../../framework/utility/utilityProvider';
import { getter } from '@progress/kendo-data-query';
import { ConfirmDeleteAlert } from '../../ConfirmAlert';
import EditRoundButton from '../../framework/forms/helpercomponents/buttons/EditRoundButton';
import RoundButton from '../../framework/forms/helpercomponents/buttons/RoundButton';
import BossDialog from '../../components/BossDialog';

const DATA_ITEM_KEY = "SID";
const SELECTED_FIELD = "selected";
const idGetter = getter(DATA_ITEM_KEY);

const MediaEpisodeGroupEditForm = (props) => {

    const [mediaCategoryType, setMediaCategoryType] = useState([]);
    const [selectedMedia, setSelectedMedia] = useState(props.item.MediaEpisodes == undefined ? [] : props.item.MediaEpisodes)
    const [openSelectedMedia, setOpenSelectedMedia] = useState(false);
    const [selectedState, setSelectedState] = useState({});

    const lang = useTranslation();

    const blankDataItem = {
        Name: "",
        MediaCategoryType: {},
        MediaEpisodes: []
    }

    useEffect(() => {
        loadcombo();
        if (props.item.SID > 0) {
            loadEditData();
        }
    }, [])

    const [dataItem, setDataItem] = useState(blankDataItem);

    const loadcombo = async () => {

        let MediaCategoryTypeRes = await API.getDataLookup(ENTITYNAME.MediaCategoryType);
        let mediaCategoryRes = await API.getDataLookup(ENTITYNAME.MediaCategory);
        let filteredMediaCategory = props.isLong != undefined && mediaCategoryRes.data.filter((item) => item.isLong == props.isLong);
        let filterData = [];
        if (props.isLong != undefined) {
            filteredMediaCategory.map((item) => {
                let tempData = MediaCategoryTypeRes.data.filter((obj) => obj.MediaCategorySID == item.SID);
                filterData.push(...tempData);
            })
        }
        else {
            filterData = MediaCategoryTypeRes.data;
        }
        setMediaCategoryType(filterData);
    }

    const loadEditData = async () => {

        let linkData = {
            link_collection: 'mediaepisode',
            link_field: 'MediaEpisodes'
        }
        let res = await API.getEntity(ENTITYNAME.MediaEpisodeGroup, props.item.SID, linkData);
        if(res.data.length == 0){
            res = await API.getEntity(ENTITYNAME.MediaEpisodeGroup, props.item.SID);
        }
        if (res.success) {
            setDataItem(res.data);
            setSelectedMedia(res.data.MediaEpisodes);
        }
        else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const onChange = (e) => {
        if(e.target.name == "MediaCategoryType"){
            setSelectedMedia([]);
            setSelectedState({});
        }
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {

        if (dataItem.Name == undefined || dataItem.Name == "") {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        if (Object.keys(dataItem.MediaCategoryType).length == 0) {
            toast.error(`${lang.please_select_media_category_type_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        if (selectedMedia.length == 0) {
            toast.error(`${lang.please_attach_atleast_one_media_episode_group_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false
        }
        return true
    }

    const onMediaSelection = (dataList) => {

        let newData = utility.checkDuplicateInData(dataList, selectedMedia, "AssetId")
        setSelectedMedia([...selectedMedia, ...newData]);

    }

    const viewCollectionSelection = (data) => {
        console.log(data.target.name)

        if (Object.keys(dataItem.MediaCategoryType).length == 0) {
            toast.error(`${lang.please_select_media_category_type_first_media_episode_group_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        setOpenSelectedMedia(!openSelectedMedia);
    }

    const handleSubmit = async () => {

        if (isValid()) {
            const saveData = {
                _id: props.item?._id,
                SID: props.item.SID ?? 0,
                Name: dataItem.Name,
                MediaCategoryType: {
                    _id: dataItem.MediaCategoryType._id,
                    SID: dataItem.MediaCategoryType.SID,
                    Description: dataItem.MediaCategoryType.Description,
                    MediaCategorySID: dataItem.MediaCategoryType.MediaCategorySID
                },
                MediaCategory: {
                    _id: selectedMedia[0].MediaCategory._id,
                    SID: selectedMedia[0].MediaCategory.SID,
                    Description: selectedMedia[0].MediaCategory.Description,
                    isLong: selectedMedia[0].MediaCategory.isLong
                },
                MediaEpisodes: selectedMedia.length > 0 ? selectedMedia.map((obj) => obj._id) : [],
                Archive: dataItem.Archive ?? false,
                checkDuplicate: true,
                query: [["Name", "=", dataItem.Name]]
            }

            let res = await API.saveData(ENTITYNAME.MediaEpisodeGroup, saveData);
            console.log(res);
            if (props.isLong == true) {
                let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_PROGRAM_GROUP : LOGEVENT.UPDATE_PROGRAM_GROUP, module: MODULE.PROGRAM_GROUP, data: res.data, message: res.message };
                API.SaveLogs(logData);
            } else if (props.isLong == false) {
                let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_INTERSTITIAL_GROUP : LOGEVENT.UPDATE_INTERSTITIAL_GROUP, module: MODULE.INTERSTITIAL_GROUP, data: res.data, message: res.message };
                API.SaveLogs(logData);
            } else {
                let logData = { event: saveData.SID == 0 ? LOGEVENT.CREATE_MEDIA_EPISODE_GROUP : LOGEVENT.UPDATE_MEDIA_EPISODE_GROUP, module: MODULE.MEDIA_EPISODE_GROUP, data: res.data, message: res.message };
                API.SaveLogs(logData);
            }

            if (res.success) {
                props.refresh();
                props.cancelEdit();
                return;
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    const onSelectionChange = useCallback(
        (event) => {
            const newSelectedState = getSelectedState({
                event,
                selectedState: selectedState,
                dataItemKey: DATA_ITEM_KEY,
            });
            setSelectedState(newSelectedState);
        },
        [selectedState]
    );

    const onHeaderSelectionChange = useCallback((event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
          newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);
    }, []);

    const handleSelectedDelete = () =>{
        let selectedData = selectedMedia.filter((item) => !selectedState[idGetter(item)])
        setSelectedMedia(selectedData);
        setSelectedState({});
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    // navigate to new window
    const editSelectedItem = (item) => {
        switch (item.MediaCategory.SID) {
            case MEDIACATEGORIES.Episode:
                window.open(`/home/MediaEpisode/Episode/${item.SID}`, '_blank');
                break;
            case MEDIACATEGORIES.Series:
                window.open(`/home/MediaEpisode/Series/${item.SID}`, '_blank');
                break;
            case MEDIACATEGORIES.Movie:
                window.open(`/home/MediaEpisode/Movie/${item.SID}`, '_blank');
                break;
            case MEDIACATEGORIES.Program:
                window.open(`/home/MediaEpisode/Program/${item.SID}`, '_blank');
                break;
            case MEDIACATEGORIES.Season:
                window.open(`/home/MediaEpisode/Season/${item.SID}`, '_blank');
                break;
            case MEDIACATEGORIES.Interstitial: 
                window.open(`/home/MediaEpisode/Interstitial/${item.SID}`, '_blank');
                break; 
            default:
                break;
        }
    }

    const upSelectedItem = (item) => {
        let index = selectedMedia.findIndex((x) => x.SID == item.SID);
        if (index > 0) {
            let tempData = selectedMedia[index];
            selectedMedia[index] = selectedMedia[index - 1];
            selectedMedia[index - 1] = tempData;
            setSelectedMedia([...selectedMedia]);
        }
    }

    const downSelectedItem = (item) => {
        let index = selectedMedia.findIndex((x) => x.SID == item.SID);
        if (index < selectedMedia.length - 1) {
            let tempData = selectedMedia[index];
            selectedMedia[index] = selectedMedia[index + 1];
            selectedMedia[index + 1] = tempData;
            setSelectedMedia([...selectedMedia]);
        }
    }

    const myCommandCell = (props)=> {
        return (
            <div className="mt-1 ml-1" style={{ display: "flex" }}>
                <EditRoundButton onClick={() => editSelectedItem(props.dataItem)}/>
                <RoundButton style={{marginLeft: "5px"}} icon={"arrow-up"} onClick={() => upSelectedItem(props.dataItem)}/>
                <RoundButton style={{marginLeft: "5px"}} icon={"arrow-down"} onClick={() => downSelectedItem(props.dataItem)}/>
            </div>
        );
    }

    return (
        <>
            <BossDialog
                title={dataItem?.Name ? dataItem?.Name : lang.create_group_header_dialog}
                onClose={props.cancelEdit}
                width="1100px"
            >
                <EditPageHeader onSubmit={handleSubmit} onCancel={props.cancelEdit} showTitle={false} />
                <Form
                    onSubmit={handleSubmit}
                    initialValues={dataItem}
                    render={(formRenderProps) => (
                        <FormElement>
                            <div className='row mt-2'>
                                <div className='col-12'>
                                    <div className='row mt-2'>
                                        <div className='col-6'>
                                            <label>{lang.name_label} *</label>
                                            <input
                                                name={"Name"}
                                                type="text"
                                                className='form-control form-control-sm'
                                                onChange={onChange}
                                                value={dataItem.Name}
                                            />
                                        </div>
                                        <div className='col-6'>
                                            <label>{lang.media_category_type_label} *</label>
                                            <DropDownList
                                                data={mediaCategoryType}
                                                name="MediaCategoryType"
                                                textField="Description"
                                                dataItemKey="_id"
                                                value={dataItem.MediaCategoryType}
                                                disabled={props.item.SID > 0 ? true : false}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                    <div className='row'>
                                        <div className="col-6" style={{ marginTop: "25px", marginBottom: "0px" }}  >
                                            <Field
                                                name={"Archive"}
                                                component={Checkbox}
                                                label={lang.archive}
                                                onChange={onChange}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </FormElement>

                    )}

                />
                <div className="row mt-2">
                    <div className="col-12">
                        <AddRoundButton title={lang.add_media_episode_button_tooltip} onClick={viewCollectionSelection} />
                        {(selectedMedia.filter((item) => selectedState[idGetter(item)]).length != 0) && <DeleteRoundButton title={lang.delete_media_episode_button_tooltip} onClick={() => ConfirmDeleteAlert(() => handleSelectedDelete(), () => { })} />}
                        <Grid
                            data={selectedMedia.map((item) => ({
                                ...item,
                                [SELECTED_FIELD]: selectedState[idGetter(item)],
                            }))}
                            dataItemKey={DATA_ITEM_KEY}
                            selectedField={SELECTED_FIELD}
                            selectable={{
                                enabled: true,
                                drag: false,
                                cell: false,
                                mode: "multiple",
                            }}
                            onSelectionChange={onSelectionChange}
                            onHeaderSelectionChange={onHeaderSelectionChange}
                            style={{ height: "39vh", marginTop: "04px", overflow: "hidden" }}>
                            <GridColumn
                                field={SELECTED_FIELD}
                                width="50px"
                                headerSelectionValue={
                                    selectedMedia.findIndex((item) => !selectedState[idGetter(item)]) === -1
                                }
                            />
                            <GridColumn cell={myCommandCell} width="120px" />
                            <Column field="Title" title={lang.title_column} />
                            <Column field="AssetId" title={lang.asset_id_column} />
                            <Column field="EpisodeNumber" title={lang.episode_number_column} />
                            <Column field="MediaCategoryType.Description" title={lang.media_category_type_column} />
                        </Grid>
                        <div className='text-right'>
                            <p className='muted'>{lang.media_episode_group_grid_note}</p>
                        </div>
                    </div>
                </div>
            </BossDialog>
            {openSelectedMedia && <CollectionSelection entityname={ENTITYNAME.MediaEpisode} title={lang.select_media_dialog_header} closeForm={() => setOpenSelectedMedia(!openSelectedMedia)} wherestatement={[['MediaCategoryType.SID', '=', dataItem.MediaCategoryType.SID], ['IsBreak', '!=', true]]} setDataList={onMediaSelection} width={"50vw"} checkDuplicate={true} />}

        </>
    )
}

export default MediaEpisodeGroupEditForm;