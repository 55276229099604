//to show duration in string format on collection
import { SCHEDULETYPE } from "../../../constant/constant";
import { GRID_COL_INDEX_ATTRIBUTE } from "@progress/kendo-react-grid";
import { useTableKeyboardNavigation } from "@progress/kendo-react-data-tools";

export const ScheduleTypeCell = (props) => {

  const field = props.field || "";

  var value = props.dataItem[field];

  var type = Object.keys(SCHEDULETYPE).find(key => SCHEDULETYPE[key] == value)

  return <td
    colSpan={props.colSpan}
    style={{ ...props?.style ?? {} }}
    role={"gridcell"}
    aria-colindex={props.ariaColumnIndex}
    aria-selected={props.isSelected}
    {...{
      [GRID_COL_INDEX_ATTRIBUTE]: props.columnIndex,
    }}
    {...useTableKeyboardNavigation(props.id)}
  >
    {type ?? props.dataItem?.mediaEpisode?.MediaCategoryType?.Description ?? ''}
  </td>;
};