import { Field, Form, FormElement } from '@progress/kendo-react-form';
import { Grid, GridColumn } from '@progress/kendo-react-grid';
import { Splitter } from '@progress/kendo-react-layout';
import React, { useEffect, useState } from 'react'
import { utility } from '../../framework/utility/utilityProvider';
import { useTranslation } from '../../locale/useTranslation'
import * as API from '../../framework/API/api'
import { ENTITYNAME, LOCALSTORAGE_KEY, LOGEVENT, MEDIACATEGORIES, MODULE } from '../../framework/constant/constant';
import SegmentTypeCollectionTab from '../Segment/SegemnetTypeCollectionTab';
import { Checkbox } from '@progress/kendo-react-inputs';
import { toast } from 'react-toastify';
import { DropDownList } from '@progress/kendo-react-dropdowns';
import ActionButton from '../../framework/forms/helpercomponents/buttons/ActionButton';
import { EditPageHeader } from '../../components/EditPageHeader';
import BossDialog from '../../components/BossDialog';

const MultiVersioningForm = (props) => {

    const lang = useTranslation();
    let initialValue = {
        ActualDuration: utility.convertMilisecondsToStringWithFrames(0),
        SegmentType: {},
        SegmentNumber: '',
        SegmentDetails: [],
        IsHardParted: false,
        IsDefault: false,
        Language: {},
        TXReady: false,
        GenerateMaterialID : false,
        MaterialContent : {}
    }
    const [dataItem, setDataItem] = useState(initialValue);
    let segmentDetail = {
        MediaEpisodeSegmentSID: 0,
        Sequence: 0,
        MediaId: "",
        TcIn: '00:00:00:00',
        TcOut: '',
        Duration: '',
        IsPreviewed: false
      };
    const [language, setLanguage] = useState([]);
    const [materialContent, setMaterialContent] = useState([]);  
    const [segmentTypes, setSegmentTypes] = useState([]);
    const [segmentDetails, setSegmentDetails] = useState([]);
    const [horizontalPanes, setHorizontalPanes] = useState([
        {
            size: "30%",
            collapsible: true
        },
        {},
    
    ]);
    // company Data
    const companyData = utility.getValue(LOCALSTORAGE_KEY.COMPANY);

    useEffect(() => {
        loadcombo();
    },[]);

    const loadcombo = async () => {
        let res = await API.getDataLookup(ENTITYNAME.SegmentType, { sort: { Description: 1 } });
        setSegmentTypes(() => res.data);
        let languageRes = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
        setLanguage(languageRes.data);
        let materialContentRes = await API.getDataLookup(ENTITYNAME.MaterialContent, { sort: { Description: 1 } });
        setMaterialContent(materialContentRes.data.filter((x) => x.MediaCategory.SID == MEDIACATEGORIES.Episode));
      }

    const onHorizontalSplitterChange = (event) => {
        setHorizontalPanes(event.newState);
    };

    const onChange = async(e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const onSave = async () => {
    
        // isNewSegment true means new ,false means old edit
        if (!dataItem.SegmentType || !dataItem.Language || segmentDetails.length == 0) {
          toast.error(`${lang.please_generate_segments_first_media_episode_version_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          return;
        }
    
        //to remove idEdit property from segment detail obj
        segmentDetails.forEach((obj) => {
          delete obj.inEdit;
        })
    
        const saveSegmentData = {
          SID: 0,
          SegmentType: dataItem.SegmentType,
          ActualDuration: utility.convertStringWithFramesToMilliseconds(dataItem.ActualDuration),
          Language: dataItem.Language,
          SegmentNumber: '', //create in backend
          IsHardParted: dataItem.IsHardParted ?? false,
          TXReady: dataItem.TXReady ?? false,
          IsDefault: dataItem.IsDefault ?? false,
          Archive: dataItem.Archive ?? false,
          Subtitle: false,
          SubtitleType: {},
          File: "",
          SegmentDetails: segmentDetails.map(item => ({
            ...item, ["TcIn"]: utility.convertStringWithFramesToMilliseconds(item.TcIn),
            ["TcOut"]: utility.convertStringWithFramesToMilliseconds(item.TcOut), ["Duration"]: utility.convertStringWithFramesToMilliseconds(item.Duration)
          }))
    
        }
  
        //send this dataItem to API saveSegmentData
        let payload = {
            item : saveSegmentData,
            episodes : props.selectedData,
            generateMaterialID : dataItem.GenerateMaterialID ?? false,
            materialContent : dataItem.MaterialContent ?? {},
            segByRc : companyData.segByRc ?? false
        }

        console.log(payload);
        let res = await API.createMultipleEpisodeVersions(payload);
        console.log(res);

        if (res.success) {
            // res.skippedEpisodes.length > 0
            toast.info(res.skippedEpisodes.length > 0 ? 
                `Episode No. ${res.skippedEpisodes.join(', ')} are skipped because it has same type of segment already exists`  :
                `${res.message}`
            ,{ position: toast.POSITION.TOP_RIGHT});
            props?.selectedData?.forEach((item)=>{
              let logData = { event: LOGEVENT.UPDATE_EPISODE, module: MODULE.EPISODE, data: {...item, saveSegmentData: saveSegmentData, materialContent : dataItem?.MaterialContent }, message: res.message };
              API.SaveLogs(logData);
            })
        }else{
            toast.error(`${res.message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
        }

        clearData();
    }

    const generateSegment = function (e) {

        let segmentCount = dataItem.SegmentType.SegmentCount;
    
        if (segmentCount) {
    
          if (props.selectedData[0] === null || props.selectedData[0].Duration === null) {
            toast.error(`${lang.media_episode_entity_duration_segment_error_message}`, {
              position: toast.POSITION.TOP_RIGHT
            });
            return;
          }
    
          let segmentDetails = [];
          let episodeDuration = utility.convertMilisecondsToStringWithFrames(props.selectedData[0].Duration);
          let actualSegmentDuration = calculateSegmentDuration(episodeDuration, segmentCount)  //to generate the function to calculate segment duration in utility folder
    
          let durationAsString = actualSegmentDuration;
    
          for (let cnt = 1; cnt <= segmentCount; cnt++) {
    
            if (dataItem.IsHardParted) {
    
              segmentDetail.Duration = actualSegmentDuration;
              segmentDetail.TcIn = '00:00:00:00';
              let millisecondsTcOut = utility.convertStringWithFramesToMilliseconds(segmentDetail.TcIn) + utility.convertStringWithFramesToMilliseconds(actualSegmentDuration);
              segmentDetail.TcOut = utility.convertMilisecondsToStringWithFrames(millisecondsTcOut);
              segmentDetail.IsPreviewed = false;
    
            } else {
    
              segmentDetail.Duration = actualSegmentDuration;
              segmentDetail.TcIn = segmentDetail.TcIn;
              let millisecondsTcOut = utility.convertStringWithFramesToMilliseconds(segmentDetail.TcIn) + utility.convertStringWithFramesToMilliseconds(actualSegmentDuration);
              segmentDetail.TcOut = utility.convertMilisecondsToStringWithFrames(millisecondsTcOut);
              segmentDetail.IsPreviewed = false;
    
            }
    
            segmentDetails.push({
              Sequence: cnt,
              MediaId: `${cnt}-${segmentCount}`,  //segmentnumber-selectedsegmenttype.count === for mediaID
              TcIn: segmentDetail.TcIn,
              Duration: durationAsString,
              TcOut: segmentDetail.TcOut,
              IsPreviewed: false
            });
            segmentDetail.TcIn = segmentDetail.TcOut;
    
    
          }
    
          setSegmentDetails(segmentDetails);
          setDataItem({ ...dataItem, ["ActualDuration"]: episodeDuration });
    
        } else {
          toast.error(`${lang.please_select_segment_type_media_episode_version_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });
        }
    
      }
    
      const updatedSegmentDetails = (updatedData) => {
        console.log(updatedData);
        setSegmentDetails(updatedData);
      }

      const handleGenerateSegmentClick = async (e) => {

        e.preventDefault();
        if(dataItem.GenerateMaterialID && Object.keys(dataItem.MaterialContent).length == 0){
          toast.error(`${lang.please_select_material_content_error_message}`, {
            position: toast.POSITION.TOP_RIGHT
          });
          return;
        }
        generateSegment();
    
      }
    
      const calculateSegmentDuration = function (episodeDuration, segmentCount) {
    
        const [hours, minutes, seconds, msec] = episodeDuration.split(':');
        let hoursToMilliseconds = parseInt(hours) * 3600000;
        let minutesToMilliseconds = parseInt(minutes) * 60000;
        let secondsToMilliseconds = parseInt(seconds) * 1000;
    
        let convertedMilliseconds = hoursToMilliseconds + minutesToMilliseconds + secondsToMilliseconds + (parseInt(msec) * 40);
    
        let result = segmentCount === 0 ? new Date('2000', '1', '1', '0', '0', '0') : utility.convertMilisecondsToStringWithFrames(convertedMilliseconds / segmentCount);
        return result;
      }

      const handleActualDuration = (durationAsString) => {
        dataItem.ActualDuration = durationAsString;
      }

      const clearData = () => {
        setSegmentDetails(() => []);
        setDataItem(() => initialValue);
        props.closeForm();
      }

  return (
    <BossDialog
        title={'Multi Versioning'}
        onClose={props.closeForm}
        width={"65%"}
        // height={"68%"}
    >
    <EditPageHeader title={""} onSubmit={onSave} onCancel={props.closeForm} showTitle={false}/>
    <Splitter panes={horizontalPanes} onChange={onHorizontalSplitterChange} >
        <div>
            <Grid data={props.selectedData} style={{ height: "53vh" }}>
            {/* <GridColumn field="Title" title={lang.title_column} editable={false} /> */}
            <GridColumn field="EpisodeNumber" title={lang.episode_number_column} editable={false} />
            <GridColumn field="AssetId" title={lang.assetId_column} editable={false} />
            <GridColumn field="ReferenceCode" title={lang.reference_code_column} editable={false} />
            </Grid>
        </div>
        <div>
        {/* <div className='col-12'> */}
          <Form
            render={(formRenderProps) => (
              <FormElement>
                  <div className="row" style={{ margin: "5px" }}>
                    <div className="col-12 mt-1">

                      <div className="row">
                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="TabView">{lang.segment_type_label} *</label>

                            <DropDownList
                              style={{
                                backgroundColor: "white",
                              }}
                              data={segmentTypes}
                              name="SegmentType"
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem.SegmentType}
                              onChange={onChange}
                            />

                          </div>
                        </div>

                        <div className="col-6">
                          <div className="form-group">
                            <label htmlFor="">{lang.language_label}</label>
                            <DropDownList
                              style={{
                                backgroundColor: "white",
                              }}
                              data={language}
                              name="Language"
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem.Language}
                              onChange={onChange}
                            />
                          </div>
                        </div>
                      </div>

                      <fieldset className="border rounded-4" style={{padding : '20px 30px' ,margin : '8px 0px 0px 0px'}}  >
                        <div className="row">
                        <div className = {dataItem.GenerateMaterialID ? "col-6" : 'col-12'} style={dataItem.GenerateMaterialID ? { marginTop: '19px' } :{}}>
                          <Field
                            style={{border: "1px solid grey"}}
                            name={"GenerateMaterialID"}
                            component={Checkbox}
                            label={'Auto Generate Material ID'}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.GenerateMaterialID}
                          />
                        </div>
                        {dataItem.GenerateMaterialID &&
                          <div className="col-6">
                            <label htmlFor="">{lang.material_content_sub_menu} *</label>
                            <DropDownList
                              style={{
                                backgroundColor: "white",
                              }}
                              data={materialContent}
                              name="MaterialContent"
                              textField="Description"
                              dataItemKey="_id"
                              value={dataItem.MaterialContent}
                              onChange={onChange}
                            />
                          </div>}
                      </div>
                      </fieldset>
              
                      <div className="row mt-3 mb-2">
                        <div className="col">
                          <Field
                            style={{border: "1px solid grey"}}
                            name={"IsHardParted"}
                            component={Checkbox}
                            label={lang.is_hard_parted_label}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.IsHardParted}
                          />
                        </div>
                        <div className="col">
                          <Field
                            style={{border: "1px solid grey"}}
                            name={"TXReady"}
                            component={Checkbox}
                            label={lang.transmission_label}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.TXReady}
                          />
                        </div>
                        <div className="col-2">
                          <Field
                            style={{border: "1px solid grey"}}
                            name={"Archive"}
                            component={Checkbox}
                            label={lang.archive}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.Archive}
                          />
                        </div>
                        <div className="col-2">
                          <Field
                            style={{border: "1px solid grey"}}
                            name={"IsDefault"}
                            component={Checkbox}
                            label={lang.is_default_label}
                            onChange={(e) => onChange(e)}
                            checked={dataItem.IsDefault}
                          />
                        </div>
                        <div className="col-3" style={{ textAlign: "right" }} >
                          <ActionButton title={lang.generate_segments_button_text} name={lang.generate_segments_button_text} onClick={handleGenerateSegmentClick} />
                        </div>
                      </div>

                      <div className="row mt-3" style={{ marginBottom: "05px" }}>
                        <div className="col-12">
                          {/* to add data list here */}
                          <SegmentTypeCollectionTab
                            segmentDetails={segmentDetails}
                            updatedSegmentDetails={updatedSegmentDetails}
                            handleActualDuration={handleActualDuration}
                            generateSegment={generateSegment}
                          />
                        </div>
                      </div>

                    </div>

                  </div>
              </FormElement>
            )} />
        {/* </div> */}
        </div>
    </Splitter>
    </BossDialog>
  )
}

export default MultiVersioningForm