import React, { useState } from 'react'
import { LOCALSTORAGE_KEY } from '../../../framework/constant/constant'
import { utility } from '../../../framework/utility/utilityProvider'

const LiveEventFooter = (props) => {

    const { statusMessage } = props

    const [isMinified, setIsMinified] = useState(utility.getValue(LOCALSTORAGE_KEY.isMenuMified))

    window.addEventListener('isMinified', () => {
        setIsMinified(utility.getValue(LOCALSTORAGE_KEY.isMenuMified));
    });


  return <div style={{zIndex: 96}} className={isMinified ? "scheduleFooter scheduleFooterMinified" : 'scheduleFooter'}>
    <div className="row mr-2">

        <div className="col-10" >
            <div className="flex-container" style={{ alignItems: "left", justifyContent: "left" }}></div>
        </div>

        <div className="col-2 mt-2" style={{ textAlign: "right" }}>
            <div className="row">
                <div className="col">
                    <span className="footerText">{statusMessage}</span>
                </div>
            </div>
        </div>

    </div>
</div>
}

export default LiveEventFooter