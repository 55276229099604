/* eslint-disable */
import React from 'react';
import { utility } from '../../../framework/utility/utilityProvider';

const SecondaryEventTimeLineView = ({ data=[], startDateTime, endDateTime, slotDuration = 60 }) => {

  const generateTimeSlots = (start, end, duration) => {
    const slots = [];
    let current = new Date(start);
    while (current <= end) {
      slots.push(current.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }));
      current = new Date(current.getTime() + duration * 60000); // Add duration in minutes
    }
    return slots;
  };

  const timeSlots = generateTimeSlots(startDateTime, endDateTime, slotDuration);

  const getEventPosition = (eventStart) => {
    const eventTime = new Date(eventStart);
    const diffMinutes = (eventTime - startDateTime) / (1000 * 60);
    return Math.floor(diffMinutes / slotDuration);
  };

  const getEventWidth = (eventStart, eventEnd) => {
    const start = new Date(eventStart);
    const end = new Date(eventEnd);
    const diffMinutes = (end - start) / (1000 * 60);
    return Math.max(1, Math.ceil(diffMinutes / slotDuration)); // Ensure at least 1 column width
  };

  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-12">
          <div className="card">
            <div className="card-body p-0 overflow-auto border">
              <table className="table table-bordered mb-0 border">
                <thead className='border'>
                  <tr className='border'>
                    {timeSlots.map((slot, index) => (
                      <th className='border' key={`time-${index}`}>{slot}</th>
                    ))}
                  </tr>
                </thead>
                <tbody className='border'>
                  {data.map((event, index) => {
                  const startTime= new Date(utility.convertMilisecondsToDateTimeString(event?.TriggerStartTime));
                  const endTime= new Date(utility.convertMilisecondsToDateTimeString(event?.TriggerEndTime));
                  return (
                    <tr key={index} style={{height: '30px'}}>
                      {timeSlots.map((_, slotIndex) => (
                        <td key={`slot-${index}-${slotIndex}`} className="position-relative" 
                        title={
                          `${event?.Name ?? ''} \n${event?.ID ?? ''} \nTriggerStartTime: ${utility.convertMilisecondsToStringWithFrames(event?.TriggerStartTime) ?? ''} \nTriggerEndTime: ${utility.convertMilisecondsToStringWithFrames(event?.TriggerEndTime) ?? ''}`
                        }>
                          {getEventPosition(startTime) === slotIndex && (
                            <div 
                              className="badge position-absolute top-0 start-0 h-98"
                              style={{
                                width: `${getEventWidth(startTime, endTime) * 100}%`,
                                zIndex: 1,
                                color: 'white',
                                backgroundColor: '#b1b1b1'
                              }}
                            >
                              {utility.subString(event?.Name, 40)}
                            </div>
                          )}
                        </td>
                      ))}
                    </tr>
                  )})}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SecondaryEventTimeLineView;