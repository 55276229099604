import React, { useEffect, useState } from 'react'
import BossDialog from '../../components/BossDialog'
import { useTranslation } from '../../locale/useTranslation'
import { EditPageHeader } from '../../components/EditPageHeader'
import * as API from '../../framework/API/api'
import { ENTITYNAME, LOGEVENT, MESSAGE_VARIANT, MESSAGE_TYPE, MODULE } from '../../framework/constant/constant'
import { utility } from '../../framework/utility/utilityProvider'
import { toast } from 'react-toastify'
import { DropDownList } from '@progress/kendo-react-dropdowns'
import { Switch } from '@mui/material'

const MessageConfigurationEditForm = (props) => {
    const lang = useTranslation()

    const blankDataItem = {
        _id: props?.item?._id,
        SID: props?.item?.SID,
        MessageType: props?.item?.MessageType ? { _id: props.item.MessageType, Description: utility.getKeyByValue(MESSAGE_TYPE, props.item.MessageType) } : {},
        MessageProvider: props?.item?.MessageProvider ?? "",
        MessageTemplate: props?.item?.MessageTemplate ?? "",
        Archive: props?.item?.Archive ?? false,
        MessageVariant: props?.item?.MessageVariant ? { _id: props.item.MessageVariant, Description: utility.getKeyByValue(MESSAGE_VARIANT, props.item.MessageVariant) } : {},
        Active: props?.item?.Active ?? false,
    }

    const [dataItem, setDataItem] = useState(blankDataItem)
    const [messageTypes, setMessageTypes] = useState([])
    const [messageProviders, setMessageProviders] = useState([])
    const [messageTemplates, setMessageTemplates] = useState([])
    const [messageVariants, setMessageVariants] = useState([])

    useEffect(() => {
        loadCombo()
    }, [])

    const loadCombo = async () => {
        let messageTypeRes = Object.keys(MESSAGE_TYPE).map((x) => {
            return {
                _id: MESSAGE_TYPE[x],
                Description: x
            }
        })
        setMessageTypes(messageTypeRes)
        let messageProviderRes = await API.getDataLookup(ENTITYNAME.MessageProvider, { query: ["Archive", '!=', true], sort: { Description: 1 } });
        setMessageProviders(messageProviderRes.data);
        let messageTemplateRes = await API.getDataLookup(ENTITYNAME.MessageTemplate, { query: ["Archive", '!=', true], sort: { Description: 1 } });
        setMessageTemplates(messageTemplateRes.data);

        let messageVariants = Object.keys(MESSAGE_VARIANT).map((x) => {
            return {
                _id: MESSAGE_VARIANT[x],
                Description: x
            }
        })
        setMessageVariants(messageVariants);

    }

    const onChange = (e) => {
        if (e.target.name == "Archive") {
            setDataItem({ ...dataItem, Archive: !dataItem.Archive })
        } else if (e.target.name === "Active") {
            setDataItem({ ...dataItem, Active: !dataItem.Active })
        }
        else {
            setDataItem({ ...dataItem, [e.target.name]: e.target.value })
        }

    }

    const isValid = () => {
        if (Object.keys(dataItem.MessageType).length == 0) {
            toast.error(lang.please_select_message_type_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        if (Object.keys(dataItem.MessageVariant).length == 0) {
            toast.error(lang.please_select_message_variant_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return false;
        }
        return true;
    }

    const handleSubmit = async () => {
        if (!isValid()) return
        let finalData = {
            _id: dataItem?._id,
            SID: dataItem?.SID ?? 0,
            MessageType: dataItem.MessageType._id,
            MessageProvider: dataItem?.MessageProvider && Object.keys(dataItem?.MessageProvider).length > 0 ? {
                _id: dataItem?.MessageProvider?._id,
                Description: dataItem?.MessageProvider?.Description
            } : {},
            MessageTemplate: dataItem?.MessageTemplate && Object.keys(dataItem?.MessageTemplate).length > 0 ? {
                _id: dataItem?.MessageTemplate?._id,
                Description: dataItem?.MessageTemplate?.Description
            } : {},
            MessageVariant: dataItem.MessageVariant._id,
            Active: dataItem.Active ?? false,
            checkDuplicate: true,
            query: [["MessageType", "=", dataItem.MessageType._id], ["MessageVariant", "=", dataItem.MessageVariant._id]],
        }
        console.log(finalData);
        let res = await API.saveData(ENTITYNAME.MessageConfiguration, finalData)
        if (res.success) {
            console.log(res.success)
            let logData = { event: res.message == "update" ? LOGEVENT.UPDATE : LOGEVENT.INSERT, module: MODULE.MESSAGE_CONFIGURATION, data: res.data, message: res.message }
            API.SaveLogs(logData)
            utility.deleteLocalStorageItem(ENTITYNAME.MessageConfiguration);
            props.refresh();
            props.cancelEdit();
        } else {

            if (res.code = 413) {
                toast.error(`Configuration of message type ${dataItem.MessageType.Description} with variant ${dataItem.MessageVariant.Description} already exists.`, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
            else {
                toast.error(res.message, {
                    position: toast.POSITION.TOP_RIGHT
                });
            }
        }
    }

    return (
        <>
            <BossDialog
                title={lang.message_configuration_dialog_header}
                onClose={props.cancelEdit}
                width={'80vh'}
            >
                <div className='row'>
                    <div className='col-12'>
                        <EditPageHeader
                            showTitle={false}
                            onSubmit={handleSubmit}
                            onCancel={props.cancelEdit}
                        />
                        <div className='row mt-4'>
                            <div className='col-6'>
                                <label>{lang.message_type_label} *</label>
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    className='form-control form-control-lm'
                                    data={messageTypes}
                                    name='MessageType'
                                    textField='Description'
                                    dataItemKey='_id'
                                    value={dataItem.MessageType}
                                    onChange={onChange}
                                />
                            </div>
                            <div className='col-6'>
                                <label>{lang.message_variant_label} *</label>
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    className='form-control form-control-lm'
                                    data={messageVariants}
                                    name='MessageVariant'
                                    textField='Description'
                                    dataItemKey='_id'
                                    value={dataItem.MessageVariant}
                                    onChange={onChange}
                                />

                            </div>
                        </div>
                        <div className='row mt-4'>
                            <div className='col-6'>
                                <label>{lang.message_provider_label}</label>
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    className='form-control form-control-lm'
                                    data={messageProviders}
                                    name='MessageProvider'
                                    textField='Description'
                                    dataItemKey='_id'
                                    value={dataItem.MessageProvider}
                                    onChange={onChange}
                                />
                            </div>
                            <div className='col-6'>
                                <label>{lang.message_template_label}</label>
                                <DropDownList
                                    style={{ backgroundColor: "white" }}
                                    className='form-control form-control-lm'
                                    data={messageTemplates}
                                    name='MessageTemplate'
                                    textField='Description'
                                    dataItemKey='_id'
                                    value={dataItem.MessageTemplate}
                                    onChange={onChange}
                                />
                            </div>

                        </div>
                        <div className='row mt-4'>
                            <div className='col-3'>
                                <input
                                    type='checkbox'
                                    name='Archive'
                                    value={dataItem.Archive}
                                    checked={dataItem.Archive}
                                    onChange={onChange}
                                />
                                <label className="mr-1 pl-1">
                                    {lang.archive}
                                </label>
                            </div>
                            <div className="col-3">
                                <Switch
                                    name="Active"
                                    label={lang.active_label}
                                    onChange={onChange}
                                    checked={dataItem.Active}
                                    value={dataItem.Active}
                                    size='small'
                                />
                                <span className={dataItem.Active ? " pl-1 text-success" : " pl-1 text-danger"}>{dataItem.Active ? `${lang.active_label}` : `${lang.deactive_lebel}`}</span>
                            </div>
                        </div>
                    </div>
                </div>

            </BossDialog>
        </>
    )
}

export default MessageConfigurationEditForm
