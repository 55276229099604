/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import * as API from "../../framework/API/api";
import { useTranslation } from "../../locale/useTranslation";
import { Grid, GridColumn as Column } from "@progress/kendo-react-grid";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import BookingApprovel from "./BookingApprovel";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import { useNavigate } from "react-router-dom";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import { Loader } from "../../framework/forms/helpercomponents/Loader";
import { orderBy } from "@progress/kendo-react-data-tools";

const initialSort = [
    {
        field: "SID",
        dir: "asc",
    },
];

export const PendingApprovalCollection = () => {

    const lang = useTranslation();
    const navigate = useNavigate();

    const [gridData, setGridData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [showForm, setShowForm] = useState(false);
    const [filterData, setFilterData] = useState([]);
    const [sort, setSort] = useState(initialSort);

    const selectedDataRef = useRef(0);
    const setSelectedDataRef = (data) => {
        selectedDataRef.current = data;
    }

    useEffect(() => {
        loadData();
    }, []);

    const loadData = async () => {
        setIsLoading(true);
        const res = await API.getCampaignPendingApproval();
        console.log(res.data);
        var filterData = res.data.map(x => {
            return {
                ...x.Campaign[0],
                TotalBookedSpots: x.TotalBookedSpots
            }
        })
        console.log(filterData);
        setGridData(filterData);
        setFilterData(filterData);
        setIsLoading(false);
    }

    const showBookingApprovalFron = (props) => {
        setSelectedDataRef(props.dataItem);
        setShowForm(true);
    }

    const MyCommandCell = (props) => (
        <td style={{ padding: "3px" }}>
            <RoundButton icon={"ellipsis-h"} onClick={() => showBookingApprovalFron(props)} />
        </td>
    )

    var defaultKeys = [];

    const onSearch = (e) => {
        e.preventDefault();
        const keyword = e.target.value?.trim();
        var datakey = Object.keys(gridData[0]);
        datakey?.map((key) => {
            if (key == "CampaignNo" && !defaultKeys.includes(key)) {
                defaultKeys.push(key);
            } else if (key == "Title" && !defaultKeys.includes(key)) {
                defaultKeys.push(key);
            }
        });
        var dataStore = [];
        if (keyword != "") {
            defaultKeys?.map((item) => {
                var filterDataValue = gridData.filter((obj) => obj[item] && obj[item].toLowerCase().includes(keyword.toLowerCase()));
                if (filterDataValue.length != 0) {
                    dataStore.push(...filterDataValue);
                }
            });
            const uniqData = [...new Set(dataStore)];
            setFilterData(uniqData);
        } else {
            setFilterData(gridData);
        }
    }

    const refreshCollection = () => {
        loadData();
    }


    return (<>
        <h1 className="page-title txt-color-bludeDark">
            <i
                className="fa fa-table marlr"
                style={{ fontSize: "20px", color: "GrayText" }}
            ></i>
            <span>{lang.pending_approval_page_breadcrumb}</span>
        </h1>
        <div className="flex-container">
            <div className="martb mar mt-3">
                <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
            </div>
            <div className="flex-container-reverse flex-item-auto">

                <div className="input-group input-group w-300 martb" >
                    <input type="text" className="form-control shadow-inset-2 pt-0 pb-0 ml-2" id="searchInput" aria-label="type 2 or more letters" placeholder={lang.search_button_text} onChange={onSearch} />
                    <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                        <div className="input-group-append">
                            <span className="input-group-text">
                                <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                            </span>
                        </div>
                    </button>
                </div>
                <div className="mt-1" style={{ paddingTop: "12px" }}>
                    <RefreshButton
                        onClick={refreshCollection}
                    />
                </div>
            </div>
        </div>
        {isLoading && <Loader />}
        <div className="row">
            <div className="col-12">
                {!isLoading && <Grid
                    data={orderBy(filterData, sort)}
                    sortable={true}
                    sort={sort}
                    onSortChange={(e) => {
                        setSort(e.sort);
                    }}
                    style={{ height: '75vh' }}>
                    <Column cell={MyCommandCell} width={"45px"} />
                    <Column field="CampaignNo" title={lang.campaign_no_column} />
                    <Column field="Agency.Name" title={lang.agency_column} />
                    <Column field="Client.Name" title={lang.client_column} />
                    <Column field="Title" title={lang.title_column} />
                    <Column field="CampaignType.Description" title={lang.campaign_type_column} />
                    <Column field="Channels" title={lang.channel_column} cell={ArrayCell} format="FullChannelName" />
                    <Column field="Product.Name" title={lang.product_label} />
                    <Column field="TotalBookedSpots" title={lang.spots_column} />
                </Grid>}
            </div>
        </div>
        {showForm && <BookingApprovel data={selectedDataRef.current} onClose={() => { setShowForm(false); loadData(); }} />}
    </>)
}
export default PendingApprovalCollection;