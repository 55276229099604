import React, { startTransition, useEffect, useRef, useState } from 'react'
import { EditPageHeader } from '../../../components/EditPageHeader'
import { useTranslation } from '../../../locale/useTranslation'
import { utility } from '../../../framework/utility/utilityProvider'
import {
    Grid,
    GridColumn as Column,
} from "@progress/kendo-react-grid";
import { orderBy } from '@progress/kendo-react-data-tools'
import { TimeCell, TimeHyperLinkCell } from '../../../framework/forms/helpercomponents/CustomGridCells/TimeCell'
import { toast } from "react-toastify";
import MyInfoCell from '../../../framework/forms/helpercomponents/CustomGridCells/MyInfoCell'
import { SegmentCountCell } from '../../../framework/forms/helpercomponents/CustomGridCells/SegmentCountCell'
import BossDialog from '../../../components/BossDialog'

const GoToSchedulePopup = (props) => {
    const lang = useTranslation();
    const [goToScheduleSlotTime, setGoToScheduleSlotTime] = useState('');
    const { schedulerData } = props;

    const [filterData, setFilterData] = useState(schedulerData);
    const [selectedData, _setSelectedData] = useState(schedulerData.length > 0 ? schedulerData[0] : null);
    const selectedDataRef = useRef(schedulerData.length > 0 ? schedulerData[0] : null);
    const setSelectedData = (data) => {
        _setSelectedData(data);
        selectedDataRef.current = data;
    }

    const ref = useRef('');

    useEffect(() => {
        if (selectedDataRef.current != null) {
            let index = filterData.findIndex((item) => item._id == selectedDataRef.current._id);
            document.getElementById('goToScheduleGrid').children.item(1).children.item(0).scrollTo(0, index * 35);
        }
    }, [selectedData])

    const onGoToSchedule = (dataItem) => {
        props.onGoToSchedule(dataItem);
    }

    const onKeyUp = (event) => {
        // onEnter
        if (event.key === 'Enter') {
            if (selectedDataRef.current == null) {
                toast.info("No data selected", { position: toast.POSITION.TOP_RIGHT });
                return;
            }
            props.onGoToSchedule(selectedDataRef.current);
        }

        // on arrow down
        if (event.key === 'ArrowDown') {
            if (selectedDataRef.current == null) {
                toast.info("No data selected", { position: toast.POSITION.TOP_RIGHT });
                return;
            }
            let nextIndex = filterData.findIndex((item) => item == selectedDataRef.current) + 1;
            if (nextIndex >= filterData.length) {
                nextIndex = filterData.length - 1;
            }
            setSelectedData(filterData[nextIndex]);
        }

        // on arrow up
        if (event.key === 'ArrowUp') {
            if (selectedDataRef.current == null) {
                toast.info("No data selected", { position: toast.POSITION.TOP_RIGHT });
                return;
            }
            let nextIndex = filterData.findIndex((item) => item == selectedDataRef.current) - 1;
            if (nextIndex < 0) {
                nextIndex = 0;
            }
            setSelectedData(filterData[nextIndex]);
        }
    }

    const GridRowRenderFilterGrid = (tr, props) => {

        const trProps = {
            style: props.dataItem.Style,
        };

        return React.cloneElement(tr, { ...trProps }, tr.props.children);

    };

    const onSelectionChange = (event) => {
        let selectedRowIndex = event.startRowIndex;
        setSelectedData(filterData[selectedRowIndex]);
    }

    return (
        <BossDialog
            title={`Go to Schedule`}
            onClose={props.close}
            height={"500px"}
            width={"700px"}
        >
            <EditPageHeader title={""} onSubmit={() => {
                if (selectedDataRef.current == null) {
                    toast.info("No data selected", { position: toast.POSITION.TOP_RIGHT });
                    return;
                }
                onGoToSchedule(selectedDataRef.current);
            }} saveButtonTitle={'Go'} onCancel={props.close} showTitle={false} />
            <div className="row mt-1 ml-1 mb-1">
                <input
                    autoFocus={true}
                    type='text'
                    style={{ height: "30px", width: "300px", border: "1px solid lightgrey", fontSize: "14px", fontFamily: "roboto", letterSpacing: "0.6px", paddingLeft: "5px" }}
                    placeholder='Enter Slot Time or Title or AssetId'
                    onChange={(e) => {
                        if (e.target.value.length > 0) {
                            ref.current = e.target.value;
                            startTransition(() => {
                                let filterData = schedulerData.filter((x) => utility.convertMilisecondsToStringWithFrames(x.SlotDateTime).includes(ref.current) || x.mediaEpisode.Title.toString().toLowerCase().includes(ref.current) || x.mediaEpisode.AssetId.toString().toLowerCase().includes(ref.current));
                                setFilterData(filterData);
                                if (filterData.length > 0) {
                                    setSelectedData(filterData[0]);
                                } else {
                                    setSelectedData(null);
                                }
                            })
                        } else {
                            setFilterData(schedulerData);
                            setSelectedData(schedulerData.length > 0 ? schedulerData[0] : null);
                        }
                    }}
                    onKeyUp={onKeyUp}
                />
            </div>
            <Grid
                rowHeight={40}
                id='goToScheduleGrid'
                style={{ userSelect: "none", height: "300px" }}
                data={orderBy(
                    filterData.map((x, i) => {
                        let isSelected = selectedDataRef.current?._id === x._id
                        return {
                            ...x, Selected: isSelected, Style: isSelected ? { backgroundColor: "#c4dcea", color: "black" } : x.Style
                        };
                    }),
                    [
                        {
                            field: "SlotDateTime",
                            dir: "asc",
                        },
                    ]
                )}
                selectedField='Selected'
                onSelectionChange={onSelectionChange}
                selectable={{
                    enabled: true,
                    drag: false,
                    cell: false,
                    mode: {
                        value: 'single',
                        label: 'Single selection mode',
                    },
                }}
                rowRender={GridRowRenderFilterGrid}
                dataItemKey={'_id'}
            >
                <Column field='SlotDateTime' title='Slot Time' cell={(props)=>TimeHyperLinkCell({...props, onClick: onGoToSchedule })} />
                <Column field='mediaEpisode.Title' title='Title' />
                <Column field='mediaEpisode.AssetId' title='Asset Id' />
                <Column field='Duration' title='Duration' cell={TimeCell} />
                <Column field='Info' title='Info' cell = {MyInfoCell} />
                <Column field='SegmentCount' title='Segment Count' cell={SegmentCountCell}/>   
            </Grid>
        </BossDialog >
    )
}

export default GoToSchedulePopup