/* eslint-disable */
import { useState, useEffect, useRef } from "react";

import { Grid, GridColumn } from "@progress/kendo-react-grid";

import * as API from "../../framework/API/api";

import { DateCell, DateOnlyCell, LocalDateTimeColumn } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import EditForm from "../../framework/forms/helpercomponents/editForm";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY, MODULE, FILEURL, DELIVERYSTATUSENUM, SOCKET_EVENTS, SOCKET_ACTION, MEDIACATEGORIES, PLATFORMTYPE, LOGEVENT } from "../../framework/constant/constant";
import { useLocation, useNavigate } from "react-router-dom";
import DeleteErrorDialogBox from "../../framework/forms/helpercomponents/alert/DeleteErrorDialogBox";
import { MenuCommandCell } from "../../framework/forms/helpercomponents/CustomGridCells/MenuCommandCell ";
import { collectionMenuModel } from "../../framework/forms/helpercomponents/contextMenu/menus/collectionMenuModel";
import { UserPreference } from "../../modules/userPreference/UserPreference";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { CheckboxCell } from "../../framework/forms/helpercomponents/CustomGridCells/CheckBoxCell";
import { HrefCell } from "../../framework/forms/helpercomponents/CustomGridCells/HrefCell";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import BackRoundButton from "../../framework/forms/helpercomponents/buttons/BackRoundButton";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import PreferenceRoundButton from "../../framework/forms/helpercomponents/buttons/PreferenceRoundButton";
import { EnumCell } from "../../framework/forms/helpercomponents/CustomGridCells/EnumCell";
import RoundButton from "../../framework/forms/helpercomponents/buttons/RoundButton";
import { PlatformCell } from "../../framework/forms/helpercomponents/CustomGridCells/PlatformCell";
import MyStatusCell from "../../components/MyStatusCell";
import MediaEpisodeHistory from "../../modules/Planning/MediaEpisodeHistory";
import { IconCell } from "../../framework/forms/helpercomponents/CustomGridCells/IconCell";
import Preview from "../../modules/platformLayout/tv/homePage/components/Preview";
import { MyToggleButton } from "../../framework/forms/helpercomponents/CustomGridCells/MyToggleButton";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { utility } from "../../framework/utility/utilityProvider";
import { LookupCell } from "../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { EditPageHeader } from "../../components/EditPageHeader";
import socket from "../../framework/socket/socket";
import { GridViewCollection } from "../../framework/forms/GridViewCollection";

export const EPGCollection = (props) => {
  //possible props
  var page_take = props.page_take ?? 50;
  var showAdd = props.showAdd ?? true;
  var showBack = props.showBack ?? true;
  var showExport = props.showExport ?? true;
  var showCommandCell = props.showCommandCell ?? true;
  var showEdit = props.showEdit ?? true;
  var showDelete = props.showDelete ?? true;
  var showTitle = props.showTitle ?? true;
  var gridRowRender = props.gridRowRender;
  var CustomEditForm = props.customEditForm ?? EditForm;
  var filterable = props.filterable ?? false;
  var sortable = props.sortable ?? false;
  var isPageable = props.isPageable ?? true;
  var wherestatement = props.wherestatement;
  var andWhereStatement = props.andWhereStatement;
  var sort = props.sort ?? { SID: 1 };
  var customColumns = props.customColumns ?? [];
  var openNewEditForm = props.openNewEditForm ?? false;
  var myTitle = props.title ?? "";
  var myMenuModel = props.menuModel ?? collectionMenuModel;
  // saving addition as we need to pass in other component which already has there own props param, By vishal on Sep 2022 
  var entityName = props.entityname;
  var showPreferenceButton = props.showPreferenceButton ?? true;
  var hideArchiveProps = props.hideArchive ?? false;

  //Add this for media management preference column
  const preferenceColumnENntityName = props.preferenceEntityName && props.preferenceEntityName.length > 0 ? props.preferenceEntityName : entityName;
  // example custome columns[]
  const [gridData, setGridData] = useState([]);
  const [total, setTotal] = useState(1);
  const [openForm, setOpenForm] = useState(false);
  const [editItem, setEditItem] = useState({ SID: 1 });
  const [warningDialog, setWarningDialog] = useState(false);
  const [deleteError, setDeleteError] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState("");
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true);
  const [isNoRecords, setIsNoRecords] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [dataColumns, setDataColumns] = useState([]);
  const [searchText, setSearchText] = useState('');
  const [showArchived, setShowArchived] = useState(false);
  const [hideArchive, setHideArchive] = useState(hideArchiveProps);
  const [filterDataItem, setFilterDataItem] = useState({});
  const [tileView, setTileView] = useState(false);


  const exportMECRef = useRef();
  const setExportMECRef = (data) => {
    exportMECRef.current = data
  }

  const exportMMCRef = useRef();
  const setExportMMCRef = (data) => {
    exportMMCRef.current = data
  }

  const [page, setPage] = useState({
    skip: 0,
    take: page_take,
  });

  //only to get wherestatement from href cell
  const location = useLocation();
  const lang = useTranslation();

  var href = location.state ? location.state.href : false;
  if (href) {
    wherestatement = location.state.wherestatement
    // console.log(wherestatement);
  }


  useEffect(() => {
    setShowArchived(false);
    setPage({ skip: 0, take: page_take });
    loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, false, filterDataItem);
  }, [props.entityname, props.gridData, props.title]);

  useEffect(() => {
  }, []);

  const enterEdit = (item) => {
    console.log("onEnterEdit");
    if (openNewEditForm === false) {
      setOpenForm(true);
      setEditItem(item);
    } // need to navigate to full page edit view
    else {
      navigate(`${item.SID}`, { state: { copy: item.copy ? true : false, quickEdit: item.quickEdit ? true : false } });
    }
  };

  const exportMECXML = (item) => {
    console.log(item);
    setExportMECRef(item);
    exportMECData(item)
  };
  const exportMECData = async (exportDataItem) => {
    var res = await API.getAmazonMECXML(exportDataItem);
    console.log('res.success');
    if (res.success) {
      window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
      toast.success(`${lang.successfully_exported_export_template_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };
  const exportMMCXML = (item) => {
    console.log(item);
    setExportMMCRef(item);
    exportMMCData(item)
  };
  const exportMMCData = async (exportDataItem) => {
    var res = await API.getAmazonMMCXML(exportDataItem);
    console.log('res.success');
    if (res.success) {
      window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
      toast.success(`${lang.successfully_exported_export_template_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  };

  const ottPackageDeliverySchedule = (item) => {
    onOttPackageDeliverySchedule(item)
  };

  const onOttPackageDeliverySchedule = async (item) => {
    const saveData = {
      MediaEpisode: { _id: item._id, Title: item.Title, AssetId: item.AssetId, MediaCategory: item.MediaCategory.Description, EpisodeNumber: item.EpisodeNumber },
      User: { _id: utility.getValue(LOCALSTORAGE_KEY.user_id) },
      DeliveryStatus: DELIVERYSTATUSENUM[0],
      TimeStamp: Date.now(),
      MetaDataChecklistResult: '',
      AssetChecklistResult: '',
      AmazonDeliveryGroup: { _id: "", Description: "" },
      Archive: false,
    };
    console.log(saveData)
    var res = await API.saveData(ENTITYNAME.OttPackageDeliverySchedule, saveData);
    console.log(res);
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    if (!res.success) return;
    navigate(-1);
  };



  //publish for OTT
  const onPublish = async (itemID) => {
    console.log('publish');
    const res = await API.onPublishSeason(itemID);
    if (res.success) {
      toast.success(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    } else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

  };

  const handleNavigate = (item) => {
    navigate(`${item.path}`, { state: item });
  }

  //Setting Preference

  //onSubmit from EDIRFORM
  const handleSubmit = async (dataItem, dataToSave) => {
    console.log("handleSUbmit");
    //if datatosave is null then dataitem is datasave
    dataToSave = dataToSave == null ? dataItem : dataToSave;

    var isDuplicate = await utility.checkDuplicate(props.entityname, dataToSave);

    if (!isDuplicate) {
      setOpenForm(true);
      toast.error(`${lang.entry_already_exist_try_with_different_values_collection_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    var res = await API.saveData(props.entityname, dataToSave);
    console.log(res);
    if (!res.success) {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }
    socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
    utility.deleteLocalStorageItem(props.entityname)
    //to load grid with new data
    loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, showArchived, filterDataItem);
    setOpenForm(false);
  };

  const onSearchEnter = (event) => {
    if (event.key === "Enter") {
      // Cancel the default action, if needed
      event.preventDefault();
      onSearchClick();
    }
  }

  const handleCancelEdit = () => {
    setOpenForm(false);
  };

  const handleDelete = async (dataItem) => {
    console.log(dataItem);
    onConfirmDelete(dataItem)
  };

  const handleArchive = async (dataItem) => {
    if (entityName == ENTITYNAME.Series || entityName == ENTITYNAME.Season) {
      var resArchiveUpdated = await API.onChangeArchiveInSeriesSeason({
        _id: dataItem._id,
        archive: !dataItem.Archive,
        entityName: entityName
      })
    } else {
      var resArchiveUpdated = await API.saveData(entityName, {
        ...dataItem,
        Archive: !dataItem.Archive
      })
    }

    if (resArchiveUpdated.success) {
      console.log(`Archive is been updated to ${!dataItem.Archive}`);
      loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, showArchived, filterDataItem);
      socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
      utility.deleteLocalStorageItem(entityName);
    } else {
      console.log(resArchiveUpdated.message);
    }
  }

  const onCloseUserPrefence = () => {
    loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, showArchived, filterDataItem);
    setShowPreferences(!showPreferences);

  }

  const setAsDefault = async (data) => {

    var setAsDefaultRes = await API.setAsDefault(entityName, data._id);
    if (setAsDefaultRes.success) {
      loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, showArchived, filterDataItem);
      socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
      utility.deleteLocalStorageItem(entityName);
    } else {
      toast.error(setAsDefaultRes.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }

  }

  const handleForceUnlock = (dataItem) => {
    onConfirmDelete(dataItem);
    let logData = { event: LOGEVENT.UNLOCK_PLANNING_SCHEDULE, module: MODULE.PLANNING_LOCK_SCHEDULE, data: dataItem, message: LOGEVENT.UNLOCK_PLANNING_SCHEDULE };
    API.SaveLogs(logData);
  }

  //Edit Row
  const MyCommandCell = (props) => (

    <MenuCommandCell
      {...props}
      publish={onPublish}
      history={history}
      enterEdit={enterEdit}
      cancelEdit={handleCancelEdit}
      onSubmit={handleSubmit}
      item={editItem}
      dataColumns={dataColumns}
      onDelete={handleDelete}
      onArchive={handleArchive}
      showEdit={showEdit}
      showDelete={showDelete}
      toNavigate={handleNavigate}
      myMenuModel={myMenuModel}
      openNewEditForm={openNewEditForm}
      setAsDefault={setAsDefault}
      exportMECXML={exportMECXML}
      exportMMCXML={exportMMCXML}
      ottPackageDeliverySchedule={ottPackageDeliverySchedule}
      onForceUnlock={handleForceUnlock}
    />
  );

  const MyCustomToggleButton = (props) => (
    <MyToggleButton
      {...props}
      entityName={entityName}
      size={"small"}
    />
  );

  const MyDatalookupCell = (props) => (
    <LookupCell {...props} dataColumns={dataColumns} />
  );

  const Mystatuscell = (props) => (
    <div>
      <MyStatusCell title={props.dataItem.CurrentStatus} titleColor="#333" tileBackColor="#72eda9" />
    </div>
  );

  const loaddata = async (pagination, wherestatement, andWhereStatement, searchText, archive, filters) => {

    console.log('here')

    if (props.gridColumns) {
      setDataColumns(props.gridColumns);
      setGridData(props.gridData);
      setIsLoading(false);
    } else {
      try {
        var currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
        var columns = await API.getFormData(preferenceColumnENntityName, currentUserId);
        var json = await API.getEntitiesWithSearch(
          props.entityname,
          { query: wherestatement, page: pagination.current_page, perPage: pagination.per_page, archive: archive, sort: sort },
          columns,
          searchText,
          filters
        );

        console.log(json.data)
        setGridData(json.data);
        setTotal(json.pagination.total);
        //if custmoe colum is coming from compemnent level then no need  a column from Server API
        customColumns.length > 0
          ? setDataColumns(customColumns)
          : setDataColumns(columns);

        // console.log(columns)
        setIsLoading(false);
      } catch (error) {
        console.log("error", error);
        setIsLoading(false);
        setIsNoRecords(true);
      }
    }
  };

  const handleErrorDialogClose = () => {
    setDeleteError(!deleteError);
    setDeleteErrorMessage("");
  }
  const errorHandler = (message) => {
    setWarningDialog(!warningDialog);
    setDeleteError(true);
    setDeleteErrorMessage(message);
  }

  const onConfirmDelete = async (dataItem) => {
    const res = await API.deleteData(props.entityname, dataItem.SID);
    console.log("Loading");
    if (!res.success) {
      console.log(res.message);
      errorHandler(res.message);
      return;
    }
    loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, '', showArchived, filterDataItem);
    socket.emit(SOCKET_EVENTS.onSocketData, { action: SOCKET_ACTION.DELETE_LOCAL_STORAGE_KEY, module: MODULE.ALL, data: props.entityname })
    utility.deleteLocalStorageItem(props.entityname);
  }

  const handleServerSideSearch = (e) => {
    setSearchText(() => e.target.value);

    if (e.target.value == '') {
      loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, '', showArchived, filterDataItem);
    }
  };

  const onSearchClick = () => {

    if (searchText != '') {
      loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, showArchived, filterDataItem);
    } else {
      loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, '', showArchived, filterDataItem);
    }

  }


  const pageChange = async (e) => {
    var current_page = e.page.skip / e.page.take + 1;

    await loaddata({ per_page: e.page.take, current_page: current_page, orderby: "SID", direction: "asc" }, wherestatement, andWhereStatement, searchText, showArchived, filterDataItem);

    setPage(e.page);
  };

  const onArchiveClick = () => {
    setShowArchived(!showArchived);
    if (!showArchived) {
      loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, true, filterDataItem);
    } else {
      loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, false, filterDataItem);
    }
  }

  const refreshCollection = () => {
    setIsLoading(true);
    loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, showArchived, filterDataItem
    );
  }

  const downloadExcel = async () => {

    if (gridData.length == 0) {
      toast.info(`${lang.no_data_found_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return;
    }

    var res = await API.exportGridExcel(gridData, dataColumns);
    console.log(res.data);
    if (res.success) {
      window.open(FILEURL.BASEURL + 'downloadReport/' + res.data)
      toast.success(`${lang.successfully_download_success_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
    else {
      toast.error(res.message, {
        position: toast.POSITION.TOP_RIGHT
      });
    }
  }

  return (
    <>
      <div>
        {showTitle && (
            <>
                <div className="float-left">
                    <h1 className="page-title txt-color-bludeDark">
                    <i
                        className="fa fa-table marlr"
                        style={{ fontSize: "20px", color: "GrayText" }}
                    ></i>
                    <span>{myTitle === "" ? props.entityname : myTitle}</span>
                    </h1>
                </div>
                <div
                    className="pointer p-1 float-right"
                    onClick={() => setTileView(!tileView)}
                    title={
                    tileView ? `${lang.grid_view_label}` : `${lang.tile_view_label}`
                    }
                ><i
                    className={`fas ${
                    tileView ? "fa-table-cells" : "fa-table-list"
                    } fa-xl`}
                />
                </div>
          </>
        )}
        <div className="flex-container">
          <div className="martb mar">
            {showBack && (
              <BackRoundButton onClick={() => navigate(-1)}></BackRoundButton>
            )}

            {showAdd && (
              <AddRoundButton onClick={() => enterEdit({ SID: 0 })}></AddRoundButton>
            )}
          </div>


          <div className="flex-container-reverse flex-item-auto">

            <div className="input-group input-group w-300 martb" >
              <input
                type="text"
                className="form-control shadow-inset-2 pt-0 pb-0"
                id="searchInput"
                aria-label="type 2 or more letters"
                placeholder={lang.search_button_text}
                onKeyDown={onSearchEnter}
                onChange={handleServerSideSearch}

                onFocus={(e) => {
                  if (props.setOutSideFocus) {
                    props.setOutSideFocus(true);
                  }
                }}
                onBlur={(e) => {

                  if (props.setOutSideFocus) {
                    props.setOutSideFocus(false);
                  }
                }}
              />
              <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} onClick={onSearchClick}>
                <div className="input-group-append">
                  <span className="input-group-text">
                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                  </span>
                </div>
              </button>
            </div>
            {showPreferenceButton && <div className="m-1" style={{ paddingTop: "12px" }}>
              <PreferenceRoundButton
                icon={"gear"}
                title={lang.preference_button_tooltip}
                onClick={() => { setShowPreferences(!showPreferences) }}
              />
            </div>
            }
            <div className="mt-1" style={{ paddingTop: "12px" }}>
              <RefreshButton
                onClick={refreshCollection}
              />
            </div>
            {showExport && (
              <div className="m-1" style={{ paddingTop: "12px" }}>
                <RoundButton
                  icon={"download"}
                  btnColor={"info"}
                  style={{ margin: "0px 03px", color: 'white' }}
                  // iconColor={"white"}
                  title={lang.export_button_tooltip}
                  onClick={downloadExcel}
                /></div>
            )}
            {!hideArchive && <div className="d-inline mt-3 mr-2">
              <input type='checkbox' name='FilteredArchive' checked={showArchived} onClick={onArchiveClick} onChange={() => { }} />  {lang.archive}
            </div>}
          </div>
        </div>
        {isLoading && <div className="ml-1">
          <i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i>
        </div>}

        {/* {isLoading && <img style={{margin:"0px 500px"}} src="https://media4.giphy.com/media/3oEjI6SIIHBdRxXI40/giphy.gif?cid=ecf05e470dz4nwx86m2071l2fgqc0sydk78pv7hhh5fd8lew&rid=giphy.gif&ct=g"/>} */}
        {isNoRecords && <div style={{ margin: "0px 500px" }}><h4><strong>{lang.no_records_found}</strong></h4></div>}
        {!isLoading && !isNoRecords && 
            tileView ? <GridViewCollection gridData={gridData} pageSize={page_take} onContextMenu={MenuCommandCell} refresh={refreshCollection}/> : 
            <Grid
                style={props.gridstyle ?? {
                height: "76vh",
                }}
                data={gridData}
                rowRender={gridRowRender}
                filterable={filterable}
                sortable={sortable}
                pageable={isPageable}
                skip={page.skip}
                take={page.take}
                onPageChange={pageChange}
                total={total}
                resizable={true}
            >
            {/* Edit Row */}

            {showCommandCell && (
              <GridColumn cell={MyCommandCell} width={"60px"} locked={true} />
            )}

            {/* Rows Loop */}
            {dataColumns.map((column, index) => {
              if (column.type === COLUMNSTYPE.date) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={DateOnlyCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
                // }else if (column.name === COLUMNSTYPE.time) {
              } else if (column.type === COLUMNSTYPE.datetime) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={DateCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
                // }else if (column.name === COLUMNSTYPE.time) {
              } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={TimeCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              } else if (column.name === "SID") {
                return;
                //we are not returning any value for SID column
              } else if (column.type === COLUMNSTYPE.image) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={ImageCell}
                    title={column.label}
                    width={column.width ?? 100}
                  />
                );
              } else if (column.type === COLUMNSTYPE.select) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={MyDatalookupCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.toggle) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={MyCustomToggleButton}
                    title={column.label}
                    width={column.width ?? 80}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.checkbox) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={CheckboxCell}
                    title={column.label}
                    width={column.width ?? 80}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.array) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={ArrayCell}
                    title={column.label}
                    width={column.width ?? 200}
                    format={column.format ?? "Description"} //display item in object
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.href) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={HrefCell}
                    title={column.label}
                    width={column.width ?? 200}
                    format={column.format}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.enum) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={EnumCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              } else if (column.type === COLUMNSTYPE.platform) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={PlatformCell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.status) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={Mystatuscell}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.localdatetime) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={LocalDateTimeColumn}
                    title={column.label}
                    width={column.width ?? 200}
                  />
                );
              }
              else if (column.type === COLUMNSTYPE.icon) {
                return (
                  <GridColumn
                    key={index}
                    field={column.name}
                    cell={IconCell}
                    title={column.label}
                    width={column.width ?? 80}
                  />
                );
              }
              else {
                return (
                  column.type !== COLUMNSTYPE.hidden && (
                    <GridColumn
                      key={index}
                      field={column.name}
                      title={column.label}
                      width={column.width ?? 200}
                    />
                  )
                );
              }
            })}
          </Grid>} 

        {openForm && (
          <CustomEditForm
            {...props} //to get other props from customEditForm
            cancelEdit={handleCancelEdit}
            onSubmit={handleSubmit}
            refresh={() => loaddata({ per_page: page_take, current_page: 1 }, wherestatement, andWhereStatement, searchText, showArchived, filterDataItem)} //to refresh the collection when data added
            item={editItem}
            dataColumns={dataColumns}
          />
        )}

        {deleteError &&
          <DeleteErrorDialogBox message={`Data of ${props.title ?? props.entityname} con Not be deleted  Due to Foreign Key Inclusion`} onClose={handleErrorDialogClose} errorMessage={deleteErrorMessage} title={props.title} />}
        {showPreferences && <UserPreference entityName={entityName} preferenceColumnENntityName={props.preferenceEntityName} handleClose={onCloseUserPrefence} />}

      </div>
    </>
  );
};
