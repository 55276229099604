/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import * as API from '../../framework/API/api'
import { ENTITYNAME } from "../../framework/constant/constant";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";
import SaveRoundButton from "../../framework/forms/helpercomponents/buttons/SaveRoundButton";
import CancelRoundButton from "../../framework/forms/helpercomponents/buttons/CancelRoundButton";
import EditRoundButton from "../../framework/forms/helpercomponents/buttons/EditRoundButton";
import DeleteRoundButton from "../../framework/forms/helpercomponents/buttons/DeleteRoundButton";
import { ConfirmDeleteAlert } from "../../ConfirmAlert";
import LanguageTranslator from "../../components/LanguageTranslator";

function MediaEpisodeSynopsisEditForm(props) {

  const [dataItem, setDataItem] = useState({
    Synopsis: "",
  });
  const [editMode, setEditMode] = useState(false);
  const [toggle, setToggle] = useState(false);
  const [languages, setLanguages] = useState([]);
  const [showTranslateForm, setShowTranslateForm] = useState(false);
  const lang = useTranslation();
  const selectedIndexRef = useRef(0);
  const setSelectedIndexRef = (data) => {
    selectedIndexRef.current = data;
  }

  useEffect(() => {
    loadCombo();
  }, [])

  const loadCombo = async () => {
    let res = await API.getDataLookup(ENTITYNAME.Language, { sort: { Description: 1 } });
    setLanguages(() => res.data);

    if (languages?.length > 0) {
      setDataItem((oldval) => ({ ...oldval, Language: languages[0] }));
    }
  }

  const onChange = (e) => {
    setDataItem({ ...dataItem, [e.target.name]: e.target.value });
  };

  const isValidforadd = () => {
    if (dataItem.Synopsis == "" || dataItem.Synopsis == undefined) {
      toast.error(`${lang.synopsis_can_not_be_null_media_episode_Synopsis_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    if (dataItem.Language == undefined || Object.keys(dataItem.Language).length == 0) {
      toast.error(`${lang.please_select_language_media_episode_Synopsis_error_message}`, {
        position: toast.POSITION.TOP_RIGHT
      });
      return false;
    }
    return true;
  }

  const addSynopsis = (index) => {
    if (isValidforadd()) {
      const synopsisObject = {
        ...dataItem,
        Language: typeof dataItem.Language === "string" ? JSON.parse(dataItem.Language) : dataItem.Language,
      }
      //add validation
      props.setSynopsisData([...props.data, synopsisObject]);
      setDataItem({ Synopsis: '' });
      clearData();
    }
  }
  const MyCommandCell = (props) => (
    <div style={{ display: "flex" }}>
      <EditRoundButton onClick={() => editFormGrid(props)} />
      <DeleteRoundButton onClick={() => ConfirmDeleteAlert(() => removeSynopsis(props.dataIndex), () => { })} />
    </div>
  )

  const editFormGrid = (data) => {
    let dataItem = data.dataItem;
    setSelectedIndexRef(data.dataIndex);
    setDataItem(dataItem);
    setToggle(true);
    setEditMode(true);
  }

  const removeSynopsis = (index) => {
    props.setSynopsisData([
      ...props.data.slice(0, index),
      ...props.data.slice(index + 1, props.data.length)
    ]);
    clearData();
    toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
      position: toast.POSITION.TOP_RIGHT
    });
  }

  const editSynopsis = () => {
    if (isValidforadd()) {
      let local = [...props.data]
      local[selectedIndexRef.current] = dataItem;
      props.setSynopsisData(local);
      setEditMode(false);
      clearData();
    }
  }

  const clearData = () => {
    setDataItem({ Synopsis: '', Language: '' });
    setEditMode(false);
    setToggle(false);
  }

  const handleLangugaeTranslate = (data) =>{
    console.log(data);
    setDataItem({ ...dataItem, Synopsis : data});
    setShowTranslateForm(false);
  }

  return (<>
    <div className="row">
      {!toggle && <div className="col-12">
        <AddRoundButton onClick={() => setToggle(true)} />
        <Grid data={props.data} style={{ height: "30vh" }}>
          <GridColumn cell={MyCommandCell} width={"70px"} locked={true} />
          <GridColumn field="Language.Description" title={lang.language_column} editable={false} />
          <GridColumn field="Synopsis" title={lang.synopsis_column} editable={false} />
        </Grid>
      </div>}
      {toggle && <div className="col-6" style={{ paddingTop: "30px" }}>
        <Grid data={props.data} style={{ height: "30vh" }}>
          <GridColumn cell={MyCommandCell} width={"60px"} locked={true} />
          <GridColumn field="Language.Description" title={lang.language_column} editable={false} />
          <GridColumn field="Synopsis" title={lang.synopsis_column} editable={false} />
        </Grid>
      </div>}
      {toggle && <div className="col-6">
        {!editMode && <AddRoundButton onClick={() => addSynopsis()} />}
        {editMode && <SaveRoundButton onClick={() => { editSynopsis() }} />}
        <CancelRoundButton onClick={clearData} />
        <div className="row ml-2" >
          <div className="col-12 mt-2" style={{ border: "groove 1px" }}>
            <div className="row">
              <div className="col-12">
                <div className="form-group">
                  <label htmlFor="">{lang.synopsis_label} *</label>
                  <label className='float-right cursor-pointer' onClick={()=>setShowTranslateForm(true)}><i className="fa-solid fa-language" aria-hidden="true"></i></label>          
                  <textarea type="text" className="form-control form-control-sm"
                    name="Synopsis"
                    value={dataItem.Synopsis}
                    onChange={(e) => onChange(e)} required />
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-6">
                <div className="form-group">
                  <label htmlFor="TabView">{lang.language_label} *</label><br />
                  <DropDownList
                    style={{
                      backgroundColor: "white",
                    }}
                    data={languages}
                    name="Language"
                    textField="Description"
                    dataItemKey="_id"
                    value={dataItem.Language}
                    onChange={onChange}
                    validator={(value) => value ? "" : "Please select the value"}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>}
    </div>
    {showTranslateForm && <LanguageTranslator onSubmit={handleLangugaeTranslate} cancelEdit={() => setShowTranslateForm(false)} />}
    </>
  )
}

export default MediaEpisodeSynopsisEditForm
