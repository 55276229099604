/* eslint-disable */
import React, { useEffect, useRef, useState } from "react";
import { Form, Field, FormElement } from "@progress/kendo-react-form";
import { Checkbox } from "@progress/kendo-react-inputs";
import CollectionSelection from "../forms/CollectionSelection";
import * as API from '../API/api'
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { FixedSidebarWithIcons } from "../../components/FixedSidebarWithIcons";
import { CardList } from "../../components/CardList";
import { ENTITYNAME, LOGEVENT, MENUTYPE, MODULE, SOCKET_ACTION, SOCKET_EVENTS } from "../constant/constant";
import { EditPageHeader } from "../../components/EditPageHeader";
import { toast } from 'react-toastify';
import { useTranslation } from "../../locale/useTranslation";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import PageCollectionSelection from "../forms/PageCollectionSelection";
import socket from "../socket/socket";

export const OttMenuForm = (props) => {

    const location = useLocation();

    const navigate = useNavigate();
    const { SID } = useParams();
    const lang = useTranslation();

    const [dataItem, setDataItem] = useState({
        SID: SID,
        Name: '',
        Platform: {},
        MenuType: {},
        Archive: false
    });

    const [OttPages, setOttPages] = useState([]);
    const [addPages, setAddPages] = useState(false);
    const [deleteOttAssetsSID, setDeleteOttPageSID] = useState([]);
    const [showOttPageEditForm, setShowOttPageEditForm] = useState(false);
    const [platform, setPlatform] = useState([]);
    const [openPages, setOpenPages] = useState(true);
    const [menuTypes, setMenuTypes] = useState([{}]);
    const addOttPagesRef = useRef();
    const setAddOttPagesRef = (data) => {
        addOttPagesRef.current = data;
    }

    useEffect(() => {
        pageRender();
    }, [])

    const pageRender = async() => {
        await loadcombo();
        if (SID > 0) {
            await loadEditData();
        }
    }

    const loadcombo = async () => {
        var platformRes = await API.getDataLookup(ENTITYNAME.Platform, { sort: { Description: 1 } });
        setPlatform(platformRes.data);
        var menuTypeRes = Object.keys(MENUTYPE).map((item) => {
            return { "Description": item, "_id": MENUTYPE[item] }
        });
        setMenuTypes(menuTypeRes)
    }

    const loadEditData = async () => {

        let linkData = {
            link_collection: 'ott_pages',
            link_field: 'Pages'
        }

        var res = await API.getEntity(props.entityname, parseInt(SID), linkData);

        if (res.success) {

            let data = {
                ...res.data,
                MenuType: {"_id": res.data.MenuType,"Description": Object.keys(MENUTYPE).find(key => MENUTYPE[key] === res.data.MenuType)}
            }
            setDataItem(data);
            setOttPages(() => res.data.Pages);

        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const onChange = (e) => {
        console.log(e.target.value);
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    };

    const isValid = () => {

        if (dataItem.Name == undefined || dataItem.Name == "") {
            toast.error(`${lang.please_enter_name_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.Platform == undefined || Object.keys(dataItem.Platform).length == 0) {
            toast.error(`${lang.please_select_platform_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.MenuType == undefined || Object.keys(dataItem.MenuType).length == 0) {
            toast.error(`${lang.please_select_menu_type_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (OttPages.length == 0) {
            toast.error(`${lang.atleast_attach_one_ott_pages_rails_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true;
    }

    const handleSubmit = async (e) => {

        if (!isValid()) return;
        let saveData = {
            _id: dataItem._id,
            SID: dataItem.SID ?? 0,
            Name: dataItem.Name,
            Platform: {
                _id: dataItem.Platform._id,
                SID: dataItem.Platform.SID,
                Description: dataItem.Platform.Description
            },
            MenuType: dataItem.MenuType._id,
            Pages: OttPages.length > 0 ? OttPages.map(x => x._id) : [],
            Archive: dataItem.Archive ?? false,
        }
        console.log(saveData);
        let res = await API.saveData(ENTITYNAME.OttMenu, saveData);
        if (res.success) {
            let logData = {event: saveData.SID==0 ? LOGEVENT.CREATE_OTT_MENU : LOGEVENT.UPDATE_OTT_MENU, module: MODULE.OTTMENU, data: res.data, message: res.message };
            API.SaveLogs(logData);
            //navigate(-1);

            //delay for 1 sec
            setTimeout(() => {

                socket.emit(SOCKET_EVENTS.onSocketData, {
                    action: SOCKET_ACTION.PLATFORM_MENU_REFRESH,
                    module: MODULE.PLATFORM,
                    data: "Menu updated",
                    // user: { name: userData.name, _id: userData._id }
                });
            
          },2000)

          toast.success(lang.update_successfully_label, {
            position: toast.POSITION.TOP_RIGHT
        });

        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    }

    const deleteOttPage = (index, dataItem) => {
        setOttPages(old => old.filter((item, i) => {
            return index !== i;
        }))
        if (dataItem.SID !== 0) {
            setDeleteOttPageSID(old => [...old, dataItem.SID])
        }
        toast.success(`${lang.delete_successfully_grid_data_success_toast_message}`, {
            position: toast.POSITION.TOP_RIGHT
        });
    }

    const editPages = (index, dataItem) => {
        window.open(`/home/Page/${dataItem.SID}`, '_blank')
    }

    const draggedAssetItem = useRef();
    const draggedOverAsset = useRef();
    const selectedDraggedItem = useRef();

    const onDragStart = (e, position, selectedDataItem) => {
        draggedAssetItem.current = position;
        selectedDraggedItem.current = selectedDataItem;
    };

    const onDragEnter = (e, position, containerName) => {
        console.log(containerName + position);

        draggedAssetItem.current = null;
        draggedOverAsset.current = null;
        selectedDraggedItem.current = null;

        if (containerName === "Destination") {
            draggedOverAsset.current = position;
        }
    };
    const moveUpDown = (index, dataItem, isMoveUp) => {
        if (index === 0 && isMoveUp) return;
        if (!isMoveUp && index === OttPages.length - 1) return;

        const copyAssets = [...OttPages];
        copyAssets.splice(index, 1);
        isMoveUp ? copyAssets.splice(index - 1, 0, dataItem) : copyAssets.splice(index + 1, 0, dataItem);

        setOttPages(copyAssets);
    }
    const onDropOnAssetsFromAssets = (e) => {
        console.log(draggedOverAsset);

        if (draggedOverAsset.current == null) return;

        const copyAssets = [...OttPages];
        //  const dragItemAsset = copyAssets[draggedAssetItem.current];
        copyAssets.splice(draggedAssetItem.current, 1);
        copyAssets.splice(draggedOverAsset.current, 0, selectedDraggedItem.current);
        draggedAssetItem.current = null;
        draggedOverAsset.current = null;
        setOttPages(copyAssets);
    }

    const sideFixMenu = [
        {
            icon: 'play',
            title: `${lang.sidefixmenu_rails_pages_tooltip}`,
            onClick: () => { setOpenPages(old => !old) }
        },
    ]

    const addOttPage = (index, isAddUp) => {
        setAddPages(true);
        setAddOttPagesRef({
            Index: index,
            IsAddUp: isAddUp
        })
    }

    const handlePagesLibrary = (dataList) => {
        var data = [...OttPages];
        var index = addOttPagesRef.current.Index;
        if (addOttPagesRef.current.IsAddUp) {
            setOttPages([...data.slice(0, index), ...dataList, ...data.slice(index, data.length)])
        } else {
            setOttPages([...data.slice(0, index + 1), ...dataList, ...data.slice(index + 1, data.length)])
        }
    }

    const getPageMenu = (index, dataItem) => {
        return [
            {
                label: `${lang.options_actionbutton_menutext}`,
                items: [
                    { label: `${lang.edit_actionbutton_option}`, icon: 'pi pi-fw pi-pencil', command: () => editPages(index, dataItem) },
                    { label: `${lang.delete_actionbutton_option}`, icon: 'pi pi-fw pi-trash', command: () => deleteOttPage(index, dataItem) },
                    { label: `${lang.moveup_actionbutton_option}`, icon: 'pi pi-fw pi-arrow-up ', command: () => moveUpDown(index, dataItem, true) },
                    { label: `${lang.movedown_actionbutton_option}`, icon: 'pi pi-fw pi-arrow-down', command: () => moveUpDown(index, dataItem, false) },
                    { label: `${lang.add_above_option}`, icon: 'pi pi-arrow-circle-up', command: () => addOttPage(index, true) },
                    { label: `${lang.add_below_option}`, icon: 'pi pi-arrow-circle-down', command: () => addOttPage(index, false) }
                ]
            }
        ]
    }

    const cancelEdit = () => {
        navigate(-1)
    }

    const customeModelFields = {
        Title: "Name",
        Description: "Description",
        PosterURL: "IconUrl",
        ThirdLine: ""
    };

    return (
        <>
            <FixedSidebarWithIcons menuModel={sideFixMenu} />
            <div className="myFlexContainer">
                {/* Rails */}
                {openPages && <CardList ottMenu={true} customeModelFields={customeModelFields} MenuModel={getPageMenu} showSearch={true} containerName="Destination" cardData={OttPages} onDeleteDataItem={deleteOttPage} close={() => setOpenPages(false)} title={lang.cardlist_pages_menu_title} onAddClick={() => { addOttPage(OttPages.length - 1, false) }} onDragStart={onDragStart} onDragEnter={onDragEnter} onDrop={onDropOnAssetsFromAssets} inTileView={true} showTileView={false} />}
                {/* Add Rails Library */}
                {addPages && <PageCollectionSelection title={lang.select_pages_header_collection} entityname={ENTITYNAME.Page} closeForm={() => setAddPages(false)} setDataList={handlePagesLibrary} />}

                {/* edit form */}
                <div className="main-layout">
                    <Form
                        initialValues={dataItem}
                        render={(formRenderProps) => (
                            <FormElement>
                                <EditPageHeader title={`${lang.ottmenu_title_editpageheader} -> ` + dataItem?.Name} onSubmit={handleSubmit} onCancel={cancelEdit} />
                                <div style={{ boxShadow: "0px 0px 10px 1px lightgrey", backgroundColor: "#EEEEEE", borderRadius: "10px", height: '85vh' }}>
                                    <div className="row mt-2" style={{ margin: "5px" }}>
                                        <div className="col-12">
                                            <div className="row mt-2">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="">{lang.name_label} *</label>
                                                        <input type="text" className="form-control form-control-sm" name="Name" value={dataItem.Name} onChange={(e) => onChange(e)} />
                                                    </div>
                                                </div>
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="">{lang.platform_label} *</label>
                                                        <DropDownList
                                                            style={{
                                                                backgroundColor: "white",
                                                            }}
                                                            data={platform}
                                                            name="Platform"
                                                            textField="Description"
                                                            dataItemKey="_id"
                                                            value={dataItem.Platform}
                                                            onChange={onChange}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-6">
                                                    <div className="form-group">
                                                        <label htmlFor="">{lang.menu_type_label} *</label>
                                                        <DropDownList
                                                            style={{
                                                                backgroundColor: "white",
                                                            }}
                                                        data={menuTypes}
                                                        name="MenuType"
                                                        textField="Description"
                                                        dataItemKey="_id"
                                                        value={dataItem.MenuType}
                                                        onChange={onChange}
                                                        disabled
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-3">
                                            <div className="form-check">
                                                <Field
                                                    name={"Archive"}
                                                    component={Checkbox}
                                                    label={lang.archive}
                                                    onChange={(e) => onChange(e)}
                                                    checked={dataItem.Archive}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </FormElement>
                        )}
                    />
                </div>
                {showOttPageEditForm && <CustomEditForm
                    {...props} //to get other props from customEditForm
                    cancelEdit={(setShowOttPageEditForm(false))}
                    onSubmit={handleSubmit}
                //  item={editItem}
                />}
            </div >
        </>)
};
