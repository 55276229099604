import { useLocation } from "react-router-dom"
import Player from "./player/Player";

const LiveTv = ()=>{
    const location =useLocation();
    const {asset}= location.state;
    console.log(location);
    return <>
    <Player src={asset.VideoPlaybackUrl}/>
    </>
}

export default LiveTv;