
import React from 'react'
import { Chart, ChartSeries,ChartSeriesDefaults,ChartCategoryAxisLabels, ChartSeriesItem, ChartCategoryAxis,  ChartCategoryAxisItem, ChartLegend,} from '@progress/kendo-react-charts';
import { ChartTooltip } from '@progress/kendo-react-charts';
import { useTranslation } from '../../../locale/useTranslation';
import { Loader } from '../../../framework/forms/helpercomponents/Loader';
import { seriesChartColorGenerator } from '../../../framework/constant/constant';

const GlobalizationChart= (props) =>
{
  const lang=useTranslation();
    return (
        <div>
           <div className="panel panel-default" style={{color:"#333", backgroundColor:"#f5f5f5", borderColor:"#ddd"}}>
                <div className="panel-heading" style={{padding:"10px 10px", borderColor:"1px solid transparent", borderBottom: "1px solid transparent", borderTopRightRadius:"1px"   // border-top-right-radius: 1px;
                  , borderTopLeftRadius :"1px", textAlignLast:"center"}}>{props.title}</div>
             {props?.isLoading ? 
            <Loader height="53.5vh" bgColor="white"/>  :
                <div className="panel-body"><iframe className="chartjs-hidden-iframe" style={{width:"100%",display:"block", border:"0px",height:"0px",margin:"0px", position:"absolute", inset:"0px"}}></iframe>
                    <Chart id="myDispatchChart" style={{display:"block", height:"340"}} seriesColors={seriesChartColorGenerator()}>
                    <ChartTooltip />
                    <ChartCategoryAxis>
                    <ChartCategoryAxisItem categories={props.name} />
                  </ChartCategoryAxis>
                  <ChartSeriesDefaults/>
                  <ChartSeries>
                    <ChartSeriesItem data={props.totaldata} />
                  </ChartSeries>
                  <ChartLegend position="top" orientation="horizontal" />
                    </Chart>
                </div>}
            </div>
             
        </div>
       
    )
  }

export default GlobalizationChart;
