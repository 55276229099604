import React, { useState, useEffect } from "react";
import { MultiSelect } from '@progress/kendo-react-dropdowns';
import { filterBy } from '@progress/kendo-data-query';

const MyMultiSelect = (props) => {

    const { data, name, value, onChange, textField, dataItemKey, style, disabled=false } = props;
    const [filterData, setFilterData] = useState([]);
    useEffect(() => {
        setFilterData(data);
    }, [data]);

    const filterChange = event => {
        setFilterData(filterBy(data, event.filter));
    };
    return <div>
        <MultiSelect 
            data={filterData} 
            name={name}
            value={value}
            onChange={onChange}
            textField={textField}
            dataItemKey={dataItemKey}
            filterable={true} 
            onFilterChange={filterChange} 
            style={style}
            disabled={disabled}
        />
    </div>;
};
export default MyMultiSelect;