/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import * as API from "../../framework/API/api";
import { ENTITYNAME, FILEURL } from "../../framework/constant/constant";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import moment from "moment";
import { useTranslation } from "../../locale/useTranslation";
import { utility } from "../../framework/utility/utilityProvider";
import { toast } from "react-toastify";
import underscore from "underscore";
import { Loader } from "../../framework/forms/helpercomponents/Loader";

const BookingScheduleStatus = (props) => {
    const [isLoading, setIsLoading] = useState(false);
    const defaultDates = utility.getFirstAndLastDateOfMonth();
    const defaultParam = {
        FromDate: defaultDates.FirstDate,
        ToDate: defaultDates.LastDate,
        Channel: {},
    }

    const selectedChannelRef = useRef();
    const setSelectedChannelRef = (data) => {
        selectedChannelRef.current = data;
    }
    const [dataItem, setDataItem] = useState(defaultParam);
    const dataItemRef = useRef(defaultParam);
    const dataItemRefSetter = (data) => {
        dataItemRef.current = data;
        setDataItem(data);
    };
    const [channel, setChannel] = useState([]);
    const [bookingSchedule, setBookinSchedulegdata] = useState([]);
    const [headerDates, setHeaderDates] = useState([]);
    const lang = useTranslation();
    const payloadRef = useRef({});
    const _export = useRef(null);

    useEffect(() => {
        loadcombo();
    }, []);

    const loadcombo = async () => {
        var channelRes = await API.getDataLookup(ENTITYNAME.Channel, { sort: { FullChannelName: 1 } });
        setChannel(channelRes.data);
        setSelectedChannelRef(channelRes.data[0])
        setDataItem({ ...dataItem, Channel: channelRes.data[0] })

        if (channelRes.data.length > 0) {
            dataItemRefSetter({ ...dataItem, Channel: channelRes.data[0] })
            payloadRef.current = {
                channelsid: selectedChannelRef.current.SID,
                startdate: new Date(dataItemRef.current.FromDate).getTime(),
                enddate: new Date(dataItemRef.current.ToDate).getTime()
            }
            loadData();
        }

        //loading
        loadBookingData()
    }

    const isValid = () => {

        if (!utility.isValidDate(dataItem.FromDate)) {
            toast.error(`${lang.please_select_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;

        }
        if (!utility.isValidDate(dataItem.ToDate)) {
            toast.error(`${lang.please_select_to_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (dataItem.ToDate <= dataItem.FromDate) {
            toast.error(`${lang.please_select_to_date_grater_then_from_date_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (Object.keys(selectedChannelRef.current).length == 0) {
            toast.error(`${lang.please_select_channel_error_message}`, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true
    }

    const assetids = underscore.groupBy(bookingSchedule, (item) => item.AssetId);


    const loadBookingData = async () => {
        if (isValid()) {
            var fromdate = (new Date(dataItem.FromDate)).setHours(0, 0, 0, 0);
            var todate = (new Date(dataItem.ToDate)).setHours(23, 59, 59); 
            payloadRef.current = {
                channelsid: selectedChannelRef.current.SID,
                startdate: fromdate,
                enddate: todate
            }
            loadData();
        }
    }

    const loadData = async () => {
        setIsLoading(true);
        let fdate = new Date(dataItem.FromDate).setHours(0, 0, 0, 0);
        let tdate = new Date(dataItem.ToDate).setHours(23, 59, 59);

        //create dates for header 
        let days = []

        // Get difference from date or to date 
        let daysCalculate = moment(tdate).diff(moment(fdate), 'days') + 1;

        for (let i = 0; i < daysCalculate; i++) {
            let firstDate = new Date(fdate);
            firstDate.setDate(firstDate.getDate() + i);
            let date = moment(firstDate).format("DD-MMM-YYYY");
            days.push(`${date}`);
        }

        setHeaderDates(days);

        var resBookinSchedule = await API.getBookingScheduleStatus(payloadRef.current);
        setBookinSchedulegdata(resBookinSchedule.data);

        setIsLoading(false);
    };


    const onChange = (e) => {
        if (e.target.name == 'Channel') {
            setSelectedChannelRef(e.target.value)
        }
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const excelExport = async () => {
        var res = await API.exportBookingScheduleStatus(bookingSchedule, headerDates);
        console.log(res);
        if (res.success) {
            var filePath = FILEURL.BASEURL + 'downloadReport/' + res.fileName;
            window.open(filePath);
        } else {
            toast.error(res.message, {
                position: toast.POSITION.TOP_RIGHT
            });
        }
    };


    return <>
     <h1 className="page-title txt-color-bludeDark">
            <i
                className="fa fa-table marlr"
                style={{ fontSize: "20px", color: "GrayText" }}
            ></i>
            <span>{lang.booking_schedule_status_header}</span>
        </h1>   
        <div className="row">
            <div className="col-12">
                <div className="row mt-2">
                    <div className="col-3">
                        <div className="form-group">
                            <label htmlFor="">{lang.from_date_label} </label>
                            <input
                                type="date"
                                className="form-control form-control-sm"
                                name="FromDate"
                                value={dataItem.FromDate}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="form-group">
                            <label htmlFor="">{lang.to_date_label} </label>
                            <input
                                type="date"
                                className="form-control form-control-sm"
                                name="ToDate"
                                value={dataItem.ToDate}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div className="col-3">
                        <div className="form-group">
                            <label htmlFor="TabView">{lang.channel_label} *</label>
                            <DropDownList
                                style={{ backgroundColor: "white" }}
                                data={channel}
                                name="Channel"
                                textField="FullChannelName"
                                dataItemKey="_id"
                                value={dataItem.Channel}
                                onChange={onChange}
                            />
                        </div>
                    </div>
                    <div style={{ textAlign: "left", marginTop: "20px" }}>
                        <ActionButton title={lang.show_button_tooltip} name={lang.show_button_text} onClick={loadBookingData} />
                    </div>
                    <div style={{ textAlign: "left", marginTop: "20px" }}>
                        <ActionButton title={lang.download_button_tooltip} name={lang.download_button_text} onClick={excelExport} />
                    </div>
                </div>
            </div>
            {isLoading ? <Loader /> :
                <div className="col-12" style={{ overflow: "overlay", overflowY: "auto", height: "74.5vh", marginRight: "05px", marginBottom: "10px", width: "25vw" }}>
                    <table className="table table-striped bookstatusviewtable" style={{ borderCollapse: "collapse" }}>
                        <thead style={{ position: "sticky", top: "0px", }}>
                            <tr style={{ textAlign: "center" }}>
                                <th style={{ bottom: "1px", color: "rgb(15, 15, 15)" }}> {lang.assetId_column}</th>
                                <th style={{ bottom: "1px", color: "rgb(15, 15, 15)", textAlign: "left" }}> <td>&nbsp;</td> {lang.title_column} </th>
                                {/* for static header dates */}
                                {headerDates.map((date, index) => {
                                    return (
                                        <th key={index} style={{ color: "rgb(15, 15, 15)" }}>
                                            &nbsp;&nbsp;{date}
                                            <td>{lang.booked_label}</td>
                                            <td>{lang.schedule_label}</td>
                                        </th>
                                    );
                                })}
                            </tr>
                        </thead>
                        <tbody>
                            {Object.keys(assetids).map((item, index) => {
                                var data = assetids[item]
                                var episodetitle = data[0].mediaepisodetitle;
                                return (
                                    <tr key={`${index}-asdf`}>
                                        <td className="TitleStyle">
                                            {item}
                                        </td>
                                        <td className="TitleStyle" style={{ whiteSpace: 'nowrap' }}> {episodetitle.length > 15 ? episodetitle.slice(0, 15 - 1) + '...' : episodetitle}
                                        </td>
                                        {headerDates.map((obj, index) => {
                                            var index = data.findIndex(x => moment(new Date(x.bookingdate)).format("DD-MMM-YYYY") == obj)
                                            return (
                                                <td>
                                                    <div className="row">
                                                        <div className="col-6">&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;  {index > -1 ? data[index].booked : 0}</div>
                                                        <div className="col-6"> &nbsp;&nbsp;&nbsp;&nbsp; {index > -1 ? data[index].schedule : 0}</div>
                                                    </div>
                                                </td>
                                            )
                                        })}
                                    </tr>
                                )
                            })}
                        </tbody>

                    </table>
                </div>
            }

        </div>
    </>
}

export default BookingScheduleStatus;