/* eslint-disable */
import React, { useState, useEffect, useRef } from "react";
import { Grid, GridColumn } from "@progress/kendo-react-grid";
import * as API from "../../framework/API/api";
import { COLUMNSTYPE, ENTITYNAME, LOCALSTORAGE_KEY, SCHEDULETYPE } from "../../framework/constant/constant";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { UserPreference } from "../userPreference/UserPreference";
import { utility } from "../../framework/utility/utilityProvider";
import { DateCell, LocalDateTimeColumn } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { ArrayCell } from "../../framework/forms/helpercomponents/CustomGridCells/ArrayCell";
import { LookupCell } from "../../framework/forms/helpercomponents/CustomGridCells/LookupCell";
import { ImageCell } from "../../framework/forms/helpercomponents/CustomGridCells/ImageCell";
import { useTranslation } from "../../locale/useTranslation";
import RefreshButton from "../../framework/forms/helpercomponents/buttons/RefreshButton";
import CustomPaginationDropDown from "../../framework/forms/helpercomponents/CustomGridCells/CustomPaginationDropDown";
import { ContextMenu } from "primereact/contextmenu";
import AddRoundButton from "../../framework/forms/helpercomponents/buttons/AddRoundButton";

export const FormatVariationCollection = (props) => {

    const lang = useTranslation();
    let page_take = props.page_take ?? 50;
    let wherestatement = props?.wherestatement ?? [];

    const initialSort = { field: 'SID', dir: 'asc' };
    const [sort, setSort] = useState([initialSort])
    const [selectedState, setSelectedState] = useState([]);// multiple selection grid
    const [gridData, setGridData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [searchText, setSearchText] = useState('');
    const [dataColumns, setDataColumns] = useState([]);
    const [page, setPage] = useState({
        skip: 0,
        take: page_take,
    });
    const [total, setTotal] = useState(1);
    const cm = useRef();
    const dataItemForContextMenuRef = useRef();

    const DATA_ITEM_KEY = '_id';
    const SELECTED_FIELD = 'selected';

    useEffect(() => {
        loaddata({ per_page: page.take, current_page: 1, orderby: 'SID', direction: 'asc' }, wherestatement, searchText);
    }, [props.channelSID]);

    const onSearch = (e) => {
        e.preventDefault();
        let text = e.target.value?.trim();
        setSearchText(text);
    };

    // Search 
    useEffect(() => {
        let sortEvent = sort[0] ?? initialSort;
        if (searchText != "") {
            const timeOut = setTimeout(() => {
                setPage({ skip: 0, take: page_take });
                loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText);
            }, 700);
            return () => clearTimeout(timeOut);
        } else {
            setPage({ skip: 0, take: page_take });
            loaddata({ per_page: page_take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText);
        }
    }, [searchText]);

    const pageChange = async (e) => {
        let current_page = e.page.skip / e.page.take + 1;
        let sortEvent = sort[0] ?? initialSort
        await loaddata({ per_page: page_take, current_page: current_page, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText)
        setPage(e.page);
    };

    const loaddata = async (pagination, wherestatement, searchText) => {
        setIsLoading(true);
        setSelectedState([])

        try {
            let currentUserId = utility.getValue(LOCALSTORAGE_KEY.user_id);
            let columns = await API.getFormData(ENTITYNAME.Variation, currentUserId);
            let json = await API.getEntitiesWithSearch(ENTITYNAME.Variation, { page: pagination.current_page, perPage: pagination.per_page, query: [...wherestatement, ["Channel.SID", "=", props.channelSID]] }, columns, searchText);
            setDataColumns(columns);
            setGridData(json.data);
            setTotal(json.pagination.total);
        } catch (error) {
            console.log("error", error);
            setGridData([]);
        }
        setIsLoading(false);
    }

    const MyDatalookupCell = (props) => (
        <LookupCell {...props} dataColumns={dataColumns} />
    );

    const onSelectionChange = (event) => {

        const ctrlKey = event.ctrlKey || event.metaKey;
        const rowIndex = event.startRowIndex;
        const dataItems = event.dataItems;
        const dataItem = dataItems[rowIndex];

        var newSelectedData = [];

        if (ctrlKey) {
            let alreadySelected = selectedState.some(item => item._id == dataItem._id);
            if (alreadySelected) {
                newSelectedData = selectedState.filter(item => item._id != dataItem._id)
            } else {
                newSelectedData = [...selectedState, dataItem]
            }
        } else {
            let alreadySelected = selectedState.length == 1 && selectedState[0]._id == dataItem._id ? true : false
            if (alreadySelected) {
                newSelectedData = []
            } else {
                newSelectedData = [dataItem]
            }
        }
        setSelectedState(newSelectedData);
    };

    const GridRowRenderMediaLibrary = (tr, props) => {
        const trProps = {
            draggable: true,
            onDragStart: (e) => {
                const propsData = [props.dataItem]

                // On Media Library Drag Start, if One Item Is Selected, We are setting drag item as selected item
                if (selectedState && selectedState.length == 1) {
                    setSelectedState(propsData);
                }

                e.dataTransfer.clearData();
                if (selectedState.length > 1) {
                    e.dataTransfer.setData("text/plain", JSON.stringify({ src: ENTITYNAME.Variation, propsData: selectedState }));

                } else {
                    e.dataTransfer.setData("text/plain", JSON.stringify({ src: ENTITYNAME.Variation, propsData }));
                }

            },
            onDragOver: e => { e.preventDefault() }
        };
        return React.cloneElement(tr, { ...trProps }, tr.props.children);
    };

    const onRefresh = () => {
        setIsLoading(true);
        setSort([initialSort])
        loaddata({ per_page: page.take, current_page: 1, orderby: 'SID', direction: 'asc' }, wherestatement, searchText);
    }

    const onSortChange = (e) => {
        setSort(e.sort)
        let sortEvent = e.sort[0] ?? initialSort
        setPage({
            skip: 0,
            take: page_take,
        })
        loaddata({ per_page: page.take, current_page: 1, orderby: sortEvent.field, direction: sortEvent.dir }, wherestatement, searchText);
    }

    const onEditItem = (dataItem) => {
        window.open(`/home/Variation/${dataItem?.SID}`, '_blank');
    }

    const onContextMenu = (e) => {
        e.syntheticEvent.preventDefault();
        cm.current.show(e.syntheticEvent);
        dataItemForContextMenuRef.current = e.dataItem;
        setSelectedState([e.dataItem]);
    }

    return (
        <div style={{ position: 'absolute', left: 0, height: "100%", width: '100%', padding: '5px', marginTop: '-12px', }}>

            <div className="flex-container">
                <div className="flex-container-reverse flex-item-auto">
                    <RefreshButton onClick={onRefresh} />
                    <AddRoundButton onClick={() => { window.open(`/home/Variation/0`, '_blank') }} />
                </div>
            </div>
            <div className="flex-container">
                <div className="flex-container-reverse flex-item-auto">
                    <div className="input-group input-group martb" >
                        <input
                            type="text"
                            className="form-control shadow-inset-2 pt-0 pb-0"
                            id="searchInput"
                            placeholder={lang.search_button_text}
                            onChange={onSearch}
                            onFocus={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(true);
                                }
                            }}
                            onBlur={(e) => {
                                if (props.setOutSideFocus) {
                                    props.setOutSideFocus(false);
                                }
                            }}
                        />
                        <button id="search" style={{ border: "0px", backgroundColor: "white", height: "38px" }} >
                            <div className="input-group-append">
                                <span className="input-group-text">
                                    <i style={{ margin: "4px 0px" }} className="fa fa-search" />
                                </span>
                            </div>
                        </button>
                    </div>

                </div>
            </div>

            {isLoading && <div className="ml-1">
                <i style={{ fontSize: "20px", color: "GrayText" }} className="fas fa-spinner fa-pulse"></i>
            </div>}
            {!isLoading && <>
                <Grid
                    className="ScheduleMediaCollection"
                    data={gridData?.map(item => {
                        item[SELECTED_FIELD] = selectedState.some(dataItem => dataItem._id == item._id)
                        return item;
                    })}
                    sort={sort}
                    sortable={true}
                    onSortChange={onSortChange}
                    rowRender={GridRowRenderMediaLibrary}
                    reorderable={true}
                    dataItemKey={DATA_ITEM_KEY}
                    selectedField={SELECTED_FIELD}
                    selectable={{
                        enabled: true,
                        drag: true,
                        cell: false,
                        mode: {
                            value: 'multiple',
                            label: 'Multiple selection mode'
                        },
                    }}
                    onSelectionChange={onSelectionChange}
                    pageable={true}
                    skip={page.skip}
                    take={page.take}
                    onPageChange={pageChange}
                    total={total}
                    resizable={true}
                    onContextMenu={onContextMenu}
                    pager={(props) => <CustomPaginationDropDown {...props} />}
                >
                    {/* Rows Loop */}
                    {dataColumns.map((column, index) => {
                        if (column.type === COLUMNSTYPE.date) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={DateCell}
                                    title={column.label}
                                    width={140}
                                    sortable={true}
                                />
                            );
                        } else if (column.name.includes("Duration") || column.type == COLUMNSTYPE.time) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={TimeCell}
                                    title={column.label}
                                    width={140}
                                    sortable={true}
                                />
                            );
                        } else if (column.name === "SID") {
                            return;
                        } else if (column.type === COLUMNSTYPE.image) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={ImageCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                    sortable={false}
                                />
                            );
                        } else if (column.type === COLUMNSTYPE.select) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={MyDatalookupCell}
                                    title={column.label}
                                    width={140}
                                    sortable={false}
                                />
                            );
                        }
                        // else if (column.type === COLUMNSTYPE.platform) {
                        //     return (
                        //       <GridColumn
                        //         key={index}
                        //         field={column.name}
                        //         cell={MyPlatfromCell}
                        //         title={column.label}
                        //         width={column.width ?? 200}
                        //       />
                        //     );
                        //   }
                        else if (column.type === COLUMNSTYPE.localdatetime) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={LocalDateTimeColumn}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.provider) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={ProviderCell}
                                    title={column.label}
                                    width={column.width ?? 200}
                                />
                            );
                        }
                        else if (column.type === COLUMNSTYPE.array) {
                            return (
                                <GridColumn
                                    key={index}
                                    field={column.name}
                                    cell={ArrayCell}
                                    title={column.label}
                                    width={140}
                                    format={column.format ?? "Description"} //display item in object
                                    sortable={false}
                                />
                            );
                        } else {
                            return (
                                column.type !== COLUMNSTYPE.hidden && (
                                    <GridColumn
                                        key={index}
                                        field={column.name}
                                        title={column.label}
                                        width={140}
                                        sortable={true}
                                    />
                                )
                            );
                        }
                    })}
                </Grid>
                <ContextMenu
                    ref={cm}
                    model={[
                        {
                            label: `Edit`, icon: 'pi pi-fw pi-pencil', command: () => { onEditItem(dataItemForContextMenuRef.current) }
                        }
                    ]} />
            </>}
        </div>
    );
};
