import { useEffect, useState } from "react";
import BossDialog from "../../components/BossDialog";
import * as API from "../../framework/API/api";
import { useTranslation } from "../../locale/useTranslation";
import { DropDownList } from "@progress/kendo-react-dropdowns";
import ActionButton from "../../framework/forms/helpercomponents/buttons/ActionButton";
import { Grid, GridColumn, getSelectedState } from "@progress/kendo-react-grid";
import { toast } from "react-toastify";
import { TimeCell } from "../../framework/forms/helpercomponents/CustomGridCells/TimeCell";
import { DateOnlyCell } from "../../framework/forms/helpercomponents/CustomGridCells/DateCell";
import { getter } from "@progress/kendo-data-query";
import moment from "moment";
import { ENTITYNAME, MEDIACATEGORIES, MODULE, SCHEDULETYPE } from "../../framework/constant/constant";
import CollectionSelection from "../../framework/forms/CollectionSelection";
import { utility } from "../../framework/utility/utilityProvider";

const SELECTED_FIELD = "selected";
const DATA_ITEM_KEY = "_id";
const idGetter = getter(DATA_ITEM_KEY);

const ShowScheduleForm = (props) => {

    const lang = useTranslation();
    const blankDataItem = {
        Channel: {},
        FromDate: moment(new Date()).format('YYYY-MM-DD'),
        ToDate: moment(new Date()).add(1, 'w').format('YYYY-MM-DD')
    }
    const [dataItem, setDataItem] = useState(blankDataItem);
    const [channels, setChannel] = useState([]);
    const [gridData, setGridData] = useState([]);
    const [selectedState, setSelectedState] = useState({});
    const [selectedItem, setSelectedItem] = useState([]);
    const [showReplacePopup, setShowReplacePopup] = useState(false);
    const [selectedPromo, setSelectedPromo] = useState({});

    useEffect(() => {
        loadCombo();
    }, []);

    const loadCombo = async () => {
        let channelRes = await API.getValidChannels();
        setChannel(channelRes.data);
        setDataItem({ ...dataItem, Channel: utility.getDefaultItem(channelRes.data) });
    }

    const onChange = (e) => {
        setDataItem({ ...dataItem, [e.target.name]: e.target.value });
    }

    const isValid = () => {
        if (!dataItem.Channel || Object.keys(dataItem.Channel).length == 0) {
            toast.error(lang.please_select_channel_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!dataItem.FromDate || dataItem.FromDate == "") {
            toast.error(lang.please_select_from_date_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if (!dataItem.ToDate || dataItem.ToDate == "") {
            toast.error(lang.please_select_to_date_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        if(dataItem.FromDate > dataItem.ToDate){
            toast.error(lang.from_date_should_be_less_than_to_date_error_message, {
                position: toast.POSITION.TOP_RIGHT
            });
            return false;
        }
        return true

    }

    const handleShowGridData = async () => {
        if (!isValid()) return;
        let payload =  {
            MediaEpisode_id: props?.data?._id,
            "Channel.SID": dataItem.Channel.SID,
            Type: SCHEDULETYPE.ShortForm,
            SlotDateTime: {
                "$gte": utility.convertStringDatetoMilliseconds(dataItem.FromDate),
                "$lte": utility.convertStringEndDateToMilliSeconds(dataItem.ToDate)
            }
        }
        let res = await API.showSchedule(payload);
        console.log(res);
        if (res.success) {
            setGridData(res.data);
        } else {
            setGridData([]);
            toast.error(res.message);
        }
        setSelectedItem([]);
        setSelectedState({});
        setSelectedPromo({Title:""});
    }

    const onSelectionChange = (event) => {
        const newSelectedState = getSelectedState({
            event,
            selectedState: selectedState,
            dataItemKey: DATA_ITEM_KEY,
        });
        setSelectedState(newSelectedState);

        let selectedIds = Object.keys(newSelectedState);
        let tempSelected = [];

        selectedIds.map((value) => {
            if (newSelectedState[value]) {
                let filter = gridData.find((obj) => obj._id == value);
                tempSelected.push(filter);
            }
        });

        setSelectedItem(tempSelected);
    };

    const onHeaderSelectionChange = (event) => {
        const checkboxElement = event.syntheticEvent.target;
        const checked = checkboxElement.checked;
        const newSelectedState = {};
        event.dataItems.forEach((item) => {
            newSelectedState[idGetter(item)] = checked;
        });
        setSelectedState(newSelectedState);

        var selectedIds = Object.keys(newSelectedState);
        var tempSelected = [];

        if (checked) {
            selectedIds.map((value) => {
                if (newSelectedState[value]) {
                    var filtered = gridData.find((obj) => obj._id == value);
                    tempSelected.push(filtered);
                }
            });
        }
        setSelectedItem(tempSelected);
    };

    const handleReplaceSelectedItem = async () => {
    
        // Check if any item is selected
        if (selectedItem.length === 0) {
            toast.error(lang.please_select_atleast_one_item_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
    
        // Check if a program is selected for replacement
        if (!selectedPromo || Object.keys(selectedPromo).length === 0 || selectedPromo?.Title == "") {
            toast.error(lang.please_select_item_to_replace_error_message, {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
    
        try {
            // Update the grid data based on the selected items and the selected program
            let lockedScheduleDate = [];
             // check current time to SlotDateTime
            let currentTime = utility.getLocalDateTimeToUtcMiliseconds(new Date());
            if(selectedItem.some(x=>x.SlotDateTime < currentTime)){
                toast.error("Selected items schedule time should be greater then current time.");
                return;
            }
            let updateData = await Promise.all(
                gridData.map(async x => {
                    // Check if the current grid item is part of the selected items
                    if (selectedItem.some(y => y._id === x._id)) {
                        // check is locked item 
                        let query = [['channel._id', '=', dataItem?.Channel?._id], ['date', '=', utility.convertStringDatetoMilliseconds(moment(new Date(x.ScheduleDate)).format('YYYY-MM-DD'))], ['moduleID', '=', MODULE.SCHEDULING]];
                        let checkLockPlaylist = await API.getData(ENTITYNAME.PLAYLIST_LOCK_STATUS, { query: query });
                        console.log(selectedPromo);
                        if (checkLockPlaylist.success && checkLockPlaylist.data && checkLockPlaylist.data.length == 0) {
                            // Save data
                            await API.saveData(ENTITYNAME.ProgramSchedule, {
                                _id: x._id,
                                MediaEpisode_id: selectedPromo._id,
                            });
                            // Return updated item
                            return {
                                ...x,
                                MediaEpisode_id: selectedPromo._id,
                                mediaEpisode: selectedPromo,
                            };
                        }else{
                            lockedScheduleDate.push(moment(new Date(x?.ScheduleDate)).format('DD-MM-YYYY')); 
                            return x;
                        }
                    }
                    return x; // Return the item as-is if it's not part of the selected items
                })
            );
            setGridData(updateData);
            let removeDuplicate = [...new Set(lockedScheduleDate)];
            if(removeDuplicate.length > 0){
                toast.error(`Schedule Date ${removeDuplicate.join(", ")} is locked`, {
                    position: toast.POSITION.TOP_RIGHT,
                });
            }
            toast.success('Updated successfully.')
        } catch (error) {
            toast.error('An error occurred while updating the selected items.', {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };

    const handleSelectedPromo = (data) => {
        let finalData = data?.length > 0 ? data[0] : {};
    
        // check publishing rights 
        if (Object.keys(finalData).length > 0) {
            let checkPublishingRights = finalData.Publishings.some(x => {
                return x?.Channel?.some(y => y?._id == dataItem?.Channel?._id) &&
                    x.PublishStartDate <= utility.convertStringEndDateToMilliSeconds(dataItem.ToDate) &&
                    x.PublishEndDate >= utility.convertStringDatetoMilliseconds(dataItem.FromDate);
            });
    
            if (!checkPublishingRights) {
                toast.error("Not valid for Publishing rights");
            }else{
                setSelectedPromo(finalData);
            }
        }
    }    

    return (
        <BossDialog
            title={`${lang.show_shedule_dialog_header} ( ${props?.data?.Title ?? ""} )`}
            onClose={props.onClose}
            width="60vw"
        >
            <div className="row">
                <div className="col-12">
                    <div className="row">
                        <div className="col-4">
                            <label>{lang.channel_label} *</label>
                            <DropDownList
                                data={channels}
                                name="Channel"
                                textField="FullChannelName"
                                dataItemKey="_id"
                                onChange={onChange}
                                value={dataItem.Channel}
                            />
                        </div>
                        <div className="col-3">
                            <label htmlFor="TabView">{lang.from_date_label} *</label>
                            <input
                                className="form-control form-control-sm"
                                name="FromDate"
                                type="date"
                                value={dataItem.FromDate}
                                onChange={onChange}
                                min={new Date().toISOString().split("T")[0]}
                            />
                        </div>
                        <div className="col-3">
                            <label htmlFor="TabView">{lang.to_date_label} *</label>
                            <input
                                className="form-control form-control-sm"
                                type="date"
                                name="ToDate"
                                value={dataItem.ToDate}
                                onChange={onChange}
                            />
                        </div>
                        <div className="col">
                            <ActionButton name={lang.show_button_text} style={{ marginTop: '18px', borderRadius: '5px' }} onClick={handleShowGridData} />
                        </div>
                    </div>

                    <div className="row mt-2">
                        <label className="ml-3" htmlFor="TabView">{lang.select_item_to_replace_label}</label>
                        <div className="col-12 d-flex">
                            <input
                                name="EnableFilter"
                                type="text"
                                className="form-control form-control-sm"
                                disabled={true}
                                value={selectedPromo?.Title}
                            />
                            <ActionButton
                                style={{ height: "30px", borderRadius: '5px' }}
                                btnColor={"success"}
                                title={lang.select_button_tooltip}
                                name={"..."}
                                onClick={() => setShowReplacePopup(true)}
                            />
                            <ActionButton
                                style={{ height: "30px", borderRadius: '5px' }}
                                btnColor={"danger"}
                                title={lang.clear_button_tooltip}
                                name={""}
                                icon={"xmark"}
                                onClick={() => setSelectedPromo({Title: ''})}
                            />
                            <ActionButton style={{ height: "30px", borderRadius: '5px' }} title={lang.replace_button_tooltip} btnColor={"success"} name={lang.replace_button_text} onClick={handleReplaceSelectedItem} />
                        </div>
                    </div>

                    <div className="row mt-3">
                        <div className="col-12 mt-2">
                            <Grid
                                data={gridData.map((item) => ({
                                    ...item,
                                    [SELECTED_FIELD]: selectedState[idGetter(item)],
                                }))}
                                style={{ height: "40vh" }}
                                selectedField={SELECTED_FIELD}
                                dataItemKey={DATA_ITEM_KEY}
                                selectable={{
                                    enabled: true,
                                    drag: false,
                                    cell: false,
                                    mode: "multiple",
                                }}
                                onSelectionChange={onSelectionChange}
                                onHeaderSelectionChange={onHeaderSelectionChange}

                            >
                                <GridColumn
                                    field={SELECTED_FIELD}
                                    width="50px"
                                    headerSelectionValue={
                                        gridData.findIndex(
                                            (item) => !selectedState[idGetter(item)]
                                        ) === -1
                                    }
                                    locked={true}
                                    filterable={false}
                                />
                                <GridColumn field="mediaEpisode.Title" title={lang.title_column} />
                                <GridColumn field="mediaEpisode.Duration" cell={TimeCell} title={lang.duration_column} />
                                <GridColumn field="ScheduleDate" cell={DateOnlyCell} title={lang.schedule_date_label} />
                                <GridColumn field="SlotDateTime" cell={TimeCell} title={lang.schedule_time_column} />
                            </Grid>
                        </div>
                    </div>
                </div>
            </div>
            {showReplacePopup && <CollectionSelection addButtonTitle={lang.select_button_text} title={lang.select_button_tooltip} entityname={ENTITYNAME.MediaEpisode} wherestatement={[["MediaCategory.SID", "=", MEDIACATEGORIES.Interstitial],["Duration", "=", props?.data?.Duration]]} closeForm={() => { setShowReplacePopup(false) }} setDataList={handleSelectedPromo} width="50vw" mode="single" />}
        </BossDialog>
    );
}

export default ShowScheduleForm;