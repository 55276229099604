import { Menu } from "primereact/menu"
import { useRef } from "react"

export const CardMenuButton = (props) => {
    const {index, dataItem} = props;

    const menu=useRef(null)
    //title
    //onClick
    //props
    //icon
    return <>
    <p className="ponter-cursor mt-1" style={{padding : '2px 0px 2px 7px'}} title='' onClick={(e)=>menu.current.toggle(e, props)}><i className="fa fa-ellipsis " /></p>
    <Menu model={props.MenuModel(index, dataItem)} popup ref={menu}/> 
    </>
}
