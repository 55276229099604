/* eslint-disable */
import {  useState } from "react";
import { Link, useLocation, useParams} from "react-router-dom"
import { LOCALSTORAGE_KEY} from "../../framework/constant/constant";
import { utility } from "../../framework/utility/utilityProvider";
import { useTranslation } from "../../locale/useTranslation";

const CURRENTLOCATION = 'currentLocation';
const SELECTED_MENU = 'selectedMenu';
const PREV_SELECTEDMENU = 'lastSelectedMenu';

const Menu = (props) => {

    let location  = useLocation();
    const lang = useTranslation();
    const { SID } = useParams();

    const { menuModel } = props;

    //need to store current location
    // utility.setValue(CURRENTLOCATION,location.pathname);

    const CreateMasterMenuState = () => {
        const [isExpanded, setIsExpanded] = useState(false);
        return [isExpanded, setIsExpanded]
    }

    var menus = [];
    if(menus.length == 0){
        menuModel.map((x) => {
            if(x.type == 1){
                let allSubMenu = []
                x.subMenu.forEach((y) => {
                    if(y.type == 1){
                        allSubMenu = [...allSubMenu,...y.subMenu];
                    }else{
                        allSubMenu = [...allSubMenu,y];
                    }
                })
                menus = [...menus,...x.subMenu, ...allSubMenu];
            }else{
                menus = [...menus,x];
            }
        })
    }

    var pathname = location.pathname?.split(`/${SID}`)
    var currentMenu = menus.find((x) => pathname[0]?.replace('/home/','') == x.link) ?? { name : 'Dashboard' };

    const handleSelectedModule = (module) => {

        // var currentMenu = menus.find((x) => pathname[0].includes(x.link));
        // console.log(currentMenu);
        // console.log(menus)

    }

    
    const userMenu = utility.getUserMenu(menuModel);
    const plus = 'fa fa-plus-square';
    const minus = 'fa fa-minus-square';

    const handleNextWindowRoute = (path) => {
        window.open(path, "_blank") //to open new tab
    }

    return userMenu.map(item => {
        if (item.type === 0) {
            return (<li key={item.name + "1"} style={currentMenu.name == item.name ? {backgroundColor : '#F4F5FA'} : {backgroundColor : ''}} >
                <Link to={item.link} onClick={() => { handleSelectedModule(item) }}>
                    <i className={item.icon} style={currentMenu.name == item.name ? {color : '#2B3467'} : {color : ''}}></i>
                    <span className="menu-item-parent pt-1" style={currentMenu.name == item.name ? {color : '#2B3467'} : {color : ''}}>{item.name}</span>
                    {!props.isMinified && item.name == `${lang.scheduling_menu}` &&  <i title={lang.open_in_a_new_tab_label} className="fa fa-external-link float-right mt-1" style={currentMenu.name == item.name ? {color : '#191825'} : {color : 'white'}} onClick={() => handleNextWindowRoute('/home/Scheduling')}></i>}
                </Link>
            </li>)
        }
        else if (item.type === 1) {
            const [isExpanded, setIsExpanded] = CreateMasterMenuState();

            return (<li key={item.name + "2"} className={isExpanded ? 'open' : undefined} >
                <a href="# " title={item.title} onClick={(e) =>{e.preventDefault(); setIsExpanded(old => !old);}}>
                    <i className={item.icon}></i>
                    <span className="menu-item-parent">{item.name}</span>
                    <b className="collapse-sign"><em className={isExpanded ? minus : plus}></em></b>
                </a>

                <ul style={isExpanded ? { display: "block" } : { display: "none" }}>
                    {
                        item.subMenu.map((item,i) => {
                            if (item.type === 0) {
                                return <li key= {item.name + i} style={currentMenu.name == item.name ? {backgroundColor : '#ECF2FF'} : {backgroundColor : ''}} >
                                    <Link to={item.link} onClick={() => {  handleSelectedModule(item) }}>
                                        <span className="menu-item-parent pt-1" style={currentMenu.name == item.name ? {color : '#191825'} : {color : ''}}>{item.name}</span>
                                        {item.name == `${lang.planning_sub_menu}` &&
                                            <i title={lang.open_in_a_new_tab_label} className="fa fa-external-link float-right mt-1" style={currentMenu.name == item.name ? {color : '#191825'} : {color : 'white'}}  onClick={(e)=>{e.preventDefault(); handleNextWindowRoute('/home/planning')}}></i>}
                                            {item.name == `${lang.scheduling_menu}` &&
                                            <i title={lang.open_in_a_new_tab_label} className="fa fa-external-link float-right mt-1" style={currentMenu.name == item.name ? {color : '#191825'} : {color : 'white'}}  onClick={(e)=>{e.preventDefault();handleNextWindowRoute('/home/Scheduling')}}></i>}
                                    </Link>
                                </li>
                            }
                            else if (item.type === 1) {
                                const [isExpanded2, setIsExpanded2] = CreateMasterMenuState();
                               

                                return <li key={item.name + i} className={isExpanded2 ? 'open':''}>
                                    <a href="# " title={item.title} onClick={(e) => {e.preventDefault(); setIsExpanded2(old => !old)}}>
                                        <i className={item.icon}></i>
                                        <span className="menu-item-parent pl-2">{item.name}</span>
                                        <b className="collapse-sign"><em className={isExpanded2 ? minus : plus}></em></b>
                                    </a>

                                    <ul style={isExpanded2 ? { display: "block" } : { display: "none" }}>
                                        {item.subMenu.map(subItem => {
                                            return (<li key={subItem.name + i} style={currentMenu.name == subItem.name ? {backgroundColor : '#ECF2FF'} : {backgroundColor : ''}}>
                                                <Link  style={{paddingTop:"2px"}} to={subItem.link} >
                                                    {/* <span className="menu-item-parent pt-1"  style={currentMenu.name == subItem.name ? {color : '#191825'} : {color : ''}}><li key={subItem.name + "6"} className="treeList">{subItem.name}</li></span> */}

                                                    <span className="menu-item-parent pt-1"  style={currentMenu.name == subItem.name ? {color : '#191825'} : {color : ''}}>{subItem.name}</span>
                                                </Link>
                                            </li>)
                                        })}
                                    </ul>
                                </li>
                            }

                        })
                    }
                </ul>
            </li>)
        }
    })
}

export default Menu;