import { EditPageHeader } from "../../components/EditPageHeader";
import { useTranslation } from "../../locale/useTranslation";
import { useState } from "react";
import BossDialog from "../../components/BossDialog";

const MMImageEditForm = (props) => {

    const [image, setImage] = useState(props?.selectedImageUrl);

    const lang = useTranslation();

    const onChange = (e) => {
        setImage(e.target.value);
    }

    const onClose = () => {
      props.onClose();
    }

    const handleSubmit = () => {
        props.setDataList(image);
        onClose();
    }

    return (
        <BossDialog
          title={lang.edit_image_url_dialog_header}
          onClose={onClose}
          width={"500px"}
        >
          <EditPageHeader onSubmit={handleSubmit} saveButtonTitle={lang.confirm_button_text} onCancel={onClose} showTitle={false} />

          <div className="form-group mt-2">
            <label htmlFor="">{lang.image_url_label}</label>
            <input type="text" name="ImageUrl" className="form-control" placeholder="Url" value={image} onChange={onChange} />
          </div>
        </BossDialog>
    )
}

export default MMImageEditForm;